import React, { ChangeEvent } from "react";
import { useDispatch, useSelector } from "react-redux";
import { counterActions } from "@/entities/counter";
import { filterAppsActions, getFilterAppsState } from "@/entities/filterApps";
import { filterAppsDataActions } from "@/entities/filterAppsData";
import styles from "./OnboardingSteps.module.scss";
import { handleFocus } from "@/widgets/inputSearch/libs/const/handleFocus";
import StoredCount from "@/shared/ui/storedcount/StoredCound";

import { ACTIVE_VIEW_HOME } from '@/Views/constants'


const MAX_STEPS = 200;
const MIN_STEPS = 0;
const MAX_INPUT_VALUE = 200;

type SetValueAction = (value: string | number) => {
  type: string;
  payload: string | number;
};

export interface OnboardingStepsProps {
  onSaveMin: (value: number) => void;
  onSaveMax: (value: number) => void;
  handleMinBlur: (
    value: string | number,
    setMinValueAction: SetValueAction,
    onSave: (value: number) => void,
  ) => void;
  handleMaxBlur: (
    value: string | number,
    setMinValueAction: SetValueAction,
    onSave: (value: number) => void,
  ) => void;
  isFetchingFilter: boolean;
  rootActiveView: string;
}

const OnboardingSteps: React.FC<OnboardingStepsProps> = ({
  onSaveMin,
  onSaveMax,
  handleMaxBlur,
  handleMinBlur,
  isFetchingFilter,
  rootActiveView
}) => {
  const { oscgte, osclte, isActiveOnboarding, activeButtonOnboarding } =
    useSelector(getFilterAppsState);
  const dispatch = useDispatch();

  const resetStyles = () => {
    dispatch(filterAppsActions.setButtonOnboarding(""));
  };

  const handleMinChange = (event: ChangeEvent<HTMLInputElement>) => {
    let eventValue = event.target.value.replace(/\./g, "");

    if (parseInt(eventValue) > MAX_INPUT_VALUE) {
      eventValue = MAX_INPUT_VALUE.toString();
    }

    if (oscgte !== "") {
      dispatch(filterAppsDataActions.resetFilterAppDataSorted());
      dispatch(filterAppsDataActions.resetFilterAppDataSortedAll());
      dispatch(filterAppsDataActions.resetFilterAppData());
      dispatch(filterAppsActions.resetPageFilter());
      dispatch(filterAppsActions.resetPageFilterSidebar());
    }

    dispatch(filterAppsActions.setOscgte(eventValue));
    onSaveMin(Number(eventValue) || MIN_STEPS);

    if (isActiveOnboarding && eventValue === "") {
      dispatch(counterActions.removeFromArray("Onboarding Steps"));
      dispatch(filterAppsActions.deactiveOnboarding());
    }

    if (!isActiveOnboarding) {
      dispatch(counterActions.addToArray("Onboarding Steps"));
      dispatch(filterAppsActions.activeOnboarding());
    }

    resetStyles();
  };

  const handleMaxChange = (event: ChangeEvent<HTMLInputElement>) => {
    let eventValue = event.target.value.replace(/\./g, "");

    if (parseInt(eventValue) > MAX_INPUT_VALUE) {
      eventValue = MAX_INPUT_VALUE.toString();
    }

    if (osclte !== "") {
      dispatch(filterAppsDataActions.resetFilterAppDataSorted());
      dispatch(filterAppsDataActions.resetFilterAppDataSortedAll());
      dispatch(filterAppsDataActions.resetFilterAppData());
      dispatch(filterAppsActions.resetPageFilter());
      dispatch(filterAppsActions.resetPageFilterSidebar());
    }

    dispatch(filterAppsActions.setOsclte(eventValue));
    onSaveMax(Number(eventValue) || MAX_STEPS);

    if (isActiveOnboarding && eventValue === "") {
      dispatch(counterActions.removeFromArray("Onboarding Steps"));
      dispatch(filterAppsActions.deactiveOnboarding());
    }

    if (!isActiveOnboarding) {
      dispatch(counterActions.addToArray("Onboarding Steps"));
      dispatch(filterAppsActions.activeOnboarding());
    }

    resetStyles();
  };

  const formatNumber = (num: string | number): string => {
    if (num === MAX_STEPS || num === "Max") return "Max";
    if (num === MIN_STEPS || num === "Min") return "Min";
    return num.toString();
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const { charCode } = event;
    if (charCode < 48 || charCode > 57) {
      event.preventDefault();
    }
  };

  const handleClick = (
    minValue: string | number,
    maxValue: string | number,
    label: string,
  ) => {
    if (activeButtonOnboarding === label) {
      dispatch(filterAppsActions.setOscgte("Min"));
      dispatch(filterAppsActions.setOsclte("Max"));
      onSaveMin(0);
      onSaveMax(0);
      dispatch(filterAppsDataActions.resetFilterAppDataSorted());
      dispatch(filterAppsDataActions.resetFilterAppDataSortedAll());
      dispatch(filterAppsDataActions.resetFilterAppData());
      dispatch(filterAppsActions.resetPageFilter());
      dispatch(filterAppsActions.resetPageFilterSidebar());
      dispatch(filterAppsActions.setButtonOnboarding(null));

      if (isActiveOnboarding) {
        dispatch(counterActions.removeFromArray("Onboarding Steps"));
        dispatch(filterAppsActions.deactiveOnboarding());
      }

      return;
    }

    dispatch(filterAppsActions.setOscgte(minValue.toString()));
    dispatch(filterAppsActions.setOsclte(maxValue.toString()));
    dispatch(filterAppsActions.setButtonOnboarding(label));

    dispatch(filterAppsDataActions.resetFilterAppDataSorted());
    dispatch(filterAppsDataActions.resetFilterAppDataSortedAll());
    dispatch(filterAppsDataActions.resetFilterAppData());
    dispatch(filterAppsActions.resetPageFilter());
    dispatch(filterAppsActions.resetPageFilterSidebar());

    onSaveMin(minValue === "Min" ? MIN_STEPS : Number(minValue));
    onSaveMax(maxValue === "Max" ? MAX_STEPS : Number(maxValue));

    if (!isActiveOnboarding) {
      dispatch(counterActions.addToArray("Onboarding Steps"));
      dispatch(filterAppsActions.activeOnboarding());
    }
  };

  const handleReset = () => {
    if ((oscgte !== "Min" || osclte !== "Max") && !isFetchingFilter) {
      dispatch(filterAppsActions.setOscgte("Min"));
      dispatch(filterAppsActions.setOsclte("Max"));
      onSaveMin(0);
      onSaveMax(0);
      dispatch(filterAppsDataActions.resetFilterAppDataSorted());
      dispatch(filterAppsDataActions.resetFilterAppDataSortedAll());
      dispatch(filterAppsDataActions.resetFilterAppData());
      dispatch(filterAppsActions.resetPageFilter());
      dispatch(filterAppsActions.resetPageFilterSidebar());
      dispatch(filterAppsActions.setButtonOnboarding(null));

      if (isActiveOnboarding) {
        dispatch(counterActions.removeFromArray("Onboarding Steps"));
        dispatch(filterAppsActions.deactiveOnboarding());
      }
    }
  };

  const [isResetVisible, setIsResetVisible] = React.useState(false);

  React.useEffect(() => {
    setIsResetVisible(oscgte !== "Min" || osclte !== "Max" || activeButtonOnboarding !== null);
  }, [oscgte, osclte, activeButtonOnboarding]);

  return (
    <div className={styles.root}>
      <div className={styles.menuButtons}>
        <div className={styles.menuButtonTop}>
          <div
            className={
              activeButtonOnboarding === "Quick"
                ? styles.active
                : styles.disabled
            }
          >
            <button onClick={() => handleClick(0, 8, "Quick")}>
              <span>Quick</span>
            </button>
          </div>
          <div
            className={
              activeButtonOnboarding === "Medium"
                ? styles.active
                : styles.disabled
            }
          >
            <button onClick={() => handleClick(9, 16, "Medium")}>
              <span>Medium</span>
            </button>
          </div>
          <div
            className={
              activeButtonOnboarding === "Long"
                ? styles.active
                : styles.disabled
            }
          >
            <button onClick={() => handleClick(17, "Max", "Long")}>
              <span>Long</span>
            </button>
          </div>
        </div>
      </div>
      <div className={styles.customMenuButton}>
        <div className={styles.min}>
          <input
            type="text"
            value={formatNumber(oscgte)}
            onChange={handleMinChange}
            onKeyPress={handleKeyPress}
            maxLength={3}
            onFocus={() =>
              oscgte === "Min" && dispatch(filterAppsActions.setOscgte(""))
            }
            onBlur={() =>
              handleMinBlur(oscgte, filterAppsActions.setOscgte, onSaveMin)
            }
          />
        </div>
        <span className={styles.textTo}>to</span>
        <div className={styles.max}>
          <input
            type="text"
            value={formatNumber(osclte)}
            onChange={handleMaxChange}
            onKeyPress={handleKeyPress}
            maxLength={3}
            onFocus={() =>
              osclte === "Max" && dispatch(filterAppsActions.setOsclte(""))
            }
            onBlur={() =>
              handleMaxBlur(osclte, filterAppsActions.setOsclte, onSaveMax)
            }
          />
        </div>
      </div>
      <div className={styles.flex}>
        {rootActiveView === ACTIVE_VIEW_HOME &&
          <StoredCount onFocus={() => handleFocus(dispatch)} />
        }
        {isResetVisible && (
          <div onClick={handleReset} className={styles.reset}>
            <img src="/img/blackIcon/Close.svg" alt="resetImg" loading="lazy" />

          </div>
        )}
      </div>
    </div>
  );
};

export default OnboardingSteps;
