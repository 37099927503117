import { handleOpenVideoPage } from "@/app/libs/const/handleOpenVideoPage";
import styles from "./ModalSaveModeSharedGroupStore.module.scss";

interface ModalSaveModeSharedGroupStoreProps {
  item: {
    id: number;
    store_screen: {
      id: number;
      thumbnail: string;
    };
    app: {
      id: number;
      name: string;
      shortname: string;
      icon: string | null;
      slug: string;
    };
  };
  openGroupPhotoModal: (
    photo: string,
    photoId: number,
    name: string,
    ts: number,
    imageStoreId: number,
    appId?: number,
    slug?: string
  ) => void;
}

const ModalSaveModeSharedGroupStore = ({ 
  item,
  openGroupPhotoModal
}: ModalSaveModeSharedGroupStoreProps) => {
  return (
    <div 
      className={styles.groupPhotoCard}
    >
      <div className={styles.groupPhotoHeaderTitle}>
        <img
          src={item.app.icon || "/img/skeletonLogo.svg"}
          alt="groupPhotoIcon"
          className={styles.appIcon}
        />
        <div className={styles.groupPhotoTitle} style={{ width: "60%" }} onClick={() => handleOpenVideoPage(0, item.app.slug, "Shared Group Store", 1, item.app.id)}>
          <h3>{item.app.name}</h3>
          <p>{item.app.shortname}</p>
        </div>
      </div>
      <div 
        className={styles.groupPhotoScreen}
        onClick={() =>
          openGroupPhotoModal(
            item.store_screen.thumbnail,
            item.id,
            item.app.name,
            0,
            item.store_screen.id,
            item.app.id,
            item.app.slug
          )
        }
      >
        <div>
          <img src={item.store_screen.thumbnail} alt="groupScreenImg" />
        </div>
      </div>
    </div>
  );
};

export default ModalSaveModeSharedGroupStore;
