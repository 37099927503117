import React, { useState } from "react";
import { Outlet } from 'react-router-dom'
import { useDispatch, useSelector } from "react-redux";
import { getFilterAppsState } from "@/entities/filterApps";
import SortHidStarModal from "@/widgets/sortHidStarModal/ui/SortHidStarModal/SortHidStarModal";
import { getSortByCategoriesSlice } from "@/entities/sortByCategories/model/selectors/getSortByCategoriesState";
import { sortByCategoriesActions } from "@/entities/sortByCategories";
import ModalHidStarMenu from "@/shared/ui/ModalHidStarMenu/ModalHidStarMenu";
import styles from "@/widgets/modalHirStar/ui/ModalHirStar/ModalHirStar.module.scss";
import { getZoomState } from '@/entities/zoom';

const HiddenStarredBaseView = () => {

  const dispatch = useDispatch();
  const { zoom } = useSelector(getZoomState);
  const [activeIndex, setActiveIndex] = React.useState<number>(0);
  const [sortDirectionHidStar, setSortDirectionHidStar] = useState("desc");
  const { filterSortHidStar } = useSelector(getFilterAppsState);
  const { sortByHirStar, hidStarOpen, indexSortHidStar } = useSelector(
    getSortByCategoriesSlice,
  );
  const containerUpRef = React.useRef<HTMLDivElement>(null);

  const orderHidStar =
    filterSortHidStar !== ""
      ? `${sortDirectionHidStar === "desc" ? "-" : ""}${filterSortHidStar}`
      : "-created_at";

  const handleItemClick = (index: number) => { setActiveIndex(index) };
  const handleSortDirectionChange = (direction: "asc" | "desc") => { setSortDirectionHidStar(direction) };

  return (
    <div className={styles.modalHirStarContainer}>
      <div className={styles.modalHirStar}>
        <div ref={containerUpRef} className={styles.root}>
          {/* <ArrowUp scrollToTop={scrollToTop} /> */}
          <div className={styles.header}>
            <div className={styles.headerMenu}>
              <ModalHidStarMenu
                activeIndex={activeIndex}
                handleItemClick={handleItemClick}
              />
            </div>
            <div className={styles.headerSideMenu}>
              <div
                className={styles.sortByContainer}
                onClick={() =>
                  dispatch(sortByCategoriesActions.toggleHidStarOpen())
                }
              >
                <div className={styles.sortBy}>
                  <h1 style={{ fontWeight: 400 }}>
                    {"Sort by"}{" "}
                    <span className={styles.span} style={{ fontWeight: 700 }}>
                      {sortByHirStar}
                    </span>
                  </h1>
                </div>
              </div>
              {indexSortHidStar !== 0 && (
                <div className={styles.containerSortDesc}>
                  <div
                    onClick={
                      sortDirectionHidStar === "desc"
                        ? () => { }
                        : () => handleSortDirectionChange("desc")
                    }
                    className={`${styles.desc} ${sortDirectionHidStar === "desc" ? styles.descActive : ""
                      }`}
                  >
                    <p>{indexSortHidStar === 2 ? "Newer" : "Descending"}</p>
                  </div>
                  <div
                    onClick={
                      sortDirectionHidStar !== "desc"
                        ? () => { }
                        : () => handleSortDirectionChange("asc")
                    }
                    className={`${styles.asc} ${sortDirectionHidStar === "desc" ? "" : styles.ascActive
                      }`}
                  >
                    <p>{indexSortHidStar === 2 ? "Older" : "Ascending"}</p>
                  </div>
                </div>
              )}
            </div>
          </div>
          <Outlet context={{ zoom: zoom, sortkey: orderHidStar }} />
          {hidStarOpen && <SortHidStarModal setSortDirection={setSortDirectionHidStar} />}
        </div>
      </div>
    </div>
  );
};

export { HiddenStarredBaseView };
