import React, { forwardRef, useState, useRef, useEffect } from "react";
import {
  IAppSavedGroup,
  IAppSaveStoreScreensData,
} from "@/features/auth/types/auth";
import styles from "./ModalSaveModeStore.module.scss";
import { RightClickModal } from "../RightClickModal/RightClickModal";

interface ModalSaveModeStoreProps {
  setHoveredStoreScreenId: React.Dispatch<React.SetStateAction<number | null>>;
  item: IAppSaveStoreScreensData;
  setSelectActive: React.Dispatch<React.SetStateAction<boolean>>;
  handlePhotoSelect: (id: number, url: string, videoId: number) => void;
  selectActive: boolean;
  selectedPhotos: Set<{
    id: number;
    url: string;
    videoId: number;
  }>;
  setName: React.Dispatch<React.SetStateAction<string>>;
  handleClickPhone: (
    timestamp: number,
    blank: boolean | undefined,
    name: string,
    appId: int,
    slug: string
  ) => void;
  openModal: (
    image: string,
    name: string,
    ts: number,
    id: number,
    idVideo: number,
    type: string,
    imageStoreId: number,
    idStore?: number,
    appId?: number,
    slug?: string,
    item?: IAppSaveStoreScreensData | undefined,
  ) => void;
  matchingGroups: IAppSavedGroup[] | undefined;
  setModalOpenGroups: React.Dispatch<React.SetStateAction<boolean>>;
  setMatchingGroups: React.Dispatch<React.SetStateAction<IAppSavedGroup[]>>;
}

const ModalSaveModeStore = forwardRef<HTMLDivElement, ModalSaveModeStoreProps>(({
  setHoveredStoreScreenId,
  item,
  setSelectActive,
  handlePhotoSelect,
  selectActive,
  selectedPhotos,
  setName,
  handleClickPhone,
  openModal,
  matchingGroups,
  setModalOpenGroups,
  setMatchingGroups,
}, ref) => {
  const [rightClickModalVisible, setRightClickModalVisible] = useState(false);
  const [rightClickPosition, setRightClickPosition] = useState({ x: 0, y: 0 });
  const modalMoreRef = useRef<HTMLDivElement>(null);

  const isSelected =
    selectActive &&
    [...selectedPhotos].some((photo) => photo.id === Number(item.id));

  const handlePhotoClick = () => {
    if (selectActive) {
      setName(item.app.name);
      handlePhotoSelect(
        Number(item.id),
        item.store_screen.thumbnail,
        item.app.id,
      );
    } else {
      setName(item.app.name);
      openModal(
        item.store_screen.thumbnail!,
        item.app.name,
        0,
        Number(item.id),
        item.app.id,
        "Store",
        item.store_screen.id,
        item.id,
        item.app.id,
        item.app.slug,
        item,
      );
    }
  };

  const handleRightClick = (e: React.MouseEvent) => {
    e.preventDefault();
    setRightClickPosition({ x: e.clientX, y: e.clientY });
    setRightClickModalVisible(true);
  };

  const closeRightClickModal = () => {
    setRightClickModalVisible(false);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        modalMoreRef.current &&
        !modalMoreRef.current.contains(event.target as Node)
      ) {
        setModalOpenGroups(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div
      ref={ref}
      className={`${styles.root} ${isSelected ? styles.selectedRoot : ""}`}
      onMouseEnter={() => setHoveredStoreScreenId(item.id)}
      onMouseLeave={() => setHoveredStoreScreenId(null)}
      onContextMenu={handleRightClick}
    >
      <div
        onClick={() => {
          setSelectActive(true);
          handlePhotoSelect(
            Number(item.id),
            item.store_screen.thumbnail,
            item.app.id,
          );
        }}
        className={isSelected ? styles.hoverElementActive : styles.hoverElement}
      >
        {isSelected && (
          <img
            src="/img/whiteIcon/Check.svg"
            alt="checkCircleImg"
          />
        )}
      </div>

      <div
        onMouseEnter={() => setName(item.app.name)}
        onClick={
          selectActive
            ? handlePhotoClick
            : () => handleClickPhone(0, true, item.app.name, item.app.id, item.app.slug)
        }
        className={styles.photoItemTitle}
      >
        <img
          src={item.app.icon ? item.app.icon : "/img/skeletonLogo.svg"}
          alt="appIcon"
          loading="lazy"
        />
        <div className={styles.photoItemContent}>
          <h3>{item.app.name}</h3>
          <p>{item.app.shortname}</p>
        </div>
      </div>

      <div
        className={`${styles.photoContainer} ${isSelected ? styles.selectedPhotoContainer : ""}`}
      >
        <div
          className={styles.containerPhoto}
          onClick={handlePhotoClick}
          style={{
            cursor: selectActive ? "pointer" : "zoom-in",
          }}
        >
          <img
            src={item.store_screen.thumbnail!}
            alt="screen"
            className={`${styles.photo} ${isSelected ? styles.selectedPhoto : ""}`}
            loading="lazy"
          />
        </div>
      </div>

      <div className={styles.photoContainerGroups}>
        {matchingGroups?.slice(0, 5)?.map((group, groupIndex) => (
          <div
            key={groupIndex}
            className={styles.photoGroupItem}
          >
            <p>{group.name}</p>
          </div>
        ))}
        {matchingGroups && matchingGroups.length > 5 && (
          <div
            ref={modalMoreRef}
            onClick={() => {
              setModalOpenGroups(true);
              setMatchingGroups(matchingGroups);
            }}
            className={styles.photoGroupItemMore}
          >
            <p className={styles.moreModalSaveModeButton}>More...</p>
          </div>
        )}
      </div>

      {rightClickModalVisible && (
        <RightClickModal
          x={rightClickPosition.x}
          y={rightClickPosition.y}
          onClose={closeRightClickModal}
          imageUrl={item.store_screen.thumbnail!}
          imageId={item.id}
          timestamp={0}
          isStoreScreen={true}
          handleOpenInNewTab={() => handleClickPhone(0, true, item.app.name, item.app.id, item.app.slug)}
          avsID={item.app.id}
          refetchData={() => console.log('Refetch data')}
          imageType="store"
        />
      )}
    </div>
  );
});

export default ModalSaveModeStore;
