import axios from 'axios'
import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import InputAuth from "@/shared/ui/InputAuth/InputAuth";
import styles from "./AuthReg.module.scss";
import useRegister from "../../libs/hooks/useRegister";
import { toast } from "react-toastify";
import { validateForm } from '@/Validation/Register';
import { userActions } from "@/entities/user";
import { useDispatch } from "react-redux";

const rightSideContentLabel = ({ text }) => {
  return (
    <div className={styles.rightSideContentLabel}>
      {/* <img src="/img/blackIcon/analysis-text-link.svg" alt="check" /> */}
      <p>{text}</p>
      <div className={styles.rightSideContentLabelOverlay}>
      </div>
    </div>
  )
}


const AuthReg = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const from = location.state?.from || "/";
  const inviteKey = localStorage.getItem('invite') || undefined
  const [username, setUsername] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  //const [passwordConfirm, setPasswordConfirm] = React.useState("");
  const [itemFromLS, setItemFromLS] = React.useState(localStorage.getItem(""));
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [isSubmitted, setIsSubmitted] = useState(false);
  const { isLoggingIn, handleRegister, handleNavigate } = useRegister(from);
  const [referral, setReferral] = useState();
  const [referralCouponId, setReferralCouponId] = useState();

  useEffect(() => {
    window.rewardful("ready", () => {
      setReferral(window.Rewardful.referral);
      if (window.Rewardful.coupon) {
        setReferralCouponId(window.Rewardful.coupon.id);
      }
    });
  }, []);

  const inputFields = [
    {
      placeholder: "Enter email address",
      type: "email",
      value: email,
      field: (value: string) => {
        setEmail(value);
        setUsername(value);
        if (isSubmitted) {
          const validationErrors = validateForm({
            email: value,
            password
          });
          setErrors(validationErrors || {});
        }
      },
      autocomplete: "email",
      style: { border: errors.email ? "1px solid red" : "1px solid #dcdcdc" },
      error: errors.email,
    },
    {
      placeholder: "Enter password",
      type: "password",
      value: password,
      field: (value: string) => {
        setPassword(value);
        if (isSubmitted) {
          const validationErrors = validateForm({
            email,
            password: value
          });
          setErrors(validationErrors || {});
        }
      },
      autocomplete: "new-password",
      style: { border: errors.password ? "1px solid red" : "1px solid #dcdcdc" },
      error: errors.password,
    },
    /* Commenting out password confirmation field
    {
      placeholder: "Confirm password",
      type: "password",
      value: passwordConfirm,
      field: (value: string) => {
        setPasswordConfirm(value);
        if (isSubmitted) {
          const validationErrors = validateForm({
            email,
            password,
            passwordConfirm: value
          });
          setErrors(validationErrors || {});
        }
      },
      autocomplete: "new-password",
      style: { border: errors.passwordConfirm ? "1px solid red" : "1px solid #dcdcdc" },
      error: errors.passwordConfirm,
    },
    */
  ];

  function postForm(action, data) {
    const f = document.createElement('form')
    f.method = 'POST'
    f.action = action

    for (const key in data) {
      const d = document.createElement('input')
      d.type = 'hidden'
      d.name = key
      d.value = data[key]
      f.appendChild(d)
    }
    document.body.appendChild(f)
    f.submit()
  }

  useEffect(() => {
    const url = `${import.meta.env.VITE_API_BASE_URL}/csrf/`
    axios.get(url, { withCredentials: true })
      .then((resp) => {

      })
  }, [])

  function getCookie(name) {
    let cookieValue = null
    if (document.cookie && document.cookie !== '') {
      const cookies = document.cookie.split(';')
      for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim()
        // Does this cookie string begin with the name we want?
        if (cookie.substring(0, name.length + 1) === (name + '=')) {
          cookieValue = decodeURIComponent(cookie.substring(name.length + 1))
          break
        }
      }
    }
    return cookieValue
  }

  function getCSRFToken() {
    return getCookie('csrftoken')
  }


  const getCallBackUrl = () => {

    //const callbackurl = 'http://localhost:10000/oauth/google/callback'
    const callbackurl = `${import.meta.env.VITE_BASE_URL_WEB}/oauth/google/callback`

    let url = new URL(callbackurl);

    if (inviteKey) {
      url.searchParams.set('invite', inviteKey);

    }

    return url.toString()
  }

  const handleGoogleLogin = () => {
    //const url = 'http://localhost:10000/_allauth/browser/v1/auth/provider/redirect'
    const url = `${import.meta.env.VITE_API_BASE_URL}/_allauth/browser/v1/auth/provider/redirect`
    localStorage.setItem('showBillingAfterVerification', 'true');

    postForm(url, {
      provider: 'google', //providerId,
      process: 'login',
      callback_url: getCallBackUrl(), //callbackURL,
      //callback_url: 'http://localhost:10000/accounts/google/login/callback/', //callbackURL,
      csrfmiddlewaretoken: getCSRFToken()
    })
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLFormElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
      if (!email || !password) {
        toast.error("Please fill in all required fields");
        return;
      }
      handleRegister(
        username,
        email,
        password,
        undefined,
        inviteKey,
        referral,
        referralCouponId,
        e);
    }
  };

  useEffect(() => {
    setItemFromLS(localStorage.getItem("btnName"));
  }, []);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitted(true);

    const validationErrors = validateForm({ email, password });
    if (validationErrors) {
      setErrors(validationErrors);
      return;
    }

    handleRegister(
      username,
      email,
      password,
      undefined,
      inviteKey,
      referral,
      referralCouponId,
      e);
  };

  const handleClickLogo = () => {
    dispatch(userActions.setWithoutLoading(false));
    navigate("/", { state: { fromLogin: true } });
    window.scrollTo(0, 0);
    window.location.reload();
  };

  return (
    <div className={styles.realRoot}>
      <div className={styles.root}>
        {itemFromLS && (
          <h1 className={styles.headerOnForm}>
            To {itemFromLS} please create an account or login
          </h1>
        )}

        <button onClick={handleClickLogo} className={styles.logoButton}>
          <img src="/img/headerLogoMain.svg" alt="headerAuthImg" loading="lazy" />
        </button>

        {/* Conditional rendering based on invite key */}
        {!inviteKey ? (
          <>
            <h1>Upgrade to pro</h1>
            <h2 className={styles.price}>$25<span>/Month</span></h2>
            <p className={styles.unlockpremium}>Unlock premium features, advanced tools, and priority support</p>
          </>
        ) : (
          <h1>Create your account</h1>
        )}

        <div className={styles.auth}>
          <div onClick={handleGoogleLogin} className={styles.googleButton}>
            <img src="/img/googleIcon.svg" alt="googleImg" loading="lazy" />
            <p>Sign in with google to complete payment</p>
          </div>

          <div className={styles.orContinueWithEmailWrapper}>
            <hr />
            <p className={styles.textOr}>or continue with email</p>
            <hr />
          </div>


          <form
            onSubmit={handleSubmit}
            onKeyDown={handleKeyDown}
            className={styles.form}
          >
            <div className={styles.boxInput}>
              {inputFields.map((input, index) => (
                <div key={index} className={styles.inputWrapper}>
                  <InputAuth
                    placeholder={input.placeholder}
                    type={input.type}
                    value={input.value}
                    onChange={input.field}
                    autocomplete={input.autocomplete}
                    style={input.style}
                  />
                  {input.error && (
                    <span className={styles.errorMessage}>{input.error}</span>
                  )}
                </div>
              ))}
            </div>

            <button
              disabled={isLoggingIn}
              className={`${styles.formButtonLogin} ${isLoggingIn ? styles.isDisabled : ""}`}
            >
              {inviteKey ? 'Register' : 'Proceed to payment'}
            </button>
            <div className={styles.routeLogin}>
              <p className={styles.alreadyHaveAccount}>Already have an account?</p>
              <Link to="/login">
                <button className={styles.login} onClick={handleNavigate}>
                  Login
                </button>
              </Link>
            </div>
          </form>
        </div>
      </div>
      <div className={styles.rightSideContent}>

        <div className={styles.rightSideContentText}>
          <h1>Unlock all apps designs & additional features</h1>
          <p>Get access to 1500+ top apps & 120k+ screens, advanced AI Search</p>


          <div className={styles.rightSideLabelList}>
            {rightSideContentLabel({ text: 'Full Access to All Apps' })}
            {rightSideContentLabel({ text: 'Revenue & Install metrics' })}
            {rightSideContentLabel({ text: 'Weekly New Apps' })}
            {rightSideContentLabel({ text: 'Advanced Search & filters' })}
            {rightSideContentLabel({ text: 'All free tools' })}
            {rightSideContentLabel({ text: 'Web onboardings' })}
            {rightSideContentLabel({ text: 'Complete App Videos' })}
          </div>

        </div>
      </div>
    </div>
  );
};

export default AuthReg;

