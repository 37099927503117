import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isModalOpen: false,
  isOpen: false,
};

const additionlInfoModalSlice = createSlice({
  name: "additionalInfoModal",
  initialState,
  reducers: {
    openModal: (state) => {
      state.isModalOpen = true;
    },
    closeModal: (state) => {
      state.isModalOpen = false;
    },
    toggleModal: (state) => {
      state.isModalOpen = !state.isModalOpen;
    },
    openItem: (state) => {
      state.isOpen = true;
    },
    closeItem: (state) => {
      state.isOpen = false;
    },
    toggleItem: (state) => {
      state.isOpen = !state.isOpen;
    },
  },
});

export const {
  actions: additionlInfoModalActions,
  reducer: additionlInfoModalReducer,
} = additionlInfoModalSlice;
