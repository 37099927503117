// react
import React from "react";
// react-day-picker
import { DayPicker } from "react-day-picker";
// date-fns
import { format } from "date-fns";
import { enUS } from "date-fns/locale";
// styles
import { useDispatch } from "react-redux";
import styles from "./CalendarRelease.module.scss";
import ModalYearCalendar from "@/shared/ui/ModalYearCalendar/ModalYearCalendar";
import { filterAppsActions } from "@/entities/filterApps";

const customWeekdays = {
  longhand: [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ],
  shorthand: ["S", "M", "T", "W", "T", "F", "S"],
};

const customLocale = {
  ...enUS,
  localize: {
    ...enUS.localize,
    day: (n: number) => customWeekdays.shorthand[n],
  },
};

interface CalendarProps {
  setSelectedDate: (date: Date | undefined) => void;
  month: Date;
  handlePreviousMonth: () => void;
  handleNextMonth: () => void;
  yearCalendarMin?: boolean;
  setYearCalendarMin?: (state: boolean) => void;
  yearCalendarMax?: boolean;
  setYearCalendarMax?: (state: boolean) => void;
  isActiveCalendarMin?: boolean;
  isActiveCalendarMax?: boolean;
  selectedYearMaxRelease?: number;
  selectedDataMax?: string | Date;
  selectedDataMin?: string | Date;
  selectedDataRangeMax?: string | Date;
  currentYear?: number;
  setMonthMax?: (state: Date) => void;
  selectedYearMinRelease?: number;
  selectedDataRangeMin?: string | Date;
  setMonthMin?: (state: Date) => void;
}

const CalendarRelease: React.FC<CalendarProps> = ({
  setSelectedDate,
  month,
  handlePreviousMonth,
  handleNextMonth,
  yearCalendarMin,
  setYearCalendarMin,
  yearCalendarMax,
  setYearCalendarMax,
  isActiveCalendarMin,
  isActiveCalendarMax,
  selectedYearMaxRelease,
  selectedDataMax,
  selectedDataMin,
  currentYear,
  setMonthMax,
  selectedYearMinRelease,
  setMonthMin,
}) => {
  const handleClickYear = () => {
    if (isActiveCalendarMin) {
      setYearCalendarMin!(!yearCalendarMin);
    }

    if (isActiveCalendarMax) {
      setYearCalendarMax!(!yearCalendarMax);
    }
  };

  const isDateDisabled = (date: Date) => {
    if (isActiveCalendarMin && selectedDataMax !== "Max") {
      return date > new Date(selectedDataMax!);
    }
    if (isActiveCalendarMax && selectedDataMin !== "Min") {
      return date < new Date(selectedDataMin!);
    }
    return false;
  };

  const dispatch = useDispatch();

  const convertToDate = (
    value: string | Date | undefined,
  ): Date | undefined => {
    if (typeof value === "string") {
      return new Date(value);
    }
    return value instanceof Date ? value : undefined;
  };

  const onDateSelectMin = (newDate: Date) => {
    console.log(selectedDataMax, "selectedDataMax");

    const maxDate =
      selectedDataMax === "Max" ? new Date() : new Date(selectedDataMax!);
    console.log(maxDate, "maxDate");

    if (newDate <= maxDate) {
      dispatch(filterAppsActions.setSelectedDataMin(newDate));
      setMonthMin!(newDate);
      dispatch(
        filterAppsActions.setReleasedFrom(format(newDate, "yyyy-MM-dd")),
      );
    }
  };

  const onDateSelectMax = (newDate: Date) => {
    const minDate =
      selectedDataMin === "Min" ? new Date(0) : new Date(selectedDataMin!);
    if (newDate >= minDate) {
      dispatch(filterAppsActions.setSelectedDataMax(newDate));
      setMonthMax!(newDate);
      dispatch(filterAppsActions.setReleasedTo(format(newDate, "yyyy-MM-dd")));
    }
  };

  return (
    <div className={styles.root}>
      <div className={styles.content}>
        <DayPicker
          mode="single"
          selected={convertToDate(
            isActiveCalendarMin ? selectedDataMin : selectedDataMax,
          )}
          onSelect={setSelectedDate}
          month={month}
          onMonthChange={isActiveCalendarMax ? setMonthMax : setMonthMin}
          className={`${styles.datePicker}`}
          locale={customLocale}
          showOutsideDays
          disabled={isDateDisabled}
          components={{
            Caption: ({ displayMonth }) => (
              <div className={styles.nav}>
                <button
                  className={styles.navButtonLeft}
                  onClick={handlePreviousMonth}
                >
                  <img
                    src="/img/blackIcon/ChevronLeft.svg"
                    alt="calendarArrowIcon"
                    loading="lazy"
                  />
                </button>
                <div onClick={handleClickYear} className={styles.dateContainer}>
                  <span>
                    {format(displayMonth, "MMMM yyyy", {
                      locale: customLocale,
                    })}
                  </span>
                  <img
                    className={styles.arrowDate}
                    src="/img/blackIcon/DropDown.svg"
                    alt="arrowReleaseDateImg"

                    loading="lazy"
                  />
                </div>
                <button
                  className={styles.navButtonRight}
                  onClick={handleNextMonth}
                >
                  <img
                    src="/img/blackIcon/ChevronLeft.svg"
                    alt="calendarArrowIcon"
                    loading="lazy"
                  />
                </button>
              </div>
            ),
          }}
        />
        {yearCalendarMin && (
          <ModalYearCalendar
            selectedYear={selectedYearMinRelease!}
            selectedDate={selectedDataMin!}
            currentYear={currentYear!}
            onDateSelect={onDateSelectMin}
            setYearCalendar={setYearCalendarMin}
            setUpdate={(date) =>
              dispatch(filterAppsActions.setReleasedFrom(date))
            }
          />
        )}
        {yearCalendarMax && (
          <ModalYearCalendar
            selectedYear={selectedYearMaxRelease!}
            selectedDate={selectedDataMax!}
            currentYear={currentYear!}
            onDateSelect={onDateSelectMax}
            setYearCalendar={setYearCalendarMax}
            setUpdate={(date) =>
              dispatch(filterAppsActions.setReleasedTo(date))
            }
          />
        )}
      </div>
    </div>
  );
};

export default CalendarRelease;
