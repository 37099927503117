import mixpanel from 'mixpanel-browser';


if (import.meta.env.VITE_MIXPANEL_TOKEN) {
  mixpanel.init(import.meta.env.VITE_MIXPANEL_TOKEN, {
    debug: false,
  });
} else {
  console.warn('Mixpanel token not found in environment variables');
}

export default mixpanel; 