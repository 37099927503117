import React, { useState, useEffect } from 'react';
import Modal from "react-modal";
import { useNavigate } from "react-router-dom";
import { useInView } from "react-intersection-observer";
import ContentLoader from "react-content-loader";
import { IoIosCheckmarkCircle } from "react-icons/io";
import { IImages } from "@/Views/Virtual/PhoneHomePageHorisontalNew";
import { useDeletePointMutation, useSavedPointsMutation } from "@/features/auth/api/authAPI";
import styles from "./PhotoItemNew.module.scss";
import { useMe, useCurrentOrganization } from '@/Hooks'
import Spinner from '@/shared/ui/Spinner/Spinner';
import { produce } from 'immer'
import mixpanel from '@/shared/lib/mixpanel';
import { toast } from 'react-toastify';

interface PhotoItemProps {
  isPaywallOnly?: boolean;
  setAppVideoScreens,
  image: IImages; // AppXScreen object
  idVideo: number;
  handleClickPhone: (timestamp: number, videoId?: number, blank?: boolean) => void;
  handleImageLoad: () => void;
  onInView: (imageId: string, inView: boolean) => void;
  isActiveBookmark: boolean;
  apiDataVideosScreens: any;
  hasMore: boolean;
  isLastItem: boolean;
  spinnerRef: React.RefObject<HTMLDivElement>;
  loadMore: () => void;
  currentPage: number;
  handleOpenBillingPage: () => void;
  handleCloseBillingPage: () => void;
  onContextMenu: (event: React.MouseEvent, imageId: string, timestamp: number, imageUrl: string, position: { x: number; y: number }) => void;
  refetchData: () => void;
  ScreenNotBlurredID?: number;
}

const PhotoItemNew = (
  {
    isPaywallOnly,
    setAppVideoScreens,
    image,
    idVideo,
    handleClickPhone,
    handleImageLoad,
    onInView,
    isActiveBookmark,
    apiDataVideosScreens,
    hasMore,
    isLastItem,
    spinnerRef,
    loadMore,
    currentPage,
    handleOpenBillingPage,
    onContextMenu,
    refetchData,
    ScreenNotBlurredID,
  }: PhotoItemProps
): JSX.Element => {

  const currentOrganization = useCurrentOrganization()
  const [selectedImage, setSelectedImage] = React.useState<string | undefined>(
    undefined,
  );

  const [selectedImageId, setSelectedImageId] = React.useState<null | number>(
    null,
  );
  const [imageIndex, setImageIndex] = React.useState<number | null>(null);
  const [isHover, setIsHover] = useState(false);

  const closeImageModal = () => {
    setSelectedImage(undefined);
    setImageIndex(null);
  };

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (isLoading && apiDataVideosScreens) {
      setIsLoading(false);
      if (imageIndex !== null) {
        const nextIndex = imageIndex + 1;
        if (nextIndex < apiDataVideosScreens.length) {
          const nextImage = apiDataVideosScreens[nextIndex];
          setSelectedImage(nextImage.screen);
          setSelectedImageId(nextImage.id);
          setImageIndex(nextIndex);
        }
      }
    }
  }, [apiDataVideosScreens, currentPage]);

  const [currentModalImage, setCurrentModalImage] = useState<IImages | null>(null);
  const [savedPoint, { isLoading: isSaving }] = useSavedPointsMutation();
  const [deletePoint, { isLoading: isDeleting }] = useDeletePointMutation();
  const [isPointActionPending, setIsPointActionPending] = useState(false);
  const [actionCooldown, setActionCooldown] = useState(false);

  const handleClick = async (event: React.MouseEvent, timestamp: string) => {
    event.stopPropagation();
    const isUserLogin = localStorage.getItem("access_token");

    if (isUserLogin) {
      if (isPointActionPending || isSaving || isDeleting || actionCooldown) {
        return;
      }

      setIsPointActionPending(true);
      setActionCooldown(true);
      const imageToSave = image;

      try {
        if (imageToSave.is_saved && imageToSave.is_saved_pk) {
          // Optimistically update UI
          const updateState = (draft: IImages[]) => {
            const index = draft.findIndex((item) => item.id === imageToSave.id);
            if (index !== -1) {
              draft[index].is_saved = false;
              draft[index].is_saved_pk = undefined;
            }
          };

          setAppVideoScreens(produce(updateState));

          setCurrentModalImage((prevImage: IImages | null) =>
            prevImage ? { ...prevImage, is_saved: false, is_saved_pk: undefined } : null
          );

          await deletePoint(imageToSave.is_saved_pk).unwrap();

          if (!isPointActionPending) {
            try {
              await refetchData();
            } catch (error) {
              console.log('[PhotoItemNew] Refetch after remove failed', { error });
            }
          }

          if (me?.email) {
            mixpanel.identify(me.email);
            setTimeout(() => {
              mixpanel.people.increment('total_unsaved_screens', 1);
            }, 100);
          }

          toast.success("Image removed from saved items");
        } else {
          // Save operation
          const updateStateWithId = (draft: IImages[]) => {
            const index = draft.findIndex((item) => item.id === imageToSave.id);
            if (index !== -1) {
              draft[index].is_saved = true;
            }
          };

          setAppVideoScreens(produce(updateStateWithId));

          try {
            const sp = await savedPoint({
              organizationUuid: currentOrganization.uuid,
              app_video: idVideo,
              ts: timestamp,
            }).unwrap();

            const updateStateWithSavedPoint = (draft: IImages[]) => {
              const index = draft.findIndex((item) => item.id === imageToSave.id);
              if (index !== -1) {
                draft[index].is_saved = true;
                draft[index].is_saved_pk = sp.id;
              }
            };

            setAppVideoScreens(produce(updateStateWithSavedPoint));

            setCurrentModalImage((prevImage: IImages | null) =>
              prevImage ? { ...prevImage, is_saved: true, is_saved_pk: sp.id } : null
            );

            toast.success("Image saved successfully");

            if (!isPointActionPending) {
              try {
                await refetchData();
              } catch (error) {
                console.log('[PhotoItemNew] Refetch after save failed', { error });
              }
            }

            mixpanel.track('Image saved', {
              idApp: idVideo,
              app_name: image.name,
              From: 'HomePage',
              idscreen: image.id,
              UserEmail: me?.email || '',
              OrganizationID: currentOrganization?.uuid || '',
            });

            if (me?.email) {
              mixpanel.identify(me.email);
              setTimeout(() => {
                mixpanel.people.increment('total_saved_screens', 1);
              }, 100);
            }

            //toast.success("Image saved successfully");
          } catch (error: any) {
            // Check for "Already saved" error
            if (error?.data?.non_field_errors?.includes("Already saved")) {
              try {
                await refetchData();
              } catch (refetchError) {
                console.log('[PhotoItemNew] Refetch after "Already saved" error failed', { refetchError });
              }
            }
            throw error; // Re-throw to be caught by outer catch block
          }
        }
      } catch (error: any) {
        toast.error(error.message || "Operation failed");
      } finally {
        setIsPointActionPending(false);
        setTimeout(() => setActionCooldown(false), 500);
      }
    } else {
      localStorage.removeItem("access_token");
      localStorage.setItem("btnName", "save a point");
      navigate("/login");
      window.scrollTo(0, 0);
    }
  };

  const handleNextImage = () => {
    if (imageIndex !== null && apiDataVideosScreens) {
      const nextIndex = imageIndex + 1;

      if (nextIndex >= apiDataVideosScreens.length) {
        if (hasMore) {
          setIsLoading(true);
          loadMore();
          return;
        } else {
          setImageIndex(0);
          setSelectedImage(apiDataVideosScreens[0].screen);
          setSelectedImageId(apiDataVideosScreens[0].id);
          setCurrentModalImage(apiDataVideosScreens[0]);
          return;
        }
      }

      const nextImage = apiDataVideosScreens[nextIndex];
      setSelectedImage(nextImage.screen);
      setSelectedImageId(nextImage.id);
      setImageIndex(nextIndex);
      setCurrentModalImage(nextImage);
    }
  };

  const handlePrevImage = () => {
    if (imageIndex !== null && apiDataVideosScreens) {
      const prevIndex = (imageIndex - 1 + apiDataVideosScreens.length) % apiDataVideosScreens.length;
      const prevImage = apiDataVideosScreens[prevIndex];
      setSelectedImage(prevImage.screen);
      setSelectedImageId(prevImage.id);
      setImageIndex(prevIndex);
      setCurrentModalImage(prevImage);
    }
  };


  const [isActiveSave, setIsActiveSave] = useState(false);
  const [imageLoaded, setImageLoaded] = useState(false);
  const { ref: refIntersection, inView } = useInView({
    threshold: 0,
    triggerOnce: false,
    rootMargin: "0px 100px 0px 0px",
  });

  const navigate = useNavigate();
  const { me } = useMe()

  useEffect(() => {
    onInView(image.id.toString(), inView);
  }, []);

  useEffect(() => {
    // todo: red: its wrong to handle these events here insdie each photo image
    const handleKeyDown = (event: KeyboardEvent) => {
      if (selectedImage) {
        if (event.key === "ArrowRight") {
          handleNextImage();
        } else if (event.key === "ArrowLeft") {
          handlePrevImage();
        }
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [selectedImage, handleNextImage, handlePrevImage]);

  const handleExpandClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    setSelectedImage(image.screen);
    setSelectedImageId(image.id);
    setImageIndex(apiDataVideosScreens.findIndex(item => item.id === image.id) ?? 0);
    setCurrentModalImage(image);
  };

  const handleContextMenu = (
    event: React.MouseEvent,
    imageId: string,
    timestamp: number,
    imageUrl: string
  ) => {
    event.preventDefault();
    event.stopPropagation();

    // Calculate position relative to the viewport
    const x = event.clientX + window.scrollX;
    const y = event.clientY + window.scrollY;

    onContextMenu(event, imageId, timestamp, imageUrl, { x, y });
  };

  return (
    <div ref={refIntersection}
      data-image-id={image.id}
      style={{ pointerEvents: "auto" }}
      onMouseEnter={() => setIsActiveSave(true)}
      onMouseLeave={() => setIsActiveSave(false)}
      onContextMenu={(e) => handleContextMenu(e, image.id.toString(), Number(image.timestamp), image.screen)}>
      <div
        className={image.is_blurry ? styles.screenItemContainerNotLogged : ""}
      >
        <div
          className={image.is_blurry ? styles.screenItemNotLogged : ""}
          onClick={() => handleClickPhone(Number(image.timestamp), image.app_video)}
        >
          {/* {!isActiveBookmark && ( */}
          {image.is_blurry && (
            <div className={styles.screenItemContentNotLogged}>
              <div className={styles.titleNotLogged}>
                <p className={styles.titleNotLoggedPro}>
                  <span>Pro</span>
                </p>
                <p className={styles.titleNotLoggedMember}>
                  Member's Only
                </p>
              </div>
              <button
                onClick={handleOpenBillingPage}
                className={styles.buttonNotLogged}
              >
                <span>Unlock Pro</span>
              </button>
            </div>
          )}
          <div
            className={`${styles.screenItem} ${isPaywallOnly && image.label !== "paywall" ? styles.paywallDimmed : ''
              } ${ScreenNotBlurredID !== undefined && ScreenNotBlurredID !== image.id ? styles.paywallDimmed : ''
              }`}
            onClick={() => handleClickPhone(Number(image.timestamp), image.app_video)}
            tabIndex={0}
          >
            <img
              src={image.screen}
              alt="Screen"
              className={styles.img}
              onLoad={() => {
                setImageLoaded(true);
                handleImageLoad();
              }}
              style={{ display: imageLoaded ? 'block' : 'none' }}
              loading="eager"
            />
            {!imageLoaded && (
              <ContentLoader
                speed={2}
                width={254}
                height={581.4}
                viewBox="0 0 254 581.4"
                backgroundColor="#f3f3f3"
                foregroundColor="#ecebeb"
              >
                <rect
                  x="0"
                  y="0"
                  rx="7"
                  ry="7"
                  width="254"
                  height="581.4"
                />
              </ContentLoader>
            )}
            {isActiveBookmark && isActiveSave && (
              <button onClick={handleExpandClick} className={styles.bigscreenContainer}>
                <div className={`${styles.bigscreen}`}>
                  <img
                    src="/img/blackIcon/FullScreen.svg"
                    alt="expandModalIcon"
                    loading="lazy"
                    className={styles.bigscreenIcon}
                  />
                </div>
              </button>
            )}
            {isActiveBookmark && isActiveSave && (
              <button
                type="button"
                onClick={(e) => handleClick(e, image.timestamp)}
                className={styles.bookmarkContainer}
                disabled={isPointActionPending || isSaving || isDeleting}
              >
                <div className={styles.bookmark}>
                  {(image.is_saved || isPointActionPending) && me ? (
                    <div className={styles.tooltipContainer}>
                        <IoIosCheckmarkCircle
                          style={{
                            color: "green",
                            fontSize: "17px",
                          }}
                          tabIndex={0}
                        />
                      <p className={styles.tooltipText}>Remove Image</p>
                    </div>
                  ) : (
                    <div className={styles.tooltipContainer}>
                      <img
                        className={styles.bookmarkIcon}
                        src="/img/blackIcon/Save.svg"
                        alt="bookmarkImg"
                        loading="lazy"
                        tabIndex={0}
                      />
                      <p className={styles.tooltipText}>Save Image</p>
                    </div>
                  )}
                </div>
              </button>
            )}
          </div>
        </div>
      </div>
      {isLastItem && hasMore && (
        <div ref={spinnerRef} className={styles.spinnerContainer}>
          <Spinner />
        </div>
      )}

      <Modal
        isOpen={!!selectedImage}
        onRequestClose={closeImageModal}
        contentLabel="Image Modal"
        className={styles.modal}
        overlayClassName={styles.overlay}
      >
        <div className={styles.modalContent} onContextMenu={(e) => handleContextMenu(e, image.id.toString(), Number(currentModalImage ? currentModalImage.timestamp : image.timestamp), image.screen)}>
          <div
            className={styles.containerImage}
            onMouseEnter={() => setIsHover(true)}
            onMouseLeave={() => setIsHover(false)}
            onClick={() => handleClickPhone(Number(currentModalImage ? currentModalImage.timestamp : image.timestamp), image.app_video)}
          >
            {isLoading ? (
              <ContentLoader
                speed={2}
                width={400}
                height={800}
                viewBox="0 0 400 800"
                backgroundColor="#f3f3f3"
                foregroundColor="#ecebeb"
              >
                <rect x="0" y="0" rx="2" ry="2" width="400" height="800" />
              </ContentLoader>
            ) : (
              <img
                className={styles.modalContentPhoto}
                src={selectedImage!}
                alt="Enlarged pic"
                loading="lazy"
              />
            )}
            <div className={`${styles.bookmarkmodalContainer} ${isHover ? styles.visible : ''}`}>
              <button
                type="button"
                onClick={(e) => handleClick(e, currentModalImage ? currentModalImage.timestamp : image.timestamp)}
                className={styles.bookmarkmodal}
              >
                {((currentModalImage && currentModalImage.is_saved) || image.is_saved || isPointActionPending) && me ? (
                  <IoIosCheckmarkCircle
                    style={{
                      color: "green",
                      fontSize: "18px",
                    }}
                  />
                ) : (
                  <img
                    className={styles.bookmarkIcon}
                    src="/img/blackIcon/Save.svg"
                    alt="bookmarkImg"
                    loading="lazy"
                  />
                )}
              </button>
            </div>
          </div>
          <img
            className={styles.arrowRight}
            src="/img/whiteIcon/ChevronRight.svg"
            alt="arrowRightImg"
            onClick={handleNextImage}
            loading="lazy"
            style={{ opacity: imageIndex === apiDataVideosScreens.length - 1 && !hasMore ? 0.5 : 1 }}
          />
          <img
            className={styles.arrowLeft}
            src="/img/whiteIcon/ChevronLeft.svg"
            alt="arrowLeftImg"
            onClick={handlePrevImage}
            loading="lazy"
          />
        </div>
      </Modal>

    </div>
  );
}

export default PhotoItemNew;
