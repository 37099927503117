// redux
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
// constants
import { backendBaseUrl } from "../lib/constants/backendBaseUrl";

const baseQuery = fetchBaseQuery({
  baseUrl: backendBaseUrl,
  prepareHeaders: (headers) => {
    const token = localStorage.getItem("access_token");
    if (token) {
      headers.set("Authorization", `Bearer ${token}`);
    }
    return headers;
  },
});

export const backendAPI = createApi({
  reducerPath: "backendAPI",
  tagTypes: [
    "DataApp",
    "DataHidden",
    "SavedPoints",
    "SavedStorePoints",
    "SharedViews",
    "SharedViewsPoints",
    "SavedGroups",
    "SavedStoreScreens",
    "SavedGroupPoints",
    "SavedApps",
  ],
  baseQuery,
  endpoints: () => ({}),
  refetchOnFocus: false,
  refetchOnReconnect: false,
  refetchOnMountOrArgChange: false,
  keepUnusedDataFor: 900,
});
