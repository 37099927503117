import React from "react";
import styles from "./ModalAppsEmpty.module.scss";

interface ModalAppsEmptyProps {
  text: string;
  img: string;
}

const ModalAppsEmpty: React.FC<ModalAppsEmptyProps> = ({ text, img }) => {
  return (
    <div className={styles.root}>
      <div className={styles.groupPhotoContent}>
        <img src={img} alt="groupEmptyImg" />
        <p>{text}</p>
      </div>
    </div>
  );
};

export default ModalAppsEmpty;
