// redux
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
// types
import { filterAppsState } from "../types/filterAppsTypes";

const initialState: filterAppsState = {
  appName: "",
  userFilter: null,
  filterSort: "revenue",
  filterSortSavedMode: "",
  filterSortHidStar: "",
  filterGroup: "",
  categories: "",
  paywall: "",
  oscgte: "Min",
  osclte: "Max",
  revenuegte: "Min",
  revenuelte: "Max",
  downloadgte: "Min",
  downloadlte: "Max",
  revInstallRateGte: "Min",
  revInstallRateLte: "Max",
  only: "not_hidden",
  selectedDataMin: "Min",
  selectedDataMax: "Max",
  selectedDataRangeMin: "Min",
  selectedDataRangeMax: "Max",
  avsTags: "",
  updatedFrom: "",
  updatedTo: "",
  releasedFrom: "",
  releasedTo: "",
  isFilter: false,
  pageFilter: 1,
  pageFilterSideBar: 1,
  pageFilterPhone: 1,
  dublicateData: false,
  activeModalIndexCategories: [],
  isActiveCategories: false,
  activeModalIndexPaywall: null,
  activeModalIndexLabelled: null,
  isPaywallOnly: false,
  isActivePaywall: false,
  isActiveOnboarding: false,
  isActiveInstalls: false,
  isActiveRevenue: false,
  isActiveContain: false,
  isActiveMagic: false,
  isActiveCalendar: false,
  isActiveCalendarUpdate: false,
  isActiveCalendarMin: false,
  isActiveCalendarMax: false,
  isActiveCalendarRangeMin: false,
  isActiveCalendarRangeMax: false,
  isActiveLabelled: false,
  activeButtonInstalls: null,
  activeButtonRevenue: null,
  activeButtonOnboarding: null,
  activeButtonReleaseDate: null,
  activeButtonUpdateDate: null,
  flowType: "",
  sortDirection: "desc"
};

const filterAppsSlice = createSlice({
  name: "filterApps",
  initialState,
  reducers: {
    setDublicateData: (state, action: PayloadAction<boolean>) => {
      state.dublicateData = action.payload;
    },
    setUserFilter: (state, action: PayloadAction<string | null>) => {
      state.userFilter = action.payload;
    },
    setAppName: (state, action: PayloadAction<string>) => {
      state.appName = action.payload;
    },
    setFilterSort: (state, action: PayloadAction<string>) => {
      state.filterSort = action.payload;
    },
    setFilterSortSavedMode: (state, action: PayloadAction<string>) => {
      state.filterSortSavedMode = action.payload;
    },
    setFilterSortHidStar: (state, action: PayloadAction<string>) => {
      state.filterSortHidStar = action.payload;
    },
    setFilterGroup: (state, action: PayloadAction<string>) => {
      state.filterGroup = action.payload;
    },
    setOnly: (state, action: PayloadAction<string>) => {
      state.only = action.payload;
    },
    setSelectedDataMin: (state, action: PayloadAction<Date | string>) => {
      state.selectedDataMin = action.payload;
    },
    setSelectedDataMax: (state, action: PayloadAction<Date | string>) => {
      state.selectedDataMax = action.payload;
    },
    setSelectedDataRangeMin: (state, action: PayloadAction<Date | string>) => {
      state.selectedDataRangeMin = action.payload;
    },
    setSelectedDataRangeMax: (state, action: PayloadAction<Date | string>) => {
      state.selectedDataRangeMax = action.payload;
    },
    toggleIsFilter: (state) => {
      state.isFilter = !state.isFilter;
    },
    activeIsFilter: (state) => {
      state.isFilter = true;
    },
    deactiveIsFilter: (state) => {
      state.isFilter = false;
    },
    countPageFilter: (state) => {
      state.pageFilter += 1;
    },
    resetPageFilter: (state) => {
      state.pageFilter = initialState.pageFilter;
    },
    countPageFilterSidebar: (state) => {
      state.pageFilterSideBar += 1;
    },
    resetPageFilterSidebar: (state) => {
      state.pageFilterSideBar = initialState.pageFilterSideBar;
    },
    countPageFilterPhone: (state) => {
      state.pageFilterPhone += 1;
    },
    resetPageFilterPhone: (state) => {
      state.pageFilterPhone = initialState.pageFilterPhone;
    },
    setCategories: (state, action: PayloadAction<string>) => {
      state.categories = action.payload;
    },
    setPaywall: (state, action: PayloadAction<string>) => {
      state.paywall = action.payload;
    },
    setOscgte: (state, action: PayloadAction<number | string>) => {
      state.oscgte = action.payload;
    },
    setOsclte: (state, action: PayloadAction<number | string>) => {
      state.osclte = action.payload;
    },
    setRevenuegte: (state, action: PayloadAction<number | string>) => {
      state.revenuegte = action.payload;
    },
    setRevenuelte: (state, action: PayloadAction<number | string>) => {
      state.revenuelte = action.payload;
    },
    setUpdatedFrom: (state, action: PayloadAction<string>) => {
      state.updatedFrom = action.payload;
    },
    setUpdatedTo: (state, action: PayloadAction<string>) => {
      state.updatedTo = action.payload;
    },
    setReleasedFrom: (state, action: PayloadAction<string>) => {
      state.releasedFrom = action.payload;
    },
    setReleasedTo: (state, action: PayloadAction<string>) => {
      state.releasedTo = action.payload;
    },
    setDownloadgte: (state, action: PayloadAction<number | string>) => {
      state.downloadgte = action.payload;
    },
    setDownloadlte: (state, action: PayloadAction<number | string>) => {
      state.downloadlte = action.payload;
    },
    resetDownloadLte: (state) => {
      state.downloadlte = initialState.downloadlte;
    },
    resetDownloadGte: (state) => {
      state.downloadgte = initialState.downloadgte;
    },

    setRevInstallRateGte: (state, action: PayloadAction<number | string>) => {
      state.revInstallRateGte = action.payload;
    },
    setRevInstallRateLte: (state, action: PayloadAction<number | string>) => {
      state.revInstallRateLte = action.payload;
    },
    resetRevInstallRateLte: (state) => {
      state.revInstallRateLte = initialState.revInstallRateLte;
    },
    resetRevInstallRateGte: (state) => {
      state.revInstallRateGte = initialState.revInstallRateGte;
    },

    resetRevenueLte: (state) => {
      state.revenuelte = initialState.revenuelte;
    },
    resetRevenueGte: (state) => {
      state.revenuegte = initialState.revenuegte;
    },
    resetOscLte: (state) => {
      state.osclte = initialState.osclte;
    },
    resetOscGte: (state) => {
      state.oscgte = initialState.oscgte;
    },
    setActiveModalIndexCategories: (
      state,
      action: PayloadAction<Array<number>>,
    ) => {
      state.activeModalIndexCategories = action.payload;
    },
    setActiveModalIndexPaywall: (
      state,
      action: PayloadAction<number | null>,
    ) => {
      state.activeModalIndexPaywall = action.payload;
    },
    setActiveModalIndexLabelled: (
      state,
      action: PayloadAction<number | null>,
    ) => {
      state.activeModalIndexLabelled = action.payload;
    },
    setActiveButtonReleaseDate: (
      state,
      action: PayloadAction<string | null>,
    ) => {
      state.activeButtonReleaseDate = action.payload;
    },
    setActiveButtonUpdateDate: (
      state,
      action: PayloadAction<string | null>,
    ) => {
      state.activeButtonUpdateDate = action.payload;
    },
    setIsPaywallOnly: (state, action: PayloadAction<boolean>) => {
      state.isPaywallOnly = action.payload;
    },
    activeCategories: (state) => {
      state.isActiveCategories = true;
    },
    deactiveCategories: (state) => {
      state.isActiveCategories = false;
    },
    activePaywall: (state) => {
      state.isActivePaywall = true;
    },
    deactivePaywall: (state) => {
      state.isActivePaywall = false;
    },
    activeOnboarding: (state) => {
      state.isActiveOnboarding = true;
    },
    deactiveOnboarding: (state) => {
      state.isActiveOnboarding = false;
    },
    activeInstalls: (state) => {
      state.isActiveInstalls = true;
    },
    deactiveInstalls: (state) => {
      state.isActiveInstalls = false;
    },
    activeRevenue: (state) => {
      state.isActiveRevenue = true;
    },
    deactiveRevenue: (state) => {
      state.isActiveRevenue = false;
    },
    activeContain: (state) => {
      state.isActiveContain = true;
    },
    deactiveContain: (state) => {
      state.isActiveContain = false;
    },
    activeMagic: (state) => {
      state.isActiveMagic = true;
    },
    deactiveMagic: (state) => {
      state.isActiveMagic = false;
    },
    activeReleaseDate: (state) => {
      state.isActiveCalendar = true;
    },
    deactiveReleaseDate: (state) => {
      state.isActiveCalendar = false;
    },
    activeUpdateDate: (state) => {
      state.isActiveCalendarUpdate = true;
    },
    deactiveUpdateDate: (state) => {
      state.isActiveCalendarUpdate = false;
    },
    setButtonInstalls: (state, action: PayloadAction<string | null>) => {
      state.activeButtonInstalls = action.payload;
    },
    setButtonRevenue: (state, action: PayloadAction<string | null>) => {
      state.activeButtonRevenue = action.payload;
    },
    setButtonOnboarding: (state, action: PayloadAction<string | null>) => {
      state.activeButtonOnboarding = action.payload;
    },
    setActiveCalendarMin: (state, action: PayloadAction<boolean>) => {
      state.isActiveCalendarMin = action.payload;
    },
    toggleCalendarMin: (state) => {
      state.isActiveCalendarMin = !state.isActiveCalendarMin;
    },
    setActiveCalendarMax: (state, action: PayloadAction<boolean>) => {
      state.isActiveCalendarMax = action.payload;
    },
    toggleCalendarMax: (state) => {
      state.isActiveCalendarMax = !state.isActiveCalendarMax;
    },
    setActiveCalendarRangeMin: (state, action: PayloadAction<boolean>) => {
      state.isActiveCalendarRangeMin = action.payload;
    },
    setActiveCalendarRangeMax: (state, action: PayloadAction<boolean>) => {
      state.isActiveCalendarRangeMax = action.payload;
    },
    toggleCalendarRangeMin: (state) => {
      state.isActiveCalendarRangeMin = !state.isActiveCalendarRangeMin;
    },
    toggleCalendarRangeMax: (state) => {
      state.isActiveCalendarRangeMax = !state.isActiveCalendarRangeMax;
    },
    activeLabelled: (state) => {
      state.isActiveLabelled = true;
    },
    deactiveLabelled: (state) => {
      state.isActiveLabelled = false;
    },
    setAvsTags: (state, action: PayloadAction<string>) => {
      state.avsTags = action.payload;
    },
    setFlowType: (state, action: PayloadAction<string | null>) => {
      state.flowType = action.payload;
    },
    resetFlowsFilter: (state) => {
      state.flowType = null;
    },
    setSortDirection: (state, action: PayloadAction<"asc" | "desc">) => {
      state.sortDirection = action.payload;
    },
  },
});

export const { actions: filterAppsActions, reducer: filterAppsReducer } =
  filterAppsSlice;
