import React, { forwardRef, useEffect, useState, useRef } from "react";
import ContentLoader from "react-content-loader";
import { IAppSavedGroup, ResponseSavedPoint } from "@/features/auth/types/auth";
import styles from "./ModalSaveModeScreens.module.scss";
import { RightClickModal } from "../RightClickModal/RightClickModal";
import ModalSaveModeMore from "../ModalSaveModeMore/ModalSaveModeMore";

interface ModalSaveModeScreensProps {
  setHoveredScreenId: React.Dispatch<React.SetStateAction<number | null>>;
  item: ResponseSavedPoint;
  setSelectActive: React.Dispatch<React.SetStateAction<boolean>>;
  handlePhotoSelect: (id: number, url: string, videoId: number) => void;
  selectActive: boolean;
  selectedPhotos: Set<{ id: number; url: string; videoId: number }>;
  setName: React.Dispatch<React.SetStateAction<string>>;
  handleClickPhone: (
    timestamp: number,
    blank: boolean | undefined,
    name: string,
    appId: int,
    slug: string
  ) => void;
  openModal: (
    image: string,
    name: string,
    ts: number,
    id: number,
    idVideo: number,
    type: string,
    imageStoreId: number,
    idStore?: number,
    appId?: number,
    slug?: string,
    item?: ResponseSavedPoint,
  ) => void;
  matchingGroups: IAppSavedGroup[] | undefined;
  setModalOpenGroups: React.Dispatch<React.SetStateAction<boolean>>;
  setMatchingGroups: React.Dispatch<React.SetStateAction<IAppSavedGroup[]>>;
  onGroupClick: (group: IAppSavedGroup) => void;
  onRefetchRequest: (itemId: number) => void;
}

const ModalSaveModeScreens = forwardRef<HTMLDivElement, ModalSaveModeScreensProps>(({
  setHoveredScreenId,
  item,
  setSelectActive,
  handlePhotoSelect,
  selectActive,
  selectedPhotos,
  setName,
  handleClickPhone,
  openModal,
  matchingGroups,
  setMatchingGroups,
  onGroupClick,
  onRefetchRequest,
}, ref) => {
  const isSelected =
    selectActive &&
    [...selectedPhotos].some((photo) => photo.id === Number(item.id));
  const handlePhotoClick = () => {
    if (selectActive) {
      setName(item.app.name);
      handlePhotoSelect(Number(item.id), item.screen!, item.app_video);
    } else {
      setName(item.app.name);
      openModal(
        item.screen!,
        item.app.name,
        Number(item.ts),
        Number(item.id),
        item.app_video,
        "Screen",
        item.id,
        0,
        item.app.id,
        item.app.slug,
        item,
      );
    }
  };

  useEffect(() => {
    if (item && item.id && item.screen === null) {
      onRefetchRequest(item.id);
    }
  }, [item, onRefetchRequest]);

  const [rightClickModalVisible, setRightClickModalVisible] = useState(false);
  const [rightClickPosition, setRightClickPosition] = useState({ x: 0, y: 0 });
  const [modalOpenGroups, setModalOpenGroups] = useState<boolean>(false);
  const modalMoreRef = useRef<HTMLDivElement>(null);

  const handleRightClick = (e: React.MouseEvent) => {
    e.preventDefault();
    setRightClickPosition({ x: e.clientX, y: e.clientY });
    setRightClickModalVisible(true);
  };

  const closeRightClickModal = () => {
    setRightClickModalVisible(false);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        modalMoreRef.current &&
        !modalMoreRef.current.contains(event.target as Node)
      ) {
        setModalOpenGroups(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div
      ref={ref}
      className={`${styles.root} ${isSelected ? styles.selectedRoot : ''}`}
      onMouseEnter={() => setHoveredScreenId(item.id)}
      onMouseLeave={() => setHoveredScreenId(null)}
      onContextMenu={handleRightClick}
    >
        <div
          onClick={() => {
            setSelectActive(true);
            handlePhotoSelect(Number(item.id), item.screen!, item.app_video);
          }}
          className={isSelected ? styles.hoverElementActive : styles.hoverElement}
        >
          {isSelected && (
          <img
            src="/img/whiteIcon/Check.svg"
            alt="checkCircleImg"
          />
          )}
        </div>
      <div
        onMouseEnter={() => setName(item.app.name)}
        onClick={
          selectActive
            ? handlePhotoClick
            : () => handleClickPhone(Number(item.ts), true, item.app.name, item.app.id, item.app.slug)
        }
        className={styles.photoItemTitle}
      >
        <img src={item.app.icon} alt="appIcon" loading="lazy" />
        <div className={styles.photoItemContent}   >
          <h3>{item.app.name}</h3>
          <p>{item.app.shortname}</p>
        </div>
      </div>
      <div
        className={`${styles.photoContainer} ${isSelected ? styles.selectedPhotoContainer : ""}`}
      >
        <div
          className={styles.containerPhoto}
          onClick={item.screen ? handlePhotoClick : undefined}
        >
          {item.screen ? (
            <img
              src={item.screen!}
              alt="screen"
              className={`${styles.photo} ${isSelected ? styles.selectedPhoto : ""}`}
              style={{ cursor: selectActive ? "pointer" : "zoom-in" }}
              loading="lazy"
            />
          ) : (
            <ContentLoader
              speed={2}
              width={290}
              height={493}
              viewBox="0 0 290 493"
              backgroundColor="#f3f3f3"
              foregroundColor="#ecebeb"
            >
              <rect x="0" y="0" rx="12" ry="12" width="290" height="493" />
            </ContentLoader>
          )}
        </div>
      </div>
      <div className={styles.photoContainerGroups}>
        {matchingGroups?.slice(0, 5)?.map((group, groupIndex) => (
          <div
            key={groupIndex}
            className={styles.photoGroupItem}
            onClick={() => onGroupClick(group)}
          >
            <p>{group.name}</p>
          </div>
        ))}
        {matchingGroups && matchingGroups.length > 5 && (
          <div
            ref={modalMoreRef}
            onClick={() => {
              setModalOpenGroups(!modalOpenGroups);
              setMatchingGroups(matchingGroups);
            }}
            className={styles.photoGroupItemMore}
          >
            <p className={styles.moreModalSaveModeButton}>More...</p>
          </div>
        )}
        {modalOpenGroups && (
          <ModalSaveModeMore
            matchingGroups={matchingGroups}
            onClose={() => setModalOpenGroups(false)}
            modalRef={modalMoreRef}
          />
        )}
      </div>

      {rightClickModalVisible && (
        <RightClickModal
          x={rightClickPosition.x}
          y={rightClickPosition.y}
          onClose={closeRightClickModal}
          imageUrl={item.screen!}
          imageId={item.id}
          timestamp={Number(item.ts)}
          isStoreScreen={false}
          handleOpenInNewTab={() => handleClickPhone(Number(item.ts), true, item.app.name, item.app.id, item.app.slug)}
          avsID={item.app_video}
          refetchData={() => console.log('Refetch data')}
          imageType="video"
        />
      )}
    </div>
  );
});

export default ModalSaveModeScreens;
