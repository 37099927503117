import React from 'react';
import { useState, useEffect } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import AccountList from '@/shared/ui/AccountList/AccountList';
import styles from "./AccountArea.module.scss";
import { useGetUserQuery } from '@/features/auth/api/authAPI';

interface AccountAreaProps {
    children: React.ReactNode;
}

const AccountArea: React.FC<AccountAreaProps> = ({ children }) => {
    const [activeTab, setActiveTab] = useState('account');
    const [selectedOrgId, setSelectedOrgId] = useState<number | null>(null);
    const { data: me, isLoading: loading, error, refetch } = useGetUserQuery(null);
    const navigate = useNavigate();
    const { uuid } = useParams();
    const location = useLocation();

    useEffect(() => {
        
        if (location.pathname.includes('/settings/teams')) {
            setActiveTab('teams');

            // wtf, why not use useParams
            const orgId = location.pathname.split('/')[3];
            if (orgId) {
                setSelectedOrgId(orgId);
            }
        } else if (location.pathname.includes('/settings/account')) {
            setActiveTab('account');
        }
    }, [location.pathname]);

    const handleTeamsClick = () => {
        setActiveTab('teams');
        if (me?.organizations && me.organizations.length > 0) {
            navigate(`/settings/teams/${me.organizations[0].uuid}/members`);
        }
    };

    const handleOrgClick = (orgId: number) => {
        setSelectedOrgId(orgId);
        setActiveTab('teams');
        navigate(`/settings/teams/${orgId}/members`);
    };

    const handleAccountClick = () => {
        setActiveTab("account");
        navigate("/settings/account");
    };

    const handleCreateNewTeam = async () => {
        await refetch(); 
    };

    return (
        <div className={styles.wrapper}>
            <div className={styles.container}>
                <AccountList
                    activeTab={activeTab}
                    handleTeamsClick={handleTeamsClick}
                    me={me}
                    selectedOrgId={selectedOrgId}
                    handleOrgClick={handleOrgClick}
                    handleAccountClick={handleAccountClick}
                    onCreateNewTeam={handleCreateNewTeam}
                />
                <div className={styles.content}>
                    {children}
                </div>
            </div>
        </div>
    );
};

export default AccountArea;
