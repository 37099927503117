import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { filterAppsActions } from "@/entities/filterApps";
import { filterAppsDataActions } from "@/entities/filterAppsData";
import { counterActions } from "@/entities/counter";
import styles from "./HiddenModal.module.scss";
import { activeModalIndexActions } from "@/entities/activeModalIndex";
import { modalFiltersActions } from "@/entities/modalFilters/model/slice/modalFiltersSlice";
import { Switch } from "@mui/material";
import { RootState } from "@reduxjs/toolkit/query/react";
import { styled } from '@mui/material/styles';

interface HiddenModalProps {
  activeButton: ("hidden" | "starred" | "paywall")[] | null;
  setActiveButton: (state: ("hidden" | "starred" | "paywall")[]) => void;
}

const CustomSwitch = styled(Switch)(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 3,
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        background: 'linear-gradient(90deg, rgb(35 97 217), rgb(94 36 217) 100%)',
        opacity: 1,
      },
    },
  },
  '& .MuiSwitch-thumb': {
    width: 20,
    height: 20,
    backgroundColor: '#fff',
  },
  '& .MuiSwitch-track': {
    borderRadius: 26,
    backgroundColor: '#grey',
    opacity: 0.3,
  },
}));

const HiddenModal: React.FC<HiddenModalProps> = ({
  activeButton,
  setActiveButton,
}) => {
  const dispatch = useDispatch();

  const isPaywallOnly = useSelector((state: RootState) => state.filterApps.isPaywallOnly);

  React.useEffect(() => {
    if (isPaywallOnly && activeButton && !activeButton.includes("paywall")) {
      setActiveButton([...activeButton, "paywall"]);
    }
  }, [isPaywallOnly, activeButton, setActiveButton]);

  // const handleReset = () => {
  //   dispatch(filterAppsActions.setOnly("not_hidden"));
  //   setActiveButton([]);
  //   dispatch(filterAppsActions.setIsPaywallOnly(false));
  //   dispatch(filterAppsDataActions.resetFilterAppDataSorted());
  //   dispatch(filterAppsDataActions.resetFilterAppDataSortedAll());
  //   dispatch(filterAppsDataActions.resetFilterAppData());
  //   dispatch(filterAppsActions.resetPageFilter());
  //   dispatch(filterAppsActions.resetPageFilterSidebar());
  //   dispatch(counterActions.clearArray());
  //   dispatch(counterActions.setCount(0));
  // };

  const handleHiddenClick = () => {
    const currentButtons = activeButton || [];
    const isActive = currentButtons.includes("hidden");
    const wasStarred = currentButtons.includes("starred");

    let newButtons = currentButtons.filter(btn => btn !== "hidden" && btn !== "starred");

    if (!isActive) {
      newButtons.push("hidden");
      dispatch(filterAppsActions.setOnly("hidden"));
      if (wasStarred) {
        dispatch(counterActions.removeFromArray("Starred"));
      }
      dispatch(counterActions.addToArray("Hidden"));
    } else {
      dispatch(filterAppsActions.setOnly("not_hidden"));
      dispatch(counterActions.removeFromArray("Hidden"));
    }

    setActiveButton(newButtons);
    dispatch(filterAppsDataActions.resetFilterAppDataSorted());
    dispatch(filterAppsDataActions.resetFilterAppDataSortedAll());
    dispatch(filterAppsDataActions.resetFilterAppData());
    dispatch(filterAppsActions.resetPageFilter());
    dispatch(filterAppsActions.resetPageFilterSidebar());
  };

  const handleStarredClick = () => {
    const currentButtons = activeButton || [];
    const isActive = currentButtons.includes("starred");
    const wasHidden = currentButtons.includes("hidden");

    let newButtons = currentButtons.filter(btn => btn !== "hidden" && btn !== "starred");

    if (!isActive) {
      newButtons.push("starred");
      dispatch(filterAppsActions.setOnly("saved"));
      if (wasHidden) {
        dispatch(counterActions.removeFromArray("Hidden"));
      }
      dispatch(counterActions.addToArray("Starred"));
    } else {
      dispatch(filterAppsActions.setOnly("not_saved"));
      dispatch(counterActions.removeFromArray("Starred"));
    }

    setActiveButton(newButtons);
    dispatch(filterAppsDataActions.resetFilterAppDataSorted());
    dispatch(filterAppsDataActions.resetFilterAppDataSortedAll());
    dispatch(filterAppsDataActions.resetFilterAppData());
    dispatch(filterAppsActions.resetPageFilter());
  };

  const handlePaywallClick = () => {
    const currentButtons = activeButton || [];
    const isActive = currentButtons.includes("paywall");

    if (!isActive) {
      setActiveButton([...currentButtons, "paywall"]);
      dispatch(filterAppsActions.setIsPaywallOnly(true));
      dispatch(counterActions.addToArray("Paywall"));
    } else {
      setActiveButton(currentButtons.filter(btn => btn !== "paywall"));
      dispatch(filterAppsActions.setIsPaywallOnly(false));
      dispatch(counterActions.removeFromArray("Paywall"));
    }

    dispatch(filterAppsDataActions.resetFilterAppDataSorted());
    dispatch(filterAppsDataActions.resetFilterAppDataSortedAll());
    dispatch(filterAppsDataActions.resetFilterAppData());
    dispatch(filterAppsActions.resetPageFilter());
  };

  const handleCountClick = (index: number) => {
    const isCurrentlyActive = 8 === index;
    if (isCurrentlyActive) {
      dispatch(activeModalIndexActions.setActiveModalIndexFilterItem(null));
      if (index === 8) {
        dispatch(modalFiltersActions.setIsHiddenOpen(false));
      }
    } else {
      if (index === 8) {
        dispatch(modalFiltersActions.setIsHiddenOpen(true));
      }
      dispatch(activeModalIndexActions.setActiveModalIndexFilterItem(index));
    }
  };

  const isExplorePage = window.location.pathname === "/";

  return (
    <div className={styles.root}>
      <div className={styles.containerButtons}>
        <div className={styles.switchContainer}>
          <div className={styles.items}>
            <img
              src="/img/blackIcon/Hide.svg"
              alt="eyeOffFilterImg"
            />
            <p>Your Hidden Apps</p>
          </div>
          <CustomSwitch
            checked={activeButton?.includes("hidden") ?? false}
            onChange={handleHiddenClick}
            inputProps={{ 'aria-label': 'hidden apps toggle' }}
          />
        </div>
        <div className={styles.switchContainer}>
          <div className={styles.items}>
            <img
              src="/img/blackIcon/Star.svg"
              alt="starFilterImg"
            />
            <p>Your Starred Apps</p>
          </div>
          <CustomSwitch
            checked={activeButton?.includes("starred") ?? false}
            onChange={handleStarredClick}
            inputProps={{ 'aria-label': 'starred apps toggle' }}
          />
        </div>
        {isExplorePage && (
          <div className={styles.switchContainer}>
            <div className={styles.items}>
              <img
                src="/img/blackIcon/PaywallType.svg"
                alt="paywallFilterImg"
              />
              <p>Paywall Only</p>
            </div>
            <CustomSwitch
              checked={activeButton?.includes("paywall") ?? false}
              onChange={handlePaywallClick}
              inputProps={{ 'aria-label': 'paywall apps toggle' }}
            />
          </div>
        )}
      </div>
      <div className={styles.flex} onClick={() => handleCountClick(8)}>
        {/* {isResetVisible && (
          <div onClick={handleReset} className={styles.reset}>
            <img src="/img/gradientIcon/Reset.svg" alt="resetImg" loading="lazy" />

          </div>
        )} */}
      </div>
    </div>
  );
};

export default HiddenModal;

