import React,{ useEffect, useRef, useState } from "react";
import { useParams, NavLink } from 'react-router-dom'
import { useSelector } from "react-redux";
import Spinner from "@/shared/ui/Spinner/Spinner";

import {
  useGetSavedGroupDownloadQuery,
  useSharedGroupsMutation,
  useDeleteSavedGroupScreensMutation,
  useGetSavedGroupPointsQuery,
  useGetSavedGroupStorePointsQuery,
  useGetSavedGroupQuery,
  useDeleteSavedGroupStorePointsMutation,
  useUpdateSavedGroupMutation,
  useGetSavedGroupObScreensQuery,
  useGetSavedGroupAnimationsQuery,
  useDeleteSavedGroupObScreenMutation,
  useDeleteSavedGroupAnimationMutation,
} from "@/features/auth/api/authAPI";
import Modal from "react-modal";
import Homestyles from "@/pages/Homepage/Homepage.module.scss";
import styles from "@/widgets/modalSaveMode/ui/ModalSaveMode/ModalSaveMode.module.scss";
import { getZoomState } from "@/entities/zoom";
import ModalAppsEmpty from "@/shared/ui/ModalAppsEmpty/ModalAppsEmpty";
import ModalSaveModeSavedGroupStore from "@/shared/ui/ModalSaveModeSavedGroupStore/ModalSaveModeSavedGroupStore";
import ModalSaveModeSavedGroupScreens from "@/shared/ui/ModalSaveModeSavedGroupScreens/ModalSaveModeSavedGroupScreens";

import { produce } from 'immer'
import { AnimationCard } from "@/shared/ui/AnimationCard/AnimationCard";
import { WebOnboardingCard } from "@/shared/ui/WebOnboardingCard/WebOnboardingCard";
import { handleOpenVideoPage } from "@/app/libs/const/handleOpenVideoPage";

const SavedModeGroupDetailView = () => {

  const { id } = useParams<{ id: string }>()
  const groupId = Number(id);
  const [savedGroup, setSavedGroup] = useState<IAppSavedGroup | null>(null)
  const [savedPoints, setSavedPoints] = useState<ISavedPoint[]>([])
  const [savedStoreScreens, setSavedStoreScreens] = useState<ISavedStoreScreen[]>([])
  const [savedAnimations, setSavedAnimations] = useState<ISavedAnimation[]>([])
  const [savedObScreens, setSavedObScreens] = useState<ISavedObScreen[]>([])
  const [deleteSavedScreens] = useDeleteSavedGroupScreensMutation();
  const [deleteSavedStoreScreens] = useDeleteSavedGroupStorePointsMutation();
  const [deleteSavedGroupAnimation] = useDeleteSavedGroupAnimationMutation();
  const [deleteSavedGroupObScreen] = useDeleteSavedGroupObScreenMutation();
  const [sharedGroups] = useSharedGroupsMutation();
  const [groupIdPoint, setGroupIdPoint] = useState<null | number>(id);
  const [selectCurrentGroupActive, setSelectCurrentGroupActive] =
    useState(false);

  const containerRef = useRef<HTMLDivElement>(null);
  const [selectedCurrentGroups, setSelectedCurrentGroups] = useState<
    Set<{ id: number; url: string; videoId: number, type: string }>
  >(new Set());
  const [responseSavedGroup, setResponseSavedGroup] = useState<number | null>(
    null,
  );

  const [photoIdGroupScreen, setPhotoIdGroupScreen] = useState<number | null>(
    null,
  );

  const [isDeleting, setIsDeleting] = useState(false);
  const [slugText, setSlugText] = useState<string>("");
  const [timestamp, setTimestamp] = useState<number | null>(null);
  const [imageIdStore, setImageIdStore] = useState<null | number>(null);
  const [name, setName] = useState<string>("...");
  const [appId, setAppId] = useState<number | null>(null);
  const [isGroupPhotoModalOpen, setIsGroupPhotoModalOpen] = useState(false);
  const [typeScreen, setTypeScreen] = useState<string>("");
  const [selectedGroupPhoto, setSelectedGroupPhoto] = useState<string>("");

  const photoGroupRef = useRef<HTMLDivElement>(null);
  const [isLinkSaved, setIsLinkSaved] = useState(false);

  const { zoomGroupSaveMode } = useSelector(getZoomState);

  const applyZoomGroup = (zoom: number) => {
    const photoCardListElement = photoGroupRef.current;

    if (photoCardListElement) {
      photoCardListElement.style.setProperty("--zoom-level", zoom.toString());
    }
  };

  useEffect(() => {
    scrollToTop();
    applyZoomGroup(zoomGroupSaveMode);
  }, [zoomGroupSaveMode]);

  const {
    data: apiDataSavedGroupDownload,
    refetch: refetchSavedGroupDownload,
  } = useGetSavedGroupDownloadQuery(responseSavedGroup!, {
    skip: !responseSavedGroup,
  });

  if (apiDataSavedGroupDownload && !apiDataSavedGroupDownload.ready_at) {
    setTimeout(() => {
      refetchSavedGroupDownload();
    }, 5000);
  }

  const scrollToTop = () => {
    if (containerRef.current) {
      containerRef.current.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };

  const allGroupPhotos = [
    ...(savedPoints),
    ...(savedStoreScreens),
    ...(savedAnimations),
    ...(savedObScreens),
  ];

  const updatePhotoDetails = (
    item: IAppSavedGroupPoints | IAppSavedGroupStorePoints,
  ) => {
    let imageUrl: string;
    let appName: string;
    let timestamp: number;
    let id: number;

    if ("saved_point" in item) {
      imageUrl = item.saved_point.screen;
      appName = item.saved_point.app.name;
      timestamp = Number(item.saved_point.ts);
      id = item.saved_point.id;
    } else if ("saved_store_screen" in item) {
      imageUrl = item.saved_store_screen.store_screen.thumbnail;
      appName = item.saved_store_screen.app.name;
      timestamp = 0;
      id = item.saved_store_screen.store_screen.id;
    } else {
      return;
    }

    const typeScreen = imageUrl.endsWith(".webp") ? "Store" : "Screen";

    setSelectedGroupPhoto(imageUrl);
    setPhotoIdGroupScreen(item.id);
    setTypeScreen(typeScreen);
    setTimestamp(timestamp);
    setName(appName);
    setImageIdStore(id);
    if ("saved_point" in item) {
      setAppId(item.saved_point.app.id);
      setSlugText(item.saved_point.app.slug);
    } else if ("saved_store_screen" in item) {
      setAppId(item.saved_store_screen.app.id);
      setSlugText(item.saved_store_screen.app.slug);
    }
  };

  const handleNextGroupPhoto = () => {
    if (allGroupPhotos.length === 0) return;

    const currentIndex = allGroupPhotos.findIndex(
      (item) => item.id === photoIdGroupScreen,
    );
    const nextIndex = (currentIndex + 1) % allGroupPhotos.length;
    const nextItem = allGroupPhotos[nextIndex];

    updatePhotoDetails(nextItem);
  };

  const handlePrevGroupPhoto = () => {
    if (allGroupPhotos.length === 0) return;

    const currentIndex = allGroupPhotos.findIndex(
      (item) => item.id === photoIdGroupScreen,
    );
    const prevIndex =
      (currentIndex - 1 + allGroupPhotos.length) % allGroupPhotos.length;
    const prevItem = allGroupPhotos[prevIndex];

    updatePhotoDetails(prevItem);
  };

  const { data: savedGroupData, refetch: refetchSavedGroup } = useGetSavedGroupQuery(groupId, {
    skip: !groupId
  });

  useEffect(() => {
    if (savedGroupData) {
      setSavedGroup(savedGroupData);
    }
  }, [savedGroupData]);

  const { data: savedPointsData, refetch: refetchSavedGroupPoints } = useGetSavedGroupPointsQuery(groupId, {
    skip: !groupId
  });

  const { data: savedStoreScreensData, refetch: refetchSavedGroupStorePoints } = useGetSavedGroupStorePointsQuery(groupId, {
    skip: !groupId
  });

  const { data: savedAnimationsData, refetch: refetchSavedGroupAnimations } = useGetSavedGroupAnimationsQuery(groupId, {
    skip: !groupId
  });

  const { data: savedObScreensData, refetch: refetchSavedGroupObScreens } = useGetSavedGroupObScreensQuery(groupId, {
    skip: !groupId
  });

  const [updateSavedGroup] = useUpdateSavedGroupMutation();

  useEffect(() => {
    if (groupId) {
      refetchSavedGroup();
      refetchSavedGroupPoints();
      refetchSavedGroupStorePoints();
      refetchSavedGroupAnimations();
      refetchSavedGroupObScreens();
    }
  }, [groupId, refetchSavedGroup, refetchSavedGroupPoints, refetchSavedGroupStorePoints,
    refetchSavedGroupAnimations, refetchSavedGroupObScreens]);

  const handleDeleteScreenFromGroup = async () => {
    try {
      if (typeScreen === "Store") {
        await deleteSavedStoreScreens(photoIdGroupScreen!).unwrap();
      } else if (typeScreen === "Screen") {
        await deleteSavedScreens(photoIdGroupScreen!).unwrap();
      } else if (typeScreen === "Animation") {
        await deleteSavedGroupAnimation(photoIdGroupScreen!).unwrap();
      } else if (typeScreen === "Onboarding") {
        await deleteSavedGroupObScreen(photoIdGroupScreen!).unwrap();
      }
      setIsGroupPhotoModalOpen(false);

      await refetchSavedGroup();
      refetchSavedGroupPoints();
      refetchSavedGroupStorePoints();

    } catch (error) {
      console.error("Error deleting screen:", error);
    }
  };

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "ArrowRight") {
        handleNextGroupPhoto();
      } else if (event.key === "ArrowLeft") {
        handlePrevGroupPhoto();
      }
    };

    window.addEventListener(
      "keydown",
      handleKeyDown as unknown as EventListener,
    );
    return () =>
      window.removeEventListener(
        "keydown",
        handleKeyDown as unknown as EventListener,
      );
  }, [handleNextGroupPhoto, handlePrevGroupPhoto]);

  const handleShareLinkGroup = async () => {
    try {
      const body = {
        saved_group: groupIdPoint!,
      };

      const response = await sharedGroups(body).unwrap();

      const urlToCopy = `${import.meta.env.VITE_BASE_URL_WEB}/shared/${response.key}`;

      await navigator.clipboard.writeText(urlToCopy);

      setIsLinkSaved(true);

      setTimeout(() => {
        setIsLinkSaved(false);
      }, 1000);
    } catch (err) {
      console.error("Failed to copy: ", err);
    }
  };

  const handleCurrentGroupSelect = (
    id: number,
    url: string,
    videoId: number,
    type: string
  ) => {

    setSelectedCurrentGroups((prevSelected) => {
      const newSelected = new Set(prevSelected);
      const itemToFind = [...newSelected].find(
        (photo) => photo.id === id && photo.url === url,
      );

      if (itemToFind) {
        newSelected.delete(itemToFind);
      } else {
        newSelected.add({ id, url, videoId, type });
      }

      return newSelected;
    });
  };

  const handleUpdateSavedGroup = async (name: string) => {
    await updateSavedGroup({ id: groupId!, name }).unwrap();
  };

  const openGroupPhotoModal = (
    photo: string,
    photoId: number,
    name: string,
    ts: number,
    imageStoreId: number,
    appId?: number,
    slug?: string,
  ) => {
    const typeScreen = photo.endsWith(".webp") ? "Store" : "Screen";
    setTypeScreen(typeScreen);
    setSelectedGroupPhoto(photo);
    setPhotoIdGroupScreen(photoId);
    setIsGroupPhotoModalOpen(true);
    setAppId(appId || null);
    setName(name);
    setImageIdStore(imageStoreId);
    setTimestamp(ts);
    setSlugText(slug!);
  };

  const handleDeleteSelectedPhotosGroup = async () => {
    setIsDeleting(true);
    try {
      const deletePromises = Array.from(selectedCurrentGroups).map(
        async (screen) => {
          if (screen.type === 'store') {
            await deleteSavedStoreScreens(screen.id).unwrap();
          } else if (screen.type === 'video') {
            await deleteSavedScreens(screen.id).unwrap();
          } else if (screen.type === 'animation') {
            await deleteSavedGroupAnimation(screen.id).unwrap();
          } else if (screen.type === 'onboarding') {
            await deleteSavedGroupObScreen(screen.id).unwrap();
          }
        },
      );

      await Promise.all(deletePromises);

      const deletedScreensVideo = Array.from(selectedCurrentGroups)
        .filter((screen) => screen.type === 'video')
        .map((item) => item.id);
      const deletedScreensStore = Array.from(selectedCurrentGroups)
        .filter((screen) => screen.type === 'store')
        .map((item) => item.id);
      const deletedAnimations = Array.from(selectedCurrentGroups)
        .filter((screen) => screen.type === 'animation')
        .map((item) => item.id);
      const deletedObScreens = Array.from(selectedCurrentGroups)
        .filter((screen) => screen.type === 'onboarding')
        .map((item) => item.id);

      setSavedPoints(produce((draft) => {
        const index = draft.findIndex(item => deletedScreensVideo.includes(item.id))
        if (index !== -1) draft.splice(index, 1)
      }));

      setSavedStoreScreens(produce((draft) => {
        const index = draft.findIndex(item => deletedScreensStore.includes(item.id))
        if (index !== -1) draft.splice(index, 1)
      }));

      setSavedAnimations(produce((draft) => {
        const index = draft.findIndex(item => deletedAnimations.includes(item.id))
        if (index !== -1) draft.splice(index, 1)
      }));

      setSavedObScreens(produce((draft) => {
        const index = draft.findIndex(item => deletedObScreens.includes(item.id))
        if (index !== -1) draft.splice(index, 1)
      }));

      await refetchSavedGroup();
      refetchSavedGroupPoints();
      refetchSavedGroupStorePoints();
      refetchSavedGroupAnimations();
      refetchSavedGroupObScreens();

      setSelectedCurrentGroups(new Set());
      setSelectCurrentGroupActive(false);

    } catch (error) {
      console.error("Failed to delete selected photos group:", error);
    } finally {
      setIsDeleting(false);
    }
  };



  useEffect(() => {
    if (savedPointsData) {
      setSavedPoints(savedPointsData.results);
    }
  }, [savedPointsData]);

  useEffect(() => {
    if (savedStoreScreensData) {
      setSavedStoreScreens(savedStoreScreensData.results);
    }
  }, [savedStoreScreensData]);

  useEffect(() => {
    if (savedAnimationsData) {
      setSavedAnimations(savedAnimationsData.results);
    }
  }, [savedAnimationsData]);

  useEffect(() => {
    if (savedObScreensData) {
      setSavedObScreens(savedObScreensData.results);
    }
  }, [savedObScreensData]);

  const handleClickPhone = (
    timestamp: number,
    blank: boolean = false,
    name: string,
    appId: number,
    slug: string
  ) => {
    handleOpenVideoPage(
      timestamp,
      slug,
      "Saved mode shared",
      1,
      appId,
      undefined,
      undefined,
    );
  };

  const handleClickStorePhone = (
    timestamp: number,
    blank: boolean = false,
    name: string,
    imageId: number | null,
  ) => {
    handleOpenVideoPage(
      timestamp,
      slugText,
      "Saved mode shared",
      1,
      appId,
      undefined,
    );
  };

  const [isEditing, setIsEditing] = useState(false);
  const [editName, setEditName] = useState("");
  const inputRef = useRef<HTMLInputElement>(null);

  const handleStartEditing = () => {
    setIsEditing(true);
    setEditName(savedGroup?.name || "");
    setTimeout(() => inputRef.current?.focus(), 0);
  };

  const handleNameSubmit = async () => {
    if (editName.trim() && editName !== savedGroup?.name) {
      try {
        await handleUpdateSavedGroup(editName.trim());
        setSavedGroup(prev => prev ? { ...prev, name: editName.trim() } : null);
      } catch (error) {
        console.error("Failed to update group name:", error);
      }
    }
    setIsEditing(false);
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      handleNameSubmit();
    } else if (e.key === 'Escape') {
      setIsEditing(false);
    }
  };

  return (

    <div className={Homestyles.modalSaveModeContainer}>
      <div className={Homestyles.modalSaveMode}>
        <div ref={containerRef} className={styles.root}>

          <div className={styles.headerGroupContentActive}>
            <div className={styles.headerGroupLeft}>
              {selectCurrentGroupActive ? (
                <div
                  onClick={() => {
                    setSelectCurrentGroupActive(!selectCurrentGroupActive);
                    setSelectedCurrentGroups(new Set());
                  }}
                  className={styles.selectSave}
                >
                  <img src="/img/cursorClickIcon.svg" alt="cursorClickImg" />
                  <p>Deselect All</p>
                </div>
              ) : (
                <>
                  <NavLink
                    to="/saved-library/groups/"
                    className={styles.groupArrow}
                  >
                    <img
                      src="/img/blackIcon/ChevronLeft.svg"
                      alt="arrowGroupLeftImg"
                    />
                  </NavLink>
                  {/* <div
                          onClick={() =>
                            setSelectCurrentGroupActive(!selectCurrentGroupActive)
                          }
                          className={styles.selectSave}
                        >
                          <img src="/img/cursorClickIcon.svg" alt="cursorClickImg" />
                          <p>Select</p>
                        </div> */}
                  {apiDataSavedGroupDownload?.ready_at && (
                    <div
                      onClick={handleZipDownload}
                      className={styles.downloadScreen}
                    >
                      <p>Download all Screens</p>
                    </div>
                  )}
                </>
              )}
            </div>
            <div className={styles.groupSaveActive}>
              {isEditing ? (
                <input
                  ref={inputRef}
                  className={styles.nameInput}
                  value={editName}
                  onChange={(e) => setEditName(e.target.value)}
                  onBlur={handleNameSubmit}
                  onKeyDown={handleKeyDown}
                  maxLength={50}
                />
              ) : (
                <h3 onClick={handleStartEditing} title={savedGroup?.name}>{savedGroup?.name}</h3>
              )}
              <p>
                {savedGroup &&
                  savedGroup?.total_saved_points +
                  savedGroup?.total_saved_store_screens +
                  savedGroup?.total_saved_animations +
                  savedGroup?.total_saved_ob_screens}{" "}
                Screens
              </p>
            </div>
            <div className={styles.headerGroupRight}>
              {selectedCurrentGroups.size > 0 ? (
                <div
                  onClick={handleDeleteSelectedPhotosGroup}
                  className={styles.deleteScreens}
                >
                  <button>
                    {isDeleting ? (
                      <Spinner width="30px" />
                    ) : (
                      <img src="/img/blackIcon/Delete.svg" alt="trashImg" />
                    )}
                  </button>
                </div>
              ) : (
                <div
                  onClick={handleShareLinkGroup}
                  className={styles.shareThis}
                >
                  <img
                    src={`${isLinkSaved ? "/img/shareSaveModeIconActive.svg" : "/img/shareSaveModeIcon.svg"}`}
                    alt="shareImg"
                    loading="lazy"
                  />
                  <p
                    style={{
                      color: isLinkSaved ? "#26de86" : "#000",
                    }}
                  >
                    {isLinkSaved ? "Link saved" : "Share this"}
                  </p>
                </div>
              )}
            </div>
          </div>

          <div className={styles.mainContent}>

            <div className={styles.groupPhotoContainerWrapper}>

              <div
                ref={photoGroupRef}
                className={styles.groupPhotoContainerActive}
              >
                {savedPoints.length === 0 && savedStoreScreens.length === 0 && savedAnimations.length === 0 && savedObScreens.length === 0 ? (
                  <ModalAppsEmpty
                    text="no screens added"
                    img="/img/groupEmptyIcon.svg"
                  />
                ) : (
                  <>
                    {savedAnimations.map((item, index) => (
                      <AnimationCard
                        key={index}
                        id={item.id}
                        url={item.saved_animation.app_video_animation.video}
                        name={item.saved_animation.app.name}
                        icon={item.saved_animation.app.icon}
                        shortname={item.saved_animation.app.shortname}
                        selectActive={selectCurrentGroupActive}
                        isSelected={Array.from(selectedCurrentGroups).some(
                          screen => screen.id === item.id
                        )}
                        onSelect={(id, url) => handleCurrentGroupSelect(
                          id,
                          url,
                          item.saved_animation.app_video_animation.id,
                          'animation'
                        )}
                        appId={item.saved_animation.app.id}
                        slug={item.saved_animation.app.slug}
                        videoId={item.saved_animation.app_video_animation.app_video}
                      />
                    ))}
                    {savedPoints.map((item, index) => (
                      <ModalSaveModeSavedGroupScreens
                        key={index}
                        selectCurrentGroupActive={selectCurrentGroupActive}
                        handleCurrentGroupSelect={handleCurrentGroupSelect}
                        item={item}
                        openGroupPhotoModal={openGroupPhotoModal}
                        selectedCurrentGroups={selectedCurrentGroups}
                      />
                    ))}
                    {savedStoreScreens.map((item, index) => (
                      <ModalSaveModeSavedGroupStore
                        key={index}
                        selectCurrentGroupActive={selectCurrentGroupActive}
                        handleCurrentGroupSelect={handleCurrentGroupSelect}
                        item={item}
                        openGroupPhotoModal={openGroupPhotoModal}
                        selectedCurrentGroups={selectedCurrentGroups}
                      />
                    ))}
                    {savedObScreens.map((item, index) => (
                      <WebOnboardingCard
                        key={index}
                        id={item.id}
                        url={item.saved_ob_screen.screen}
                        name={item.saved_ob_screen.app.name}
                        icon={item.saved_ob_screen.app.icon}
                        shortname={item.saved_ob_screen.app.shortname}
                        selectActive={selectCurrentGroupActive}
                        isSelected={Array.from(selectedCurrentGroups).some(
                          screen => screen.id === item.id
                        )}
                        onSelect={(id, url) => handleCurrentGroupSelect(
                          id,
                          url,
                          item.saved_ob_screen.app_ob_video,
                          'onboarding'
                        )}
                        appId={item.saved_ob_screen.app.id}
                        onboardingVideoId={item.saved_ob_screen.app_ob_video}
                        slug={item.saved_ob_screen.app.slug}
                        timeFrom={parseFloat(item.saved_ob_screen.ts)}
                      />
                    ))}
                  </>
                )}
              </div>
            </div>
          </div>


          <Modal
            isOpen={isGroupPhotoModalOpen}
            onRequestClose={() => setIsGroupPhotoModalOpen(false)}
            contentLabel="Group Photo Modal"
            className={styles.modalGroup}
            overlayClassName={styles.overlay}
          >
            <div className={styles.modalContent}>
              <div className={styles.containerImage}>
                <button
                  className={styles.modalContentPhotoButton}
                  onClick={() => {
                    if (typeScreen === "Store") {
                      handleClickStorePhone(timestamp!, true, name, imageIdStore);
                    } else {
                      handleClickPhone(timestamp!, true, name, appId!, slugText);
                    }
                  }}
                >
                  <img
                    className={styles.modalContentPhoto}
                    src={selectedGroupPhoto}
                    alt="Enlarged pic"
                    loading="lazy"
                  />
                </button>
              </div>
              <img
                className={styles.arrowRight}
                src="/img/whiteIcon/ChevronRight.svg"
                alt="arrowRightImg"
                onClick={handleNextGroupPhoto}
                loading="lazy"
              />
              <img
                className={styles.arrowLeft}
                src="/img/whiteIcon/ChevronLeft.svg"
                alt="arrowLeftImg"
                onClick={handlePrevGroupPhoto}
                loading="lazy"
              />
              <div className={styles.modalFooterContainer}>
                <div className={styles.modalFooter}>
                  <button onClick={handleDeleteScreenFromGroup}>
                    <img
                      className={styles.deleteIcon}
                      src="/img/whiteIcon/Delete.svg"
                      alt="deleteImg"
                      loading="lazy"
                    />
                    <p>Delete</p>
                  </button>
                </div>
              </div>
            </div>
          </Modal>
        </div>
      </div>
    </div>
  );
};

export { SavedModeGroupDetailView };