import React, { useState } from 'react';
import styles from "./ChangeRole.module.scss";
import { useMe } from '@/Hooks/useMe';

interface ChangeRoleProps {
  member: { id: number; currentRole: string; name: string };
  onClose: () => void;
  onConfirm: (newRole: string, newOrgName?: string) => void;
  isOpen: boolean;
}

const ChangeRole: React.FC<ChangeRoleProps> = ({ member, onClose, onConfirm, isOpen }) => {
  const [newRole, setNewRole] = useState(member.currentRole);
  const [showOwnerConfirm, setShowOwnerConfirm] = useState(false);
  const [showSoleOwnerConfirm, setShowSoleOwnerConfirm] = useState(false);
  const [newOrgName, setNewOrgName] = useState('');
  const { me } = useMe();

  if (!isOpen) return null;

  const handleConfirm = () => {
    if (newRole === 'owner') {
      const isOnlyOwner = me.organizations.filter(org => org.role === 'owner').length === 1;
      if (isOnlyOwner) {
        setShowSoleOwnerConfirm(true);
      } else {
        setShowOwnerConfirm(true);
      }
    } else {
      onConfirm(newRole);
    }
  };

  return (
    <div className={styles.overlay}>
      {!showOwnerConfirm && !showSoleOwnerConfirm && (
        <div className={styles.modal}>
          <div className={styles.close}>
            <img src="/img/gradientIcon/Warning.svg" alt="close" onClick={onClose} />
          </div>
          <h1 className={styles.title}>Change Role for {member.name}</h1>
          <p className={styles.text}>Current role: {member.currentRole}</p>
          <select
            value={newRole}
            onChange={(e) => setNewRole(e.target.value)}
            className={styles.selectRole}
          >
            <option value="member">Member</option>
            <option value="admin">Admin</option>
            <option value="owner">Owner</option>
          </select>
          <div className={styles.buttonContainer}>
            <button onClick={onClose} className={styles.cancelButton}>Cancel</button>
            <button onClick={handleConfirm} className={styles.confirmButton}>Confirm</button>
          </div>
        </div>
      )}

      {showSoleOwnerConfirm && (
        <div className={styles.modal}>
          <div className={styles.close}>
            <img src="/img/gradientIcon/Warning.svg" alt="close" onClick={() => setShowSoleOwnerConfirm(false)} />
          </div>
          <h1 className={styles.title}>Warning: Last Owner Role</h1>
          <p className={styles.text}>
            You are currently the only owner across all your organizations. If you transfer ownership,
            you won't have owner access to any organization. Are you sure you want to proceed?
          </p>
          <input
            type="text"
            value={newOrgName}
            onChange={(e) => setNewOrgName(e.target.value)}
            placeholder="Enter new organization name"
            className={styles.confirmInput}
          />
          <div className={styles.buttonContainer}>
            <button onClick={() => setShowSoleOwnerConfirm(false)} className={styles.cancelButton}>Cancel</button>
            <button
              onClick={() => {
                if (newOrgName.trim()) {
                  setShowSoleOwnerConfirm(false);
                  onConfirm(newRole, newOrgName);
                }
              }}
              disabled={!newOrgName.trim()}
              className={`${styles.confirmButton} ${!newOrgName.trim() ? styles.disabled : ''}`}
            >
              Continue
            </button>
          </div>
        </div>
      )}

      {showOwnerConfirm && (
        <div className={styles.modal}>
          <div className={styles.close}>
            <img src="/img/gradientIcon/Warning.svg" alt="close" onClick={() => setShowOwnerConfirm(false)} />
          </div>
          <h1 className={styles.title}>Confirm Owner Change</h1>
          <p className={styles.text}>
            Are you sure you want to make this person an owner of the team? You will lose owner access, you will be switched to admin.
          </p>
          <div className={styles.buttonContainer}>
            <button onClick={() => setShowOwnerConfirm(false)} className={styles.cancelButton}>Cancel</button>
            <button onClick={() => onConfirm(newRole)} className={styles.confirmButton}>
              Confirm
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ChangeRole;
