import React from "react";
import { VideoLabelsResponseData } from "@/features/auth/types/auth";
import styles from "./RenderLabels.module.scss";
interface RenderLabelsProps {
  apiDataVideosLabelsById: VideoLabelsResponseData | undefined;
  photoWidth: number;
  videoDuration: number;
}

const RenderLabels = React.forwardRef<HTMLDivElement, RenderLabelsProps>(
  ({ apiDataVideosLabelsById, photoWidth, videoDuration }, ref) => {
    const [hoveredIndex, setHoveredIndex] = React.useState<number | null>(null);
    const [hoverTimeout, setHoverTimeout] =
      React.useState<NodeJS.Timeout | null>(null);
    const photosWidth =
      ref && typeof ref !== "function" && ref.current
        ? ref.current.offsetWidth
        : 0;

    const labels = apiDataVideosLabelsById!.results.map((label) => ({
      startPosition: (parseFloat(label.time_from) / videoDuration) * 100,
      endPosition: (parseFloat(label.time_to) / videoDuration) * 100,
      startTime: parseFloat(label.time_from),
      label: label.label,
    }));

    const handleMouseEnter = (index: number) => {
      const timeout = setTimeout(() => {
        setHoveredIndex(index);
      }, 300);
      setHoverTimeout(timeout);
    };

    const handleMouseLeave = () => {
      if (hoverTimeout) {
        clearTimeout(hoverTimeout);
        setHoverTimeout(null);
      }
      setHoveredIndex(null);
    };

    const shouldShowLabel = (currentIndex: number) => {
      const currentLabel = labels[currentIndex];

      if (currentLabel.label === "paywall") {
        return true;
      }

      const hasNearbyLabel = labels.some((label, index) => {
        if (index === currentIndex) return false;
        const timeDiff = Math.abs(label.startTime - currentLabel.startTime);
        return timeDiff < 7;
      });

      return !hasNearbyLabel || hoveredIndex === currentIndex;
    };

    return labels.map((label, index) => {
      const labelWidth =
        ((label.endPosition - label.startPosition) * photosWidth) / 100;

      return (
        <div
          key={index}
          className={`${styles.root} ${label.label === "paywall" ? styles.paywallLabel : ""
            }`}
          style={{
            left: `${label.startPosition}%`,
            width: `${Math.max(labelWidth, photoWidth) < 75
              ? Math.max(labelWidth, photoWidth) + 30
              : Math.max(labelWidth, photoWidth)
              }px`,
            zIndex: 2,
          }}
          onMouseEnter={() => handleMouseEnter(index)}
          onMouseLeave={handleMouseLeave}
        >
          {shouldShowLabel(index) && <span>{label.label}</span>}
        </div>
      );
    });
  },
);

export default RenderLabels;
