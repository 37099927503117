import React, { ChangeEvent, RefObject, useState } from 'react';
import styles from "./Account.module.scss";
import { useGradient } from '@/Hooks/useGradient';
import { SidebarContext } from '@/Views/AccountTeamsView/AccountTeamsRootView';
import { useContext } from 'react';

interface AccountProps {
  me: {
    profile_picture?: string;
    username: string;
    first_name: string;
    email: string;
  } | null;
  formData: {
    username: string;
    first_name: string;
    email: string;
  };
  profilePicture: File | null;
  fileInputRef: RefObject<HTMLInputElement>;
  handleChange: (e: ChangeEvent<HTMLInputElement>) => void;
  handleSave: () => void;
  isUpdating: boolean;
  handleFileChange: (e: ChangeEvent<HTMLInputElement>) => void;
  handleChangePassword: (passwordForm: { currentPassword: string, newPassword: string, confirmPassword: string }) => void;
}

const Account = ({
  me,
  formData,
  profilePicture,
  fileInputRef,
  handleChange,
  handleSave,
  isUpdating,
  handleFileChange,
  handleChangePassword
}: AccountProps) => {
  const { isSidebarOpen, setIsSidebarOpen } = useContext(SidebarContext);
  const [isChangePassword, setIsChangePassword] = useState(false);
  const [passwordForm, setPasswordForm] = useState({
    currentPassword: '',
    newPassword: '',
    confirmPassword: ''
  });

  const letterGradient = useGradient(me?.email?.[0] || 'A');

  const handlePasswordInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setPasswordForm(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const resetPasswordForm = () => {
    setPasswordForm({
      currentPassword: '',
      newPassword: '',
      confirmPassword: ''
    });
  };

  const handlePasswordSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    await handleChangePassword(passwordForm);
    resetPasswordForm();
    setIsChangePassword(false);
  };

  return (
    <>
      <div className={styles.mobileHeader}>
        <button className={styles.mobileHeaderButtonLeft} onClick={() => setIsSidebarOpen(true)}>
          <img width={24} height={24} src="/img/ColorIconAccount.svg" alt="team" />
        </button>
        <h1 className={styles.mobileHeaderTitle}>Account area</h1>
        <button
          className={styles.mobileHeaderButtonRight}
          onClick={() => setIsSidebarOpen(true)}
        >
          <img src="/img/blackIcon/Close.svg" alt="close" />
        </button>
      </div>

      <p className={styles.profileInfo}>Profile Info</p>

      <form className={styles.profileSection} onSubmit={(e) => e.preventDefault()}>
        <input
          type="file"
          ref={fileInputRef}
          style={{ display: 'none' }}
          onChange={handleFileChange}
          accept="image/*"
        />
        <button className={styles.buttonFile} onClick={() => fileInputRef.current?.click()}>
          {profilePicture ? (
            <img
              src={URL.createObjectURL(profilePicture)}
              alt="Organization"
              className={styles.teamIcon}
            />
          ) : me?.profile_picture ? (
            <img
              src={me.profile_picture}
              alt="Organization"
              className={styles.teamIcon}
            />
          ) : (
            <p
              className={styles.teamIcon}
              style={{ background: letterGradient }}
            >
              {me?.email?.[0]}
            </p>
          )}
        </button>
      </form>

      <form onSubmit={(e) => {
        e.preventDefault();
        handleSave();
      }}>

        <div className={styles.infoSection}>
          <label className={styles.label} htmlFor="first_name">First Name</label>
          <div className={styles.inputWrapper}>
            <input
              type="text"
              id="first_name"
              name="first_name"
              value={formData.first_name}
              onChange={handleChange}
              className={styles.input}
            />
          </div>
        </div>
        <div className={styles.infoSection}>
          <label className={styles.label} htmlFor="last_name">Last Name</label>
          <div className={styles.inputWrapper}>
            <input
              type="text"
              id="last_name"
              name="last_name"
              value={formData.last_name}
              onChange={handleChange}
              className={styles.input}
            />
          </div>
        </div>
        <div className={styles.infoSection}>
          <label className={styles.label} htmlFor="email">Email address</label>
          <div className={styles.inputWrapper}>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              className={styles.input}
            />
          </div>
        </div>
        <div className={styles.saveSection}>
          <button
            type="submit"
            className={styles.buttonPrimary}
            disabled={isUpdating}
          >
            {isUpdating ? 'Saving...' : 'Save Changes'}
          </button>
        </div>
      </form>


          <div className={styles.modal}>
            <div className={styles.modalHeader}>
              <h1>Change Password</h1>
            </div>
            <form onSubmit={handlePasswordSubmit}>
              <div className={styles.infoSection}>
                <label className={styles.label} htmlFor="currentPassword">Current Password</label>
                <div className={styles.inputWrapper}>
                  <input
                    type="password"
                    id="currentPassword"
                    name="currentPassword"
                    value={passwordForm.currentPassword}
                    onChange={handlePasswordInputChange}
                    className={styles.input}
                  />
                </div>
              </div>
              <div className={styles.modalBody}>
              <div className={styles.infoSection}>
                <label className={styles.label} htmlFor="newPassword">New Password</label>
                <div className={styles.inputWrapper}>
                  <input
                    type="password"
                    id="newPassword"
                    name="newPassword"
                    value={passwordForm.newPassword}
                    onChange={handlePasswordInputChange}
                    className={styles.input}
                  />
                </div>
              </div>
              <div className={styles.infoSection}>
                <label className={styles.label} htmlFor="confirmPassword">Confirm Password</label>
                <div className={styles.inputWrapper}>
                  <input
                    type="password"
                    id="confirmPassword"
                    name="confirmPassword"
                    value={passwordForm.confirmPassword}
                    onChange={handlePasswordInputChange}
                    className={styles.input}
                  />
                </div>
              </div>
              </div>
              <div className={styles.modalActions}>
                <button
                  type="button"
                  className={styles.buttonSecondary}
                  onClick={() => setIsChangePassword(false)}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className={styles.buttonPrimary}
                >
                  Save Changes
                </button>
              </div>
            </form>
          </div>
    </>
  );
};

export default Account;
