// redux
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
// types
import { FilterInputSearchState } from "../types/filterInputSearchTypes";

const initialState: FilterInputSearchState = {
  appName: "",
  isNameSearch: false,
};

const filterInputSearchSlice = createSlice({
  name: "filterInputSearch",
  initialState,
  reducers: {
    setAppName: (state, action: PayloadAction<string>) => {
      state.appName = action.payload;
    },
    toggleNameSearch: (state) => {
      state.isNameSearch = !state.isNameSearch;
    },
    activeNameSearch: (state) => {
      state.isNameSearch = true;
    },
    deactiveNameSearch: (state) => {
      state.isNameSearch = false;
    },
  },
});

export const {
  actions: filterInputSearchActions,
  reducer: filterInputSearchReducer,
} = filterInputSearchSlice;
