// react
import React from "react";
// date-fns
import { format, subMonths, subYears } from "date-fns";
// redux
import { useDispatch, useSelector } from "react-redux";
// entities
import { counterActions } from "@/entities/counter";
// widgets
import CalendarRelease from "@/widgets/calendarRelease/ui/CalendarRelease/CalendarRelease";
// entities
import { filterAppsActions, getFilterAppsState } from "@/entities/filterApps";
// styles
import styles from "./ReleaseDateModal.module.scss";
import { filterAppsDataActions } from "@/entities/filterAppsData";
import { handleFocus } from "@/widgets/inputSearch/libs/const/handleFocus";
import StoredCount from "@/shared/ui/storedcount/StoredCound";

import { ACTIVE_VIEW_HOME } from "@/Views/constants";

export interface ReleaseDateModalProps {
  selectedYearMinRelease: number | null;
  selectedYearMaxRelease: number | null;
  setSelectedYearMinRelease: (year: number | null) => void;
  setSelectedYearMaxRelease: (year: number | null) => void;
  monthMin: Date;
  setMonthMin: (state: Date) => void;
  monthMax: Date;
  setMonthMax: (state: Date) => void;
  rootActiveView: string;
}

const ReleaseDateModal: React.FC<ReleaseDateModalProps> = ({
  selectedYearMinRelease,
  selectedYearMaxRelease,
  setSelectedYearMinRelease,
  setSelectedYearMaxRelease,
  monthMin,
  setMonthMin,
  monthMax,
  setMonthMax,
  rootActiveView,
}) => {
  // dispatch
  const dispatch = useDispatch();
  // selectors
  const {
    activeButtonReleaseDate,
    isActiveCalendarMin,
    isActiveCalendarMax,
    selectedDataMin,
    selectedDataMax,
    isActiveCalendar,
  } = useSelector(getFilterAppsState);
  const currentYear = new Date().getFullYear();

  const [yearCalendarMin, setYearCalendarMin] = React.useState(false);
  const [yearCalendarMax, setYearCalendarMax] = React.useState(false);

  const [isResetVisible, setIsResetVisible] = React.useState(false);

  React.useEffect(() => {
    setIsResetVisible(
      selectedDataMin !== "Min" ||
      selectedDataMax !== "Max" ||
      activeButtonReleaseDate !== null,
    );
  }, [selectedDataMin, selectedDataMax, activeButtonReleaseDate]);

  const handlePreviousMonthMin = () => {
    const newMonth = new Date(monthMin);
    newMonth.setMonth(monthMin.getMonth() - 1);
    setMonthMin(newMonth);
  };

  const handleNextMonthMin = () => {
    const newMonth = new Date(monthMin);
    newMonth.setMonth(monthMin.getMonth() + 1);
    setMonthMin(newMonth);
  };

  const handlePreviousMonthMax = () => {
    const newMonth = new Date(monthMax);
    newMonth.setMonth(monthMax.getMonth() - 1);
    setMonthMax(newMonth);
  };

  const handleNextMonthMax = () => {
    const newMonth = new Date(monthMax);
    newMonth.setMonth(monthMax.getMonth() + 1);
    setMonthMax(newMonth);
  };

  const handleClick = (label: string) => {
    if (activeButtonReleaseDate === label) {
      dispatch(filterAppsActions.setSelectedDataMin("Min"));
      dispatch(filterAppsActions.setSelectedDataMax("Max"));
      dispatch(filterAppsActions.setReleasedFrom(""));
      dispatch(filterAppsActions.setReleasedTo(""));
      dispatch(filterAppsDataActions.resetFilterAppDataSorted());
      dispatch(filterAppsDataActions.resetFilterAppDataSortedAll());
      dispatch(filterAppsDataActions.resetFilterAppData());
      dispatch(filterAppsActions.resetPageFilter());
      dispatch(filterAppsActions.resetPageFilterSidebar());
      dispatch(filterAppsActions.setActiveButtonReleaseDate(null));

      if (isActiveCalendar) {
        dispatch(counterActions.removeFromArray("Release Date"));
        dispatch(filterAppsActions.deactiveReleaseDate());
      }

      return;
    }

    const currentDate = new Date();
    let minDate;
    let maxDate;

    switch (label) {
      case "Last 3 months":
        minDate = subMonths(currentDate, 3);
        maxDate = currentDate;
        break;
      case "Last 1 year":
        minDate = subYears(currentDate, 1);
        maxDate = currentDate;
        break;
      case "Last 2 years":
        minDate = subYears(currentDate, 2);
        maxDate = currentDate;
        break;
      default:
        return;
    }

    const formattedDateMin =
      minDate instanceof Date ? format(minDate, "yyyy-MM-dd") : minDate;
    const formattedDateMax =
      maxDate instanceof Date ? format(maxDate, "yyyy-MM-dd") : maxDate;

    dispatch(filterAppsActions.setSelectedDataMin(minDate));
    dispatch(filterAppsActions.setSelectedDataMax(maxDate));
    dispatch(filterAppsActions.setReleasedFrom(formattedDateMin));
    dispatch(filterAppsActions.setReleasedTo(formattedDateMax));
    dispatch(filterAppsActions.setActiveButtonReleaseDate(label));
    dispatch(filterAppsDataActions.resetFilterAppDataSorted());
    dispatch(filterAppsDataActions.resetFilterAppDataSortedAll());
    dispatch(filterAppsDataActions.resetFilterAppData());
    dispatch(filterAppsActions.resetPageFilter());
    dispatch(filterAppsActions.resetPageFilterSidebar());
    setMonthMin(minDate);
    setMonthMax(maxDate);

    if (!isActiveCalendar) {
      dispatch(counterActions.addToArray("Release Date"));
      dispatch(filterAppsActions.activeReleaseDate());
    }
  };

  const handleReset = () => {
    if (selectedDataMin !== "Min" || selectedDataMax !== "Max") {
      const currentDate = new Date();
      dispatch(filterAppsActions.setSelectedDataMin("Min"));
      dispatch(filterAppsActions.setSelectedDataMax("Max"));
      dispatch(filterAppsActions.setActiveButtonReleaseDate(null));
      dispatch(filterAppsActions.setReleasedFrom(""));
      dispatch(filterAppsActions.setReleasedTo(""));
      dispatch(filterAppsDataActions.resetFilterAppDataSorted());
      dispatch(filterAppsDataActions.resetFilterAppDataSortedAll());
      dispatch(filterAppsDataActions.resetFilterAppData());
      dispatch(filterAppsActions.resetPageFilter());
      dispatch(filterAppsActions.resetPageFilterSidebar());
      setSelectedYearMinRelease(null);
      setSelectedYearMaxRelease(null);
      setMonthMin(currentDate);
      setMonthMax(currentDate);

      if (isActiveCalendar) {
        dispatch(counterActions.removeFromArray("Release Date"));
        dispatch(filterAppsActions.deactiveReleaseDate());
      }
    }
  };

  const handleClickMin = () => {
    if (isActiveCalendarMax) {
      dispatch(filterAppsActions.setActiveCalendarMax(false));
    }

    dispatch(filterAppsActions.toggleCalendarMin());
    setYearCalendarMax(false);
  };

  const handleClickMax = () => {
    if (isActiveCalendarMin) {
      dispatch(filterAppsActions.setActiveCalendarMin(false));
    }

    dispatch(filterAppsActions.toggleCalendarMax());
    setYearCalendarMin(false);
  };

  const handleSelectedMin = (date: Date | string) => {
    if (date instanceof Date) {
      const formattedDate = format(date, "yyyy-MM-dd");
      if (selectedDataMax === "Max" || date <= new Date(selectedDataMax)) {
        dispatch(filterAppsActions.setSelectedDataMin(date));
        dispatch(filterAppsActions.setReleasedFrom(formattedDate));
      }
    } else {
      dispatch(filterAppsActions.setSelectedDataMin("Min"));
      dispatch(filterAppsActions.setReleasedFrom(""));
    }

    // Сброс активной кнопки при ручном изменении даты
    if (selectedDataMin !== "Min" || selectedDataMax !== "Max") {
      dispatch(filterAppsActions.setActiveButtonReleaseDate(null));
    }

    dispatch(filterAppsActions.setActiveCalendarMin(false));
    dispatch(filterAppsDataActions.resetFilterAppDataSorted());
    dispatch(filterAppsDataActions.resetFilterAppDataSortedAll());
    dispatch(filterAppsDataActions.resetFilterAppData());
    dispatch(filterAppsActions.resetPageFilter());
    dispatch(filterAppsActions.resetPageFilterSidebar());
  };

  const handleSelectedMax = (date: Date | string) => {
    if (date instanceof Date) {
      const formattedDate = format(date, "yyyy-MM-dd");
      if (selectedDataMin === "Min" || date >= new Date(selectedDataMin)) {
        dispatch(filterAppsActions.setSelectedDataMax(date));
        dispatch(filterAppsActions.setReleasedTo(formattedDate));
      }
    } else {
      dispatch(filterAppsActions.setSelectedDataMax("Max"));
      dispatch(filterAppsActions.setReleasedTo(""));
    }

    // Сброс активной кнопки при ручном изменении даты
    if (selectedDataMin !== "Min" || selectedDataMax !== "Max") {
      dispatch(filterAppsActions.setActiveButtonReleaseDate(null));
    }

    dispatch(filterAppsActions.setActiveCalendarMax(false));
    dispatch(filterAppsDataActions.resetFilterAppDataSorted());
    dispatch(filterAppsDataActions.resetFilterAppDataSortedAll());
    dispatch(filterAppsDataActions.resetFilterAppData());
    dispatch(filterAppsActions.resetPageFilter());
    dispatch(filterAppsActions.resetPageFilterSidebar());
  };

  const formatNumber = (selectedData: string | Date): string => {
    if (selectedData === "Max" || !selectedData) return "Max";
    if (selectedData === "Min" || !selectedData) return "Min";

    const date =
      selectedData instanceof Date ? selectedData : new Date(selectedData);

    if (Number.isNaN(date.getTime())) {
      return typeof selectedData === "string"
        ? `1 Jan ${selectedData}`
        : "Invalid Date";
    }

    // Check screen width and format date accordingly
    return window.innerWidth < 550
      ? format(date, "d MMM") // Shows only day and month (e.g., "15 Jan")
      : format(date, "d MMM yyyy"); // Shows full date (e.g., "15 Jan 2024")
  };

  return (
    <div className={styles.root}>
      <div className={styles.menuButtons}>
        <div
          onClick={() => handleClick("Last 3 months")}
          className={
            activeButtonReleaseDate === "Last 3 months"
              ? styles.buttonItemActive
              : styles.buttonItem
          }
        >
          <button type="button">Last 3 months</button>
        </div>
        <div
          onClick={() => handleClick("Last 1 year")}
          className={
            activeButtonReleaseDate === "Last 1 year"
              ? styles.buttonItemActive
              : styles.buttonItem
          }
        >
          <button type="button">Last 1 year</button>
        </div>
        <div
          onClick={() => handleClick("Last 2 years")}
          className={
            activeButtonReleaseDate === "Last 2 years"
              ? styles.buttonItemActive
              : styles.buttonItem
          }
        >
          <button type="button">Last 2 years</button>
        </div>
      </div>
      <div className={styles.customMenuButton}>
        <div onClick={handleClickMin} className={styles.min}>
          <input
            type="text"
            value={formatNumber(selectedDataMin)}
            maxLength={8}
            readOnly
          />
          <img
            className={styles.arrowReleaseDate}
            src="/img/blackIcon/DropDown.svg"
            alt="arrowReleaseDateImg"

            loading="lazy"
          />
        </div>
        <span className={styles.textTo}>to</span>
        <div onClick={handleClickMax} className={styles.max}>
          <input
            type="text"
            value={formatNumber(selectedDataMax)}
            readOnly
            maxLength={8}
          />
          <img
            className={styles.arrowReleaseDate}
            src="/img/blackIcon/DropDown.svg"
            alt="arrowReleaseDateImg"

            loading="lazy"
          />
        </div>
      </div>
      <div className={styles.flex}>
        {rootActiveView === ACTIVE_VIEW_HOME && (
          <StoredCount onFocus={() => handleFocus(dispatch)} />
        )}
        {isResetVisible && (
          <div onClick={handleReset} className={styles.reset}>
            <img
              src="/img/blackIcon/Close.svg"
              alt="resetImg"
              loading="lazy"
            />
          </div>
        )}
      </div>
      {isActiveCalendarMin && (
        <CalendarRelease
          setSelectedDate={(date: Date | undefined) => handleSelectedMin(date!)}
          month={monthMin}
          setMonthMin={setMonthMin}
          handlePreviousMonth={handlePreviousMonthMin}
          handleNextMonth={handleNextMonthMin}
          yearCalendarMin={yearCalendarMin}
          setYearCalendarMin={setYearCalendarMin}
          isActiveCalendarMin={isActiveCalendarMin}
          selectedYearMinRelease={selectedYearMinRelease!}
          currentYear={currentYear}
          selectedDataMax={selectedDataMax}
          selectedDataMin={selectedDataMin}
        />
      )}
      {isActiveCalendarMax && (
        <CalendarRelease
          setSelectedDate={(date: Date | undefined) => handleSelectedMax(date!)}
          month={monthMax}
          setMonthMax={setMonthMax}
          handlePreviousMonth={handlePreviousMonthMax}
          handleNextMonth={handleNextMonthMax}
          yearCalendarMin={yearCalendarMin}
          yearCalendarMax={yearCalendarMax}
          setYearCalendarMax={setYearCalendarMax}
          selectedDataMax={selectedDataMax}
          selectedDataMin={selectedDataMin}
          isActiveCalendarMax={isActiveCalendarMax}
          selectedYearMaxRelease={selectedYearMaxRelease!}
          currentYear={currentYear}
        />
      )}
    </div>
  );
};

export default ReleaseDateModal;
