export const dataItem = [
  // { text: "None", filter: "" },
  { text: "Recently added", filter: "created_at" },
  { text: "App Name", filter: "name" },
  { text: "Installs", filter: "download" },
  { text: "App Release Date", filter: "release_date" },
  { text: "Revenue", filter: "revenue" },
  { text: "Onboarding Steps", filter: "onboarding_step_count" },
  { text: "Revenue per Install", filter: "rev_install_rate" },
];
