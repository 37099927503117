// react
import React from "react";
// react-day-picker
import { DayPicker } from "react-day-picker";
// date-fns
import { format } from "date-fns";
import { enUS } from "date-fns/locale";
// styles
import { useDispatch } from "react-redux";
import styles from "./Calendar.module.scss";
import ModalYearCalendar from "@/shared/ui/ModalYearCalendar/ModalYearCalendar";
import { filterAppsActions } from "@/entities/filterApps";

const customWeekdays = {
  longhand: [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ],
  shorthand: ["S", "M", "T", "W", "T", "F", "S"],
};

const customLocale = {
  ...enUS,
  localize: {
    ...enUS.localize,
    day: (n: number) => customWeekdays.shorthand[n],
  },
};

interface CalendarProps {
  setSelectedDate: (state: Date | undefined) => void;
  month: Date;
  setMonth: (state: Date) => void;
  handlePreviousMonth: () => void;
  handleNextMonth: () => void;
  yearCalendarMin: boolean;
  setYearCalendarMin: (state: boolean) => void;
  yearCalendarMax: boolean;
  setYearCalendarMax: (state: boolean) => void;
  isActiveCalendarMin: boolean;
  isActiveCalendarMax: boolean;
  selectedYearMaxUpdate?: number;
  selectedYearMinUpdate?: number;
  selectedDataMax?: string | Date;
  selectedDataMin?: string | Date;
  selectedDataRangeMax?: string | Date;
  currentYear?: number;
  setMonthMax?: (state: Date) => void;
  selectedYearMinRelease?: number;
  selectedDataRangeMin?: string | Date;
  setMonthMin?: (state: Date) => void;
  isDateDisabled: (date: Date) => boolean;
}

const Calendar: React.FC<CalendarProps> = ({
  setSelectedDate,
  month,
  setMonth,
  handlePreviousMonth,
  handleNextMonth,
  yearCalendarMin,
  setYearCalendarMin,
  yearCalendarMax,
  setYearCalendarMax,
  isActiveCalendarMin,
  isActiveCalendarMax,
  selectedYearMaxUpdate,
  selectedDataRangeMax,
  currentYear,
  setMonthMax,
  selectedYearMinUpdate,
  selectedDataRangeMin,
  setMonthMin,
  isDateDisabled,
}) => {
  const handleClickYear = () => {
    if (isActiveCalendarMin) {
      setYearCalendarMin(!yearCalendarMin);
    }

    if (isActiveCalendarMax) {
      setYearCalendarMax(!yearCalendarMax);
    }
  };

  React.useEffect(() => {
    console.log(
      "selectedDataRangeMax after render:",
      selectedDataRangeMax,
      selectedDataRangeMin,
    );
  }, [selectedDataRangeMax, selectedDataRangeMin]);

  const dispatch = useDispatch();

  const convertToDate = (
    value: string | Date | undefined,
  ): Date | undefined => {
    if (typeof value === "string") {
      return new Date(value);
    }
    return value instanceof Date ? value : undefined;
  };

  const onDateSelectMin = (newDate: Date) => {
    const maxDate =
      selectedDataRangeMax === "Max"
        ? new Date()
        : new Date(selectedDataRangeMax!);

    if (newDate <= maxDate) {
      dispatch(filterAppsActions.setSelectedDataRangeMin(newDate));
      setMonthMin!(newDate);
      dispatch(filterAppsActions.setUpdatedFrom(format(newDate, "yyyy-MM-dd")));
    }
  };

  const onDateSelectMax = (newDate: Date) => {
    console.log(selectedDataRangeMin, "selectedDataRangeMin");

    const minDate =
      selectedDataRangeMin === "Min"
        ? new Date()
        : new Date(selectedDataRangeMin!);
    console.log(minDate, "minDate");

    if (newDate >= minDate) {
      dispatch(filterAppsActions.setSelectedDataRangeMax(newDate));
      setMonthMax!(newDate);
      dispatch(filterAppsActions.setUpdatedTo(format(newDate, "yyyy-MM-dd")));
    }
  };

  return (
    <div className={styles.root}>
      <div className={styles.content}>
        <DayPicker
          mode="single"
          selected={convertToDate(
            isActiveCalendarMax ? selectedDataRangeMax : selectedDataRangeMin,
          )}
          onSelect={setSelectedDate}
          month={month}
          onMonthChange={setMonth}
          className={`${styles.datePicker}`}
          locale={customLocale}
          disabled={isDateDisabled}
          showOutsideDays
          components={{
            Caption: ({ displayMonth }) => (
              <div className={styles.nav}>
                <button
                  className={styles.navButtonLeft}
                  onClick={handlePreviousMonth}
                >
                  <img
                    src="/img/blackIcon/ChevronLeft.svg"
                    alt="calendarArrowIcon"
                    loading="lazy"
                  />
                </button>
                <div onClick={handleClickYear} className={styles.dateContainer}>
                  <span>
                    {format(displayMonth, "MMMM yyyy", {
                      locale: customLocale,
                    })}
                  </span>
                  <img
                    className={styles.arrowDate}
                    src="/img/blackIcon/DropDown.svg"
                    alt="arrowReleaseDateImg"

                    loading="lazy"
                  />
                </div>
                <button
                  className={styles.navButtonRight}
                  onClick={handleNextMonth}
                >
                  <img
                    src="/img/blackIcon/ChevronLeft.svg"
                    alt="calendarArrowIcon"
                    loading="lazy"
                  />
                </button>
              </div>
            ),
          }}
        />
        {yearCalendarMin && (
          <ModalYearCalendar
            selectedYear={selectedYearMinUpdate!}
            selectedDate={selectedDataRangeMin!}
            currentYear={currentYear!}
            setYearCalendar={setYearCalendarMin}
            setUpdate={(date) =>
              dispatch(filterAppsActions.setUpdatedFrom(date))
            }
            onDateSelect={onDateSelectMin}
          />
        )}
        {yearCalendarMax && (
          <ModalYearCalendar
            selectedYear={selectedYearMaxUpdate!}
            selectedDate={selectedDataRangeMax!}
            currentYear={currentYear!}
            setYearCalendar={setYearCalendarMax}
            setUpdate={(date) => dispatch(filterAppsActions.setUpdatedTo(date))}
            onDateSelect={onDateSelectMax}
          />
        )}
      </div>
    </div>
  );
};

export default Calendar;
