import React from "react";
import { useDispatch, useSelector } from "react-redux";
import styles from "./MontlyRevenue.module.scss";
import { filterAppsActions, getFilterAppsState } from "@/entities/filterApps";
import { filterAppsDataActions } from "@/entities/filterAppsData";
import { counterActions } from "@/entities/counter";
import StoredCount from "@/shared/ui/storedcount/StoredCound";
import { handleFocus } from "@/widgets/inputSearch/libs/const/handleFocus";

import { ACTIVE_VIEW_HOME } from "@/Views/constants";

type SetValueAction = (value: string | number) => {
  type: string;
  payload: string | number;
};

export interface MontlyRevenueProps {
  onSaveMin: (min: number) => void;
  onSaveMax: (max: number) => void;
  handleMinBlur: (
    value: string | number,
    setMinValueAction: SetValueAction,
    onSave: (value: number) => void,
  ) => void;
  handleMaxBlur: (
    value: string | number,
    setMinValueAction: SetValueAction,
    onSave: (value: number) => void,
  ) => void;
  isFetchingFilter: boolean;
  rootActiveView: string;
}

const MAX_REVENUE = 9999999999;
const MIN_REVENUE = 0;

const MontlyRevenue: React.FC<MontlyRevenueProps> = ({
  onSaveMin,
  onSaveMax,
  handleMinBlur,
  handleMaxBlur,
  isFetchingFilter,
  rootActiveView,
}) => {
  const { revenuegte, revenuelte, isActiveRevenue, activeButtonRevenue } =
    useSelector(getFilterAppsState);
  const dispatch = useDispatch();

  const resetStyles = () => {
    dispatch(filterAppsActions.setButtonRevenue(""));
  };

  const handleMinChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const eventValue = event.target.value.replace(/\./g, "");

    if (revenuegte !== "") {
      dispatch(filterAppsDataActions.resetFilterAppDataSorted());
      dispatch(filterAppsDataActions.resetFilterAppDataSortedAll());
      dispatch(filterAppsDataActions.resetFilterAppData());
      dispatch(filterAppsActions.resetPageFilter());
      dispatch(filterAppsActions.resetPageFilterSidebar());
    }

    dispatch(filterAppsActions.setRevenuegte(eventValue));
    onSaveMin(Number(eventValue) || MIN_REVENUE);

    if (isActiveRevenue && eventValue === "") {
      dispatch(counterActions.removeFromArray("Monthly Revenue"));
      dispatch(filterAppsActions.deactiveRevenue());
    }

    if (!isActiveRevenue) {
      dispatch(counterActions.addToArray("Monthly Revenue"));
      dispatch(filterAppsActions.activeRevenue());
    }

    resetStyles();
  };

  const handleMaxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const eventValue = event.target.value.replace(/\./g, "");

    if (revenuelte !== "") {
      dispatch(filterAppsDataActions.resetFilterAppDataSorted());
      dispatch(filterAppsDataActions.resetFilterAppDataSortedAll());
      dispatch(filterAppsDataActions.resetFilterAppData());
      dispatch(filterAppsActions.resetPageFilter());
      dispatch(filterAppsActions.resetPageFilterSidebar());
    }

    dispatch(filterAppsActions.setRevenuelte(eventValue));
    onSaveMax(Number(eventValue) || MAX_REVENUE);

    if (isActiveRevenue && eventValue === "") {
      dispatch(counterActions.removeFromArray("Monthly Revenue"));
      dispatch(filterAppsActions.deactiveRevenue());
    }

    if (!isActiveRevenue) {
      dispatch(counterActions.addToArray("Monthly Revenue"));
      dispatch(filterAppsActions.activeRevenue());
    }

    resetStyles();
  };

  const formatNumber = (num: string | number): string => {
    if (num === MAX_REVENUE || num === "Max") return "Max";
    if (num === MIN_REVENUE || num === "Min") return "Min";
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const { charCode } = event;
    if (charCode < 48 || charCode > 57) {
      event.preventDefault();
    }
  };

  const handleClick = (
    minValue: string | number,
    maxValue: string | number,
    label: string,
  ) => {
    if (!isFetchingFilter) {
      if (activeButtonRevenue === label) {
        dispatch(filterAppsActions.setRevenuegte("Min"));
        dispatch(filterAppsActions.setRevenuelte("Max"));
        onSaveMin(0);
        onSaveMax(0);
        dispatch(filterAppsDataActions.resetFilterAppDataSorted());
        dispatch(filterAppsDataActions.resetFilterAppDataSortedAll());
        dispatch(filterAppsDataActions.resetFilterAppData());
        dispatch(filterAppsActions.resetPageFilter());
        dispatch(filterAppsActions.resetPageFilterSidebar());
        dispatch(filterAppsActions.setButtonRevenue(null));

        if (isActiveRevenue) {
          dispatch(counterActions.removeFromArray("Monthly Revenue"));
          dispatch(filterAppsActions.deactiveRevenue());
        }

        return;
      }

      dispatch(filterAppsActions.setRevenuegte(minValue.toString()));
      dispatch(filterAppsActions.setRevenuelte(maxValue.toString()));
      dispatch(filterAppsActions.setButtonRevenue(label));

      dispatch(filterAppsDataActions.resetFilterAppDataSorted());
      dispatch(filterAppsDataActions.resetFilterAppDataSortedAll());
      dispatch(filterAppsDataActions.resetFilterAppData());
      dispatch(filterAppsActions.resetPageFilter());
      dispatch(filterAppsActions.resetPageFilterSidebar());

      onSaveMin(minValue === "Min" ? MIN_REVENUE : Number(minValue));
      onSaveMax(maxValue === "Max" ? MAX_REVENUE : Number(maxValue));

      if (!isActiveRevenue) {
        dispatch(counterActions.addToArray("Monthly Revenue"));
        dispatch(filterAppsActions.activeRevenue());
      }
    }
  };

  const handleReset = () => {
    if ((revenuegte !== "Min" || revenuelte !== "Max") && !isFetchingFilter) {
      dispatch(filterAppsActions.setRevenuegte("Min"));
      dispatch(filterAppsActions.setRevenuelte("Max"));
      onSaveMin(0);
      onSaveMax(0);
      dispatch(filterAppsDataActions.resetFilterAppDataSorted());
      dispatch(filterAppsDataActions.resetFilterAppDataSortedAll());
      dispatch(filterAppsDataActions.resetFilterAppData());
      dispatch(filterAppsActions.resetPageFilter());
      dispatch(filterAppsActions.resetPageFilterSidebar());
      dispatch(filterAppsActions.setButtonRevenue(null));

      if (isActiveRevenue) {
        dispatch(counterActions.removeFromArray("Monthly Revenue"));
        dispatch(filterAppsActions.deactiveRevenue());
      }
    }
  };

  const [isResetVisible, setIsResetVisible] = React.useState(false);

  React.useEffect(() => {
    setIsResetVisible(
      revenuegte !== "Min" ||
      revenuelte !== "Max" ||
      activeButtonRevenue !== null,
    );
  }, [revenuegte, revenuelte, activeButtonRevenue]);

  return (
    <div className={styles.root}>
      <div className={styles.menuButtons}>
        <div className={styles.menuButtonTop}>
          <div
            className={
              activeButtonRevenue === "Extra Small"
                ? styles.active
                : styles.disabled
            }
          >
            <button onClick={() => handleClick("Min", 10000, "Extra Small")}>
              <span>XS</span>
            </button>
          </div>
          <div
            className={
              activeButtonRevenue === "Small" ? styles.active : styles.disabled
            }
          >
            <button onClick={() => handleClick(10001, 30000, "Small")}>
              <span>S</span>
            </button>
          </div>
          <div
            className={
              activeButtonRevenue === "Medium" ? styles.active : styles.disabled
            }
          >
            <button onClick={() => handleClick(30001, 50000, "Medium")}>
              <span>M</span>
            </button>
          </div>
        </div>
        <div className={styles.menuButtonBottom}>
          <div
            className={
              activeButtonRevenue === "Large" ? styles.active : styles.disabled
            }
          >
            <button onClick={() => handleClick(50001, 100000, "Large")}>
              <span>L</span>
            </button>
          </div>
          <div
            className={
              activeButtonRevenue === "Extra Large"
                ? styles.active
                : styles.disabled
            }
          >
            <button onClick={() => handleClick(100001, 1000000, "Extra Large")}>
              <span>XL</span>
            </button>
          </div>
          <div
            className={
              activeButtonRevenue === "XXL" ? styles.active : styles.disabled
            }
          >
            <button
              className={
                activeButtonRevenue === "XXL"
                  ? styles.xlItemActive
                  : styles.xlItem
              }
              onClick={() => handleClick(1000001, "Max", "XXL")}
            >
              <span>XXL</span>
            </button>
          </div>
        </div>
      </div>
      <div className={styles.customMenuButton}>
        <div className={styles.min}>
          <span>$</span>
          <input
            type="text"
            value={formatNumber(revenuegte)}
            onChange={handleMinChange}
            onKeyPress={handleKeyPress}
            maxLength={8}
            onFocus={() =>
              revenuegte === "Min" &&
              dispatch(filterAppsActions.setRevenuegte(""))
            }
            onBlur={() =>
              handleMinBlur(
                revenuegte,
                filterAppsActions.setRevenuegte,
                onSaveMin,
              )
            }
          />
        </div>
        <span className={styles.textTo}>to</span>
        <div className={styles.max}>
          <span>$</span>
          <input
            type="text"
            value={formatNumber(revenuelte)}
            onChange={handleMaxChange}
            onKeyPress={handleKeyPress}
            maxLength={8}
            onFocus={() =>
              revenuelte === "Max" &&
              dispatch(filterAppsActions.setRevenuelte(""))
            }
            onBlur={() =>
              handleMaxBlur(
                revenuelte,
                filterAppsActions.setRevenuelte,
                onSaveMax,
              )
            }
          />
        </div>
      </div>
      <div className={styles.flex}>
        {rootActiveView === ACTIVE_VIEW_HOME && (
          <StoredCount onFocus={() => handleFocus(dispatch)} />
        )}
        {isResetVisible && (
          <div onClick={handleReset} className={styles.reset}>
            <img
              src="/img/blackIcon/Close.svg"
              alt="resetImg"
              loading="lazy"
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default MontlyRevenue;
