import React from 'react';
import styles from "./Switcher.module.scss";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { getFilterState } from '@/entities/filter';
import { useSelector } from 'react-redux';

const SwitcherContext = ({ onClick }) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const { isOcrSelected } = useSelector(getFilterState);
    const [query, setQuery] = useState(searchParams.get('q'))

    useEffect(() => {
        setQuery(searchParams.get('q'));
    }, [searchParams]);

    const Show = () => {
        if (isOcrSelected == false) {
            return (
                <>
                    <img src="/img/blackIcon/SparkRight.svg" alt="icon" />
                    <p className={styles.ShowText}>{query}</p>
                </>
            )
        } else if (isOcrSelected == true) {
            return (
                <>
                    <img src="/img/blackIcon/TextInscreen.svg" alt="icon" />
                    <p className={styles.ShowText}>{query}</p>
                </>
            )
        }
    }

    return (
        <div className={styles.container} onClick={onClick}>
            <div className={styles.menuGroupNew}>
                <Show />
            </div>
        </div>
    );
};

export default SwitcherContext;
