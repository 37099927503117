import styles from "./Error.module.scss";

const Error = () => {
  return (
    <div className={styles.root}>
      <h1>
        404
      </h1>
      <h3>
        Not Found
      </h3>
      <p>
        The requested URL was not found on this server.
      </p>
    </div>
  );
};

export default Error;
