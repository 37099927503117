import { useEffect, useRef, useState } from "react";
import { useOutletContext } from 'react-router-dom'
import ModalSaveMode from "@/widgets/modalSaveMode/ui/ModalSaveMode/ModalSaveMode";
import {
  useGetSavedPointsAllQuery
} from "@/features/auth/api/authAPI";
import { useCurrentOrganization } from '@/Hooks'

const SavedModeVideoView = () => {

  const currenOrganization = useCurrentOrganization()
  const { zoom, sortkey, userFilter } = useOutletContext()
  const [page, setPage] = useState(1);
  const PAGE_SIZE = 10;
  const [allResults, setAllResults] = useState<any[]>([]);

  const { data: savedPoints, refetch: refetchSavedPoints, isLoading, isFetching } = useGetSavedPointsAllQuery({
    organizationUuid: currenOrganization?.uuid,
    order: sortkey,
    user: userFilter,
    page,
    pageSize: PAGE_SIZE
  }, {
    skip: !currenOrganization
  });

  useEffect(() => {
    if (savedPoints?.results && page === 1) {
      setAllResults(savedPoints.results);
    } else if (savedPoints?.results) {
      setAllResults(prev => [...prev, ...savedPoints.results]);
    }
  }, [savedPoints?.results, page]);

  const combinedData = {
    ...savedPoints,
    results: allResults
  };

  const [activeIndex, setActiveIndex] = useState(0);
  const photoGroups = useRef<HTMLDivElement>(null);

  const applyZoom = (zoom: number) => {
    const photoCardListElement = photoGroups.current;

    if (photoCardListElement) {
      photoCardListElement.style.setProperty("--zoom-level", zoom.toString());
    }
  };

  useEffect(() => {
    applyZoom(zoom);
  }, [zoom]);

  return (
    <div>
      <ModalSaveMode
        dataSavedPoint={combinedData}
        activeIndex={activeIndex}
        refetchSavedPoints={refetchSavedPoints}
        viewType="video"
        page={page}
        setPage={setPage}
        isLoading={isLoading}
        isFetching={isFetching}
        pageSize={PAGE_SIZE}
      />
    </div>
  );
};

export { SavedModeVideoView };
