import React, { useState, useEffect, useRef, FC } from "react";
import { useOutletContext } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import useModalPhoneItemState from "@/widgets/modalPhoneItem/libs/hook/useModalPhoneItemState";
import useModalPhoneItemEffects from "@/widgets/modalPhoneItem/libs/hook/useModalPhoneItemEffects";
import useModalPhoneItemControls from "@/widgets/modalPhoneItem/libs/hook/useModalPhoneItemControls";
import Header from "@/widgets/headerModal/ui/Header/Header";
import ButtonPay from "@/widgets/paybackButton/ui/ButtonPlay/ButtonPay";
import { VideoControls } from "@/widgets/videoControls/ui/VideoControls/VideoControls";
import PhotoCardList from "@/widgets/photoCardList/ui/PhotoCardList/PhotoCardList";
import AdditionalInfo from "@/widgets/additionalInfo/ui/AdditionalInfo/AdditionalInfo";
import SavePosition from "@/widgets/savePosition/ui/SavePosition/SavePosition";
import {
  useDeleteSavedAppsMutation,
  useGetHiddenAppsQuery,
  useGetSavedAppsQuery,
  useGetUserQuery,
  useSavedAppsMutation,
  useGetAppVideoFlowsQuery,
} from "@/features/auth/api/authAPI";
import {
  getModalMenuPhoneState,
  modalMenuPhoneActions,
} from "@/entities/modalMenuPhone";
import { videoActions } from "@/entities/video/model/slice/videoSlice";
import { saveModeActions } from "@/entities/saveMode";
import ErrorModalPhoneItem from "@/shared/ui/ErrorModalPhoneItem/ErrorModalPhoneItem";
import ButtonFilter from "@/shared/ui/ButtonFilter/ButtonFilter";
import AppStoreScreensModal from "@/shared/ui/AppStoreScreensModal/AppStoreScreensModal";
import { secondsToHMS } from "@/widgets/modalPhoneItem/libs/const/secondsToHMS";
import { buttonData, webOnboardingData } from "@/widgets/modalPhoneItem/libs/data/buttonData";
import styles from "@/widgets/modalPhoneItem/ui/ModalPhoneItem/ModalPhoneItem.module.scss";
import { useMe, useCurrentOrganization } from "@/Hooks";
import mixpanel from "@/shared/lib/mixpanel";
import { Flows } from "@/shared/ui/Flows/Flows";
import Spinner from "@/shared/ui/Spinner/Spinner";
import { toast } from "react-toastify";
import { getVideoState } from "@/entities/video/model/selectors/getVideoState";
import WebOnboardingVideoPage from "@/widgets/WebOnboardingVideoPage/WebOnboardingVideoPage";
import Phone from "@/widgets/phone/ui/Phone/Phone";

interface ModalMenuItemProps { }

const AppVideoView: FC<ModalMenuItemProps> = () => {

  const { isActiveAnyModal, setIsActiveAnyModal } = useOutletContext()

  const phoneRef = useRef<HTMLDivElement | null>(null);
  const [showSavedMode, setShowSavedMode] = useState(false);
  const [showActiveIcon, setShowActiveIcon] = useState(true);
  const [tempText, setTempText] = useState<string>("");
  const [isActivePoster, setIsActivePoster] = useState<boolean>(true);
  const [showSaveApp, setShowSaveApp] = useState(false);
  const currentOrganization = useCurrentOrganization();
  const token = localStorage.getItem("access_token");
  const {
    state,
    setShowLottie,
    setHasNullItem,
    setVideoReady,
    setImagesReady,
    setIsModalOpen,
    setIsTimestampReady,
    setIsScrolling,
    setMarkers,
    photoCardListRef,
    setCroppedImages,
  } = useModalPhoneItemState();

  const { data: appDataStarredApps } = useGetSavedAppsQuery("", {
    skip: !token,
  });
  const { data: appDataHidden } = useGetHiddenAppsQuery("-created_at", {
    skip: !token,
  });
  const {
    ref,
    refLottie,
    inView,
    inViewLottie,
    apiDataVideosOne,
    apiDataVideosOneOB,
    isLoading,
    isLoadingOB,
    appDataOne,
    apiDataVideosScreensById,
    apiDataVideosStoreScreens,
    appStoreImageId,
    appId,
    refetchDataVideo,
    refetchDataVideoOB,
    apiDataVideosLabelsById,
    apiDataPointsByVideo,
    refetchDataScreens,
    refetchDataStore,
  } = useModalPhoneItemEffects(state, {
    setIsScrolling,
    setHasNullItem,
    photoCardListRef,
    setShowLottie,
    setCroppedImages,
  });
  const { data: userData } = useGetUserQuery(null, { skip: !token });
  const [savedApp] = useSavedAppsMutation();
  const [deleteSavedApp] = useDeleteSavedAppsMutation();
  const { videoRef, onSeek, openModal, closeModal } = useModalPhoneItemControls(
    setVideoReady,
    setIsModalOpen,
    refetchDataVideo,
  );


  const [appVideoScreens, setAppVideoScreens] = useState([])
  const [savedPoints, setSavedPoints] = useState([])
  const [appStoreScreens, setAppStoreScreens] = useState([])
  const { me } = useMe();

  

  const { data: flowsData } = useGetAppVideoFlowsQuery(
    Number(apiDataVideosOne?.id),
    {
      skip: !appDataOne || !apiDataVideosOne?.id,
    }
  );

  const handleOpenBillingPage = () => {
    dispatch(saveModeActions.activeProModal());
  };

  useEffect(() => {
    if (apiDataVideosScreensById) {
      setAppVideoScreens(apiDataVideosScreensById.results)
    }
  }, [apiDataVideosScreensById])

  useEffect(() => {
    if (apiDataPointsByVideo) {
      setSavedPoints(apiDataPointsByVideo.results)
    }
  }, [apiDataPointsByVideo])


  useEffect(() => {
    if (apiDataVideosStoreScreens) {
      setAppStoreScreens(apiDataVideosStoreScreens.results)
    }
  }, [apiDataVideosStoreScreens])

  const handleOverlayClick = (
    e: MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    if (e.target === e.currentTarget) {
      closeModal();
    }
  };

  const starredAppId =
    appDataStarredApps &&
    appDataOne &&
    appDataStarredApps.results.find((item) => {
      return Number(item.app.id) === appDataOne.id;
    });
  const dispatch = useDispatch();
  const { activeIndex, isModalOpenPhotoCard } = useSelector(getModalMenuPhoneState);
  const href = window.location.href;
  const [apiDataVideosOneActive, setApiDataVideosOneActive] =
    useState<boolean>(false);
  const isStarred = appDataStarredApps?.results.some(
    (item) => Number(item.app.id) === Number(appId),
  );
  const isHidden = appDataHidden?.results.some(
    (item) => Number(item.app.id) === Number(appId)
  );
  const hiddenAppItem = appDataHidden?.results.find(
    (item) => Number(item.app.id) === Number(appId)
  );



  useEffect(() => {
    console.log('isModalOpenPhotoCard', isModalOpenPhotoCard)
    setIsActiveAnyModal(isModalOpenPhotoCard)
  }, [isModalOpenPhotoCard])

  const removeStarredApp = async () => {
    try {
      await deleteSavedApp(Number(starredAppId!.id)).unwrap();

      toast.success("App removed from saved successfully");

      if (me?.email) {
        mixpanel.identify(me.email);
        setTimeout(() => {
          mixpanel.people.decrement('total_saved_apps', 1);
        }, 100);
      }

    } catch (error) {
      toast.error("Failed to remove app from saved. Please try again.");
      console.error("Error removing saved app:", error);
    }
  };

  const iconTimerSaveAppRef = useRef<NodeJS.Timeout | null>(null);

  const handleSaveApp = async () => {
    if (!token) {
      window.location.href = "/login";
      return;
    }

    try {
      localStorage.setItem("btnName", "star an app");
      if (iconTimerSaveAppRef.current) clearTimeout(iconTimerSaveAppRef.current);
      setShowSaveApp(true);
      iconTimerSaveAppRef.current = setTimeout(() => {
        setShowSaveApp(false);
      }, 1500);

      await savedApp({
        app: Number(appId),
      }).unwrap();

      toast.success("App saved successfully");

      if (me?.email) {
        mixpanel.identify(me.email);
        setTimeout(() => {
          mixpanel.people.increment('total_saved_apps', 1);
        }, 100);
      }

      mixpanel.track('App saved', {
        idApp: appId,
        app_name: appDataOne?.name,
        UserEmail: me?.email || '',
        OrganizationID: currentOrganization?.uuid || '',
      });
    } catch (error) {
      toast.error("Failed to save app. Please try again.");
      console.error("Error saving app:", error);
    }
  };

  const iconTimerRef = useRef<NodeJS.Timeout | null>(null);
  const hideTimerRef = useRef<NodeJS.Timeout | null>(null);
  const activeFlowRef = useRef<HTMLDivElement | null>(null);

  const handleSavePointClick = () => {

    setShowSavedMode(true);
    setShowActiveIcon(true);

    iconTimerRef.current = setTimeout(() => {
      setVideoReady(true);
      setShowActiveIcon(false);
    }, 1000);

    hideTimerRef.current = setTimeout(() => {
      setShowSavedMode(false);
    }, 10000);
  };

  const { videoType } = useSelector(getVideoState);
  const currentVideoData = videoType === 1 ? apiDataVideosOne : apiDataVideosOneOB;
  const isCurrentVideoLoading = videoType === 1 ? isLoading : isLoadingOB;
  

  useEffect(() => {
    if (apiDataVideosOneOB && videoType === 1 && !apiDataVideosOne) {
      dispatch(videoActions.setVideoType(2));
    }
  }, [apiDataVideosOne, apiDataVideosOneOB])

  if (!currentVideoData || (!state.showLottie && state.videoReady)) {
    setTimeout(() => {
      setApiDataVideosOneActive(true);
    }, 1000);
  }


  useEffect(() => {
    if (currentVideoData && !state.videoReady) {
      if (videoType === 1) {
        refetchDataVideo();
      } else {
        refetchDataVideoOB();
      }
    }
  }, [currentVideoData, state.videoReady, refetchDataVideo, refetchDataVideoOB]);

  const handleVideoTypeChange = (type: number) => {
    dispatch(videoActions.setVideoType(type));
  };

  return state.hasNullItem ? (
    <ErrorModalPhoneItem />
  ) : (
    <>
      {!state.videoReady && !apiDataVideosOneActive ? (
        <div className={styles.loadingContainer}>
          <Spinner width={45} />
        </div>
      ) : (
        <>
          {(apiDataVideosOneOB || apiDataVideosOne) && (
            <div className={styles.videoTypeSwitch}>
              {apiDataVideosOne && (
                <button
                  className={`${styles.switchButton} ${videoType === 1 ? styles.active : ''}`}
                  onClick={() => handleVideoTypeChange(1)}
                >
                  {/* <img src="/img/appVideo.svg" alt="appVideo" /> */}
                  App Replay
                </button>
              )}
              {apiDataVideosOneOB && (
                <button
                  className={`${styles.switchButton} ${videoType === 2 ? styles.active : ''}`}
                  onClick={() => handleVideoTypeChange(2)}
                >
                  {/* <img src="/img/onboardingVideo.svg" alt="onboardingVideo" /> */}
                  Web Onboarding
                </button>
              )}
            </div>
          )}
          <div ref={ref} className={styles.containerRoot}>
            {appDataOne && currentVideoData?.video && (
              <>
                <div className={styles.headerContainer}>
                  <Header
                    data={appDataOne}
                    appId={Number(appId)}
                  />
                  {/* {videoType === 1 && (
                    <div className={styles.flowsContainer}>
                      <Flows
                        onSeek={onSeek}
                        flows={flowsData?.results}
                        currentTime={Number(state.currentPlayTime.toFixed(0))}
                        apiDataVideosLabelsById={apiDataVideosLabelsById}
                        activeFlowRef={activeFlowRef}
                      />
                    </div>
                  )} */}
                </div>
              </>
            )}

            <Phone
              videoRef={videoRef}
              setVideoReady={setVideoReady}
              videoReady={state.videoReady}
              apiDataVideosOne={currentVideoData}
              setIsTimestampReady={setIsTimestampReady}
              isTimestampReady={state.isTimestampReady}
              isModalOpen={state.isModalOpen}
              inViewLottie={inViewLottie}
              appDataOne={appDataOne}
              isActivePoster={isActivePoster}
              setIsActivePoster={setIsActivePoster}
              userData={userData}
              openModal={openModal}
              handleOpenBillingPage={handleOpenBillingPage}
              currentOrganization={currentOrganization}
            />
            <div className={styles.controller}>
              <div className={`${styles.containerButton}`}>
                <div className={styles.containerButtonLeft}>
                  <ButtonPay />
                  {videoType === 1 && (
                    <SavePosition
                      apiDataVideosOne={currentVideoData}
                      isLoading={isCurrentVideoLoading}
                      styleProps=""
                      withBefore={true}
                      setIsActivePoster={setIsActivePoster}
                      apiDataPointsByVideo={apiDataPointsByVideo}
                      onSavePointClick={handleSavePointClick}
                      isPro={userData?.is_pro}
                      selectedTimestamp={state.currentPlayTime.toFixed(0)}
                    />
                  )}
                </div>
                <div
                  onClick={(e) => {
                    e.stopPropagation();
                    dispatch(videoActions.togglePlayPause());
                  }}
                  className={styles.playTimer}
                >
                  <>
                    <div className={styles.playTimer}>
                      <div className={styles.playTimerItem}>
                        <img src={state.playing ? "/img/pauseIconTimer.svg" : "/img/playIconTimer.svg"} alt="" />
                      </div>
                      <p>
                        {secondsToHMS(Number(state.currentPlayTime.toFixed(0)))}
                        <span>/</span>
                        {secondsToHMS(Number(currentVideoData?.duration_seconds))}
                      </p>
                    </div>
                  </>
                </div>

                <div className={`${styles.containerButtonWebOnboarding} ${videoType === 2 ? styles.containerButtonWebOnboarding2 : ''}`}>
                </div>

              </div>
              <div className={styles.controlsContainer}>
                {currentVideoData?.video && (
                  <div className={styles.containerVideo}>
                    {apiDataVideosScreensById &&
                      currentVideoData?.video !== null &&
                      appVideoScreens.length > 0 &&
                      videoType !== 2 && (
                        <div>
                          <VideoControls
                            onSeek={onSeek}
                            apiDataVideosOne={currentVideoData}
                            inView={inView}
                            activeFlowRef={activeFlowRef}
                            setMarkers={setMarkers}
                            inViewLottie={inViewLottie}
                            croppedImages={state.croppedImages}
                            apiDataPointsByVideo={apiDataPointsByVideo}
                            apiDataVideosLabelsById={apiDataVideosLabelsById}
                          />
                        </div>
                      )}
                  </div>
                )}
              </div>
            </div>
          </div>


          <div className={styles.containerButtons}>
            {(videoType === 2 ? webOnboardingData : buttonData).map((item, index) => (
              <ButtonFilter
                key={index}
                text={item.text}
                imgActive={item.imgActive}
                isActive={index === activeIndex}
                onClick={() =>
                  dispatch(modalMenuPhoneActions.setActiveIndex(index))
                }
                height="38px"
              />
            ))}
          </div>

          {activeIndex === 1 && appStoreScreens.length > 0 && (
            <div className={styles.containerAppStoreScreens}>
              <AppStoreScreensModal
                appStoreScreens={appStoreScreens}
                setAppStoreScreens={setAppStoreScreens}
                appStoreImageId={appStoreImageId}
                appId={appId}
                isPro={userData ? userData.is_pro : false}
                currentOrganization={currentOrganization}
                refetchDataStore={refetchDataStore}
              />
            </div>
          )}

          {currentVideoData?.video !== null && activeIndex === 0 && videoType === 1 && (
            <div className={`${styles.photoCardList} `} ref={photoCardListRef}>
              <PhotoCardList
                onSeek={onSeek}
                appVideoScreens={appVideoScreens}
                setAppVideoScreens={setAppVideoScreens}
                setImagesReady={setImagesReady}
                imagesReady={state.imagesReady}
                apiDataVideosOne={currentVideoData}
                setIsActivePoster={setIsActivePoster}
                ref={phoneRef}
                isModalOpenPhotoCard={isModalOpenPhotoCard}
                handleOpenBillingPage={handleOpenBillingPage}
                refetchDataScreens={refetchDataScreens}
              />
            </div>
          )}

          {activeIndex === 0 && videoType === 2 && currentVideoData?.video && (
            <div className={styles.containerWebOnboarding}>
              <WebOnboardingVideoPage
                videoData={currentVideoData}
                isOpen={state.isOpen}
              />
            </div>
          )}


          {state.isOpen && appDataOne && (
            <div className={styles.modal}>
              <AdditionalInfo
                data={appDataOne}
                handleSaveApp={handleSaveApp}
                isStarred={isStarred || false}
                isHidden={isHidden || false}
                removeStarredApp={removeStarredApp}
                setTempText={setTempText}
                appId={Number(appId)}
                hiddenAppItemId={hiddenAppItem?.id}
              />
            </div>
          )}
        </>
      )}
      {state.isModalOpen && (
        <div className={styles.modalOverlay} onClick={handleOverlayClick} />
      )}
    </>
  );
};

export { AppVideoView }