import { Check } from "lucide-react";
import styles from './OnboardingModal.module.scss';

export const ComparisonTable = () => (
  <div className={styles.tableContainer}>
    <table className={styles.table}>
      <thead>
        <tr className={styles.headerRow}>
          <th className={styles.headerCell}></th>
          <th className={styles.headerCellCenter}>ScreensDesign</th>
          <th className={styles.headerCellCenter}>Mobbin</th>
          <th className={styles.headerCellCenter}>Dribbble</th>
        </tr>
      </thead>
      <tbody>
        {[
          { name: "Real iOS App Designs", screens: true, mobbin: true, dribbble: false },
          { name: "iOS Apps Included", screens: "1500+", mobbin: "600+", dribbble: "0" },
          { name: "Full App Videos", screens: true, mobbin: false, dribbble: false },
          { name: "Animation Explorer", screens: true, mobbin: false, dribbble: false },
          { name: "Revenue/Install Filtering", screens: true, mobbin: false, dribbble: false },
          { name: "Web Onboardings", screens: true, mobbin: false, dribbble: false },
          { name: "Advanced Search", screens: true, mobbin: true, dribbble: true },
          { name: "Beautiful Design Inspiration", screens: true, mobbin: true, dribbble: true },
        ].map((row, i) => (
          <tr key={i} className={styles.row}>
            <td className={styles.cell}>{row.name}</td>
            <td className={styles.cellCenter}>
              {typeof row.screens === 'boolean' ?
                (row.screens ? <Check className={styles.checkIcon} /> : '-') :
                row.screens}
            </td>
            <td className={styles.cellCenter}>
              {typeof row.mobbin === 'boolean' ?
                (row.mobbin ? <Check className={styles.checkIcon} /> : '-') :
                row.mobbin}
            </td>
            <td className={styles.cellCenter}>
              {typeof row.dribbble === 'boolean' ?
                (row.dribbble ? <Check className={styles.checkIcon} /> : '-') :
                row.dribbble}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
);