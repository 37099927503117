import * as React from "react"
import * as SeparatorPrimitive from "@radix-ui/react-separator"
import classNames from "classnames"

import "./separator.css" // We'll need to create this CSS file

const Separator = React.forwardRef<
  React.ElementRef<typeof SeparatorPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof SeparatorPrimitive.Root>
>(
  (
    { className, orientation = "horizontal", decorative = true, ...props },
    ref
  ) => (
    <SeparatorPrimitive.Root
      ref={ref}
      decorative={decorative}
      orientation={orientation}
      className={classNames(
        "separator",
        orientation === "horizontal" ? "separator-horizontal" : "separator-vertical",
        className
      )}
      {...props}
    />
  )
)
Separator.displayName = SeparatorPrimitive.Root.displayName

export { Separator }
