import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { modalMenuActions } from "@/entities/modalMenu";
import { meUserActions } from "@/entities/me";
import { userActions } from "@/entities/user";

export const useLogout = (setIsLogout: (state: boolean) => void) => {
  // todo: needs fixes here
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isLoggedOut = !localStorage.getItem("access_token");

  const handleLogout = () => {
    if (isLoggedOut) {
      return;
    }

    localStorage.removeItem("access_token");
    dispatch(meUserActions.resetMe());
    dispatch(modalMenuActions.hideModal());
    dispatch(userActions.logout());
    localStorage.removeItem("btnName");
    navigate("/");
    //setIsLogout(true);
    window.location.reload();
  };

  return { handleLogout, isLoggedOut };
};
