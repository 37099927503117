// react
import React from "react";
// features
import { ResponseHiddenApps } from "@/features/auth/types/auth";
import { useDeleteHiddenAppsMutation } from "@/features/auth/api/authAPI";
// shared
import ModalHirStarEmpty from "@/shared/ui/ModalHirStarEmpty/ModalHirStarEmpty";
import HiddenApp from "@/shared/ui/HiddenApp/HiddenApp";
// const
import { handleOpenVideoPage } from "@/app/libs/const/handleOpenVideoPage";
// types
import { IDeletingItems } from "../libs/types";
// styles
import styles from "./HiddenApps.module.scss";
import { produce } from 'immer'
import { toast } from "react-toastify";
import mixpanel from "@/shared/lib/mixpanel";
import { useMe } from "@/Hooks/useMe";

interface HiddenAppsProps {
  appDataHidden: ResponseHiddenApps | undefined;
  setHiddenApps: React.Dispatch<React.SetStateAction<ResponseHiddenApps | undefined>>;
}

const HiddenApps: React.FC<HiddenAppsProps> = ({ appDataHidden, setHiddenApps }) => {
  const [deletingItems, setDeletingItems] = React.useState<IDeletingItems>({});
  const [deleteHiddenApp] = useDeleteHiddenAppsMutation();
  const { me } = useMe();

  const handleDeleteApp = async (id: number) => {
    setDeletingItems((prev) => ({
      ...prev,
      [id]: { isDeleting: true, iconChanged: true },
    }));
    try {
      await deleteHiddenApp(id).unwrap();
      if (me?.email) {
        mixpanel.identify(me.email);
        setTimeout(() => {
          mixpanel.people.decrement('total_hidden_apps', 1);
        }, 100); 
      }
      toast.success("App unhidden successfully!");
      
      setHiddenApps(produce((draft) => {
        const index = draft?.findIndex(item => item.id === id)
        if (index !== -1) draft?.splice(index, 1)
      }))
    } finally {
      // Clean up the deletingItems state for this ID
      setDeletingItems((prev) => {
        const newState = { ...prev }
        delete newState[id]
        return newState
      });
    }
  };

  return (
    <div className={styles.root}>
        <div className={styles.hiddenApps}>
          {appDataHidden.length ? (
            appDataHidden.map((item, index) => (
              <HiddenApp
                key={index}
                handleOpenVideoPage={handleOpenVideoPage}
                handleDeleteApp={handleDeleteApp}
                deletingItems={deletingItems}
                item={item}
              />
            ))
          ) : (
            <ModalHirStarEmpty
              text="no hidden apps added"
              img="/img/groupEmptyIcon.svg"
            />
          )}
        </div>
    </div>
  );
};

export default HiddenApps;
