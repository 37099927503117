import Logo from './Logo';
import PricingCard from './PricingCard';
import FeatureList from './FeatureList';
import { motion } from 'framer-motion';
import './styles.css'; // We'll need to create this CSS file

const features = [
  { id: '1', name: 'Full Access to All Apps' },
  { id: '2', name: 'Revenue & Install metrics' },
  { id: '3', name: 'Weekly New Apps' },
  { id: '4', name: 'Advanced Search & filters' },
  { id: '5', name: 'All free tools' },
  { id: '6', name: 'Web onboardings' },
  { id: '7', name: 'Complete App Videos' },
];

const Index = () => {
  return (
    <div className="register-container">
      <div className="logo-container">
        <Logo />
      </div>
      
      <div className="register-grid">
        {/* Left Column - Pricing Card */}
        <div className="pricing-column">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, ease: [0.22, 1, 0.36, 1] }}
          >
            <PricingCard 
              price="$25" 
              period="Month" 
              description="Unlock premium features, advanced tools, and priority support" 
            />
          </motion.div>
        </div>
        
        {/* Right Column - Features */}
        <div className="features-column">
          <div className="features-background">
            <div 
              className="features-background-image"
              style={{ 
                backgroundImage: 'url(/lovable-uploads/1d309704-07d4-4ec2-980d-0a56b0b10cf9.png)'
              }}
            />
          </div>
          
          <motion.div 
            className="features-content"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.3, duration: 0.8 }}
          >
            <motion.h2 
              className="features-title"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.4, duration: 0.8 }}
            >
              Unlock all apps<br />
              designs & additional<br />
              features
            </motion.h2>
            
            <motion.p 
              className="features-description"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.5, duration: 0.8 }}
            >
              Get access to 1500+ top apps & 120k+ screens, advanced AI Search
            </motion.p>
            
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.6, duration: 0.8 }}
            >
              <FeatureList features={features} />
            </motion.div>
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default Index;
