import { useMemo } from 'react';

const LETTER_GRADIENTS = {
  'A': 'linear-gradient(45deg, #FF6B6B, #FFE66D)',
  'B': 'linear-gradient(45deg, #4ECDC4, #556270)',
  'C': 'linear-gradient(45deg, #556270, #FF6B6B)',
  'D': 'linear-gradient(45deg, #6C5B7B, #C06C84)',
  'E': 'linear-gradient(45deg, #355C7D, #6C5B7B)',
  'F': 'linear-gradient(45deg, #2A363B, #E84A5F)',
  'G': 'linear-gradient(45deg, #99B898, #FECEA8)',
  'H': 'linear-gradient(45deg, #FF847C, #E84A5F)',
  'I': 'linear-gradient(45deg, #2A363B, #FF847C)',
  'J': 'linear-gradient(45deg, #A8E6CF, #DCEDC1)',
  'K': 'linear-gradient(45deg, #FFD3B6, #FFAAA5)',
  'L': 'linear-gradient(45deg, #FF8B94, #FFD3B6)',
  'M': 'linear-gradient(45deg, #98DDCA, #D5ECC2)',
  'N': 'linear-gradient(45deg, #FFB6B9, #FAE3D9)',
  'O': 'linear-gradient(45deg, #BBDED6, #61C0BF)',
  'P': 'linear-gradient(45deg, #FFB6B9, #BBE1FA)',
  'Q': 'linear-gradient(45deg, #B983FF, #94B3FD)',
  'R': 'linear-gradient(45deg, #94DAFF, #99FEFF)',
  'S': 'linear-gradient(45deg, #E8F6EF, #B8DFD8)',
  'T': 'linear-gradient(45deg, #FFE699, #FFB6B9)',
  'U': 'linear-gradient(45deg, #B5EAEA, #EDF6E5)',
  'V': 'linear-gradient(45deg, #FFBCBC, #F38BA0)',
  'W': 'linear-gradient(45deg, #B5DEFF, #CAB8FF)',
  'X': 'linear-gradient(45deg, #FFC4C4, #EE9595)',
  'Y': 'linear-gradient(45deg, #FFF5E4, #FFE3E1)',
  'Z': 'linear-gradient(45deg, #D7E9F7, #AAC4FF)',
};

export const getGradientForLetter = (letter: string) => {
  const upperLetter = letter.toUpperCase();
  return LETTER_GRADIENTS[upperLetter] || LETTER_GRADIENTS['A'];
};

export const useGradient = (letter: string) => {
  return useMemo(() => getGradientForLetter(letter), [letter]);
};
