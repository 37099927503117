// react-router-dom
import { Link } from "react-router-dom";
// styles
import styles from "./AuthTerms.module.scss";

const AuthTerms = () => {
  return (
    <div className={styles.root}>
      <p>
        By continuing, you acknowledge that you have read and understood, and
        agree to ScreenDesign’s{" "}
        <Link to="/terms">
          <span>Terms & Conditions</span>
        </Link>{" "}
        and{" "}
        <Link to="/privacy">
          <span>Privacy Policy</span>
        </Link>
      </p>
    </div>
  );
};

export default AuthTerms;
