// redux
import { createSlice } from "@reduxjs/toolkit";
// types
import { inputSearchState } from "../types/inputSearchTypes";

const initialState: inputSearchState = {
  isActiveInput: false,
  isActiveOCR: false,
  isActiveClick: false,
};

const inputSearchSlice = createSlice({
  name: "inputSearch",
  initialState,
  reducers: {
    toggleInputSearch: (state) => {
      state.isActiveInput = !state.isActiveInput;
    },
    activeInputSearch: (state) => {
      state.isActiveInput = true;
    },
    deactiveInputSearch: (state) => {
      state.isActiveInput = false;
    },
    toggleOCR: (state) => {
      state.isActiveOCR = !state.isActiveOCR;
    },
    activeOCR: (state) => {
      state.isActiveOCR = true;
    },
    deactiveOCR: (state) => {
      state.isActiveOCR = false;
    },
    setIsActiveClick: (state, action) => {
      state.isActiveClick = action.payload;
    },
  },
});

export const { actions: inputSearchActions, reducer: inputSearchReducer } =
  inputSearchSlice;
