import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IUser, UserStateSchema } from "../types/user";

const initialState: UserStateSchema = {
  isLoggedIn: false,
  user: {
    username: "",
    password: "",
    grant_type: "password",
    client_id: "",
  },
  withoutLoading: false,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<IUser>) => {
      state.user = action.payload;
    },
    login: (state) => {
      state.isLoggedIn = true;
    },
    logout: (state) => {
      state.user = initialState.user;
      state.isLoggedIn = false;
      localStorage.removeItem("access_token");
    },
    setWithoutLoading: (state, action: PayloadAction<boolean>) => {
      state.withoutLoading = action.payload;
    },
  },
});

export const { actions: userActions, reducer: userReducer } = userSlice;
