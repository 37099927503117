import Modal from "react-modal";
import { useDispatch } from "react-redux";
import styles from "../../../pages/Homepage/Homepage.module.scss";
import { appsActions } from "@/entities/apps";

import { useMe } from '@/Hooks'

interface IPaymentSuccessModalProps {
  isOpen: boolean;
  setIsSuccessPayment: (value: boolean) => void;
  amountPlans: any;
  lineItems: any;
  transactionId: any;
  description: string;
}

const PaymentSuccessModal = ({
  isOpen,
  setIsSuccessPayment,
  amountPlans,
  lineItems,
  transactionId,
  description,
}: IPaymentSuccessModalProps) => {

  const { me } = useMe()
  const dispatch = useDispatch();

  // Чтение данных из localStorage
  const title = localStorage.getItem("modalTitle") || "Payment Successful!";
  const message =
    localStorage.getItem("modalMessage") ||
    `Thank you for your purchase, ${me?.username}! Your payment has been successfully processed.`;

  const handleClose = () => {
    setIsSuccessPayment(false);
    dispatch(appsActions.setStatusPayment(""));
    dispatch(appsActions.setLineItems([]));
    dispatch(appsActions.setTransactionId(""));
    dispatch(appsActions.setAmountPlans(0));


    // Очистка данных из localStorage
    localStorage.removeItem("modalTitle");
    localStorage.removeItem("modalMessage");
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={handleClose}
      contentLabel="Groups Modal"
      className={styles.modalPayment}
      overlayClassName={styles.overlay}
    >
      <div className={styles.modalContentPayment}>
        {/* <div onClick={handleClose} className={styles.closeItem}>
          <img src="img/closeSaveModeIcon.svg" alt="closeImg" loading="lazy" />
        </div> */}
        <img
          className={styles.paymentLogo}
          src="/img/paymentLogoIcon.svg"
          alt="paymennLogoImg"
        />
        <div className={styles.paymentTitle}>
          <h1>{title}</h1>
          <p>{message}</p>
        </div>
        <div className={styles.paymentMain}>
          <h2>Payment Details:</h2>
          <div className={styles.paymentMainContent}>
            <div className={styles.paymentMainContentItem}>
              <h3>Plan</h3>
              <p>{description}</p>
            </div>
            <div className={styles.paymentMainContentItem}>
              <h3>Amount Charged</h3>
              <p>${(amountPlans / 100).toFixed(2)}</p>
            </div>
            {/* <div className={styles.paymentMainContentItem}>
              <h3>Transaction ID</h3>
              <p>{transactionId}</p>
            </div> */}
            {/* <div className={styles.paymentMainContentItem}>
              <h3>Subscription expires</h3>
              <p>
                {(() => {
                  const date = new Date();
                  date.setMonth(date.getMonth() + 3);
                  return date.toLocaleDateString("en-US", {
                    month: "long",
                    day: "numeric",
                    year: "numeric",
                  });
                })()}
              </p>
            </div> */}
          </div>
        </div>
        <p className={styles.paymentFooter}>
          A confirmation email has been sent to {me?.email} with the
          details of your purchase.
        </p>
      </div>
    </Modal>
  );
};

export default PaymentSuccessModal;
