import React from "react";
import { useDispatch, useSelector } from "react-redux";
// entities
import { getSortByCategoriesSlice } from "@/entities/sortByCategories/model/selectors/getSortByCategoriesState";
import { sortByCategoriesActions } from "@/entities/sortByCategories";
import { filterAppsActions } from "@/entities/filterApps";
// shared
import SortHidStarModalItem from "@/shared/ui/SortHidStarModalItem/SortHidStarModalItem";
// data
import { dataItem } from "../../libs/data/dataItem";
// styles
import styles from "./SortHidStarModal.module.scss";

interface SortHidStarModalProps {
  setSortDirection: (state: string) => void;
}

const SortHidStarModal: React.FC<SortHidStarModalProps> = ({
  setSortDirection,
}) => {
  const dispatch = useDispatch();
  const { indexSortHidStar } = useSelector(getSortByCategoriesSlice);

  const handleClick = (index: number, text: string, filter: string) => {
    if (indexSortHidStar === index) {
      return;
    }

    if (text === "None") {
      setSortDirection("desc");
    }

    dispatch(sortByCategoriesActions.setSortByHirStar(text));
    dispatch(sortByCategoriesActions.closeHidStar());
    dispatch(sortByCategoriesActions.setIndexSortHidStar(index));
    dispatch(filterAppsActions.setFilterSortHidStar(filter));
  };

  return (
    <div className={styles.root}>
      {dataItem.map((item, index) => {
        return (
          <SortHidStarModalItem
            key={index}
            name={item.text}
            isActive={indexSortHidStar === index}
            onClick={() => handleClick(index, item.text, item.filter)}
          />
        );
      })}
    </div>
  );
};

export default SortHidStarModal;
