import React from "react";
import { ResponseSavedApps } from "@/features/auth/types/auth";
import { useDeleteSavedAppsMutation } from "@/features/auth/api/authAPI";
import ModalHirStarEmpty from "@/shared/ui/ModalHirStarEmpty/ModalHirStarEmpty";
import StarredApp from "@/shared/ui/StarredApp/StarredApp";
import { handleOpenVideoPage } from "@/app/libs/const/handleOpenVideoPage";
import { IDeletingItems } from "@/widgets/hiddenApps/ui/libs/types";
import styles from "./StarredApps.module.scss";

import { produce } from 'immer'
import { toast } from "react-toastify";
import { useMe } from "@/Hooks/useMe";
import mixpanel from "@/shared/lib/mixpanel";


interface StarredAppsProps {
  apiDataApps: ResponseSavedApps | undefined;
}

const StarredApps: React.FC<StarredAppsProps> = ({ apiDataApps, setSavedApps }) => {

  const [deletingItems, setDeletingItems] = React.useState<IDeletingItems>({});
  const [deleteSavedApp] = useDeleteSavedAppsMutation();
  const { me } = useMe();

  const handleDeleteApp = async (id: number) => {
    setDeletingItems((prev) => ({
      ...prev,
      [id]: { isDeleting: true, iconChanged: true },
    }));
    try {
      await deleteSavedApp(id).unwrap();

      if (me?.email) {
        mixpanel.identify(me.email);
        setTimeout(() => {
          mixpanel.people.decrement('total_saved_apps', 1);
        }, 100);
      }

      setSavedApps(produce((draft) => {
        const index = draft.findIndex(item => item.id === id)
        if (index !== -1) draft.splice(index, 1)
      }))

      toast.success("App unstarred successfully!");

    } catch (error) {
      console.error("Error deleting app:", error);
      toast.error("Failed to unstar app. Please try again.");

    } finally {
      setDeletingItems((prev) => ({
        ...prev,
        [id]: { isDeleting: false, iconChanged: false },
      }));
    }
  };

  return (
    <div className={styles.root}>
      <div className={styles.hiddenApps}>
        {apiDataApps.length ? (
          apiDataApps.map((item, index) => {
            return (
              <StarredApp
                key={index}
                item={item}
                handleOpenVideoPage={handleOpenVideoPage}
                handleDeleteApp={handleDeleteApp}
                deletingItems={deletingItems}
                me={me}
              />
            );
          })
        ) : (
          <ModalHirStarEmpty
            text="no starred apps added"
            img="/img/groupEmptyIcon.svg"
          />
        )}
      </div>
    </div>
  );
};

export default StarredApps;
