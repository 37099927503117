// react
import React from "react";
// styles
import styles from "./InputAuth.module.scss";

interface InputAuthProps {
  placeholder: string;
  type: string;
  value: string;
  onChange: (value: string) => void;
  autocomplete: string | undefined;
  style?: React.CSSProperties;
}

const InputAuth: React.FC<InputAuthProps> = ({
  placeholder,
  type,
  onChange,
  value,
  autocomplete,
  style,
}) => {
  return (
    <div className={styles.root}>
      <input
        type={type}
        placeholder={placeholder}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        autoComplete={autocomplete}
        style={style}
      />
    </div>
  );
};

export default InputAuth;
