import React from "react";
import { useSavePosition } from "../../libs/hook/handleClick";
import {
  IAppVideoData,
  ResponseSavedPointGet,
} from "@/features/auth/types/auth";
import styles from "./SavePosition.module.scss";
import mixpanel from "@/shared/lib/mixpanel";
import { useMe, useCurrentOrganization } from "@/Hooks";

interface SavePositionProps {
  isLoading: boolean;
  apiDataVideosOne: IAppVideoData | undefined;
  styleProps: string;
  withBefore: boolean;
  setIsActivePoster: (state: boolean) => void;
  apiDataPointsByVideo: ResponseSavedPointGet | undefined;
  selectedTimestamp?: string;
  onSavePointClick?: () => void;
  isPro?: boolean | undefined;
}

const SavePosition: React.FC<SavePositionProps> = ({
  isLoading,
  apiDataVideosOne,
  styleProps,
  withBefore,
  setIsActivePoster,
  apiDataPointsByVideo,
  selectedTimestamp,
  onSavePointClick,
  isPro,
}) => {

  const [isActiveClick, setIsActiveClick] = React.useState(false);
  const { handleClick, currentPlayTime } = useSavePosition(
    apiDataVideosOne,
    isLoading,
    setIsActiveClick,
    setIsActivePoster,
    apiDataPointsByVideo,
    styleProps,
  );
  const { me } = useMe();
  const currentOrganization = useCurrentOrganization();

  const handleSaveClick = () => {
    handleClick(currentPlayTime, selectedTimestamp);
    localStorage.setItem("btnName", "save a point");
    onSavePointClick && onSavePointClick();

    if (me?.email) {
      mixpanel.identify(me.email);
      setTimeout(() => {
        mixpanel.people.increment('total_saved_screens', 1);
      }, 100); 
    }

    mixpanel.track('Image saved', {
      idApp: apiDataVideosOne?.id,
      app_name: apiDataVideosOne?.name,
      From: "VideoPage",
      UserEmail: me?.email || '',
      OrganizationID: currentOrganization?.uuid || '',
    });
  };

  return (
    <button
      type="button"
      onClick={handleSaveClick}
      className={`${styles.root} ${withBefore ? styles.withBefore : ""}`}
      disabled={
        isLoading ||
        isActiveClick ||
        (isPro &&
          !isPro &&
          apiDataVideosOne &&
          apiDataVideosOne.blur_starts_at <= currentPlayTime)
      }
    >
      <img src="/img/blackIcon/Save.svg" alt="savePointImg" loading="lazy" />
      <span>Save Point</span>
    </button>
  );
};

export default SavePosition;
