import React, { useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'

import { useMe, useAPI } from '@/Hooks'

const JoinView = () => {

    const api = useAPI()
    const { me, loading } = useMe()
    const navigate = useNavigate()
    const { key } = useParams()

    const doJoin = () => {
        api.post('/v1/join/', { invite: key })
            .then((resp) => {
                // if we show success/error message remove line below
                window.location.href = '/'
            })
            .catch((err) => {
                // todo: show error modal / text if there is any
                console.log(err)

            })
            .finally(() => {
                // if we show success/error message remove line below
                window.location.href = '/'

                // if we dont show message uncomment lines below
                // setTimeout(() => {
                //     // redirect after an error modal / text was shown for x seconds
                //     window.location.href = '/'
                // }, 2000)
            })
    }

    useEffect(() => {
        if (!loading && !me) {
            // use something else here but needs to persist
            localStorage.setItem('invite', key)
            return navigate('/login/')
        }

        if (me) {
            doJoin()
        }

    }, [me])

    return (<></>)

}

export { JoinView }