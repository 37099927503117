import Joi from 'joi';

export const registerSchema = Joi.object({
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .required()
    .messages({
      'string.email': 'Invalid email address',
      'string.empty': 'Email is required'
    }),
  password: Joi.string()
    .min(8)
    .pattern(/[A-Z]/)
    .pattern(/[a-z]/)
    .pattern(/[0-9]/)
    .pattern(/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/)
    .required()
    .messages({
      'string.min': 'Password must be at least 8 characters',
      'string.pattern.base': 'Password must contain uppercase, lowercase, numbers and special characters',
      'string.empty': 'Password is required'
    }),
  // passwordConfirm: Joi.string()
  //   .valid(Joi.ref('password'))
  //   .required()
  //   .messages({
  //     'any.only': 'Passwords must match',
  //     'string.empty': 'Password confirmation is required'
  //   })
});

export const validateForm = (formData: {
  email: string;
  password: string;
  // passwordConfirm: string;
}) => {
  const { error } = registerSchema.validate(formData, { abortEarly: false });
  
  if (error) {
    const validationErrors: {[key: string]: string} = {};
    error.details.forEach(detail => {
      validationErrors[detail.path[0]] = detail.message;
    });
    return validationErrors;
  }
  
  return null;
};
