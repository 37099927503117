import axios from 'axios'
import { backendBaseUrl } from "@/shared/lib/constants/backendBaseUrl";
import { useAccessToken } from './useAccessToken'

const useAPI = () => {

    const accessToken = useAccessToken()
    //const token = localStorage.getItem("access_token");

    const baseURL = backendBaseUrl
    //const baseURL = 'http://localhost:8000'
    const instance = axios.create({
      baseURL: baseURL,
      //timeout: 1000,
      headers: {'Authorization': `Bearer ${accessToken}`}
    });

    return instance
}


export { useAPI }