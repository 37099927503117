import React, { forwardRef, useState } from "react";
import { IoIosCheckmarkCircle } from "react-icons/io";
import styles from "./ModalSaveModeGroup.module.scss";
import { toast } from "react-toastify";
import mixpanel from "@/shared/lib/mixpanel";
import { useMe, useCurrentOrganization } from "@/Hooks";

interface ModalSaveModeGroupProps {
  groupName: string;
  changeGroupName: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleCreateGroup: () => Promise<void>;
}

const ModalSaveModeGroup = forwardRef<HTMLDivElement, ModalSaveModeGroupProps>(
  ({ groupName, changeGroupName, handleCreateGroup }, ref) => {
    const [isCreated, setIsCreated] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const { me } = useMe()
    const currentOrganization = useCurrentOrganization()

    const handleCreate = async () => {
      try {
        await handleCreateGroup();
        setIsCreated(true);

        if (me?.email) {
          mixpanel.identify(me.email);
          setTimeout(() => {
            mixpanel.people.increment('total_created_groups', 1);
          }, 100); 
        }

        mixpanel.track('Group created', {
          group_name: groupName,
          UserEmail: me?.email || '',
          OrganizationID: currentOrganization?.uuid || '',
        });
        setError(null);
      } catch (err) {
        setError("Failed to create group. Please try again.");
        toast.success(`${error}`);
      }
    };

    return (
      <div ref={ref} className={styles.root}>
        <div className={styles.groupContainer}>
          <h3>Group name</h3>
          <input
            value={groupName}
            maxLength={35}
            onChange={changeGroupName}
            type="text"
            autoFocus
          />
        </div>
        <div className={styles.buttonCreate}>
          <button onClick={handleCreate} disabled={isCreated}>
            {isCreated ? (
              <>
                <IoIosCheckmarkCircle
                  style={{
                    color: "green",
                    fontSize: "24px",
                    paddingRight: "2px",
                  }}
                />
                <span>Created</span>
              </>
            ) : (
              <span>Create</span>
            )}
          </button>
        </div>
      </div>
    );
  },
);

export default ModalSaveModeGroup;
