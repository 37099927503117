import { useEffect, useRef, useState } from "react";
import { NavLink, useLocation } from 'react-router-dom'
import {
  useSavedGroupsMutation,
  useDeleteSavedGroupPointsMutation,
  useSavedGroupPointsMutation,
  useFetchSavedGroupsMutation,
  useSavedGroupStorePointsMutation,
  useCreateSavedGroupObScreenMutation
} from "@/features/auth/api/authAPI";
import ModalSaveModeGroup from "@/shared/ui/ModalSaveModeGroup/ModalSaveModeGroup";
import Homestyles from "@/pages/Homepage/Homepage.module.scss";
import ModalAppsEmpty from "@/shared/ui/ModalAppsEmpty/ModalAppsEmpty";
import ModalSaveModeGroups from "@/shared/ui/ModalSaveModeGroups/ModalSaveModeGroups";
import Spinner from "@/shared/ui/Spinner/Spinner";
import styles from "@/widgets/modalSaveMode/ui/ModalSaveMode/ModalSaveMode.module.scss";
import { produce } from 'immer'
import { useCurrentOrganization } from '@/Hooks'
import { toast } from "react-toastify";

const SavedModeGroupListView = () => {

  const currenOrganization = useCurrentOrganization()
  const [savedGroups, setSavedGroups] = useState([])

  const imageDimensions = [
    { width: 246.88, height: 534 },
    { width: 226, height: 490.59 },
    { width: 206, height: 447.17 },
    { width: 186, height: 403.76 },
  ];

  const [deleteGroupPoint] = useDeleteSavedGroupPointsMutation();
  const [isDeleting, setIsDeleting] = useState(false);
  const [addGroupModal, setAddGroupModal] = useState(false);
  const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);
  const [selectedPhotos, setSelectedPhotos] = useState<
    Set<{ id: number; url: string; videoId: number; type: string }>
  >(new Set());
  const [selectActive, setSelectActive] = useState(false);
  const lastGroupRef = useRef<HTMLDivElement>(null);
  const [groupIdPoint, setGroupIdPoint] = useState<null | number>(null);
  const [filterValueGroup, setFilterValueGroup] = useState("");
  const [isCurrentGroupActive, setIsCurrentGroupActive] = useState(false);
  const [selectGroupActive, setSelectGroupActive] = useState(false);
  const refCreateGroup = useRef<HTMLDivElement>(null);
  const refCreateGroupBtn = useRef<HTMLDivElement>(null);
  const [selectedGroups, setSelectedGroups] = useState<Set<number>>(new Set());
  const [modalAddGroupActive, setModalAddGroupActive] = useState(false);
  const [addScreeStoreToGroup] = useSavedGroupStorePointsMutation();
  const [addScreenToGroup] = useSavedGroupPointsMutation();
  const [createSavedGroupObScreen] = useCreateSavedGroupObScreenMutation();
  const photoGroups = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const [createGroup] = useSavedGroupsMutation();
  const [groupActive, setGroupActive] = useState(true);
  const [groupName, setGroupName] = useState("");
  const [fetchSavedGroups, { isLoading: isFetchingSavedGroups }] = useFetchSavedGroupsMutation();

  const handleFetchSavedGroups = async () => {
    if (!currenOrganization) return;
    try {
      const result = await fetchSavedGroups(currenOrganization.uuid).unwrap();
      setSavedGroups(result.results);
    } catch (error) {
      console.error("Failed to fetch saved groups:", error);
    }
  };

  const handleClickGroup = async (idGroup: number, screens: string[]) => {
    const newScreens = screens!.filter(
      (screen) =>
        !Array.from(selectedPhotos).some((item) => item.url === screen),
    );
    const alreadyExistingCount = screens!.length - newScreens.length;

    if (alreadyExistingCount > 0) {
      const message =
        alreadyExistingCount === 1
          ? "1 screen was already inside this group, we didn't add it a second time."
          : `${alreadyExistingCount} screens were already inside this group, we didn't add them a second time.`;

      toast.info(message);
      return;
    }

    for (const photoId of selectedPhotos) {
      switch (photoId.type) {
        case 'store':
          await addScreeStoreToGroup({
            saved_group: idGroup,
            saved_store_screen: photoId.id,
          }).unwrap();
          break;
        case 'video':
          await addScreenToGroup({
            saved_group: idGroup,
            saved_point: photoId.id,
          }).unwrap();
          break;
        case 'webOnboarding':
          await createSavedGroupObScreen({
            saved_group: idGroup,
            saved_ob_screen: photoId.id,
          }).unwrap();
          break;
        default:
          console.warn(`Unknown image type: ${photoId.type}`);
      }
      handleFetchSavedGroups();
    }

    if (timer) {
      clearTimeout(timer);
    }

    const newTimer = setTimeout(() => {
      setSelectedPhotos(new Set());
      setAddGroupModal(false);
      setSelectActive(false);
    }, 500);

    setTimer(newTimer);
  };

  const handleGroupSelect = (id: number) => {
    setSelectedGroups((prevSelected) => {
      const newSelected = new Set(prevSelected);
      if (newSelected.has(id)) {
        newSelected.delete(id);
      } else {
        newSelected.add(id);
      }
      return newSelected;
    });
  };

  const handleOpenGroup = () => {
    setIsCurrentGroupActive(true);
  };

  const handleCreateGroup = async () => {
    setSelectActive(false);
    try {
      const newGroup = await createGroup({
        organizationUuid: currenOrganization.uuid,
        name: groupName,
        total_saved_points: 0,
        total_saved_store_screens: 0,
      }).unwrap();
      toast.success("Group created successfully!");
      await handleFetchSavedGroups();
      await handleClickGroup(newGroup.id, []);
      setTimeout(() => {
        setModalAddGroupActive(false);
        setGroupName("");
      }, 500);

      setTimeout(() => {
        if (lastGroupRef.current && containerRef.current) {
          const container = containerRef.current;
          const lastGroupBottom =
            lastGroupRef.current.offsetTop + lastGroupRef.current.offsetHeight;
          const targetScrollTop = lastGroupBottom - container.offsetHeight;

          container.scrollTo({
            top: targetScrollTop,
            behavior: "smooth",
          });
        }
      }, 200);
    } catch (error) {
      console.error("Error creating group:", error);
      let errorMessage = "An unknown error occurred";

      if (error.data && error.data.non_field_errors && error.data.non_field_errors.length > 0) {
        errorMessage = error.data.non_field_errors[0];
      } else if (error instanceof Error) {
        errorMessage = error.message;
      }

      console.log("Attempting to show error toast:", errorMessage);
      toast.error(`Failed to create group: ${errorMessage}`);

    } finally {
      setModalAddGroupActive(false);
    }
  };

  const changeGroupName = (event: ChangeEvent<HTMLInputElement>) => {
    setGroupName(event.target.value);
  };

  const handleDeleteGroup = async () => {
    setIsDeleting(true);
    try {
      const deletePromises = Array.from(selectedGroups).map((groupId) =>
        deleteGroupPoint(groupId).unwrap()
      );

      await Promise.all(deletePromises);

      // Update the savedGroups state to remove all deleted groups
      setSavedGroups(produce((draft) => {
        return draft.filter(group => !selectedGroups.has(group.id));
      }));

      setSelectedGroups(new Set());
      setSelectGroupActive(false);

      // Show a success message
      toast.success(`Successfully deleted ${selectedGroups.size} group${selectedGroups.size > 1 ? 's' : ''}`);
    } catch (error) {
      console.error("Failed to delete groups:", error);
      toast.error("Failed to delete some or all groups. Please try again.");
    } finally {
      setIsDeleting(false);
    }
  };

  useEffect(() => {
    handleFetchSavedGroups();
  }, [currenOrganization]);

  const location = useLocation();

  useEffect(() => {
    if (location.state && location.state.openAddGroupModal) {
      setModalAddGroupActive(true);
      if (location.state.selectedPhotos) {
        setSelectedPhotos(new Set(location.state.selectedPhotos));
      }

      window.history.replaceState({}, document.title);
    }
  }, [location]);

  return (

    <div className={Homestyles.modalSaveModeContainer}>
      <div className={Homestyles.modalSaveMode}>
        <div ref={containerRef} className={styles.root}>

          <div className={styles.headerGroupContent}>
            {selectGroupActive ? (
              <div className={styles.headerGroupLeftSide}>
                <div
                  onClick={() => {
                    setSelectGroupActive(!selectGroupActive);
                    setSelectedGroups(new Set());
                  }}
                  className={styles.selectSave}
                >
                  <img src="/img/cursorClickIcon.svg" alt="cursorClickImg" />
                  <p>Deselect All</p>
                </div>
              </div>
            ) : (
              <div className={styles.headerGroupLeftSide}>



                <NavLink to="/saved-library/" className={styles.groupArrow}>
                  <img
                    src="/img/blackIcon/ChevronLeft.svg"
                    alt="arrowGroupLeftImg"
                  />
                </NavLink>

                {/* 444444444 */}
                {/* <div
                  onClick={() => setSelectGroupActive(!selectGroupActive)}
                  className={styles.selectSave}
                >
                  <img src="/img/cursorClickIcon.svg" alt="cursorClickImg" />
                  <p>Select</p>
                </div> */}
              </div>
            )}
            <div ref={refCreateGroup} className={styles.groupSaveActive}>
              <img src="/img/blackIcon/SavedGroup.svg" alt="groupSaveImg" />
              <p>Saved Groups</p>
            </div>
            {selectedGroups.size > 0 ? (
              <div onClick={handleDeleteGroup} className={styles.groupCreate}>
                {isDeleting ? (
                  <Spinner width="30px" />
                ) : (
                  <img src="/img/blackIcon/Delete.svg" alt="trashImg" />
                )}
                <p>Delete</p>
              </div>
            ) : (
              <div
                ref={refCreateGroupBtn}
                onClick={() => setModalAddGroupActive(!modalAddGroupActive)}
                className={styles.groupCreate}
              >
                <img src="/img/blackIcon/Plus.svg" alt="plusGroupImg" />
                <p>Create New Group</p>
              </div>
            )}
          </div>

          <div className={styles.mainContent}>
            {groupActive ? (
              <div className={styles.groupPhotoContainerWrapper}>
                {isCurrentGroupActive ? (
                  <div
                    ref={photoGroupRef}
                    className={styles.groupPhotoContainerActive}
                  >
                  </div>
                ) : (
                  <div className={styles.groupPhotoContainer}>

                    {savedGroups.length > 0 ? (
                      savedGroups.map((item, index) => {
                        const countScreens =
                          item.total_saved_points + item.total_saved_store_screens + item.total_saved_animations + item.total_saved_ob_screens;
                        const isLastGroup =
                          index === savedGroups.length - 1;
                        // 2222222
                        return (
                          <ModalSaveModeGroups
                            key={index}
                            selectGroupActive={true}
                            selectedGroups={selectedGroups}
                            item={item}
                            isLastGroup={isLastGroup}
                            handleGroupSelect={handleGroupSelect}
                            handleOpenGroup={handleOpenGroup}
                            setFilterValueGroup={setFilterValueGroup}
                            setGroupIdPoint={setGroupIdPoint}
                            countScreens={countScreens}
                            imageDimensions={imageDimensions}
                            ref={lastGroupRef}
                          />
                        );
                      })
                    ) : (
                      <ModalAppsEmpty
                        text="no groups added"
                        img="/img/groupEmptyIcon.svg"
                      />
                    )}
                  </div>
                )}
              </div>
            ) : (
              <div ref={photoGroups} className={styles.photosContainer}>

              </div>
            )}
          </div>

          {modalAddGroupActive && (
            // Create a new group
            <ModalSaveModeGroup
              ref={refCreateGroup}
              changeGroupName={changeGroupName}
              groupName={groupName}
              handleCreateGroup={handleCreateGroup}
            />
          )}

        </div>
      </div>
    </div>
  );
};

export { SavedModeGroupListView };
