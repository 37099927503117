import React, { useState, useEffect, useRef, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useInView } from "react-intersection-observer";
import Modal from "react-modal";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { IoIosCheckmarkCircle } from "react-icons/io";
import {
  useGetVideoStoreScrollQuery,
  useHiddenAppsMutation,
  useSavedAppsMutation,
  useSavedStorePointsMutation,
  useDeleteSavedAppsMutation,
  useSavedStoreScreensDeleteMutation,
} from "@/features/auth/api/authAPI";
import { modalMenuPhoneActions } from "@/entities/modalMenuPhone";
import InfoPhoneHomePageHorizontal from "@/shared/ui/InfoPhoneHomePageHorizontal/InfoPhoneHomePageHorizontal";
import { modalItemData } from "@/widgets/phoneHomePageHorisontal/libs/const/modalItemData";
import { formatNumber } from "@/widgets/phoneHomePageHorisontal/libs/const/formatNumber";
import {
  DisplayDataItem,
  PhoneHomePageHorisontalProps,
} from "@/widgets/phoneHomePageHorisontal/libs/types/type";
import styles from "./ExploreStoreRow.module.scss"; //"./PhoneHomePageStore.module.scss";
import ContentLoader from "react-content-loader";
import { filterAppsDataActions, getFilterAppsData } from "@/entities/filterAppsData";
import { produce } from 'immer'
import MenuButtons from "@/widgets/phoneHomePageHorisontal/ui/MenuButtons/MenuButtons";
import mixpanel from "@/shared/lib/mixpanel";
import { useMe, useCurrentOrganization } from "@/Hooks";
import { toast } from "react-toastify";
import { RightClickModal } from "@/shared/ui/RightClickModal/RightClickModal";
import { handleOpenVideoPage } from "@/app/libs/const/handleOpenVideoPage";
import { getButtonFilter } from "@/entities/buttonFilter";

import AutoSizer from "react-virtualized-auto-sizer";
import { FixedSizeList as List } from 'react-window';

const ExploreStoreRow: React.FC<PhoneHomePageHorisontalProps> = ({
  isOpen,
  dataApi,
  organization
}) => {
  const [page, setPage] = React.useState(1);
  const [isHover, setIsHover] = useState(false);
  const [data, setData] = React.useState<DisplayDataItem[]>([]);
  const [lastFetchedTime, setLastFetchedTime] = React.useState(Date.now());
  const scrollContainerRef = React.useRef<HTMLDivElement>(null);
  const [isInfoModal, setIsInfoModal] = React.useState<boolean>(false);
  const [textInfoModa, setTextInfoModal] = React.useState<string>("");
  const [showStarredMessage, setShowStarredMessage] = React.useState(false);
  const [isHiddenClickLoading, setIsHiddenClickLoading] =
    React.useState<boolean>(false);
  const [showHiddenMessage, setShowHiddenMessage] =
    React.useState<boolean>(false);
  const [isScrolling, setIsScrolling] = React.useState(false);
  const [isStarClickLoading, setIsStarClickLoading] =
    React.useState<boolean>(false);
  
  //const [showSkeleton, setShowSkeleton] = React.useState(true);
  const showSkeleton = false
  const phoneHomePageStoreRef = React.useRef<HTMLDivElement>(null);
  const [selectedImage, setSelectedImage] = React.useState<string | undefined>(
    undefined,
  );
  const [totalImagesCount, setTotalImagesCount] = React.useState(0);
  const [loadedImagesCount, setLoadedImagesCount] = React.useState(0);
  const [, setIsPhonePageLoading] = React.useState(false);
  const [selectedImageId, setSelectedImageId] = React.useState<null | number>(
    null,
  );
  const [imageIndex, setImageIndex] = React.useState<number | null>(null);
  // const [isActiveSave, setIsActiveSave] = React.useState(true);
  const [hoveredImageId, setHoveredImageId] = React.useState<number | null>(
    null,
  );
  const idVideo = dataApi.avs.id;

  // const { data: apiDataVideosScreens, isLoading: isLoadingScreens, refetch: refetchData } =
  //   useGetVideoStoreScrollQuery(
  //     { id: dataApi?.id, page },
  //     { refetchOnMountOrArgChange: true },
  //   );

  const apiDataVideosScreens = dataApi.store_screens
  const isLoadingScreens = false

  const [savedStoreScreen] = useSavedStorePointsMutation();
  const [savedStoreScreensDelete] = useSavedStoreScreensDeleteMutation();

  const [hideApp] = useHiddenAppsMutation();
  const [saveApp] = useSavedAppsMutation();
  const [deleteSavedApp] = useDeleteSavedAppsMutation();

  const { ref, inView } = useInView({ threshold: 0 });

  const { activeIndex, activeView } = useSelector(getButtonFilter)

  const dispatch = useDispatch();
  const navigate = useNavigate();
  //const [modalPhoneHorisontalRef] = useAutoAnimate();

  const { me } = useMe();
  const currentOrganization = useCurrentOrganization();

  const [selectedItem, setSelectedItem] = useState()
  const [appStoreScreens, setAppStoreScreens] = useState(dataApi.store_screens)
  const [appStoreScreensNext, setAppStoreScreensNext] = useState()

  const [contextMenu, setContextMenu] = useState({
    show: false,
    x: 0,
    y: 0,
    imageId: '',
    timestamp: 0,
    imageUrl: ''
  });

  useEffect(() => {
    if (apiDataVideosScreens) {
      setAppStoreScreens(apiDataVideosScreens)
      setAppStoreScreensNext(false)
    }
  }, [apiDataVideosScreens])


  useEffect(() => {

    if (appStoreScreens.length && selectedItem) {
      // force reselected selected item so modal is re-renreder post save of store screen
      setSelectedItem(appStoreScreens.find((item) => item.id == selectedItem.id))
    }
  }, [appStoreScreens])

 const { savedApps } = useSelector(getFilterAppsData);

  const isStarred = savedApps?.some(
    (item) => item.app.id === dataApi.id,
  );

  const handleClickPhone = (timestamp: number) => {
    if (!isScrolling) {
      handleOpenVideoPage(
        timestamp,
        dataApi.slug,
        "Home Page screens",
        1,
        dataApi.id,
        Number(dataApi.avs.id),
        undefined, 
        me?.username || "",
        currentOrganization?.uuid || ""
      );
    }
  };
  const loadMore = () => {
    if (appStoreScreensNext && Date.now() - lastFetchedTime > 500) {
      setPage((prev) => prev + 1);
      setLastFetchedTime(Date.now());
    }
  };

  React.useEffect(() => {
    if (inView) {
      loadMore();
    }
  }, [inView]);

  const handleScroll = () => {
    if (scrollContainerRef.current) {
      setIsScrolling(true);
      setTimeout(() => setIsScrolling(false), 500);
    }
  };

  const handleMouseEnter = () => {
    dispatch(modalMenuPhoneActions.setModalId(dataApi.id));
  };

  const prepareDataForDisplay = (): DisplayDataItem[] => {
    if (!appStoreScreens.length) return [];

    const imagesWithLabels: DisplayDataItem[] = [];
    const labelGroups: Record<string, DisplayDataItem> = {};

    appStoreScreens.forEach((image) => {
      if (image.labels && image.labels.length > 0) {
        image.labels.forEach((label) => {
          if (!labelGroups[label]) {
            labelGroups[label] = {
              label,
              images: [],
              isGrouped: true,
            };
          }
          labelGroups[label].images.push(image);
        });
      } else {
        imagesWithLabels.push({
          label: "",
          images: [image],
          isGrouped: false,
        });
      }
    });

    return [...imagesWithLabels, ...Object.values(labelGroups)];
  };

  const InfoItemData = [
    {
      img: "dollarFilterIconActive",
      title: "Monthly Revenue:",
      text: formatNumber(dataApi.revenue),
    },
    {
      img: "downloadFilterIconActive",
      title: "Monthly Installs:",
      text: formatNumber(dataApi.downloads),
    },
    {
      img: "creditCardLockActive",
      title: "Paywall Type:",
      text: dataApi.avs.paywall_type,
    },
    {
      img: "dotsGridActive",
      title: "Onboarding Steps:",
      text: dataApi.avs.onboarding_step_count,
    },
  ];

  const handleImageLoad = () => {
    setLoadedImagesCount((prevCount) => prevCount + 1);
  };

  if (
    (loadedImagesCount === totalImagesCount ||
      loadedImagesCount > totalImagesCount) &&
    totalImagesCount > 0 &&
    !isLoadingScreens
  ) {
    setTimeout(() => {
      setIsPhonePageLoading(true);
    }, 1);
  }

  React.useEffect(() => {
    const displayData = prepareDataForDisplay();

    setData((prev: DisplayDataItem[]) => {
      const mergedData = [...prev, ...displayData];

      mergedData.sort((a, b) => {
        return (
          parseFloat(a.images[0].timestamp!) -
          parseFloat(b.images[0].timestamp!)
        );
      });

      return mergedData;
    });

    if (appStoreScreens.length) {
      setTotalImagesCount(appStoreScreens.length);
    }
  }, [appStoreScreens]);

  const openImageModal = (item, image: string, index: number, imageId: number) => {
    setSelectedItem(item)
    setSelectedImage(image);
    setImageIndex(index);
    setSelectedImageId(imageId);
  };

  const closeImageModal = () => {
    setSelectedItem(undefined)
    setSelectedImage(undefined);
    setImageIndex(null);
  };

  const handleNextImage = () => {
    if (imageIndex !== null && appStoreScreens.length) {
      const nextIndex = (imageIndex + 1) % appStoreScreens.length;
      if (nextIndex === 0) {
        setPage((prev) => prev + 1);
      }
      const nextItem = appStoreScreens[nextIndex];
      if (nextItem) {
        setSelectedItem(nextItem)
        setImageIndex(nextIndex);
        setSelectedImage(nextItem.thumbnail);
        setSelectedImageId(nextItem.id);
      }
    }
  };

  const handlePrevImage = () => {
    if (imageIndex !== null && appStoreScreens.length) {
      const prevIndex = (imageIndex - 1 + appStoreScreens.length) % appStoreScreens.length;
      setImageIndex(prevIndex);
      const prevItem = appStoreScreens[prevIndex];
      if (prevItem) {
        setSelectedItem(prevItem)
        setSelectedImage(prevItem.thumbnail);
        setSelectedImageId(prevItem.id);
      }
    }
  };

  React.useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "ArrowRight") {
        handleNextImage();
      } else if (event.key === "ArrowLeft") {
        handlePrevImage();
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [imageIndex, data]);

  interface ITipestampMarker {
    appVideo: number;
    storeScreen: number;
  }

  const handleHideClick = async () => {
    setTextInfoModal("App Hidden");
    setIsHiddenClickLoading(true);
    const isUserLogin = localStorage.getItem("access_token");

    if (!isUserLogin) {
      navigate("/register");
      localStorage.setItem("btnName", "hide");
      return;
    }
    setIsInfoModal(false);

    try {
      setShowHiddenMessage(true);
      await hideApp({
        app: dataApi.id,
      }).unwrap();

      if (me?.email) {
        mixpanel.identify(me.email);
        setTimeout(() => {
          mixpanel.people.increment('total_hidden_apps', 1);
        }, 100);
      }

      mixpanel.track('App hidden', {
        idApp: dataApi.id,
        app_name: dataApi.name,
        UserEmail: me?.email || '',
        OrganizationID: currentOrganization?.uuid || '',
      });

      toast.success("App hidden successfully");
    } catch (error) {
      console.error("Error hiding app:", error);
      toast.error("Error hiding app");
    } finally {
      setIsHiddenClickLoading(false);
    }
    dispatch(filterAppsDataActions.removeAppFromData(dataApi.id));
  };

  const handleStarClick = async () => {
    const isUserLogin = localStorage.getItem("access_token");
    if (!isUserLogin) {
      navigate("/register");
      localStorage.setItem("btnName", "star an app");
      return;
    }

    setIsInfoModal(false);
    setIsStarClickLoading(true);

    try {
      if (isStarred || isStarClickLoading || showStarredMessage) {
        const foundFilteredId = savedApps?.filter(
          (result) => result.app.id === dataApi.id
        );

        if (foundFilteredId && foundFilteredId.length > 0) {
          await deleteSavedApp(foundFilteredId[0].id).unwrap();

          if (me?.email) {
            mixpanel.identify(me.email);

            setTimeout(() => {
              mixpanel.people.decrement('total_saved_apps', 1);

              mixpanel.track('App unsaved', {
                idApp: dataApi.id,
                app_name: dataApi.name,
                UserEmail: me.email,
                OrganizationID: currentOrganization?.uuid || '',
              });
            }, 500);
          }
          toast.success("App removed from saved items");
        }
      } else {
        await saveApp({
          app: dataApi.id,
        }).unwrap();

        if (me?.email) {
          mixpanel.identify(me.email);

          setTimeout(() => {
            mixpanel.people.increment('total_saved_apps', 1);

            mixpanel.track('App saved', {
              idApp: dataApi.id,
              app_name: dataApi.name,
              UserEmail: me.email,
              OrganizationID: currentOrganization?.uuid || '',
            });
          }, 500);
        }

        toast.success("App saved successfully");
        setShowStarredMessage(true);
        setTimeout(() => {
          setShowStarredMessage(false);
        }, 500);
      }
    } catch (error) {
      console.error("Error toggling star status:", error);
      toast.error("Error toggling star status");
    } finally {
      setIsStarClickLoading(false);
    }
  };

  // const timestampMarkersToView = timestampMarkersStorageStore
  //   ? timestampMarkersStorageStore &&
  //     JSON.parse(timestampMarkersStorageStore).map((item: ITipestampMarker) => {
  //       return item.storeScreen;
  //     })
  //   : timestampMarkers?.map((item) => item.storeScreen) || [];


  const uniqueData: DisplayDataItem[] = React.useMemo(() => {
    const uniqueImagesMap = new Map();
    let processedImages = 0;
    const maxImages = Infinity;

    for (const item of data) {
      for (const image of item.images) {
        if (processedImages >= maxImages) break;

        // if (!uniqueImagesMap.has(image.id)) { invalidate set to force re-render due to garbage implementation from before
        uniqueImagesMap.set(image.id, {
          ...image,
          label: item.label,
          isGrouped: item.isGrouped,
        });
        processedImages++;
        //}
      }
      if (processedImages >= maxImages) break;
    }

    const uniqueImages = Array.from(uniqueImagesMap.values());

    return uniqueImages.reduce((acc, image) => {
      const lastGroup = acc[acc.length - 1];
      if (lastGroup && lastGroup.label === image.label) {
        lastGroup.images.push(image);
      } else {
        acc.push({
          label: image.label,
          images: [image],
          isGrouped: image.isGrouped,
        });
      }
      return acc;
    }, []);
  }, [data]);

  // const dataImgPro = data
  //   ? data.slice(2, 5).map((item) => ({ img: item?.images[0].thumbnail }))
  //   : [];

  // const dataImgProActive = data
  //   ? data.slice(2, 10).map((item) => ({ img: item?.images[0].thumbnail }))
  //   : [];

  // useEffect(() => {
  //   if (
  //     uniqueData.length > 0 &&
  //     appStoreScreens.length &&
  //     !isLoadingScreens
  //   ) {
  //     setShowSkeleton(false);
  //   }
  //   dispatch(filterAppsDataActions.setIsScreensLoadingStore(isLoadingScreens))
  // }, [uniqueData, isLoadingScreens, appStoreScreens]);




  const handleSaveImage = async (storeScreen: number) => {
    if (organization) {
      const imageToUpdate = appStoreScreens.find((item) => item.id === storeScreen);

      if (imageToUpdate) {
        try {
          if (imageToUpdate.is_saved) {
            // Delete the saved image
            await savedStoreScreensDelete(imageToUpdate.is_saved_pk).unwrap();

            if (me?.email) {
              mixpanel.identify(me.email);
              setTimeout(() => {
                mixpanel.people.increment('total_unsaved_screens', 1);
              }, 100);
            }

            setAppStoreScreens(produce((draft) => {
              const index = draft.findIndex((obj) => obj.id === storeScreen);
              if (index !== -1) {
                draft[index].is_saved = false;
                draft[index].is_saved_pk = null;
              }
            }));

            toast.success("Image removed from saved items");
          } else {
            // Save the image
            const resp = await savedStoreScreen({
              organizationUuid: organization,
              storeScreen: storeScreen,
            }).unwrap();

            mixpanel.track('Image saved', {
              idApp: dataApi.id,
              app_name: dataApi.name,
              From: 'HomePage',
              idscreen: storeScreen,
              UserEmail: me?.email || '',
              OrganizationID: currentOrganization?.uuid || '',
            });

            if (me?.email) {
              mixpanel.identify(me.email);
              setTimeout(() => {
                mixpanel.people.increment('total_saved_screens', 1);
              }, 100);
            }

            setAppStoreScreens(produce((draft) => {
              const index = draft.findIndex((obj) => obj.id === storeScreen);
              if (index !== -1) {
                draft[index].is_saved = true;
                draft[index].is_saved_pk = resp.id;
              }
            }));

            //toast.success("Image saved successfully");
          }
        } catch (error) {
          if (axios.isAxiosError(error)) {
            console.error("Save/Delete operation failed:", error.response?.data.error);
            toast.error(error.response?.data.error);
          } else {
            console.error("An unexpected error occurred:", error);
            toast.error("An unexpected error occurred");
          }
        }
      } else {
        console.error("Image not found in appStoreScreens");
        toast.error("Unable to process image. Image data is missing.");
      }
    } else {
      localStorage.setItem("btnName", "save image");
      navigate("/register", { state: { fromLogin: true } });
      window.scrollTo(0, 0);
    }
  };

  // Add these handlers to your component if they don't exist
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);


  const handleContextMenu = (e: React.MouseEvent, imageId: string, timestamp: number) => {
    e.preventDefault();

    const selectedImage = uniqueData
      ?.flatMap(group => group.images)
      .find(img => img.id.toString() === imageId);

    const imageUrl = selectedImage?.thumbnail || selectedImage?.screen || '';

    setContextMenu({
      show: true,
      x: e.clientX,
      y: e.clientY,
      imageId,
      timestamp,
      imageUrl
    });
  };

  const handleCloseContextMenu = () => {
    setContextMenu(prev => ({
      ...prev,
      show: false
    }));
  };

  const onItemsRendered = ({ overscanStartIndex, overscanStopIndex, visibleStartIndex, visibleStopIndex }) => { }

  const columnsListRef = useRef();

  const Column = useMemo(() => {

      return ({ index, style }) => {

        const item = appStoreScreens[index]

        return (
          <div style={style}>
            <div className={styles.photo} >
              <div 
                className={styles.photoContainer} 
                onMouseEnter={handleMouseEnter}
                
              >
                        <div
                          key={item.id}
                          onMouseEnter={() => setHoveredImageId(item.id)}
                          onMouseLeave={() => setHoveredImageId(null)}
                          onContextMenu={(e) => {
                            try {
                              handleContextMenu(e, item.id.toString(), item.timestamp);
                            } catch (error) {
                              console.error('Error in context menu handler:', error);
                              toast.error('Error showing context menu');
                            }
                          }}
                        >
                          <div className={styles.photo} onClick={() => handleClickPhone(0)}>
                            <img
                              // ref={ref}
                              src={item.thumbnail}
                              alt="Screen"
                              className={styles.groupedImage}
                              onLoad={handleImageLoad}
                              loading="lazy"
                            />
                          </div>
                          {hoveredImageId === item.id && (
                            <>
                              <button
                                onClick={() => handleSaveImage(item.id)}
                                className={styles.bookmarkContainer}
                              >
                                <div className={styles.bookmark}>
                                  {item.is_saved ? (
                                    <div className={styles.tooltipContainer}>
                                      <div className={styles.iconWrapper}>
                                        <IoIosCheckmarkCircle
                                          style={{
                                            color: "green",
                                            fontSize: "20px",
                                          }}
                                          tabIndex={0}
                                        />
                                      </div>
                                      <span className={styles.tooltipText}>Remove Image</span>
                                    </div>
                                  ) : (
                                    <div className={styles.tooltipContainer}>
                                      <img
                                        className={styles.bookmarkIcon}
                                        src="/img/blackIcon/Save.svg"
                                        alt="bookmarkImg"
                                        loading="lazy"
                                        tabIndex={0}
                                      />
                                      <span className={styles.tooltipText}>Save Image</span>
                                    </div>
                                  )}
                                </div>
                              </button>
                              <button
                                onClick={() => openImageModal(item, item.thumbnail!, index, item.id)}
                                className={styles.bigscreenContainer}
                              >
                                <div className={styles.bigscreen}>
                                  <img
                                    src="/img/blackIcon/FullScreen.svg"
                                    alt="expandModalIcon"
                                    loading="lazy"
                                    className={styles.bigscreenIcon}
                                  />
                                </div>
                              </button>
                            </>
                          )}
                        </div>
              </div>
          </div>
          </div>
      )
    }
    }, [appStoreScreens, hoveredImageId])

  return (
    <>

      <div className={styles.root}>
        <div className={styles.containerTitle}>
          <div className={styles.boxLeft}>
            <img
              className={styles.logo}
              src={
                !dataApi.icon || dataApi.icon === ""
                  ? "/img/skeletonLogo.svg"
                  : dataApi.icon
              }
              alt="ing-logoHeader"
              onClick={() => handleClickPhone(0)}
              loading="lazy"
              draggable={false}
            />
            <div
              className={styles.title}
            >
              <h1 className={`${styles.titleMainText}`} onClick={() => handleClickPhone(0)}>{dataApi.name}</h1>
              <p className={`${styles.titleText}`} onClick={() => handleClickPhone(0)}>{dataApi.shortname}</p>
            </div>
          </div>

          <div className={styles.infoDesktopList}>
            {InfoItemData.map((item, index) => {
              let displayText = item.text;
              if (
                (item.title === "Monthly Installs:" ||
                  item.title === "Monthly Revenue:") &&
                item.text === 0
              ) {
                displayText = "<5k";
              }
              return (
                <div key={index}>
                  <InfoPhoneHomePageHorizontal
                    img={item.img}
                    title={item.title}
                    text={displayText}
                    dataApi={dataApi}
                  />
                </div>
              );
            })}
              <div className={styles.dots} onClick={() => setIsInfoModalOpen(!isInfoModalOpen)}>
                <img src="/img/blackIcon/Dots.svg" alt="dots" />
                {isInfoModalOpen && (
                  <div className={styles.infoModal}>
                    <div className={styles.infoModalContent}>
                      <MenuButtons
                        handleHideClick={handleHideClick}
                        handleStarClick={handleStarClick}
                        isStarred={isStarred}
                        isStarClickLoading={isStarClickLoading}
                        showStarredMessage={showStarredMessage}
                        className={styles.infoMenuMobile}
                      />
                    </div>
                  </div>
                )}
              </div>

          </div>
        </div>
        <div ref={phoneHomePageStoreRef}>
          <div
            className={styles.containerPhotos}
            ref={scrollContainerRef}
            // onScroll={handleScroll}
          >

        
            <AutoSizer disableHeight>
              {({ width }) => {

                  const itemSize = 280 //width/5.5//*zoom

                  return (
                      <List
                      //onScroll={handleOnScroll}
                      ref={columnsListRef}
                      // style={{ marginTop: '20px' }}
                      height={500}
                      itemCount={appStoreScreens.length}
                      itemSize={280}
                      layout="horizontal"
                      width={width}
                      onItemsRendered={onItemsRendered}
                      overscanCount={0}
                      >
                      {Column}
                      </List>
                  )
              }}
              </AutoSizer>

            {/* {showSkeleton && (
              <div className={styles.skeletonPhoto}>
                {Array.from({
                  length: page * 10,
                }).map((_, index) => (
                  <ContentLoader
                    key={index}
                    speed={2}
                    width={266.06}
                    height={474}
                    viewBox="0 0 266.06 474"
                    backgroundColor="#f3f3f3"
                    foregroundColor="#ecebeb"
                  >
                    <rect
                      x="0"
                      y="0"
                      rx="7"
                      ry="7"
                      width="266.06"
                      height="474"
                    />
                  </ContentLoader>
                ))}
              </div>
            )} */}
            {/* {uniqueData?.map((item, index) => {
              return (
                <div key={`Photos--${index}`} className={styles.photo}>
                  {item.isGrouped && (
                    <p
                      className={`${item.label === "paywall"
                        ? styles.spanLabelPaywall
                        : styles.spanLabel
                        }`}
                    >
                      <span>{item.label}</span>
                    </p>
                  )}
                  <div
                    className={styles.photoContainer}
                    onMouseEnter={handleMouseEnter}
                  >
                    {item.images.map((image) => {
                      return (
                        <div
                          key={image.id}
                          onMouseEnter={() => setHoveredImageId(image.id)}
                          onMouseLeave={() => setHoveredImageId(null)}
                          onContextMenu={(e) => {
                            try {
                              handleContextMenu(e, image.id.toString(), image.timestamp);
                            } catch (error) {
                              console.error('Error in context menu handler:', error);
                              toast.error('Error showing context menu');
                            }
                          }}
                        >
                          <div className={styles.photo} onClick={() => handleClickPhone(0)}>
                            <img
                              // ref={ref}
                              src={image.thumbnail}
                              alt="Screen"
                              className={styles.groupedImage}
                              onLoad={handleImageLoad}
                              loading="lazy"
                            />
                          </div>
                          {hoveredImageId === image.id && (
                            <>
                              <button
                                onClick={() => handleSaveImage(image.id)}
                                className={styles.bookmarkContainer}
                              >
                                <div className={styles.bookmark}>
                                  {image.is_saved ? (
                                    <div className={styles.tooltipContainer}>
                                      <div className={styles.iconWrapper}>
                                        <IoIosCheckmarkCircle
                                          style={{
                                            color: "green",
                                            fontSize: "20px",
                                          }}
                                          tabIndex={0}
                                        />
                                      </div>
                                      <span className={styles.tooltipText}>Remove Image</span>
                                    </div>
                                  ) : (
                                    <div className={styles.tooltipContainer}>
                                      <img
                                        className={styles.bookmarkIcon}
                                        src="/img/blackIcon/Save.svg"
                                        alt="bookmarkImg"
                                        loading="lazy"
                                        tabIndex={0}
                                      />
                                      <span className={styles.tooltipText}>Save Image</span>
                                    </div>
                                  )}
                                </div>
                              </button>
                              <button
                                onClick={() => openImageModal(image, image.thumbnail!, index, image.id)}
                                className={styles.bigscreenContainer}
                              >
                                <div className={styles.bigscreen}>
                                  <img
                                    src="/img/blackIcon/FullScreen.svg"
                                    alt="expandModalIcon"
                                    loading="lazy"
                                    className={styles.bigscreenIcon}
                                  />
                                </div>
                              </button>
                            </>
                          )}
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })} */}

          </div>
        </div>
        <div 
          className={styles.modalWindow} 
          //ref={modalPhoneHorisontalRef}
          >
        </div>
      </div>


      <Modal
        isOpen={!!selectedImage}
        onRequestClose={closeImageModal}
        contentLabel="Image Modal"
        className={styles.modal}
        overlayClassName={styles.overlay}
      >
        <div className={styles.modalContent} onContextMenu={(e) => handleContextMenu(e, selectedImageId?.toString() || '', selectedItem?.timestamp || 0)} >
          <div
            onClick={() => handleClickPhone(0)}
            className={styles.containerImage}
          >
            <img
              className={styles.modalContentPhoto}
              onMouseEnter={() => setIsHover(true)}
              onMouseLeave={() => setIsHover(false)}
              src={selectedImage!}
              alt="Enlarged pic"
              loading="lazy"
            />
          </div>
          <div className={`${styles.bookmarkmodalContainer} ${isHover ? styles.visible : ''}`}>
            <button
              onClick={() => handleSaveImage(selectedItem.id)}
              className={styles.bookmarkmodal}
            >
              {selectedItem && selectedItem.is_saved ? (
                <IoIosCheckmarkCircle
                  style={{
                    color: "green",
                    fontSize: "20px",
                  }}
                />
              ) : (
                <img
                  className={styles.bookmarkIcon}
                  src="/img/blackIcon/Save.svg"
                  alt="bookmarkImg"
                  loading="lazy"
                />
              )}
            </button>
          </div>
          <img
            className={styles.arrowRight}
            src="/img/whiteIcon/ChevronRight.svg"
            alt="arrowRightImg"
            onClick={handleNextImage}
            loading="lazy"
          />
          <img
            className={styles.arrowLeft}
            src="/img/whiteIcon/ChevronLeft.svg"
            alt="arrowLeftImg"
            onClick={handlePrevImage}
            loading="lazy"
          />
        </div>
      </Modal>

      {contextMenu.show && (
        <>
          {!contextMenu.imageUrl && console.warn('RightClickModal rendered without imageUrl:', contextMenu)}
          <RightClickModal
            x={contextMenu.x}
            y={contextMenu.y}
            onClose={handleCloseContextMenu}
            imageUrl={contextMenu.imageUrl}
            imageId={contextMenu.imageId}
            timestamp={contextMenu.timestamp}
            handleOpenInNewTab={handleClickPhone}
            data-context-menu
            avsID={dataApi.avs.id}
            //refetchData={refetchData}
            imageType="store"
            isStoreScreen={true}
          />
        </>
      )}
    </>
  );
};

export default ExploreStoreRow;