import React, { useState, useEffect } from 'react'

import { useGetUserQuery } from "@/features/auth/api/authAPI";

import { useMe } from '@/Hooks'


const useCurrentOrganization = () => {
    // red, 2nd temporary implementation while refactoring
    const { me } = useMe()
    const [org, setOrg] = useState()
    
    // const token = localStorage.getItem("access_token");
    // const { data: userData } = useGetUserQuery(null, { skip: !token });

    useEffect(() => {
        if (me) {
            setOrg(me.organizations.find((item) => item.active ))
        }
    }, [me])

    return org
}


export { useCurrentOrganization }