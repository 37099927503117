// react
import React, { useState } from "react";
// types
import { IAppSavedGroupPoints } from "@/features/auth/types/auth";
// styles
import styles from "./ModalSaveModeSavedGroupScreens.module.scss";
import { RightClickModal } from "../RightClickModal/RightClickModal";
import { handleOpenVideoPage } from '@/app/libs/const/handleOpenVideoPage';

interface ModalSaveModeSavedGroupScreensProps {
  handleCurrentGroupSelect: (id: number, url: string, videoId: number) => void;
  item: IAppSavedGroupPoints;
  openGroupPhotoModal: (
    photo: string,
    photoId: number,
    name: string,
    ts: number,
    imageStoreId: number,
    appId?: number,
    slug?: string,
  ) => void;
  selectedCurrentGroups: Set<{
    id: number;
    url: string;
    videoId: number;
    type: string
  }>;
}

const ModalSaveModeSavedGroupScreens: React.FC<
  ModalSaveModeSavedGroupScreensProps
> = ({
  handleCurrentGroupSelect,
  item,
  openGroupPhotoModal,
  selectedCurrentGroups,
}) => {
    const [hoveredScreenId, setHoveredScreenId] = useState<number | null>(null);
    const [rightClickModalVisible, setRightClickModalVisible] = useState(false);
    const [rightClickPosition, setRightClickPosition] = useState({ x: 0, y: 0 });

    const handleRightClick = (e: React.MouseEvent) => {
      e.preventDefault();
      setRightClickPosition({ x: e.clientX, y: e.clientY });
      setRightClickModalVisible(true);
    };

    const closeRightClickModal = () => {
      setRightClickModalVisible(false);
    };

    const isSelected = Array.from(selectedCurrentGroups).some(
      screen => screen.id === item.id
    );

    return (
      <div
        className={`${styles.root} ${isSelected ? styles.selectedRoot : ''}`}
        onMouseEnter={() => setHoveredScreenId(item.id)}
        onMouseLeave={() => setHoveredScreenId(null)}
        onContextMenu={handleRightClick}
      >
        <div
          onClick={(event) => {
            event.stopPropagation();
            handleCurrentGroupSelect(
              item.id,
              item.saved_point.screen,
              item.saved_point.app_video,
              'video'
            );
          }}
          className={isSelected ? styles.hoverElementActive : styles.hoverElement}
        >
          {isSelected && (
            <img
              src="/img/whiteIcon/Check.svg"
              alt="checkCircleImg"
            />
          )}
        </div>
        <div
          className={styles.photoItemTitle}
          onClick={() =>
            openGroupPhotoModal(
              item.saved_point.screen,
              item.id,
              item.saved_point.app.name,
              Number(item.saved_point.ts),
              0,
              item.saved_point.app.id,
              item.saved_point.app.slug,
            )
          }
        >
          <img
            src={
              item.saved_point.app.icon !== null
                ? item.saved_point.app.icon
                : "/img/skeletonLogo.svg"
            }
            alt="groupPhotoIcon"
            loading="lazy"
          />
          <div className={styles.photoItemContent}>
            <h3 title={item.saved_point.app.name}>{item.saved_point.app.name}</h3>
            <p title={item.saved_point.app.shortname}>{item.saved_point.app.shortname}</p>
          </div>
        </div>
        <div
          className={`${styles.photoContainer} ${isSelected ? styles.selectedPhotoContainer : ""}`}
        >
          <div className={styles.containerPhoto} onClick={() =>
        handleOpenVideoPage(
          Number(item.saved_point.ts),
          item.saved_point.app.slug,
          'video',
          1,
          item.saved_point.app.id,
          item.saved_point.app_video
        )}
          >
            <img
              src={item.saved_point.screen}
              alt="groupScreenImg"
              className={`${styles.photo} ${isSelected ? styles.selectedPhoto : ""}`}
              loading="lazy"
            />
          </div>
        </div>

        {rightClickModalVisible && (
          <RightClickModal
            x={rightClickPosition.x}
            y={rightClickPosition.y}
            onClose={closeRightClickModal}
            imageUrl={item.saved_point.screen}
            imageId={item.id}
            timestamp={Number(item.saved_point.ts)}
            isStoreScreen={false}
            handleOpenInNewTab={() => handleOpenVideoPage(
              Number(item.saved_point.ts),
              item.saved_point.app.slug,
              'video',
              1,
              item.saved_point.app.id,
              item.saved_point.app_video
            )}
            avsID={item.saved_point.app_video}
            refetchData={() => console.log('Refetch data')}
            imageType="video"
          />
        )}
      </div>
    );
  };

export default ModalSaveModeSavedGroupScreens;
