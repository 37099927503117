// redux
import { useDispatch, useSelector } from "react-redux";
// entities
import React from "react";
import { getSortByCategoriesSlice } from "@/entities/sortByCategories/model/selectors/getSortByCategoriesState";
// shared
import CategoriesRevenueModalItem from "../../../../shared/ui/CategoriesRevenueModalItem/CategoriesRevenueModalItem";
// hook
import useHandleClick from "../../libs/hook/useHandleClick";
// data
import { dataItem } from "../../libs/data/dataItem";
// styles
import styles from "./CategoriesRevenueModal.module.scss";
import { sortByCategoriesActions } from "@/entities/sortByCategories";

interface CategoriesRevenueModalProps {}

const CategoriesRevenueModal = React.forwardRef<
  HTMLDivElement,
  CategoriesRevenueModalProps
>((_, ref) => {
  // refs
  const categoriesRevenueRef = React.useRef<HTMLDivElement>(null);
  // dispatch
  const dispatch = useDispatch();
  // selectors
  const { indexSort } = useSelector(getSortByCategoriesSlice);
  // custom hook
  const { handleClick } = useHandleClick();

  React.useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      if (
        categoriesRevenueRef.current &&
        !categoriesRevenueRef.current.contains(e.target as Node) &&
        ref &&
        "current" in ref &&
        ref.current &&
        !ref.current.contains(e.target as Node)
      ) {
        dispatch(sortByCategoriesActions.closeRevenua());
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className={styles.root} ref={categoriesRevenueRef}>
      {dataItem.map((item, index) => {
        return (
          <CategoriesRevenueModalItem
            key={index}
            name={item.text}
            isActive={indexSort === index}
            onClick={() => handleClick(index, item.text, item.filter)}
          />
        );
      })}
    </div>
  );
});

export default CategoriesRevenueModal;
