import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AppResponseData } from "@/features/auth/types/auth";
import { appState } from "../types/appsTypes";

const initialState: appState = {
  apiDataApp: {
    count: 0,
    next: "",
    previous: "",
    results: [],
  },
  apiDataVideoUrl: "",
  statusPayment: "",
  transactionId: "",
  description: "",
  amountPlans: 0,
  line_items: [],
};

const appsSlice = createSlice({
  name: "apps",
  initialState,
  reducers: {
    setApps: (state, action: PayloadAction<AppResponseData>) => {
      state.apiDataApp = action.payload;
    },
    setVideoUrl: (state, action: PayloadAction<string>) => {
      state.apiDataVideoUrl = action.payload;
    },
    setStatusPayment: (state, action: PayloadAction<string>) => {
      state.statusPayment = action.payload;
    },
    setAmountPlans: (state, action: PayloadAction<number | null>) => {
      state.amountPlans = action.payload;
    },
    setLineItems: (state, action: PayloadAction<any>) => {
      state.line_items = action.payload;
    },
    setTransactionId: (state, action: PayloadAction<string>) => {
      state.transactionId = action.payload;
    },
    setDescription: (state, action: PayloadAction<string>) => {
      state.description = action.payload;
    },
  },
});

export const { actions: appsActions, reducer: appsReducer } = appsSlice;
