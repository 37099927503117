// react
import React from "react";
// styles
import styles from "./ModalSaveModeSortDesc.module.scss";

interface ModalSaveModeSortDescProps {
  sortDirection: string;
  handleSortDirectionChange: (direction: "asc" | "desc") => void;
  indexSortSaveMode: number;
}

const ModalSaveModeSortDesc: React.FC<ModalSaveModeSortDescProps> = ({
  sortDirection,
  handleSortDirectionChange,
  indexSortSaveMode,
}) => {
  return (
    <div className={styles.root}>
      <div
        onClick={
          sortDirection === "desc"
            ? () => {}
            : () => handleSortDirectionChange("desc")
        }
        className={`${styles.desc} ${
          sortDirection === "desc" ? styles.descActive : ""
        }`}
      >
        <p>{indexSortSaveMode === 2 ? "Descending" : "Descending"}</p>
      </div>
      <div
        onClick={
          sortDirection !== "desc"
            ? () => {}
            : () => handleSortDirectionChange("asc")
        }
        className={`${styles.asc} ${
          sortDirection === "desc" ? "" : styles.ascActive
        }`}
      >
        <p>{indexSortSaveMode === 2 ? "Ascending" : "Ascending"}</p>
      </div>
    </div>
  );
};

export default ModalSaveModeSortDesc;
