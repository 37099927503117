// shared
import { backendAPI } from "@/shared/api/backendAPI";
// types
import {
  AppCategories,
  AppPaywall,
  AppRequestDataFilter,
  AppRequestDataFilterAll,
  AppResponseData,
  AppResponseDataOne,
  IAppSavedGroup,
  IAppVideoData,
  IHiddenApp,
  ISavedApps,
  ISharedViews,
  LoginResponseData,
  RegisterRequestData,
  RegisterResponseData,
  RequestAppDataAvsTags,
  RequestCheckoutSession,
  RequestCreateSavedApps,
  RequestHiddenApps,
  RequestSavedGroups,
  RequestSavedGroupsPoints,
  RequestSavedPoint,
  RequestSavedStoreGroupsPoints,
  RequestSavedStorePoint,
  RequestSavedStoreScreens,
  RequestSharedGroups,
  RequestSharedViews,
  RequestVideoTimelineScreens,
  ResponseAvsTags,
  ResponseCheckoutSession,
  ResponseCheckoutStatus,
  ResponseCreateSavedApp,
  ResponseDataSaveMode,
  ResponseHiddenApps,
  ResponsePlans,
  ResponseSavedApps,
  ResponseSavedGroupDownloads,
  ResponseSavedGroupDownloadsCreate,
  ResponseSavedGroupPoints,
  ResponseSavedGroupStorePoints,
  ResponseSavedGroups,
  ResponseSavedPoint,
  ResponseSavedPointGet,
  ResponseSavedStoreScreens,
  ResponseSharedGroups,
  ResponseSharedItem,
  ResponseSharedItemSavedPoints,
  ResponseSharedItemStoreScreens,
  ResponseSharedViews,
  ResponseSharedViewsPoints,
  ResponseVideoTimelineScreens,
  UserResponseData,
  VideoLabelsResponseData,
  VideoResponseData,
  VideoStoreScreensRequestData,
  VideosScreensRequestData,
  VideosScreensRequestDataScreenTags,
  VideosScreensRequestDataScreenText,
  VideosScreensResponseData,
  VideosStoreScreensResponseData,
  UserUpdateRequestData,
  CreateInviteRequest,
  CreateInviteResponse,
  Organization,
  CreateOrganizationRequest,
  MembershipResponse,
  UpdateMembershipRequest,
  UpdateMembershipResponse,
  DeleteMembershipRequest,
  ListOrganizationUsersResponse,
  ListOrganizationUsersRequest,
  ListInvitesResponse,
  Invite,
  UpdateInviteRequest,
  ListInvitesRequest,
  AvsTag,
  ResetPasswordConfirmRequest,
  AvsTagsParams,
  PurchaseSeatsRequest,
  ChangePasswordRequest,
  SharedGroupResponse,
  ResponseAvf,
  SavedAnimation,
  SavedAnimationResponse,
  CreateSavedAnimationRequest,
  AppOnboardingVideosResponse,
  AppOnboardingVideosParams,
  AppOnboardingVideoScreen,
  AppOnboardingVideoScreensResponse,
  AppOnboardingVideoScreensParams,
  SavedObScreenRequest,
  SavedObScreen,
  SavedObScreensResponse,
  SavedGroupAnimationRequest,
  SavedGroupAnimation,
  SavedGroupAnimationsResponse,
  SavedGroupObScreenRequest,
  SavedGroupObScreen,
  SavedGroupObScreensResponse,
  FlowType,
  AppSuggestPayloadData,
} from "../types/auth";


export const authAPI = backendAPI.injectEndpoints({
  endpoints: (build) => ({
    loginUser: build.mutation<LoginResponseData, FormData>({
      query: (formData) => ({
        url: "/oauth2/token/",
        method: "POST",
        body: formData,
      }),

      transformResponse: (response: LoginResponseData) => {
        localStorage.setItem("access_token", response.access_token);
        return response;
      },
    }),
    registerUser: build.mutation<RegisterResponseData, RegisterRequestData>({
      query: (user) => ({
        url: "/v1/auth/register/",
        method: "POST",
        body: user,
      }),
    }),
    getUser: build.query<UserResponseData, null>({
      query: () => ({
        url: "/v1/me/",
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      }),

      transformResponse: (response: UserResponseData) => {
        try {
          if (response.email !== "" && response.username !== "") {
            return response;
          }
          localStorage.removeItem("access_token");
          return response;
        } catch (error) {
          localStorage.removeItem("access_token");
          return response;
        }
      },
    }),
    getApp: build.query<AppResponseData, number>({
      query: (id) => ({
        url: `/v1/apps/?page=${id}`,
        method: "GET",
      }),

      transformResponse: (response: AppResponseData) => {
        return response;
      },
    }),
    getAppOne: build.query<AppResponseDataOne, number>({
      query: (id) => ({
        url: `/v1/apps/${id}/`,
        method: "GET",
      }),

      transformResponse: (response: AppResponseDataOne) => {
        return response;
      },
    }),
    getAppSlugOne: build.query<AppResponseDataOne, string>({
      query: (slug) => ({
        url: `/v1/app/${slug}/`,
        method: "GET",
      }),

      transformResponse: (response: AppResponseDataOne) => {
        return response;
      },
    }),
    getAppFilterAll: build.query<AppResponseData, AppRequestDataFilterAll>({
      query: ({
        id,
        order,
        name,
        categories,
        paywall,
        oscgteNumber,
        osclteNumber,
        revenuegteNumber,
        revenuelteNumber,
        downloadgteNumber,
        downloadlteNumber,
        revInstallRateGteNumber,
        revInstallRateLteNumber,
        avsTags,
        updatedFrom,
        updatedTo,
        releasedFrom,
        releasedTo,
      }) => ({
        url: `/v1/apps/?page_size=10&page=${id}&name=${name}&order=${order}&category_name=${categories}&paywall_type=${paywall}&osc_gte=${oscgteNumber}&osc_lte=${osclteNumber}&revenue_gte=${revenuegteNumber}&revenue_lte=${revenuelteNumber}&download_gte=${downloadgteNumber}&download_lte=${downloadlteNumber}&rev_install_rate_gte=${revInstallRateGteNumber}&rev_install_rate_lte=${revInstallRateLteNumber}&avs_tags=${avsTags}&updated_from=${updatedFrom}&updated_to=${updatedTo}&released_from=${releasedFrom}&released_to=${releasedTo}`,
        method: "GET",
      }),

      transformResponse: (response: AppResponseData) => {
        return response;
      },
    }),

    getAppFilter: build.query<AppResponseData, AppRequestDataFilter>({
      query: ({
        id,
        order,
        name,
        categories,
        paywall,
        oscgteNumber,
        osclteNumber,
        revenuegteNumber,
        revenuelteNumber,
        downloadgteNumber,
        downloadlteNumber,
        revInstallRateGteNumber,
        revInstallRateLteNumber,
        avsTags,
        updatedFrom,
        updatedTo,
        releasedFrom,
        releasedTo,
        has_paywall,
        only,
      }) => {
        const params = new URLSearchParams();

        params.append('page_size', '7');
        params.append('page', id.toString());

        if (name) params.append('name', name);
        if (order) params.append('order', order);
        if (only) params.append('only', only);
        if (categories) params.append('category__in', categories);
        if (paywall) params.append('paywall_type', paywall);
        if (has_paywall) params.append('has_paywall', has_paywall);
        if (oscgteNumber) params.append('osc_gte', oscgteNumber);
        if (osclteNumber) params.append('osc_lte', osclteNumber);
        if (revenuegteNumber) params.append('revenue_gte', revenuegteNumber);
        if (revenuelteNumber) params.append('revenue_lte', revenuelteNumber);
        if (downloadgteNumber) params.append('download_gte', downloadgteNumber);
        if (downloadlteNumber) params.append('download_lte', downloadlteNumber);
        if (revInstallRateGteNumber) params.append('rev_install_rate_gte', revInstallRateGteNumber);
        if (revInstallRateLteNumber) params.append('rev_install_rate_lte', revInstallRateLteNumber);
        if (avsTags) params.append('avs_tags', avsTags);
        if (updatedFrom) params.append('updated_from', updatedFrom);
        if (updatedTo) params.append('updated_to', updatedTo);
        if (releasedFrom) params.append('released_from', releasedFrom);
        if (releasedTo) params.append('released_to', releasedTo);

        return {
          url: `/v1/apps/?${params.toString()}`,
          method: "GET",
        };
      },

      transformResponse: (response: AppResponseData) => {
        return response;
      },

      providesTags: (result) =>
        result
          ? [
            ...result.results.map(({ id }) => ({
              type: "DataApp" as const,
              id,
            })),
            { type: "DataApp", id: "LISTALL" },
          ]
          : [{ type: "DataApp", id: "LISTALL" }],
    }),
    getAppReplay: build.query<AppResponseData, AppRequestDataFilter>({
      query: ({
        id,
        order,
        name,
        categories,
        paywall,
        oscgteNumber,
        osclteNumber,
        revenuegteNumber,
        revenuelteNumber,
        downloadgteNumber,
        downloadlteNumber,
        revInstallRateGteNumber,
        revInstallRateLteNumber,
        avsTags,
        updatedFrom,
        updatedTo,
        releasedFrom,
        releasedTo,
        has_paywall,
        only,
      }) => {
        const params = new URLSearchParams();

        params.append('page_size', '7');
        params.append('page', id.toString());

        if (name) params.append('name', name);
        if (order) params.append('order', order);
        if (only) params.append('only', only);
        if (categories) params.append('category__in', categories);
        if (paywall) params.append('paywall_type', paywall);
        if (has_paywall) params.append('has_paywall', has_paywall);
        if (oscgteNumber) params.append('osc_gte', oscgteNumber);
        if (osclteNumber) params.append('osc_lte', osclteNumber);
        if (revenuegteNumber) params.append('revenue_gte', revenuegteNumber);
        if (revenuelteNumber) params.append('revenue_lte', revenuelteNumber);
        if (downloadgteNumber) params.append('download_gte', downloadgteNumber);
        if (downloadlteNumber) params.append('download_lte', downloadlteNumber);
        if (revInstallRateGteNumber) params.append('rev_install_rate_gte', revInstallRateGteNumber);
        if (revInstallRateLteNumber) params.append('rev_install_rate_lte', revInstallRateLteNumber);
        if (avsTags) params.append('avs_tags', avsTags);
        if (updatedFrom) params.append('updated_from', updatedFrom);
        if (updatedTo) params.append('updated_to', updatedTo);
        if (releasedFrom) params.append('released_from', releasedFrom);
        if (releasedTo) params.append('released_to', releasedTo);

        return {
          url: `/v1/app-replays/?${params.toString()}`,
          method: "GET",
        };
      },

      transformResponse: (response: AppResponseData) => {
        return response;
      },

      // providesTags: (result) =>
      //   result
      //     ? [
      //       ...result.results.map(({ id }) => ({
      //         type: "DataApp" as const,
      //         id,
      //       })),
      //       { type: "DataApp", id: "LISTALL" },
      //     ]
      //     : [{ type: "DataApp", id: "LISTALL" }],
    }),
    getAppStore: build.query<AppResponseData, AppRequestDataFilter>({
      query: ({
        id,
        order,
        name,
        categories,
        paywall,
        oscgteNumber,
        osclteNumber,
        revenuegteNumber,
        revenuelteNumber,
        downloadgteNumber,
        downloadlteNumber,
        revInstallRateGteNumber,
        revInstallRateLteNumber,
        avsTags,
        updatedFrom,
        updatedTo,
        releasedFrom,
        releasedTo,
        has_paywall,
        only,
      }) => {
        const params = new URLSearchParams();

        params.append('page_size', '7');
        params.append('page', id.toString());

        if (name) params.append('name', name);
        if (order) params.append('order', order);
        if (only) params.append('only', only);
        if (categories) params.append('category__in', categories);
        if (paywall) params.append('paywall_type', paywall);
        if (has_paywall) params.append('has_paywall', has_paywall);
        if (oscgteNumber) params.append('osc_gte', oscgteNumber);
        if (osclteNumber) params.append('osc_lte', osclteNumber);
        if (revenuegteNumber) params.append('revenue_gte', revenuegteNumber);
        if (revenuelteNumber) params.append('revenue_lte', revenuelteNumber);
        if (downloadgteNumber) params.append('download_gte', downloadgteNumber);
        if (downloadlteNumber) params.append('download_lte', downloadlteNumber);
        if (revInstallRateGteNumber) params.append('rev_install_rate_gte', revInstallRateGteNumber);
        if (revInstallRateLteNumber) params.append('rev_install_rate_lte', revInstallRateLteNumber);
        if (avsTags) params.append('avs_tags', avsTags);
        if (updatedFrom) params.append('updated_from', updatedFrom);
        if (updatedTo) params.append('updated_to', updatedTo);
        if (releasedFrom) params.append('released_from', releasedFrom);
        if (releasedTo) params.append('released_to', releasedTo);

        return {
          url: `/v1/app-stores/?${params.toString()}`,
          method: "GET",
        };
      },

      transformResponse: (response: AppResponseData) => {
        return response;
      },

      providesTags: (result) =>
        result
          ? [
            ...result.results.map(({ id }) => ({
              type: "DataApp" as const,
              id,
            })),
            { type: "DataApp", id: "LISTALL" },
          ]
          : [{ type: "DataApp", id: "LISTALL" }],
    }),
    getVideos: build.query<VideoResponseData, void | null>({
      query: () => ({
        url: "/v1/appvideos/?page_size=1000/",
        method: "GET",
      }),

      transformResponse: (response: VideoResponseData) => {
        return response;
      },
    }),
    getVideoOne: build.query<IAppVideoData, number>({
      query: (id) => ({
        url: `/v1/appvideos/${id}/`,
        method: "GET",
      }),

      transformResponse: (response: IAppVideoData) => {
        return response;
      },
    }),

    getVideoOneOB: build.query<AppOnboardingVideoScreen, number>({
      query: (id) => ({
        url: `/v1/appobvideos/${id}/`,
        method: "GET",
      }),

      transformResponse: (response: AppOnboardingVideoScreen) => {
        return response;
      },
    }),

    getVideosScreensById: build.query<VideosScreensResponseData, number>({
      query: (id) => ({
        url: `/v1/appvideoscreens/?page_size=200&app=${id}&order=timestamp`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      }),

      transformResponse: (response: VideosScreensResponseData) => {
        return response;
      },
    }),
    getVideoStoreScreens: build.query<VideosScreensResponseData, void>({
      query: () => ({
        url: "/v1/appstorescreens/",
        method: "GET",
      }),

      transformResponse: (response: VideosScreensResponseData) => {
        return response;
      },
    }),
    getVideoStoreScreensById: build.query<
      VideosStoreScreensResponseData,
      VideoStoreScreensRequestData
    >({
      query: ({ id, screenText }) => ({
        url: `/v1/appstorescreens/?app=${id}&page_size=1000&order=created_at&screen_text=${screenText}`,
        method: "GET",
      }),

      transformResponse: (response: VideosStoreScreensResponseData) => {
        return response;
      },
    }),
    getVideoStoreScroll: build.query<
      VideosScreensResponseData,
      VideosScreensRequestData
    >({
      query: ({ id, page }) => ({
        url: `/v1/appstorescreens/?page_size=10&app=${id}&page=${page}&order=created_at`,
        method: "GET",
      }),

      transformResponse: (response: VideosScreensResponseData) => {
        return response;
      },
    }),
    getVideoStoreScrollScreenText: build.query<
      VideosScreensResponseData,
      VideosScreensRequestDataScreenText
    >({
      query: ({ id, screenText }) => ({
        url: `/v1/appstorescreens/?app=${id}&page_size=1000&order=created_at&screen_text=${screenText}`,
        method: "GET",
      }),

      transformResponse: (response: VideosScreensResponseData) => {
        return response;
      },
    }),
    getVideoScreensScroll: build.query<
      VideosScreensResponseData,
      VideosScreensRequestData
    >({
      query: ({ id, page, pageSize }) => ({
        url: `/v1/appvideoscreens/?page_size=${pageSize}&app=${id}&page=${page}&order=timestamp`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      }),

      transformResponse: (response: VideosScreensResponseData) => {
        return response;
      },
    }),
    getVideoScreensScrollScreenText: build.query<
      VideosScreensResponseData,
      VideosScreensRequestDataScreenText
    >({
      query: ({ id, screenText }) => ({
        url: `/v1/appvideoscreens/?app=${id}&page_size=1000&order=timestamp&screen_text=${screenText}`,
        method: "GET",
      }),

      transformResponse: (response: VideosScreensResponseData) => {
        return response;
      },
    }),
    getVideoScreensScrollScreenTags: build.query<
      VideosScreensResponseData,
      VideosScreensRequestDataScreenTags
    >({
      query: ({ id, screenTags }) => ({
        url: `/v1/appvideoscreens/?app=${id}&page_size=1000&order=timestamp&screen_tags=${screenTags}`,
        method: "GET",
      }),

      transformResponse: (response: VideosScreensResponseData) => {
        return response;
      },
    }),
    getVideoLabels: build.query<VideoLabelsResponseData, number>({
      query: (id) => ({
        url: `/v1/appvideolabels/?app_video=${id}&order=time_from`,
        method: "GET",
      }),

      transformResponse: (response: VideoLabelsResponseData) => {
        return response;
      },
    }),
    getAppCategories: build.query<AppCategories, void>({
      query: () => ({
        url: "/v1/app-categories/?order=name",
        method: "GET",
      }),

      transformResponse: (response: AppCategories) => {
        return response;
      },
    }),
    getAppDevelopers: build.query<AppResponseData, string>({
      query: (developerName) => ({
        url: `/v1/apps/?developer_name=${developerName}`,
        method: "GET",
      }),

      transformResponse: (response: AppResponseData) => {
        return response;
      },
    }),
    getAppPaywall: build.query<AppPaywall[], void>({
      query: () => ({
        url: "/v1/paywall-types/",
        method: "GET",
      }),

      transformResponse: (response: AppPaywall[]) => {
        return response;
      },
    }),
    savedPoints: build.mutation<ResponseSavedPoint, RequestSavedPoint>({
      query: (data) => {
        const { organizationUuid, ...body } = data;
        return {
          url: `/v1/organizations/${organizationUuid}/saved-points/`,
          method: "POST",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
          body: body,
        }
      },

      transformResponse: (response: ResponseSavedPoint) => {
        return response;
      },

      invalidatesTags: [
        { type: "SavedPoints", id: "LIST" } as never,
        { type: "SavedPoints", id: "LISTITEM" } as never,
        { type: "SavedPoints", id: "LISTALL" } as never,
      ],
    }),
    getSavedPoints: build.query<ResponseSavedPointGet, number>({
      query: (page) => ({
        url: `/v1/saved-points/?order=-created_at&screen_ready=true&page_size=12&page=${page}`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      }),
      providesTags: (result) =>
        result
          ? [
            ...result.results.map(({ id }) => ({
              type: "SavedPoints" as const,
              id,
            })),
            { type: "SavedPoints", id: "LIST" },
          ]
          : [{ type: "SavedPoints", id: "LIST" }],
    }),
    getSavedPointsAll: build.query<ResponseSavedPointGet, {
      organizationUuid: string;
      app?: number;
      appVideo?: number;
      order?: string;
      page?: number;
      pageSize?: number;
      screenReady?: boolean;
      user?: number;
    }>({
      query: ({ organizationUuid, app, appVideo, order, page, pageSize, screenReady, user }) => {
        const params = new URLSearchParams();

        if (order) params.append('order', order);
        if (page) params.append('page', page.toString());
        if (pageSize) params.append('page_size', pageSize.toString());
        if (screenReady !== undefined) params.append('screen_ready', screenReady.toString());
        if (user) params.append('user', user.toString());
        if (app) params.append('app', app.toString());
        if (appVideo) params.append('app_video', appVideo.toString());

        return {
          url: `/v1/organizations/${organizationUuid}/saved-points/?${params.toString()}`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        };
      },
      providesTags: (result) =>
        result
          ? [
            ...result.results.map(({ id }) => ({
              type: "SavedPoints" as const,
              id,
            })),
            { type: "SavedPoints", id: "LISTALL" },
          ]
          : [{ type: "SavedPoints", id: "LISTALL" }],
    }),
    getVideoTimelineScreens: build.query<
      ResponseVideoTimelineScreens,
      RequestVideoTimelineScreens
    >({
      query: ({ appVideoId }) => ({
        url: `/v1/avtimelinescreen/?page_size=1000&order=ts&app_video=${appVideoId}`,
        method: "GET",
      }),

      transformResponse: (response: ResponseVideoTimelineScreens) => {
        return response;
      },
    }),
    getAppDataSaveMode: build.query<ResponseDataSaveMode, string>({
      query: (name) => ({
        url: `/v1/apps/?name=${name}`,
        method: "GET",
      }),

      transformResponse: (response: ResponseDataSaveMode) => {
        return response;
      },
    }),
    getAvsTags: build.query<ResponseAvsTags, object>({
      query: () => ({
        url: "/v1/avs-tags/",
        method: "GET",
      }),

      transformResponse: (response: ResponseAvsTags) => {
        return response;
      },
    }),
    getAppDataAvsTags: build.query<ResponseDataSaveMode, RequestAppDataAvsTags>(
      {
        query: ({ name }) => ({
          url: `/v1/apps/?avs_tags=${name}`,
          method: "GET",
        }),

        transformResponse: (response: ResponseDataSaveMode) => {
          return response;
        },
      },
    ),
    deletePoint: build.mutation<null, number | null>({
      query: (id) => ({
        url: `/v1/saved-points/${id}/`,
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      }),

      transformResponse: (response: null) => {
        return response;
      },
      invalidatesTags: [
        { type: "SavedPoints", id: "LIST" } as never,
        { type: "SavedPoints", id: "LISTITEM" } as never,
        { type: "SavedPoints", id: "LISTALL" } as never,
        { type: "SavedGroups", id: "LIST" } as never,
        { type: "SavedGroups", id: "LISTFILTER" } as never,
      ],
    }),
    getSavedPointByVideoId: build.query<ResponseSavedPointGet, number>({
      query: ({ organizationUuid, videoId }) => ({
        //url: `/v1/saved-points/?app_video=${videoId}`,
        url: `/v1/organizations/${organizationUuid}/saved-points/?app_video=${videoId}`,
        method: "GET",
      }),

      providesTags: (result) =>
        result
          ? [
            ...result.results.map(({ id }) => ({
              type: "SavedPoints" as const,
              id,
            })),
            { type: "SavedPoints", id: "LISTITEM" },
          ]
          : [{ type: "SavedPoints", id: "LISTITEM" }],
    }),
    getSavedStorePointsAll: build.query<ResponseSavedStoreScreens, {
      organizationUuid: string;
      params?: {
        page?: number;
        pageSize?: number;
        sort?: string;
        user?: number;
      }
    }>({
      query: ({ organizationUuid, params }) => {
        const queryParams = new URLSearchParams();
        if (params?.page) queryParams.append('page', params.page.toString());
        if (params?.pageSize) queryParams.append('page_size', params.pageSize.toString());
        if (params?.sort) queryParams.append('sort', params.sort);
        if (params?.user) queryParams.append('user', params.user.toString());

        return {
          url: `/v1/organizations/${organizationUuid}/saved-store-screens/?${queryParams.toString()}`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        };
      },
      providesTags: (result) =>
        result
          ? [
            ...result.results.map(({ id }) => ({
              type: "SavedStoreScreens" as const,
              id,
            })),
            { type: "SavedStoreScreens", id: "LIST" },
          ]
          : [{ type: "SavedStoreScreens", id: "LIST" }],
    }),
    savedStorePoints: build.mutation<
      ResponseSavedPoint,
      { organizationUuid: string; storeScreen: number }
    >({
      query: ({ organizationUuid, storeScreen }) => ({
        url: `/v1/organizations/${organizationUuid}/saved-store-screens/`,
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
        body: { store_screen: storeScreen },
      }),
      transformResponse: (response: ResponseSavedPoint) => {
        return response;
      },
      invalidatesTags: [{ type: "SavedStoreScreens", id: "LIST" } as never],
    }),
    getSharedViews: build.query<ResponseSharedViews, null>({
      query: () => ({
        url: "/v1/shared-views/?page_size=1000&order=created_at",
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      }),

      transformResponse: (response: ResponseSharedViews) => {
        return response;
      },

      providesTags: (result) =>
        result
          ? [
            ...result.results.map(({ id }) => ({
              type: "SharedViews" as const,
              id,
            })),
            { type: "SharedViews", id: "LIST" },
          ]
          : [{ type: "SharedViews", id: "LIST" }],
    }),
    sharedViews: build.mutation<ISharedViews, RequestSharedViews>({
      query: (data) => ({
        url: "/v1/shared-views/",
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
        body: data,
      }),

      transformResponse: (response: ISharedViews) => {
        return response;
      },
    }),
    getSharedViewsPoints: build.query<ResponseSharedViewsPoints, string>({
      query: (key) => ({
        url: `/v1/shared-saved-points/${key}/?page_size=1000&screen_ready=true`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      }),

      transformResponse: (response: ResponseSharedViewsPoints) => {
        return response;
      },

      providesTags: (result) =>
        result
          ? [
            ...result.results.map(({ id }) => ({
              type: "SharedViewsPoints" as const,
              id,
            })),
            { type: "SharedViewsPoints", id: "LIST" },
          ]
          : [{ type: "SharedViewsPoints", id: "LIST" }],
    }),
    getSavedGroups: build.query<ResponseSavedGroups, null>({
      query: () => ({
        url: "/v1/saved-groups/?page_size=1000&order=created_at",
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      }),

      transformResponse: (response: ResponseSavedGroups) => {
        return response;
      },

      providesTags: (result) =>
        result
          ? [
            ...result.results.map(({ id }) => ({
              type: "SavedGroups" as const,
              id,
            })),
            { type: "SavedGroups", id: "LIST" },
          ]
          : [{ type: "SavedGroups", id: "LIST" }],
    }),
    getSavedGroupsFilter: build.query<ResponseSavedGroups, string>({
      query: (name) => ({
        url: `/v1/saved-groups/?page_size=1000&order=created_at&name=${name}`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      }),

      transformResponse: (response: ResponseSavedGroups) => {
        return response;
      },

      providesTags: (result) =>
        result
          ? [
            ...result.results.map(({ id }) => ({
              type: "SavedGroups" as const,
              id,
            })),
            { type: "SavedGroups", id: "LISTFILTER" },
          ]
          : [{ type: "SavedGroups", id: "LISTFILTER" }],
    }),
    savedGroups: build.mutation<IAppSavedGroup, RequestSavedGroups>({
      query: (data) => {
        const { organizationUuid, ...body } = data;
        return {
          url: `/v1/organizations/${organizationUuid}/saved-groups/`,
          method: "POST",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
          body: body,
        }
      },

      transformResponse: (response: IAppSavedGroup) => {
        return response;
      },

      invalidatesTags: [
        { type: "SavedGroups", id: "LIST" } as never,
        { type: "SavedGroups", id: "LISTFILTER" } as never,
      ],
    }),
    getSavedStoreScreens: build.query<ResponseSavedStoreScreens, string>({
      query: (id) => ({
        url: `/v1/saved-store-screens/${id}`,
        method: "GET",
      }),

      transformResponse: (response: ResponseSavedStoreScreens) => {
        return response;
      },

      providesTags: (result) =>
        result
          ? [
            ...result.results.map(({ id }) => ({
              type: "SavedStoreScreens" as const,
              id,
            })),
            { type: "SavedStoreScreens", id: "LIST" },
          ]
          : [{ type: "SavedStoreScreens", id: "LIST" }],
    }),
    savedStoreScreensDelete: build.mutation<ResponseSavedStoreScreens, number>({
      query: (id) => ({
        url: `/v1/saved-store-screens/${id}/`,
        method: "DELETE",
      }),

      transformResponse: (response: ResponseSavedStoreScreens) => {
        return response;
      },

      invalidatesTags: [
        { type: "SavedStoreScreens", id: "LISTALL" } as never,
        { type: "SavedStoreScreens", id: "LIST" } as never,
      ],
    }),
    getSavedGroupPoints: build.query<ResponseSavedGroupPoints, number>({
      query: (groupId) => ({
        url: `/v1/saved-group-points/?saved_group=${groupId}`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      }),

      transformResponse: (response: ResponseSavedGroupPoints) => {
        return response;
      },

      providesTags: (result) =>
        result
          ? [
            ...result.results.map(({ id }) => ({
              type: "SavedStoreScreens" as const,
              id,
            })),
            { type: "SavedStoreScreens", id: "LIST" },
          ]
          : [{ type: "SavedStoreScreens", id: "LIST" }],
    }),
    savedGroupPoints: build.mutation<
      ResponseSavedStoreScreens,
      RequestSavedGroupsPoints
    >({
      query: (body) => ({
        url: "/v1/saved-group-points/",
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
        body,
      }),

      transformResponse: (response: ResponseSavedStoreScreens) => {
        return response;
      },

      invalidatesTags: [
        { type: "SavedGroups", id: "LIST" },
        { type: "SavedGroups", id: "LISTFILTER" },
        { type: "SavedGroupPoints", id: "LIST" },
        { type: "SavedPoints", id: "LISTALL" },
      ],
    }),
    deleteSavedGroupScreens: build.mutation<ResponseSavedStoreScreens, number>({
      query: (id) => ({
        url: `/v1/saved-group-points/${id}`,
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      }),

      invalidatesTags: [
        { type: "SavedStoreScreens", id: "LIST" } as never,
        { type: "SavedStoreScreens", id: "LISTALL" } as never,
        { type: "SavedGroups", id: "LIST" } as never,
        { type: "SavedGroups", id: "LISTFILTER" } as never,
      ],
    }),
    deleteSavedGroupPoints: build.mutation<null, number>({
      query: (id) => ({
        url: `/v1/saved-groups/${id}/`,
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      }),

      invalidatesTags: [
        { type: "SavedGroups", id: "LIST" } as never,
        { type: "SavedGroups", id: "LISTFILTER" } as never,
      ],
    }),
    savedGroupStorePoints: build.mutation<
      ResponseSavedStoreScreens,
      RequestSavedStoreGroupsPoints
    >({
      query: (body) => ({
        url: "/v1/saved-group-store-screens/",
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
        body,
      }),

      transformResponse: (response: ResponseSavedStoreScreens) => {
        return response;
      },

      invalidatesTags: [
        { type: "SavedGroups", id: "LIST" },
        { type: "SavedGroups", id: "LISTFILTER" },
        { type: "SavedGroupStorePoints", id: "LIST" },
        { type: "SavedStoreScreens", id: "LISTALL" },
      ],
    }),
    deleteSavedGroupStorePoints: build.mutation<
      ResponseSavedStoreScreens,
      number
    >({
      query: (id) => ({
        url: `/v1/saved-group-store-screens/${id}`,
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      }),

      invalidatesTags: [
        { type: "SavedPoints", id: "LIST" } as never,
        { type: "SavedPoints", id: "LISTFILTER" } as never,
        { type: "SavedPoints", id: "LISTALL" } as never,
        { type: "SavedStoreScreens", id: "LIST" } as never,
        { type: "SavedStoreScreens", id: "LISTALL" } as never,
        { type: "SavedGroups", id: "LIST" } as never,
        { type: "SavedGroups", id: "LISTFILTER" } as never,
      ],
    }),

    // TODO: add skip
    getSavedGroupStorePoints: build.query<
      ResponseSavedGroupStorePoints,
      number
    >({
      query: (groupId) => ({
        url: `/v1/saved-group-store-screens/?saved_group=${groupId}`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      }),

      transformResponse: (response: ResponseSavedGroupStorePoints) => {
        return response;
      },

      providesTags: (result) =>
        result
          ? [
            ...result.results.map(({ id }) => ({
              type: "SavedStoreScreens" as const,
              id,
            })),
            { type: "SavedStoreScreens", id: "LIST" },
          ]
          : [{ type: "SavedStoreScreens", id: "LIST" }],
    }),
    getPlans: build.query<ResponsePlans[], void>({
      query: () => ({
        url: "/v1/plans/",
        method: "GET",
      }),

      transformResponse: (response: ResponsePlans[]) => {
        return response;
      },
    }),
    checkoutSession: build.mutation<
      ResponseCheckoutSession,
      RequestCheckoutSession
    >({
      query: ({ priceId, uuid, qte, referralId, referralCouponId }) => ({
        url: `/v1/organizations/${uuid}/checkouts/`,
        method: "POST",
        body: {
          price_id: priceId,
          qte: qte,
          success_url: import.meta.env.VITE_SD_CHECKOUT_SUCCESS_URL,
          referral_id: referralId ? referralId : undefined,
          referral_coupon: referralCouponId ? referralCouponId : undefined
        },
      }),

      transformResponse: (response: ResponseCheckoutSession) => {
        return response;
      },
    }),
    getCheckoutStatus: build.query<ResponseCheckoutStatus, string>({
      query: (id) => ({
        url: `/v1/checkouts/${id}/`,
        method: "GET",
      }),

      transformResponse: (response: ResponseCheckoutStatus) => {
        return response;
      },
    }),
    billingWebHook: build.mutation<void, void>({
      query: () => ({
        url: "/v1/billing-webhook/",
        method: "POST",
      }),

      transformResponse: (response: void) => {
        return response;
      },
    }),
    savedGroupDownload: build.mutation<
      ResponseSavedGroupDownloadsCreate,
      number
    >({
      query: (savedGroupId) => ({
        url: "/v1/saved-group-download/",
        method: "POST",
        body: {
          saved_group: savedGroupId,
        },
      }),

      transformResponse: (response: ResponseSavedGroupDownloadsCreate) => {
        return response;
      },
    }),
    getSavedGroupDownload: build.query<ResponseSavedGroupDownloads, number>({
      query: (id) => ({
        url: `/v1/saved-group-download/${id}/`,
        method: "GET",
      }),

      transformResponse: (response: ResponseSavedGroupDownloads) => {
        return response;
      },
    }),
    sharedGroups: build.mutation<ResponseSharedGroups, RequestSharedGroups>({
      query: (body) => ({
        url: "/v1/shared-groups/",
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
        body,
      }),

      transformResponse: (response: ResponseSharedGroups) => {
        return response;
      },
    }),
    getSharedGroupsById: build.query<void, number>({
      query: (id) => ({
        url: `/v1/shared-groups/${id}`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      }),

      transformResponse: (response: void) => {
        return response;
      },
    }),
    getSharedItem: build.query<ResponseSharedItem, string>({
      query: (key) => ({
        url: `/v1/shared/${key}/`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      }),

      transformResponse: (response: ResponseSharedItem) => {
        return response;
      },
    }),
    getSharedItemSavedPoints: build.query<
      ResponseSharedItemSavedPoints,
      string
    >({
      query: (key) => ({
        url: `/v1/shared/${key}/saved-points/`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      }),

      transformResponse: (response: ResponseSharedItemSavedPoints) => {
        return response;
      },
    }),
    getSharedItemStoreScreens: build.query<
      ResponseSharedItemStoreScreens,
      string
    >({
      query: (key) => ({
        url: `/v1/shared/${key}/store-screens/`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      }),

      transformResponse: (response) => {
        return response;
      },
    }),
    getSavedApps: build.query<ResponseSavedApps, string>({
      query: (order) => ({
        url: `/v1/saved-apps/?order=${order}`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      }),
      transformResponse: (response: ResponseSavedApps) => {
        return response;
      },

      providesTags: (result) =>
        result
          ? [
            ...result.results.map(({ id }) => ({
              type: "SavedApps" as const,
              id,
            })),
            { type: "SavedApps", id: "LIST" },
          ]
          : [{ type: "SavedApps", id: "LIST" }],
    }),
    getSavedApp: build.query<ISavedApps, number>({
      query: (id) => ({
        url: `/v1/saved-apps/${id}`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },

        transformResponse: (response: ISavedApps) => {
          return response;
        },
      }),
    }),
    savedApps: build.mutation<ResponseCreateSavedApp, RequestCreateSavedApps>({
      query: (body) => ({
        url: "/v1/saved-apps/",
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
        body,
      }),

      transformResponse: (response: ResponseCreateSavedApp) => {
        return response;
      },

      invalidatesTags: [{ type: "SavedApps", id: "LIST" } as never],
    }),
    deleteSavedApps: build.mutation<null, number>({
      query: (id) => ({
        url: `/v1/saved-apps/${id}/`,
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      }),

      invalidatesTags: [{ type: "SavedApps", id: "LIST" } as never],
    }),
    getHiddenApps: build.query<ResponseHiddenApps, string>({
      query: (order) => ({
        url: `/v1/hidden-apps/?order=${order}`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      }),
      transformResponse: (response: ResponseHiddenApps) => {
        return response;
      },

      providesTags: (result) =>
        result
          ? [
            ...result.results.map(({ id }) => ({
              type: "DataHidden" as const,
              id,
            })),
            { type: "DataHidden", id: "LISTALL" },
          ]
          : [{ type: "DataHidden", id: "LISTALL" }],
    }),
    hiddenApps: build.mutation<IHiddenApp, RequestHiddenApps>({
      query: (body) => ({
        url: "/v1/hidden-apps/",
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
        body,
      }),
      transformResponse: (response: IHiddenApp) => {
        return response;
      },

      invalidatesTags: [
        { type: "DataApp", id: "LISTALL" } as never,
        { type: "DataHidden", id: "LISTALL" } as never,
      ],
    }),
    deleteHiddenApps: build.mutation<void, number>({
      query: (id) => ({
        url: `/v1/hidden-apps/${id}/`,
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      }),
      invalidatesTags: [
        { type: "DataApp", id: "LISTALL" } as never,
        { type: "DataHidden", id: "LISTALL" } as never,
      ],
    }),
    updateUser: build.mutation<UserResponseData, UserUpdateRequestData>({
      query: (userData) => ({
        url: "/v1/me/",
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
        body: userData,
      }),
      invalidatesTags: [{ type: "User", id: "ME" }],
    }),
    createInvite: build.mutation<CreateInviteResponse, CreateInviteRequest>({
      query: ({ organizationUuid, email, role }) => ({
        url: `/v1/organizations/${organizationUuid}/invites/`,
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          "Content-Type": "application/json",
        },
        body: { email, role },
      }),
      invalidatesTags: [{ type: "TeamMembers", id: "LIST" }],
    }),
    getOrganization: build.query<Organization, number>({
      query: (uuid) => ({
        url: `/v1/organizations/${uuid}/`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      }),
      providesTags: (result, error, uuid) => [{ type: "Organization", uuid }],
    }),
    getOrganizationsList: build.query<Organization[], void>({
      query: () => ({
        url: `/v1/organizations/`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      }),
      providesTags: [{ type: 'Organization', id: 'LIST' }],
    }),
    createOrganization: build.mutation<Organization, CreateOrganizationRequest>({
      query: (organizationData) => ({
        url: '/v1/organizations/',
        method: 'POST',
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          'Content-Type': 'application/json',
        },
        body: organizationData,
      }),
      invalidatesTags: [{ type: 'Organization', id: 'LIST' }],
    }),
    getMembership: build.query<MembershipResponse, number>({
      query: (id) => ({
        url: `/v1/membership/${id}/`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      }),
      providesTags: (result, error, id) => [{ type: "Membership", id }],
    }),
    updateMembershipRole: build.mutation<UpdateMembershipResponse, UpdateMembershipRequest>({
      query: ({ id, role, name }) => ({
        url: `/v1/membership/${id}/`,
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          "Content-Type": "application/json",
        },
        body: { role, name },
      }),
      invalidatesTags: (result, error, { id }) => [{ type: "Membership", id }],
    }),
    deleteMembership: build.mutation<void, DeleteMembershipRequest>({
      query: ({ id }) => ({
        url: `/v1/membership/${id}/`,
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      }),
      invalidatesTags: (result, error, { id }) => [{ type: "Membership", id }],
    }),
    listOrganizationUsers: build.query<ListOrganizationUsersResponse, ListOrganizationUsersRequest>({
      query: ({ uuid, order, page, page_size, role }) => ({
        url: `/v1/organizations/${uuid}/users/`,
        method: "GET",
        params: {
          order,
          page,
          page_size,
          role,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      }),
      providesTags: (result) =>
        result
          ? [
            ...result.results.map(({ id }) => ({ type: "OrganizationUsers" as const, id })),
            { type: "OrganizationUsers", id: "LIST" },
          ]
          : [{ type: "OrganizationUsers", id: "LIST" }],
    }),
    leaveOrganization: build.mutation<void, number>({
      query: (organizationUuid) => ({
        url: `/v1/organizations/${organizationUuid}/leave/`,
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      }),
      invalidatesTags: [
        { type: "Organization", id: "LIST" },
        { type: "Membership", id: "LIST" },
      ],
    }),
    switchOrganization: build.mutation<void, number>({
      query: (organizationUuid) => ({
        url: `/v1/organizations/${organizationUuid}/switch/`,
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      }),
      invalidatesTags: [
        { type: "Organization", id: "CURRENT" },
        { type: "User", id: "ME" },
      ],
    }),
    updateOrganization: build.mutation<Organization, { uuid: string; data: FormData | { name: string } }>({
      query: ({ uuid, data }) => ({
        url: `/v1/organizations/${uuid}/`,
        method: 'PATCH',
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          ...(!(data instanceof FormData) && {
            'Content-Type': 'application/json'
          })
        },
        body: data,
      }),
      invalidatesTags: (result, error, { uuid }) => [
        { type: 'Organization', uuid },
        { type: 'Organization', id: 'LIST' }
      ],
    }),
    getOrganizationUsers: build.query<ListOrganizationUsersResponse, string>({
      query: (organizationUuid) => ({
        url: `/v1/organizations/${organizationUuid}/users/`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      }),
      providesTags: (result) =>
        result
          ? [
            ...result.results.map(({ id }) => ({ type: "OrganizationUsers" as const, id })),
            { type: "OrganizationUsers", id: "LIST" },
          ]
          : [{ type: "OrganizationUsers", id: "LIST" }],
    }),
    fetchSavedGroups: build.mutation<ResponseSavedGroups, number>({
      query: (organizationUuid) => ({
        url: `/v1/organizations/${organizationUuid}/saved-groups/`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      }),
      transformResponse: (response: ResponseSavedGroups) => {
        return response;
      },
      invalidatesTags: [{ type: "SavedGroups", id: "LIST" }],
    }),
    getSavedGroup: build.query<IAppSavedGroup, number>({
      query: (id) => ({
        url: `/v1/saved-groups/${id}/`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      }),
      transformResponse: (response: IAppSavedGroup) => {
        return response;
      },
      providesTags: (result, error, id) => [{ type: "SavedGroup", id }],
    }),

    getOrganizationInvites: build.query<ListInvitesResponse, ListInvitesRequest>({
      query: ({ uuid, ...params }) => ({
        url: `/v1/organizations/${uuid}/invites/`,
        method: "GET",
        params: {
          accepted__is: params.accepted__is,
          order: params.order,
          page: params.page,
          page_size: params.page_size,
          role: params.role,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      }),
      providesTags: (result) =>
        result
          ? [
            ...result.results.map(({ id }) => ({ type: "Invites" as const, id })),
            { type: "Invites", id: "LIST" },
          ]
          : [{ type: "Invites", id: "LIST" }],
    }),

    deleteInvite: build.mutation<void, number>({
      query: (inviteId) => ({
        url: `/v1/invites/${inviteId}/`,
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      }),
      invalidatesTags: (result, error, id) => [
        { type: "Invites", id },
        { type: "Invites", id: "LIST" },
      ],
    }),

    updateInvite: build.mutation<Invite, { id: number; data: UpdateInviteRequest }>({
      query: ({ id, data }) => ({
        url: `/v1/invites/${id}/`,
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          "Content-Type": "application/json",
        },
        body: data,
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: "Invites", id },
        { type: "Invites", id: "LIST" },
      ],
    }),

    resendEmailVerification: build.mutation<void, string>({
      query: (email) => ({
        url: "/v1/auth/email-verify-resend/",
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
        body: { email }
      }),
    }),

    getAvsTagsNotLogin: build.query<AvsTag[], void>({
      query: () => ({
        url: "/v1/tags/wl/",
        method: "GET",
      }),
      transformResponse: (response: AvsTag[]) => response,
    }),

    resetPasswordConfirm: build.mutation<void, ResetPasswordConfirmRequest>({
      query: (data) => ({
        url: "/v1/auth/reset-confirm/",
        method: "POST",
        body: data,
      }),
    }),

    getAvsTagsList: build.query<AvsTag[], AvsTagsParams>({
      query: (params) => {
        const searchParams = new URLSearchParams();

        // Add parameters to URL only if they are defined
        if (params.q) searchParams.append('q', params.q);
        if (params.mo !== undefined) searchParams.append('mo', params.mo.toString());

        return {
          url: `/v1/tags/avs/${searchParams.toString() ? `?${searchParams.toString()}` : ''}`,
          method: "GET",
        };
      },
      transformResponse: (response: AvsTag[]) => response,
    }),

    updateSavedGroup: build.mutation<IAppSavedGroup, { id: number; name: string }>({
      query: ({ id, name }) => ({
        url: `/v1/saved-groups/${id}/`,
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
        body: { name },
      }),

      transformResponse: (response: IAppSavedGroup) => {
        return response;
      },

      invalidatesTags: [
        { type: "SavedGroups", id: "LIST" },
        { type: "SavedGroups", id: "LISTFILTER" },
        { type: "SavedGroup", id: "LIST" },
      ],
    }),
    purchaseSeats: build.mutation<void, PurchaseSeatsRequest>({
      query: ({ organizationUuid, qte }) => ({
        url: `/v1/organizations/${organizationUuid}/seats/`,
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ qte }),
      }),
    }),
    previewSeats: build.query<{ total: number }, { organizationUuid: string, qte: number }>({
      query: ({ organizationUuid, qte }) => ({
        url: `/v1/organizations/${organizationUuid}/preview/`,
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ qte }),
      }),
    }),
    changePassword: build.mutation<void, ChangePasswordRequest>({
      query: (data) => ({
        url: "/v1/auth/change-password/",
        method: "PUT",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
        body: data,
      }),
    }),
    getSharedGroup: build.query<SharedGroupResponse, string>({
      query: (key) => ({
        url: `/v1/shared/${key}/`,
        method: "GET",
      }),
      transformResponse: (response: SharedGroupResponse) => {
        return response;
      },
    }),
    getAppVideoFlows: build.query<ResponseAvf, number>({
      query: (appVideoId) => ({
        url: `/v1/avf/?app_video=${appVideoId}`,
        method: "GET",
      }),
      transformResponse: (response: ResponseAvf) => {
        return response;
      },
    }),
    getAppVideoScreensPaginated: build.query<VideosScreensResponseData, { appId: number; pageSize: number; page: number }>({
      query: ({ appId, pageSize, page, featured_only }) => ({
        url: `/v1/appvideoscreens/`,
        method: "GET",
        params: {
          app: appId,
          page_size: pageSize,
          order: 'timestamp',
          page: page,
          featured_only: featured_only ? featured_only : undefined
        }
      }),
      transformResponse: (response: VideosScreensResponseData) => {
        return response;
      },
    }),
    createSavedAnimation: build.mutation<SavedAnimation, { organizationUuid: string; data: CreateSavedAnimationRequest }>({
      query: ({ organizationUuid, data }) => ({
        url: `/v1/organizations/${organizationUuid}/saved-animations/`,
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          "Content-Type": "application/json",
        },
        body: data,
      }),
      invalidatesTags: [{ type: "SavedAnimations", id: "LIST" }],
    }),
    deleteSavedAnimation: build.mutation<void, number>({
      query: (id) => ({
        url: `/v1/saved-animations/${id}/`,
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      }),
      invalidatesTags: [{ type: "SavedAnimations", id: "LIST" }],
    }),
    getSavedAnimations: build.query<SavedAnimationResponse, {
      organizationUuid: string,
      params?: {
        page?: number;
        pageSize?: number;
        sort?: string;
        user?: number;
      }
    }>({
      query: ({ organizationUuid, params }) => {
        const queryParams = new URLSearchParams();
        if (params?.page) queryParams.append('page', params.page.toString());
        if (params?.pageSize) queryParams.append('page_size', params.pageSize.toString());
        if (params?.sort) queryParams.append('sort', params.sort);
        if (params?.user) queryParams.append('user', params.user.toString());

        return {
          url: `/v1/organizations/${organizationUuid}/saved-animations/?${queryParams.toString()}`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        };
      },
      providesTags: (result) =>
        result
          ? [
            ...result.results.map(({ id }) => ({
              type: "SavedAnimations" as const,
              id,
            })),
            { type: "SavedAnimations", id: "LIST" },
          ]
          : [{ type: "SavedAnimations", id: "LIST" }],
    }),

    getAppOnboardingVideos: build.query<AppOnboardingVideosResponse, AppOnboardingVideosParams>({
      query: ({
        page = 1,
        page_size = 10,
        order,
        name,
        categories,
        paywall,
        oscgteNumber,
        osclteNumber,
        revenuegteNumber,
        revenuelteNumber,
        downloadgteNumber,
        downloadlteNumber,
        revInstallRateGteNumber,
        revInstallRateLteNumber,
        avsTags,
        updatedFrom,
        updatedTo,
        releasedFrom,
        releasedTo,
        has_paywall,
        only,
        flow_type
      } = {}) => {
        const params = new URLSearchParams();

        params.append('page_size', page_size.toString());
        params.append('page', page.toString());

        if (name) params.append('name', name);
        if (order) params.append('order', order);
        if (only) params.append('only', only);
        if (categories) params.append('category__in', categories);
        if (paywall) params.append('paywall_type', paywall);
        if (has_paywall) params.append('has_paywall', has_paywall);
        if (oscgteNumber) params.append('osc_gte', oscgteNumber);
        if (osclteNumber) params.append('osc_lte', osclteNumber);
        if (revenuegteNumber) params.append('revenue_gte', revenuegteNumber);
        if (revenuelteNumber) params.append('revenue_lte', revenuelteNumber);
        if (downloadgteNumber) params.append('download_gte', downloadgteNumber);
        if (downloadlteNumber) params.append('download_lte', downloadlteNumber);
        if (revInstallRateGteNumber) params.append('rev_install_rate_gte', revInstallRateGteNumber);
        if (revInstallRateLteNumber) params.append('rev_install_rate_lte', revInstallRateLteNumber);
        if (avsTags) params.append('avs_tags', avsTags);
        if (updatedFrom) params.append('updated_from', updatedFrom);
        if (updatedTo) params.append('updated_to', updatedTo);
        if (releasedFrom) params.append('released_from', releasedFrom);
        if (releasedTo) params.append('released_to', releasedTo);
        if (flow_type) params.append('flow_type', flow_type);

        return {
          url: `/v1/appobvideos/?${params.toString()}`,
          method: "GET",
        };
      },

      transformResponse: (response: AppOnboardingVideosResponse) => {
        return response;
      },

      providesTags: (result) =>
        result
          ? [
            ...result.results.map(({ id }) => ({
              type: "AppOnboardingVideos" as const,
              id,
            })),
            { type: "AppOnboardingVideos", id: "LIST" },
          ]
          : [{ type: "AppOnboardingVideos", id: "LIST" }],
    }),
    getAppOnboardingVideoScreens: build.query<AppOnboardingVideoScreensResponse, AppOnboardingVideoScreensParams>({
      query: ({ page = 1, page_size = 10, app, app_ob_video } = {}) => {
        const params = new URLSearchParams();

        if (page) params.append('page', page.toString());
        if (page_size) params.append('page_size', page_size.toString());
        if (app) params.append('app', app.toString());
        if (app_ob_video) params.append('app_ob_video', app_ob_video.toString());

        return {
          url: `/v1/appobvideoscreens/?${params.toString()}`,
          method: "GET",
        };
      },

      transformResponse: (response: AppOnboardingVideoScreensResponse) => {
        return response;
      },

      providesTags: (result) =>
        result
          ? [
            ...result.results.map(({ id }) => ({
              type: "AppOnboardingVideoScreens" as const,
              id,
            })),
            { type: "AppOnboardingVideoScreens", id: "LIST" },
          ]
          : [{ type: "AppOnboardingVideoScreens", id: "LIST" }],
    }),
    createSavedObScreen: build.mutation<SavedObScreen, { organizationUuid: string; data: SavedObScreenRequest }>({
      query: ({ organizationUuid, data }) => ({
        url: `/v1/organizations/${organizationUuid}/saved-ob-screens/`,
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
        body: data,
      }),
      invalidatesTags: [{ type: "SavedObScreens", id: "LIST" }],
    }),
    getSavedObScreens: build.query<SavedObScreensResponse, {
      organizationUuid: string;
      params?: {
        page?: number;
        pageSize?: number;
        sort?: string;
        user?: number;
      }
    }>({
      query: ({ organizationUuid, params }) => {
        const queryParams = new URLSearchParams();
        if (params?.page) queryParams.append('page', params.page.toString());
        if (params?.pageSize) queryParams.append('page_size', params.pageSize.toString());
        if (params?.sort) queryParams.append('sort', params.sort);
        if (params?.user) queryParams.append('user', params.user.toString());

        return {
          url: `/v1/organizations/${organizationUuid}/saved-ob-screens/?${queryParams.toString()}`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        };
      },
      providesTags: (result) =>
        result
          ? [
            ...result.results.map(({ id }) => ({
              type: "SavedObScreens" as const,
              id,
            })),
            { type: "SavedObScreens", id: "LIST" },
          ]
          : [{ type: "SavedObScreens", id: "LIST" }],
    }),
    deleteSavedObScreen: build.mutation<void, number>({
      query: (id) => ({
        url: `/v1/saved-ob-screens/${id}/`,
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      }),
      invalidatesTags: [{ type: "SavedObScreens", id: "LIST" }],
    }),
    createSavedGroupAnimation: build.mutation<SavedGroupAnimation, SavedGroupAnimationRequest>({
      query: (data) => ({
        url: '/v1/saved-group-animations/',
        method: 'POST',
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
        body: data,
      }),
      invalidatesTags: [
        { type: 'SavedGroupAnimations', id: 'LIST' },
        { type: 'SavedGroups', id: 'LIST' },
      ],
    }),

    getSavedGroupAnimation: build.query<SavedGroupAnimation, number>({
      query: (id) => ({
        url: `/v1/saved-group-animations/${id}/`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      }),
      providesTags: (result, error, id) => [{ type: 'SavedGroupAnimations', id }],
    }),

    deleteSavedGroupAnimation: build.mutation<void, number>({
      query: (id) => ({
        url: `/v1/saved-group-animations/${id}/`,
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      }),
      invalidatesTags: [
        { type: 'SavedGroupAnimations', id: 'LIST' },
        { type: 'SavedGroups', id: 'LIST' },
      ],
    }),

    createSavedGroupObScreen: build.mutation<SavedGroupObScreen, SavedGroupObScreenRequest>({
      query: (data) => ({
        url: '/v1/saved-group-ob-screens/',
        method: 'POST',
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
        body: data,
      }),
      invalidatesTags: [
        { type: 'SavedGroupObScreens', id: 'LIST' },
        { type: 'SavedGroups', id: 'LIST' },
      ],
    }),

    getSavedGroupObScreen: build.query<SavedGroupObScreen, number>({
      query: (id) => ({
        url: `/v1/saved-group-ob-screens/${id}/`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      }),
      providesTags: (result, error, id) => [{ type: 'SavedGroupObScreens', id }],
    }),

    deleteSavedGroupObScreen: build.mutation<void, number>({
      query: (id) => ({
        url: `/v1/saved-group-ob-screens/${id}/`,
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      }),
      invalidatesTags: [
        { type: 'SavedGroupObScreens', id: 'LIST' },
        { type: 'SavedGroups', id: 'LIST' },
      ],
    }),

    getSavedGroupAnimations: build.query<SavedGroupAnimationsResponse, number>({
      query: (groupId) => ({
        url: `/v1/saved-group-animations/?saved_group=${groupId}`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      }),

      transformResponse: (response: SavedGroupAnimationsResponse) => {
        return response;
      },

      providesTags: (result) =>
        result
          ? [
            ...result.results.map(({ id }) => ({
              type: "SavedGroupAnimations" as const,
              id,
            })),
            { type: "SavedGroupAnimations", id: "LIST" },
          ]
          : [{ type: "SavedGroupAnimations", id: "LIST" }],
    }),

    getSavedGroupObScreens: build.query<SavedGroupObScreensResponse, number>({
      query: (groupId) => ({
        url: `/v1/saved-group-ob-screens/?saved_group=${groupId}`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      }),

      transformResponse: (response: SavedGroupObScreensResponse) => {
        return response;
      },

      providesTags: (result) =>
        result
          ? [
            ...result.results.map(({ id }) => ({
              type: "SavedGroupObScreens" as const,
              id,
            })),
            { type: "SavedGroupObScreens", id: "LIST" },
          ]
          : [{ type: "SavedGroupObScreens", id: "LIST" }],
    }),
    getFlowTypes: build.query<FlowType[], void>({
      query: () => ({
        url: "/v1/flow-types/",
        method: "GET",
      }),

      transformResponse: (response: FlowType[]) => {
        return response;
      },
    }),
    searchApps: build.query<AppResponseData, { q: string;[key: string]: any }>({
      query: ({ q, ...otherParams }) => {
        const params = new URLSearchParams();
        params.append('q', q)
        //params.append('page_size', '999')

        Object.entries(otherParams).forEach(([key, value]) => {
          if (value !== undefined && value !== null) {
            params.append(key, value.toString());
          }
        });

        return {
          url: `/v1/app-search/?${params.toString()}`,
          method: "GET",
        };
      },

      transformResponse: (response: AppResponseData) => {
        return response;
      },
    }),
    searchAnything: build.query<AppResponseData, { q: string;[key: string]: any }>({
      query: ({ q, s, limit, offset, ...otherParams }) => {
        const params = new URLSearchParams();
        params.append('q', q)
        if (s) {
          params.append('s', s)
        }
        if (limit !== undefined) {
          params.append('limit', limit.toString())
        }
        if (offset !== undefined) {
          params.append('offset', offset.toString())
        }

        Object.entries(otherParams).forEach(([key, value]) => {
          if (value !== undefined && value !== null) {
            params.append(key, value.toString());
          }
        });

        return {
          url: `/v1/anything-search/?${params.toString()}`,
          method: "GET",
        };
      },

      transformResponse: (response: AppResponseData) => {
        return response;
      },
    }),
    suggestApp: build.mutation<void, AppSuggestPayloadData>({
      query: (data) => ({
        url: "/v1/app-requests/",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
        body: data,
      }),
    }),
  }),
});

export const {
  useLoginUserMutation,
  useRegisterUserMutation,
  useGetUserQuery,
  useGetAppQuery,
  useGetVideosScreensByIdQuery,
  useGetVideosQuery,
  useGetVideoOneQuery,
  useGetVideoOneOBQuery,
  useGetVideoScreensScrollQuery,
  useGetVideoStoreScreensQuery,
  useGetAppFilterQuery,
  useGetAppReplayQuery,
  useGetAppStoreQuery,
  useGetAppOneQuery,
  useGetAppSlugOneQuery,
  useGetVideoLabelsQuery,
  useGetAppCategoriesQuery,
  useGetAppFilterAllQuery,
  useGetAppDevelopersQuery,
  useGetVideoStoreScreensByIdQuery,
  useGetVideoStoreScrollQuery,
  useGetAppPaywallQuery,
  useSavedPointsMutation,
  useGetSavedPointsQuery,
  useGetVideoTimelineScreensQuery,
  useGetAppDataSaveModeQuery,
  useGetVideoScreensScrollScreenTextQuery,
  useGetVideoStoreScrollScreenTextQuery,
  useGetVideoScreensScrollScreenTagsQuery,
  useGetAvsTagsQuery,
  useGetAppDataAvsTagsQuery,
  useDeletePointMutation,
  useGetSavedPointByVideoIdQuery,
  useGetSavedPointsAllQuery,
  useGetSavedStorePointsAllQuery,
  useSavedStorePointsMutation,
  useGetSharedViewsQuery,
  useSharedViewsMutation,
  useGetSavedGroupsQuery,
  useGetSharedViewsPointsQuery,
  useSavedGroupsMutation,
  useGetSavedGroupsFilterQuery,
  useGetSavedStoreScreensQuery,
  useSavedStoreScreensDeleteMutation,
  useSavedGroupPointsMutation,
  useGetSavedGroupPointsQuery,
  useSavedGroupStorePointsMutation,
  useGetSavedGroupStorePointsQuery,
  useGetPlansQuery,
  useBillingWebHookMutation,
  useCheckoutSessionMutation,
  useGetCheckoutStatusQuery,
  useDeleteSavedGroupPointsMutation,
  useDeleteSavedGroupScreensMutation,
  useDeleteSavedGroupStorePointsMutation,
  useSavedGroupDownloadMutation,
  useGetSavedGroupDownloadQuery,
  useSharedGroupsMutation,
  useGetSharedGroupsByIdQuery,
  useGetSharedItemQuery,
  useGetSharedItemSavedPointsQuery,
  useGetSharedItemStoreScreensQuery,
  useGetSavedAppsQuery,
  useGetSavedAppQuery,
  useSavedAppsMutation,
  useDeleteSavedAppsMutation,
  useGetHiddenAppsQuery,
  useHiddenAppsMutation,
  useDeleteHiddenAppsMutation,
  useUpdateUserMutation,
  useCreateInviteMutation,
  useGetOrganizationQuery,
  useGetOrganizationsListQuery,
  useCreateOrganizationMutation,
  useGetMembershipQuery,
  useUpdateMembershipRoleMutation,
  useDeleteMembershipMutation,
  useListOrganizationUsersQuery,
  useLeaveOrganizationMutation,
  useSwitchOrganizationMutation,
  useUpdateOrganizationMutation,
  useGetOrganizationUsersQuery,
  useFetchSavedGroupsMutation,
  useGetSavedGroupQuery,
  useGetOrganizationInvitesQuery,
  useDeleteInviteMutation,
  useUpdateInviteMutation,
  useResendEmailVerificationMutation,
  useGetAvsTagsNotLoginQuery,
  useResetPasswordConfirmMutation,
  useGetAvsTagsListQuery,
  useUpdateSavedGroupMutation,
  usePurchaseSeatsMutation,
  usePreviewSeatsQuery,
  useChangePasswordMutation,
  useGetSharedGroupQuery,
  useGetAppVideoFlowsQuery,
  useGetAppVideoScreensPaginatedQuery,
  useCreateSavedAnimationMutation,
  useDeleteSavedAnimationMutation,
  useGetSavedAnimationsQuery,
  useGetAppOnboardingVideosQuery,
  useGetAppOnboardingVideoScreensQuery,
  useCreateSavedObScreenMutation,
  useGetSavedObScreensQuery,
  useDeleteSavedObScreenMutation,
  useCreateSavedGroupAnimationMutation,
  useGetSavedGroupAnimationQuery,
  useDeleteSavedGroupAnimationMutation,
  useCreateSavedGroupObScreenMutation,
  useGetSavedGroupObScreenQuery,
  useDeleteSavedGroupObScreenMutation,
  useGetSavedGroupAnimationsQuery,
  useGetSavedGroupObScreensQuery,
  useGetFlowTypesQuery,
  useSearchAppsQuery,
  useSearchAnythingQuery,
  useSuggestAppMutation,
} = authAPI;
