import React, { useEffect, useState, useMemo } from 'react';
import styles from './SeatsModal.module.scss';
import { usePurchaseSeatsMutation, usePreviewSeatsQuery, useGetOrganizationQuery } from '@/features/auth/api/authAPI';
import { toast } from 'react-toastify';

interface SeatsModalProps {
    isOpen: boolean;
    onClose: () => void;
    onGoToBilling?: () => void;
    currentSeats: number;
    organizationUuid: number;
}

const SeatsModal: React.FC<SeatsModalProps> = ({ isOpen, onClose, onGoToBilling, currentSeats, organizationUuid }) => {
    const [newSeats, setNewSeats] = useState(1);
    const [isProcessing, setIsProcessing] = useState(false);
    const [error, setError] = useState<string | null>(null);

    const [purchaseSeats] = usePurchaseSeatsMutation();
    const { data: organization, refetch: refetchOrganization } = useGetOrganizationQuery(organizationUuid);

    const additionalSeats = newSeats;

    const { data: previewData, refetch: refetchPreview } = usePreviewSeatsQuery({
        organizationUuid,
        qte: additionalSeats
    });

    useEffect(() => {
        if (isOpen && additionalSeats > 0) {
            refetchPreview();
        }
    }, [isOpen, additionalSeats, refetchPreview]);

    useEffect(() => {
        if (additionalSeats > 0) {
            refetchPreview();
        }
    }, [additionalSeats, refetchPreview]);

    const handleSelectPlan = async () => {
        setIsProcessing(true);
        setError(null);

        try {
            if (additionalSeats <= 0) {
                toast.error("Please select additional seats to purchase");
                return;
            }

            await purchaseSeats({
                organizationUuid,
                qte: additionalSeats
            }).unwrap();

            await new Promise(resolve => setTimeout(resolve, 1000));
            await refetchOrganization();
            toast.success("Seats purchased successfully!");
            onClose();

        } catch (error: any) {
            if (error.data && error.data.non_field_errors && error.data.non_field_errors[0]) {
                toast.error(error.data.non_field_errors[0]);
            } else {
                toast.error("Error during seat purchase process. Please try again.");
            }
        } finally {
            setIsProcessing(false);
        }
    };

    const total = previewData?.total / 100;

    const modalContent = useMemo(() => {
        if (!isOpen) return null;

        return (
            <div className={styles.overlay}>
                <div className={styles.modal}>
                    <div className={styles.close}>
                        <img src="/img/gradientIcon/Warning.svg" alt="close" onClick={onClose} />
                    </div>
                    <h1 className={styles.title}>Additional seats required</h1>
                    <p className={styles.description}>
                        It looks like you're out of available seats. To continue with this invitation,
                        an additional charge of ${total ? total : 0} will be applied.
                    </p>

                    <ul className={styles.seatsContainer}>
                        <li className={styles.seatsItem}>
                            <p>How many new seats?</p>
                            <div className={styles.counterContainer}>
                                <button
                                    className={styles.counterButton}
                                    onClick={() => setNewSeats(prev => Math.max(1, prev - 1))}
                                    disabled={newSeats <= 1}
                                >
                                    -
                                </button>
                                <p>{newSeats}</p>
                                <button
                                    className={styles.counterButton}
                                    onClick={() => setNewSeats(prev => prev + 1)}
                                >
                                    +
                                </button>
                            </div>
                        </li>
                        <li className={styles.seatsItem}>
                            <p>Total Amount</p>
                            <p>${total ? total : 0}</p>
                        </li>
                    </ul>
                    <div className={styles.buttonContainer}>
                        <button
                            onClick={onClose}
                            className={styles.cancelButton}
                            disabled={isProcessing}
                        >
                            Go back to team settings
                        </button>
                        <button
                            onClick={handleSelectPlan}
                            className={styles.confirmButton}
                            disabled={isProcessing || newSeats <= 0}
                        >
                            {isProcessing ? 'Processing...' : 'Confirm Payment'}
                        </button>
                    </div>
                </div>
            </div>
        );
    }, [isOpen, onClose, onGoToBilling, currentSeats, organizationUuid, newSeats, isProcessing, total]);

    return modalContent;
};

export default SeatsModal; 