import React, { useState } from 'react';
import { useResendEmailVerificationMutation } from '@/features/auth/api/authAPI';
import styles from './FormEmailSend.module.scss';
import { useLocation } from 'react-router-dom';

export default function VerifyOptions({ email: propEmail }: { email?: string }) {
    const location = useLocation();
    const emailFromState = location.state?.email;
    const [inputEmail, setInputEmail] = useState(emailFromState || "");
    const [isResending, setIsResending] = useState(false);
    const [resendSuccess, setResendSuccess] = useState(false);
    const [error, setError] = useState('');
    const [isBlocked, setIsBlocked] = useState(false);
    const [resendVerification] = useResendEmailVerificationMutation();

    const handleOpenGmail = () => {
        window.open('https://mail.google.com', '_blank');
    };

    const handleResendEmail = async () => {
        setIsResending(true);
        setError('');
        setResendSuccess(false);
        try {
            await resendVerification(inputEmail).unwrap();
            setResendSuccess(true);
        } catch (err: any) {
            setError(err.data?.message || 'Failed to resend verification email. Please try again.');
            setIsBlocked(true);
            setTimeout(() => setIsBlocked(false), 10000);
        } finally {
            setIsResending(false);
        }
    };

    const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setInputEmail(e.target.value);
    };

    return (
        <div className={styles.wrapper}>
            <div className={styles.card}>
                <div className={styles.cardHeader}>
                    <h1 className={styles.title}>Verify Your Email</h1>
                    <p className={styles.description}>
                        {(emailFromState || propEmail || inputEmail) ? (
                            <>We've sent a verification link to {emailFromState || propEmail || inputEmail}</>
                        ) : (
                            'Please enter your email to receive a verification link'
                        )}
                    </p>
                </div>

                <div className={styles.cardContent}>
                    <button
                        onClick={handleOpenGmail}
                        className={styles.buttonOutline}
                    >
                        <span className={styles.icon}>✉️</span>
                        Open Gmail
                    </button>
                    <label htmlFor="email" className={styles.label}>Enter email to resend</label>
                    <input 
                        className={styles.input} 
                        type="email" 
                        id="email" 
                        value={inputEmail}
                        onChange={handleEmailChange}
                        placeholder="Enter email" 
                        required
                    />

                    <button
                        onClick={handleResendEmail}
                        disabled={isResending || resendSuccess || isBlocked}
                        className={styles.button}
                    >
                        {isResending ? 'Resending...' : isBlocked ? 'Wait 10s...' : 'Resend'}
                    </button>

                    {error && (
                        <div className={styles.alertError}>
                            <div className={styles.alertHeader}>
                            <span className={styles.icon}>⚠️</span>
                            <h3 className={styles.alertTitle}>Error</h3>
                            </div>
                            <p className={styles.alertDescription}>{error}</p>
                        </div>
                    )}

                    {resendSuccess && (
                        <div className={styles.alertSuccess}>
                            <div className={styles.alertHeader}>
                                <span className={styles.icon}>✓</span>
                                <h3 className={styles.alertTitle}>Success</h3>
                            </div>
                            <p className={styles.alertDescription}>
                                Verification email has been resent. Please check your inbox.
                            </p>
                        </div>
                    )}
                </div>

                <div className={styles.cardFooter}>
                    <p className={styles.footerText}>
                        If you don't see the email, check your spam folder.
                    </p>
                </div>
            </div>
        </div>
    );
}