// react
import React, { useState } from "react";
// types
import { IAppSavedGroupStorePoints } from "@/features/auth/types/auth";
// styles
import styles from "./ModalSaveModeSavedGroupStore.module.scss";
import { RightClickModal } from '../RightClickModal/RightClickModal';
import { handleOpenVideoPage } from '@/app/libs/const/handleOpenVideoPage';

interface ModalSaveModeSavedGroupStoreProps {
  selectCurrentGroupActive: boolean;
  handleCurrentGroupSelect: (id: number, url: string, videoId: number, type: string) => void;
  item: IAppSavedGroupStorePoints;
  openGroupPhotoModal: (
    photo: string,
    photoId: number,
    name: string,
    ts: number,
    imageStoreId: number,
    appId?: number,
    slug?: string,
  ) => void;
  selectedCurrentGroups: Set<{
    id: number;
    url: string;
    videoId: number;
    type: string;
  }>;
}

const ModalSaveModeSavedGroupStore: React.FC<ModalSaveModeSavedGroupStoreProps> = ({
  selectCurrentGroupActive,
  handleCurrentGroupSelect,
  item,
  openGroupPhotoModal,
  selectedCurrentGroups,
}) => {
  const [hoveredScreenId, setHoveredScreenId] = useState<number | null>(null);
  const [rightClickModalVisible, setRightClickModalVisible] = useState(false);
  const [rightClickPosition, setRightClickPosition] = useState({ x: 0, y: 0 });

  const handleRightClick = (e: React.MouseEvent) => {
    e.preventDefault();
    setRightClickPosition({ x: e.clientX, y: e.clientY });
    setRightClickModalVisible(true);
  };

  const closeRightClickModal = () => {
    setRightClickModalVisible(false);
  };

  const isSelected = Array.from(selectedCurrentGroups).some(
    screen => screen.id === item.id
  );

  const handlePhotoClick = () => {
    openGroupPhotoModal(
      item.saved_store_screen.store_screen.thumbnail,
      item.id,
      item.saved_store_screen.app.name,
      0,
      item.saved_store_screen.store_screen.id,
      item.saved_store_screen.app.id,
      item.saved_store_screen.app.slug,
    );
  };

  return (
    <div
      className={`${styles.root} ${isSelected ? styles.selectedRoot : ''}`}
      onMouseEnter={() => setHoveredScreenId(item.id)}
      onMouseLeave={() => setHoveredScreenId(null)}
      onContextMenu={handleRightClick}
    >
      <div
        onClick={(event) => {
          event.stopPropagation();
          handleCurrentGroupSelect(
            item.id,
            item.saved_store_screen.store_screen.thumbnail,
            item.saved_store_screen.store_screen.id,
            'store'
          );
        }}
        className={isSelected ? styles.hoverElementActive : styles.hoverElement}
      >
        {isSelected && (
          <img
            src="/img/whiteIcon/Check.svg"
            alt="checkCircleImg"
          />
        )}
      </div>

      <div
        className={styles.photoItemTitle}
        onClick={handlePhotoClick}
      >
        <img
          src={item.saved_store_screen.app.icon || "/img/skeletonLogo.svg"}
          alt="appIcon"
          loading="lazy"
        />
        <div className={styles.photoItemContent}>
          <h3>{item.saved_store_screen.app.name}</h3>
          <p>{item.saved_store_screen.app.shortname}</p>
        </div>
      </div>

      <div
        className={`${styles.photoContainer} ${isSelected ? styles.selectedPhotoContainer : ""}`}
      >
        <div
          className={styles.containerPhoto}
          onClick={handlePhotoClick}
          style={{
            cursor: selectCurrentGroupActive ? "pointer" : "zoom-in",
          }}
        >
          <img
            src={item.saved_store_screen.store_screen.image}
            alt="screen"
            className={`${styles.photo} ${isSelected ? styles.selectedPhoto : ""}`}
            loading="lazy"
          />
        </div>
      </div>

      {rightClickModalVisible && (
        <RightClickModal
          x={rightClickPosition.x}
          y={rightClickPosition.y}
          onClose={closeRightClickModal}
          imageUrl={item.saved_store_screen.store_screen.thumbnail}
          imageId={item.id}
          timestamp={0}
          isStoreScreen={true}
          handleOpenInNewTab={() => handleOpenVideoPage(
            0,
            item.saved_store_screen.app.slug,
            'video',
            1,
            item.saved_store_screen.app.id
          )}
          avsID={item.saved_store_screen.app.id}
          refetchData={() => console.log('Refetch data')}
          imageType="store"
        />
      )}
    </div>
  );
};

export default ModalSaveModeSavedGroupStore;
