import React from "react";
import styles from "./CategoriesDeveloperModalItem.module.scss";

interface CategoriesRevenueModalItemProps {
  name: string;
  isActive: boolean;
  onClick: () => void;
}

const CategoriesDeveloperModalItem: React.FC<
  CategoriesRevenueModalItemProps
> = ({ name, isActive, onClick }) => {
  return (
    <div
      onClick={onClick}
      className={`${styles.root} ${isActive ? styles.itemActive : ""}`}
    >
      <span>{name}</span>
    </div>
  );
};

export default CategoriesDeveloperModalItem;
