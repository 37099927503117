import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useGetAppDevelopersQuery } from "@/features/auth/api/authAPI";
import {
  additionlInfoModalActions,
  getAdditionInfoModal,
} from "@/entities/additionInfoModal";
import AdditionalInfoModal from "@/shared/ui/AdditionalInfoModal/AdditionalInfoModal";
import {
  formatCategoryName,
  formatDate,
  formatNumber,
} from "@/shared/lib/constants/formaters";
import { AdditionalInfoProps } from "../../types/additionalInfoProps";
import styles from "./AdditionalInfo.module.scss";
import SaveApp from "@/shared/ui/SaveApp/SaveApp";
import { handleOpenVideoPage } from "@/app/libs/const/handleOpenVideoPage";

interface AdditionalInfoProps {
  data: any;
  handleSaveApp?: () => void;
  isStarred?: boolean;
  isHidden?: boolean;
  removeStarredApp?: () => void;
  setTempText?: (text: string) => void;
  appId?: number;
  hiddenAppItemId?: number;
}

const AdditionalInfo: React.FC<AdditionalInfoProps> = ({ data, handleSaveApp, isStarred, isHidden, removeStarredApp, setTempText, appId, hiddenAppItemId }) => {
  const modalRef = React.useRef<HTMLDivElement>(null);
  const timerRef = React.useRef<NodeJS.Timeout | null>(null);
  const { data: appDevelopers } = useGetAppDevelopersQuery(data.developer.name);
  const { isModalOpen } = useSelector(getAdditionInfoModal);
  const dispatch = useDispatch();

  const handleClickOutside = (event: MouseEvent) => {
    if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
      dispatch(additionlInfoModalActions.closeItem());
      dispatch(additionlInfoModalActions.closeModal());
    }
  };

  const handleMouseEnterDeveloper = () => {
    dispatch(additionlInfoModalActions.openModal());
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
  };

  const handleMouseLeaveDeveloper = () => {
    timerRef.current = setTimeout(() => {
      dispatch(additionlInfoModalActions.closeModal());
    }, 400);
  };

  const handleMouseEnterModal = () => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
  };

  const handleMouseLeaveModal = () => {
    timerRef.current = setTimeout(() => {
      dispatch(additionlInfoModalActions.closeModal());
    }, 400);
  };

  const handleClickDeveloper = (idApp: number) => {
    const appDeveloper = appDevelopers?.results.find(
      (item) => item.id === idApp,
    );
    handleOpenVideoPage(
      0,
      appDeveloper!.slug,
      "Developer",
      1,
      appDeveloper!.id,
      undefined,
      undefined,
    );
  };

  React.useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className={styles.root} ref={modalRef}>
      <div className={styles.closeButton}>
        <button onClick={() => dispatch(additionlInfoModalActions.closeItem())}>
          <img src="/img/blackIcon/Close.svg" alt="close" loading="lazy" />
        </button>
      </div>

      <div className={styles.containerTitle}>
        <div className={styles.containerTitleBox}>
          <img
            className={styles.logo}
            src={
              !data.icon || data.icon === ""
                ? "/img/skeletonLogo.svg"
                : data.icon
            }
            alt="ing-logoHeader"
            loading="lazy"
            draggable={false}
          />
          <div className={styles.title}>
            <h1>{data.name}</h1>
            <p>{data.shortname}</p>
          </div>
          <SaveApp
            onClick={handleSaveApp}
            isStarred={isStarred}
            isHidden={isHidden}
            removeStarredApp={removeStarredApp}
            setTempText={setTempText}
            appId={appId}
            hiddenAppItemId={hiddenAppItemId}
          />
        </div>

      </div>
      <div className={styles.line} />
      <div className={styles.containerInfo}>
        <div className={styles.containerBlockWrapper}>
          <div className={`${styles.containerBlock} ${styles.widthBlock}`}>
            <div className={styles.containerBlockTitle}>
              {/* <img src="/img/mdiCompany.svg" alt="mdiCompanyImg" loading="lazy" /> */}
              <h3>Developer</h3>
            </div>
            <button
              onMouseEnter={handleMouseEnterDeveloper}
              onMouseLeave={handleMouseLeaveDeveloper}
            >
              {data.developer.name === "" ? "Unknown" : data.developer.name}
            </button>
          </div>
          <div className={styles.containerBlock}>
            <div className={styles.containerBlockTitle}>
              {/* <img src="/img/appleLogo.svg" alt="appleLogoImg" loading="lazy" /> */}
              <h3>App Store</h3>
            </div>
            <a
              href={data.appstore_link}
              target="_blank"
              rel="noopener noreferrer"
              className={styles.appStoreLink}
            >
              View in App Store
            </a>
          </div>
        </div>
        {data.categories && data.categories.length > 0 && (
          <div className={styles.containerBlock}>
            <div className={styles.containerBlockTitle}>
              {/* <img
                src="/img/tablerCategory.svg"
                alt="tablerCategoryImg"
                loading="lazy"
              /> */}
            <h3>Category</h3>
          </div>
          <p>
            {!data.categories || data.categories.length === 0 || !data.categories[0].name
              ? "Unknown"
              : formatCategoryName(data.categories[0].name)}
          </p>
        </div>
        )}
        <div className={styles.containerBlockWrapper}>
          <div className={`${styles.containerBlock} ${styles.widthBlock}`}>
            <div className={styles.containerBlockTitle}>
              {/* <img
                src="/img/gradientIcon/Install.svg"
                alt="downloadImg"
                loading="lazy"
              /> */}
              <h3>Monthly Downloads</h3>
            </div>
            <p>
              {data.downloads === null || data.downloads === 0
                ? "<5k"
                : formatNumber(data.downloads)}
            </p>
          </div>
          <div className={styles.containerBlock}>
            <div className={styles.containerBlockTitle}>
              {/* <img
                src="/img/dollarCircle.svg"
                alt="dollarCircleImg"
                loading="lazy"
              /> */}
              <h3>Monthly Revenue</h3>
            </div>
            <p>
              {data.revenue === null || data.revenue === 0
                ? "<5k"
                : `${formatNumber(data.revenue)}`}
            </p>
          </div>
        </div>
        <div className={styles.containerBlockWrapper}>
          <div className={`${styles.containerBlock} ${styles.widthBlock}`}>
            <div className={styles.containerBlockTitle}>
              {/* <img
                src="/img/iconParkRotation.svg"
                alt="iconParkRotationImg"
                loading="lazy"
              /> */}
              <h3>Release date</h3>
            </div>
            <p>
              {data.released === null ? "Unknown" : formatDate(data.released)}
            </p>
          </div>
          <div className={styles.containerBlock}>
            <div className={styles.containerBlockTitle}>
              {/* <img
                src="/img/clockFastForward.svg"
                alt="iconParkRotationImg"
                loading="lazy"
              /> */}
              <h3>Last Update</h3>
            </div>
            <p>{data.updated === "" ? "Unknown" : formatDate(data.updated)}</p>
          </div>
        </div>
        <div className={styles.containerBlockWrapper}>
          <div className={`${styles.containerBlock} ${styles.widthBlock}`}>
            <div className={styles.containerBlockTitle}>
              {/* <img
                src="/img/dotsGrid.svg"
                alt="iconParkRotationImg"
                loading="lazy"
              /> */}
              <h3>Onboarding Steps</h3>
            </div>
            <p>
              {data.updated === "" ? "Unknown" : data.avs.onboarding_step_count}
            </p>
          </div>
          <div className={styles.containerBlock}>
            <div className={styles.containerBlockTitle}>
              {/* <img
                src="/img/creditCardLock.svg"
                alt="iconParkRotationImg"
                loading="lazy"
              /> */}
              <h3>Paywall Type</h3>
            </div>
            <p>{data.updated === "" ? "Unknown" : data.avs.paywall_type}</p>
          </div>
        </div>
        <div className={styles.containerBlock}>
          <div className={styles.containerBlockTitle}>
            {/* <img
              src="/img/adsIconDefault.svg"
              alt="iconParkRotationImg"
              loading="lazy"
            /> */}
            <h3>Running ads</h3>
          </div>
          <p>
            {data.updated === ""
              ? "Unknown"
              : data.avs.has_onboarding_with_quiz
                ? "Yes"
                : "No"}
          </p>
        </div>
      </div>
      <div className={styles.modalDeveloper}>
        {isModalOpen && appDevelopers && (
          <AdditionalInfoModal
            onMouseEnter={handleMouseEnterModal}
            onMouseLeave={handleMouseLeaveModal}
            onClick={handleClickDeveloper}
            data={appDevelopers}
          />
        )}
      </div>
    </div>
  );
};

export default AdditionalInfo;
