import { useEffect, useRef, useState } from "react";
import { useOutletContext } from 'react-router-dom'
import ModalSaveMode from "@/widgets/modalSaveMode/ui/ModalSaveMode/ModalSaveMode";
import styles from "@/widgets/modalSaveMode/ui/ModalSaveMode/ModalSaveMode.module.scss";
import { useCurrentOrganization } from '@/Hooks'
import { useGetSavedStorePointsAllQuery } from "@/features/auth/api/authAPI";

const SavedModeStoreView = () => {
  const { zoom, sortkey, userFilter } = useOutletContext()
  const [activeIndex, setActiveIndex] = useState(1);
  const [page, setPage] = useState(1);
  const PAGE_SIZE = 10;
  const [allResults, setAllResults] = useState<any[]>([]);
  const [sortDirectionSavedMode, setSortDirectionSavedMode] = useState("desc");
  const photoGroups = useRef<HTMLDivElement>(null);
  const currentOrganization = useCurrentOrganization();

  const handleItemClick = (index: number) => { setActiveIndex(index); };

  const { data: savedStoreScreens, refetch: refetchSavedScreens, isLoading, isFetching } = useGetSavedStorePointsAllQuery({
    organizationUuid: currentOrganization?.uuid || '',
    params: {
      sort: sortkey,
      user: userFilter,
      page: page,
      pageSize: PAGE_SIZE
    }
  }, {
    skip: !currentOrganization
  });

  useEffect(() => {
    if (savedStoreScreens?.results && page === 1) {
      setAllResults(savedStoreScreens.results);
    } else if (savedStoreScreens?.results) {
      setAllResults(prev => [...prev, ...savedStoreScreens.results]);
    }
  }, [savedStoreScreens?.results, page]);

  const combinedData = {
    ...savedStoreScreens,
    results: allResults
  };

  const applyZoom = (zoom: number) => {
    const photoCardListElement = photoGroups.current;
    if (photoCardListElement) {
      photoCardListElement.style.setProperty("--zoom-level", zoom.toString());
    }
  };

  useEffect(() => {
    applyZoom(zoom);
  }, [zoom]);

  return (
    <div className={styles.mainContent}>
      <div ref={photoGroups} className={styles.photosContainer}>
        <ModalSaveMode
          dataSavedPoint={undefined}
          activeIndex={activeIndex}
          handleItemClick={handleItemClick}
          apiDataSharedViews={undefined}
          apiDataSavedStoreScreens={combinedData}
          apiDataSharedItem={undefined}
          sharedGroupsQuery={null}
          refetchSavedPoints={refetchSavedScreens}
          apiDataSharedItemSavedPoints={undefined}
          apiDataSharedItemStoreScreens={undefined}
          sortDirection={sortDirectionSavedMode}
          setSortDirection={setSortDirectionSavedMode}
          viewType="store"
          page={page}
          setPage={setPage}
          isLoading={isLoading}
          isFetching={isFetching}
          pageSize={PAGE_SIZE}
        />
      </div>
    </div>
  );
};

export { SavedModeStoreView };
