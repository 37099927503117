import mixpanel from "@/shared/lib/mixpanel";

export const handleOpenVideoPage = (
  timestamp: number | 0,
  slugText: string,
  from: string,
  videoType: number,
  appId: number,
  videoId: number,
  onboardingVideoId?: number,
  userName?: string,
  organizationId?: string,
) => {
  sessionStorage.setItem("modalId", appId?.toString() || "");

  let newUrl = `/apps/${slugText}/?ts=${timestamp}&vt=${videoType}`;

  if (appId !== undefined) {
    newUrl += `&id=${appId}`;
  }

  if (videoId !== undefined) {
    newUrl += `&av=${videoId}`;
  }

  if (onboardingVideoId !== undefined) {
    newUrl += `&obv=${onboardingVideoId}`;
  }

  window.open(newUrl, "_blank");

  mixpanel.track("Video of App Clicked", {
    app_id: appId,
    app_slug: slugText,
    UserName: userName,
    OrganizationID: organizationId,
    from: from || "Home Page",
  });
};
