import React, { useState, useCallback, useEffect } from "react";
import {
  useGetAppVideoScreensPaginatedQuery,
  useSavedPointsMutation,
  useDeletePointMutation,
  useGetVideoStoreScrollQuery,
  useSavedStorePointsMutation,
  useSavedStoreScreensDeleteMutation,
} from "@/features/auth/api/authAPI";
import { IoIosCheckmarkCircle } from "react-icons/io";
import { useMe, useCurrentOrganization } from "@/Hooks";
import { toast } from "react-toastify";
import mixpanel from "@/shared/lib/mixpanel";
import { useNavigate } from "react-router-dom";
import styles from "./MultipuleSelect.module.scss";
import PhoneHomePageHorisontalNew from "@/Views/Virtual/PhoneHomePageHorisontalNew";
import Modal from "react-modal";
import ContentLoader from "react-content-loader";
import { motion, AnimatePresence } from "framer-motion";
import { getFilterAppsData } from "@/entities/filterAppsData/model/selectors/getFilterAppsData";
import { useSelector } from "react-redux";
import PhoneHomePageStore from "@/widgets/PhoneHomePageStore/ui/PhoneHomePageStore/PhoneHomePageStore";
import { getButtonFilter } from "@/entities/buttonFilter";
import { produce } from "immer";
import axios from "axios";
import { handleOpenVideoPage } from "@/app/libs/const/handleOpenVideoPage";

interface Screen {
  id: number;
  screen: string;
  timestamp: string;
  is_saved?: boolean;
  is_saved_pk?: number;
  app_video?: number;
}

interface AppDataFilter {
  id: number;
  name: string;
  shortname: string;
  icon: string;
}

interface MultipuleSelectProps {
  appDataFilter: AppDataFilter;
}

const MultipuleSelect: React.FC<MultipuleSelectProps> = ({ appDataFilter, currentAppIndex, setCurrentAppIndex, appIndex, onNextApp, appDataToShow, onPreviousApp }) => {
  const { me } = useMe();
  const navigate = useNavigate();
  const [pageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [isHovered, setIsHovered] = useState(false);
  const [isHovered2, setIsHovered2] = useState(false);
  const currenOrganization = useCurrentOrganization();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [screens, setScreens] = useState<Screen[]>([]);
  const [imageLoaded, setImageLoaded] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showSkeleton, setShowSkeleton] = useState(true);
  const { activeIndex } = useSelector(getButtonFilter)
  const [isPointActionPending, setIsPointActionPending] = useState(false);
  const [slideDirection, setSlideDirection] = useState<'left' | 'right'>('right');
  const [transitionDirection, setTransitionDirection] = useState<'next' | 'prev' | null>(null);
  const { filterAppDataSorted, isScreensLoadingHome, isScreensLoadingStore } = useSelector(getFilterAppsData);
  const [preloadedImages, setPreloadedImages] = useState<{ [key: number]: boolean }>({});
  const [isImageError, setIsImageError] = useState(false);

  const [savedPoint, { isLoading: isSaving }] = useSavedPointsMutation();
  const [deletePoint, { isLoading: isDeleting }] = useDeletePointMutation();

  const [savedStoreScreen] = useSavedStorePointsMutation();
  const [savedStoreScreensDelete] = useSavedStoreScreensDeleteMutation();

  const {
    data: paginatedHomeScreens,
    isFetching: isHomeScreensFetching,
    isLoading: isHomeScreensLoading,
    error: homeScreensError,
  } = useGetAppVideoScreensPaginatedQuery(
    {
      appId: appDataFilter?.id,
      pageSize: pageSize,
      page: page,
      featured_only: true
    },
    {
      skip: !appDataFilter?.id || !hasMore || activeIndex !== 0,
    },
  );

  const {
    data: paginatedStoreScreens,
    isFetching: isStoreScreensFetching,
    isLoading: isStoreScreensLoading,
    error: storeScreensError,
  } = useGetVideoStoreScrollQuery(
    {
      id: appDataFilter?.id,
      page: page,
    },
    {
      skip: !appDataFilter?.id || !hasMore || activeIndex !== 1,
    },
  );

  const isScreensFetching = activeIndex === 0 ? isHomeScreensFetching : isStoreScreensFetching;
  const isScreensLoading = activeIndex === 0 ? isHomeScreensLoading : isStoreScreensLoading;
  const screensError = activeIndex === 0 ? homeScreensError : storeScreensError;
  const paginatedScreens = activeIndex === 0 ? paginatedHomeScreens : paginatedStoreScreens;

  const handlePrev = () => {
    setSlideDirection('left');
    setCurrentIndex((prevIndex) => {
      const newIndex = prevIndex === 0 ? screens.length - 1 : prevIndex - 1;
      return newIndex;
    });
  };

  const handleNext = () => {
    setSlideDirection('right');
    setCurrentIndex((prevIndex) => {
      const newIndex = prevIndex === screens.length - 1 ? 0 : prevIndex + 1;
      return newIndex;
    });

    if (screens.length - currentIndex <= 5 && hasMore) {
      loadMore();
    } else {
      for (let i = 1; i <= 3; i++) {
        const futureIndex = (currentIndex + i) % screens.length;
        if (screens[futureIndex]?.screen) {
          preloadImage(screens[futureIndex].screen, futureIndex);
        }
      }
    }
  };

  const handleSavePoint = async (event: React.MouseEvent) => {
    event.stopPropagation();

    if (!me || !me.username || !me.email) {
      localStorage.removeItem("access_token");
      localStorage.setItem("btnName", "save a point");
      navigate("/login", { state: { fromLogin: true } });
      window.scrollTo(0, 0);
      return;
    }

    const currentScreen = screens[currentIndex];
    if (!currentScreen) return;

    if (activeIndex === 1) {
      await handleSaveImage(currentScreen.id);
      return;
    }

    setIsPointActionPending(true);
    try {
      if (currentScreen.is_saved) {
        if (currentScreen.is_saved_pk) {
          await deletePoint(currentScreen.is_saved_pk).unwrap();
          if (me?.email) {
            mixpanel.identify(me.email);
            setTimeout(() => {
              mixpanel.people.increment("total_unsaved_screens", 1);
            }, 100);
          }

          setScreens((prevScreens) =>
            prevScreens.map((screen) =>
              screen.id === currentScreen.id
                ? { ...screen, is_saved: false, is_saved_pk: undefined }
                : screen,
            ),
          );

          toast.success("Image removed from saved items");
        }
      } else {
        try {
          const sp = await savedPoint({
            organizationUuid: currenOrganization.uuid,
            app_video: appDataFilter.latest_appvideo_id,
            ts: currentScreen.timestamp,
          }).unwrap();

          mixpanel.track("Image saved", {
            idApp: appDataFilter.id,
            app_name: appDataFilter.name,
            From: "HomePage",
            idscreen: currentScreen.id,
            UserEmail: me?.email || "",
            OrganizationID: currenOrganization?.uuid || "",
          });

          if (me?.email) {
            mixpanel.identify(me.email);
            setTimeout(() => {
              mixpanel.people.increment("total_saved_screens", 1);
            }, 100);
          }

          setScreens((prevScreens) =>
            prevScreens.map((screen) =>
              screen.id === currentScreen.id
                ? { ...screen, is_saved: true, is_saved_pk: sp.id }
                : screen,
            ),
          );

          //toast.success("Image saved successfully");
        } catch (error: unknown) {
          const err = error as { data?: { non_field_errors?: string[] } };
          if (err?.data?.non_field_errors?.[0] === "Already saved") {
            toast.warning("Image already saved");
            return;
          }
          throw error;
        }
      }
    } catch (error) {
      toast.error("Failed to save/remove image");
    } finally {
      setIsPointActionPending(false);
    }
  };

  const handleSaveImage = async (storeScreen: number) => {
    if (me && currenOrganization.uuid) {
      const imageToUpdate = screens.find((item) => item.id === storeScreen);

      if (imageToUpdate) {
        try {
          if (imageToUpdate.is_saved) {
            // Delete the saved image
            await savedStoreScreensDelete(imageToUpdate.is_saved_pk).unwrap();

            if (me?.email) {
              mixpanel.identify(me.email);
              setTimeout(() => {
                mixpanel.people.increment('total_unsaved_screens', 1);
              }, 100);
            }

            setScreens(produce((draft) => {
              const index = draft.findIndex((obj) => obj.id === storeScreen);
              if (index !== -1) {
                draft[index].is_saved = false;
                draft[index].is_saved_pk = undefined;
              }
            }));

            toast.success("Image removed from saved items");
          } else {
            // Save the image
            const resp = await savedStoreScreen({
              organizationUuid: currenOrganization.uuid,
              storeScreen: storeScreen,
            }).unwrap();

            mixpanel.track('Image saved', {
              idApp: appDataFilter.id,
              app_name: appDataFilter.name,
              From: 'HomePage',
              idscreen: storeScreen,
              UserEmail: me?.email || '',
              OrganizationID: currenOrganization?.uuid || '',
            });

            if (me?.email) {
              mixpanel.identify(me.email);
              setTimeout(() => {
                mixpanel.people.increment('total_saved_screens', 1);
              }, 100);
            }

            setScreens(produce((draft) => {
              const index = draft.findIndex((obj) => obj.id === storeScreen);
              if (index !== -1) {
                draft[index].is_saved = true;
                draft[index].is_saved_pk = resp.id;
              }
            }));

            //toast.success("Image saved successfully");
          }
        } catch (error) {
          if (axios.isAxiosError(error)) {
            console.error("Save/Delete operation failed:", error.response?.data.error);
            toast.error(error.response?.data.error);
          } else {
            console.error("An unexpected error occurred:", error);
            toast.error("An unexpected error occurred");
          }
        }
      } else {
        console.error("Image not found in screens");
        toast.error("Unable to process image. Image data is missing.");
      }
    } else {
      localStorage.setItem("btnName", "save image");
      navigate("/register", { state: { fromLogin: true } });
      window.scrollTo(0, 0);
    }
  };

  const handleOpenModal = () => {
    setCurrentAppIndex(appIndex);
    setIsModalOpen(prev => !prev);
  };

  const handlePreviousApp = (index: number) => {
    setTransitionDirection('prev');
    requestAnimationFrame(() => {
      onPreviousApp(index);
    });
  };

  const handleNextApp = (index: number) => {
    setTransitionDirection('next');
    requestAnimationFrame(() => {
      onNextApp(index);
    });
  };

  const modalVariants = {
    hidden: { opacity: 0, scale: 0.5, x: "-50%", y: "-50%" },
    visible: { opacity: 1, scale: 1, x: "-50%", y: "-50%" },
    exit: { opacity: 0, scale: 0.5, x: "-50%", y: "-50%" }
  };

  const contentVariants = {
    hidden: (direction: 'next' | 'prev') => ({
      opacity: 0,
      y: direction === 'next' ? 100 : -100
    }),
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5,
        ease: "easeOut"
      }
    },
    exit: (direction: 'next' | 'prev') => ({
      opacity: 0,
      y: direction === 'next' ? -100 : 100,
      transition: {
        duration: 0.5,
        ease: "easeIn"
      }
    })
  };

  const imageVariants = {
    enter: (direction: string) => ({
      x: direction === 'right' ? 300 : -300,
      opacity: 0
    }),
    center: {
      x: 0,
      opacity: 1
    },
    exit: (direction: string) => ({
      x: direction === 'right' ? -300 : 300,
      opacity: 0
    })
  };

  const switchVariants = {
    hidden: (direction: 'next' | 'prev') => ({
      opacity: 0,
      y: direction === 'next' ? 100 : -100,
      scale: 0.8
    }),
    visible: {
      opacity: 1,
      y: 0,
      scale: 1,
      transition: {
        duration: 0.3,
        ease: "easeOut"
      }
    },
    exit: (direction: 'next' | 'prev') => ({
      opacity: 0,
      y: direction === 'next' ? -100 : 100,
      scale: 0.8,
      transition: {
        duration: 0.2,
        ease: "easeIn"
      }
    })
  };

  const loadMore = useCallback(() => {
    if (hasMore && !isScreensFetching) {
      setPage((prev) => prev + 1);
    }
  }, [hasMore, isScreensFetching]);

  const preloadImage = useCallback((imageUrl: string, index: number) => {
    if (!imageUrl || preloadedImages[index]) return;

    const img = new Image();
    img.src = imageUrl;
    img.onload = () => {
      setPreloadedImages(prev => ({ ...prev, [index]: true }));
    };
    img.onerror = () => {
      console.error(`Failed to preload image at index ${index}`);
    };
  }, [preloadedImages]);

  useEffect(() => {
    if (screens.length === 0) return;

    preloadImage(screens[currentIndex]?.screen, currentIndex);

    const nextIndex = currentIndex === screens.length - 1 ? 0 : currentIndex + 1;
    if (screens[nextIndex]?.screen) {
      preloadImage(screens[nextIndex].screen, nextIndex);
    }

    const prevIndex = currentIndex === 0 ? screens.length - 1 : currentIndex - 1;
    if (screens[prevIndex]?.screen) {
      preloadImage(screens[prevIndex].screen, prevIndex);
    }
  }, [currentIndex, screens, preloadImage]);

  useEffect(() => {
    setPage(1);
    setScreens([]);
    setCurrentIndex(0);
    setHasMore(true);
    setShowSkeleton(true);
  }, [activeIndex, appDataFilter?.id]);

  useEffect(() => {
    if (paginatedScreens?.results?.length > 0) {
      setScreens((prevScreens) => {
        if (page === 1) {
          const newScreens = activeIndex === 0
            ? paginatedScreens.results.map(screen => ({
              id: screen.id,
              screen: screen.screen,
              timestamp: screen.timestamp,
              is_saved: screen.is_saved,
              is_saved_pk: screen.is_saved_pk,
              app_video: screen.app_video,
            }))
            : paginatedScreens.results.map(screen => ({
              id: screen.id,
              screen: screen.image,
              timestamp: '',
              is_saved: screen.is_saved || false,
              is_saved_pk: screen.is_saved_pk || null,
              app_video: undefined,
            }));
          return newScreens;
        }

        const existingIds = new Set(prevScreens.map(screen => screen.id));

        const newScreens = activeIndex === 0
          ? paginatedScreens.results.filter(screen => !existingIds.has(screen.id)).map(screen => ({
            id: screen.id,
            screen: screen.screen,
            timestamp: screen.timestamp,
            is_saved: screen.is_saved,
            is_saved_pk: screen.is_saved_pk,
            app_video: screen.app_video,
          }))
          : paginatedScreens.results.filter(screen => !existingIds.has(screen.id)).map(screen => ({
            id: screen.id,
            screen: screen.image,
            timestamp: '',
            is_saved: screen.is_saved || false,
            is_saved_pk: screen.is_saved_pk || null,
            app_video: undefined,
          }));
        return [...prevScreens, ...newScreens];
      });

      // Handle pagination
      if (activeIndex === 0) {
        setHasMore(!!paginatedScreens.next);
      } else {
        const receivedItems = paginatedScreens.results.length;
        setHasMore(receivedItems === pageSize);
      }
    }
  }, [paginatedScreens, activeIndex, page, pageSize]);

  useEffect(() => {
    if (screens.length > 0 && !isScreensLoading) {
      setShowSkeleton(false);
    }
  }, [screens, isScreensLoading]);

  useEffect(() => {
    if (screensError) {
      if ("status" in screensError && screensError.status === 404) {
        setHasMore(false);
      }
      console.error("Error fetching video screens:", screensError);
      setShowSkeleton(false);
    }
  }, [screensError]);

  useEffect(() => {
    if (currentAppIndex > appIndex) {
      setTransitionDirection('next');
    } else if (currentAppIndex < appIndex) {
      setTransitionDirection('prev');
    }
  }, [currentAppIndex, appIndex]);

  return (
    <div
      className={styles.container}
    >
      <div className={styles.infoContainer}>
        <img className={styles.icon} src={appDataFilter.icon} alt="Icon" />
        <div className={styles.textContainer} onClick={() => handleOpenVideoPage(0, appDataFilter.slug, 'HomePage', 1, appDataFilter.id, appDataFilter.latest_appvideo_id)}>
          <h5>{appDataFilter.name}</h5>
          <h6>{appDataFilter.shortname}</h6>
        </div>
      </div>

      <div className={styles.containerImg}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <div
          className={`${styles.arrowIcon} ${isHovered && currentIndex > 0 ? styles.visible : ""}`}
          onClick={currentIndex > 0 ? handlePrev : undefined}
          style={{ pointerEvents: currentIndex > 0 ? 'auto' : 'none' }}
        >
          <img src="/img/blackIcon/ArrowLeft.svg" alt="Arrow Left" />
        </div>

        {showSkeleton ? (
          <div className={styles.imgContainer}>
            <ContentLoader
              speed={2}
              width={340}
              height={550}
              viewBox="0 0 340 550"
              backgroundColor="#f3f3f3"
              foregroundColor="#ecebeb"
            >
              <rect x="0" y="0" rx="7" ry="7" width="340" height="550" />
            </ContentLoader>
          </div>
        ) : screens.length > 0 && (
          <div className={styles.imgContainer}>
            <AnimatePresence mode="wait" initial={false}>
              <motion.div
                key={currentIndex}
                className={styles.imageWrapper}
                variants={imageVariants}
                initial="enter"
                animate="center"
                exit="exit"
                custom={slideDirection}
                transition={{
                  duration: 0.2,
                  ease: "easeInOut"
                }}
              >
                <img
                  src={screens[currentIndex]?.screen}
                  alt="Screenshot"
                  className={`${styles.firstimg} ${imageLoaded ? styles.loaded : ''}`}
                  onClick={handleOpenModal}
                  onLoad={() => {
                    setImageLoaded(true);
                    setShowSkeleton(false);
                    setIsImageError(false);
                  }}
                  onError={() => {
                    setIsImageError(true);
                    setImageLoaded(true);
                    setShowSkeleton(false);
                  }}
                  loading="eager"
                  decoding="async"
                  fetchpriority="high"
                />
                {isImageError && (
                  <div className={styles.errorContainer}>
                    <p>Failed to load image</p>
                  </div>
                )}
              </motion.div>
            </AnimatePresence>

            {(!imageLoaded || showSkeleton) && (
              <ContentLoader
                speed={1}
                width={340}
                height={550}
                viewBox="0 0 340 550"
                backgroundColor="#f3f3f3"
                foregroundColor="#ecebeb"
                style={{ position: 'absolute', top: 0, left: 0 }}
              >
                <rect x="0" y="0" rx="7" ry="7" width="340" height="550" />
              </ContentLoader>
            )}

            <button
              type="button"
              onClick={handleSavePoint}
              className={`${styles.bookMarkContainer} ${isHovered ? styles.visible : ""}`}
              disabled={isPointActionPending || isSaving || isDeleting}
            >
              {screens[currentIndex]?.is_saved && me ? (
                <IoIosCheckmarkCircle
                  style={{
                    color: "green",
                    fontSize: "17px",
                  }}
                />
              ) : (
                <img src="/img/blackIcon/Save.svg" alt="Bookmark" />
              )}
            </button>


            {/* <div
              className={`${styles.seeSimilareContainer} ${isHovered ? styles.visible : ""}`}
            >
              <img src="/img/blackIcon/similar.svg" alt="See Similar" />
              <p>See Similar</p>
            </div> */}
          </div>
        )}

        <div
          className={`${styles.arrowIcon} ${isHovered ? styles.visible : ""}`}
          onClick={handleNext}
        >
          <img src="/img/blackIcon/ArrowRight.svg" alt="Arrow Right" />
        </div>
      </div>

      <AnimatePresence mode="wait">
        {isModalOpen && !isScreensLoadingHome && !isScreensLoadingStore && (
          <Modal
            isOpen={isModalOpen}
            onRequestClose={handleOpenModal}
            contentLabel="Image Modal"
            className={styles.modal}
            overlayClassName={styles.overlay}
          >
            <motion.div
              initial="hidden"
              animate="visible"
              exit="exit"
              variants={modalVariants}
              transition={{ duration: 0.3, ease: "easeInOut" }}
              style={{ position: "absolute", top: "50%", left: "50%" }}
            >
              <motion.div
                key={currentAppIndex}
                initial="hidden"
                animate="visible"
                exit="exit"
                variants={contentVariants}
                custom={transitionDirection}
                transition={{ duration: 0.3, ease: "easeInOut" }}
              >
                <motion.div>
                  {currentAppIndex > 0 && (
                    <div className={styles.prevAppContainer}>
                      <button
                        className={styles.previusAppButton}
                        onClick={() => handlePreviousApp(currentAppIndex)}
                        onMouseEnter={() => setIsHovered(true)}
                        onMouseLeave={() => setIsHovered(false)}
                      >
                        <img src="/img/blackIcon/ArrowRight.svg" alt="Arrow Right" />
                      </button>
                      <p className={`${styles.previusAppText} ${isHovered ? styles.visible : ""}`}>
                        Go to previous app
                      </p>
                    </div>
                  )}

                  <AnimatePresence mode="wait" custom={transitionDirection}>
                    <motion.div
                      key={activeIndex}
                      custom={transitionDirection}
                      variants={switchVariants}
                      initial="hidden"
                      animate="visible"
                      exit="exit"
                      style={{ width: '100%' }}
                    >
                      {activeIndex === 0 ? (
                        <PhoneHomePageHorisontalNew
                          dataApi={appIndex === currentAppIndex ? appDataFilter : appDataToShow}
                          isOpen={false}
                          setIsPhonePageLoading={() => { }}
                          setIsSaveScreen={() => { }}
                          setLastClickTime={() => { }}
                          isFirstGroup={false}
                          currentOrganization={currenOrganization}
                          isPaywallOnly={false}
                          isPhonePageLoading={false}
                        />
                      ) : (
                        <PhoneHomePageStore
                          organization={currenOrganization.uuid}
                          key={appDataFilter.id}
                          dataApi={appIndex === currentAppIndex ? appDataFilter : appDataToShow}
                          apiDataSavedStoreScreens={{
                            count: 0,
                            next: null,
                            previous: null,
                            results: []
                          }}
                          isLoadingStore={false}
                          isFetchingStore={false}
                        />
                      )}
                    </motion.div>
                  </AnimatePresence>

                  {filterAppDataSorted.length > currentAppIndex + 1 && (
                    <div className={styles.nextAppContainer}>
                      <button
                        className={styles.nextAppButton}
                        onClick={() => handleNextApp(currentAppIndex)}
                        onMouseEnter={() => setIsHovered2(true)}
                        onMouseLeave={() => setIsHovered2(false)}
                      >
                        <img src="/img/blackIcon/ArrowRight.svg" alt="Arrow Right" />
                      </button>
                      <p className={`${styles.nextAppText} ${isHovered2 ? styles.visible : ""}`}>
                        Go to next app
                      </p>
                    </div>
                  )}
                </motion.div>
              </motion.div>
            </motion.div>
          </Modal>
        )}
      </AnimatePresence>

    </div>
  );
};

export default MultipuleSelect;
