import React, { useState, useEffect } from 'react'
import { useOutletContext, useLocation, useSearchParams, Outlet } from 'react-router-dom'
import ExploreAppsStyles from "@/Views/Virtual/ExplorAppsNew.module.scss";

import SwitcherContext from "@/shared/ui/SwitcherContext/Switcher";
import { produce } from 'immer'
import { getFilterAppsState } from "@/entities/filterApps";
import { useDispatch, useSelector } from "react-redux";
import { filterActions, getFilterState } from "@/entities/filter";

import ActiveSearch from "@/shared/ui/ActiveSearch/AvtiveSearch";
import { useMe } from '@/Hooks'
import { getFilterInputSearchState } from "@/entities/filterInputSearch";
import { activeModalIndexActions } from "@/entities/activeModalIndex";

import {
    ACTIVE_VIEW_SEARCH,
    SEARCH_MODE_OCR
} from '@/Views/constants'
import { saveModeActions } from '@/entities/saveMode/model/slice/saveModeSlice';


const SearchRootView = () => {

    const dispatch = useDispatch();
    const [ searchParams ] = useSearchParams();
    const {
        setRootSearch,
        setRootActiveView,
        searchValue,
        setSearchValue,
        sortDirection
    } = useOutletContext() as any


    useEffect(() => {
        setRootActiveView(ACTIVE_VIEW_SEARCH)
    }, [])

    const {
        filterSort,
        categories,
        paywall,
        oscgte,
        osclte,
        revenuegte,
        revenuelte,
        downloadgte,
        downloadlte,
        releasedFrom,
        releasedTo,
        updatedFrom,
        updatedTo
    } = useSelector(getFilterAppsState)

    const { appName } = useSelector(getFilterInputSearchState);

    
    const makeOrderValue = (key, direction) => {
        if (key !== "") {
            return `${direction === "desc" ? "-" : ""}${key}`
        } else {
            return ""
        }
    }

    const downloadgteNumber = downloadgte === "Min" ? "" : downloadgte;
    const downloadlteNumber = downloadlte === "Max" ? "" : downloadlte;
    const revenuegteNumber = revenuegte === "Min" ? "" : revenuegte;
    const revenuelteNumber = revenuelte === "Max" ? "" : revenuelte;
    const oscgteNumber = oscgte === "Min" ? "" : oscgte;
    const osclteNumber = osclte === "Max" ? "" : osclte;

    const [filterParams, setFilterParams] = useState({
        q: searchParams.get('q'),
        smode: searchParams.get('smode'),
        category__in: categories,
        name: appName,
        download_gte: downloadgteNumber,
        download_lte: downloadlteNumber,
        revenue_gte: revenuegteNumber,
        revenue_lte: revenuelteNumber,
        paywall_type: paywall,
        osc_gte: oscgteNumber,
        osc_lte: osclteNumber,
        released_from: releasedFrom,
        released_to: releasedTo,
        updated_from: updatedFrom,
        updated_to: updatedTo,
        //order: makeOrderValue(filterSort, sortDirection),
    })

    const [isSearchBoxOpen, setIsSearchBoxOpen] = useState(false);
    const location = useLocation();
    const { me } = useMe()
    //const [searchParams, setSearchParams] = useSearchParams();
    //const [filterParams, setFilterParams] = useState({})
    const [query, setQuery] = useState(searchParams.get('q'))
    const [searchMode, setSearchMode] = useState(searchParams.get('smode'))
    const { isActiveMagic } = useSelector(getFilterState);
    const [prevFilters, setPrevFilters] = useState()
    const [debugMode, setDebugMode] = useState(false)

    

    

    

    useEffect(() => {

        //setPrevFilters(filterParams)

        // const downloadgteNumber = downloadgte === "Min" ? "" : downloadgte;
        // const downloadlteNumber = downloadlte === "Max" ? "" : downloadlte;
        // const revenuegteNumber = revenuegte === "Min" ? "" : revenuegte;
        // const revenuelteNumber = revenuelte === "Max" ? "" : revenuelte;
        // const oscgteNumber = oscgte === "Min" ? "" : oscgte;
        // const osclteNumber = osclte === "Max" ? "" : osclte;

        // if (categories != undefined) { setFilterParams(produce((draft) => { draft['category__in'] = categories })) }
        // if (appName != undefined) { setFilterParams(produce((draft) => { draft['name'] = appName })) }
        // if (downloadgte != undefined) { setFilterParams(produce((draft) => { draft['download_gte'] = downloadgteNumber })) }
        // if (downloadlte != undefined) { setFilterParams(produce((draft) => { draft['download_lte'] = downloadlteNumber })) }
        // if (revenuegte != undefined) { setFilterParams(produce((draft) => { draft['revenue_gte'] = revenuegteNumber })) }
        // if (revenuelte != undefined) { setFilterParams(produce((draft) => { draft['revenue_lte'] = revenuelteNumber })) }
        // if (paywall != undefined) { setFilterParams(produce((draft) => { draft['paywall_type'] = paywall })) }
        // if (oscgte != undefined) { setFilterParams(produce((draft) => { draft['osc_gte'] = oscgteNumber })) }
        // if (osclte != undefined) { setFilterParams(produce((draft) => { draft['osc_lte'] = osclteNumber })) }
        // if (releasedFrom != undefined) { setFilterParams(produce((draft) => { draft['released_from'] = releasedFrom })) }
        // if (releasedTo != undefined) { setFilterParams(produce((draft) => { draft['released_to'] = releasedTo })) }
        // if (updatedFrom != undefined) { setFilterParams(produce((draft) => { draft['updated_from'] = updatedFrom })) }
        // if (updatedTo != undefined) { setFilterParams(produce((draft) => { draft['updated_to'] = updatedTo })) }
        // if (filterSort !== undefined) { setFilterParams(produce((draft) => { draft['order'] = makeOrderValue(filterSort, sortDirection) })) }

        setFilterParams(produce((draft) => {
            draft['q'] = searchParams.get('q')
            draft['smode'] = searchParams.get('smode')
            draft['category__in'] = categories
            draft['name'] = appName
            draft['download_gte'] = downloadgteNumber
            draft['download_lte'] = downloadlteNumber
            draft['revenue_gte'] = revenuegteNumber
            draft['revenue_lte'] = revenuelteNumber
            draft['paywall_type'] = paywall
            draft['osc_gte'] = oscgteNumber
            draft['osc_lte'] = osclteNumber
            draft['released_from'] = releasedFrom
            draft['released_to'] = releasedTo
            draft['updated_from'] = updatedFrom
            draft['updated_to'] = updatedTo
            //draft['order'] = makeOrderValue(filterSort, sortDirection)
        }))

    }, [
        searchParams,
        categories,
        appName,
        downloadgte,
        downloadlte,
        revenuegte,
        revenuelte,
        paywall,
        oscgte,
        osclte,
        releasedFrom,
        releasedTo,
        updatedFrom,
        updatedTo,
        filterSort,
        sortDirection
    ])

    //console.log('filtersort', filterSort)

    useEffect(() => {
        // make category the default-opened menu from filters sidebar
        // 1 is category's index
        dispatch(activeModalIndexActions.setActiveModalIndexFilterItem(1));
    }, [])

        useEffect(() => {
            setRootSearch(
                <SwitcherContext onClick={handleActiveSearchClick} /> 
            )
            return () => {
                setSearchValue('')
                setRootSearch(<></>)
            }
    }, [query, searchMode])

    useEffect(() => {
        setQuery(searchParams.get('q'))
        dispatch(filterActions.setOcrSelected(searchParams.get('smode') == SEARCH_MODE_OCR));
        setSearchMode(searchParams.get('smode'))
    }, [searchParams, setSearchValue])

    const handleCloseBillingPage = () => {
        dispatch(saveModeActions.deactiveProModal());
      };
    
      const handleOpenBillingPage = () => {
        dispatch(saveModeActions.activeProModal());
    };
    
    useEffect(() => {
        if (me && me.su && searchParams.get('debug') == '1') {
            setDebugMode(true)
        }
    }, [searchParams, me])

    const handleActiveSearchClick = () => {
        dispatch(filterActions.activateFilterMagic());
        setIsSearchBoxOpen(true);
    };

    return (
        <div style={{ 
            position: 'relative',
            height: location.pathname.includes('/ctx') ? '100vh' : '' 
        }}>
            {isActiveMagic && (
                <div className={ExploreAppsStyles.overlay}></div>
            )}

            <Outlet
                context={{
                    filterParams,
                    prevFilters,
                    debugMode,
                    handleOpenBillingPage,
                    handleCloseBillingPage,
                    searchValue,
                    setSearchValue
                }}
            />
        </div>
    )
}

export { SearchRootView }
