import { FormEvent, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRegisterUserMutation } from "@/features/auth/api/authAPI";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { userActions } from "@/entities/user";

const useRegister = (from: string) => {

  const [isRegistering, setIsRegistering] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [registerUser] = useRegisterUserMutation();

  const handleRegister = async (
    username: string,
    email: string,
    password: string,
    passwordConfirm: string,
    inviteKey: string,
    referral: string,
    referralCouponId: string,
    e: FormEvent<HTMLFormElement>,
  ) => {
    e.preventDefault();

    if (!email || !password) {
      toast.error("Please fill in all required fields");
      return;
    }

    setIsRegistering(true);

    // if (password !== passwordConfirm) {
    //   toast.error("Passwords do not match!");
    //   setIsRegistering(false);
    //   return;
    // }

    const userData = {
      email,
      username,
      password,
      password_confirm: passwordConfirm,
      invite: inviteKey,
      success_url: import.meta.env.VITE_SD_CHECKOUT_SUCCESS_URL,
      client_id: import.meta.env.VITE_API_CLIENT_ID,
      referral_id: referral ? referral : undefined,
      referral_coupon: referralCouponId ? referralCouponId : undefined,
    };

    try {
      const resp = await registerUser(userData).unwrap();


      localStorage.setItem("access_token", resp.auth.access_token);


      dispatch(userActions.login());
      dispatch(userActions.setWithoutLoading(false));


      if (!inviteKey) {
        location.href = resp.redirect_url;
      } else {

        navigate("/", { state: { fromRegister: true } });
        window.location.reload(); 
      }

      //toast.success("Registration successful! Please check your email to verify your account.");

      // red: disalbe email verification navigate call
      // navigate("/verify-email", { 
      //   state: { 
      //     email: email,
      //     justRegistered: true
      //   } 
      // });

    } catch (error: unknown) {
      console.error("Registration error:", error);
      if (typeof error === 'object' && error !== null && 'data' in error) {
        const errorData = error.data as {
          non_field_errors?: string[],
          email?: string[],
          username?: string[]
        };

        if (errorData.email && errorData.email.length > 0) {
          toast.error(errorData.email[0]);
        } else if (errorData.non_field_errors && errorData.non_field_errors.length > 0) {
          const errorMessage = errorData.non_field_errors[0];
          if (errorMessage === "Username taken") {
            toast.error("Username is already taken. Please choose a different username.");
          } else if (errorMessage === "Email taken") {
            toast.error("Email is already registered. Please use a different email address.");
          } else {
            toast.error(errorMessage);
          }
        } else {
          toast.error("Registration failed. Please try again.");
        }
      } else {
        toast.error("Registration failed. Please try again.");
      }

    } finally {
      setIsRegistering(false);
    }
  };

  const handleNavigate = () => {
    navigate("/login");
  };

  return { isRegistering, handleRegister, handleNavigate };
};

export default useRegister;
