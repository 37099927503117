import React from "react";
import { IoIosCheckmarkCircle } from "react-icons/io";
import { ResponseSavedGroups } from "@/features/auth/types/auth";
import styles from "./ModalSaveModeGroupsModal.module.scss";

interface ModalSaveModeGroupsModalProps {
  apiDataSavedGroupsFilter: ResponseSavedGroups | undefined;
  filterValueGroup: string;
  handleFilterGroup: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleAddGroup: () => void;
  handleClickGroup: (idGroup: number, screens: string[]) => Promise<void>;
  hasMatchingItems: (groupId: number) => boolean;
}

const ModalSaveModeGroupsModal = React.forwardRef<
  HTMLDivElement,
  ModalSaveModeGroupsModalProps
>(
  (
    {
      apiDataSavedGroupsFilter,
      filterValueGroup,
      handleFilterGroup,
      handleAddGroup,
      handleClickGroup,
      hasMatchingItems
    },
    ref,
  ) => {
    return (
      <div ref={ref} className={styles.root}>
        <div className={styles.groupSaveHeader}>
          {apiDataSavedGroupsFilter &&
            apiDataSavedGroupsFilter?.results.length >= 10 && (
              <div className={styles.groupSaveInput}>
                <>
                  <img
                    src="/img/searchLgGroupIcon.svg"
                    alt="searchLgGtoupImg"
                  />
                  <input
                    value={filterValueGroup}
                    onChange={handleFilterGroup}
                    placeholder="Find a group"
                    type="text"
                  />
                </>
              </div>
            )}
          <div onClick={handleAddGroup} className={styles.groupSaveBtn}>
            <img src="/img/blackIcon/Plus.svg" alt="plusGroupModalImg" />
            <button>
              <span>Add new group</span>
            </button>
          </div>
        </div>
        <div className={styles.line} />
        <div className={styles.groupSaveBottom}>
          {apiDataSavedGroupsFilter?.results.map((item) => {
            const screens = item.saved_points.map((point) => point.screen);
            const screensStore = item.saved_store_screens.map((storeScreen) => {
              return storeScreen.store_screen?.thumbnail || '';
            });

            const allScreens = [...screens, ...screensStore];

            return (
              <div className={styles.groupSaveItem} key={item.id}>
                <p onClick={() => handleClickGroup(item.id, allScreens)}>
                  {item.name}
                </p>
                {hasMatchingItems(item.id) && (
                  <IoIosCheckmarkCircle
                    style={{
                      position: "absolute",
                      top: "50%",
                      right: "-8px",
                      transform: "translateY(-50%)",
                      color: "green",
                      fontSize: "18px",
                      marginLeft: "5px",
                    }}
                  />
                )}
                <div
                  onClick={() => handleClickGroup(item.id, allScreens)}
                  className={styles.arrowToGroup}
                >
                  <img src="/img/whiteIcon/ArrowRight.svg" alt="" />
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  },
);

export default ModalSaveModeGroupsModal;
