import React from "react";
import styles from "./CategoryModalItem.module.scss";

interface CategoryModalItemProps {
  text: string;
  onItemClick: () => void;
  isActive: boolean;
}

const CategoryModalItem: React.FC<CategoryModalItemProps> = ({
  text,
  onItemClick,
  isActive,
}) => {
  return (
    <div
      className={`${styles.root} ${isActive ? styles.activeItem : ""}`}
      onClick={onItemClick}
    >
      <p>{text}</p>
    </div>
  );
};

export default CategoryModalItem;
