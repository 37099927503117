import { useEffect, useState } from "react";
import { useParams, useLocation } from 'react-router-dom';
import { useMe } from '@/Hooks';
import Teams from '@/shared/ui/Teams/Teams';
import styles from "@/widgets/accountArea/ui/AccountArea/AccountArea.module.scss";
import Spinner from "@/shared/ui/Spinner/Spinner";
import { useGetOrganizationsListQuery } from "@/features/auth/api/authAPI";

const TeamsView = () => {
  const { uuid } = useParams();
  const { me, refetchUser } = useMe();
  const { refetch: refetchOrganizations } = useGetOrganizationsListQuery();
  const [selectedOrgId, setSelectedOrgId] = useState<number | null>(null);
  const location = useLocation();

  useEffect(() => {
    if (uuid) {
      setSelectedOrgId(uuid);
    }
  }, [uuid]);

  if (!me || selectedOrgId === null) {
    return <Spinner/>;
  }

  const handleRefetchOrganizations = async () => {
    try {
      await refetchOrganizations();
      await refetchUser();
    } catch (error) {
      console.error('Error refetching organizations:', error);
    }
  };

  const activeMenuItem = location.pathname.includes('/billing') ? 'Billing' : 'Members';
  return (
    <div className={styles.teamsContent}>
      <Teams
        me={me}
        refetchOrganizations={handleRefetchOrganizations}
        selectedOrgId={selectedOrgId}
        initialActiveMenuItem={activeMenuItem}
      />
    </div>
  );
};

export { TeamsView };
