import * as React from "react"
import { Slot } from "@radix-ui/react-slot"
import classNames from "classnames"
import "./button.css" // We'll need to create this CSS file

// Define button variants using string constants instead of Tailwind classes
const VARIANTS = {
  default: "button-default",
  destructive: "button-destructive",
  outline: "button-outline",
  secondary: "button-secondary",
  ghost: "button-ghost",
  link: "button-link",
}

const SIZES = {
  default: "button-size-default",
  sm: "button-size-sm",
  lg: "button-size-lg",
  icon: "button-size-icon",
}

// Create a function that returns the appropriate class names
const buttonVariants = ({ 
  variant = "default", 
  size = "default", 
  className = "" 
}: { 
  variant?: keyof typeof VARIANTS, 
  size?: keyof typeof SIZES, 
  className?: string 
}) => {
  return classNames(
    "button-base",
    VARIANTS[variant],
    SIZES[size],
    className
  )
}

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  asChild?: boolean
  variant?: keyof typeof VARIANTS
  size?: keyof typeof SIZES
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className = "", variant = "default", size = "default", asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : "button"
    return (
      <Comp
        className={buttonVariants({ variant, size, className })}
        ref={ref}
        {...props}
      />
    )
  }
)
Button.displayName = "Button"

export { Button, buttonVariants, VARIANTS, SIZES }
