import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getFilterState } from "@/entities/filter";
import { saveModeActions } from "@/entities/saveMode";
import styles from "./HeaderWithLogin.module.scss";
import { useMe, useSavedMode, useCurrentOrganization } from "@/Hooks";
import { Organization } from "@/types/organization";
import { useSwitchOrganizationMutation, useGetOrganizationsListQuery } from "@/features/auth/api/authAPI";
import ModalMenu from "@/widgets/modalMenu/ui/ModalMenu/ModalMenu";
import ToolsModal from "@/widgets/toolsModal/toolsModal";
import { CloseTools } from "@/widgets/toolsModal/CloseTools";
import { OpenTools } from "@/widgets/toolsModal/OpenTools";
import { getButtonFilter } from "@/entities/buttonFilter";

interface HeaderWithLoginProps { }

const HeaderWithLogin: React.FC<HeaderWithLoginProps> = (
  { 
    filterButtonPlaceHolder, 
    searchBarPlaceHolder 
  }
) => {

    const { savedModeActive } = useSavedMode();
    const { isActiveMagic } = useSelector(getFilterState);
    const { me, loading: isLoadingUser, refetch: refetchUser } = useMe();
    const dispatch = useDispatch();
    const [isBurgerOpen, setIsBurgerOpen] = useState(false);
    const [isOrganizationListOpen, setIsOrganizationListOpen] = useState(false);
    const [selectedOrganization, setSelectedOrganization] =
      useState<Organization | null>(null);
    const [isLogout, setIsLogout] = useState(false);
    const refModal = useRef<HTMLDivElement>(null);

    const [switchOrganization, { isLoading: isSwitchingOrganization }] =
      useSwitchOrganizationMutation();
      
    const { data: organizationsList, isLoading: isLoadingOrganizations } =
      useGetOrganizationsListQuery(undefined, { skip: !me});
    const currentOrg = useCurrentOrganization();
    const [shouldRender, setShouldRender] = useState(false);
    const [isToolsModalOpen, setIsToolsModalOpen] = useState(false);
    const { activeIndex } = useSelector(getButtonFilter)

    const handleToggleList = () => {
      setIsOrganizationListOpen(!isOrganizationListOpen);
    };

    const handleToggleBurger = () => {
      setIsBurgerOpen(!isBurgerOpen);
      setIsOrganizationListOpen(false);
    };
    const handleClose = (event?: React.MouseEvent) => {
      if (event) {
        event.stopPropagation();
      }
      setIsBurgerOpen(false);
    };

    const handleOpenBillingPage = () => {
      dispatch(saveModeActions.activeProModal());
    };

    const handleSelectOrganization = async (org: Organization) => {
      if (org.uuid === me.current_organization?.uuid) {
        setIsOrganizationListOpen(false);
        return;
      }

      try {
        await switchOrganization(org.uuid).unwrap();
        setSelectedOrganization(org);
        setIsOrganizationListOpen(false);
        if (typeof refetchUser === "function") {
          await refetchUser();
        } else {
          console.error("refetchUser is not a function");
        }
        window.location.reload();
      } catch (error) {
        console.error("Failed to switch organization:", error);
      }
    };

    const isSavedModeActive = savedModeActive;

    const imgOrganization = organizationsList?.find((org) => org.uuid === currentOrg?.uuid)?.image;

    const displayImage = (
      <div className={styles.header_organization_container}>
        <div className={styles.header_organization_pro} style={{ gap: '8px' }}>
        {imgOrganization ? (
          <img src={imgOrganization} alt={currentOrg?.name} className={styles.header_organization_container_img} />
        ) : (
          <div className={styles.header_organization_container_img}>{currentOrg?.name?.charAt(0).toUpperCase() || "?"}</div>
        )}
        <p>{currentOrg?.name}</p>
        </div>
        <div className={styles.header_organization_pro}>
        {currentOrg?.is_pro && (
          <div className={styles.header_organization_container_pro_container}>
            <p>PRO</p>
          </div>
        )}
        <img src="/img/blackIcon/DropDown.svg" alt="DropDown" />
        </div>
      </div>
    );

    useEffect(() => {
      if (!me || me.organizations?.length === 0 || currentOrg !== undefined) {
        return;
      }

      const timer = setTimeout(() => {
        if (currentOrg === undefined && me.organizations?.length > 0) {
          const firstOrganization = me.organizations[0];
          handleSelectOrganization(firstOrganization);
        }
      }, 100);

      return () => clearTimeout(timer);
    }, [currentOrg, me?.organizations]);

    useEffect(() => {
      const timer = setTimeout(() => {
        setShouldRender(true);
      }, 1000);

      return () => clearTimeout(timer);
    }, []);

    const handleClickPro = () => {
      dispatch(saveModeActions.activeProModal());
    };

    const handleToolsClick = () => {
      setIsToolsModalOpen(true);
    };

    const handleToolsClose = () => {
      setIsToolsModalOpen(false);
    };

    const currentPath = window.location.pathname;

    const getToolsText = () => {

      if (currentPath.startsWith("/saved-library")) {
        return "Saved Library";
      }

      // if (currentPath === "/") {
      //   return activeIndex === 0 ? "Explore Apps" : "App Store Screens";
      // }

      switch (currentPath) {
        case '/':
          return 'Explore Apps'
        case "/animation-explorer":
          return "Animation explorer";
        case "/web-onboardings":
          return "Web Onboardings";
        case "/store-screenshots/":
          return "App Store Screens";
        default:
          return "Tools";
      }
    };

    const shouldShowTools = () => {
      const allowedPaths = [
        "/",
        "/store-screenshots/",
        "/animation-explorer",
        "/web-onboardings",
        "/saved-library",
      ];
      return (
        allowedPaths.includes(currentPath) ||
        currentPath.startsWith("/saved-library")
      );
    };

    let rootStyleExtra = ""

    if (isActiveMagic) {
      rootStyleExtra = styles.rootOverlay
    }

    useEffect(() => {
      const handleClickOutside = (event: MouseEvent) => {
        if (
          refModal.current &&
          !refModal.current.contains(event.target as Node)
        ) {
          setIsBurgerOpen(false);
        }
      };

      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, []);

    return (
      <>
        <div
          className={`${styles.root} ${rootStyleExtra}`}
        >
          <div className={styles.leftSection}>
            <a className={styles.headerLogo} href="/">
              <img
                className={styles.desktopLogo}
                src="/img/headerLogoMain.svg"
                alt="headerlogoMainImg"
                loading="lazy"
                draggable={false}
              />
              <img
                className={styles.mobileLogo}
                src="/img/LOGOMOBILE.png"
                alt="headerlogoMobileImg"
                loading="lazy"
              />
            </a>

            {shouldShowTools() && (
              <div className={styles.toolsContainer}>
                <div className={styles.toolsWrapper}>
                  {isToolsModalOpen ? (
                    <CloseTools
                      handleCloseTools={handleToolsClose}
                      text={getToolsText()}
                    />
                  ) : (
                    <OpenTools
                      handleOpenTools={handleToolsClick}
                      text={getToolsText()}
                    />
                  )}
                  {isToolsModalOpen && (
                    <div
                      className={styles.modalWrapper}
                      onClick={(e) => e.stopPropagation()}
                    >
                      <ToolsModal
                        onClose={handleToolsClose}
                        currentPath={currentPath}
                      />
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>

          <div className={styles.searchBarPlaceHolder}>
            {searchBarPlaceHolder && (
              <>
                {searchBarPlaceHolder}
              </>
            )}
          </div>

          <div className={styles.topRightWrapper}>
            {!shouldRender ? (
              <>  </>
            ) : me && !isLoadingUser ? (
              <div className={styles.containerHeaderMenu}>
                <div
                  className={`${styles.saveModeContainer} ${isSavedModeActive ? styles.saveModeContainerActive : ""}`}
                >
                  {isSavedModeActive ? (
                    <></>
                  ) : (
                    <div className={styles.saveModeContainer}>
                      {/* <a href="/saved-library">
                        <Tooltip
                          className={styles.tooltipSeved}
                          title="Saved"
                          componentsProps={{
                            tooltip: {
                              sx: {
                                // background:
                                //   "linear-gradient(90deg, rgb(35 97 217), rgb(94 36 217) 100%)",
                                top: '-20px',
                                backgroundColor: "white",
                                color: "#8c8c8c", //"white",
                                fontSize: "14px",
                                padding: "0px 0px",
                                borderRadius: "24px",
                                margin: "-7px 2px 3px !important",
                                whiteSpace: "nowrap",
                              },
                            },
                          }}
                          placement="bottom"
                        >
                          <button
                            className={styles.saveMode}
                            onClick={checkSavedModeClick}
                          >
                            <img
                              className={styles.saveModeIcon}
                              src="/img/blackIcon/Save.svg"
                              alt="saveModeImg"
                              loading="lazy"
                            />
                          </button>
                        </Tooltip>
                      </a> */}
                    </div>
                  )}
                </div>
                <div className={styles.headerMenu}>
                  {filterButtonPlaceHolder && (
                    <>
                      {filterButtonPlaceHolder}
                    </>
                  )}

                  {!me.is_pro && !currentOrg?.is_pro && !isLoadingUser && (
                    <button
                      id="unlockProButton"
                      onClick={handleOpenBillingPage}
                      className={styles.button}
                    >
                      Unlock Pro
                    </button>
                  )}
                  <div className={styles.switchTeam} ref={refModal}>
                    <div className={styles.headerMenuImg}>
                      <div className={styles.container_burger} style={{ border: '1px solid #e9e9e9' }} onClick={handleToggleBurger}>
                        {imgOrganization
                          ? <img src={imgOrganization} alt={currentOrg?.name} className={styles.header_organization_container_img} />
                          : <div className={styles.header_organization_container_img}>{currentOrg?.name?.charAt(0).toUpperCase() || "?"}</div>
                        }
                      </div>
                    </div>
                    {isBurgerOpen && (
                      <div onClick={(e) => e.stopPropagation()}>
                        <ModalMenu
                          setIsLogout={setIsLogout}
                          handleToggleList={handleToggleList}
                          displayImage={displayImage}
                          organizationsList={organizationsList}
                          handleSelectOrganization={handleSelectOrganization}
                          isOrganizationListOpen={isOrganizationListOpen}
                          isLoadingOrganizations={isLoadingOrganizations}
                          onClose={handleClose}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ) : (
              <div className={styles.containerHeaderMenu2} ref={refModal}>
                {filterButtonPlaceHolder && (
                  <>
                    {filterButtonPlaceHolder}
                  </>
                )}
                <div className={styles.flexContainer}>
                  <div className={styles.headerMenu}>
                    <button id="unlockProButton" onClick={handleClickPro}>Unlock Pro</button>
                  </div>

                </div>
                <div className={styles.container_burger} onClick={handleToggleBurger}>
                  {isBurgerOpen &&
                    <img src="/img/blackIcon/Close.svg" alt="open" className={styles.container_burger_x}/>
                  }

                  {!isBurgerOpen &&
                    <img src="/img/blackIcon/Menu.svg" alt="open"  />
                  }
                </div>
                {isBurgerOpen && (
                  <div onClick={(e) => e.stopPropagation()} className={styles.BurgerMenuWrapper}>
                    <ModalMenu
                      setIsLogout={setIsLogout}
                      onClose={handleClose}
                    />
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </>
    );
};

export default HeaderWithLogin;
