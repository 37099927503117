import { saveModeActions } from "@/entities/saveMode";
import BillingPage from "@/pages/BillingPage/BillingPage";
import { lazy, Suspense } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import Modal from "react-modal";
import styles from "./LoginPageWrapper.module.scss";

const LoginPage = lazy(() => import("@/pages/Login/Login"));

const LoginPageWrapper = () => {

  const dispatch = useDispatch();
  const { isActiveProModal } = useSelector((state: boolean) => state.saveMode);

  return (
    <Suspense>
      <LoginPage />
      <Modal
        isOpen={isActiveProModal}
        onRequestClose={() => dispatch(saveModeActions.deactiveProModal())}
        contentLabel="Groups Modal"
        className={styles.modalPayment}
        overlayClassName={styles.overlay}
        shouldCloseOnOverlayClick={false}
      >

        <BillingPage
          isOpen={isActiveProModal}
          onClose={() => dispatch(saveModeActions.deactiveProModal())}
        />

      </Modal>
    </Suspense>
  );
};

export default LoginPageWrapper;
