import React, { useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import styles from "./toolsModal.module.scss";
import { useMe } from '@/Hooks';
import { handleButtonFilter } from "../exploreApps/libs/const/handleButtonFilter";
import { useDispatch, useSelector } from "react-redux";
import { getButtonFilter } from "@/entities/buttonFilter";
import { filterAppsActions } from "@/entities/filterApps/model/slice/filterAppsSlice";
interface ToolsModalProps {
    onClose: () => void;
    currentPath: string;
}

const ToolsModal: React.FC<ToolsModalProps> = ({ onClose, currentPath }) => {
    const refModal = useRef<HTMLDivElement>(null);
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const { activeIndex } = useSelector(getButtonFilter)
    const { me } = useMe();
    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (
                refModal.current &&
                !refModal.current.contains(event.target as Node) &&
                !(event.target as HTMLElement).closest('.toolsButton')
            ) {
                onClose();
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [onClose]);

    const handleExploreApps = (e: React.MouseEvent<HTMLButtonElement>) => {
        //handleButtonFilter(0, dispatch, e)
        
        //todo red: not pretty, needs better solution after
        dispatch(filterAppsActions.resetPageFilter());
        
        location.href = '/'
        //navigate("/")
        onClose();
    };

    const handleAnimationExplorer = () => {
        navigate("/animation-explorer");
        onClose();
    };

    const handleWebOnboarding = () => {
        navigate("/web-onboardings");
        onClose();
    };

    const handleAppStoreScreens = (e: React.MouseEvent<HTMLButtonElement>) => {
        //handleButtonFilter(1, dispatch, e)
        //navigate("/")

        //todo red: not pretty, needs better solution after
        dispatch(filterAppsActions.resetPageFilter());
        
        location.href = "/store-screenshots/"
        //navigate("/store-screenshots/")
        onClose();
    };

    const handleSavedModels = () => {
        navigate({ pathname: "/saved-library" })
        onClose();
    };


    const modalVariants = {
        hidden: {
            opacity: 0,
            x: "-65%",
            y: "-100%",
            scale: 0.8,
        },
        visible: {
            opacity: 1,
            x: "-50%",
            y: "-50%",
            scale: 1,
            transition: {
                type: "spring",
                duration: 0.4,
                bounce: 0.2
            }
        },
        exit: {
            opacity: 0,
            x: "-100%",
            y: "-100%",
            scale: 0.8,
            transition: {
                type: "spring",
                duration: 0.3,
                bounce: 0
            }
        }
    };

    const overlayVariants = {
        hidden: { opacity: 0 },
        visible: {
            opacity: 1,
            transition: { duration: 0.2 }
        },
        exit: {
            opacity: 0,
            transition: { duration: 0.2 }
        }
    };

    return (
        <AnimatePresence mode="wait">
            <motion.div
                className={styles.overlay}
                onClick={onClose}
                variants={overlayVariants}
                initial="hidden"
                animate="visible"
                exit="exit"
                key="k1"
            />
            <motion.div
                key="k2"
                ref={refModal}
                className={styles.toolsModal}
                variants={modalVariants}
                initial="hidden"
                animate="visible"
                exit="exit"
                style={{ position: 'fixed' }}
            >
                <div className={styles.container}>
                    <section className={styles.sectionAppResearch}>
                        <ul className={styles.list}>
                            <li
                                onClick={handleExploreApps}
                                className={currentPath === '/' && activeIndex === 0 ? styles.active : ''}
                            >
                                <div className={styles.imgContainer}>
                                <img src="/img/blackIcon/smart-phone-01.svg" alt="Explore Apps" />
                                </div>
                                <div className={styles.textContainer}>
                                    <h3>Explore Apps</h3>
                                    <p>Discover successful apps starting from their onboarding</p>
                                </div>
                            </li>

                            <li
                                onClick={handleAppStoreScreens}
                                className={currentPath === '/' && activeIndex === 1 ? styles.active : ''}
                            >
                                <div className={styles.imgContainer}>
                                <img src="/img/blackIcon/smart-phone-01-1.svg" alt="Saved Mode" />
                                </div>
                                <div className={styles.textContainer}>
                                    <h3>App Store Screens</h3>
                                    <p>Easily browse app store screenshots</p>
                                </div>
                            </li>
                            <li
                                onClick={handleAnimationExplorer}
                                className={currentPath === '/animation-explorer' ? styles.active : ''}
                            >
                                <div className={styles.imgContainer}>
                                <img src="/img/blackIcon/motion-01.svg" alt="Animation Explorer" />
                                </div>
                                <div className={styles.textContainer}>
                                    <h3>Animation Explorer</h3>
                                    <p>Discover inspiring mobile app animations</p>
                                </div>
                            </li>

                            <li
                                onClick={handleWebOnboarding}
                                className={currentPath === '/web-onboardings' ? styles.active : ''}
                            >  
                                <div className={styles.imgContainer}>
                                <img src="/img/blackIcon/browser.svg" alt="Web Onboardings" />
                                </div>
                                <div className={styles.textContainer}>
                                    <h3>Web Onboardings</h3>
                                    <p>Study effective web signup flows</p>
                                </div>
                            </li>
                        </ul>

                        {me && (
                        <button className={styles.savedModels} onClick={handleSavedModels}>
                            <img src="/img/whiteIcon/Save.svg" alt="Saved Libary" />
                            <span>Saved Library</span>
                        </button>
                        )}
                    </section>
                    <section className={styles.sectionFreeTools}>
                        {/* <h2 className={styles.title}>Free Tools</h2> */}
                        <ul className={styles.listFreeTools}>
                            <li>
                                <a href="/free-tools/review-analyzer/">
                                    <div className={styles.imgContainer}>
                                    <img src="/img/blackIcon/analysis-text-link.svg" alt="Animation Explorer" />
                                </div>
                                    <div className={styles.textContainer}>
                                        <h3>Review Analytics</h3>
                                        <p>Transform app review into actionable insight</p>
                                    </div>
                                </a>
                            </li>
                            <li>
                                <a href="/free-tools/icons-explorer/">
                                    <div className={styles.imgContainer}>
                                    <img src="/img/blackIcon/searching.svg" alt="Animation Explorer" />
                                    </div>
                                    <div className={styles.textContainer}>
                                        <h3>Icon Finder</h3>
                                        <p>Discover the perfect icons for your app</p>
                                    </div>
                                </a>
                            </li>
                            <li>
                                <a href="/free-tools/keywords-finder/">
                                    <div className={styles.imgContainer}>
                                    <img src="/img/blackIcon/search-status.svg" alt="Animation Explorer" />
                                    </div>
                                    <div className={styles.textContainer}>
                                        <h3>Keyword Finder</h3>
                                        <p>Optimize your app's visibility with ASO keywords.</p>
                                    </div>
                                </a>
                            </li>
                            <li>
                                <a href="/free-tools/titles/">
                                    <div className={styles.imgContainer}>
                                    <img src="/img/blackIcon/ai-content-generator-01.svg" alt="Animation Explorer" />
                                    </div>
                                    <div className={styles.textContainer}>
                                        <h3>Magic Title Generator</h3>
                                        <p>Create compelling app titles with AI</p>
                                    </div>
                                </a>
                            </li>
                        </ul>
                    </section>
                </div>
            </motion.div>
        </AnimatePresence>
    );
};

export default ToolsModal;