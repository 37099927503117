import React, { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useResetPasswordConfirmMutation } from "@/features/auth/api/authAPI";
import styles from "./ChangePasswordForm.module.scss";
import { toast } from "react-toastify";
import eyeIcon from "/img/blackIcon/HideCloseEye.svg";
import hideIcon from "/img/blackIcon/Hide.svg";

const ChangePasswordForm = () => {
    const { uid, token } = useParams();
    const navigate = useNavigate();
    const [resetPasswordConfirm] = useResetPasswordConfirmMutation();
    
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        
        if (password !== confirmPassword) {
            toast.error("Passwords don't match");
            return;
        }
        
        if (password.length < 8) {
            toast.error("Password must be at least 8 characters long");
            return;
        }
        
        try {
            await resetPasswordConfirm({
                new_password: password,
                token: token || "",
                uidb64: uid || "",
            }).unwrap();
            
            toast.success("Password reset successfully. Please login. Redirecting to login page in 3 seconds");
            setTimeout(() => {
                navigate("/login");
            }, 3000);
            
        } catch (err: any) {
            const errorMessage = err.data?.non_field_errors?.[0] || "Failed to reset password. Please try again.";
            toast.error(errorMessage);
        }
    };

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const toggleConfirmPasswordVisibility = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };

    return (
        <div className={styles.root}>
            <h3>Enter your new password</h3>
            <form className={styles.form} onSubmit={handleSubmit}>
                <div className={styles.passwordContainer}>
                <input 
                    className={styles.password}
                    type={showPassword ? "text" : "password"}
                    placeholder="Enter new password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                    minLength={8}
                />
                 <img 
                    src={showPassword ? hideIcon : eyeIcon}
                    className={styles.eyeIcon}
                    onClick={togglePasswordVisibility}
                    alt="Toggle password visibility"
                    />
                </div>
                <div className={styles.passwordContainer}>
                <input 
                    className={styles.password}
                    type={showConfirmPassword ? "text" : "password"}
                    placeholder="Confirm new password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    required
                    minLength={8}
                 
                />
                 <img 
                    src={showConfirmPassword ? hideIcon : eyeIcon}
                    className={styles.eyeIcon}
                    onClick={toggleConfirmPasswordVisibility}
                    alt="Toggle password visibility"
                    />
                </div>
                <button type="submit" className={styles.formButton}>
                    Continue
                </button>
            </form>
        </div>
    );
};

export default ChangePasswordForm;