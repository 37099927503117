import React, { useEffect, useState } from 'react'
import { useOutletContext, useNavigate, useSearchParams } from 'react-router-dom'
import Modal from "react-modal";
import axios from 'axios'
import { useDispatch } from "react-redux";
import { produce } from 'immer'
import { filterActions } from "@/entities/filter";
import styles from './NoContext.module.scss';
import { IoIosCheckmarkCircle } from 'react-icons/io';
import Spinner from '@/shared/ui/Spinner/Spinner';
import { useSavedPointsMutation, useDeletePointMutation } from '@/features/auth/api/authAPI';
import { SEARCH_MODE_OCR } from '@/Views/constants'
import { useCurrentOrganization, useMe, useAPI } from '@/Hooks'
import { NoContextItems } from './NoContextItems';
import { toast } from 'react-toastify';
import { RightClickModal } from "@/shared/ui/RightClickModal/RightClickModal";
import { handleOpenVideoPage } from '@/app/libs/const/handleOpenVideoPage';


const NoContext = () => {

  const dispatch = useDispatch();
  const api = useAPI()
  const { filterParams, prevFilters, setSearchValue, handleOpenBillingPage } = useOutletContext() as any
  const [searchParams, setSearchParams] = useSearchParams();
  const { me } = useMe()
  const currentOrganization = useCurrentOrganization()
  const [searchResults, setSearchResults] = useState([])
  const [searchResultsCount, setSearchResultsCount] = useState(0)
  const [searchResultsNext, setSearchResultsNext] = useState(true)
  const [searchResultsLoading, setSearchResultsLoading] = useState(false)
  const [searchResultsPage, setSearchResultsPage] = useState()
  const [requestsMade, setRequestsMade] = useState(0)
  const isOCRSearch = searchParams.get('smode') === SEARCH_MODE_OCR;
  const [selectedItem, setSelectedItem] = useState()
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const [currentImageIndex, setCurrentImageIndex] = useState();
  const [currentAppIndex, setCurrentAppIndex] = useState<number | null>(null);
  const [currentScreenNotBlurredID, setCurrentScreenNotBlurredID] = useState<number | null>(null);
  const [appDataToShow, setAppDataToShow] = useState<any>(null);
  const [transitionDirection, setTransitionDirection] = useState<'next' | 'prev' | null>(null);
  const navigate = useNavigate();
  const [savedPoint] = useSavedPointsMutation();
  const [deletePoint] = useDeletePointMutation();

  const hasFilterParams = (params) => {
    // not empty str & not undefined & not null

    if (!params || params == {}) { return false }

    for (const [key, value] of Object.entries(params)) {
      if (value !== '' && value !== undefined && value !== null) {
        return true
      }
    }

    return false;
  }

  const fetchSearchResults = (startIndex: number, stopIndex: number) => {
    // if (!searchParams.get('q') || !searchParams.get('smode')) { return }

    const page = 1
    setSearchResultsPage(page)
    setSearchResultsLoading(true)
    setSearchResults([])

    const p = {
      page: page,
      ...filterParams,
      //...Object.fromEntries(searchParams)
    }

    api.get('/v1/search/noctx/', { params: p })
      .then((res) => {
        setSearchResultsCount(res.data.count)
        setSearchResultsNext(res.data.next)
        setSearchResults(res.data.results)
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
      })
      .catch((error) => {
        console.error('Search failed:', error);
        toast.error("Search failed");
      })
      .finally(() => {
        setRequestsMade(requestsMade + 1)
        setSearchResultsLoading(false)
      })
  }

  // useEffect(() => {
  //   console.log('searchparams', searchParams.toString())
  //   fetchSearchResults()
  // }, [searchParams])

  // useEffect(() => {
  //   console.log('filterparams', filterParams)
  //   console.log('prevFilters', prevFilters)
  //   if (hasFilterParams(filterParams) || hasFilterParams(prevFilters)) {
  //     fetchSearchResults()
  //   }
  // }, [filterParams, prevFilters])

  useEffect(() => {
    fetchSearchResults()
  }, [filterParams])

  const handleExpandClick = (avs: any, index: number) => {
    setSelectedItem(avs);
    setSelectedImage(avs.screen);
    setCurrentImageIndex(index);
  };

  const closeImageModal = () => {
    setSelectedItem(undefined)
    setSelectedImage(null);
  };

  const handleNextImage = () => {
    if (!searchResults.length) {

      return;
    }

    const nextIndex = (currentImageIndex + 1) % searchResults.length;


    const nextResult = searchResults[nextIndex];
    if (nextResult) {

      setSelectedItem(nextResult);
      setSelectedImage(nextResult.screen);
      setCurrentImageIndex(nextIndex);
    }
  };

  const handlePrevImage = () => {
    if (!searchResults.length) {

      return;
    }

    const prevIndex = (currentImageIndex - 1 + searchResults.length) % searchResults.length;


    const prevResult = searchResults[prevIndex];
    if (prevResult) {

      setSelectedItem(prevResult);
      setSelectedImage(prevResult.screen);
      setCurrentImageIndex(prevIndex);
    }
  };

  const handleAppClick = (app, timestamp: number, appvideoId: number) => {
    handleOpenVideoPage(
      timestamp,
      app.slug,
      "Search no context",
      1,
      app.id,
      appvideoId,
      undefined,
      me?.username || "",
      currentOrganization?.uuid || ""
    );
  };

  const handleRefButton = () => {
    dispatch(filterActions.activateFilterMagic());

    // const isCurrentlyOCR = searchParams.get('smode') === SEARCH_MODE_OCR;
    // const newMode = isCurrentlyOCR ? SEARCH_MODE_VECTOR : SEARCH_MODE_OCR;

    // dispatch(filterActions.setOcrSelected(newMode === SEARCH_MODE_OCR));


    // const newParams = new URLSearchParams(searchParams);
    // newParams.set('smode', newMode);
    // setSearchParams(newParams);
  };

  const handleClickPhone = () => {
    const avs = searchResults[currentImageIndex];
    handleAppClick(avs.app, avs.timestamp, avs.app_video_id);
  };

  const handleSavePhoto = async (avs) => {

    if (me && me.username !== "" && me.email !== "" && currentOrganization) {

      if (avs.is_saved) {
        try {

          await deletePoint(avs.is_saved_pk).unwrap();

          setSearchResults(produce((draft) => {
            const index = draft.findIndex(obj => obj.id == avs.id)
            if (index !== -1) {
              draft[index].is_saved = false
              draft[index].is_saved_pk = null
            }
          }))

          toast.success("Point removed successfully");

        } catch (error) {
          if (axios.isAxiosError(error)) {
            toast.error("Error removing saved point");
          }
        }
      } else {
        try {;
          const resp = await savedPoint({
            app_video: avs.app_video_id,
            ts: avs.timestamp,
            organizationUuid: currentOrganization.uuid,
          }).unwrap();

          setSearchResults(produce((draft) => {
            const index = draft.findIndex(obj => obj.id == avs.id)

            if (index !== -1) {
              draft[index].is_saved = true
              draft[index].is_saved_pk = resp.id
            }
          }))

          toast.success("Point saved successfully");
        } catch (error) {
          if (axios.isAxiosError(error)) {
            toast.error("Error saving point");
          }
        }
      }
    } else {
      localStorage.removeItem("access_token");
      localStorage.setItem("btnName", "save point");
      navigate("/login", { state: { fromLogin: true } });
      window.scrollTo(0, 0);
    }
  };

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (selectedImage) {
        if (event.key === "ArrowRight") {
          handleNextImage();
        } else if (event.key === "ArrowLeft") {
          handlePrevImage();
        }
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [selectedImage, handleNextImage, handlePrevImage]);


  useEffect(() => {

    if (searchResults.length && selectedItem) {

      setSelectedItem(searchResults.find((item) => item.id == selectedItem.id))
    }
  }, [searchResults])


  const [contextMenu, setContextMenu] = useState<{
    x: number;
    y: number;
    imageId: string;
    timestamp: string;
    app_video_id: number;
  } | null>(null);

  const handleContextMenu = (event: React.MouseEvent, imageId: string, timestamp: number, app_video_id: number) => {
    event.preventDefault();
    setContextMenu({
      x: event.clientX,
      y: event.clientY,
      imageId,
      timestamp: timestamp.toString(),
      app_video_id,
    });
  };

  const handleCloseContextMenu = () => {
    setContextMenu(null);
  };

  const handleNextApp = (currentIndex: number) => {
    const nextIndex = currentIndex + 1;
    const nextResult = searchResults[nextIndex];
    setCurrentAppIndex(nextIndex);
    setTransitionDirection('next');
    setAppDataToShow(nextResult.app || null);
  };

  const handlePreviousApp = (currentIndex: number) => {
    const previousIndex = currentIndex - 1;
    const previousResult = searchResults[previousIndex];
    setCurrentAppIndex(previousIndex);
    setTransitionDirection('prev');
    setAppDataToShow(previousResult.app || null);
  };

  return (
    <>
      {!searchResults.length && !searchResultsLoading && requestsMade > 0 &&
        <div className={styles.center}>

          <div className={styles.noMatches}>

            <img src="/img/Illustrations.jpg" width="250px" height="250px" alt="no results" />
            <h2>No Results Found</h2>
            <p>It looks like there are no screens matching your search.<br />
              Try searching for something now</p>

            <button onClick={handleRefButton} className={styles.button}>
              {isOCRSearch ? "Try using smart AI" : "Try searching text on screen"}
            </button>
          </div>

        </div>
      }

        {searchResultsLoading &&
          <div className={styles.spinnerContainer}>
            <Spinner width={150} color="#000" />
          </div>
        }

      <div className={styles.noContextContainerWrapper}>
        <div className={styles.noContextContainer} >
          {searchResults.map((item, index) => (
            <NoContextItems
              key={item.id}
              index={index}
              app={item}
              setSearchResults={setSearchResults}
              onAppClick={handleAppClick}
              handleExpandClick={handleExpandClick}
              handleOpenBillingPage={handleOpenBillingPage}
              setSearchValue={setSearchValue}
              handleContextMenu={handleContextMenu}
              currentAppIndex={currentAppIndex}
              appIndex={index}
              setCurrentAppIndex={setCurrentAppIndex}
              onNextApp={handleNextApp}
              onPreviousApp={handlePreviousApp}
              appDataToShow={appDataToShow}
              transitionDirection={transitionDirection}
              searchResults={searchResults}
              currentScreenNotBlurredID={currentScreenNotBlurredID}
              setCurrentScreenNotBlurredID={setCurrentScreenNotBlurredID}
            />
          ))}
          
          <Modal
            isOpen={!!selectedImage}
            onRequestClose={closeImageModal}
            contentLabel="Image Modal"
            className={styles.modal}
            overlayClassName={styles.overlay}
          >
            <div className={styles.modalContent}>
              <div
                onClick={handleClickPhone}
                onContextMenu={(e) => handleContextMenu(
                  e,
                  selectedItem?.id.toString() || '',
                  Number(selectedItem?.timestamp),
                  selectedItem?.app_video_id
                )}
                className={styles.containerImage}
              >
                <img
                  className={styles.modalContentPhoto}
                  src={selectedImage!}
                  alt="Enlarged pic"
                  loading="lazy"
                />
              </div>
              <div className={styles.bookmarkmodalContainer}>
                <button
                  type="button"
                  onClick={() => handleSavePhoto(selectedItem)}
                  className={styles.bookmarkmodal}
                >
                  {selectedItem && selectedItem.is_saved ? (
                    <IoIosCheckmarkCircle
                      style={{
                        color: "green",
                        fontSize: "18px",
                      }}
                    />
                  ) : (
                    <img
                      className={styles.bookmarkIcon}
                      src="/img/blackIcon/Save.svg"
                      alt="bookmarkImg"
                      loading="lazy"
                    />
                  )}
                </button>
              </div>
              <img
                className={styles.arrowRight}
                src="/img/whiteIcon/ChevronRight.svg"
                alt="arrowRightImg"
                onClick={handleNextImage}
                loading="lazy"
              />
              <img
                className={styles.arrowLeft}
                src="/img/whiteIcon/ChevronLeft.svg"
                alt="arrowLeftImg"
                onClick={handlePrevImage}
                loading="lazy"
              />
            </div>
          </Modal>
          {contextMenu && (
            <RightClickModal
              x={contextMenu.x}
              y={contextMenu.y}
              onClose={handleCloseContextMenu}
              imageUrl={searchResults.find(item => item.id.toString() === contextMenu.imageId)?.screen || ''}
              imageId={contextMenu.imageId || ''}
              handleOpenInNewTab={() => {
                const resultItem = searchResults.find(item => item.id.toString() === contextMenu.imageId);
                if (resultItem) {
                  handleAppClick(resultItem.app, Number(contextMenu.timestamp), resultItem.app_video_id);
                }
              }}
              timestamp={Number(contextMenu.timestamp)}
              imageType="video"
              refetchData={() => {
                const currentItem = searchResults.find(item => item.id.toString() === contextMenu.imageId);
                if (currentItem) {
                  setSearchResults(produce((draft) => {
                    const index = draft.findIndex(obj => obj.id.toString() === contextMenu.imageId);
                    if (index !== -1) {
                      draft[index] = { ...draft[index], is_saved: !draft[index].is_saved };
                    }
                  }));
                }
              }}
              avsID={contextMenu.app_video_id}
            />
          )}
        </div>
      </div>
    </>
  )
}


export { NoContext }
