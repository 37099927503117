// react
import React from "react";
// types
import { ISharedViewsPoint } from "@/features/auth/types/auth";
// styles
import styles from "./ModalSaveModeShare.module.scss";

interface ModalSaveModeShareProps {
  setName: React.Dispatch<React.SetStateAction<string>>;
  item: ISharedViewsPoint;
  handleClickPhone: (
    timestamp: number,
    blank: boolean | undefined,
    name: string,
  ) => void;
  selectActive: boolean;
  selectedPhotos: Set<{
    id: number;
    url: string;
    videoId: number;
  }>;
  handlePhotoSelect: (id: number, url: string, videoId: number) => void;
  openModal: (
    image: string,
    name: string,
    ts: number,
    id: number,
    idVideo: number,
    type: string,
    imageStoreId: number,
    idStore?: number,
    appId?: number,
    slug?: string,
  ) => void;
}

const ModalSaveModeShare: React.FC<ModalSaveModeShareProps> = ({
  setName,
  item,
  handleClickPhone,
  selectActive,
  selectedPhotos,
  handlePhotoSelect,
  openModal,
}) => {
  return (
    <div className={styles.root} onMouseEnter={() => setName(item.app.name)}>
      <div
        onClick={() => {
          setName(item.app.name);
          handleClickPhone(Number(item.ts), true, item.app.name);
        }}
        className={styles.photoItemTitle}
      >
        <img src={item.app.icon} alt="appIcon" loading="lazy" />
        <div className={styles.photoItemContent}>
          <h3>{item.app.name}</h3>
          <p>{item.app.shortname}</p>
        </div>
      </div>
      <div
        className={`${styles.photoContainer} ${
          selectActive &&
          [...selectedPhotos].some((photo) => photo.id === Number(item.id))
            ? styles.selectedPhotoContainer
            : ""
        }`}
      >
        <img
          onClick={
            selectActive
              ? () =>
                  handlePhotoSelect(
                    Number(item.id),
                    item.screen,
                    item.app_video,
                  )
              : () =>
                  openModal(
                    item.screen!,
                    item.app.name,
                    Number(item.ts),
                    Number(item.id),
                    item.app_video,
                    "Screen",
                    item.id,
                  )
          }
          src={item.screen!}
          alt="screen"
          className={`${styles.photo} ${
            selectActive &&
            [...selectedPhotos].some((photo) => photo.id === Number(item.id))
              ? styles.selectedPhoto
              : ""
          }`}
          style={{
            cursor: selectActive ? "pointer" : "zoom-in",
          }}
          loading="lazy"
        />
      </div>
    </div>
  );
};

export default ModalSaveModeShare;
