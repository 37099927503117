import React from 'react';

const CheckMarkToastIcon: React.FC = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path opacity="0.5" d="M12.25 22.25C17.7729 22.25 22.25 17.7729 22.25 12.25C22.25 6.72715 17.7729 2.25 12.25 2.25C6.72715 2.25 2.25 6.72715 2.25 12.25C2.25 17.7729 6.72715 22.25 12.25 22.25Z" fill="url(#paint0_linear_6688_7254)" />
    <path d="M9 12.75L11 15L15 10.5" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <defs>
      <linearGradient id="paint0_linear_6688_7254" x1="2.25" y1="12.25" x2="22.25" y2="12.25" gradientUnits="userSpaceOnUse">
        <stop stopColor="#2361D9" />
        <stop offset="1" stopColor="#5E24D9" />
      </linearGradient>
    </defs>
  </svg>
);

const ErrorToastIcon: React.FC = () => (
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12 22C17.5229 22 22 17.5229 22 12C22 6.47715 17.5229 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5229 6.47715 22 12 22Z" fill="url(#paint0_radial_6701_7043)"/>
    <path d="M14.9168 9.08331L9.0835 14.9166M9.0835 9.08331L14.9168 14.9166" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <defs>
      <radialGradient id="paint0_radial_6701_7043" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(19.9157 3.2499) rotate(132.555) scale(20.7404 28.1866)">
        <stop stopColor="#F98F8F"/>
        <stop offset="1" stopColor="#FF4A4D"/>
      </radialGradient>
    </defs>
</svg>

);

const InfoToastIcon: React.FC = () => (
<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M10 20C15.5229 20 20 15.5229 20 10C20 4.47715 15.5229 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5229 4.47715 20 10 20Z" fill="url(#paint0_radial_6701_7002)" />
  <path d="M10 6V10M10 14H10.01" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  <defs>
    <radialGradient id="paint0_radial_6701_7002" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(17.9157 1.2499) rotate(132.555) scale(20.7404 18.8831)">
      <stop stopColor="#FAE79C" />
      <stop offset="1" stopColor="#F8AB38" />
    </radialGradient>
  </defs>
</svg>
);

const Close: React.FC = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.25">
      <path d="M17 7L7 17M7 7L17 17" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </g>
  </svg>
);

export { CheckMarkToastIcon, InfoToastIcon, ErrorToastIcon, Close };
