import * as React from 'react';
import { Tooltip, styled } from '@mui/material';
import { Instance } from '@popperjs/core';
// features
import { IAppData } from "@/features/auth/types/auth";
// styles
import styles from "./InfoPhoneHomePageHorizontal.module.scss";
import { formatNumber } from '@/shared/lib/constants/formaters';

// Add this styled component
const StyledTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  '& .MuiTooltip-tooltip': {
    background: 'var(--white-color)',
    color: 'var(--dark-title-color)',
    boxShadow: '0 16px 48px 0 rgba(0, 0, 0, 0.22)',
    fontSize: 11,
  },
}));

interface InfoPhoneHomePageHorizontalProps {
  img: string;
  title: string;
  text: string | null | number;
  onClick?: (() => void) | undefined;
  dataApi?: IAppData;
  tooltipText?: string;
}

const InfoPhoneHomePageHorizontal: React.FC<InfoPhoneHomePageHorizontalProps> = ({ img, title, text, onClick, tooltipText }) => {
  const positionRef = React.useRef<{ x: number; y: number }>({
    x: 0,
    y: 0,
  });
  const popperRef = React.useRef<Instance>(null);
  const areaRef = React.useRef<HTMLDivElement>(null);
  const titleRef = React.useRef<HTMLHeadingElement>(null);

  const handleMouseMove = (event: React.MouseEvent) => {
    positionRef.current = { x: event.clientX, y: event.clientY };
    if (popperRef.current != null) {
      popperRef.current.update();
    }
  };

  let displayText = text;

  if (
    (title === "Monthly Installs:" || title === "Monthly Revenue:" ||
      title === "Latest Revenue:" || title === "Latest Downloads:") &&
    (text === 0 || text === null)
  ) {
    displayText = text === null ? null : "<5k";
  } else if (typeof text === 'number' || !isNaN(Number(text))) {
    displayText = formatNumber(text);
  } else if (typeof text === 'string' && text.startsWith('$')) {
    const numericValue = Number(text.replace('$', ''));
    if (!isNaN(numericValue)) {
      displayText = `$${formatNumber(numericValue)}`;
    }
  } else if (title === "Web onboarding page:") {
    displayText = "Open";
  }

  return (
    <StyledTooltip
      title={tooltipText}
      placement="top"
      PopperProps={{
        popperRef,
        anchorEl: {
          getBoundingClientRect: () => {
            const titleRect = titleRef.current?.getBoundingClientRect();
            return new DOMRect(
              positionRef.current.x,
              (titleRect?.y ?? 0) + 6,
              0,
              titleRect?.height ?? 0
            );
          },
        },

      }}
    >
      <div
        style={onClick !== undefined ? { cursor: "pointer" } : {}}
        onClick={onClick}
        className={styles.root}
        ref={areaRef}
        onMouseMove={handleMouseMove}
      >
        <div className={styles.titleContainer}>
          <div className={styles.titleWrapper}>
            <h6 ref={titleRef} className={styles.title}>{title}</h6>
          </div>
          <p>{displayText}</p>
        </div>
      </div>
    </StyledTooltip>
  );
};

export default InfoPhoneHomePageHorizontal;
