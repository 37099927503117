import React from 'react';
import styles from "./RemoveMember.module.scss";

interface RemoveMemberProps {
  member: { id: number; name: string };
  onClose: () => void;
  onConfirm: () => void;
  isOpen: boolean;
}

const RemoveMember: React.FC<RemoveMemberProps> = ({ member, onClose, onConfirm, isOpen }) => (
  isOpen && (
    <div className={styles.overlay}>
      <div className={styles.modal}>
        <div className={styles.close}>
          <img src="/img/gradientIcon/Warning.svg" alt="close" />
        </div>
        <h1 className={styles.title}>Remove Member</h1>
        <p className={styles.text}>Are you sure you want to remove {member.name} from the team?</p>
        <div className={styles.buttonContainer}>
          <button className={styles.cancelButton} onClick={onClose}>Cancel</button>
          <button className={styles.confirmButton} onClick={onConfirm}>Remove</button>
        </div>
      </div>
    </div>
  )
);

export default RemoveMember;
