import { lazy, Suspense } from "react";

const CopyrightViewContent = lazy(() => import("@/Views/CopyrightViewContent"));

const CopyrightView = () => {
  return (
    <Suspense>
      <CopyrightViewContent />
    </Suspense>
  );
};

export { CopyrightView };
