import React from 'react'
import AuthTerms from '@/shared/ui/AuthTerms/AuthTerms'
import LogoHeader from '@/shared/ui/LogoHeader/LogoHeader'
import AuthResetEmail from '@/widgets/authResetEmail/ui/AuthReset/AuthResetEmail'

import styles from './ResetView.module.scss'

const ResetView = () => {
    return (
        <div className={styles.root}>
            <LogoHeader />
        <div className={styles.reset}>
          <AuthResetEmail />
        </div>
        <div className={styles.authTerms}>
          <AuthTerms />
        </div>
      </div>
    )
}

export { ResetView }