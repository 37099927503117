import React from "react";
import styles from "./MenuButtons.module.scss";

interface MenuButtonsProps {
  handleHideClick: () => void;
  handleStarClick: () => void;
  isStarred: boolean;
  isStarClickLoading: boolean;
  showStarredMessage: boolean;
  className?: string;
}

const MenuButtons: React.FC<MenuButtonsProps> = ({
  handleHideClick,
  handleStarClick,
  isStarClickLoading,
  isStarred,
  className,
}) => {

  return (

    <div className={`${styles.menuButtons} ${className}`}>
      <>
        <button
          onClick={handleHideClick}
          className={styles.menuHidden}
        >
          <img className={styles.hide}
            src="/img/blackIcon/HideCloseEye.svg"
            alt="hideIcon"
            loading="eager"
            draggable="false"
            onMouseEnter={(e) => {
              e.currentTarget.src = "/img/blackIcon/Hide.svg";
            }}
            onMouseLeave={(e) => {
              e.currentTarget.src = "/img/blackIcon/HideCloseEye.svg";
            }}
          />
          <p>{isStarred ? "Hide app" : "Hide app"}</p>
          {/* <span className={`${styles.tooltip} ${styles.tooltip1}`}>Hide</span> */}
        </button>
        <button
          onClick={handleStarClick}
          className={
            isStarred
              ? styles.menuStarredActive
              : styles.menuStarred
          }
          disabled={isStarClickLoading}
        >
          <img
            src={isStarred ? "/img/whiteIcon/Star.svg" : "/img/blackIcon/Star.svg"}
            alt="starPhotoImg"
            loading="eager"
            draggable="false"
          />
          <p>{isStarred ? "Favorite app" : "Favorite app"}</p>
          {/* <span className={`${styles.tooltip} ${styles.tooltip2}`}>
            {isStarred ? "Remove" : "Add to"}{" "}
            <span className={styles.tooltipdisplay}>favorites</span>
          </span> */}
        </button>
      </>
    </div>
  );
};

export default MenuButtons;
