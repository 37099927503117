import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./SaveApp.module.scss";
import { useMe } from '@/Hooks';
import { useDeleteHiddenAppsMutation, useHiddenAppsMutation } from "@/features/auth/api/authAPI";
import { Tooltip } from "@mui/material";

interface SaveAppProps {
  onClick: () => void;
  removeStarredApp: () => void;
  isStarred: boolean;
  setTempText: (text: string) => void;
  appId: number;
  isHidden: boolean;
  hiddenAppItemId?: number;
}

const SaveApp: React.FC<SaveAppProps> = ({
  onClick,
  removeStarredApp,
  isStarred,
  setTempText,
  appId,
  isHidden,
  hiddenAppItemId,
}) => {
  const [isAppStarred, setIsAppStarred] = useState(isStarred);
  const [isHiddenClickLoading, setIsHiddenClickLoading] = useState(false);
  const navigate = useNavigate();
  const { me } = useMe();
  const [hideApp] = useHiddenAppsMutation();
  const [deleteHiddenApp] = useDeleteHiddenAppsMutation();

  React.useEffect(() => {
    setIsAppStarred(isStarred);
  }, [isStarred]);

  const handleClick = () => {
    if (isAppStarred) {
      removeStarredApp();
      setTempText("App Unstarred");
      setTimeout(() => {
        setTempText("");
      }, 2000);
    } else {
      onClick();
    }
    setIsAppStarred(!isAppStarred);
    localStorage.setItem("btnName", "star an app");
  };

  const handleHideClick = async () => {
    if (!me) {
      navigate("/login");
      localStorage.setItem("btnName", "hide");
      return;
    }
    setIsHiddenClickLoading(true);
    try {
      await new Promise<void>((resolve) =>
        setTimeout(() => {
          resolve();
        }, 1000)
      );
      if (isHidden) {
        if (hiddenAppItemId) {
          await handleDeleteApp(hiddenAppItemId);
        } else {
          console.error("Hidden app item ID is undefined");
        }
      } else {
        await hideApp({
          app: appId,
        }).unwrap();
      }
    } catch (error) {
      console.error("Error handling hide/unhide app:", error);
    } finally {
      setIsHiddenClickLoading(false);
    }
  };

  const handleDeleteApp = async (hiddenAppItemId: number) => {
    try {
      await deleteHiddenApp(hiddenAppItemId).unwrap();
    } catch (error) {
      console.error("Error deleting hidden app:", error);
    }
  };

  return (
    <div className={styles.content}>
      <Tooltip
        title={isAppStarred ? "Remove from favorites" : "Add to favorites"}
        placement="top"
        componentsProps={{
          tooltip: {
            sx: {
              background: 'linear-gradient(90deg, rgb(35 97 217), rgb(94 36 217) 100%)',
              color: 'white',
              fontSize: '0.875rem',
              padding: '8px 12px',
              borderRadius: '4px',
              margin: '2px 2px 3px !important',
            },
          },
        }}
      >
        <div
          onClick={handleClick}
          className={`${styles.cursor} ${styles.iconWrapper}`}
        >
          {isAppStarred && me ? (
            <img src="/img/whiteIcon/Star.svg" alt="saveAppImg" />
          ) : (
            <img src="/img/blackIcon/Star.svg" alt="saveAppImg" />
          )}
        </div>
      </Tooltip>
      <Tooltip title={isHidden ? "Remove from hidden" : "Add to hidden"} placement="top"
        componentsProps={{
          tooltip: {
            sx: {
              background: 'linear-gradient(90deg, rgb(35 97 217), rgb(94 36 217) 100%)',
              color: 'white',
              fontSize: '0.875rem',
              padding: '8px 12px',
              borderRadius: '4px',
              margin: '2px 2px 3px !important',
            },
          },
        }}>
        <div onClick={handleHideClick}
          className={`${styles.cursor} ${styles.iconWrapper}`}>
          {isHidden ? (
            <img src="/img/blackIcon/Hide.svg" alt="unhideAppImg" style={{ opacity: isHiddenClickLoading ? 0.5 : 1 }} />
          ) : (
            <img
              src="/img/blackIcon/HideCloseEye.svg"
              alt="hideAppImg"
              style={{ opacity: isHiddenClickLoading ? 0.5 : 1 }}
            />
          )}
        </div>
      </Tooltip>

    </div>
  );
};

export default SaveApp;
