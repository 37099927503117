// widgets
import ChangePasswordForm from "../../widgets/changePassword/ChangePasswordForm";
// shared
import LogoHeader from "../../shared/ui/LogoHeader/LogoHeader";
import AuthTerms from "../../shared/ui/AuthTerms/AuthTerms";
// styles
import styles from "./ChangePassword.module.scss";

const ChangePassword = () => {
  return (
    <div className={styles.root}>
      <LogoHeader />
      <div className={styles.changePassword}>
        <ChangePasswordForm/>
      </div>
      <div className={styles.authTerms}>
        <AuthTerms />
      </div>
    </div>
  );
};

export default ChangePassword;
