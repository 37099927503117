// redux
import { Dispatch } from "redux";
// entitites
import { activeModalIndexActions } from "@/entities/activeModalIndex";
import { inputSearchActions } from "@/entities/inputSearch";
import { modalFiltersActions } from "@/entities/modalFilters/model/slice/modalFiltersSlice";

export const handleFocus = (dispatch: Dispatch) => {
  dispatch(activeModalIndexActions.setActiveModalIndexFilterItem(null));
  dispatch(inputSearchActions.activeInputSearch());
  dispatch(modalFiltersActions.closeCategoryModal());
  dispatch(modalFiltersActions.closeMontlyInstallsModal());
  dispatch(modalFiltersActions.closeMontlyRevenueModal());
  dispatch(modalFiltersActions.closePaywallModal());
  dispatch(modalFiltersActions.closeOnboardingStepsModal());
  dispatch(modalFiltersActions.closeContainsWordsModal());
  dispatch(modalFiltersActions.closeLabelledModal());
  dispatch(modalFiltersActions.setIsReleaseDateOpen(false));
  dispatch(modalFiltersActions.setIsUpdateDateOpen(false));
};
