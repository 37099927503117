import React, { useState, useContext } from 'react'

const HiddenStarredModeContext = React.createContext()

const HiddenStarredModeProvider = ({ children }) => {

    const [hiddenStarredModeActive, setHiddenStarredModeActive] = useState(false)

    return (
        <HiddenStarredModeContext.Provider value={{ hiddenStarredModeActive, setHiddenStarredModeActive }}>{children}</HiddenStarredModeContext.Provider>
    )
}


const useHiddenStarredMode = () => {
    const ctx = useContext(HiddenStarredModeContext)
    return ctx
}

export { HiddenStarredModeProvider, useHiddenStarredMode }