// redux
import { ReducersMapObject, configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
// entities
import { modalMenuReducer } from "@/entities/modalMenu";
import { modalMenuPhoneReducer } from "@/entities/modalMenuPhone";
import { commentsReducer } from "@/entities/comments";
import { userReducer } from "@/entities/user";
import { meUserReducer } from "@/entities/me";
import { appsReducer } from "@/entities/apps";
import { modalDataReducer } from "@/entities/modalData";
import { videoReducer } from "@/entities/video/model/slice/videoSlice";
import { videosDataReducer } from "@/entities/videosData";
import { photosDataReducer } from "@/entities/photosData";
import { inputReducer } from "@/entities/input";
import { filterReducer } from "@/entities/filter";
import { buttonFilterReducer } from "@/entities/buttonFilter";
import { idModalReducer } from "@/entities/idModal";
import { filterAppsDataReducer } from "@/entities/filterAppsData";
import { filterAppsReducer } from "@/entities/filterApps";
import { filterInputSearchReducer } from "@/entities/filterInputSearch";
import { zoomReducer } from "@/entities/zoom";
import { appVideoPathReducer } from "@/entities/appVideoPath";
import { additionlInfoModalReducer } from "@/entities/additionInfoModal";
import { sortByCategoriesReducer } from "@/entities/sortByCategories";
import { inputSearchReducer } from "@/entities/inputSearch";
import { activeModalIndexReducer } from "@/entities/activeModalIndex";
import { modalFiltersReducer } from "@/entities/modalFilters/model/slice/modalFiltersSlice";
import { counterReducer } from "@/entities/counter";
import { saveModeReducer } from "@/entities/saveMode";
import { savePositionReducer } from "@/entities/savePosition";
import { locationReducer } from "@/entities/location";
import { billingReducer } from "@/entities/billing";
// shared
import { backendAPI } from "@/shared/api/backendAPI";
import { AuthErrorMiddleware } from "@/shared/api/middleware";
// types
import { StateSchema } from "./StateSchema";

export const createStore = () => {
  const rootReducers: ReducersMapObject<StateSchema> = {
    modalMenu: modalMenuReducer,
    modalMenuPhone: modalMenuPhoneReducer,
    comments: commentsReducer,
    user: userReducer,
    video: videoReducer,
    meUser: meUserReducer,
    apps: appsReducer,
    modalData: modalDataReducer,
    videosData: videosDataReducer,
    photosData: photosDataReducer,
    input: inputReducer,
    filter: filterReducer,
    buttonFilter: buttonFilterReducer,
    idModal: idModalReducer,
    filterApps: filterAppsReducer,
    filterAppsData: filterAppsDataReducer,
    filterInputSearch: filterInputSearchReducer,
    zoom: zoomReducer,
    appVideoPath: appVideoPathReducer,
    additionalInfoModal: additionlInfoModalReducer,
    sortByCategories: sortByCategoriesReducer,
    inputSearch: inputSearchReducer,
    modalFilters: modalFiltersReducer,
    activeModalIndex: activeModalIndexReducer,
    counter: counterReducer,
    saveMode: saveModeReducer,
    savePosition: savePositionReducer,
    location: locationReducer,
    billing: billingReducer,
    [backendAPI.reducerPath]: backendAPI.reducer,
  };

  const store = configureStore({
    reducer: rootReducers,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({}).concat(
        backendAPI.middleware,
        AuthErrorMiddleware,
      ),
  });

  setupListeners(store.dispatch);

  return store;
};
