// redux
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
// types
import { ActiveModalIndexState } from "../types/activeModalIndexTypes";

const initialState: ActiveModalIndexState = {
  index: null,
  activeModalIndexFilterItem: null,
  currentImageIndexStore: null,
};

const activeModalIndexSlice = createSlice({
  name: "activeModalIndex",
  initialState,
  reducers: {
    setIndex: (state, action: PayloadAction<number>) => {
      state.index = action.payload;
    },
    setActiveModalIndexFilterItem: (
      state,
      action: PayloadAction<number | null>,
    ) => {
      state.activeModalIndexFilterItem = action.payload;
    },
    setCurrentImageIndexStore: (
      state,
      action: PayloadAction<number | null>,
    ) => {
      state.currentImageIndexStore = action.payload;
    },
  },
});

export const {
  actions: activeModalIndexActions,
  reducer: activeModalIndexReducer,
} = activeModalIndexSlice;
