// redux
import { useDispatch, useSelector } from "react-redux";
// entities
import { sortByCategoriesActions } from "@/entities/sortByCategories";
import { getSortByCategoriesSlice } from "@/entities/sortByCategories/model/selectors/getSortByCategoriesState";
import { filterAppsActions } from "@/entities/filterApps";
import { filterAppsDataActions } from "@/entities/filterAppsData";

const useHandleClick = () => {
  const dispatch = useDispatch();
  const { indexSort } = useSelector(getSortByCategoriesSlice);

  const handleClick = (index: number, text: string, filter: string) => {

    //console.log('click sort index', index)
    //console.log('click sort indexSort', indexSort)
    
    if (indexSort === index) {
      return;
    }

    dispatch(sortByCategoriesActions.setSortBy(text));
    dispatch(sortByCategoriesActions.closeRevenua());
    dispatch(sortByCategoriesActions.setIndexSort(index));
    dispatch(filterAppsDataActions.resetFilterAppDataSortedAll());
    dispatch(filterAppsDataActions.resetFilterAppDataSorted());
    dispatch(filterAppsDataActions.resetFilterAppData());
    dispatch(filterAppsActions.resetPageFilterSidebar());
    dispatch(filterAppsActions.resetPageFilter());

    dispatch(filterAppsActions.setFilterSort(filter));
  };

  return { handleClick, indexSort };
};

export default useHandleClick;
