import React, { useState, useEffect, useRef } from 'react';
import { useMe } from '@/Hooks';
import { useUpdateUserMutation, useGetUserQuery, useChangePasswordMutation } from '@/features/auth/api/authAPI';
import Account from '@/shared/ui/Account/Account';
import Spinner from '@/shared/ui/Spinner/Spinner';
import styles from "@/widgets/accountArea/ui/AccountArea/AccountArea.module.scss";
import { toast } from 'react-toastify';

const AccountView = () => {
  const { me, loading } = useMe();
  const [updateUser, { isLoading: isUpdating }] = useUpdateUserMutation();
  const [formData, setFormData] = useState({
    first_name: '',
    email: '',
    last_name: '',
  });
  const [profilePicture, setProfilePicture] = useState<File | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { refetch: refetchUser } = useGetUserQuery(null);
  const [changePassword] = useChangePasswordMutation();

  useEffect(() => {
    if (me && !formData.email) {
      setFormData({
        first_name: me.first_name || '',
        last_name: me.last_name || '',
        email: me.email || '',
      });
    }
  }, [me]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const handleSave = async () => {
    try {
      await updateUser(formData).unwrap();
      await refetchUser();
      toast.success('Profile updated successfully');
    } catch (error) {
      toast.error('Failed to update profile');
      setFormData({
        first_name: me?.first_name || '',
        last_name: me?.last_name || '',
        email: me?.email || '',
      });
    }
  };

  const handleUploadClick = () => {
    fileInputRef.current?.click();
  };

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      setProfilePicture(file);
      
      try {
        const pictureFormData = new FormData();
        pictureFormData.append('profile_picture', file);
        await updateUser(pictureFormData).unwrap();
        await refetchUser();
        toast.success('Profile picture updated successfully');
      } catch (error) {
        toast.error('Failed to update profile picture');
        setProfilePicture(null);
      }
    }
  };

  const handleChangePassword = async (passwords: { 
    currentPassword: string; 
    newPassword: string; 
    confirmPassword: string; 
  }) => {
    try {
      await changePassword({
        password_current: passwords.currentPassword,
        password: passwords.newPassword,
        password_confirm: passwords.confirmPassword
      }).unwrap();
      toast.success('Password changed successfully');
    } catch (error: any) {
      if (error?.data?.password_current?.[0]) {
        toast.error(error.data.password_current[0]);
      }
      else if (error?.data?.non_field_errors?.[0]) {
        toast.error(error.data.non_field_errors[0]);
      }
      else {
        toast.error('Failed to change password');
      }
    }
  };

  if (loading || isUpdating) {
    return (
      <div className={styles.spinner}>
        <Spinner />
      </div>
    );
  }

  return (
    <div>
      <Account
        me={me}
        formData={formData}
        profilePicture={profilePicture}
        fileInputRef={fileInputRef}
        handleChange={handleChange}
        handleUploadClick={handleUploadClick}
        handleSave={handleSave}
        isUpdating={isUpdating}
        handleFileChange={handleFileChange}
        handleChangePassword={handleChangePassword}
      />
    </div>
  );
};

export { AccountView };
