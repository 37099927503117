import { lazy, Suspense } from "react";

const PolicyViewContent = lazy(() => import("@/Views/PolicyViewContent"));

const PolicyView = () => {
  return (
    <Suspense>
      <PolicyViewContent />
    </Suspense>
  );
};

export { PolicyView }
