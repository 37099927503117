import { lazy, Suspense } from "react";

const TermsViewContent = lazy(() => import("@/Views/TermsViewContent"));

const TermsView = () => {
  return (
    <Suspense>
      <TermsViewContent />
    </Suspense>
  );
};

export { TermsView };
