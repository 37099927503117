// redux
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
// types
import { filterState } from "../types/filterTypes";

const initialState: filterState = {
  isActive: false,
  isActiveMagic: false,
  inputIsActive: false,
  categoryIsActive: false,
  paywallIsActive: false,
  isOcrSelected: false,
  isContextActive: true, 
};

const filterSlice = createSlice({
  name: "filter",
  initialState,
  reducers: {
    activateFilter: (state) => {
      state.isActive = true;
    },
    deactivateFilter: (state) => {
      state.isActive = false;
    },
    toggleFilter: (state) => {
      state.isActive = !state.isActive;
    },
    activateFilterMagic: (state) => {
      state.isActiveMagic = true;
    },
    deactivateFilterMagic: (state) => {
      state.isActiveMagic = false;
    },
    toggleFilterMagic: (state) => {
      state.isActiveMagic = !state.isActiveMagic;
    },
    activateInput: (state) => {
      state.inputIsActive = true;
    },
    deactivateInput: (state) => {
      state.inputIsActive = false;
    },
    toggleInput: (state) => {
      state.inputIsActive = !state.inputIsActive;
    },
    activateCategory: (state) => {
      state.categoryIsActive = true;
    },
    deactivateCategory: (state) => {
      state.categoryIsActive = false;
    },
    toggleCategory: (state) => {
      state.categoryIsActive = !state.categoryIsActive;
    },
    activatePaywall: (state) => {
      state.paywallIsActive = true;
    },
    deactivatePaywall: (state) => {
      state.paywallIsActive = false;
    },
    togglePaywall: (state) => {
      state.paywallIsActive = !state.paywallIsActive;
    },
    setOcrSelected: (state, action: PayloadAction<boolean>) => {
      state.isOcrSelected = action.payload;
    },
    setContextActive: (state, action: PayloadAction<boolean>) => {
      state.isContextActive = action.payload;
    },
  },
});

export const { actions: filterActions, reducer: filterReducer } = filterSlice;
