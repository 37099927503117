import React, { useState, useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { Organization } from '@/features/auth/types/auth';
import styles from "./AccountList.module.scss";
import CreateNewTeam from '../CreateNewTeam/CreateNewTeam';
import { useGetOrganizationsListQuery } from '@/features/auth/api/authAPI';
import Spinner from "@/shared/ui/Spinner/Spinner";
import { SidebarContext } from '@/Views/AccountTeamsView/AccountTeamsRootView';
import mixpanel from '@/shared/lib/mixpanel';
import { useCurrentOrganization } from '@/Hooks';

interface AccountListProps {
  activeTab: string;
  handleTeamsClick: () => void;
  me?: { email?: string };
  selectedOrgId: number | null;
  handleOrgClick: (orgId: string) => void;
  handleAccountClick: () => void;
  onCreateNewTeam: () => Promise<void>;
}

const AccountList: React.FC<AccountListProps> = ({
  activeTab,
  handleTeamsClick,
  me,
  selectedOrgId,
  handleOrgClick,
  handleAccountClick,
  onCreateNewTeam
}) => {
  const [isCreateNewTeamOpen, setIsCreateNewTeamOpen] = useState(false);
  const location = useLocation();
  const { data: organizationsList, isLoading: isLoadingOrganizations, refetchOrganizations } = useGetOrganizationsListQuery();

  const { isSidebarOpen, setIsSidebarOpen } = useContext(SidebarContext);
  const currentOrganization = useCurrentOrganization()

  useEffect(() => {
    if (location.state && location.state.openCreateNewTeam) {
      setIsCreateNewTeamOpen(true);
      window.history.replaceState({}, document.title);
    }
  }, [location]);

  const handleCreateNewTeam = () => {
    mixpanel.track('Create new team clicked', {
      UserEmail: me?.email || '',
      OrganizationID: currentOrganization?.uuid || '',
    });
    setIsCreateNewTeamOpen(true);
  };

  const handleCloseSidebar = () => {
    setIsSidebarOpen(false);
  };

  const handleAccountItemClick = () => {
    handleAccountClick();
    handleCloseSidebar();
  };

  const handleTeamsItemClick = () => {
    if (organizationsList && organizationsList.length > 0) {
      const firstOrgId = organizationsList[0].uuid;
      handleOrgClick(firstOrgId);
    }
    handleTeamsClick();
    handleCloseSidebar();
  };

  const handleOrgItemClick = (orgId: string) => {
    handleOrgClick(orgId);
    handleCloseSidebar();
  };

  if (!organizationsList) {
    return <Spinner />;
  }

  return (
    <>
      <div
        className={`${styles.overlay} ${isSidebarOpen ? styles.open : ''}`}
        onClick={handleCloseSidebar}
      />
      <div className={`${styles.sidebar} ${isSidebarOpen ? styles.open : ''}`}>
        <div className={styles.closeButton}>
          <button onClick={() => setIsSidebarOpen(false)}>
            <img width={36} height={36} src="/img/blackIcon/Close.svg" alt="close" />
          </button>
        </div>
        <ul className={styles.sidebarList}>
          <li
            className={`${styles.sidebarItem} ${activeTab === 'account' ? styles.sidebarItemActive : ''}`}
            onClick={handleAccountItemClick}
          >
            <div className={styles.sidebarItemContent} >
              <img src="/img/AccountPage/Profile.svg" alt="Account" />
              <span>Account</span>
            </div>
          </li>
          <li
            className={`${styles.sidebarItem}`}
          >
            <div className={styles.sidebarItemContainer}>
              <div className={`${styles.sidebarItemContent} ${activeTab === 'teams' ? styles.sidebarItemActive : ''}`} onClick={handleTeamsItemClick}>
                <img src="/img/AccountPage/Teams.svg" alt="Teams" />
                <span>Teams</span>
              </div>
              {isLoadingOrganizations ? (
                <div className={styles.loading}><Spinner /></div>
              ) : organizationsList && organizationsList.length > 0 ? (
                <div className={styles.organizationListContainer}>
                  <ul className={styles.organizationList}>
                    {[...organizationsList]
                      .sort((a, b) => {
                        const aUserOrg = me?.organizations?.find(userOrg => userOrg.uuid === a.uuid);
                        const bUserOrg = me?.organizations?.find(userOrg => userOrg.uuid === b.uuid);
                        if (aUserOrg?.is_pro && !bUserOrg?.is_pro) return -1;
                        if (!aUserOrg?.is_pro && bUserOrg?.is_pro) return 1;
                        return 0;
                      })
                      .map((org: Organization) => {
                        const userOrg = me?.organizations?.find(userOrg => userOrg.uuid === org.uuid);
                        if (!userOrg) return null;

                        return (
                          <li
                            key={org.uuid}
                            className={`${styles.organizationItem} ${activeTab === 'teams' && selectedOrgId === org.uuid ? styles.selectedOrg : ''}`}
                            onClick={() => handleOrgItemClick(org.uuid)}
                          >
                            <div className={styles.orgItemContainer}>
                            <div className={styles.orgInitial} style={{ background: 'black' }}>
                              {(userOrg.name || 'Unnamed').charAt(0).toUpperCase()}
                            </div>
                            <div className={styles.accountName} title={userOrg.name || 'Unnamed'}>
                              {userOrg.name || 'Unnamed'}
                            </div>
                            {userOrg.is_pro && <div className={styles.proBadge}>PRO</div>}
                            </div>
                          </li>
                        );
                      })
                      .filter(Boolean)
                    }
                  </ul>
                  <button className={styles.createTeamButton} onClick={handleCreateNewTeam}>Create a new team</button>
                </div>
              ) : (
                <div className={styles.noOrganizations}>No teams found</div>
              )}
            </div>
          </li>
        </ul>
        <CreateNewTeam
          isOpen={isCreateNewTeamOpen}
          onClose={() => {
            setIsCreateNewTeamOpen(false);
            onCreateNewTeam();
          }}
        />
      </div>
    </>
  );
};

export default AccountList;
