import React from 'react';
import AuthForm from './AuthForm';
import './PricingCard.css'; // We'll need to create this CSS file

type PricingCardProps = {
  price: string;
  period: string;
  description: string;
};

const PricingCard: React.FC<PricingCardProps> = ({ price, period, description }) => {
  const handleFormSubmit = (email: string, password: string) => {
    console.log("Form submitted", { email, password });
    // Here you would handle the authentication/payment process
  };

  return (
    <div className="pricing-card">
      <div className="pricing-content">
        <div className="pricing-header">
          <h2 className="pricing-title">Upgrade to pro</h2>
          <div className="pricing-price-container">
            <span className="pricing-price">{price}</span>
            <span className="pricing-period">/{period}</span>
          </div>
          <p className="pricing-description">{description}</p>
        </div>

        <AuthForm onSubmit={handleFormSubmit} />
      </div>
    </div>
  );
};

export default PricingCard;
