import React, { useEffect } from 'react'
import { Navigate, Outlet } from 'react-router-dom'

import {
    useHiddenStarredMode
} from '@/Hooks'
import { useMe } from '@/Hooks/useMe'

const HiddenStarredRootView = () => {

    const { setHiddenStarredModeActive } = useHiddenStarredMode()
    const { me, loading } = useMe()

    useEffect(() => {
        setHiddenStarredModeActive(true)
        return () => {
            setHiddenStarredModeActive(false)
        }
    }, [])

    if (loading) {
        return null 
    }
    if (!me) {
        return <Navigate to="/" replace />
    }

    return (
        <Outlet />
    )
}

export { HiddenStarredRootView }