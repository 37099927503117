import React from "react";
import debounce from "lodash/debounce";
import { useInView } from "react-intersection-observer";
import { useDispatch, useSelector } from "react-redux";
import { VideoControlsProps } from "@/widgets/savePosition/libs/types/VideoControlsProps";
import { CroppedImage } from "@/widgets/croppedImage/ui/CroppedImage/CroppedImage";
import { getVideoState } from "@/entities/video/model/selectors/getVideoState";
import { videoActions } from "@/entities/video/model/slice/videoSlice";
import { getSavePostionState } from "@/entities/savePosition";
import RenderLabels from "@/shared/ui/RenderLabels/RenderLabels";
import styles from "./VideoControls.module.scss";

export const VideoControls: React.FC<VideoControlsProps> = ({
  onSeek,
  apiDataVideosOne,
  inView,
  setMarkers,
  inViewLottie,
  croppedImages,
  apiDataPointsByVideo,
  apiDataVideosLabelsById,
  activeFlowRef,
}) => {
  const [containerWidth, setContainerWidth] = React.useState(0);
  const [isDragging, setIsDragging] = React.useState(false);
  const [startX, setStartX] = React.useState(0);
  const [startTranslateX, setStartTranslateX] = React.useState(0);
  const [wasPlaying, setWasPlaying] = React.useState<boolean>(false);
  const [isUserScrolling, setIsUserScrolling] = React.useState<boolean>(false);
  const progressBarRef = React.useRef<HTMLDivElement>(null);
  const videoItemRef = React.useRef<HTMLImageElement>(null);
  const { currentPlayTime } = useSelector(getVideoState);
  const { isActivePosition } = useSelector(getSavePostionState);
  const accessToken = localStorage.getItem("access_token");
  const dispatch = useDispatch();

  const { ref: refIntersection, inView: inViewIntersection } = useInView({
    threshold: 0,
  });

  React.useEffect(() => {
    if (!isUserScrolling && !inViewIntersection) {
      checkAndScrollIntoView();
    }
  }, [inViewIntersection]);

  React.useEffect(() => {
    if (currentPlayTime >= 0 && currentPlayTime <= 1) {
      scrollToStart();
    }
  }, [currentPlayTime]);

  React.useEffect(() => {
    if (inView) {
      checkAndScrollIntoView();
    }
  }, [inView]);

  // const itemScreen = [...(apiDataVideosScreens?.results || [])].sort(
  //   (a, b) => Number(a.ts) - Number(b.ts)
  // );

  const photoWidth = 48 ;
  const durationSeconds = apiDataVideosOne?.duration_seconds || 0;
  const progressBarWidth = containerWidth;
  const completionPercentage =
    (currentPlayTime / durationSeconds) * progressBarWidth;
  const translateX = Math.min(completionPercentage, progressBarWidth);

  const handleMouseDown = (e: React.MouseEvent) => {
    setIsDragging(true);
    setStartX(e.clientX);
    setStartTranslateX(translateX);
    setWasPlaying(currentPlayTime !== durationSeconds);
    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener(
      "mouseup",
      () => {
        document.removeEventListener("mousemove", handleMouseMove);
      },
      { once: true },
    );

    if (videoItemRef.current) {
      videoItemRef.current.style.transition = "none";
    }

    dispatch(videoActions.setPlaying(false));
  };

  const handleMouseMove = (e: MouseEvent) => {
    if (!isDragging || !progressBarRef.current) return;

    const deltaX = e.clientX - startX;
    const newPosition = Math.min(
      Math.max(startTranslateX + deltaX, 0),
      progressBarWidth,
    );
    const newTime = (newPosition / progressBarWidth) * durationSeconds;

    if (videoItemRef.current) {
      videoItemRef.current.style.transform = `translateX(${newPosition}px)`;
    }

    onSeek(newTime);
    checkAndScrollIntoView();
  };

  React.useEffect(() => {
    if (!inViewLottie) {
      checkAndScrollIntoView();
    }
  }, [inViewLottie]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleMouseUp = () => {
    setIsDragging(false);
    document.removeEventListener("mousemove", handleMouseMove);
    document.removeEventListener("mouseup", handleMouseUp);

    if (videoItemRef.current && progressBarRef.current) {
      videoItemRef.current.style.transition = "transform 0.2s linear";
    }

    if (wasPlaying) {
      dispatch(videoActions.setPlaying(true));
    }

    setWasPlaying(false);
  };

  const handleScrollStart = React.useCallback(() => {
    setIsUserScrolling(true);
  }, []);

  const handleScrollEnd = React.useCallback(
    debounce(() => {
      setIsUserScrolling(false);
    }, 100),
    [],
  );

  const checkAndScrollIntoView = () => {
    if (!videoItemRef.current || !progressBarRef.current) return;

    const elementRect = videoItemRef.current.getBoundingClientRect();
    const parentRect = progressBarRef.current.getBoundingClientRect();

    if (
      elementRect.left < parentRect.left ||
      elementRect.right > parentRect.right
    ) {
      const offset = elementRect.left - parentRect.left;
      progressBarRef.current.scrollLeft += offset;
    }
  };

  const scrollToStart = () => {
    if (progressBarRef.current) {
      progressBarRef.current.scrollLeft = 0;
    }
  };

  React.useEffect(() => {
    const numberOfPhotos = croppedImages!.length;
    const newWidth = numberOfPhotos * photoWidth - 7.5;
    setContainerWidth(newWidth);

  }, [
    apiDataVideosOne?.id,
    // apiDataVideosScreens,
    currentPlayTime,
    // itemScreen,
    accessToken,
    dispatch,
    isActivePosition,
    setMarkers,
  ]);

  React.useEffect(() => {
    const scrollContainer = progressBarRef.current;
    if (!scrollContainer) return;

    scrollContainer.addEventListener("scroll", handleScrollStart);
    scrollContainer.addEventListener("scroll", handleScrollEnd);

    return () => {
      scrollContainer.removeEventListener("scroll", handleScrollStart);
      scrollContainer.removeEventListener("scroll", handleScrollEnd);
    };
  }, [handleScrollStart, handleScrollEnd]);

  const handleContainerClick = async (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();

    const containerRect = e.currentTarget.getBoundingClientRect();
    const clickX = e.clientX - containerRect.left;
    const relativePosition = clickX / containerRect.width;

    const newTime = relativePosition * durationSeconds;

    onSeek(newTime);

    if (activeFlowRef.current) {
      activeFlowRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }

    const newPosition = (newTime / durationSeconds) * progressBarWidth;
    if (videoItemRef.current) {
      videoItemRef.current.style.transform = `translateX(${newPosition}px)`;
      videoItemRef.current.style.transition = "transform 0.2s ease";
    }
  };

  const timestampMarkersToView = apiDataPointsByVideo?.results?.map((marker, index) => (
    <div
      key={`${marker.ts}-${index}`}
      className={styles.marker}
      style={{
        left: `${(Number(marker.ts) / durationSeconds) * progressBarWidth + 7
          }px`,
      }}
    />
  ));

  const photosRef = React.useRef<HTMLDivElement>(null);

  return (
    <div
      className={styles.root}
      ref={progressBarRef}
      style={{ overflowY: progressBarWidth > 1300 ? "hidden" : "visible" }}
    >
      <div
        className={styles.photosContainer}
        style={{
          width: `${progressBarWidth}px`,
        }}
        onClick={handleContainerClick}
      >
        <RenderLabels
          ref={photosRef}
          apiDataVideosLabelsById={apiDataVideosLabelsById}
          photoWidth={photoWidth}
          videoDuration={apiDataVideosOne?.duration_seconds || 0}
        />
        <div
          style={{ transform: `translateX(${translateX}px)` }}
          className={styles.videoItemBox}
          ref={videoItemRef}
          onMouseDown={handleMouseDown}
        >
          <img
            className={styles.videoItem}
            src="/img/videoItemTopIcon.svg"
            alt="videoItemImg"
            ref={refIntersection}
            loading="lazy"
          />
          <div className={styles.videoItemLine} />
        </div>
        <div className={styles.markersContainer}>{timestampMarkersToView}</div>
        <div
          ref={photosRef}
          className={styles.photos}
        >
          {croppedImages!.map((imageData, index) => {
            return (
              <div
                key={`${index}`}
                // onClick={(e) => handleClickOnPhoto(e, index)}
                className={styles.screenContainer}
              >
                <CroppedImage imageData={imageData} photoWidth={photoWidth} />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
