import { useEffect, useState } from "react";
import { useOutletContext } from 'react-router-dom'
import Modal from "react-modal";
import { useDispatch, useSelector } from "react-redux";

import {
  useGetAppReplayQuery,
  useGetSavedAppsQuery
} from "@/features/auth/api/authAPI";
import { getFilterAppsState } from "@/entities/filterApps";
import { getFilterInputSearchState } from "@/entities/filterInputSearch";
import { getSaveModeState, saveModeActions } from "@/entities/saveMode";
import { getFilterState } from "@/entities/filter";
import { getAppState } from "@/entities/apps";
import styles from "./HomeNew.module.scss";
import PaymentSuccessModal from "@/shared/ui/PaymentSuccessModal/PaymentSuccessModal";
import { useCurrentOrganization, useMe } from '@/Hooks'
import mixpanel from "@/shared/lib/mixpanel";
import ExploreAppsNew from "./ExplorAppsNew";
import { filterAppsDataActions } from "@/entities/filterAppsData/model/slice/filterAppsDataSlice";

const HomeNew = () => {

  const currenOrganization = useCurrentOrganization()
  const {
    setRootResult,
    setRootResultCount,
    setRootResultFetching,
    order
  } = useOutletContext();

  const [isPhonePageLoading, setIsPhonePageLoading] = useState(false);
  const [sortDirectionHidStar, setSortDirectionHidStar] = useState("desc");
  const [isSaveScreen, setIsSaveScreen] = useState(false);
  const [lastClickTime, setLastClickTime] = useState(0);
  const {
    filterSortHidStar,
    pageFilter,
    categories,
    paywall,
    isPaywallOnly,
    oscgte,
    osclte,
    only,
    revenuegte,
    revenuelte,
    downloadgte,
    downloadlte,
    revInstallRateGte,
    revInstallRateLte,
    avsTags,
    updatedFrom,
    updatedTo,
    releasedFrom,
    releasedTo,
  } = useSelector(getFilterAppsState);
  const { appName } = useSelector(getFilterInputSearchState);
  const { isActiveProModal } = useSelector(getSaveModeState);
  const { me } = useMe();
  const { statusPayment, amountPlans, line_items, transactionId, description } = useSelector(getAppState);
  const orderHidStar =
    filterSortHidStar !== ""
      ? `${sortDirectionHidStar === "desc" ? "-" : ""}${filterSortHidStar}`
      : "-created_at";
  const downloadgteNumber = downloadgte === "Min" ? "" : downloadgte;
  const downloadlteNumber = downloadlte === "Max" ? "" : downloadlte;
  const revenuegteNumber = revenuegte === "Min" ? "" : revenuegte;
  const revenuelteNumber = revenuelte === "Max" ? "" : revenuelte;
  const revInstallRateGteNumber = revInstallRateGte === "Min" ? "" : revInstallRateGte;
  const revInstallRateLteNumber = revInstallRateLte === "Max" ? "" : revInstallRateLte;
  const oscgteNumber = oscgte === "Min" ? "" : oscgte;
  const osclteNumber = osclte === "Max" ? "" : osclte;
  const [isSuccessPayment, setIsSuccessPayment] = useState(
    statusPayment === "complete" ? true : false,
  );

  useEffect(() => {
    if (statusPayment === "complete") {
      mixpanel.track('Payment successful', {
        UserEmail: me?.email || '',
        OrganizationID: currenOrganization?.uuid || '',
      });
    }
  }, [statusPayment])


  const { isActiveMagic } = useSelector(getFilterState);
  const dispatch = useDispatch();
  const token = localStorage.getItem("access_token");
  const [showLottie, setShowLottie] = useState(true);

  const { data: apiDataApps } = useGetSavedAppsQuery(orderHidStar, {
    skip: !token || !orderHidStar,
  });

  useEffect(() => {
    if (apiDataApps) {
      dispatch(filterAppsDataActions.setSavedApps(apiDataApps.results));
    }
  }, [apiDataApps]);

  const {
    data: apiDataFilter,
    isFetching
  } = useGetAppReplayQuery(
    {
      id: pageFilter,
      name: appName,
      order,
      categories,
      paywall,
      has_paywall: isPaywallOnly,
      oscgteNumber,
      osclteNumber,
      revenuegteNumber,
      revenuelteNumber,
      downloadgteNumber,
      downloadlteNumber,
      revInstallRateGteNumber,
      revInstallRateLteNumber,
      only,
      avsTags,
      updatedFrom,
      updatedTo,
      releasedFrom,
      releasedTo,
    },
    {
      refetchOnMountOrArgChange: true
    },
  );

  useEffect(() => {
      setRootResultFetching(isFetching)
  }, [isFetching])

  useEffect(() => {
    if (apiDataFilter) {
      setRootResult(apiDataFilter)
      setRootResultCount(apiDataFilter.count)

      const nonEmptyFilters = {
        ...(order && { order }),
        ...(categories && { categories }),
        ...(paywall && { paywall }),
        ...(oscgteNumber && { osc_gte: oscgteNumber }),
        ...(osclteNumber && { osc_lte: osclteNumber }),
        ...(revenuegteNumber && { revenue_gte: revenuegteNumber }),
        ...(revenuelteNumber && { revenue_lte: revenuelteNumber }),
        ...(downloadgteNumber && { download_gte: downloadgteNumber }),
        ...(downloadlteNumber && { download_lte: downloadlteNumber }),
        ...(revInstallRateGteNumber && { rev_install_rate_gte: revInstallRateGteNumber }),
        ...(revInstallRateLteNumber && { rev_install_rate_lte: revInstallRateLteNumber }),
        ...(only && { only }),
        ...(avsTags && { avs_tags: avsTags }),
        ...(updatedFrom && { updated_from: updatedFrom }),
        ...(updatedTo && { updated_to: updatedTo }),
        ...(releasedFrom && { released_from: releasedFrom }),
        ...(releasedTo && { released_to: releasedTo }),
      }

      if (Object.keys(nonEmptyFilters).length > 0) {
        mixpanel.track("Filtered View", {
          UserEmail: me?.email || '',
          OrganizationID: currenOrganization?.uuid || '',
          ...(order && { order }),
          ...(categories && { categories }),
          ...(paywall && { paywall }),
          ...(oscgteNumber && { osc_gte: oscgteNumber }),
          ...(osclteNumber && { osc_lte: osclteNumber }),
          ...(revenuegteNumber && { revenue_gte: revenuegteNumber }),
          ...(revenuelteNumber && { revenue_lte: revenuelteNumber }),
          ...(downloadgteNumber && { download_gte: downloadgteNumber }),
          ...(downloadlteNumber && { download_lte: downloadlteNumber }),
          ...(revInstallRateGteNumber && { rev_install_rate_gte: revInstallRateGteNumber }),
          ...(revInstallRateLteNumber && { rev_install_rate_lte: revInstallRateLteNumber }),
          ...(only && { only }),
          ...(avsTags && { avs_tags: avsTags }),
          ...(updatedFrom && { updated_from: updatedFrom }),
          ...(updatedTo && { updated_to: updatedTo }),
          ...(releasedFrom && { released_from: releasedFrom }),
          ...(releasedTo && { released_to: releasedTo }),
        });
      }
    }

  }, [apiDataFilter])

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (showLottie) {
      timeout = setTimeout(() => {
        setShowLottie(false);
      }, 500);
    }
    return () => clearTimeout(timeout);
  }, [showLottie]);

  useEffect(() => {
    let timeout: NodeJS.Timeout;

    if (isSaveScreen) {
      timeout = setTimeout(() => {
        setIsSaveScreen(false);
      }, 2000);
    }

    return () => clearTimeout(timeout);
  }, [lastClickTime]);

  return (
    <>
      <div className={styles.root}>
        <div>
          <ExploreAppsNew
            apiDataFilter={apiDataFilter}
            isFetching={isFetching}
            setIsPhonePageLoading={setIsPhonePageLoading}
            isPhonePageLoading={isPhonePageLoading}
          />
        </div>
      </div>
      {isActiveMagic && <div className={styles.rootOverlay} />}

      <PaymentSuccessModal
        isOpen={isSuccessPayment}
        setIsSuccessPayment={setIsSuccessPayment}
        amountPlans={amountPlans}
        lineItems={line_items}
        transactionId={transactionId}
        description={description}
      />

      <Modal
        isOpen={isActiveProModal}
        onRequestClose={() => dispatch(saveModeActions.deactiveProModal())}
        contentLabel="ps Modal"
        className={styles.modalPayment}
        overlayClassName={styles.overlay}
      >
      </Modal>
    </>
  );
};

export { HomeNew };