import React from "react";
import ReactPlayer from "react-player/lazy";
import { useLocation } from "react-router-dom";
import { useInView } from "react-intersection-observer";
import { useDispatch, useSelector } from "react-redux";
import { getVideoState } from "@/entities/video/model/selectors/getVideoState";
import { videoActions } from "@/entities/video/model/slice/videoSlice";
import { PhoneProps } from "../../libs/types/PhoneProps";
import styles from "./Phone.module.scss";
import { saveModeActions } from "@/entities/saveMode/model/slice/saveModeSlice";

import ContentLoader from "react-content-loader";

const Phone = (
    {
      videoRef,
      videoReady,
      setVideoReady,
      setIsTimestampReady,
      isTimestampReady,
      isModalOpen,
      inViewLottie,
      appDataOne,
      setIsActivePoster,
      apiDataVideosOne,
      userData,
      openModal,
      handleOpenBillingPage,
      currentOrganization,
    }: PhoneProps
  ): JSX.Element => {

    const [hasSeeked, setHasSeeked] = React.useState(false);
    const { playbackRate, playing, currentPlayTime } =
      useSelector(getVideoState);
    const dispatch = useDispatch();
    
    // todo: inview without ref, to be checked & removed
    const { ref: refIntersection, inView } = useInView({ threshold: 1 });
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const timestamp = queryParams.get("ts");
    const [isHovered, setIsHovered] = React.useState(false);
    const accessToken = localStorage.getItem("access_token");

    const handleClickVideo = () => {
      togglePlayPause();
    };

    const togglePlayPause = () => {
      dispatch(videoActions.togglePlayPause());
    };

    const onProgress = (playedSeconds: React.SetStateAction<number>) => {
      dispatch(videoActions.setCurrentPlayTime(Number(playedSeconds)));
    };

    const { videoType } = useSelector(getVideoState);

    React.useEffect(() => {
      if (appDataOne) {
        // todo: never edit dom like this
        document.title = `${appDataOne.name} | app preview video onboarding paywall`;
      }

      const handleKeyPress = (e: KeyboardEvent) => {
        if (e.code === "Space" && videoReady) {
          e.preventDefault();
          e.stopPropagation();
          setIsActivePoster(false);
          handleClickVideo();
        }
      };

      if (inView || videoRef?.current || !isTimestampReady || !inViewLottie) {
        setIsTimestampReady(true);
      }

      if (videoReady && !hasSeeked) {
        if (timestamp) {
          videoRef!.current!.seekTo(Number(timestamp), "seconds");
        } else {
          videoRef!.current!.seekTo(0, "seconds");
        }
        setHasSeeked(true);
      }

      window.addEventListener("keydown", handleKeyPress);

      return () => {
        window.removeEventListener("keydown", handleKeyPress);
      };
    }, [playing, videoReady, hasSeeked, appDataOne]);


    const handleClickPro = () => {
      dispatch(saveModeActions.activeProModal());
    };



    return (
      <>
        {isModalOpen && (
          <div className={styles.modalOverlay}></div>
        )}
        <div className={`${styles.videoContainer} ${isModalOpen ? styles.modalVideoContainer : ""}`}>
          <div className={`${styles.wrapperRadius} ${isModalOpen ? styles.modalWrapperRadius : ""}`} onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}>
            {apiDataVideosOne &&
              apiDataVideosOne?.blur_starts_at <= currentPlayTime &&
              (!userData?.is_pro && !currentOrganization?.is_pro) && !appDataOne.featured && (
                <div
                  className={styles.blurVideoPro}
                >
                  <div className={styles.blurVideoImg}>
                    <img
                      src="/img/unlockProVideo.svg"
                      alt="unlockProItemImg"
                    />
                  </div>
                  <div className={styles.blurVideoContainer}>
                    <h3>Upgrade to Pro for unlimited access </h3>
                    <p>
                      Just $25/month
                    </p>
                    <button
                      id="unlockProButton"
                      onClick={() => {
                        accessToken
                          ? handleOpenBillingPage()
                          : handleClickPro()
                      }}
                    >
                      <p>Unlock Pro</p>
                    </button>
                  </div>
                </div>
              )}
            <ReactPlayer
              onContextMenu={(e)=> e.preventDefault()} // disable ctx menu
              url={apiDataVideosOne?.video}
              controls={isModalOpen || (isHovered && videoType === 2)}
              ref={videoRef}
              
              onProgress={({ playedSeconds }) => onProgress(playedSeconds)}
              progressInterval={10}
              
              
              onPause={() => dispatch(videoActions.pause())}
              onPlay={() => dispatch(videoActions.play())}
              playbackRate={playbackRate}
              playing={playing}
              
              muted={true}
              loop={true}
              
              onReady={() => setVideoReady(true)}
              // onBuffer={() => setVideoReady(false)}
              // onBufferEnd={() => setVideoReady(true)}

              width={!videoReady ? "0px" : "auto"}
              height={!videoReady ? "0px" : "100%"}
              style={{ display: 'block', borderRadius: '24px', zIndex: 16 }}
              className={styles.video}
            />
            {!videoReady && (
              <div className={styles.contentLoaderContainer}>
                <ContentLoader
                  speed={2}
                  width="235px"
                  height="auto"
                  viewBox="0 0 224 485"
                  backgroundColor="#f3f3f3"
                  foregroundColor="#ecebeb"
                >
                  <rect x="0" y="0" rx="7" ry="7" width="100%" height="100%" />
                </ContentLoader>
              </div>
            )}
            {!isModalOpen && videoReady && (
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  openModal();
                }}
                className={`${styles.sharedContainer}`}
              >
                <img
                  src="/img/blackIcon/FullScreen.svg"
                  alt="expandModalIcon"
                  loading="lazy"
                />
              </div>
            )}
          </div>
        </div>
      </>
    );
  }

export default Phone;
