export const data = [
  {
    img: "/img/blackIcon/Grid.svg",
    text: "App Name",
    imgActive: "/img/gradientIcon/Grid.svg",
    filterName: "",
  },
  {
    img: "/img/blackIcon/Categorie.svg",
    text: "Category",
    imgActive: "/img/gradientIcon/Categorie.svg",
    filterName: "",
  },
  {
    img: "/img/blackIcon/Install.svg",
    text: "Monthly Installs",
    imgActive: "/img/gradientIcon/Install.svg",
    filterName: "",
  },
  {
    img: "/img/blackIcon/Revenue.svg",
    text: "Monthly Revenue",
    imgActive: "/img/gradientIcon/Revenue.svg",
    filterName: "",
  },
  {
    img: "/img/blackIcon/PaywallType.svg",
    text: "Paywall Type",
    imgActive: "/img/gradientIcon/PaywallType.svg",
    filterName: "",
  },
  {
    img: "/img/blackIcon/OnboardingStep.svg",
    text: "Onboarding Steps",
    imgActive: "/img/gradientIcon/OnboardingStep.svg",
    filterName: "",
  },
  // {
  //   img: "/img/labelledIcon.svg",
  //   text: "Labelled for you",
  //   imgActive: "/img/labelledIconActive.svg",
  //   filterName: "",
  // },
  {
    img: "/img/blackIcon/Rocket.svg",
    text: "App Release Date",
    imgActive: "/img/gradientIcon/Rocket.svg",
    filterName: "",
  },
  {
    img: "/img/blackIcon/LastUpdated.svg",
    text: "App Last Update",
    imgActive: "/img/gradientIcon/LastUpdated.svg",
    filterName: "",
  },
  {
    img: "/img/blackIcon/Star.svg",
    text: "See only",
    imgActive: "/img/gradientIcon/Star.svg",
    filterName: "",
  },
  {
    img: "/img/blackIcon/MagicWand.svg",
    text: "Flows type",
    imgActive: "/img/gradientIcon/MagicWand.svg",
    filterName: "",
  },
  {
    img: "/img/blackIcon/Revenue.svg",
    text: "Revenue per Install",
    imgActive: "/img/gradientIcon/Revenue.svg",
    filterName: "",
  },
];
