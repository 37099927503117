import React, { useState } from 'react';
import styles from "./MembersList.module.scss";
import RemoveMember from '../RemoveMember/RemoveMember';
import ChangeRole from '../ChangeRole/ChangeRole';
import { useGetOrganizationQuery } from '@/features/auth/api/authAPI';
import { 
  useGetOrganizationInvitesQuery, 
  useDeleteInviteMutation, 
  useUpdateInviteMutation 
} from "@/features/auth/api/authAPI";
import { toast } from "react-toastify";
import { useParams } from 'react-router-dom';
import {  useAPI } from '@/Hooks'

interface Member {
  id: number;
  user: {
    id: number;
    username: string;
    first_name: string;
    last_name: string;
    email: string;
    profile_picture: string;
  };
  role: string;
  created_at: string;
}

interface MembersListProps {
  organizationUsers: Member[];
  onDeleteMembership: (id: number) => Promise<void>;
  onUpdateMembershipRole: (id: number, newRole: string) => Promise<void>;
  userRole: string;
  currentUserId: number;
  organizationUuid: number; 
  handleLeaveOrganization:void
}

const MembersList: React.FC<MembersListProps> = ({
  organizationUsers,
  onDeleteMembership,
  onUpdateMembershipRole,
  userRole,
  currentUserId,
  organizationUuid,
  handleLeaveOrganization
}) => {
  const [removingMember, setRemovingMember] = useState<{ id: number; name: string } | null>(null);
  const [isRemoveModalOpen, setIsRemoveModalOpen] = useState(false);
  const [changingRole, setChangingRole] = useState<{ id: number; currentRole: string; name: string } | null>(null);
  const [isChangeRoleModalOpen, setIsChangeRoleModalOpen] = useState(false);
  const { uuid: Uuid } = useParams();

  const { data: organization } = useGetOrganizationQuery(organizationUuid);
  const api = useAPI()

  const { data: invites, refetch: refetchInvites } = useGetOrganizationInvitesQuery({
    uuid: organizationUuid,
    order: "-created_at",
    page: 1,
    page_size: 10
  });

  const [deleteInvite] = useDeleteInviteMutation();
  const [updateInvite] = useUpdateInviteMutation();

  const handleDeleteInvite = async (inviteId: number) => {
    try {
      await deleteInvite(inviteId).unwrap();
      toast.success("Invite deleted successfully");
      refetchInvites();
    } catch (error) {
      toast.error("Failed to delete invite");
    }
  };

  const handleUpdateInviteRole = async (inviteId: number, newRole: string) => {
    try {
      await updateInvite({ id: inviteId, data: { role: newRole } }).unwrap();
      toast.success("Invite role updated successfully");
      refetchInvites();
    } catch (error) {
      toast.error("Failed to update invite role");
    }
  };

const handleRenewSubscriptionClick = () => {

  api.post(`/v1/organizations/${organizationUuid}/renew/`)
     .then((resp) => {
      location.reload()
     })
     .catch((err) => {
        // todo: show error if there is any
        console.log(err)
     })

}


  return (
    <div className={styles.container}>
      <div>
        {organization?.cancel_at &&
        <div className={styles.annualActive}>
          <div className={styles.annualActiveText}>
            {/* <p className={styles.annualActiveTextTitle}>Team Annual: {organization.has_subscription ? 'Active' : 'Inactive'}</p> */}
            <p className={styles.annualActiveTextSubtitle}>Active until {new Date(organization.cancel_at).toLocaleDateString()}</p>
          </div>
          {userRole === 'owner' &&
            <button className={styles.annualActiveButton} onClick={handleRenewSubscriptionClick}>
              Renew subscription
            </button>
          }
        </div>
        }
      </div>
      <div className={styles.flex}>

        <h1 className={styles.sectionTitle}>
          Members Management  
          <span className={styles.seatCount}>
            ({organizationUsers.length} of {organization?.seats > 1 ? organization.seats : 1})
          </span>
        </h1>
      </div>
      <table className={styles.membersTable}>
        <thead>
          <tr className={styles.tableHeader}>
            <th className={styles.tableHeaderItem}>Username</th>
            <th className={styles.tableHeaderItem}>Fullname</th>
            <th className={styles.tableHeaderItem}>Role</th>
            <th className={styles.tableHeaderItem}>Created at</th>
            <th className={styles.tableHeaderItem}>Actions</th>
          </tr>
        </thead>
        <tbody>
          {organizationUsers.map((member) => (
            <tr key={member.id}>
              <td>{member.user.username}</td>
              <td>{`${member.user.first_name} ${member.user.last_name}`.trim() || 'N/A'}</td>
              <td
                className={`${styles.roleButton} ${userRole === 'owner' && member.role !== 'owner' ? styles.clickable : ''}`}
                onClick={() => {
                  if (userRole === 'owner' && member.role !== 'owner') {
                    setChangingRole({
                      id: member.id,
                      currentRole: member.role,
                      name: `${member.user.first_name} ${member.user.last_name}`.trim() || member.user.username
                    });
                    setIsChangeRoleModalOpen(true);
                  }
                }}
              >
                {member.role}
              </td>
              <td>{new Date(member.created_at).toLocaleDateString()}</td>
              <td>
                {member.user.id === currentUserId ? (
                  !(member.role === 'owner' && organizationUsers.filter(m => m.role === 'owner').length === 1) && (
                    <button onClick={handleLeaveOrganization} className={styles.removeButton}>
                      Leave
                    </button>
                  )
                ) : (userRole === 'owner' || (userRole === 'admin' && member.role !== 'owner')) && (
                  <button
                    className={styles.removeButton}
                    onClick={() => {
                      setRemovingMember({
                        id: member.id,
                        name: `${member.user.first_name} ${member.user.last_name}`.trim() || member.user.username
                      });
                      setIsRemoveModalOpen(true);
                    }}
                  >
                    <img src="/img/AccountPage/Delate.svg" alt="remove" />
                    Remove
                  </button>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {removingMember && (
        <RemoveMember
          member={removingMember}
          onClose={() => {
            setIsRemoveModalOpen(false);
            setRemovingMember(null);
          }}
          onConfirm={() => {
            onDeleteMembership(removingMember.id);
            setIsRemoveModalOpen(false);
            setRemovingMember(null);
          }}
          isOpen={isRemoveModalOpen}
        />
      )}
      {changingRole && (
        <ChangeRole
          member={changingRole}
          onClose={() => {
            setIsChangeRoleModalOpen(false);
            setChangingRole(null);
          }}
          onConfirm={(newRole, newOrgName) => {
            onUpdateMembershipRole(changingRole.id, newRole, newOrgName);
            setIsChangeRoleModalOpen(false);
            setChangingRole(null);
          }}
          isOpen={isChangeRoleModalOpen}
        />
      )}
      
      {(userRole === 'owner' || userRole === 'admin') && (
        <div>
          <h1 className={styles.sectionTitle}>Pending Invites</h1>
          <table className={styles.membersTable}>
            <thead>
              <tr className={styles.tableHeader}>
                <th className={styles.tableHeaderItem}>Email</th>
                <th className={styles.tableHeaderItem}>Role</th>
                <th className={styles.tableHeaderItem}>Created at</th>
                <th className={styles.tableHeaderItem}>Status</th>
                <th className={styles.tableHeaderItem}>Actions</th>
              </tr>
            </thead>
            <tbody>
              {invites?.results.filter(invite => !invite.accepted).map((invite) => (
                <tr key={invite.id}>
                  <td>{invite.email}</td>
                  <td>
                    <select
                      value={invite.role}
                      onChange={(e) => handleUpdateInviteRole(invite.id, e.target.value)}
                      className={styles.roleSelect}
                    >
                      <option value="member">Member</option>
                      <option value="admin">Admin</option>
                    </select>
                  </td>
                  <td>{new Date(invite.created_at).toLocaleDateString()}</td>
                  <td>Pending</td>
                  <td>
                    <button
                      onClick={() => handleDeleteInvite(invite.id)}
                      className={styles.removeButton}
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

    </div>
  );
};

export default MembersList;
