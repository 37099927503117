import { lazy, Suspense } from "react";

const FreeTools = lazy(() => import("@/pages/FreeTools/FreeTools"));

const HomePageWrapper = () => {
  return (
    <Suspense>
      <FreeTools/>
    </Suspense>
  );
};

export default HomePageWrapper;
