// react
import React from "react";
// styles
import { useDispatch } from "react-redux";
import { format } from "date-fns";
import styles from "./ModalYearCalendar.module.scss";
import { filterAppsActions } from "@/entities/filterApps";
import { filterAppsDataActions } from "@/entities/filterAppsData";

interface ModalYearCalendarProps {
  selectedDate: Date | string;
  currentYear: number;
  onDateSelect: (date: Date) => void;
  selectedYear: number;
  setYearCalendar: ((state: boolean) => void) | undefined;
  setUpdate?: (state: string) => void;
}

const ModalYearCalendar: React.FC<ModalYearCalendarProps> = ({
  selectedDate,
  currentYear,
  onDateSelect,
  selectedYear,
  setYearCalendar,
  setUpdate,
}) => {
  const startYear = 2007;
  const endYear = currentYear + 1;
  const years = Array.from(
    { length: endYear - startYear },
    (v, k) => startYear + k,
  );

  const dispatch = useDispatch();

  const handleYearSelect = (year: number) => {
    let newDate: Date;

    if (selectedDate instanceof Date && !isNaN(selectedDate.getTime())) {
      newDate = new Date(selectedDate);
    } else if (
      typeof selectedDate === "string" &&
      !isNaN(Date.parse(selectedDate))
    ) {
      newDate = new Date(selectedDate);
    } else {
      newDate = new Date();
    }

    if (year !== newDate.getFullYear()) {
      newDate.setFullYear(year);
      dispatch(filterAppsDataActions.resetFilterAppDataSorted());
      dispatch(filterAppsDataActions.resetFilterAppDataSortedAll());
      dispatch(filterAppsDataActions.resetFilterAppData());
      dispatch(filterAppsActions.resetPageFilter());
      dispatch(filterAppsActions.resetPageFilterSidebar());
    }

    try {
      const formattedDate = format(newDate, "yyyy-MM-dd");
      setUpdate!(formattedDate);
      onDateSelect(newDate);
    } catch (error) {
      console.error("Error formatting date:", error);
      setUpdate!(year.toString());
      onDateSelect(newDate);
    }

    setYearCalendar!(false);
  };

  return (
    <div className={styles.root}>
      <div className={styles.yearsGridContainer}>
        <div className={styles.yearsGrid}>
          {years.map((year) => (
            <button
              key={year}
              className={`${styles.year} ${
                year === selectedYear ? styles.selectedYear : ""
              }`}
              onClick={() => handleYearSelect(year)}
            >
              {year}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ModalYearCalendar;
