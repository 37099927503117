import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { commentsState } from "../types/commentsTypes";

const initialState: commentsState = {
  comments: [
    {
      text: "Wow, the buttons and icons are so much bigger now! It's like the appgot a comfortable upgrade for my eyes",
      id: Date.now(),
    },
  ],
  commentText: "",
};

const commentsSlice = createSlice({
  name: "comments",
  initialState,
  reducers: {
    addComment: (state, action: PayloadAction<string>) => {
      state.comments.push({
        text: action.payload,
        id: Date.now(),
      });
    },
    setCommentText: (state, action: PayloadAction<string>) => {
      state.commentText = action.payload;
    },
    resetCommenttext: (state) => {
      state.commentText = "";
    },
  },
});

export const { actions: commentsActions, reducer: commentsReducer } =
  commentsSlice;
