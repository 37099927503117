import React, { useEffect, useState, useRef } from 'react';
import styles from './LandingMobile.module.scss'
import ModalMenu from '@/widgets/modalMenu/ui/ModalMenu/ModalMenu';
import { Link, useNavigate } from 'react-router-dom';
import { animate, motion, useMotionValue } from 'framer-motion';
import { styled } from '@mui/material/styles';
import { Switch } from '@mui/material';
import useMeasure from '@/Hooks/useMeasure';

const slider = [
    {
        id: 1,
        image: "/img/landingPage/IconApp1.jpg",
    },
    {
        id: 2,
        image: "/img/landingPage/IconApp2.jpg",
    },
    {
        id: 3,
        image: "/img/landingPage/IconApp3.jpg",
    },
    {
        id: 4,
        image: "/img/landingPage/IconApp4.jpg",
    },
    {
        id: 5,
        image: "/img/landingPage/IconApp5.jpg",
    },
    {
        id: 6,
        image: "/img/landingPage/IconApp6.jpg",
    },
    {
        id: 7,
        image: "/img/landingPage/IconApp7.jpg",
    },
    {
        id: 8,
        image: "/img/landingPage/IconApp8.jpg",
    },
    {
        id: 9,
        image: "/img/landingPage/IconApp9.jpg",
    },
    {
        id: 10,
        image: "/img/landingPage/IconApp10.jpg",
    },
    {
        id: 11,
        image: "/img/landingPage/IconApp11.jpg",
    },
    {
        id: 12,
        image: "/img/landingPage/IconApp12.jpg",
    }
]

const LandingMobile = () => {

    const CustomSwitch = styled(Switch)(({ theme }) => ({
        width: 28,
        height: 16,
        padding: 0,
        '& .MuiSwitch-switchBase': {
            padding: 2,
            '&.Mui-checked': {
                transform: 'translateX(12px)',
                color: '#fff',
                '& + .MuiSwitch-track': {
                    background: '#000',
                    opacity: 1,
                },
            },
        },
        '& .MuiSwitch-thumb': {
            width: 12,
            height: 12,
            backgroundColor: '#fff',
        },
        '& .MuiSwitch-track': {
            borderRadius: 16,
            backgroundColor: '#grey',
            opacity: 0.3,
        },
    }));

    const [activeSwitcher, setActiveSwitcher] = useState("designer");
    const [isBurgerOpen, setIsBurgerOpen] = useState(false);
    const [isLogout, setIsLogout] = useState(false);
    const navigate = useNavigate();
    const tenthSectionRef = useRef<HTMLElement>(null);

    let [ref, { width }] = useMeasure();
    const xTranslation = useMotionValue(0);

    const handleToggleBurger = () => {
        setIsBurgerOpen(!isBurgerOpen);
    }

    const handleClose = () => {
        setIsBurgerOpen(false);
    }

    const scrollToTenthSection = () => {
        if (tenthSectionRef.current) {
            tenthSectionRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }

    const handleCreateAccount = () => {
        navigate('/register');
    }

    const handleVideoClick = (event: React.MouseEvent<HTMLVideoElement>) => {
        const video = event.currentTarget;
        if (video.requestFullscreen) {
            video.requestFullscreen();
        } else if ((video as any).webkitRequestFullscreen) {
            (video as any).webkitRequestFullscreen(); // Safari
        } else if ((video as any).msRequestFullscreen) {
            (video as any).msRequestFullscreen(); // IE11
        }
    };

    useEffect(() => {
        const handleResize = () => {
            const isMobileWidth = window.innerWidth > 550;
            if (isMobileWidth) {
                navigate('/');
            }
        };

        window.addEventListener('resize', handleResize);
        handleResize();

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        let controls;
        let finalPosition = -width / 2;
        controls = animate(xTranslation, [0, finalPosition], {
            ease: "linear", duration: 20, repeat: Infinity, repeatType: "loop", repeatDelay: 0,
        });
        return controls.stop;
    }, [xTranslation, width])

    return (
        <div className={styles.landingMobile}>
            <section className={styles.landingMobile__container__gradient}>
                <div className={styles.landingMobile__header}>
                    <div className={styles.landingMobile__header__logo}>
                        <Link to="/landing-mobile">
                            <img src="/img/headerLogoMain.svg" alt="logo" />
                        </Link>
                    </div>
                    <div className={styles.landingMobile__header__menu}>
                        <div className={styles.container_burger} onClick={handleToggleBurger}>
                            <img src={isBurgerOpen ? "/img/blackIcon/Close.svg" : "/img/blackIcon/Menu.svg"} alt="open" />
                        </div>
                    </div>
                    {isBurgerOpen && <div onClick={e => e.stopPropagation()}><ModalMenu setIsLogout={setIsLogout} ref={null} onClose={handleClose} /></div>}
                </div>
            </section>

            <section className={`${styles.landingMobile__container} ${styles.landingMobile__container__FirstSection}`}>
                <div className={styles.switcher__wrapper}>
                    <span
                        className={`${styles.switcher__label} ${activeSwitcher === "designer" ? "" : styles.inactive}`}
                        onClick={() => setActiveSwitcher("designer")}
                    >
                        Designer
                    </span>
                    <CustomSwitch checked={activeSwitcher === "growth"} onChange={() => setActiveSwitcher(activeSwitcher === "designer" ? "growth" : "designer")} />
                    <span
                        className={`${styles.switcher__label} ${activeSwitcher === "growth" ? "" : styles.inactive}`}
                        onClick={() => setActiveSwitcher("growth")}
                    >
                        Growth team
                    </span>
                </div>
            </section>

            <div className={styles.text__container}>
                <div className={styles.text__container__text}>
                    <h2>
                        {activeSwitcher === "designer"
                            ? "Design with Confidence, Not Guesswork"
                            : "Convert More Users, Backed by Data"
                        }
                    </h2>
                    <h3>
                        {activeSwitcher === "designer"
                            ? "Explore 1500+ real-world app flows that users actually engage with. See what top designers build—beyond dribbble concepts."
                            : "Analyze winning subscription flows from top-grossing apps. Filter by revenue, installs, and onboarding steps to find what actually drives conversion."
                        }
                    </h3>
                </div>

                <button onClick={scrollToTenthSection}>
                    Try Today
                </button>

                <p>Used by 1000s of apps and designers</p>

                <div className={styles.landingMobile__container__FirstSection__Slider}>
                    <motion.div
                        ref={ref}
                        style={{ x: xTranslation, position: 'absolute', left: 0, display: 'flex' }}
                    >
                        {[...slider, ...slider].map((item, index) => (
                            <div
                                key={`${item.id}-${index}`}
                                className={styles.landingMobile__container__FirstSection__Slider__item}
                                style={{ marginRight: '12px' }}
                            >
                                <img src={item.image} alt="image" />
                            </div>
                        ))}
                    </motion.div>
                </div>

                <video
                    src="https://media.screensdesign.com/assets/Landing.mp4"
                    autoPlay
                    playsInline
                    muted
                    loop
                    onClick={handleVideoClick}
                    style={{ cursor: 'pointer' }}
                ></video>

            </div>

            <section className={`${styles.landingMobile__container} ${styles.landingMobile__container__ThirdSection}`}>
                <h2 className={styles.landingMobile__container__Title}>Smart Filters</h2>

                <h3 className={styles.landingMobile__container__Subtitle}>Filter apps by what matters. Revenue, installs, onboarding steps, and more.</h3>

                <video
                    src="https://media.screensdesign.com/assets/SmartFilters.mp4"
                    autoPlay
                    playsInline
                    muted
                    loop
                    onClick={handleVideoClick}
                    style={{ cursor: 'pointer' }}
                ></video>
            </section>

            <section className={`${styles.landingMobile__container} ${styles.landingMobile__container__SecondSection}`}>
                <h2 className={styles.landingMobile__container__Title}>Powerful Search</h2>

                <h3 className={styles.landingMobile__container__Subtitle}>Search 100k+ screens by any element From blue buttons to paywall types - find exactly what you need</h3>

                <video src="https://media.screensdesign.com/assets/PowerfulSearch.mp4"
                    autoPlay
                    muted
                    loop
                    playsInline
                    onClick={handleVideoClick}
                    style={{ cursor: 'pointer' }}></video>

            </section>

            <section className={`${styles.landingMobile__container} ${styles.landingMobile__container__FourthSection}`}>
                <h2 className={styles.landingMobile__container__Title}>Complete App Flow</h2>

                <h3 className={styles.landingMobile__container__Subtitle}>See the full user journey. Understand how successful apps convert users.</h3>

                <video src="https://media.screensdesign.com/assets/CompleteAppFlow.mp4"
                    autoPlay
                    muted
                    loop
                    playsInline
                    onClick={handleVideoClick}
                    style={{ cursor: 'pointer' }}></video>
            </section>

            {/* <section className={`${styles.landingMobile__container} ${styles.landingMobile__container__FifthSection}`}>
                <h2 className={styles.landingMobile__container__Title}>Revenue In Intelligence</h2>

                <h3 className={styles.landingMobile__container__Subtitle}>Real performance metrics. Access revenue data and install metrics.</h3>

                <video src="/video/RevenueInIntelligence.mp4" autoPlay muted loop></video>
            </section> */}

            <section className={`${styles.landingMobile__container} ${styles.landingMobile__container__SixthSection}`}>
                <h2 className={styles.landingMobile__container__Title}>Full App Videos</h2>

                <h3 className={styles.landingMobile__container__Subtitle}>Watch live interaction. Not just screenshots -  see how everything works in real-time</h3>

                <video src="https://media.screensdesign.com/assets/FullAppVideos.mp4"
                    autoPlay
                    muted
                    loop
                    playsInline
                    onClick={handleVideoClick}
                    style={{ cursor: 'pointer' }}></video>
            </section>

            <section className={`${styles.landingMobile__container} ${styles.landingMobile__container__SeventhSection}`}>
                <h2 className={styles.landingMobile__container__Title}>Web Onboardings</h2>

                <h3 className={styles.landingMobile__container__Subtitle}>Cross-platform insights. See how top apps convert on web.</h3>

                <video src="https://media.screensdesign.com/assets/WebOnboardings.mp4"
                    autoPlay
                    muted
                    loop
                    playsInline
                    onClick={handleVideoClick}
                    style={{ cursor: 'pointer' }}></video>
            </section>


            <section className={`${styles.landingMobile__container} ${styles.landingMobile__container__EighthSection}`}>
                <h1>For Founders & growth teams</h1>
                <ul className={styles.landingMobile__container__list}>
                    <li className={styles.landingMobile__container__list__item}>
                        <div>
                            <img src="/img/landingPage/Founders1.svg" alt="" />
                        </div>
                        <p>Proven conversion flows with videos</p>
                    </li>

                    <li className={styles.landingMobile__container__list__item}>
                        <div>
                            <img src="/img/landingPage/Founders2.svg" alt="" />
                        </div>
                        <p>Successful paywall strategies</p>
                    </li>

                    <li className={styles.landingMobile__container__list__item}>
                        <div>
                            <img src="/img/landingPage/Founders3.svg" alt="" />
                        </div>
                        <p>Top apps onboarding secrets</p>
                    </li>

                    <li className={styles.landingMobile__container__list__item}>
                        <div>
                            <img src="/img/landingPage/Founders4.svg" alt="" />
                        </div>
                        <p>Filter by revenue and much more</p>
                    </li>
                </ul>
            </section>



            <section className={`${styles.landingMobile__container} ${styles.landingMobile__container__EighthSection}`}>
                <h1>For designers</h1>
                <ul className={styles.landingMobile__container__list}>
                    <li className={styles.landingMobile__container__list__item}>
                        <div>
                            <img src="/img/landingPage/Designers1.svg" alt="" />
                        </div>
                        <p>100k+ screens, all searchable</p>
                    </li>

                    <li className={styles.landingMobile__container__list__item}>
                        <div>
                            <img src="/img/landingPage/Designers2.svg" alt="" />
                        </div>
                        <p>Real-world implementation examples</p>
                    </li>

                    <li className={styles.landingMobile__container__list__item}>
                        <div>
                            <img src="/img/landingPage/Designers3.svg" alt="" />
                        </div>
                        <p>Ready-to-use design inspiration</p>
                    </li>
                </ul>
            </section>


            <section className={`${styles.landingMobile__container} ${styles.landingMobile__container__NinthSection}`}>
                <h1>Fresh insights weekly</h1>

                <h3 className={styles.landingMobile__container__Subtitle}>
                    20+ new apps added every week.<br />
                    Stay ahead with constantly updated content.
                </h3>

                <ul className={styles.landingMobile__container__NinthSection__list}>
                    <li>
                        <img src="/img/landingPage/IconApp1.jpg" alt="" />
                    </li>
                    <li>
                        <img src="/img/landingPage/IconApp2.jpg" alt="" />
                    </li>
                    <li>
                        <img src="/img/landingPage/IconApp3.jpg" alt="" />
                    </li>
                    <li>
                        <img src="/img/landingPage/IconApp4.jpg" alt="" />
                    </li>
                    <li>
                        <img src="/img/landingPage/IconApp5.jpg" alt="" />
                    </li>
                </ul>
            </section>

            <section className={`${styles.landingMobile__container} ${styles.landingMobile__container__TenthSection}`} ref={tenthSectionRef}>
                <h3 className={styles.landingMobile__container__Subtitle}>
                    Get access to 1500+ top apps & 120K+ searchable screens
                </h3>
                <h1>$25 <br />
                    <span>/per month</span>
                </h1>
                <button onClick={handleCreateAccount}>Get Pro Today</button>

                <ul className={styles.landingMobile__container__TenthSection__list}>
                    <li>
                        <img src="/img/landingPage/mobile.svg" alt="" />
                        <p>Full Access to all Apps</p>
                    </li>
                    <li>
                        <img src="/img/landingPage/analysis-text-link.svg" alt="" />
                        <p>Revenue & Install Metrics</p>
                    </li>
                    <li>
                        <img src="/img/landingPage/video-square.svg" alt="" />
                        <p>Complete App Videos</p>
                    </li>
                    <li>
                        <img src="/img/landingPage/search-status.svg" alt="" />
                        <p>Advanced Search & Filters</p>
                    </li>
                    <li>
                        <img src="/img/landingPage/browser.svg" alt="" />
                        <p>Web Onboardings</p>
                    </li>
                    <li>
                        <img src="/img/landingPage/mobile.svg" alt="" />
                        <p>Weekly New Apps</p>
                    </li>
                    <li>
                        <img src="/img/landingPage/magic-wand.svg" alt="" />
                        <p>All Free Tools</p>
                    </li>
                </ul>
            </section>

        </div>
    )
}

export default LandingMobile;