import React from "react";
import Spinner from "../Spinner/Spinner";
import styles from "./ModalSaveModeSelectedModal.module.scss";

interface SelectedPhoto {
  id: number;
  url: string;
  videoId: number;
}

interface ModalSaveModeSelectedModalProps {
  handleDeselectAll: () => void;
  selectedPhotos: Set<SelectedPhoto>;
  selectedApps: Set<unknown>;
  setAddGroupModal: React.Dispatch<React.SetStateAction<boolean>>;
  addGroupModal: boolean;
  setFilterValueGroup: React.Dispatch<React.SetStateAction<string>>;
  handleDeleteSelectedPhotos: () => Promise<void>;
  isDeleting: boolean;
}

const ModalSaveModeSelectedModal = React.forwardRef<
  HTMLDivElement,
  ModalSaveModeSelectedModalProps
>(
  (
    {
      handleDeselectAll,
      selectedPhotos,
      selectedApps,
      setAddGroupModal,
      addGroupModal,
      setFilterValueGroup,
      handleDeleteSelectedPhotos,
      isDeleting,
    },
    ref,
  ) => {
    const totalSelected = selectedPhotos.size + selectedApps.size;

    const handleAddToGroupClick = () => {
      setAddGroupModal(!addGroupModal);
      setFilterValueGroup("");
    };

    return (
      <div className={styles.root}>
        <div onClick={handleDeselectAll} className={styles.selectedItem}>
          <p>{totalSelected} Selected</p>
          <div className={styles.imageCloseModalSaveContainer}>
            <img src="/img/whiteIcon/Close.svg" alt="Deselect All" />
          </div>
        </div>
        <div className={styles.selectedContainerRight}>
          <div
            onClick={handleAddToGroupClick}
            className={styles.groupSave}
            ref={ref}
          >
            <img src="/img/gradientIcon/Plus.svg" alt="Add to Group" />
            <p>Add to Group</p>
          </div>
          <button onClick={handleDeleteSelectedPhotos}>
            {isDeleting ? (
              <Spinner width="30px" />
            ) : (
              <img src="/img/whiteIcon/Announcement.svg" alt="Delete" />
            )}
          </button>
        </div>
      </div>
    );
  },
);

export default ModalSaveModeSelectedModal;
