// redux
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

const initialState = {
  activeIndex: 0,
  activeView: 0,
};

const buttonFilterSlice = createSlice({
  name: "buttonFilter",
  initialState,
  reducers: {
    setActiveIndex(state, action: PayloadAction<number>) {
      state.activeIndex = action.payload;
    },
    setActiveView(state, action: PayloadAction<number>) {
      state.activeView = action.payload;
    },
    toggleActiveView(state) {
      state.activeView = state.activeView === 0 ? 1 : 0;
    },
  },
});

export const { actions: buttonFilterActions, reducer: buttonFilterReducer } =
  buttonFilterSlice;
