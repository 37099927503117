// react
import React from "react";
// types
import { IItemStoreScreen } from "@/features/auth/types/auth";
// styles
import styles from "./ModalSaveModeShareStore.module.scss";

interface ModalSaveModeShareStoreProps {
  selectCurrentGroupActive: boolean;
  handleCurrentGroupSelect: (id: number, url: string, videoId: number) => void;
  item: IItemStoreScreen;
  openGroupPhotoModal: (
    photo: string,
    photoId: number,
    name: string,
    ts: number,
    imageStoreId: number,
    appId?: number,
    slug?: string,
  ) => void;
  selectedCurrentGroups: Set<{
    id: number;
    url: string;
    videoId: number;
  }>;
}

const ModalSaveModeShareStore: React.FC<ModalSaveModeShareStoreProps> = ({
  selectCurrentGroupActive,
  handleCurrentGroupSelect,
  item,
  openGroupPhotoModal,
  selectedCurrentGroups,
}) => {
  return (
    <div
      className={styles.root}
      onClick={
        selectCurrentGroupActive
          ? () =>
              handleCurrentGroupSelect(
                item.id,
                item.store_screen.thumbnail,
                item.store_screen.id,
              )
          : () =>
              openGroupPhotoModal(
                item.store_screen.thumbnail,
                item.id,
                item.app.name,
                0,
                item.store_screen.id,
                item.app.id,
                item.app.slug,
              )
      }
      style={{
        cursor: selectCurrentGroupActive ? "pointer" : "zoom-in",
      }}
    >
      <div className={styles.groupPhotoHeaderTitle}>
        <img
          src={item.app.icon ? item.app.icon : "/img/skeletonLogo.svg"}
          alt="groupPhotoIcon"
        />
        <div className={styles.groupPhotoTitle}>
          <h3>{item.app.name}</h3>
          <p>{item.app.shortname}</p>
        </div>
      </div>
      <div
        className={`${styles.groupPhotoScreen} ${
          selectCurrentGroupActive &&
          [...selectedCurrentGroups].some(
            (photo) => photo.id === Number(item.id),
          )
            ? styles.selectedGroupPhotoScreen
            : ""
        }`}
      >
        <div>
          <img src={item.store_screen.image} alt="photoStoreImg" />
        </div>
      </div>
    </div>
  );
};

export default ModalSaveModeShareStore;
