// redux
import { useSelector } from "react-redux";
// enitites
import { getSortByCategoriesSlice } from "@/entities/sortByCategories/model/selectors/getSortByCategoriesState";
// shared
import CategoriesDeveloperModalItem from "@/shared/ui/CategoriesDeveloperModalItem/CategoriesDeveloperModalItem";
// hook
import useHandleClick from "../../libs/hook/useHandleClick";
// data
import { dataItem } from "../../libs/data/dataItem";
// styles
import styles from "./CategoriesDeveloperModal.module.scss";

const CategoriesRevenueModal = () => {
  // selectors
  const { indexGroup } = useSelector(getSortByCategoriesSlice);
  // custom hook
  const { handleClick } = useHandleClick();

  return (
    <div className={styles.root}>
      {dataItem.map((item, index) => {
        return (
          <CategoriesDeveloperModalItem
            key={index}
            name={item.text}
            isActive={indexGroup === index}
            onClick={() => handleClick(index, item.text, item.filter)}
          />
        );
      })}
    </div>
  );
};

export default CategoriesRevenueModal;
