import React, { useEffect } from 'react';
import styles from './SwitchToDesktop.module.scss';
import { useNavigate } from 'react-router-dom';

const SwitchToDesktop = () => {

    const navigate = useNavigate();

useEffect(() => {
    const handleResize = () => {
        const isMobileWidth = window.innerWidth > 550;
        if (isMobileWidth) {
            navigate('/');
        }
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
}, []);


    return (
        <div className={styles.switchToDesktop}>
            <div className={styles.switchToDesktop__container}>
                <img className={styles.switchToDesktop__container__img} src="/img/SwitchToDesktop.svg" alt="switchToDesktop" />
                <h1>Switch to Desktop for the Best Experience!</h1>
                <p>Our platform is designed to provide the <strong>best experience on desktop.</strong> Please visit us from <strong>a desktop browser</strong> to access all features and enjoy seamless functionality.</p>
                <div className={styles.switchToDesktop__container__text}>
                    <p>We’re working to optimize for mobile soon. Thank you for your understanding!</p>
                </div>
            </div>
        </div>
    )
}

export default SwitchToDesktop;