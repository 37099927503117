import styles from "./ButtonFilter.module.scss";
import React, { useState, useEffect } from "react";

interface ButtonFilterProps {
  text: string;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  isActive?: boolean;
  height?: string;
  imgActive?: string;
  identifier?: string;
}

const ButtonFilter: React.FC<ButtonFilterProps> = ({
  text,
  onClick,
  isActive,
  identifier
}) => {
  const [width, setWidth] = useState(window.innerWidth <= 750);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth <= 750);
    };

    window.addEventListener('resize', handleResize);
    
    return () => window.removeEventListener('resize', handleResize);
  }, []);  

  
  return (
    <button
      className={`${styles.root} ${isActive ? styles.activeItem : ''}`}
      onClick={onClick}
      data-identifier={identifier}
    >
      {/* {isActive ? (
        <img src={activeImg} alt="buttonImg" loading="eager" />
      ) : (
        <img src={img} alt="buttonImg" loading="eager" />
      )} */}
      <p>{text}</p>
    </button>
  );
};

export default ButtonFilter;
