import styles from "./SuggestAppModal.module.scss";
import { useState } from "react";
import Joi from "joi";
import { toast } from "react-toastify";
import { useSuggestAppMutation } from "@/features/auth/api/authAPI";
import { AppSuggestPayloadData } from "@/features/auth/types/auth";
import SuggestAppAutocomplete from "./SuggestAppAutocomplete";

const schema = Joi.object({
  appName: Joi.string().required().min(1).max(255).messages({
    "string.empty": "App name is required",
    "any.required": "App name is required",
    "string.max": "App name cannot exceed 255 characters",
  }),
  description: Joi.string().required().min(1).max(255).messages({
    "string.empty": "Description is required",
    "any.required": "Description is required",
    "string.max": "Description cannot exceed 255 characters",
  }),
});

const SuggestAppModal = ({ onClose }: { onClose: () => void }) => {
  const [formData, setFormData] = useState<AppSuggestPayloadData>({
    appName: "",
    description: "",
  });
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [suggestApp, { isLoading }] = useSuggestAppMutation();

  const handleSubmit = async () => {
    const { error } = schema.validate(formData, { abortEarly: false });

    if (error) {
      const validationErrors: { [key: string]: string } = {};
      error.details.forEach((detail) => {
        validationErrors[detail.path[0]] = detail.message;
      });
      setErrors(validationErrors);
      return;
    }

    setErrors({});

    try {
      await suggestApp(formData).unwrap();
      onClose();
      toast.success("App has been suggested successfully");
    } catch (error) {
      console.error("Failed to submit app suggestion:", error);
      toast.error("Failed to submit app suggestion");
    }
  };

  const handleInputChange = (
    field: keyof AppSuggestPayloadData,
    value: string,
  ) => {
    setFormData((prev) => ({ ...prev, [field]: value }));
    // Clear error for the field being edited
    if (errors[field]) {
      setErrors((prev) => {
        const newErrors = { ...prev };
        delete newErrors[field];
        return newErrors;
      });
    }
  };

  return (
    <>
      <div onClick={onClose} className={styles.suggestAppModalOverlay}></div>
      <div className={styles.suggestAppModalContainer}>
        <div onClick={onClose} className={styles.suggestAppModalClose}>
          <img src="/img/cancel.svg" alt="Close Icon" />
        </div>
        <div className={styles.suggestAppModalHeader}>
          <img src="/img/message-add.svg" alt="Add Icon" />
        </div>
        <div className={styles.suggestAppModalInputContainer}>
          <h1>Which app should we suggest?</h1>
          <p className={styles.suggestAppModalInputContainerSubtext}>
            Only one app per request.
          </p>
          <SuggestAppAutocomplete
            value={formData.appName}
            onChange={(value) => handleInputChange("appName", value)}
          />
          {errors.appName && (
            <span className={styles.error}>{errors.appName}</span>
          )}
        </div>
        <div className={styles.suggestAppModalTextareaContainer}>
          <h1>What do you like about it?</h1>
          <textarea
            placeholder="Tell us more about the app..."
            rows={6}
            value={formData.description}
            onChange={(e) => handleInputChange("description", e.target.value)}
          />
          {errors.description && (
            <span className={styles.error}>{errors.description}</span>
          )}
        </div>
        <div className={styles.suggestAppModalButtonContainer}>
          <button onClick={handleSubmit} disabled={isLoading}>
            {isLoading ? "Submitting..." : "Submit Request"}
          </button>
        </div>
      </div>
    </>
  );
};

export default SuggestAppModal;
