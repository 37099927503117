import React, { useState } from 'react';
import styles from './WebOnboardingCard.module.scss';
import { handleOpenVideoPage } from '@/app/libs/const/handleOpenVideoPage';
import { RightClickModal } from '../RightClickModal/RightClickModal';

interface WebOnboardingCardProps {
    id: number;
    url: string;
    name: string;
    icon: string;
    shortname: string;
    selectActive: boolean;
    isSelected: boolean;
    onSelect: (id: number, url: string) => void;
    appId: number;
    slug: string;
    timeFrom: number;
    onboardingVideoId: number;
}

export const WebOnboardingCard: React.FC<WebOnboardingCardProps> = ({
    id,
    url,
    name,
    icon,
    shortname,
    selectActive,
    isSelected,
    onSelect,
    appId,
    slug,
    timeFrom,
    onboardingVideoId,
}) => {
    const [rightClickModalVisible, setRightClickModalVisible] = useState(false);
    const [rightClickPosition, setRightClickPosition] = useState({ x: 0, y: 0 });

    const handleClick = (e: React.MouseEvent) => {
        e.stopPropagation();
        if (selectActive && onSelect) {
            onSelect(id, url);
        } else {
            handleOpenVideoPage(timeFrom || 0, slug, "saved-library/onboarding", 2, appId, undefined, onboardingVideoId);
        }
    };

    const handleRightClick = (e: React.MouseEvent) => {
        e.preventDefault();
        setRightClickPosition({ x: e.clientX, y: e.clientY });
        setRightClickModalVisible(true);
    };

    const closeRightClickModal = () => {
        setRightClickModalVisible(false);
    };

    return (
        <div
            className={`${styles.root} ${isSelected ? styles.selectedRoot : ''}`}
            onClick={handleClick}
            onContextMenu={handleRightClick}
        >
            <div
                onClick={(e) => {
                    e.stopPropagation();
                    if (onSelect) {
                        onSelect(id, url);
                    }
                }}
                className={isSelected ? styles.hoverElementActive : styles.hoverElement}
            >
                {isSelected && (
                    <img
                        src="/img/whiteIcon/Check.svg"
                        alt="checkCircleImg"
                    />
                )}
            </div>

            <div className={styles.photoItemTitle}>
                <img src={icon} alt={`${name} icon`} loading="lazy" />
                <div className={styles.photoItemContent}>
                    <h3>{name}</h3>
                    <p>{shortname}</p>
                </div>
            </div>

            <div className={`${styles.photoContainer} ${isSelected ? styles.selectedPhotoContainer : ''}`}>
                <div className={styles.containerPhoto}>
                    <img
                        src={url}
                        alt={`${name} onboarding`}
                        className={`${styles.photo} ${isSelected ? styles.selectedPhoto : ''}`}
                        loading="lazy"
                    />
                </div>
            </div>

            {rightClickModalVisible && (
                <RightClickModal
                    x={rightClickPosition.x}
                    y={rightClickPosition.y}
                    onClose={closeRightClickModal}
                    imageUrl={url}
                    imageId={id}
                    timestamp={timeFrom}
                    isStoreScreen={false}
                    handleOpenInNewTab={() => handleOpenVideoPage(timeFrom || 0, slug, "saved-library/onboarding", 2, appId, undefined, onboardingVideoId)}
                    avsID={onboardingVideoId}
                    refetchData={() => console.log('Refetch data')}
                    imageType="webOnboarding"
                />
            )}
        </div>
    );
};