// react
import React, { ComponentProps } from "react";
// redux
import { useDispatch } from "react-redux";
// entities
import { filterAppsActions } from "@/entities/filterApps";
// styles
import styles from "./FilterModalElement.module.scss";
import { IModal } from "@/widgets/filterModal/types/types";
import { CommonProps } from "@mui/material/OverridableComponent";
import { FilterHandler } from "@/widgets/modalSidebarProcess/libs/types/FilterHandler";
import { ContainsWordsProps } from "../ContainsWords/ContainsWords";
import { PaywallTypeProps } from "@/widgets/paywallType/ui/PaywallType/PaywallType";
import { OnboardingStepsProps } from "@/widgets/onboardingSteps/ui/OnboardingSteps/OnboardingSteps";
import { MontlyRevenueProps } from "@/widgets/montlyRevenue/ui/MontlyRevenue/MontlyRevenue";
import { MontlyInstallsProps } from "@/widgets/montlyInstalls/ui/MontlyInstalls/MontlyInstalls";
import { ICategoryModalProps } from "@/widgets/categoryModal/libs/types/ICategoryModalProps";
import { ReleaseDateModalProps } from "@/widgets/releaseDateModal/ui/ReleaseDateModal/ReleaseDateModal";
import { UpdateDateModalProps } from "@/widgets/updateDateModal/ui/UpdateDateModal/UpdateDateModal";
import { useInView } from "react-intersection-observer";
import { ResponseAvsTags } from "@/features/auth/types/auth";

interface FilterModalElementProps {
  text: string;
  img: string;
  imgActive: string;
  filterName?: string;
  onItemClick: () => void;
  isActive: boolean;
  showIcon: boolean;
  showIconInstalls: boolean;
  showIconRevenue: boolean;
  showIconRevenueMin: boolean;
  showIconOnboarding: boolean;
  showIconOnboardingMin: boolean;
  showIconPaywall: boolean;
  showIconContains: boolean;
  showIconInstallsMin: boolean;
  showIconLabelled: boolean;
  showIconRelease: boolean;
  showIconUpdate: boolean;
  showIconHidStar: boolean;
  showContainsWordFilter: boolean;
  modals: IModal[];
  isNext: boolean;
  rootActiveView: string;
  ref: any;
  apiDataFilter?: any;
  isFetching: boolean;
  showIconFlows: boolean;
}

interface LabelledModalProps {
  appDataTags: ResponseAvsTags;
  activeType: string;
  setActiveType: (state: string) => void;
}

type ComponentProps = CommonProps & {
  onSave?: FilterHandler;
} & Partial<
  ContainsWordsProps &
  PaywallTypeProps &
  OnboardingStepsProps &
  MontlyRevenueProps &
  MontlyInstallsProps &
  ICategoryModalProps &
  LabelledModalProps &
  ReleaseDateModalProps &
  UpdateDateModalProps
>;

const FilterModalElement: React.FC<FilterModalElementProps> = ({
  text,
  img,
  imgActive,
  onItemClick,
  isActive,
  showIcon,
  showIconInstalls,
  showIconRevenue,
  showIconRevenueMin,
  showIconOnboarding,
  showIconOnboardingMin,
  showIconPaywall,
  showIconContains,
  showIconLabelled,
  showIconRelease,
  showIconUpdate,
  showIconInstallsMin,
  showContainsWordFilter,
  showIconHidStar,
  modals,
  isNext,
  rootActiveView,
  apiDataFilter,
  isFetching,
}) => {


  const [lastFetchedTime, setLastFetchedTime] = React.useState(Date.now());
  const dispatch = useDispatch();

  const { ref, inView } = useInView({
    threshold: 0,
  });

  React.useEffect(() => {
    if (inView && isNext && !isFetching && Date.now() - lastFetchedTime > 400) {
      setLastFetchedTime(Date.now());
      dispatch(filterAppsActions.countPageFilter());
    }
  }, [inView, apiDataFilter?.next, isFetching, lastFetchedTime, dispatch, isNext]);

  const handleClick = (e: React.MouseEvent) => {
    if (isActive && (rootActiveView !== 'animation-explorer' && rootActiveView !== 'web-onboardings')) {
      onItemClick();
    } else if (!isActive) {
      onItemClick();
    }
  };

  return (
    <>
      <div
        className={`${styles.root} ${isActive ? styles.activeItem : ""}`}
        onClick={handleClick}
        style={
          text === "App Name" && showContainsWordFilter
            ? { display: "none" }
            : {}
        }
      >
        <div className={styles.imgContainer}>
          <img
            src={
              showIconInstalls ||
                showIconInstallsMin ||
                showIcon ||
                showIconRevenue ||
                showIconRevenueMin ||
                isActive ||
                showIconPaywall ||
                showIconContains ||
                showIconOnboarding ||
                showIconOnboardingMin ||
                showIconRelease ||
                showIconUpdate ||
                showIconLabelled ||
                showIconHidStar
                ? imgActive
                : img
            }
            alt="itemImg"
            loading="eager"
          />
          <h3>{text}</h3>
        </div>
        <div className={styles.content}>
          {isActive && modals.map(({ isOpen, Component, onSave, onSaveMin, onSaveMax, extraProps }, index) => (
            isOpen && (
              <div key={index} className={styles[Component.name.toLowerCase()]} onClick={(e) => e.stopPropagation()}>
                <Component
                  onSave={onSave as ComponentProps["onSave"]}
                  onSaveMax={onSaveMax as ComponentProps["onSaveMax"]}
                  onSaveMin={onSaveMin as ComponentProps["onSaveMin"]}
                  {...extraProps}
                />
              </div>
            )
          ))}
        </div>
      </div>
    </>
  );
};

export default FilterModalElement;

