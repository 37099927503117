import React, { useEffect, useState  } from 'react'
import axios from 'axios'

const OAuthCallbackView = () => {

  const [rewardfulAvailable, setRewardfulAvailable] = useState(false);
  const [rewardfulReady, setRewardfulReady] = useState(false);
  const [referral, setReferral] = useState();
  const [referralCouponId, setReferralCouponId] = useState();

  useEffect(() => {
    if (window.Rewardful) {
      setRewardfulAvailable(true);
      window.rewardful("ready", () => {
        setReferral(window.Rewardful.referral);
        setReferralCouponId(window.Rewardful.coupon.id);
        setRewardfulReady(true);
        console.log('rewardful ready')
      });
    } else {
      setRewardfulAvailable(false);
    }
  }, []);

  const getCookie = (name) => {
    let cookieValue = null
    if (document.cookie && document.cookie !== '') {
      const cookies = document.cookie.split(';')
      for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim()
        // Does this cookie string begin with the name we want?
        if (cookie.substring(0, name.length + 1) === (name + '=')) {
          cookieValue = decodeURIComponent(cookie.substring(name.length + 1))
          break
        }
      }
    }
    return cookieValue
  }

  const getCSRFToken = () => { return getCookie('csrftoken') }

  const getSessionToken = () => {
    const tokenStorage = window.sessionStorage
    const sessionToken = tokenStorage.getItem('sessionToken')
    return sessionToken
  }

  const getHeaders = () => {
    const headers = {}
    headers['accept'] = 'application/json'
    headers['X-CSRFToken'] = getCSRFToken()

    const sessionToken = getSessionToken()

    if (sessionToken) {
      headers['X-Session-Token'] = sessionToken
    }

    return headers
  }

  const fetchAccessToken = () => {

    const data = { client_id: import.meta.env.VITE_API_CLIENT_ID }
    //const url = 'http://localhost:10000/v1/auth/token/'
    const url = `${import.meta.env.VITE_API_BASE_URL}/v1/auth/token/`

    axios.post(url, data, { headers: getHeaders(), withCredentials: true })
    .then((resp) => {

      localStorage.setItem("access_token", resp.data.access_token)
      
      let headers = getHeaders()

      headers['Authorization'] = `Bearer ${resp.data.access_token}`

      axios.get(`${import.meta.env.VITE_API_BASE_URL}/v1/me/`, { headers: headers, withCredentials: true })
      .then((resp) => {

        const currentActiveOrgs = resp.data.organizations.filter((org: any) => org.active )
        if (currentActiveOrgs.length === 0) {
          console.log(resp.data)
          console.error('no active org found post oauth')
          return
        } 
        
        const currentOrg = currentActiveOrgs[0]

        if (currentOrg.is_pro) {
          location.href= '/'
          return
        }

        //const url = 'http://localhost:10000/v1/billing/checkout/'
        const url = `${import.meta.env.VITE_API_BASE_URL}/v1/organizations/${currentOrg.uuid}/checkouts/`

        const data = {
            qte: 1,
            success_url: import.meta.env.VITE_SD_CHECKOUT_SUCCESS_URL,
            referral_id: referral ? referral : undefined,
            referral_coupon: referralCouponId ? referralCouponId : undefined
        }

        axios.post(url, data, { headers: headers, withCredentials: true })
        .then((resp) => {
          location.href = resp.data.redirect_url
        })
      })
    })
    .catch((err) => {
      console.log(err)
      console.error('token fetching error')
    })
  }

  useEffect(() => {
    if (rewardfulAvailable && !rewardfulReady) return

    //const url = 'http://localhost:10000/_allauth/browser/v1/auth/session'
    const url = `${import.meta.env.VITE_API_BASE_URL}/_allauth/browser/v1/auth/session`

    axios.get(url, { headers: getHeaders(), withCredentials: true })
    .then((resp) => {
      //console.log(resp.data)
      fetchAccessToken()
    })
    .catch((err) => {
      console.log(err)
      console.error('session fetching error')
    })

  }, [rewardfulReady, rewardfulAvailable])

  return (
    <></>
  )


};

export { OAuthCallbackView };
