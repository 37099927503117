// redux
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
// types
import { SortByCategoriesState } from "../types/sortByCategoriesTypes";

const initialState: SortByCategoriesState = {
  revenuaOpen: false,
  developerOpen: false,
  saveModeOpen: false,
  hidStarOpen: false,
  sortBy: "None",
  sortBySaveMode: "None",
  sortByHirStar: "None",
  isActiveSort: false,
  isActiveSortSaveMode: false,
  group: "None",
  isActiveGroup: false,
  indexSort: 4,
  indexSortHidStar: 0,
  indexSortSaveMode: 0,
  indexGroup: 0,
};

const SortByCategoriesSlice = createSlice({
  name: "sortByCategories",
  initialState,
  reducers: {
    toggleRevenuaOpen(state) {
      state.revenuaOpen = !state.revenuaOpen;
    },
    toggleDeveloperOpen(state) {
      state.developerOpen = !state.developerOpen;
    },
    openRevenua(state) {
      state.revenuaOpen = true;
    },
    openDeveloper(state) {
      state.developerOpen = true;
    },
    closeDeveloper(state) {
      state.developerOpen = false;
    },
    toggleSortSaveMode(state) {
      state.saveModeOpen = !state.saveModeOpen;
    },
    openSortSaveMode(state) {
      state.saveModeOpen = true;
    },
    closeSortSaveMode(state) {
      state.saveModeOpen = false;
    },
    toggleHidStarOpen(state) {
      state.hidStarOpen = !state.hidStarOpen;
    },
    openHidStar(state) {
      state.hidStarOpen = true;
    },
    closeHidStar(state) {
      state.hidStarOpen = false;
    },
    closeRevenua(state) {
      state.revenuaOpen = false;
    },
    setSortBy(state, action) {
      state.sortBy = action.payload;
    },
    setSortBySaveMode(state, action) {
      state.sortBySaveMode = action.payload;
    },
    setSortByHirStar(state, action) {
      state.sortByHirStar = action.payload;
    },
    setGroup(state, action) {
      state.group = action.payload;
    },
    setIndexSort(state, action: PayloadAction<number>) {
      state.indexSort = action.payload;
    },
    setIndexSortHidStar: (state, action: PayloadAction<number>) => {
      state.indexSortHidStar = action.payload;
    },
    setIndexSortSaveMode(state, action: PayloadAction<number>) {
      state.indexSortSaveMode = action.payload;
    },
    setIndexGroup(state, action: PayloadAction<number>) {
      state.indexGroup = action.payload;
    },
    setIsActiveSort(state, action: PayloadAction<boolean>) {
      state.isActiveSort = action.payload;
    },
    setIsActiveSortSaveMode(state, action: PayloadAction<boolean>) {
      state.isActiveSortSaveMode = action.payload;
    },
    setIsActiveGroup(state, action: PayloadAction<boolean>) {
      state.isActiveGroup = action.payload;
    },
  },
});

export const {
  actions: sortByCategoriesActions,
  reducer: sortByCategoriesReducer,
} = SortByCategoriesSlice;
