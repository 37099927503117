import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { counterActions } from "@/entities/counter";
import { filterAppsActions, getFilterAppsState } from "@/entities/filterApps";
import { filterAppsDataActions } from "@/entities/filterAppsData";
import styles from "./MontlyInstalls.module.scss";
import { handleFocus } from "@/widgets/inputSearch/libs/const/handleFocus";
import StoredCount from "@/shared/ui/storedcount/StoredCound";

import { ACTIVE_VIEW_HOME } from "@/Views/constants";

const MAX_DOWNLOADS = 9999999999;
const MIN_DOWNLOADS = 0;

type SetValueAction = (value: string | number) => {
  type: string;
  payload: string | number;
};

export interface MontlyInstallsProps {
  inputRef: React.RefObject<HTMLInputElement>;
  onSaveMin: (value: number) => void;
  onSaveMax: (value: number) => void;
  handleMinBlur: (
    value: string | number,
    setMinValueAction: SetValueAction,
    onSave: (value: number) => void,
  ) => void;
  handleMaxBlur: (
    value: string | number,
    setMinValueAction: SetValueAction,
    onSave: (value: number) => void,
  ) => void;
  isFetchingFilter: boolean;
  rootActiveView: string;
}

const MontlyInstalls: React.FC<MontlyInstallsProps> = ({
  inputRef,
  onSaveMax,
  onSaveMin,
  handleMinBlur,
  handleMaxBlur,
  isFetchingFilter,
  rootActiveView,
}) => {
  const { downloadgte, downloadlte, isActiveInstalls, activeButtonInstalls } =
    useSelector(getFilterAppsState);
  const dispatch = useDispatch();

  const resetStyles = () => {
    dispatch(filterAppsActions.setButtonInstalls(""));
  };


  const [isResetVisible, setIsResetVisible] = useState(false);

  useEffect(() => {
    const isSelected =
      downloadgte !== "Min" ||
      downloadlte !== "Max" ||
      activeButtonInstalls !== null;
    setIsResetVisible(isSelected);
  }, [downloadgte, downloadlte, activeButtonInstalls]);

  const handleMinChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const eventValue = event.target.value.replace(/\./g, "");

    if (downloadgte !== "") {
      dispatch(filterAppsDataActions.resetFilterAppDataSorted());
      dispatch(filterAppsDataActions.resetFilterAppDataSortedAll());
      dispatch(filterAppsDataActions.resetFilterAppData());
      dispatch(filterAppsActions.resetPageFilter());
      dispatch(filterAppsActions.resetPageFilterSidebar());
    }

    dispatch(filterAppsActions.setDownloadgte(eventValue));
    onSaveMin(Number(eventValue) || MIN_DOWNLOADS);

    if (isActiveInstalls && eventValue === "") {
      dispatch(counterActions.removeFromArray("Monthly Installs"));
      dispatch(filterAppsActions.deactiveInstalls());
    }

    if (!isActiveInstalls) {
      dispatch(counterActions.addToArray("Monthly Installs"));
      dispatch(filterAppsActions.activeInstalls());
    }

    resetStyles();
  };

  const handleMaxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const eventValue = event.target.value.replace(/\./g, "");

    if (downloadlte !== "") {
      dispatch(filterAppsDataActions.resetFilterAppDataSorted());
      dispatch(filterAppsDataActions.resetFilterAppDataSortedAll());
      dispatch(filterAppsDataActions.resetFilterAppData());
      dispatch(filterAppsActions.resetPageFilter());
      dispatch(filterAppsActions.resetPageFilterSidebar());
    }

    dispatch(filterAppsActions.setDownloadlte(eventValue));
    onSaveMax(Number(eventValue) || MAX_DOWNLOADS);

    if (isActiveInstalls && eventValue === "") {
      dispatch(counterActions.removeFromArray("Monthly Installs"));
      dispatch(filterAppsActions.deactiveInstalls());
    }

    if (!isActiveInstalls) {
      dispatch(counterActions.addToArray("Monthly Installs"));
      dispatch(filterAppsActions.activeInstalls());
    }

    resetStyles();
  };

  const formatNumber = (num: string | number): string => {
    if (num === MAX_DOWNLOADS || num === "Max") return "Max";
    if (num === MIN_DOWNLOADS || num === "Min") return "Min";
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const { charCode } = event;
    if (charCode < 48 || charCode > 57) {
      event.preventDefault();
    }
  };

  const handleClick = (
    minValue: string | number,
    maxValue: string | number,
    label: string,
  ) => {
    if (!isFetchingFilter) {
      if (activeButtonInstalls === label) {
        dispatch(filterAppsActions.setDownloadgte("Min"));
        dispatch(filterAppsActions.setDownloadlte("Max"));
        onSaveMin(0);
        onSaveMax(0);
        dispatch(filterAppsDataActions.resetFilterAppDataSorted());
        dispatch(filterAppsDataActions.resetFilterAppDataSortedAll());
        dispatch(filterAppsDataActions.resetFilterAppData());
        dispatch(filterAppsActions.resetPageFilter());
        dispatch(filterAppsActions.resetPageFilterSidebar());
        dispatch(filterAppsActions.setButtonInstalls(null));

        if (isActiveInstalls) {
          dispatch(counterActions.removeFromArray("Monthly Installs"));
          dispatch(filterAppsActions.deactiveInstalls());
        }

        return;
      }

      dispatch(filterAppsActions.setDownloadgte(minValue.toString()));
      dispatch(filterAppsActions.setDownloadlte(maxValue.toString()));
      dispatch(filterAppsActions.setButtonInstalls(label));

      dispatch(filterAppsDataActions.resetFilterAppDataSorted());
      dispatch(filterAppsDataActions.resetFilterAppDataSortedAll());
      dispatch(filterAppsDataActions.resetFilterAppData());
      dispatch(filterAppsActions.resetPageFilter());
      dispatch(filterAppsActions.resetPageFilterSidebar());

      onSaveMin(minValue === "Min" ? MIN_DOWNLOADS : Number(minValue));
      onSaveMax(maxValue === "Max" ? MAX_DOWNLOADS : Number(maxValue));

      if (!isActiveInstalls) {
        dispatch(counterActions.addToArray("Monthly Installs"));
        dispatch(filterAppsActions.activeInstalls());
      }
    }
  };

  const handleReset = () => {
    if ((downloadgte !== "Min" || downloadlte !== "Max") && !isFetchingFilter) {
      dispatch(filterAppsActions.setDownloadgte("Min"));
      dispatch(filterAppsActions.setDownloadlte("Max"));
      onSaveMin(0);
      onSaveMax(0);
      dispatch(filterAppsDataActions.resetFilterAppDataSorted());
      dispatch(filterAppsDataActions.resetFilterAppDataSortedAll());
      dispatch(filterAppsDataActions.resetFilterAppData());
      dispatch(filterAppsActions.resetPageFilter());
      dispatch(filterAppsActions.resetPageFilterSidebar());
      dispatch(filterAppsActions.setButtonInstalls(null));

      if (isActiveInstalls) {
        dispatch(counterActions.removeFromArray("Monthly Installs"));
        dispatch(filterAppsActions.deactiveInstalls());
      }
    }
  };

  return (
    <div className={styles.root}>
      <div className={styles.menuButtons}>
        <div className={styles.menuButtonTop}>
          <div
            className={
              activeButtonInstalls === "Extra Small"
                ? styles.active
                : styles.disabled
            }
          >
            <button onClick={() => handleClick("Min", 10000, "Extra Small")}>
              <span>XS</span>
            </button>
          </div>
          <div
            className={
              activeButtonInstalls === "Small" ? styles.active : styles.disabled
            }
          >
            <button onClick={() => handleClick(10001, 30000, "Small")}>
              <span>S</span>
            </button>
          </div>
          <div
            className={
              activeButtonInstalls === "Medium"
                ? styles.active
                : styles.disabled
            }
          >
            <button onClick={() => handleClick(30001, 50000, "Medium")}>
              <span>M</span>
            </button>
          </div>
        </div>
        <div className={styles.menuButtonBottom}>
          <div
            className={
              activeButtonInstalls === "Large" ? styles.active : styles.disabled
            }
          >
            <button onClick={() => handleClick(50001, 100000, "Large")}>
              <span>L</span>
            </button>
          </div>
          <div
            className={
              activeButtonInstalls === "Extra Large"
                ? styles.active
                : styles.disabled
            }
          >
            <button
              className={
                activeButtonInstalls === "Extra Large"
                  ? styles.xlItemActive
                  : styles.xlItem
              }
              onClick={() => handleClick(100001, 1000000, "Extra Large")}
            >
              <span>XL</span>
            </button>
          </div>
          <div
            className={
              activeButtonInstalls === "XXL" ? styles.active : styles.disabled
            }
          >
            <button
              className={
                activeButtonInstalls === "XXL"
                  ? styles.xlItemActive
                  : styles.xlItem
              }
              onClick={() => handleClick(1000001, "Max", "XXL")}
            >
              <span>XXL</span>
            </button>
          </div>
        </div>
      </div>
      <div className={styles.customMenuButton}>
        <div className={styles.min}>
          <input
            type="text"
            value={formatNumber(downloadgte)}
            onChange={handleMinChange}
            onKeyPress={handleKeyPress}
            maxLength={8}
            onFocus={() =>
              downloadgte === "Min" &&
              dispatch(filterAppsActions.setDownloadgte(""))
            }
            onBlur={() =>
              handleMinBlur(
                downloadgte,
                filterAppsActions.setDownloadgte,
                onSaveMin,
              )
            }
          />
        </div>
        <span className={styles.textTo}>to</span>
        <div className={styles.max}>
          <input
            type="text"
            value={formatNumber(downloadlte)}
            onChange={handleMaxChange}
            onKeyPress={handleKeyPress}
            maxLength={8}
            onFocus={() =>
              downloadlte === "Max" &&
              dispatch(filterAppsActions.setDownloadlte(""))
            }
            onBlur={() =>
              handleMaxBlur(
                downloadlte,
                filterAppsActions.setDownloadlte,
                onSaveMax,
              )
            }
          />
        </div>
      </div>

      <div className={styles.flex}>
        {rootActiveView === ACTIVE_VIEW_HOME && (
          <StoredCount onFocus={() => handleFocus(dispatch)} />
        )}

        {isResetVisible && (
          <div onClick={handleReset} className={styles.reset}>
            <img
              src="/img/blackIcon/Close.svg"
              alt="resetImg"
              loading="lazy"
            />
          </div>
        )}
      </div>
    </div>
  );
};
export default MontlyInstalls;
