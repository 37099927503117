import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSortByCategoriesSlice } from "@/entities/sortByCategories/model/selectors/getSortByCategoriesState";
import { sortByCategoriesActions } from "@/entities/sortByCategories";
import { filterAppsActions } from "@/entities/filterApps";
import SortSavedModeModalItem from "../SortSavedModeModalItem/SortSavedModeModalItem";
import ModalSaveModeSortDesc from "../ModalSaveModeSortDesc/ModalSaveModeSortDesc";
import styles from "./SortSavedModeModal.module.scss";
import SavedModeOrganizationItem from "../SavedModeOrganizationItem/SavedModeOrganizationItem";
import { useCurrentOrganization } from "@/Hooks";
import { useGetOrganizationUsersQuery } from "@/features/auth/api/authAPI";

const dataItem = [
  { text: "None", filter: "created_at" },
  { text: "Name", filter: "app_name" },
  { text: "Date Created", filter: "created_at" },
];

interface SortSavedModeModalProps {
  setSortDirection: (sortDirection: string) => void;
  isOpen: boolean;
  onClose: () => void;
  selectedUser: string | null;
  setSelectedUser: (user: string | null) => void;
  showSavedBy?: boolean;
}

const SortSavedModeModal: React.FC<SortSavedModeModalProps> = ({
  setSortDirection,
  isOpen,
  onClose,
  selectedUser,
  setSelectedUser,
  showSavedBy = true,
}) => {
  const dispatch = useDispatch();
  const { indexSortSaveMode, sortBySaveMode } = useSelector(
    getSortByCategoriesSlice,
  );
  const [sortDirectionSavedMode, setSortDirectionSavedMode] = useState("desc");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const currentOrganization = useCurrentOrganization();

  const { data: organizationUsers } = useGetOrganizationUsersQuery(
    currentOrganization?.uuid,
    {
      skip: !currentOrganization?.uuid,
    },
  );

  const handleClick = (index: number, text: string, filter: string) => {
    if (indexSortSaveMode === index) {
      return;
    }

    dispatch(sortByCategoriesActions.setSortBySaveMode(text));
    dispatch(sortByCategoriesActions.setIndexSortSaveMode(index));
    dispatch(filterAppsActions.setFilterSortSavedMode(filter));
    dispatch(filterAppsActions.setUserFilter(null));

    if (text === "None") {
      setSortDirection("desc");
    }

    setIsModalOpen(false);
  };

  const handleUserClick = (userId: string, userName: string) => {
    setSelectedUser(userName);
    dispatch(filterAppsActions.setUserFilter(userId));
    setIsModalOpen(false);
  };

  const handleSortDirectionChange = (direction: "asc" | "desc") => {
    setSortDirectionSavedMode(direction);
    setSortDirection(direction);
  };

  const resetSelectedFilters = (event: React.MouseEvent) => {
    event.stopPropagation();
    dispatch(sortByCategoriesActions.setSortBySaveMode("None"));
    dispatch(sortByCategoriesActions.setIndexSortSaveMode(0));
    dispatch(filterAppsActions.setFilterSortSavedMode("created_at"));
    setSortDirection("desc");
    setSortDirectionSavedMode("desc");
    dispatch(filterAppsActions.setUserFilter(null));
    setSelectedUser(null);
    setIsModalOpen(false);
  };

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const modalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        modalRef.current &&
        !modalRef.current.contains(event.target as Node)
      ) {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen, onClose]);

  return (
    <div className={styles.root} ref={modalRef}>
      <div className={styles.close}>
        <img
          onClick={onClose}
          src="/img/blackIcon/Close.svg"
          alt="closeImg"
          loading="lazy"
        />
      </div>

      <div className={styles.header}>
        {(sortBySaveMode !== "None" || selectedUser !== null) && (
          <div onClick={resetSelectedFilters} className={styles.reset}>
            <img
              src="/img/gradientIcon/Reset.svg"
              alt="resetImg"
              loading="lazy"
            />
            <p>Reset</p>
          </div>
        )}
        <div className={styles.sortBy}>
          <p onClick={toggleModal}>
            Sort by <span className={styles.sortValue}>{sortBySaveMode}</span>
          </p>
          {/* <img onClick={toggleModal} src="/img/arrowDownActive.svg" alt="arrowDownImg" loading="lazy" /> */}

          {sortBySaveMode !== "None" && (
            <ModalSaveModeSortDesc
              sortDirection={sortDirectionSavedMode}
              handleSortDirectionChange={handleSortDirectionChange}
              indexSortSaveMode={indexSortSaveMode}
            />
          )}

          {isModalOpen && (
            <div className={styles.modalWrapper}>
              <div className={styles.modal}>
                <div className={styles.sortBy2}>
                  {dataItem.map((item, index) => (
                    <SortSavedModeModalItem
                      key={index}
                      name={item.text}
                      isActive={indexSortSaveMode === index}
                      onClick={() => handleClick(index, item.text, item.filter)}
                    />
                  ))}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      {showSavedBy && (
        <div className={styles.savedBy}>
          <div className={styles.savedByContainer}>
            <img
              src="/img/blackIcon/Install.svg"
              alt="savedBy"
              loading="lazy"
            />
            <p className={styles.savedByText}>
              Saved by{selectedUser ? ` ${selectedUser}` : ""}
            </p>
          </div>
          <div className={styles.savedByGrid}>
            {organizationUsers?.results &&
            organizationUsers.results.length > 0 ? (
              organizationUsers.results.map((member) => (
                <SavedModeOrganizationItem
                  key={member.id}
                  name={
                    `${member.user.first_name} ${member.user.last_name}`.trim() ||
                    member.user.email
                  }
                  img={member.user.profile_picture}
                  isActive={
                    selectedUser ===
                    (`${member.user.first_name} ${member.user.last_name}`.trim() ||
                      member.user.username)
                  }
                  onClick={() =>
                    handleUserClick(
                      member.user.id,
                      `${member.user.first_name} ${member.user.last_name}`.trim() ||
                        member.user.username,
                    )
                  }
                />
              ))
            ) : (
              <p>No users found</p>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default SortSavedModeModal;
