import React, { useEffect, useRef, forwardRef } from "react";
import { useDispatch } from "react-redux";
import { MagicOCRProps } from "../../libs/types/MagicOCRProps";
import styles from "./MagicOCR.module.scss";
import { Tooltip } from "@mui/material";
import { styled } from '@mui/material/styles';
import { saveModeActions } from "@/entities/saveMode";
import { filterActions } from "@/entities/filter";

import { useCurrentOrganization, useMe } from '@/Hooks';

interface ExtendedMagicOCRProps extends MagicOCRProps {
  style?: React.CSSProperties;
  inputStyle?: React.CSSProperties;
  onSearch?: () => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
}

const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  '& .MuiTooltip-tooltip': {
    color: 'white',
    background: 'linear-gradient(rgb(35 97 217), rgb(94 36 217) 100%)',
    fontSize: '14px',
    marginTop: '-10px  !important',
  },
});

const MagicOCR = forwardRef<HTMLInputElement, ExtendedMagicOCRProps>(({
  searchValue,
  handleChangeOCR,
  style = {},
  inputStyle = {},
  onSearch,
  onKeyDown,
}, ref) => {

  const { me } = useMe()
  const currentOrganization = useCurrentOrganization();
  const dispatch = useDispatch();
  const inputRef = useRef<HTMLInputElement>(null);
  const [isMobile, setIsMobile] = React.useState(window.innerWidth <= 500);
  const [showTooltip, setShowTooltip] = React.useState(false);
  const [showPressEnter, setShowPressEnter] = React.useState(false);

  useEffect(() => {

    //console.log('magicocr on load searchvalue', searchValue)
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 500);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleInputClick = () => {
    if (!me || !currentOrganization?.is_pro) {
      setShowTooltip(true);

      setTimeout(() => {
        setShowTooltip(false);
      }, 3000);
    }
  };

  const handleOpenBillingPage = (e) => {
    dispatch(filterActions.deactivateFilterMagic());
    dispatch(saveModeActions.activeProModal());
  };

  useEffect(() => {
    const focusInput = () => {
      if (ref && 'current' in ref && ref.current) {
        ref.current.focus();
      }
      if (inputRef.current) {
        inputRef.current.focus();
      }
    };

    focusInput();

    const timeoutId = setTimeout(focusInput, 100);

    return () => clearTimeout(timeoutId);
  }, []);

  useEffect(() => {
    if (!searchValue) {
      setShowPressEnter(false)
    }
  }, [searchValue])

  const handleSearchTextChange = (e) => {
    handleChangeOCR(e)
    if (searchValue) {
      setTimeout(() => {
        setShowPressEnter(true)
      }, 5000)
    }
  }

  return (
    <div className={`${styles.magicOcrWrapper}`} style={style}>
      {(!me || !currentOrganization?.is_pro) && (
        // <div className={styles.magicOcrProWrapperWrapper}>
        //   <div className={styles.magicOcrProWrapper}>
        //     <h5>Typing is for Pros. <span id="unlockProButton" onClick={handleOpenBillingPage} className={styles.magicOcrProWrapperUpgrade}>Upgrade now</span> and take full control of your search !</h5>
        //   </div>
        // </div>
        <div className={styles.inputWithButton}>
            <div className={styles.inputContainer}>
                <div className={`${styles.magicOcrInput} ${styles.magicOcrFakeInput}`}>
                  Searching is for pros, <span id="unlockProButton" onClick={handleOpenBillingPage}>upgrade now</span> or test below
                </div>

                {/* <input
                  ref={(element) => {
                    inputRef.current = element;
                    if (typeof ref === 'function') {
                      ref(element);
                    } else if (ref) {
                      ref.current = element;
                    }
                  }}
                  className={`${styles.magicOcrInput}`}
                  onKeyDown={onKeyDown}
                  style={inputStyle}
                  value={searchValue}
                  onChange={handleSearchTextChange}
                  type="text"
                  placeholder={"Searching is for pros, Upgrade now or test below"}
                  // placeholder={
                  //   !me || !currentOrganization?.is_pro
                  //     ? "Try with one label below"
                  //     : isMobile
                  //       ? "Search for any content"
                  //       : "Search for any content"
                  // }
                  maxLength={50}
                  disabled={!me || !currentOrganization?.is_pro}
                /> */}
              {/* {(!me || !currentOrganization?.is_pro) && (
                <div
                  className={styles.inputOverlay}
                  onClick={handleInputClick}
                />
              )} */}
              <div className={styles.svg}>
                <img src="/img/blackIcon/SmartSearch.svg" alt="Search icon" />
              </div>
            </div>
            <>
              <div className={styles.searchButtonNew}>
                {searchValue && showPressEnter && (
                  <div className={styles.enterButtonWrapper} onClick={() => onSearch(false)}>
                    Press
                    <div className={styles.enterButton}>
                      <img src="/img/blackIcon/EnterButton.svg" alt="Enter button" />
                    </div>
                    to Search with AI
                  </div>
                )}
              </div>
            </>
          </div>
      )}
      {me && currentOrganization?.is_pro && (
        <div className={styles.inputWithButton}>
          <div className={styles.inputContainer}>
            <CustomTooltip
              title="Try with one label below"
              placement="bottom"
              open={showTooltip}
            >
              <input
                ref={(element) => {
                  inputRef.current = element;
                  if (typeof ref === 'function') {
                    ref(element);
                  } else if (ref) {
                    ref.current = element;
                  }
                }}
                className={`${styles.magicOcrInput}`}
                onKeyDown={onKeyDown}
                style={inputStyle}
                value={searchValue}
                onChange={handleSearchTextChange}
                type="text"
                placeholder={
                  !me || !currentOrganization?.is_pro
                    ? "Try with one label below"
                    : isMobile
                      ? "Search for any content"
                      : "Search for any content"
                }
                maxLength={50}
                disabled={!me || !currentOrganization?.is_pro}
              />
            </CustomTooltip>
            {(!me || !currentOrganization?.is_pro) && (
              <div
                className={styles.inputOverlay}
                onClick={handleInputClick}
              />
            )}
            <div className={styles.svg}>
              <img src="/img/blackIcon/SmartSearch.svg" alt="Search icon" />
            </div>
          </div>
          <>
            <div className={styles.searchButtonNew}>
              {searchValue && showPressEnter && (
                <div className={styles.enterButtonWrapper} onClick={() => onSearch(false)}>
                  Press
                  <div className={styles.enterButton}>
                    <img src="/img/blackIcon/EnterButton.svg" alt="Enter button" />
                  </div>
                  to Search with AI
                </div>
              )}
            </div>
          </>
        </div>
      )}
    </div>
  );
});

export default MagicOCR;
