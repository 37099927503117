import { useEffect, useRef, useState } from "react";
import { useParams, useNavigate } from 'react-router-dom';
import Modal from "react-modal";
import Homestyles from "@/pages/Homepage/Homepage.module.scss";
import styles from "@/widgets/modalSaveMode/ui/ModalSaveMode/ModalSaveMode.module.scss";
import ModalAppsEmpty from "@/shared/ui/ModalAppsEmpty/ModalAppsEmpty";
import { useGetSharedItemSavedPointsQuery, useGetSharedItemStoreScreensQuery, useGetSharedGroupQuery, useFetchSavedGroupsMutation } from "@/features/auth/api/authAPI";
import ModalSaveModeSharedGroupStore from "@/shared/ui/ModalSaveModeSharedGroupStore/ModalSaveModeSharedGroupStore";
import ModalSaveModeSharedGroupScreens from "@/shared/ui/ModalSaveModeSharedGroupScreens/ModalSaveModeSharedGroupScreens";
import { handleOpenVideoPage } from "@/app/libs/const/handleOpenVideoPage";
import { useCurrentOrganization } from '@/Hooks'


const SavedModeSharedView = () => {

  const { key } = useParams<{ key: string }>();
  const navigate = useNavigate();
  const containerRef = useRef<HTMLDivElement>(null);
  const photoGroupRef = useRef<HTMLDivElement>(null);
  const currenOrganization = useCurrentOrganization()
  const [savedPoints, setSavedPoints] = useState<ISavedPoint[]>([]);
  const [savedStoreScreens, setSavedStoreScreens] = useState<ISavedStoreScreen[]>([]);
  const [isGroupPhotoModalOpen, setIsGroupPhotoModalOpen] = useState(false);
  const [selectedGroupPhoto, setSelectedGroupPhoto] = useState<string>("");
  const [photoIdGroupScreen, setPhotoIdGroupScreen] = useState<number | null>(null);
  const [typeScreen, setTypeScreen] = useState<string>("");
  const [timestamp, setTimestamp] = useState<number | null>(null);
  const [name, setName] = useState<string>("");
  const [appId, setAppId] = useState<number | null>(null);
  const [slugText, setSlugText] = useState<string>("");
  const [imageIdStore, setImageIdStore] = useState<null | number>(null);
  const { data: savedPointsData } = useGetSharedItemSavedPointsQuery(key!);
  const { data: savedStoreScreensData } = useGetSharedItemStoreScreensQuery(key!);
  const { data: sharedGroupData } = useGetSharedGroupQuery(key!);
  const [fetchSavedGroups, { isLoading: isFetchingSavedGroups }] = useFetchSavedGroupsMutation();
  const [savedGroups, setSavedGroups] = useState<ISavedGroup[]>([]);
  const handleFetchSavedGroups = async () => {
    if (!currenOrganization) return;
    try {
      const result = await fetchSavedGroups(currenOrganization.uuid).unwrap();
      setSavedGroups(result.results);
    } catch (error) {
      console.error("Failed to fetch saved groups:", error);
    }
  };

  useEffect(() => {
    handleFetchSavedGroups();
  }, [currenOrganization]);

  useEffect(() => {
    if (savedPointsData) {
      setSavedPoints(savedPointsData.results);
    }
  }, [savedPointsData]);

  useEffect(() => {
    if (savedStoreScreensData) {
      setSavedStoreScreens(savedStoreScreensData.results);
    }
  }, [savedStoreScreensData]);

  useEffect(() => {
    if (savedGroups.length > 0 && sharedGroupData) {
      const matchingGroup = savedGroups.find(group => group.id === sharedGroupData.saved_group.id);
      if (matchingGroup) {
        navigate(`/saved-library/groups/${matchingGroup.id}`);
      }
    }
  }, [savedGroups, sharedGroupData, navigate]);

  // ... rest of the navigation and modal handling logic from SavedModeGroupDetailView ...

  const handleClickPhone = (
    timestamp: number,
    blank: boolean = false,
    name: string,
    appId: number,
    slug: string
  ) => {
    handleOpenVideoPage(
      timestamp,
      slug,
      "Saved mode shared",
      1,
      appId,
      undefined,
      undefined,
    );
  };

  const handleClickStorePhone = (
    timestamp: number,
    blank: boolean = false,
    name: string,
    imageId: number | null,
  ) => {
    handleOpenVideoPage(
      timestamp,
      slugText,
      "Saved mode shared",
      1,
      appId!,
      undefined,
      undefined,
    );
  };

  const openGroupPhotoModal = (
    photo: string,
    photoId: number,
    name: string,
    ts: number,
    imageStoreId: number,
    appId?: number,
    slug?: string,
  ) => {
    const typeScreen = photo.endsWith(".webp") ? "Store" : "Screen";
    setTypeScreen(typeScreen);
    setSelectedGroupPhoto(photo);
    setPhotoIdGroupScreen(photoId);
    setIsGroupPhotoModalOpen(true);
    setAppId(appId || null);
    setName(name);
    setImageIdStore(imageStoreId);
    setTimestamp(ts);
    setSlugText(slug || "");
  };

  const allGroupPhotos = [
    ...(savedPoints),
    ...(savedStoreScreens),
  ];

  const updatePhotoDetails = (
    item: ISavedPoint | ISavedStoreScreen,
  ) => {
    let imageUrl: string;
    let appName: string;
    let timestamp: number;
    let id: number;

    if ("screen" in item) {
      imageUrl = item.screen;
      appName = item.app.name;
      timestamp = Number(item.ts);
      id = item.id;
    } else {
      imageUrl = item.store_screen.thumbnail;
      appName = item.app.name;
      timestamp = 0;
      id = item.store_screen.id;
    }

    const typeScreen = imageUrl.endsWith(".webp") ? "Store" : "Screen";

    setSelectedGroupPhoto(imageUrl);
    setPhotoIdGroupScreen(item.id);
    setTypeScreen(typeScreen);
    setTimestamp(timestamp);
    setName(appName);
    setImageIdStore(id);
    if ("screen" in item) {
      setAppId(item.app.id);
      setSlugText(item.app.slug);
    } else {
      setAppId(item.app.id);
      setSlugText(item.app.slug);
    }
  };

  const handleNextGroupPhoto = () => {
    if (allGroupPhotos.length === 0) return;

    const currentIndex = allGroupPhotos.findIndex(
      (item) => item.id === photoIdGroupScreen,
    );
    const nextIndex = (currentIndex + 1) % allGroupPhotos.length;
    const nextItem = allGroupPhotos[nextIndex];

    updatePhotoDetails(nextItem);
  };

  const handlePrevGroupPhoto = () => {
    if (allGroupPhotos.length === 0) return;

    const currentIndex = allGroupPhotos.findIndex(
      (item) => item.id === photoIdGroupScreen,
    );
    const prevIndex =
      (currentIndex - 1 + allGroupPhotos.length) % allGroupPhotos.length;
    const prevItem = allGroupPhotos[prevIndex];

    updatePhotoDetails(prevItem);
  };

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "ArrowRight") {
        handleNextGroupPhoto();
      } else if (event.key === "ArrowLeft") {
        handlePrevGroupPhoto();
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, [photoIdGroupScreen, savedPoints, savedStoreScreens]);

  return (
    <div className={Homestyles.modalSaveModeContainer}>
      <div className={Homestyles.modalSaveMode}>
        <div ref={containerRef} className={styles.root}>
          <div className={styles.headerGroupContentActive}>
            <div className={styles.headerGroupLeft}>
              <div className={styles.groupArrow} onClick={() => navigate(-1)}>
                <img src="/img/blackIcon/ChevronLeft.svg" alt="arrowGroupLeftImg" />
              </div>
            </div>
            <div className={styles.groupSaveActive}>
              <h3 title={sharedGroupData?.saved_group.name}>{sharedGroupData?.saved_group.name}</h3>
              <p>{savedPoints.length + savedStoreScreens.length} Screens</p>
            </div>
          </div>

          <div className={styles.mainContent}>
            <div className={styles.groupPhotoContainerWrapper}>
              <div ref={photoGroupRef} className={styles.groupPhotoContainerActive}>
                {savedPoints.length > 0 || savedStoreScreens.length > 0 ? (
                  <>
                    {savedPoints.map((item, index) => (
                      <ModalSaveModeSharedGroupScreens
                        key={index}
                        item={item}
                        openGroupPhotoModal={openGroupPhotoModal}
                      />
                    ))}
                    {savedStoreScreens.map((item, index) => (
                      <ModalSaveModeSharedGroupStore
                        key={index}
                        item={item}
                        openGroupPhotoModal={openGroupPhotoModal}
                      />
                    ))}
                  </>
                ) : (
                  <ModalAppsEmpty
                    text="no screens available"
                    img="/img/groupEmptyIcon.svg"
                  />
                )}
              </div>
            </div>
          </div>

          {/* Modal for viewing images */}
          <Modal
            isOpen={isGroupPhotoModalOpen}
            onRequestClose={() => setIsGroupPhotoModalOpen(false)}
            contentLabel="Group Photo Modal"
            className={styles.modalGroup}
            overlayClassName={styles.overlay}
          >
            <div className={styles.modalContent}>
              <div className={styles.containerImage}>
                <button
                  className={styles.modalContentPhotoButton}
                  onClick={() => {
                    if (typeScreen === "Store") {
                      handleClickStorePhone(timestamp!, true, name, imageIdStore);
                    } else {
                      handleClickPhone(timestamp!, true, name, appId!, slugText);
                    }
                  }}
                >
                  <img
                    className={styles.modalContentPhoto}
                    src={selectedGroupPhoto}
                    alt="Enlarged pic"
                    loading="lazy"
                  />
                </button>
              </div>
              <img
                className={styles.arrowRight}
                src="/img/whiteIcon/ChevronRight.svg"
                alt="arrowRightImg"
                onClick={handleNextGroupPhoto}
                loading="lazy"
              />
              <img
                className={styles.arrowLeft}
                src="/img/whiteIcon/ChevronLeft.svg"
                alt="arrowLeftImg"
                onClick={handlePrevGroupPhoto}
                loading="lazy"
              />
            </div>
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default SavedModeSharedView;
