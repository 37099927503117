import React, { useState, useContext } from 'react'

const AccountTeamsContext = React.createContext()

const AccountTeamsProvider = ({ children }) => {

    const [AccountTeamsActive, setAccountTeamsActive] = useState(false)

    return (
        <AccountTeamsContext.Provider value={{ AccountTeamsActive, setAccountTeamsActive }}>{children}</AccountTeamsContext.Provider>
    )
}

const useAccountTeams = () => {
    const ctx = useContext(AccountTeamsContext)
   
    return ctx
}

export { AccountTeamsProvider, useAccountTeams }