import React from "react";
import ReactPlayer from "react-player";
import { useSelector } from "react-redux";
import { getZoomState } from "@/entities/zoom";
import { getVideoState } from "@/entities/video/model/selectors/getVideoState";
import { getModalMenuPhoneState } from "@/entities/modalMenuPhone";
import { getAdditionInfoModal } from "@/entities/additionInfoModal";
import { getSavePostionState } from "@/entities/savePosition";
import { ImageData } from "@/widgets/savePosition/libs/types/VideoControlsProps";
import { IMarker } from "@/widgets/savePosition/libs/types/IMarker";

export interface CroppedImage {
  img: string;
  sx: number;
  sy: number;
  sWidth: number;
  sHeight: number;
  startTime: string;
  endTime: string;
}

const useModalPhoneItemState = () => {
  const [hasNullItem, setHasNullItem] = React.useState(false);
  const [videoReady, setVideoReady] = React.useState<boolean>(false);
  const [imagesReady, setImagesReady] = React.useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [croppedImages, setCroppedImages] = React.useState<ImageData[]>([]);
  const [isTimestampReady, setIsTimestampReady] =
    React.useState<boolean>(false);
  const [isScrolling, setIsScrolling] = React.useState(true);
  const [markers, setMarkers] = React.useState<IMarker[]>([]);
  const [shareModal, setShareModal] = React.useState(false);
  const [showLottie, setShowLottie] = React.useState(true);

  const videoRef = React.useRef<ReactPlayer>(null);
  const photoCardListRef = React.useRef<HTMLDivElement>(null);
  const { zoom } = useSelector(getZoomState);
  const { currentPlayTime, playing } = useSelector(getVideoState);
  const { isModalOpenPhotoCard, isExploreAppsReset } = useSelector(
    getModalMenuPhoneState,
  );
  const { isOpen } = useSelector(getAdditionInfoModal);
  const { isActivePosition } = useSelector(getSavePostionState);

  return {
    state: {
      hasNullItem,
      videoReady,
      imagesReady,
      isModalOpen,
      isTimestampReady,
      isScrolling,
      markers,
      zoom,
      currentPlayTime,
      playing,
      isModalOpenPhotoCard,
      isOpen,
      isActivePosition,
      isExploreAppsReset,
      showLottie,
      shareModal,
      croppedImages,
    },
    setHasNullItem,
    setVideoReady,
    setImagesReady,
    setIsModalOpen,
    setIsTimestampReady,
    setIsScrolling,
    setMarkers,
    setShowLottie,
    videoRef,
    photoCardListRef,
    setShareModal,
    setCroppedImages,
  };
};

export default useModalPhoneItemState;
