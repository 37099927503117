// redux
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
// types
import { IVideo } from "../types/video";

const initialState: IVideo = {
  playing: false,
  playbackRate: 1,
  currentPlayTime: 0,
  playedSeconds: 0,
  activeWindow: "",
  activeImage: "",
  emptyVideo: false,
  videoType: 1,
};

export const videoSlice = createSlice({
  name: 'video',
  initialState,
  reducers: {
    togglePlayPause: (state) => {
      state.playing = !state.playing;
    },
    pause: (state) => {
      state.playing = false;
    },
    play: (state) => {
      state.playing = true;
    },
    setPlaying: (state, action: PayloadAction<boolean>) => {
      state.playing = action.payload;
    },
    setPlaybackRate: (state, action: PayloadAction<number>) => {
      state.playbackRate = action.payload;
    },
    setCurrentPlayTime: (state, action: PayloadAction<number>) => {
      state.currentPlayTime = action.payload;
    },
    setPlayedSeconds: (state, action: PayloadAction<number>) => {
      state.playedSeconds = action.payload;
    },
    setActiveWindow: (state, action: PayloadAction<string | null>) => {
      state.activeWindow =
        state.activeWindow === action.payload ? "" : action.payload;
    },
    setActiveImage: (state, action: PayloadAction<string | null>) => {
      state.activeImage =
        state.activeWindow === action.payload ? "" : action.payload;
    },
    setEmptyVideo: (state, action: PayloadAction<boolean>) => {
      state.emptyVideo = action.payload;
    },
    setVideoType: (state, action: PayloadAction<number>) => {
      state.videoType = action.payload;
    },
  },
});

export const { actions: videoActions, reducer: videoReducer } = videoSlice;
