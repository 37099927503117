// redux
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

const initialState = {
  videoPath: "",
};

const appVideoPathSlice = createSlice({
  name: "appVideoPath",
  initialState,
  reducers: {
    setVideoPath: (state, action: PayloadAction<string>) => {
      state.videoPath = action.payload;
    },
  },
});

export const { actions: appVideoPathActions, reducer: appVideoPathReducer } =
  appVideoPathSlice;
