// redux
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
// types
import { LocationState } from "../types/locationType";

const initialState: LocationState = {
  locationPath: "",
};

const locationSlice = createSlice({
  name: "location",
  initialState,
  reducers: {
    setLocationPath: (state, action: PayloadAction<string>) => {
      state.locationPath = action.payload;
    },
  },
});

export const { actions: locationActions, reducer: locationReducer } =
  locationSlice;
