import React, { useEffect, useState } from 'react';
import styles from './StoredCount.module.scss';

interface StoredCountProps {
  onFocus: () => void;
}

const StoredCount: React.FC<StoredCountProps> = ({ onFocus }) => {
  const [storedCount, setStoredCount] = useState<number | null>(() => {
    const savedCount = localStorage.getItem("appDataCount");
    return savedCount ? Number(savedCount) : null;
  });

  useEffect(() => {
    const checkLocalStorage = () => {
      const currentCount = localStorage.getItem("appDataCount");
      const numericCount = currentCount ? Number(currentCount) : null;
      if (numericCount !== storedCount) {
        setStoredCount(numericCount);
        console.log("Count has changed:", numericCount);
      }
    };

    checkLocalStorage();
    const intervalId = setInterval(checkLocalStorage, 1000);

    return () => clearInterval(intervalId);
  }, [storedCount]);

  return (
    <p
      className={styles.descActive}
      onClick={onFocus}
    >
      Showing {storedCount !== null ? storedCount : "No data available"} apps
    </p>
  );
};

export default StoredCount;
