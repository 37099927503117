import React, { useRef, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import styles from "./Header.module.scss";
import { additionlInfoModalActions } from "@/entities/additionInfoModal";
import mixpanel from "@/shared/lib/mixpanel";
import { useMe, useCurrentOrganization } from "@/Hooks";
import { Tooltip } from "@mui/material";

interface HeaderProps {
  data: {
    icon: string;
    name: string;
    shortname: string;
  };
  appId: number;
}

const Header: React.FC<HeaderProps> = ({
  data,
  appId
}) => {
  // Add mock data array
  const mockVersions = [
    { id: 1, date: 'Feb 2025' },
    { id: 2, date: 'Jan 2025' },
    { id: 3, date: 'Dec 2024' },
  ];

  const dispatch = useDispatch();
  const { me } = useMe();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedVersion, setSelectedVersion] = useState(mockVersions[0].id);
  const currentOrganization = useCurrentOrganization();

  const handleExpand = () => {
    mixpanel.track('Info clicked', {
      app_name: data.name,
      idApp: appId,
      UserEmail: me?.email || '',
      OrganizationID: currentOrganization?.uuid || '',
    });
    dispatch(additionlInfoModalActions.toggleItem());
  };

  const iconTimerSaveAppRef = useRef<NodeJS.Timeout | null>(null);
  const iconTimerRef = useRef<NodeJS.Timeout | null>(null);
  const hideTimerRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    return () => {
      if (iconTimerSaveAppRef.current) clearTimeout(iconTimerSaveAppRef.current);
      if (iconTimerRef.current) clearTimeout(iconTimerRef.current);
      if (hideTimerRef.current) clearTimeout(hideTimerRef.current);
    };
  }, []);

  return (
    <>
      <div className={styles.root}>
        <div className={styles.containerLeft}>
          <div className={styles.containerTop}>
            <div className={styles.containerTitle}>
              <img
                className={styles.logo}
                src={
                  data!.icon === "" || !data!.icon
                    ? "/img/skeletonLogo.svg"
                    : data!.icon
                }
                alt="ing-logoHeader"
                loading="lazy"
                draggable={false}
              />
              <div className={styles.title} >
                <h1 title={data!.name}>{data!.name}</h1>
                <p title={data!.shortname}>{data!.shortname}</p>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.containerRight}>
          {/* <div className={styles.multipuleVersionContainer} onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
            <img src="/img/MultipuleVersion.svg" alt="multipuleVersion" />
            <p>{mockVersions.find(v => v.id === selectedVersion)?.date}</p>
            <img src="/img/blackIcon/DropDown.svg" alt="arrowDown" />

            {isDropdownOpen && (
              <div className={styles.multipuleVersionDropdown}>
                {mockVersions.map((version) => (
                  <p
                    key={version.id}
                    className={`${styles.multipuleVersionDropdownItem} ${selectedVersion === version.id ? styles.active : ''}`}
                    onClick={() => setSelectedVersion(version.id)}
                  >
                    {version.date}
                  </p>
                ))}
              </div>
            )}
          </div> */}
          <Tooltip
            title="See info"
            placement="top"
            componentsProps={{
              tooltip: {
                sx: {
                  background: 'linear-gradient(90deg, rgb(35 97 217), rgb(94 36 217) 100%)',
                  color: 'white',
                  fontSize: '0.8',
                  padding: '8px 12px',
                  borderRadius: '24px',
                  margin: '2px 2px 3px !important',
                },
              },
            }}
          >
            <div className={styles.containerExpand} onClick={() => handleExpand()}>
              <img
                className={styles.iconExpand}
                src="/img/blackIcon/Info.svg"
                alt="img-close"
                loading="lazy"
              />
              {/* <p>App info</p> */}
            </div>
          </Tooltip>
        </div>
      </div>
    </>
  );
};

export default Header;
