import React, { useEffect, useState, useRef } from 'react';
import styles from './RightClickModal.module.scss';
import { useCurrentOrganization } from "@/Hooks";
import { useFetchSavedGroupsMutation, useSavedGroupPointsMutation, useGetSavedPointsAllQuery, useGetSavedStorePointsAllQuery, useSavedGroupStorePointsMutation, useSavedPointsMutation, useSavedStorePointsMutation, useCreateSavedObScreenMutation, useCreateSavedGroupObScreenMutation, useGetSavedObScreensQuery } from "@/features/auth/api/authAPI";
import { ResponseSavedGroups } from '@/features/auth/types/auth';
import { toast } from 'react-toastify';
import { useLocation, useNavigate } from 'react-router-dom';
import { useMe } from '@/Hooks';
import mixpanel from '@/shared/lib/mixpanel';
import { IoIosCheckmarkCircle } from 'react-icons/io';
import Modal from 'react-modal';

interface RightClickModalProps {
  x: number;
  y: number;
  onClose: () => void;
  imageUrl: string;
  imageId: number | string;
  timestamp: number;
  isStoreScreen?: boolean;
  handleOpenInNewTab: (timestamp: number) => void;
  avsID?: number;
  refetchData: () => void;
  imageType: 'video' | 'store' | 'animation' | 'webOnboarding';
  containerRef?: React.RefObject<HTMLElement>;
}

export const RightClickModal: React.FC<RightClickModalProps> = ({
  x,
  y,
  onClose,
  imageUrl,
  imageId,
  timestamp,
  isStoreScreen = false,
  handleOpenInNewTab,
  avsID,
  refetchData,
  imageType,
  containerRef,
}) => {
  const navigate = useNavigate();
  const { me } = useMe()

  const currentOrganization = useCurrentOrganization();
  const [savedGroups, setSavedGroups] = useState<ResponseSavedGroups | null>(null);
  const [fetchSavedGroups, { isLoading }] = useFetchSavedGroupsMutation();

  const [savedStoreScreen] = useSavedStorePointsMutation();
  const [saveGroupPoints] = useSavedGroupPointsMutation();

  const [saveGroupStorePoints] = useSavedGroupStorePointsMutation();
  const [savedPoint] = useSavedPointsMutation();

  const [createSavedObScreen] = useCreateSavedObScreenMutation();
  const [createSavedGroupObScreen] = useCreateSavedGroupObScreenMutation();

  const { data: savedPoints } = useGetSavedPointsAllQuery(
    { organizationUuid: currentOrganization?.uuid },
    { skip: !currentOrganization?.uuid || isStoreScreen }
  );

  const { data: savedStorePoints } = useGetSavedStorePointsAllQuery(
    currentOrganization?.uuid,
    { skip: !currentOrganization?.uuid || !isStoreScreen }
  );

  const { data: savedObScreens } = useGetSavedObScreensQuery({
    organizationUuid: currentOrganization?.uuid || '',
  }, {
    skip: !currentOrganization?.uuid,
  });

  let savedPointGroup;
  switch (imageType) {
    case 'store':
      savedPointGroup = savedStorePoints?.results.find(point => point.store_screen.id === Number(imageId));
      break;
    case 'webOnboarding':
      savedPointGroup = savedObScreens?.results.find(point => Number(point.ts) == Number(timestamp));
      break;
    case 'video':
    default:
      savedPointGroup = savedPoints?.results.find(point => point.ts == timestamp && point.app_video == avsID);
      break;
  }

  useEffect(() => {
    const loadSavedGroups = async () => {
      if (currentOrganization?.uuid) {
        try {
          const response = await fetchSavedGroups(currentOrganization.uuid).unwrap();
          setSavedGroups(response);
        } catch (error) {
          console.error('Failed to fetch saved groups:', error);
        }
      }
    };

    loadSavedGroups();
  }, [currentOrganization?.uuid, fetchSavedGroups]);

  const handleCopyScreen = async (e: React.MouseEvent) => {

    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

    if (isSafari) {
      handleCopyScreenSafari(e)
    } else {
      handleCopyScreenOthers(e)
    }
  }

  
  const handleCopyScreenSafari = async (e: React.MouseEvent) => {
    e.stopPropagation();

    const getImagePromise = async () => {

      const response = await fetch(imageUrl, { cache: 'no-store' });
      const blob = await response.blob();
      const blobUrl = URL.createObjectURL(blob);
      const img = new Image();
      await new Promise((resolve, reject) => {
        img.onload = resolve;
        img.onerror = reject;
        img.src = blobUrl;
      });

      const canvas = document.createElement('canvas');
      canvas.width = img.width;
      canvas.height = img.height;

      const ctx = canvas.getContext('2d');
      if (!ctx) throw new Error('Failed to get canvas context');
      ctx.drawImage(img, 0, 0);

      const pngBlob = await new Promise<Blob>((resolve, reject) => {
        canvas.toBlob((blob) => {
          if (blob) resolve(blob);
          else reject(new Error('Failed to create blob'));
        }, 'image/png');
      });

      console.log('pngblob', pngBlob)
      return pngBlob
    }

    try {

      await navigator.clipboard.write([
        new ClipboardItem({
          'image/png': getImagePromise()
        })
      ]);

      toast.success("Image copied to clipboard");
    } catch (error) {
      console.error('Failed to copy image:', error);
      toast.error("Failed to copy image to clipboard");
    }
    onClose();
  }

  const handleCopyScreenOthers = async (e: React.MouseEvent) => {
    e.stopPropagation();
    try {
      const response = await fetch(imageUrl, { cache: 'no-store' });
      const blob = await response.blob();

      const blobUrl = URL.createObjectURL(blob);

      const img = new Image();
      await new Promise((resolve, reject) => {
        img.onload = resolve;
        img.onerror = reject;
        img.src = blobUrl;
      });

      const canvas = document.createElement('canvas');
      canvas.width = img.width;
      canvas.height = img.height;

      const ctx = canvas.getContext('2d');
      if (!ctx) throw new Error('Failed to get canvas context');
      ctx.drawImage(img, 0, 0);

      const pngBlob = await new Promise<Blob>((resolve, reject) => {
        canvas.toBlob((blob) => {
          if (blob) resolve(blob);
          else reject(new Error('Failed to create blob'));
        }, 'image/png');
      });

      URL.revokeObjectURL(blobUrl);

      await navigator.clipboard.write([
        new ClipboardItem({
          'image/png': pngBlob
        })
      ]);

      toast.success("Image copied to clipboard");
    } catch (error) {
      console.error('Failed to copy image:', error);
      toast.error("Failed to copy image to clipboard");
    }
    onClose();
  };

  const handleOpenVideo = (e: React.MouseEvent) => {
    e.stopPropagation();
    handleOpenInNewTab(timestamp);
    onClose();
  };

  const isVideoPage = useLocation().pathname.includes('/apps/');
  const isSavedMode = useLocation().pathname.includes('/saved-library/');

  const isHomePage = useLocation().pathname == '/';

  const handleAddToGroup = async (groupId: number) => {
    if (!me) {
      navigate('/login');
      localStorage.setItem('btnName', 'add to group');
      return;
    }

    try {
      if (isStoreScreen) {

        let savedStorePointId = savedStorePoints?.results.find(point =>
          point.store_screen.id === Number(imageId))?.id;

        if (!savedStorePointId) {
          const savedResult = await savedStoreScreen({
            organizationUuid: currentOrganization.uuid,
            storeScreen: imageId,
          }).unwrap();
          savedStorePointId = savedResult.id;
          refetchData();
        }

        await saveGroupStorePoints({
          saved_group: groupId,
          saved_store_screen: savedStorePointId
        }).unwrap();
        toast.success("Store image added to group");
      } else if (imageType === 'webOnboarding') {
        let savedObPointId = savedPointGroup?.id;

        if (!savedObPointId) {
          const savedResult = await createSavedObScreen({
            organizationUuid: currentOrganization.uuid,
            data: {
              app_ob_video: avsID,
              ts: timestamp,
            },
          }).unwrap();
          savedObPointId = savedResult.id;
          refetchData();
        }

        await createSavedGroupObScreen({
          saved_group: groupId,
          saved_ob_screen: savedObPointId
        }).unwrap();
        toast.success("Web onboarding image added to group");
      } else {

        let savedPointId = savedPointGroup?.id;

        if (!savedPointId) {
          const savedResult = await savedPoint({
            organizationUuid: currentOrganization.uuid,
            app_video: avsID,
            ts: timestamp,
          }).unwrap();
          savedPointId = savedResult.id;
          refetchData();
        }

        await saveGroupPoints({
          saved_group: groupId,
          saved_point: savedPointId
        }).unwrap();
        toast.success("Image Added to the group to group");
      }
    } catch (error: any) {
      if (error?.data?.non_field_errors?.[0] === "Items exists already") {
        toast.warning("This item is already in the selected group");
      } else {
        toast.error("Failed to add item to group");
      }
      console.error('Failed to add item to group:', error);
    }
  };

  const handleCreateGroup = async () => {
    if (!me) {
      navigate('/login');
      localStorage.setItem('btnName', 'create group');
      return;
    }

    try {
      let savedPointId;

      if (isStoreScreen) {
        let savedStorePointId = savedStorePoints?.results.find(point =>
          point.store_screen.id === Number(imageId))?.id;

        if (!savedStorePointId) {
          const savedResult = await savedStoreScreen({
            organizationUuid: currentOrganization.uuid,
            storeScreen: imageId,
          }).unwrap();
          savedStorePointId = savedResult.id;
          await refetchData();
        }
        savedPointId = savedStorePointId;
      } else if (imageType === 'webOnboarding') {
        let savedObPointId = savedObScreens?.results.find(point =>
          Number(point.ts) == Number(timestamp))?.id;

        if (!savedObPointId) {
          const savedResult = await createSavedObScreen({
            organizationUuid: currentOrganization.uuid,
            data: {
              app_ob_video: avsID,
              ts: timestamp,
            },
          }).unwrap();
          savedObPointId = savedResult.id;
          await refetchData();
        }
        savedPointId = savedObPointId;
      } else {
        let savedVideoPointId = savedPoints?.results.find(point =>
          point.ts == timestamp && point.app_video == avsID)?.id;

        if (!savedVideoPointId) {
          const savedResult = await savedPoint({
            organizationUuid: currentOrganization.uuid,
            app_video: avsID,
            ts: timestamp,
          }).unwrap();
          savedVideoPointId = savedResult.id;
          await refetchData();
        }
        savedPointId = savedVideoPointId;
      }

      navigate('/saved-library/groups/', {
        state: {
          openAddGroupModal: true,
          selectedPhotos: [{
            id: savedPointId,
            url: imageUrl,
            videoId: avsID || 0,
            type: imageType
          }]
        }
      });
      onClose();

    } catch (error) {
      console.error('Failed in handleCreateGroup:', error);
      toast.error("Failed to save point before creating group");
    }
  };

  const handleSaveImage = async (e: React.MouseEvent) => {
    e.preventDefault();

    if (!me) {
      navigate('/login');
      localStorage.setItem('btnName', 'save image');
      return;
    }

    try {
      if (imageType === 'video') {
        await savedPoint({
          organizationUuid: currentOrganization.uuid,
          app_video: avsID,
          ts: timestamp,
        }).unwrap();
      } else if (imageType === 'store') {
        await savedStoreScreen({
          organizationUuid: currentOrganization.uuid,
          storeScreen: imageId,
        }).unwrap();
      } else if (imageType === 'webOnboarding') {
        await createSavedObScreen({
          organizationUuid: currentOrganization.uuid,
          data: {
            app_ob_video: avsID,
            ts: timestamp,
          },
        }).unwrap();
      }

      if (me?.email) {
        mixpanel.identify(me.email);
        setTimeout(() => {
          mixpanel.people.increment('total_saved_screens', 1);
        }, 100);
      }

      mixpanel.track('Image saved', {
        idApp: avsID,
        From: "VideoPage",
        UserEmail: me?.email || '',
        OrganizationID: currentOrganization?.uuid || '',
      });

      setTimeout(() => {
        refetchData();
      }, 500);
      //toast.success("Image saved successfully");
    } catch (error) {
      if (error?.data?.non_field_errors?.[0] === "Already saved") {
        toast.warning("Image already saved");
        refetchData();
        return;
      } else {
        toast.error("Failed to add item to group");
      }
      console.error('Failed to add item to group:', error);
    }
    onClose();
  };

  const isImageInSpecificGroup = (group, imageId: number | string, imageType: string) => {
    switch (imageType) {
      case 'video':
        return group.saved_points.some(point => {
          return point.ts == timestamp;
        });
      case 'store':
        return group.saved_store_screens.some(screen => {
          return screen.store_screen.id == imageId;
        });
      case 'animation':
        return group.saved_animations.some(animation => {
          return animation.id === imageId;
        });
      case 'webOnboarding':
        return group.saved_ob_screens.some(screen => {
          return Number(screen.ts) == Number(timestamp);
        });
      default:
        return false;
    }
  };
  const [dropdownPosition, setDropdownPosition] = useState<'right' | 'left'>('right');
  const contextMenuRef = useRef<HTMLDivElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (contextMenuRef.current && containerRef?.current) {
      const containerRect = containerRef.current.getBoundingClientRect();
      const dropdownWidth = 200;

      const spaceToRightEdge = containerRect.right - x;
      const requiredSpace = dropdownWidth + 28;

      if (spaceToRightEdge < requiredSpace) {
        setDropdownPosition('left');
      } else {
        setDropdownPosition('right');
      }
    }
  }, [x, y, containerRef]);

  const getMenuPosition = () => {
    if (!isHomePage || !containerRef?.current) {
      return { top: y, left: x };
    }

    const containerRect = containerRef.current.getBoundingClientRect();
    return {
      top: y - containerRect.top,
      left: x - containerRect.left
    };
  };

  const menuPosition = getMenuPosition();

  return (
    <>
      <Modal
        isOpen={true}
        onRequestClose={onClose}
        contentLabel="Context Menu"
        className={styles.modal}
        overlayClassName={styles.overlay}
      >
        <div
          ref={contextMenuRef}
          className={`${isHomePage ? styles.contextMenuHomePage : styles.contextMenu}`}
          style={{
            top: menuPosition.top,
            left: menuPosition.left,
          }}
          data-context-menu
        >
          <a onClick={handleCopyScreen} className={styles.menuItem}>
            <img src="/img/blackIcon/Copy.svg" alt="copy" className={styles.menuIcon} />
            Copy Screen
          </a>
          {!isVideoPage && (
            <a onClick={handleOpenVideo} className={styles.menuItem}>
              <img src="/img/blackIcon/AnimationExplorer.svg" alt="Open" className={styles.menuIcon} />
              Open Video
            </a>
          )}
          {!isSavedMode && (
            <>
              {/* <a
                onClick={handleSaveImage}
                className={styles.menuItem}
              >
                <img src="/img/blackIcon/Save.svg" className={styles.menuIcon} />
                Save Image
              </a> */}
              <div className={`${styles.menuItem} ${styles.dropdownContainer}`}>
                <img src="/img/blackIcon/Plus.svg" alt="Add to Group" className={styles.menuIcon} />
                <span>Add to Group</span>
                <div
                  ref={dropdownRef}
                  className={`${dropdownPosition === 'right' ? styles.dropdown : styles.dropdownLeft}`}
                >
                  <a
                    onClick={handleCreateGroup}
                    className={`${styles.menuItem} ${styles.createGroup}`}
                  >
                    <img src="/img/blackIcon/Team2.svg" alt="Create Group" className={styles.menuIcon} />
                    Create New Group
                  </a>
                  {savedGroups?.results.map((group, index) => (
                    <div
                      key={`${group.id}-${index}`}
                      className={styles.dropdownItem}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleAddToGroup(group.id);
                        onClose();
                      }}
                    >
                      {group.name}
                      {isImageInSpecificGroup(group, imageId, imageType) && (
                        <IoIosCheckmarkCircle
                          style={{
                            position: "absolute",
                            color: "green",
                            fontSize: "18px",
                            marginLeft: "5px",
                          }}
                        />
                      )}
                    </div>
                  ))}
                </div>
              </div>
            </>
          )}
        </div>
      </Modal>
    </>
  );
};

