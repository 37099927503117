import { useCallback, useEffect } from 'react'
import { useOutletContext } from 'react-router-dom'
import styles from "./AnimationExplorerer.module.scss";
import { useState } from "react";
import AnimationExplorerCard from "@/shared/ui/AnimationExplorerCard/AnimationExplorerCard";
import { produce } from 'immer'

import {
    getFilterInputSearchState
} from "@/entities/filterInputSearch";
import {
  activeModalIndexActions
} from "@/entities/activeModalIndex";

import { useDispatch, useSelector } from "react-redux";
import { getFilterAppsState } from "@/entities/filterApps";
import { useAPI } from '@/Hooks'
import { ACTIVE_VIEW_SEARCH } from '@/Views/constants'
import { useInView } from 'react-intersection-observer'
import Spinner from '@/shared/ui/Spinner/Spinner';
import { getFilterState } from '@/entities/filter';
import { is } from 'date-fns/locale';


const AnimationExplorer = () => {

  const api = useAPI()
  const dispatch = useDispatch()

  const {
        setRootActiveView,
        sortDirection
    } = useOutletContext()

    const {
      filterSort,
      categories,
      paywall,
      oscgte,
      osclte,
      revenuegte,
      revenuelte,
      downloadgte,
      downloadlte,
      revInstallRateGte,
      revInstallRateLte,
      releasedFrom,
      releasedTo,
      updatedFrom,
      updatedTo,
      only
  } = useSelector(getFilterAppsState)

  const { appName } = useSelector(getFilterInputSearchState)

  const makeOrderValue = (key, direction) => {
    if (key !== "") {
        return `${direction === "desc" ? "-" : ""}${key}`
    } else {
        return ""
    }
}

  const downloadgteNumber = downloadgte === "Min" ? "" : downloadgte;
  const downloadlteNumber = downloadlte === "Max" ? "" : downloadlte;
  const revenuegteNumber = revenuegte === "Min" ? "" : revenuegte;
  const revenuelteNumber = revenuelte === "Max" ? "" : revenuelte;
  const oscgteNumber = oscgte === "Min" ? "" : oscgte;
  const osclteNumber = osclte === "Max" ? "" : osclte;
  const revInstallRateGteNumber = revInstallRateGte === "Min" ? "" : revInstallRateGte;
  const revInstallRateLteNumber = revInstallRateLte === "Max" ? "" : revInstallRateLte;

  const [page, setPage] = useState(1)
  const [isLoading, setIsLoading] = useState(false)
  const [animations, setAnimations] = useState([])
  const [hasMore, setHasMore] = useState(true)
  const [filterParams, setFilterParams] = useState({
    page: 1,
    page_size: 10,
    only: only,
    category__in: categories,
    name: appName,
    download_gte: downloadgteNumber,
    download_lte: downloadlteNumber,
    revenue_gte: revenuegteNumber,
    revenue_lte: revenuelteNumber,
    paywall_type: paywall,
    osc_gte: oscgteNumber,
    osc_lte: osclteNumber,
    released_from: releasedFrom,
    released_to: releasedTo,
    updated_from: updatedFrom,
    updated_to: updatedTo,
    order: makeOrderValue(filterSort, sortDirection),
    rev_install_rate_gte: revInstallRateGteNumber,
    rev_install_rate_lte: revInstallRateLteNumber
  })
  
  const { isActiveMagic } = useSelector(getFilterState)
  const { ref: loadMoreRef, inView } = useInView()
  

  useEffect(() => {
        setRootActiveView(ACTIVE_VIEW_SEARCH)
    }, [])

  useEffect(() => {
    setPage(1)
    setAnimations([])
    setHasMore(true)
    
    setFilterParams({
      page: 1,
      only: only,
      category__in: categories,
      name: appName,
      download_gte: downloadgteNumber,
      download_lte: downloadlteNumber,
      revenue_gte: revenuegteNumber,
      revenue_lte: revenuelteNumber,
      paywall_type: paywall,
      osc_gte: oscgteNumber,
      osc_lte: osclteNumber,
      released_from: releasedFrom,
      released_to: releasedTo,
      updated_from: updatedFrom,
      updated_to: updatedTo,
      order: makeOrderValue(filterSort, sortDirection),
      rev_install_rate_gte: revInstallRateGteNumber,
      rev_install_rate_lte: revInstallRateLteNumber
    })
  }, [
    categories,
    appName,
    downloadgte,
    downloadlte,
    revenuegte,
    revenuelte,
    paywall,
    oscgte,
    osclte,
    releasedFrom,
    releasedTo,
    updatedFrom,
    updatedTo,
    revInstallRateGte,
    revInstallRateLte,
    filterSort,
    sortDirection,
    only
  ])

  useEffect(() => {
        dispatch(activeModalIndexActions.setActiveModalIndexFilterItem(1));
    }, [])

  

  const fetchAnimations = useCallback(() => {
    if (isLoading) return
    
    setIsLoading(true)
    const p = { 
      ...filterParams,
      page_size: 10 
    }
    
    api.get('/v1/appvideoanimations/', { params: p })
       .then((res) => {
          if (filterParams.page > 1) {
            setAnimations(prev => [...prev, ...res.data.results])
          } else {
            setAnimations(res.data.results)
          }
          setHasMore(!!res.data.next)
       })
       .catch((error) => {
          console.error('Search failed:', error);
       })
       .finally(() => {
          setIsLoading(false)
       })
  }, [filterParams, isLoading])

  useEffect(() => {
    fetchAnimations()
  }, [filterParams])

  useEffect(() => {
    if (inView && hasMore && !isLoading) {
      setFilterParams(prev => ({
        ...prev,
        page: prev.page + 1
      }))
    }
  }, [inView, hasMore, isLoading])

  return (
  <div className={`${styles.container} ${isActiveMagic ? styles.overlay : ""}`}>
      <div className={styles.cards}>
        {animations.map((item, index) => (
          <AnimationExplorerCard
            key={item.id}
            name={item.app.name}
            shortname={item.app.shortname}
            icon={item.app.icon}
            video={item.video}
            app_video={item.app_video}
            time_from={item.time_from}
            id={item.id}
            slug={item.app.slug}
            avpk={item.app_video}
            appid={item.app.id}
          />
        ))}
      </div>
      {hasMore && (
        <div ref={loadMoreRef} className={styles.loadingContainer}>
          {isLoading && <Spinner />}
        </div>
      )}
    </div>
  );
};

export default AnimationExplorer;
