// react
import React from "react";
// react-intersection-observer
import { useInView } from "react-intersection-observer";

export const ObserverWrapper: React.FC<{
  index: number;
  onViewChange: (index: number, inView: boolean) => void;
  children: React.ReactNode;
}> = ({ index, onViewChange, children }) => {
  const { ref, inView } = useInView({
    threshold: 1,
  });

  React.useEffect(() => {
    onViewChange(index, inView);
  }, [inView, index, onViewChange]);

  return <div ref={ref}>{children}</div>;
};
