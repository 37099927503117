import React from "react";
import CustomSlider from "@/widgets/customSlider/ui/CustomSlider/CustomSlider";
import { BillingPlanTeamsProps } from "@/shared/types/BillingPlanTeams";
import styles from "./BillingPlanTeams.module.scss";
import { useGetPlansQuery } from "@/features/auth/api/authAPI";
import Spinner from "../Spinner/Spinner";

const BillingPlanTeams: React.FC<BillingPlanTeamsProps> = ({
  sliderValue,
  handleSliderChange,
  handleSelectPlan,
  pendingPlanData,
}) => {
  const { data: apiDataPlans } = useGetPlansQuery();

  if (!apiDataPlans || apiDataPlans.length === 0) {
    return (
      <div className={styles.root}>
        <Spinner />
      </div>
    );
  }

  const plan = apiDataPlans[0];
  const { unit_amount, id: priceId } = plan;

  
  // works as long azs recurring_interval is "month"

  const pricePerUser = unit_amount / 100 / plan.recurring_interval_count;
  const totalPrice = sliderValue * pricePerUser;

  const billedEvery = plan.recurring_interval === 'month' && plan.recurring_interval_count === 1 ? 'Monthly' : `Every ${plan.recurring_interval_count} ${plan.recurring_interval}s`

  return (
    <div className={styles.root}>
      <div className={styles.planTeamsSlider}>
        <span className={styles.planTeamsMin}>2</span>
        <CustomSlider
          min={2}
          max={20}
          step={1}
          value={sliderValue}
          onChange={handleSliderChange}
        />
        <span className={styles.planTeamsMax}>20</span>
      </div>
      <div className={styles.planTeamsPrice}>
        <p>
          Full access to all app designs, advanced search, and additional
          features.
        </p>
        <h1 className={styles.planTeamsPrice__price}>
          ${totalPrice.toFixed(2)}/<span>per month</span>
        </h1>
        <span className={styles.planTeamsBilled}>Billed {billedEvery}</span>
      </div>
      <div className={styles.planTeamsButton}>
        <button onClick={() => handleSelectPlan(priceId, sliderValue)}>
          {" "}
          {pendingPlanData ? "Processing..." : "Get Team Plan"}
        </button>
      </div>
      <div className={styles.footerText}>
        <a href="mailto:hi@screensdesign.com">
          Contact us if you need a bigger plan
        </a>
      </div>
    </div>
  );
};

export default BillingPlanTeams;
