import React from "react";
import classNames from "classnames";
import { rates } from "../../libs/data/rates";
import useHandleRateClick from "../../libs/hook/useHandleRateClick";
import { RateMap } from "../../libs/types/enum";
import styles from "./ButtonPay.module.scss";

interface ButtonPayProps { }

const ButtonPay: React.FC<ButtonPayProps> = () => {
  const { isOpen, activeRate, handleRateClick, setIsOpen } =
    useHandleRateClick();

  return (
    <div className={styles.root}>
      <div onClick={() => setIsOpen(!isOpen)}>
        {isOpen ? (
          <div className={styles.buttonContainer}>
            <div className={styles.buttonWithMenu}>
              <p>Speed</p>
              <div className={styles.buttonRateActive}>
                <span>{RateMap[activeRate.rate as keyof typeof RateMap]}</span>
                <img
                  src="/img/blackIcon/DropDown.svg"
                  style={{ rotate: "180deg" }}
                  alt="arrowDown"
                  loading="lazy"
                />
              </div>
              {/* <p className={styles.rateNameText}>{activeRate.rateName}</p> */}
            </div>
            <div className={styles.dropMenu}>
              <ul className={styles.dropMenuList}>
                {rates.map((item, index) => {
                  return (
                    <div
                      key={index}
                      onClick={(event) =>
                        handleRateClick(item.rate, item.rateName, event)
                      }
                      className={classNames(styles.dropItem, {
                        [styles.dropItemActive]: item.rate === activeRate.rate,
                      })}
                    >
                      <li>{item.rate}</li>
                      {/* <li>{item.rateName}</li> */}
                    </div>
                  );
                })}
              </ul>
            </div>
          </div>
        ) : (
          <div className={styles.buttonContainer}>
            <div className={styles.buttonWithoutMenu}>
              <p>Speed</p>
              <div className={styles.buttonRate}>
                <span>{RateMap[activeRate.rate as keyof typeof RateMap]}</span>
                <img src="/img/blackIcon/DropDown.svg" alt="arrowDown" loading="lazy" />
              </div>
              {/* <p className={styles.rateNameText}>{activeRate.rateName}</p> */}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ButtonPay;
