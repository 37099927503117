import React from "react";
import styles from "./SavedModeOrganizationItem.module.scss";
import { useGradient } from '@/Hooks/useGradient';

interface SavedModeOrganizationItemProps {
  name: string;
  isActive: boolean;
  onClick: () => void;
  img?: string;
}

const SavedModeOrganizationItem: React.FC<SavedModeOrganizationItemProps> = ({
  name,
  isActive,
  onClick,
  img,
}) => {
  const firstLetter = name.charAt(0);
  const gradient = useGradient(firstLetter);

  return (
    <div
      onClick={onClick}
      className={`${styles.root} ${isActive ? styles.active : ""}`}
    >
      {img ? (
        <img 
          className={styles.image}
          src={img} 
          alt={name} 
          loading="lazy" 
        />
      ) : (
        <div 
          className={styles.image}
          style={{ background: gradient }}
        >
          {firstLetter}
        </div>
      )}
      <span className={styles.name} title={name}>{name}</span>
    </div>
  );
};

export default SavedModeOrganizationItem;
