import React from 'react';
import styles from "./UnlockPro.module.scss";

interface UnlockProProps {
  dataImgPro: Array<{ img: string; id: number | null; screen?: string; labels?: string }>;
  handleOpenBillingPage: () => void;
}

const UnlockPro: React.FC<UnlockProProps> = ({  dataImgPro, handleOpenBillingPage, variant }) => {

  return (
    <div className={`${styles.unlockProContainer} ${variant ? styles[variant] : ''}`}>
      <div className={styles.photo}>
        <div className={styles.photoContainer}>
          <img
            className={styles.unlockProItem}
            src="/img/unlockProItemIcon.svg"
            alt="unlockProItem"
          />
          <div className={styles.unlockProPhotos}>
            {dataImgPro.map((item, index) => (
              <img
                key={index}
                className={styles.unlockProImage}
                src={item.screen || item.img}
                alt={`unlockProImg${index + 1}`}
              />
            ))}
          </div>
          <div className={styles.unlockProContent}>
            <div className={styles.unlockProContentTitle}>
              <h1>Upgrade to Pro for unlimited access</h1>
              <p>
                Just <span>$25/month</span>
              </p>
            </div>
            <button
              onClick={handleOpenBillingPage}
            >
              <span>Unlock Pro</span>
            </button>
            <div className={styles.unlockProIcons}>
              <ul className={styles.list}>
                <li>
                  <img src="/img/imageAppIcon1.jpg" alt="icon" /></li>
                <li>
                  <img src="/img/imageAppIcon2.jpg" alt="icon2" /></li>
                <li>
                  <img src="/img/imageAppIcon3.jpg" alt="icon3" /></li>
                <li>
                  <img src="/img/imageAppIcon4.jpg" alt="icon4" /></li>
                <div className={styles.unlockProIconsText}>
                  <h3>1500+ Apps</h3>
                  <p>20 new every week</p>
                </div>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UnlockPro;
