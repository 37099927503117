import React from "react";
import { useDispatch, useSelector } from "react-redux";
import HeaderWithLogin from "@/widgets/headerWithLogin/ui/HeaderWithLogin/HeaderWithLogin";
import ModalMenu from "@/widgets/modalMenu/ui/ModalMenu/ModalMenu";
import { getModalMenuState } from "@/entities/modalMenu";
import { LayoutHeaderProps } from "../../libs/types/LayoutHeaderProps";
import { useMe, useCurrentOrganization } from '@/Hooks'
import { useEffect } from "react";
import Modal from "react-modal";
import { saveModeActions } from "@/entities/saveMode";
import BillingPage from "@/pages/BillingPage/BillingPage";
import styles from "@/pages/Homepage/Homepage.module.scss";
import mixpanel from "@/shared/lib/mixpanel";
import { identifyUser } from '@/shared/lib/smartlook';
import { useNavigate } from "react-router-dom";


const LayoutHeader: React.FC<LayoutHeaderProps> = (
  {
    children,
    filterButtonPlaceHolder,
    searchBarPlaceHolder
  }
) => {

  const { me, loading } = useMe()
  const currentOrganization = useCurrentOrganization()
  const isVisible = useSelector(getModalMenuState);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isActiveProModal } = useSelector((state: boolean) => state.saveMode);

  useEffect(() => {
    // const showBillingAfterVerification = localStorage.getItem('showBillingAfterVerification')
    // if (showBillingAfterVerification === 'true') {
    //   if (!currentOrganization?.is_pro) {
    //     dispatch(saveModeActions.activeProModal());
    //   }
    // }
    // if (showBillingAfterVerification == "yes" && currentOrganization?.is_pro == false) {
    //   dispatch(saveModeActions.activeProModal());
    // }
  }, [me, currentOrganization]);

  useEffect(() => {
    if (me?.email) {
      mixpanel.identify(me.email);
      setTimeout(() => {
        mixpanel.people.set({
          $email: me.email,
          $name: me.username,
        });
      }, 100);

      identifyUser(me.email, {
        name: me.username || '',
        organization: currentOrganization?.uuid || ''
      });
    }
  }, [me, currentOrganization]);

  useEffect(() => {
    const isMobileDevice = () => {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;
      return /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(userAgent.toLowerCase());
    };

    const handleRedirect = () => {
      const isMobile = isMobileDevice();
      const isMobileWidth = window.innerWidth < 550;

      if (isMobile && isMobileWidth && !me && !loading) {
        navigate('/landing-mobile/');
      } else if (isMobile && isMobileWidth && me && !loading) {
        navigate('/desktop/');
      }
    };

    window.addEventListener('resize', handleRedirect);
    handleRedirect();

    return () => window.removeEventListener('resize', handleRedirect);
  }, [me, loading, navigate]);

  return (
    <div>
      {/* {me ? ( */}
      <HeaderWithLogin
        filterButtonPlaceHolder={filterButtonPlaceHolder}
        searchBarPlaceHolder={searchBarPlaceHolder}
      />

      {isVisible && (<ModalMenu />)}
      {children}
      <Modal
        isOpen={isActiveProModal}
        onRequestClose={() => dispatch(saveModeActions.deactiveProModal())}
        contentLabel="Groups Modal"
        className={styles.modalPayment}
        overlayClassName={styles.overlay}
        shouldCloseOnOverlayClick={false}
      >

        <BillingPage
          isOpen={isActiveProModal}
          onClose={() => dispatch(saveModeActions.deactiveProModal())}
        />

      </Modal>
    </div>
  );
};

export default LayoutHeader;
