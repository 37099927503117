import { useEffect, useRef, useState } from "react";
import { useOutletContext } from 'react-router-dom'
import ModalSaveMode from "@/widgets/modalSaveMode/ui/ModalSaveMode/ModalSaveMode";
import styles from "@/widgets/modalSaveMode/ui/ModalSaveMode/ModalSaveMode.module.scss";
import { useCurrentOrganization } from '@/Hooks'
import { useGetSavedAnimationsQuery } from "@/features/auth/api/authAPI";

const SavedModeAnimationView = () => {
    const { sortkey, userFilter } = useOutletContext()
    const [activeIndex, setActiveIndex] = useState(1);
    const [page, setPage] = useState(1);
    const PAGE_SIZE = 10;
    const [allResults, setAllResults] = useState<any[]>([]);
    const [sortDirectionSavedMode, setSortDirectionSavedMode] = useState("desc");
    const animationGroups = useRef<HTMLDivElement>(null);
    const currentOrganization = useCurrentOrganization();

    const handleItemClick = (index: number) => { setActiveIndex(index); };

    const { data: savedAnimations, refetch: refetchSavedAnimations, isLoading, isFetching } = useGetSavedAnimationsQuery({
        organizationUuid: currentOrganization?.uuid || '',
        params: {
            sort: sortkey,
            user: userFilter,
            page: page,
            pageSize: PAGE_SIZE
        }
    }, {
        skip: !currentOrganization
    });

    useEffect(() => {
        if (savedAnimations?.results && page === 1) {
            setAllResults(savedAnimations.results);
        } else if (savedAnimations?.results) {
            setAllResults(prev => [...prev, ...savedAnimations.results]);
        }
    }, [savedAnimations?.results, page]);

    const combinedData = {
        ...savedAnimations,
        results: allResults
    };

    return (
        <div className={styles.mainContent}>
            <div ref={animationGroups} className={styles.photosContainer}>
                <ModalSaveMode
                    dataSavedPoint={combinedData}
                    activeIndex={activeIndex}
                    handleItemClick={handleItemClick}
                    apiDataSharedViews={undefined}
                    sharedViewsQuery={null}
                    apiDataSavedStoreScreens={undefined}
                    apiDataSharedItem={undefined}
                    sharedGroupsQuery={null}
                    refetchSavedPoints={refetchSavedAnimations}
                    apiDataSharedItemSavedPoints={undefined}
                    apiDataSharedItemStoreScreens={undefined}
                    sortDirection={sortDirectionSavedMode}
                    setSortDirection={setSortDirectionSavedMode}
                    viewType="animation"
                    page={page}
                    setPage={setPage}
                    isLoading={isLoading}
                    isFetching={isFetching}
                    pageSize={PAGE_SIZE}
                />
            </div>
        </div>
    );
};

export { SavedModeAnimationView };
