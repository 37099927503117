// redux
import { isRejectedWithValue } from "@reduxjs/toolkit";
// types
import type { MiddlewareAPI, Middleware } from "@reduxjs/toolkit";
import { userActions } from "@/entities/user";

export const AuthErrorMiddleware: Middleware =
  (api: MiddlewareAPI) => (next) => async (action) => {
    if (isRejectedWithValue(action)) {
      if (action.payload && action.payload.status == "401") {
        api.dispatch(userActions.logout());
      } else {
        console.warn("We got a rejected action:", action);
      }
    }

    return next(action);
  };
