// react
import React, { useEffect } from "react";
// redux
import { useDispatch, useSelector } from "react-redux";
// entitites
import { filterAppsActions, getFilterAppsState } from "@/entities/filterApps";
import { filterAppsDataActions } from "@/entities/filterAppsData";
import { getModalFiltersState } from "@/entities/modalFilters/model/selectors/getModalFiltersState";
import { modalFiltersActions } from "@/entities/modalFilters/model/slice/modalFiltersSlice";
import { getFilterInputSearchState } from "@/entities/filterInputSearch";
import {
  activeModalIndexActions,
  getActiveModalIndex,
} from "@/entities/activeModalIndex";
import { filterActions } from "@/entities/filter";
// shared
import FilterModalElement from "@/shared/ui/FilterModalElement/FilterModalElement";
// types
import { FilterModalItemProps } from "../../types/types";
// styles
import styles from "./FilterModalItem.module.scss";
import { useNavigate } from "react-router-dom";
import { useMe } from '@/Hooks/useMe';

const FilterModalItem: React.FC<FilterModalItemProps> = ({
  dataModal,
  selectedFilters,
  showContainsWordFilter,
  dataCategories,
  modals,
  refetch,
  isNext,
  rootActiveView,
  ref
}) => {
  // selectors
  const {
    isCategoryOpen,
    isMontlyInstallsOpen,
    isMontlyRevenueOpen,
    isRevInstallRateOpen,
    isOnboardingStepsOpen,
    isPaywallOpen,
    isContainsWords,
    isLabelledOpen,
    isReleaseDateOpen,
    isUpdateDateOpen,
    isHiddenOpen,
    isFlowsOpen,
  } = useSelector(getModalFiltersState);
  const {
    oscgte,
    osclte,
    revenuegte,
    revenuelte,
    downloadgte,
    downloadlte,
    selectedDataMax,
    selectedDataMin,
    selectedDataRangeMax,
    selectedDataRangeMin,
    only,
    avsTags,
  } = useSelector(getFilterAppsState);
  const { appName } = useSelector(getFilterInputSearchState);
  const { activeModalIndexFilterItem } = useSelector(getActiveModalIndex);
  // dispatch
  const dispatch = useDispatch();
  // navigate
  const navigate = useNavigate();
  const accessToken = localStorage.getItem("access_token");
  const { me, loading } = useMe();

  const handleItemClick = (index: number) => {

    const isCurrentlyActive = activeModalIndexFilterItem === index;

    if (isCurrentlyActive) {
      dispatch(activeModalIndexActions.setActiveModalIndexFilterItem(null));

      if (isCategoryOpen) {
        dispatch(modalFiltersActions.closeCategoryModal());
      }

      if (isMontlyInstallsOpen) {
        dispatch(modalFiltersActions.closeMontlyInstallsModal());
      }

      if (isRevInstallRateOpen) {
        dispatch(modalFiltersActions.closeRevInstallRateModal());
      }

      if (isMontlyRevenueOpen) {
        dispatch(modalFiltersActions.closeMontlyRevenueModal());
      }

      if (isOnboardingStepsOpen) {
        dispatch(modalFiltersActions.closeOnboardingStepsModal());
      }

      if (isPaywallOpen) {
        dispatch(modalFiltersActions.closePaywallModal());
      }

      if (isContainsWords) {
        dispatch(modalFiltersActions.closeContainsWordsModal());
      }

      if (isLabelledOpen) {
        dispatch(modalFiltersActions.closeLabelledModal());
      }

      if (isReleaseDateOpen) {
        dispatch(modalFiltersActions.setIsReleaseDateOpen(false));
      }

      if (isUpdateDateOpen) {
        dispatch(modalFiltersActions.setIsUpdateDateOpen(false));
      }

      if (isHiddenOpen) {
        dispatch(modalFiltersActions.setIsHiddenOpen(false));
      }

      if (isFlowsOpen) {
        dispatch(modalFiltersActions.closeFlowsModal());
      }

      if (dataModal[index].filterName !== "") {
        dispatch(filterAppsDataActions.resetFilterAppDataSorted());
        dispatch(filterAppsActions.resetPageFilter());
        dispatch(filterAppsActions.deactiveIsFilter());
        dispatch(filterAppsActions.setFilterSort(""));
      }
    } else {
      if (activeModalIndexFilterItem !== null) {
        dispatch(filterAppsActions.deactiveIsFilter());
      }

      if (isCategoryOpen) {
        dispatch(modalFiltersActions.closeCategoryModal());
      }

      if (isMontlyInstallsOpen) {
        dispatch(modalFiltersActions.closeMontlyInstallsModal());
      }

      if (isRevInstallRateOpen) {
        dispatch(modalFiltersActions.closeRevInstallRateModal());
      }

      if (isMontlyRevenueOpen) {
        dispatch(modalFiltersActions.closeMontlyRevenueModal());
      }

      if (isOnboardingStepsOpen) {
        dispatch(modalFiltersActions.closeOnboardingStepsModal());
      }

      if (isPaywallOpen) {
        dispatch(modalFiltersActions.closePaywallModal());
      }

      if (isContainsWords) {
        dispatch(modalFiltersActions.closeContainsWordsModal());
      }

      if (isLabelledOpen) {
        dispatch(modalFiltersActions.closeLabelledModal());
      }

      if (isReleaseDateOpen) {
        dispatch(modalFiltersActions.setIsReleaseDateOpen(false));
      }

      if (isUpdateDateOpen) {
        dispatch(modalFiltersActions.setIsUpdateDateOpen(false));
      }

      if (isHiddenOpen) {
        dispatch(modalFiltersActions.setIsHiddenOpen(false));
      }

      if (isFlowsOpen) {
        dispatch(modalFiltersActions.closeFlowsModal());
      }

      dispatch(activeModalIndexActions.setActiveModalIndexFilterItem(index));

      if (dataModal[index].filterName !== "") {
        dispatch(filterAppsActions.resetPageFilter());
        dispatch(filterAppsDataActions.resetFilterAppDataSorted());
        dispatch(filterAppsActions.setFilterSort(dataModal[index].filterName));
        dispatch(filterAppsActions.activeIsFilter());
      }
    }
  };

  const handleClickCategory = () => {
    //console.log('handleClickCategory')
    dispatch(modalFiltersActions.openCategoryModal());
    dispatch(filterActions.activateCategory());
  };

  const handleClickRevInstallRate = () => {
    //console.log('handleClickRevInstallRate')
    dispatch(modalFiltersActions.openRevInstallRateModal());
  };

  const handleClickInstalls = () => {
    dispatch(modalFiltersActions.openMontlyInstallsModal());
  };

  const handleClickRevenue = () => {
    dispatch(modalFiltersActions.openMontlyRevenueModal());
  };

  const handleClickOnboarding = () => {
    dispatch(modalFiltersActions.openOnboardingStepsModal());
  };

  const handleClickPaywall = () => {
    dispatch(modalFiltersActions.openPaywallModal());
  };

  const handleClickContains = () => {
    dispatch(modalFiltersActions.openContainsWordsModal());
  };

  const handleClickFlows = () => {
    dispatch(modalFiltersActions.openFlowsModal());
  };

  // const handleClickLabelled = () => {
  //   dispatch(modalFiltersActions.openLabelledModal());
  // };

  const handleClickRelease = () => {
    dispatch(modalFiltersActions.setIsReleaseDateOpen(true));
  };

  const handleClickUpdate = () => {
    dispatch(modalFiltersActions.setIsUpdateDateOpen(true));
  };

  const handleClickHidden = () => {
    if (!accessToken) {
      dispatch(filterActions.deactivateFilter());
      navigate("/login");
    } else {
      dispatch(modalFiltersActions.setIsHiddenOpen(true));
    }
  };


  useEffect(() => {
    if (activeModalIndexFilterItem === null) { return }
    if (activeModalIndexFilterItem === 10) { handleClickRevInstallRate() }
    if (activeModalIndexFilterItem === 7) { handleClickUpdate() }
    if (activeModalIndexFilterItem === 6) { handleClickRelease() }
    if (activeModalIndexFilterItem === 8 && me) { handleClickHidden() }
    if (activeModalIndexFilterItem === 5) { handleClickOnboarding() }
    if (activeModalIndexFilterItem === 4) { handleClickPaywall() }
    if (activeModalIndexFilterItem === 3) { handleClickRevenue() }
    if (activeModalIndexFilterItem === 2) { handleClickInstalls() }
    if (activeModalIndexFilterItem === 1) { handleClickCategory() }
    if (activeModalIndexFilterItem === 0) { handleClickContains() }
    if (activeModalIndexFilterItem === 9 && rootActiveView === 'web-onboardings') {
      handleClickFlows()
    }
    /* {activeModalIndex === 6 && <ModalSelectPrice />} */
    /* {activeModalIndex === 3 && <ModalColorsFilter />}  */
  }, [activeModalIndexFilterItem, me])

  return (
    <div className={styles.root}>
      {dataModal.map((item, index) => {
        if (index === 8 && (!me || rootActiveView === 'web-onboardings')) return null;
        if (index === 4 && (rootActiveView === 'web-onboardings' || rootActiveView === 'animation-explorer')) return null;
        if (index === 5 && rootActiveView === 'web-onboardings') return null;
        if (index === 9 && rootActiveView !== 'web-onboardings') return null;

        return (
          <FilterModalElement
            key={index}
            img={item.img}
            text={item.text}
            imgActive={item.imgActive}
            filterName={item.filterName}
            onItemClick={() => handleItemClick(index)}
            isActive={activeModalIndexFilterItem === index}
            modals={modals}
            showIcon={item.text === "Category" && dataCategories!.length > 0}
            showIconInstalls={
              item.text === "Monthly Installs" && downloadlte !== "Max"
            }
            showIconInstallsMin={
              item.text === "Monthly Installs" && downloadgte !== "Min"
            }
            showIconRevenue={
              item.text === "Monthly Revenue" && revenuelte !== "Max"
            }
            showIconRevenueMin={
              item.text === "Monthly Revenue" && revenuegte !== "Min"
            }
            showIconOnboarding={
              item.text === "Onboarding Steps" && osclte !== "Max"
            }
            showIconOnboardingMin={
              item.text === "Onboarding Steps" && oscgte !== "Min"
            }
            showIconPaywall={
              item.text === "Paywall Type" &&
              selectedFilters!.paywallType !== ""
            }
            showIconLabelled={
              item.text === "Labelled for you" && avsTags !== ""
            }
            showIconRelease={
              item.text === "Release Date" &&
              (selectedDataMax !== "Max" || selectedDataMin !== "Min")
            }
            showIconUpdate={
              item.text === "Last Update Date" &&
              (selectedDataRangeMax !== "Max" || selectedDataRangeMin !== "Min")
            }
            showIconContains={item.text === "App Name" && appName !== ""}
            showIconHidStar={
              item.text === "Hidden and Favorites" && only !== "not_hidden" && !!me
            }
            showContainsWordFilter={showContainsWordFilter}
            refetch={refetch}
            isNext={isNext}
            rootActiveView={rootActiveView}
            ref={ref}
            showIconFlows={
              item.text === "Flows" && selectedFilters?.flowType !== ""
            }
          />
        );
      })}
    </div>
  );
};

export default FilterModalItem;
