// import Lottie from "lottie-react";
// import spinnerAnimation from "../loader.json";
import { FC } from "react";

interface SpinnerProps {
  width?: number;
  color?: string;
}

const Spinner: FC<SpinnerProps> = ({ width, color = '#D8D0FF' }) => {
  // red:
  // temporary disabling of the spinner because of lottie cpu issues on safari
  // todo: find alternative or better way to do this
  return (
    // <Lottie
    //   animationData={spinnerAnimation}
    //   loop={true}
    //   autoplay={true}
    //   style={{ width: width ? width : "50px" }}
    // />

    <div style={{ width: width ? width : "50px" }}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200">
        <linearGradient id="a11">
          <stop offset="0" stopColor={color} stopOpacity="0" />
          <stop offset="1" stopColor={color} />
        </linearGradient>
        <circle fill="none" stroke="url(#a11)" strokeWidth="15" strokeLinecap="round" strokeDasharray="0 44 0 44 0 44 0 44 0 360" cx="100" cy="100" r="70" transform-origin="center">
          <animateTransform type="rotate" attributeName="transform" calcMode="discrete" dur="0.5" values="360;324;288;252;216;180;144;108;72;36" repeatCount="indefinite"></animateTransform>
        </circle>
      </svg>
    </div>
  );
};

export default Spinner;
