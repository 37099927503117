// redux
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
// types
import { ZoomState } from "../types/zoomTypes";

const initialState: ZoomState = {
  zoom: 1,
  zoomGroupSaveMode: 1,
};

const zoomLevels = [2.0, 1.75, 1.5, 1.25, 1, 0.7, 0.5];

const zoomSlice = createSlice({
  name: "zoom",
  initialState,
  reducers: {
    setZoom: (state, action: PayloadAction<number>) => {
      state.zoom = action.payload;
    },
    zoomIn: (state) => {
      const currentIndex = zoomLevels.indexOf(state.zoom);
      const nextIndex = currentIndex - 1;
      state.zoom = zoomLevels[nextIndex] ?? state.zoom;
    },
    zoomOut: (state) => {
      const currentIndex = zoomLevels.indexOf(state.zoom);
      const nextIndex = currentIndex + 1;
      state.zoom = zoomLevels[nextIndex] ?? state.zoom;
    },
    zoomInGroupSaveMode: (state) => {
      const currentIndex = zoomLevels.indexOf(state.zoomGroupSaveMode);
      const nextIndex = currentIndex - 1;
      state.zoomGroupSaveMode =
        zoomLevels[nextIndex] ?? state.zoomGroupSaveMode;
    },
    zoomOutGroupSaveMode: (state) => {
      const currentIndex = zoomLevels.indexOf(state.zoomGroupSaveMode);
      const nextIndex = currentIndex + 1;
      state.zoomGroupSaveMode =
        zoomLevels[nextIndex] ?? state.zoomGroupSaveMode;
    },
    resetZoomGroupSaveMode: (state) => {
      state.zoomGroupSaveMode = initialState.zoomGroupSaveMode;
    },
    resetZoom: (state) => {
      state.zoom = initialState.zoom;
    },
  },
});

export const { actions: zoomActions, reducer: zoomReducer } = zoomSlice;
