// redux
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
// features
import { UserResponseData } from "@/features/auth/types/auth";
// types
import { meState } from "../types/meTypes";

const initialState: meState = {
  apiDataMe: {
    id: 0,
    username: "Guest",
    email: "",
    first_name: "",
    last_name: "",
    organizations: {
      0: {
        id: 0,
        name: "",
        role: "",
      },
    },
  },
};

const meSlice = createSlice({
  name: "meUser",
  initialState,
  reducers: {
    setMe: (state, action: PayloadAction<UserResponseData>) => {
      state.apiDataMe = action.payload;
    },
    resetMe: (state) => {
      state.apiDataMe = initialState.apiDataMe;
    },
  },
});

export const { actions: meUserActions, reducer: meUserReducer } = meSlice;
