import React, { useRef } from 'react';
import styles from './AnimationCard.module.scss';
import ContentLoader from "react-content-loader";
import { useMe, useCurrentOrganization } from "@/Hooks";
import { handleOpenVideoPage } from '@/app/libs/const/handleOpenVideoPage';

interface AnimationCardProps {
    id: number;
    url: string;
    name: string;
    icon: string;
    shortname: string;
    selectActive?: boolean;
    isSelected?: boolean;
    onSelect?: (id: number, url: string, videoId: number) => void;
    appId: number;
    slug: string;
    videoId: number;
}

const AnimationCard: React.FC<AnimationCardProps> = ({
    id,
    url,
    name,
    icon,
    shortname,
    selectActive = false,
    isSelected = false,
    onSelect,
    appId,
    slug,
    videoId
}) => {
    const { me } = useMe();
    const currentOrganization = useCurrentOrganization();
    const videoRef = useRef<HTMLVideoElement>(null);

    const handleClick = (e: React.MouseEvent) => {
        e.stopPropagation();
        if (selectActive && onSelect) {
            onSelect(id, url, videoId);
        } else {
            redirectToVideoPage(e);
        }
    };

    const redirectToVideoPage = (e: React.MouseEvent) => {
        e.stopPropagation();
        handleOpenVideoPage(
            0,
            slug,
            "Home Page screens",
            1,
            appId,
            videoId,
            undefined,
            me?.username || "",
            currentOrganization?.uuid || ""
        );
    };

    const handleMouseEnter = () => {
        if (videoRef.current) {
            videoRef.current.play();
        }
    };

    const handleMouseLeave = () => {
        if (videoRef.current) {
            videoRef.current.pause();
            videoRef.current.currentTime = 0;
        }
    };

    return (
        <div
            className={`${styles.root} ${isSelected ? styles.selectedRoot : ''}`}
            onClick={handleClick}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            <div
                onClick={(e) => {
                    e.stopPropagation();
                    if (onSelect) {
                        onSelect(id, url, videoId);
                    }
                }}
                className={isSelected ? styles.hoverElementActive : styles.hoverElement}
            >
                {isSelected && (
                    <img
                        src="/img/whiteIcon/Check.svg"
                        alt="checkCircleImg"
                    />
                )}
            </div>

            <div className={styles.groupPhotoHeaderTitle}>
                <img
                    src={icon || "/img/skeletonLogo.svg"}
                    alt="appIcon"
                    loading="lazy"
                />
                <div className={styles.groupPhotoTitle}>
                    <h3 title={name}>{name || 'Untitled'}</h3>
                    <p title={shortname}>{shortname || 'No description'}</p>
                </div>
            </div>

            <div className={`${styles.groupPhotoScreen} ${isSelected ? styles.selectedPhotoContainer : ''}`}>
                {url ? (
                    <video
                        ref={videoRef}
                        src={url}
                        className={`${styles.videoContent} ${isSelected ? styles.selectedVideo : ''}`}
                        loop
                        muted
                        playsInline
                        preload="auto"
                    />
                ) : (
                    <ContentLoader
                        speed={2}
                        width={290}
                        height={493}
                        viewBox="0 0 290 493"
                        backgroundColor="#f3f3f3"
                        foregroundColor="#ecebeb"
                    >
                        <rect x="0" y="0" rx="12" ry="12" width="290" height="493" />
                    </ContentLoader>
                )}
            </div>
        </div>
    );
};

export { AnimationCard };
