import React, { useEffect, useState, useRef, useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useInView } from "react-intersection-observer";
import { useAutoAnimate } from "@formkit/auto-animate/react";
import { useDispatch, useSelector } from "react-redux";
import {
  useDeleteSavedAppsMutation,
  useHiddenAppsMutation,
  useSavedAppsMutation,
  useGetAppVideoScreensPaginatedQuery,
} from "@/features/auth/api/authAPI";
import { modalMenuPhoneActions } from "@/entities/modalMenuPhone";
import { filterAppsDataActions, getFilterAppsData } from "@/entities/filterAppsData";
import { getFilterState } from "@/entities/filter";
import { saveModeActions } from "@/entities/saveMode";
import InfoPhoneHomePageHorizontal from "@/shared/ui/InfoPhoneHomePageHorizontal/InfoPhoneHomePageHorizontal";
import { modalItemData } from "@/widgets/phoneHomePageHorisontal/libs/const/modalItemData";
import { formatNumber } from "@/widgets/phoneHomePageHorisontal/libs/const/formatNumber";
import { IoIosCheckmarkCircle } from "react-icons/io";
import {
  DisplayDataItem,
  PhoneHomePageHorisontalProps,
} from "@/widgets/phoneHomePageHorisontal/libs/types/type";
import styles from "./PhoneHomePageHorisontalNew.module.scss";
import { useMe } from '@/Hooks'
import UnlockPro from "@/shared/ui/UnlockPro/UnlockPro";
import MenuButtons from '@/widgets/phoneHomePageHorisontal/ui/MenuButtons/MenuButtons';
import mixpanel from "@/shared/lib/mixpanel";
import { RightClickModal } from "@/shared/ui/RightClickModal/RightClickModal";
import { toast } from "react-toastify";
import { handleOpenVideoPage } from "@/app/libs/const/handleOpenVideoPage";
import PhotoItemNew from "./PhotoItemNew";

export interface IAppVideoScreensData {
  id: number;
  screen: string;
  timestamp: string;
  labels: string[];
  app_video?: number;
  name?: string;
}

export interface IImages extends IAppVideoScreensData {
  highlighter: boolean;
  isGrouped: boolean;
  label: string;
  is_saved?: boolean;
  is_saved_pk?: number;
}

interface DisplayDataItem {
  label: string;
  images: IImages[];
  isGrouped: boolean;
}

interface Organization {
  uuid: string;
  is_pro: boolean;
}

interface User {
  email: string;
  username: string;
  is_pro: boolean;
}

interface PhoneHomePageHorisontalProps {
  isOpen: boolean;
  dataApi: any;
  setIsPhonePageLoading: (state: boolean) => void;
  isFirstGroup: boolean;
  currentOrganization: Organization;
  isPaywallOnly: boolean;
  isPhonePageLoading: boolean;
  ScreenNotBlurredID?: number;
  app_video_id?: number;
}

const MemoizedPhotoItemNew = React.memo(PhotoItemNew);

const PhoneHomePageHorisontalNew = React.forwardRef<
  HTMLDivElement,
  PhoneHomePageHorisontalProps
>(
  (
    {
      isOpen,
      dataApi,
      setIsPhonePageLoading,
      isFirstGroup,
      currentOrganization,
      isPaywallOnly,
      ScreenNotBlurredID,
      app_video_id,
    },
    ref,
  ) => {


    const InfoItemData = [
      {
        img: "dollarFilterIconActive",
        title: "Monthly Revenue:",
        text: formatNumber(dataApi?.revenue),
      },
      {
        img: "downloadFilterIconActive",
        title: "Monthly Installs:",
        text: formatNumber(dataApi?.downloads),
      },
      {
        img: "creditCardLockActive",
        title: "Paywall Type:",
        text: dataApi?.avs?.paywall_type,
      },
      {
        img: "dotsGridActive",
        title: "Onboarding Steps:",
        text: dataApi?.avs?.onboarding_step_count,
      },
    ];

    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState<number>(11);
    const [isInfoModal, setIsInfoModal] = useState<boolean>(false);
    const [textInfoModa, setTextInfoModal] = useState<string>("");
    const [showStarredMessage, setShowStarredMessage] = useState(false);
    const [isStarClickLoading, setIsStarClickLoading] =
      useState<boolean>(false);
    const [isHiddenClickLoading, setIsHiddenClickLoading] =
      useState<boolean>(false);
    const [showHiddenMessage, setShowHiddenMessage] = useState<boolean>(false);
    const [data, setData] = useState<DisplayDataItem[]>([]);
    const scrollContainerRef = useRef<HTMLDivElement>(null);
    const [isScrolling, setIsScrolling] = useState(false);
    const [storedProImages, setStoredProImages] = useState<Array<{
      img: string;
      id: number | null;
    }> | null>(null);
    const phoneHomePageHorizontalRef = useRef<HTMLDivElement>(null);
    const [loadedImagesCount, setLoadedImagesCount] = useState(0);
    const [totalImagesCount, setTotalImagesCount] = useState(0);
    const [showSkeleton, setShowSkeleton] = useState(true);
    const imageRefs = useRef<{ [key: string]: HTMLDivElement | null }>({});
    const [isSearchingPaywall, setIsSearchingPaywall] = useState(false);
    const [, setHighlightedImages] = useState<string[]>([]);
    const { isActiveMagic } = useSelector(getFilterState);
    const navigate = useNavigate();
    const { me } = useMe() as { me: User };
    const [appVideoScreens, setAppVideoScreens] = useState<IImages[]>([]);
    const [appVideoScreensNext, setAppVideoScreensNext] = useState<string | null>(null);
    const [appVideoScreensCount, setAppVideoScreensCount] = useState<number>(0);
    const [appVideoScreensLoading, setAppVideoScreensLoading] = useState<boolean>(false);
    const [appVideoScreensFetching, setAppVideoScreensFetching] = useState<boolean>(false);
    const [hasMore, setHasMore] = useState(true);
    const spinnerRef = useRef(null);

    const [isDragging, setIsDragging] = useState(false);
    const [startX, setStartX] = useState(0);
    const [scrollLeft, setScrollLeft] = useState(0);    

    // Add new state for tracking screen width
    const [isWideScreen, setIsWideScreen] = useState(window.innerWidth > 768);

    // Add effect to update screen width state
    useEffect(() => {
      const handleResize = () => {
        setIsWideScreen(window.innerWidth > 768);
      };

      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, []);

    // appvideoscreens
    const {
      data: paginatedScreens,
      isLoading: isScreensLoading,
      isFetching: isScreensFetching,
      error: screensError,
      refetch: refetchAppVideoScreens
    } = useGetAppVideoScreensPaginatedQuery(
      {
        appId: dataApi?.id,
        pageSize: pageSize,
        page: page
      },
      {
        skip: !dataApi?.id || !hasMore
      }
    );

    const organization = currentOrganization as Organization;


    // useEffect(() => {
    //   setAppVideoScreens([]);
    // }, [dataApi.id]);

    useEffect(() => {
      if (!paginatedScreens) return;

      setAppVideoScreens(prevScreens => {
        const currentScreens = prevScreens || [];
        const newScreens = paginatedScreens.results.map(screen => ({
          ...screen,
          highlighter: false,
          isGrouped: false,
          label: screen.labels?.[0] || "",
        }));
        return [...currentScreens, ...newScreens];
      });

      setHasMore(!!paginatedScreens.next);
      setAppVideoScreensNext(paginatedScreens.next);
      setAppVideoScreensCount(paginatedScreens.count);
    }, [paginatedScreens]);

    useEffect(() => {
      setAppVideoScreensFetching(isScreensFetching);
      setAppVideoScreensLoading(isScreensLoading);
      dispatch(filterAppsDataActions.setIsScreensLoadingHome(isScreensLoading));
    }, [isScreensFetching, isScreensLoading]);

    useEffect(() => {
      if (screensError) {
        if ('status' in screensError && screensError.status === 404) {
          setHasMore(false);
        }
        console.log('Error fetching video screens:', screensError);
      }
    }, [screensError]);

    React.useEffect(() => {
      if (isActiveMagic) {
        setPageSize(1000);
      }
    }, [isActiveMagic]);

    const { ref: refIntersection, inView } = useInView({
      threshold: 0,
    });

    const dispatch = useDispatch();
    const [modalPhoneHorisontalRef] = useAutoAnimate();
    const [hideApp] = useHiddenAppsMutation();
    const [saveApp, { isLoading: isSaveAppLoading }] = useSavedAppsMutation();
    const [deleteSavedApp, { isLoading: isDeleteSavedAppLoading }] = useDeleteSavedAppsMutation();


    const handleClickPhone = (timestamp: number, videoId: number) => {
      if (!isScrolling) {
        handleOpenVideoPage(
          timestamp,
          dataApi.slug,
          "Home Page screens",
          1,
          dataApi.id,
          videoId,
          undefined,
          me?.username || "",
          organization?.uuid || ""
        );
      }
    };
    const handleImageLoad = () => {
      setLoadedImagesCount((prev) => prev + 1);
    };

    const handleOpenBillingPage = (e: React.MouseEvent<HTMLDivElement>) => {
      e.stopPropagation();
      dispatch(saveModeActions.activeProModal());
    };

    if (
      (loadedImagesCount === totalImagesCount ||
        loadedImagesCount > totalImagesCount) &&
      totalImagesCount > 0 &&
      !appVideoScreensLoading
    ) {
      setTimeout(() => {
        setIsPhonePageLoading(true);
      }, 1000);
    }

    const loadMore = useCallback(() => {
      if (hasMore && !isScreensFetching) {
        setPage(prev => prev + 1);
      }
    }, [hasMore, isScreensFetching]);

    useEffect(() => {
      const observer = new IntersectionObserver(
        (entries) => {
          if (entries[0].isIntersecting && hasMore && !isScreensFetching) {
            loadMore();
          }
        },
        { threshold: 1.0 }
      );

      if (spinnerRef.current) {
        observer.observe(spinnerRef.current);
      }

      return () => {
        if (spinnerRef.current) {
          observer.unobserve(spinnerRef.current);
        }
      };
    }, [hasMore, isScreensFetching]);

    useEffect(() => {
      if (inView) {
        loadMore();
      }
    }, [inView]);

    const handleScroll = () => {
      if (scrollContainerRef.current && !isDragging) {
        setIsScrolling(true);
        setTimeout(() => setIsScrolling(false), 500);
      }
    };

    const handleMouseEnter = () => {
      dispatch(modalMenuPhoneActions.setModalId(dataApi.id));
    };

    const handleInView = useCallback((imageId: string, inView: boolean) => {
      setHighlightedImages((prev) => {
        const newImages = new Set(prev);
        if (inView) {
          if (!newImages.has(imageId)) {
            newImages.add(imageId);
            return Array.from(newImages);
          }
        } else {
          if (newImages.has(imageId)) {
            newImages.delete(imageId);
            return Array.from(newImages);
          }
        }
        return prev;
      });
    }, []);

    const handleHideClick = async () => {
      const isUserLogin = localStorage.getItem("access_token");

      if (!isUserLogin) {
        navigate("/login");
        localStorage.setItem("btnName", "hide");
        return;
      }
      setIsHiddenClickLoading(true);
      setIsInfoModal(false);

      try {
        await hideApp({
          app: dataApi.id,
        }).unwrap();

        if (me?.email) {
          mixpanel.identify(me.email);
          setTimeout(() => {
            mixpanel.people.increment('total_hidden_apps', 1);
          }, 100);
        }

        mixpanel.track('App hidden', {
          idApp: dataApi.id,
          app_name: dataApi.name,
          UserEmail: me?.email || '',
          OrganizationID: organization?.uuid || '',
        });
        dispatch(filterAppsDataActions.removeAppFromData(dataApi.id));
        toast.success("App hidden successfully");
      } catch (error) {
        console.error("Error hiding app:", error);
        toast.error("Failed to hide app");
      } finally {
        setIsHiddenClickLoading(false);
        setShowHiddenMessage(false);
      }
    };

    const { savedApps } = useSelector(getFilterAppsData);

    const filterCallback = useCallback((item: { app: { id: number } }) =>
      item.app.id === dataApi.id
      , [dataApi.id]);

    const isStarred = useMemo(() =>
      savedApps?.some(filterCallback)
      , [savedApps, filterCallback]);

    const handleStarClick = async (
      event: React.MouseEvent<HTMLButtonElement>,
    ) => {
      event.stopPropagation();
      const isUserLogin = localStorage.getItem("access_token");
      if (!isUserLogin) {
        navigate("/login");
        localStorage.setItem("btnName", "star an app");
        return;
      }
      if (isStarred || isStarClickLoading) {
        const foundFilteredId = savedApps?.filter(
          (result) => result.app.id == dataApi.id,
        );

        try {
          if (foundFilteredId && foundFilteredId?.length > 0) {
            setIsStarClickLoading(true);
            await deleteSavedApp(foundFilteredId[0].id).unwrap();

            if (me?.email) {
              mixpanel.identify(me.email);
              setTimeout(() => {
                mixpanel.people.decrement('total_saved_apps', 1);
              }, 100);
            }

            toast.success("App removed from saved items");
            setIsStarClickLoading(false);
          }
        } catch (error) {
          console.error("Error removing app from saved items:", error);
          toast.error("Error removing app from saved items");
        }
      } else {
        setIsInfoModal(false);
        try {
          setIsStarClickLoading(true);
          await saveApp({
            app: dataApi.id,
          }).unwrap();

          if (me?.email) {
            mixpanel.identify(me.email);
            setTimeout(() => {
              mixpanel.people.increment('total_saved_apps', 1);
            }, 100);
          }

          mixpanel.track('App saved', {
            idApp: dataApi.id,
            app_name: dataApi.name,
            UserEmail: me?.email || '',
            OrganizationID: currentOrganization?.uuid || '',
          });
          toast.success("App saved successfully");
          setIsStarClickLoading(false);
        } finally {
          setIsStarClickLoading(false);
        }
      }
    };

    useEffect(() => {
      if (appVideoScreens && (!me || (!me.is_pro && !organization?.is_pro)) && !storedProImages) {
        const newStoredProImages = [];
        let count = 0;
        for (let i = 0; i < appVideoScreens.length && count < 3; i++) {
          if (i >= 5 && appVideoScreens[i] && appVideoScreens[i].screen) {
            newStoredProImages.push({
              id: appVideoScreens[i].id,
              img: appVideoScreens[i].screen,
              labels: appVideoScreens[i].labels[0] || "",
            });
            count++;
          }
        }

        while (newStoredProImages.length < 3) {
          newStoredProImages.push({ img: "", id: null });
        }

        if (newStoredProImages.some(item => item.img !== "" && item.id !== null)) {
          setStoredProImages(newStoredProImages);
        }
      }
    }, [appVideoScreens, me, storedProImages]);

    const dataImgPro = storedProImages || [];

    const uniqueData: DisplayDataItem[] = React.useMemo(() => {
      const uniqueImagesMap = new Map();

      let processedImages = 0;
      const maxImages = 1000;

      for (const item of data) {
        for (const image of item.images) {
          if (processedImages >= maxImages) break;

          // commented by red, causes issues with setAppVideoScreens from photoitem (reuses previous version here due to garbage implementation from before)
          // if (!uniqueImagesMap.has(image.id)) {
          uniqueImagesMap.set(image.id, {
            ...image,
            label: item.label,
            isGrouped: item.isGrouped,
          });
          processedImages++;
          //}
        }
        if (processedImages >= maxImages) break;
      }

      const uniqueImages = Array.from(uniqueImagesMap.values());

      return uniqueImages.reduce((acc, image) => {
        const lastGroup = acc[acc.length - 1];
        if (lastGroup && lastGroup.label === image.label) {
          lastGroup.images.push(image);
        } else {
          acc.push({
            label: image.label,
            images: [image],
            isGrouped: image.isGrouped,
          });
        }
        return acc;
      }, []);
    }, [data]);

    useEffect(() => {
      if (
        uniqueData.length > 0 &&
        appVideoScreens &&
        !appVideoScreensLoading &&
        !appVideoScreensFetching
      ) {
        setShowSkeleton(false);
      }
    }, [uniqueData, appVideoScreensLoading, appVideoScreensFetching, appVideoScreens]);

    const infoModalRef = useRef(null);

    let totalImages = 0;
    const renderedImageIds = new Set();
    const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);


    useEffect(() => {
      const handleOutsideClick = (event: MouseEvent) => {
        if (
          infoModalRef.current &&
          !infoModalRef.current.contains(event.target as Node)
        ) {
          setIsInfoModalOpen(false);
        }
      };

      document.addEventListener("mousedown", handleOutsideClick);
      return () => {
        document.removeEventListener("mousedown", handleOutsideClick);
      };
    }, []);

    // Modify handlers to check screen width
    const handleMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
      if (!isWideScreen) return;
      setIsDragging(true);
      setStartX(e.pageX - scrollContainerRef.current!.offsetLeft);
      setScrollLeft(scrollContainerRef.current!.scrollLeft);
    };

    const handleMouseLeave = () => {
      if (!isWideScreen) return;
      setIsDragging(false);
    };

    const handleMouseUp = () => {
      if (!isWideScreen) return;
      setIsDragging(false);
    };

    const handleMouseMove = (e: React.MouseEvent<HTMLDivElement>) => {
      if (!isWideScreen || !isDragging) return;
      e.preventDefault();
      const x = e.pageX - scrollContainerRef.current!.offsetLeft;
      const walk = (x - startX) * 2;
      scrollContainerRef.current!.scrollLeft = scrollLeft - walk;
    };

    const handleTouchStart = (e: React.TouchEvent<HTMLDivElement>) => {
      if (!isWideScreen) return;
      setIsDragging(true);
      setStartX(e.touches[0].pageX - scrollContainerRef.current!.offsetLeft);
      setScrollLeft(scrollContainerRef.current!.scrollLeft);
    };

    const handleTouchMove = (e: React.TouchEvent<HTMLDivElement>) => {
      if (!isWideScreen || !isDragging) return;
      const x = e.touches[0].pageX - scrollContainerRef.current!.offsetLeft;
      const walk = (x - startX) * 2;
      scrollContainerRef.current!.scrollLeft = scrollLeft - walk;
    };

    useEffect(() => {
      if (isPaywallOnly) {
        setIsSearchingPaywall(true);
      }
    }, [isPaywallOnly, data]);

    const searchPaywall = () => {
      for (let i = 0; i < data.length; i++) {
        const item = data[i];
        if (item.label === "paywall" && imageRefs.current[item.images[0].id]) {
          const elementRect = imageRefs.current[item.images[0].id]!.getBoundingClientRect();
          const parentRect = scrollContainerRef.current!.getBoundingClientRect();

          const scrollToStart = elementRect.left - parentRect.left;

          scrollContainerRef.current!.scrollTo({
            left: scrollContainerRef.current!.scrollLeft + scrollToStart,
            behavior: "smooth",
          });

          return true;
        }
      }
      return false;
    };

    useEffect(() => {
      if (isSearchingPaywall) {
        const found = searchPaywall();
        if (found) {
          setIsSearchingPaywall(false);
        } else if (appVideoScreensNext) {
          setPage((prevPage) => prevPage + 1);
        } else {
          setIsSearchingPaywall(false);
        }
      }
    }, [isSearchingPaywall, appVideoScreens]);

    const handlePaywallTypeClick = () => {
      if (dataApi?.avs?.paywall_type && dataApi?.avs?.paywall_type !== "None") {
        setIsSearchingPaywall(true);
        mixpanel.track('Paywall type clicked', {
          idApp: dataApi.id,
          UserEmail: me?.email || '',
          OrganizationID: currentOrganization?.uuid || '',
        });
        setIsInfoModalOpen(false);
      }
    };

    const [contextMenu, setContextMenu] = useState<{
      x: number;
      y: number;
      show: boolean;
      imageId: string | null;
      timestamp?: number;
      imageUrl?: string;
    }>({
      x: 0,
      y: 0,
      show: false,
      imageId: null,
    });

    const handleContextMenu = (
      event: React.MouseEvent,
      imageId: string,
      timestamp: number,
      imageUrl: string
    ) => {
      event.preventDefault();
      event.stopPropagation();

      setContextMenu({
        x: event.clientX,
        y: event.clientY,
        show: true,
        imageId,
        timestamp,
        imageUrl,
      });
    };

    const handleCloseContextMenu = useCallback(() => {
      setContextMenu(prev => ({ ...prev, show: false }));
    }, []);


    useEffect(() => {
      const handleClickOutside = (event: MouseEvent) => {
        const contextMenuElement = document.querySelector('[data-context-menu]');
        if (contextMenu.show && contextMenuElement && !contextMenuElement.contains(event.target as Node)) {
          handleCloseContextMenu();
        }
      };

      if (contextMenu.show) {
        document.body.style.overflow = 'hidden';
        const containerPhotos = document.querySelector(`.${styles.containerPhotos}`);
        if (containerPhotos instanceof HTMLElement) {
          containerPhotos.style.overflow = 'hidden';
        }
      } else {
        document.body.style.overflow = '';
        const containerPhotos = document.querySelector(`.${styles.containerPhotos}`);
        if (containerPhotos instanceof HTMLElement) {
          containerPhotos.style.overflow = '';
        }
      }

      document.addEventListener('mousedown', handleClickOutside);

      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
        document.body.style.overflow = '';
        const containerPhotos = document.querySelector(`.${styles.containerPhotos}`);
        if (containerPhotos instanceof HTMLElement) {
          containerPhotos.style.overflow = '';
        }
      };
    }, [contextMenu.show, handleCloseContextMenu]);

    // Optimize the prepareDataForDisplay function with proper memoization
    const prepareDataForDisplay = useCallback(() => {
      if (!appVideoScreens?.length) return [];

      return appVideoScreens.reduce((displayData: DisplayDataItem[], image) => {
        const labels = image.labels || [];
        const mainLabel = labels.includes("paywall") ? "paywall" : (labels[0] || "");
        const highlighter = false;

        if (!Array.isArray(labels) || labels.length === 0) {
          displayData.push({
            label: "",
            images: [{ ...image, highlighter, label: "" }],
            isGrouped: false,
          });
          return displayData;
        }

        const lastGroup = displayData[displayData.length - 1];
        if (
          lastGroup &&
          lastGroup.label === mainLabel &&
          Number(image.timestamp) - parseFloat(lastGroup.images[lastGroup.images.length - 1].timestamp) <= 1
        ) {
          lastGroup.images.push({ ...image, highlighter, label: mainLabel });
        } else {
          displayData.push({
            label: mainLabel,
            images: [{ ...image, highlighter, label: mainLabel }],
            isGrouped: true,
          });
        }

        return displayData;
      }, []);
    }, [appVideoScreens]);

    // Optimize data state update with proper batching
    useEffect(() => {
      const newData = prepareDataForDisplay();
      if (!newData.length) return;

      setData((prev) => {
        if (JSON.stringify(prev) === JSON.stringify(newData)) {
          return prev;
        }
        return newData;
      });
    }, [prepareDataForDisplay]);

    // Add new state for tracking if we're searching for specific screen
    const [isSearchingScreen, setIsSearchingScreen] = useState(false);

    // Add new function to search and scroll to specific screen
    const searchScreenById = () => {
      if (!ScreenNotBlurredID) return false;

      for (let i = 0; i < appVideoScreens.length; i++) {
        const screen = appVideoScreens[i];
        if (screen.id === ScreenNotBlurredID && imageRefs.current[screen.id]) {
          const elementRect = imageRefs.current[screen.id]!.getBoundingClientRect();
          const parentRect = scrollContainerRef.current!.getBoundingClientRect();

          const scrollToStart = elementRect.left - parentRect.left;

          scrollContainerRef.current!.scrollTo({
            left: scrollContainerRef.current!.scrollLeft + scrollToStart,
            behavior: "smooth",
          });

          return true;
        }
      }
      return false;
    };

    // Add effect to handle screen searching
    useEffect(() => {
      if (ScreenNotBlurredID) {
        setIsSearchingScreen(true);
      }
    }, [ScreenNotBlurredID, appVideoScreens]);

    // Add effect to handle pagination while searching for screen
    useEffect(() => {
      if (isSearchingScreen) {
        const found = searchScreenById();
        if (found) {
          setIsSearchingScreen(false);
        } else if (appVideoScreensNext) {
          setPage((prevPage) => prevPage + 1);
        } else {
          setIsSearchingScreen(false);
        }
      }
    }, [isSearchingScreen, appVideoScreens]);

    // Add effect to check new paginated results
    useEffect(() => {
      if (paginatedScreens?.results && ScreenNotBlurredID) {
        const screenExists = paginatedScreens.results.some(
          screen => screen.id === ScreenNotBlurredID
        );
        if (screenExists) {
          setIsSearchingScreen(true);
        }
      }
    }, [paginatedScreens?.results, ScreenNotBlurredID]);

    return (
      <>
        {isScreensLoading ? (
          <></>
        ) : (
          <div className={`${styles.root}`} ref={ref}>
            <div
              className={styles.containerTitle}
            >
              <div className={styles.boxLeft}>
                <img
                  className={styles.logo}
                  onClick={() => handleClickPhone(0, Number(dataApi.latest_appvideo_id))}
                  src={
                    dataApi.icon === "" || !dataApi.icon
                      ? "/img/skeletonLogo.svg"
                      : dataApi.icon
                  }
                  alt="ing-logoHeader"
                  loading="eager"
                  draggable={false}
                />
                <div
                  className={styles.title}
                >
                  <h5 title={dataApi.name} onClick={() => handleClickPhone(0, Number(dataApi.latest_appvideo_id))} className={styles.titleMainText}>{dataApi.name}</h5>
                  <h6 title={dataApi.shortname} onClick={() => handleClickPhone(0, Number(dataApi.latest_appvideo_id))} className={styles.titleText}>{dataApi.shortname}</h6>
                </div>
              </div>

              <div className={styles.infoDesktop}>
                {InfoItemData.map((item, index) => {
                  let displayText = item.text;
                  if (
                    (item.title === "Monthly Installs:" ||
                      item.title === "Monthly Revenue:") &&
                    item.text === 0
                  ) {
                    displayText = "<5k";
                  }
                  const hasPaywall = dataApi?.avs?.paywall_type && dataApi?.avs?.paywall_type !== "No Paywall";
                  return (
                    <div key={index}>
                      <InfoPhoneHomePageHorizontal
                        img={item.img}
                        title={item.title}
                        text={displayText}
                        dataApi={dataApi}
                        onClick={
                          item.title === "Paywall Type:" && hasPaywall
                            ? handlePaywallTypeClick
                            : undefined
                        }
                        tooltipText={
                          item.title === "Paywall Type:"
                            ? hasPaywall
                              ? "Bring me there"
                              : "There's no paywall"
                            : undefined
                        }
                      />
                    </div>
                  );
                })}
                <div className={styles.dots} ref={infoModalRef} onClick={() => setIsInfoModalOpen(!isInfoModalOpen)}>
                  <img src="/img/blackIcon/Dots.svg" alt="dots" />
                  {isInfoModalOpen && (
                    <div className={styles.infoModal}>
                        <MenuButtons
                          handleHideClick={handleHideClick}
                          handleStarClick={handleStarClick}
                          isStarred={isStarred}
                          isStarClickLoading={isStarClickLoading}
                          showStarredMessage={showStarredMessage}
                        />
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div>
              <div ref={phoneHomePageHorizontalRef}>
                <div
                  className={styles.containerPhotos}
                  ref={scrollContainerRef}
                  onScroll={handleScroll}
                  onMouseDown={handleMouseDown}
                  onMouseLeave={handleMouseLeave}
                  onMouseUp={handleMouseUp}
                  onMouseMove={handleMouseMove}
                  onTouchStart={handleTouchStart}
                  onTouchMove={handleTouchMove}
                  onTouchEnd={handleMouseUp}
                >
                  <>
                    {!isFirstGroup ? (
                      (!me?.is_pro && !organization?.is_pro) ? (
                        <>
                          {uniqueData.map((item, index) => {
                            if (totalImages > 5 && (!me?.is_pro && !organization?.is_pro)) return null;

                            return (
                              <div
                                key={`group-${index}-${item.label}`}
                                className={styles.photo}
                                  style={{
                                    display: totalImages < 5 ? "flex" : "none",
                                  }}
                              >
                                {item.isGrouped && (
                                  <div className={`${item.label === "paywall" ? styles.spanLabelPaywall : styles.spanLabel}`}>
                                    <p className={styles.leftLine} />
                                    <span className={styles.stickySpan}>{item.label}</span>
                                    <p className={styles.rightLine} />
                                  </div>
                                )}
                                <div
                                  className={styles.photoContainer}
                                  onMouseEnter={handleMouseEnter}
                                >
                                  {item.images.map((image, imgIndex) => {
                                    if (
                                      !renderedImageIds.has(image.id) &&
                                      totalImages < 5 &&
                                      (!me?.is_pro || !organization?.is_pro)
                                    ) {
                                      renderedImageIds.add(image.id);
                                      totalImages++;

                                      return (
                                        <div
                                          key={`image-${image.id}-${imgIndex}`}
                                          ref={(el) =>
                                            (imageRefs.current[image.id] = el)
                                          }
                                        >
                                          <div ref={refIntersection}>
                                            <MemoizedPhotoItemNew
                                              isPaywallOnly={isPaywallOnly}
                                              setAppVideoScreens={setAppVideoScreens}
                                              idVideo={dataApi.latest_appvideo_id || app_video_id}
                                              image={image}
                                              handleClickPhone={handleClickPhone}
                                              handleImageLoad={handleImageLoad}
                                              onInView={handleInView}
                                              isActiveBookmark={true}
                                              apiDataVideosScreens={appVideoScreens}
                                              hasMore={hasMore}
                                              isLastItem={index === uniqueData.length - 1 && imgIndex === item.images.length - 1}
                                              spinnerRef={spinnerRef}
                                              loadMore={loadMore}
                                              handleOpenBillingPage={handleOpenBillingPage}
                                              onContextMenu={handleContextMenu}
                                              containerRef={scrollContainerRef}
                                              refetchData={refetchAppVideoScreens}
                                              ScreenNotBlurredID={ScreenNotBlurredID}
                                            />
                                          </div>
                                        </div>
                                      );
                                    }
                                    return null;
                                  })}
                                </div>
                              </div>
                            );
                          })}
                          {!showSkeleton && (!me?.is_pro && !organization?.is_pro) && !dataApi.featured && (
                            <UnlockPro
                              dataImgPro={dataImgPro}
                              handleOpenBillingPage={handleOpenBillingPage}
                            />
                          )}
                          {(!me?.is_pro && !organization?.is_pro) &&
                            uniqueData.map((item, index) => {

                              const visibleImages = item.images.filter(image =>
                                !renderedImageIds.has(image.id) &&
                                !storedProImages?.some(proImage => proImage.img === image.screen)
                              );

                              if (visibleImages.length === 0) {
                                return null;
                              }

                              return (
                                <div
                                  key={`group-${index}-${item.label}`}
                                  className={styles.photo}
                                >
                                  {item.isGrouped && (
                                    <div className={`${item.label === "paywall" ? styles.spanLabelPaywall : styles.spanLabel}`}>
                                      <p className={styles.leftLine} />
                                      <span className={styles.stickySpan}>{item.label}</span>
                                      <p className={styles.rightLine} />
                                    </div>
                                  )}
                                  <div
                                    className={styles.photoContainer}
                                    onMouseEnter={handleMouseEnter}
                                  >

                                    {visibleImages.map((image, imgIndex) => {

                                      return (
                                        <div
                                          key={`image-${image.id}-${imgIndex}`}
                                          ref={(el) =>
                                            (imageRefs.current[image.id] = el)
                                          }
                                        >
                                          <div ref={refIntersection}>
                                            <MemoizedPhotoItemNew
                                              isPaywallOnly={isPaywallOnly}
                                              setAppVideoScreens={setAppVideoScreens}
                                              idVideo={dataApi.latest_appvideo_id || app_video_id}
                                              image={image}
                                              handleClickPhone={handleClickPhone}
                                              handleImageLoad={handleImageLoad}
                                              isActiveBookmark={false}
                                              onInView={handleInView}
                                              apiDataVideosScreens={appVideoScreens}
                                              hasMore={hasMore}
                                              isLastItem={index === uniqueData.length - 1 && imgIndex === item.images.length - 1}
                                              spinnerRef={spinnerRef}
                                              loadMore={loadMore}
                                              handleOpenBillingPage={handleOpenBillingPage}
                                              onContextMenu={handleContextMenu}
                                              containerRef={scrollContainerRef}
                                              refetchData={refetchAppVideoScreens}
                                              ScreenNotBlurredID={ScreenNotBlurredID}
                                            />
                                          </div>
                                        </div>
                                      );
                                    })}
                                  </div>
                                </div>
                              );
                            })}
                        </>
                      ) : (
                        uniqueData.map((item, index) => {
                          return (
                            <div
                              key={`group-${index}-${item.label}`}
                              className={styles.photo}
                            >
                              {item.isGrouped && (
                                <div className={`${item.label === "paywall" ? styles.spanLabelPaywall : styles.spanLabel}`}>
                                  <p className={styles.leftLine} />
                                  <span className={styles.stickySpan}>{item.label}</span>
                                  <p className={styles.rightLine} />
                                </div>
                              )}
                              <div
                                className={styles.photoContainer}
                                onMouseEnter={handleMouseEnter}
                              >
                                {item.images.map((image, imgIndex) => {

                                  return (
                                    <div
                                      key={`image-${image.id}-${imgIndex}`}
                                      ref={(el) =>
                                        (imageRefs.current[image.id] = el)
                                      }
                                    >
                                      <div ref={refIntersection}>
                                        <MemoizedPhotoItemNew
                                          isPaywallOnly={isPaywallOnly}
                                          setAppVideoScreens={setAppVideoScreens}
                                          idVideo={dataApi.latest_appvideo_id || app_video_id}
                                          image={image}
                                          handleClickPhone={handleClickPhone}
                                          handleImageLoad={handleImageLoad}
                                          isActiveBookmark={true}
                                          onInView={handleInView}
                                          apiDataVideosScreens={appVideoScreens}
                                          hasMore={hasMore}
                                          isLastItem={index === uniqueData.length - 1 && imgIndex === item.images.length - 1}
                                          spinnerRef={spinnerRef}
                                          loadMore={loadMore}
                                          handleOpenBillingPage={handleOpenBillingPage}
                                          onContextMenu={handleContextMenu}
                                          containerRef={scrollContainerRef}
                                          refetchData={refetchAppVideoScreens}
                                          ScreenNotBlurredID={ScreenNotBlurredID}
                                        />
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          );
                        })
                      )
                    ) : (
                      uniqueData.map((item, index) => {
                        return (
                          <div
                            key={`group-${index}-${item.label}`}
                            className={styles.photo}
                            style={{
                              display: totalImages < 5 ? "flex" : "none",
                            }}
                          >
                            {item.isGrouped && (
                              <div className={`${item.label === "paywall" ? styles.spanLabelPaywall : styles.spanLabel}`}>
                                <p className={styles.leftLine} />
                                <span className={styles.stickySpan}>{item.label}</span>
                                <p className={styles.rightLine} />
                              </div>
                            )}
                            <div
                              className={styles.photoContainer}
                              onMouseEnter={handleMouseEnter}
                            >
                              {item.images.map((image, imgIndex) => {

                                // здесь рендеряться карточки с фотографиями

                                return (
                                  <div
                                    key={`image-${image.id}-${imgIndex}`}
                                    ref={(el) => (imageRefs.current[image.id] = el)}
                                  >
                                    <div ref={refIntersection}>
                                      <MemoizedPhotoItemNew
                                        isPaywallOnly={isPaywallOnly}
                                        setAppVideoScreens={setAppVideoScreens}
                                        idVideo={dataApi.latest_appvideo_id || app_video_id}
                                        image={image}
                                        handleClickPhone={handleClickPhone}
                                        handleImageLoad={handleImageLoad}
                                        onInView={handleInView}
                                        isActiveBookmark={true}
                                        apiDataVideosScreens={appVideoScreens}
                                        hasMore={hasMore}
                                        isLastItem={index === uniqueData.length - 1 && imgIndex === item.images.length - 1}
                                        spinnerRef={spinnerRef}
                                        loadMore={loadMore}
                                        handleOpenBillingPage={handleOpenBillingPage}
                                        onContextMenu={handleContextMenu}
                                        containerRef={scrollContainerRef}
                                        refetchData={refetchAppVideoScreens}
                                        ScreenNotBlurredID={ScreenNotBlurredID}
                                      />
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        );
                      })
                    )}
                  </>
                </div>
              </div>
            </div>
            <div className={styles.modalWindow} ref={modalPhoneHorisontalRef}>
            </div>
            {contextMenu.show && (
              <RightClickModal
                x={contextMenu.x}
                y={contextMenu.y}
                onClose={handleCloseContextMenu}
                imageUrl={contextMenu.imageUrl || ""}
                imageId={contextMenu.imageId || ""}
                handleOpenInNewTab={handleClickPhone}
                timestamp={contextMenu.timestamp || 0}
                data-context-menu
                avsID={dataApi.latest_appvideo_id}
                refetchData={refetchAppVideoScreens}
                imageType="video"
                containerRef={scrollContainerRef}
              />
            )}
          </div>
        )}
      </>
    );
  },
);

export default React.memo(PhoneHomePageHorisontalNew);