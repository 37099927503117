import React from "react";
import { Sparkles } from "lucide-react";
import styles from './OnboardingModal.module.scss';
import { cn } from "./Utils";

interface GetStartedButtonProps {
  onClick: (e: React.MouseEvent) => void;
  isHovering: boolean;
  onMouseEnter: () => void;
  onMouseLeave: () => void;
  children: React.ReactNode;
}

export const GetStartedButton = ({
  onClick,
  isHovering,
  onMouseEnter,
  onMouseLeave,
  children
}: GetStartedButtonProps) => (
  <button
    onMouseEnter={onMouseEnter}
    onMouseLeave={onMouseLeave}
    onClick={onClick}
    className={cn(
      styles.button,
      isHovering ? styles.buttonHovered : ''
    )}
  >
    <span>{children}</span>
    <Sparkles
      className={cn(
        styles.sparkles,
        isHovering ? styles.sparklesHovered : styles.sparklesHidden
      )}
    />
  </button>
);