import React from 'react';
import './Logo.css'; // We'll need to create this CSS file

const Logo: React.FC = () => {
  return (
    <div className="logo-container">
      <h1 className="logo-text">
        <span className="logo-brand">screens</span>
        <span className="logo-highlight">design</span>
      </h1>
    </div>
  );
};

export default Logo;
