// redux
import { createSlice } from "@reduxjs/toolkit";
// types
import { ModalMenuState } from "../types/ModalMenuTypes";

const initialState: ModalMenuState = {
  isVisible: false,
  isLoggedOutModal: false,
};

const modalMenuSlice = createSlice({
  name: "modalMenu",
  initialState,
  reducers: {
    toggleModal: (state) => {
      state.isVisible = !state.isVisible;
    },
    showModal: (state) => {
      state.isVisible = true;
    },
    hideModal: (state) => {
      state.isVisible = false;
    },
    toggleLoggout: (state) => {
      state.isLoggedOutModal = !state.isLoggedOutModal;
    },
    showLoggout: (state) => {
      state.isLoggedOutModal = true;
    },
    hideLoggout: (state) => {
      state.isLoggedOutModal = false;
    },
  },
});

export const { actions: modalMenuActions, reducer: modalMenuReducer } =
  modalMenuSlice;
