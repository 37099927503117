import React from 'react';
import './FeatureList.css'; // We'll need to create this CSS file

type Feature = {
  id: string;
  name: string;
};

type FeatureListProps = {
  features: Feature[];
};

const FeatureList: React.FC<FeatureListProps> = ({ features }) => {
  return (
    <div className="feature-list-container">
      {features.map((feature, index) => (
        <div 
          key={feature.id}
          className="feature-item"
          style={{ 
            animationDelay: `${index * 0.1}s`,
            animation: 'slideUp 0.5s ease-out forwards',
            opacity: 0
          }}
        >
          {feature.name}
        </div>
      ))}
    </div>
  );
};

export default FeatureList;
