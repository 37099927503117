// react
import React from "react";
// react-player
import ReactPlayer from "react-player";

const useModalPhoneItemControls = (
  setVideoReady: (ready: boolean) => void,
  setIsModalOpen: (open: boolean) => void,
  refetchDataVideo: () => void,
) => {
  const videoRef = React.useRef<ReactPlayer>(null);

  const onSeek = React.useCallback((time: number) => {
    if (videoRef.current) {
      videoRef.current.seekTo(time, "seconds");
    }
  }, []);

  const openModal = React.useCallback(() => {
    setIsModalOpen(true);
  }, [setIsModalOpen]);

  const closeModal = React.useCallback(() => {
    setIsModalOpen(false);
  }, [setIsModalOpen]);

  return {
    videoRef,
    onSeek,
    openModal,
    closeModal,
    refetchDataVideo,
  };
};

export default useModalPhoneItemControls;
