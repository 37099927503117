import { FC } from "react";
import { BillingPlanMonthProps } from "@/shared/types/BillingPlanMonthProps";
import styles from "./BillingPlanMonth.module.scss";
import { useGetPlansQuery } from "@/features/auth/api/authAPI";
import Spinner from "../Spinner/Spinner";

const BillingPlanMonth: FC<BillingPlanMonthProps> = ({
  planFeaturesItem,
  handleSelectPlan,
  isProcessing,
  pendingPlanData,
}) => {
  const { data: apiDataPlans } = useGetPlansQuery();

  if (!apiDataPlans || apiDataPlans.length === 0) {
    return (
      <div className={styles.root}>
        <Spinner />
      </div>
    );
  }

  const plan = apiDataPlans[0];
  const {
    recurring_interval,
    recurring_interval_count,
    unit_amount,
    id: priceId,
  } = plan;

  const monthlyPrice = (unit_amount / 100 / recurring_interval_count).toFixed(
    2,
  );

  return (
    <div className={styles.root}>
      <p className={styles.planCardMonthSubtitle}>
        Full access to all app designs, advanced search, and additional
        features.
      </p>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div className={styles.planCardMonthContent}>
          <p
            className={styles.planCardMonthFeaturesTitle}
            style={{ textAlign: "left" }}
          >
            Features
          </p>
          <div className={styles.planCardMonthFeatures}>
            {planFeaturesItem.map((feature, index) => (
              <div key={index} className={styles.planCardMonthFeaturesItem}>
                <img src="/img/blackIcon/Checklist.svg" alt="checkPlanImg" />
                <p>{feature.text}</p>
              </div>
            ))}
          </div>
        </div>
        <div className={styles.planCardMonthPriceContainer}>
          <p className={styles.planCardMonthPrice}>
            ${monthlyPrice}
            <span className={styles.span}> Per/{recurring_interval}</span>
          </p>
          {/* <span style={{ fontSize: "14px", color: "#4C4C4C" }}>
            Billed every 3 months
          </span> */}

          <div
            className={`${styles.planCardMonthButton} ${isProcessing ? styles.planCardMonthButtonDisabled : ""}`}
          >
            <button
              onClick={() => handleSelectPlan(priceId)}
              disabled={isProcessing}
            >
              {pendingPlanData ? "Processing..." : "Unlock Pro"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BillingPlanMonth;
