// styles
import { useDispatch } from "react-redux";
import styles from "./ErrorModalPhoneItem.module.scss";
import { modalMenuPhoneActions } from "@/entities/modalMenuPhone";

const ErrorModalPhoneItem = () => {
  const dispatch = useDispatch();

  return (
    <div className={styles.root}>
      <p className={styles.errorMessage}>Error: No data available</p>
      <button
        onClick={() => dispatch(modalMenuPhoneActions.hideModalPhone())}
        className={styles.goBackButton}
      >
        Go Back
      </button>
    </div>
  );
};

export default ErrorModalPhoneItem;
