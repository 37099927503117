// redux
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
// type
import { counterState } from "../types/counterTypes";

const initialState: counterState = {
  count: 0,
  dataFilter: [],
};

const conuterSlice = createSlice({
  name: "counter",
  initialState,
  reducers: {
    increment(state) {
      state.count += 1;
    },
    decrement(state) {
      state.count -= 1;
    },
    setCount: (state, action: PayloadAction<number>) => {
      state.count = action.payload;
    },
    incrementByAmount: (state, action: PayloadAction<number>) => {
      state.count += action.payload;
    },
    addToArray: (state, action: PayloadAction<string>) => {
      state.dataFilter = [...state.dataFilter, action.payload];
      state.count += 1;
    },
    removeFromArray: (state, action: PayloadAction<string>) => {
      state.dataFilter = state.dataFilter.filter(
        (item) => item !== action.payload,
      );
      state.count -= 1;
    },
    clearArray: (state) => {
      state.dataFilter = [];
      state.count = 0;
    },
  },
});

export const { actions: counterActions, reducer: counterReducer } =
  conuterSlice;
