import React from "react";
import { Provider } from "react-redux";
import { createStore } from "../../config/store/createStore";

interface StoreProviderProps {
  children: React.ReactNode;
}

export const StoreProvider: React.FC<StoreProviderProps> = ({ children }) => {
  const store = createStore();

  return <Provider store={store}>{children}</Provider>;
};
