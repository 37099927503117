import React, { useContext } from 'react'
import { useGetUserQuery } from '../features/auth/api/authAPI'

const MeContext = React.createContext<any>(null)

export const MeProvider = ({ children }: { children: React.ReactNode }) => {
    const hasToken = !!localStorage.getItem('access_token')
    
    const { data: me, isLoading: loading, error, refetch: refetchUser } = useGetUserQuery(null, {
        skip: !hasToken
    })

    return (
        <MeContext.Provider value={{ me, loading, error, refetchUser, isAuthenticated: hasToken }}>
            {children}
        </MeContext.Provider>
    )
}

export const useMe = () => {
    const context = useContext(MeContext)
    if (context === undefined) {
        throw new Error('useMe must be used within a MeProvider')
    }
    return context
}
