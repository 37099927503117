import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { filterActions, getFilterState } from "@/entities/filter";
import { getCounterState } from "@/entities/counter";
import FilterModalsidebar from "@/widgets/modalSidebarProcess/ui/FilterModalSidebar/FilterModalsidebar";
import { FilterButtonProps } from "../../libs/types/FilterButtonProps";
import styles from "./FilterButton.module.scss";
import { filterAppsDataActions } from "@/entities/filterAppsData";
import { useLocation } from 'react-router-dom';

const FilterButton: React.FC<FilterButtonProps> = ({
  isError,
  refetch,
  isFetching,
  setSortDirection,
  activeType,
  setActiveType,
  apiDataFilter,
  uniqueFilterAppDataSorted,
  isNext,
  activeButtonHidStar,
  setActiveButtonHidStar,
  rootActiveView
}) => {
  
  const dispatch = useDispatch();
  const { count } = useSelector(getCounterState);
  const { isActiveMagic } = useSelector(getFilterState);
  const location = useLocation();
  const isSearchPage = location.pathname.startsWith('/search');
  const isSavedMode = location.pathname.startsWith('/saved-library');

  useEffect(() => {
    if (apiDataFilter?.count !== undefined) {
      localStorage.setItem("appDataCount", apiDataFilter.count.toString());
      dispatch(filterAppsDataActions.setAppDataCount(apiDataFilter.count));
    }
  }, [apiDataFilter]);

  useEffect(() => {
    const hasVisited = localStorage.getItem('hasVisitedBefore');
    if (!hasVisited && !isSavedMode) {
      dispatch(filterActions.activateFilter());
      localStorage.setItem('hasVisitedBefore', 'true');
    }
  }, []);

  return (
    <>
      {!isSavedMode && (
        <div
          className={`${styles.root} ${isSearchPage ? styles.searchPageFilter2 : ''}`}
          onClick={() => dispatch(filterActions.activateFilter())}
          style={{ pointerEvents: isActiveMagic ? "none" : "auto" }}

        >

          <button
            className={`${count <= 0
              ? `${styles.filterDisactive} ${isSearchPage ? styles.searchPageFilter : ''}`
              : `${styles.filterActive}`
              }`}
          >

            {!count &&
              <p>Filter & Sort</p>
            }

            <div className={styles.titleContainer}>
              <div className={`${styles.containerItems} ${count > 2 ? styles.multiRow : ""}`}>
                {count > 0 && (<p>Filter</p>)}
                {count > 2 && <p className={styles.additionalCount}>+{count - 2}</p>}
              </div>
            </div>
            {count <= 0 ? null : (
              <p className={styles.count}>
                <span>{count}</span>
              </p>
            )}
          </button>

          <FilterModalsidebar
            dataCount={apiDataFilter?.count}
            isError={isError}
            refetch={refetch}
            isNext={isNext}
            isFetching={isFetching}
            setSortDirection={setSortDirection}
            activeType={activeType}
            setActiveType={setActiveType}
            uniqueFilterAppDataSorted={uniqueFilterAppDataSorted}
            activeButtonHidStar={activeButtonHidStar}
            setActiveButtonHidStar={setActiveButtonHidStar}
            rootActiveView={rootActiveView}
          />

        </div>
      )}
    </>
  );
};

export default FilterButton;
