import React from "react";
import { NavLink } from "react-router-dom";

import { dataHidStarMenu } from "@/shared/lib/dates/dataHidStarMenu/dataHidStarMenu";
import styles from "./ModalHidStarMenu.module.scss";

interface ModalSaveModeMenuProps {
  activePath: string;
  handleItemClick: (index: number) => void;
}

const ModalHidStarMenu: React.FC<ModalSaveModeMenuProps> = ({
  activePath,
  handleItemClick,
}) => {
  return (
    <div className={styles.modalContainer}>
      {dataHidStarMenu.map((item, index) => {
        const isActive = activePath.includes(item.target);
        return (
          <NavLink
            to={item.target}
            className={`${styles.modalMenuItem} ${isActive ? styles.modalMenuItemActive : ""}`}
            key={index}
            onClick={() => handleItemClick(index)}
          >
            <img
              src={isActive ? item.imgActive : item.img}
              alt="appsMenuIcon"
            />
            <p>{item.text}</p>
          </NavLink>
        );
      })}
    </div>
  );
};

export default ModalHidStarMenu;
