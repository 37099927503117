// react
import React from "react";
// redux
import { useDispatch } from "react-redux";
// entities
import { videoActions } from "@/entities/video/model/slice/videoSlice";
import mixpanel from "@/shared/lib/mixpanel";
import { useMe, useCurrentOrganization } from "@/Hooks";

const useHandleRateClick = () => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [activeRate, setActiveRate] = React.useState({
    rate: "1.0x",
    rateName: "Normal",
  });
  const { me } = useMe();
  const currentOrganization = useCurrentOrganization();
  const dispatch = useDispatch();

  const handleRateClick = (
    rate: string,
    rateName: string,
    event: React.MouseEvent<HTMLElement>,
  ) => {
    event.stopPropagation();
    const rateValue =
      rateName === "Normal" ? 1 : parseFloat(rate.replace("x", ""));
    dispatch(videoActions.setPlaybackRate(rateValue));
    setActiveRate({ rate, rateName });

    mixpanel.track('Changed video speed', {
      rate: rate,
      UserEmail: me?.email || '',
      OrganizationID: currentOrganization?.uuid || '',
    });
    setIsOpen(false);
  };

  return {
    handleRateClick,
    isOpen,
    activeRate,
    setIsOpen,
  };
};

export default useHandleRateClick;
