import { cn } from "./Utils";
import styles from './OnboardingModal.module.scss';

interface StepIndicatorProps {
  totalSteps: number;
  currentStep: number;
  onStepClick: (step: number) => void;
}

export const StepIndicator = ({ totalSteps, currentStep, onStepClick }: StepIndicatorProps) => (
  <div className={styles.container}>
    {Array.from({ length: totalSteps }).map((_, idx) => (
      <div
        key={idx}
        onClick={(e) => {
          e.stopPropagation();
          onStepClick(idx);
        }}
        className={cn(
          styles.dot,
          idx === currentStep ? styles.activeDot : ''
        )}
      />
    ))}
  </div>
);