import React from "react";
import { IAppData } from "@/features/auth/types/auth";
import Spinner from "../Spinner/Spinner";
import styles from "./FilterSortedElements.module.scss";
import { handleOpenVideoPage } from "@/app/libs/const/handleOpenVideoPage";

interface FilterSorteDElementsProps {
  filterAppDataSortedAll?: IAppData[];
  elementLength?: number;
  isNext: boolean;
  isError: boolean;
}

type Ref = HTMLDivElement;

const FilterSortedElements = React.forwardRef<Ref, FilterSorteDElementsProps>(
  (
    { filterAppDataSortedAll, elementLength, isNext, isError },
    ref,
  ) => {

    const handleClickPhone = (
      dataApi: IAppData,
      e: React.MouseEvent,
      timestamp?: number,
    ) => {
      e.preventDefault();
      e.stopPropagation();

      handleOpenVideoPage(
        timestamp || 0,
        dataApi.slug,
        "Home Page screens",
        1,
        dataApi.id,
        undefined,
        undefined, 
      );
    };

    const uniqueFilterAppDataSortedAll = React.useMemo(() => {
      const uniqueSet = new Set(filterAppDataSortedAll?.map((app) => app.id));
      return filterAppDataSortedAll?.filter((app) => {
        if (uniqueSet.has(app.id)) {
          uniqueSet.delete(app.id);
          return true;
        }
        return false;
      });
    }, [filterAppDataSortedAll]);

    return (
      <div>
        <div className={styles.root}>
          {uniqueFilterAppDataSortedAll?.map((item, index) => {
            return index + 1 === elementLength ? (

                <div
                  onClick={(e) => handleClickPhone(item, e, 0)}
                  key={`lastElement-${index}-${item.id}`}
                  // ref={ref}
                >
                  <div className={styles.appContainer}>
                    <img
                      src={
                        item.icon === "" || !item.icon
                          ? "/img/skeletonLogo.svg"
                          : item.icon
                      }
                      alt="iconApps"
                    />
                    <div className={styles.title}>
                      <h3>{item.name}</h3>
                      <p>{item.shortname}</p>
                    </div>
                  </div>
                </div>

            ) : (

                <div
                  onClick={(e) => handleClickPhone(item, e, 0)}
                  key={`filter-${index}`}
                  // ref={ref}
                  className={styles.appContainer}
                >
                  <img
                    src={
                      item.icon === "" || !item.icon
                        ? "/img/skeletonLogo.svg"
                        : item.icon
                    }
                    alt="iconApps"
                    loading="lazy"
                  />
                  <div className={styles.title}>
                    <h3>{item.name}</h3>
                    <p>{item.shortname}</p>
                  </div>
                </div>
            );
          })}
          {uniqueFilterAppDataSortedAll && uniqueFilterAppDataSortedAll.length > 0 && (
            <div ref={ref} style={{ height: "20px", visibility: "hidden", position: "relative", bottom: "0" }} />
          )}
          <div
            style={{
              width: "100%",
              height: "100%",
              display: isNext && !isError ? "inline" : "none",
            }}
            className={
              uniqueFilterAppDataSortedAll &&
              uniqueFilterAppDataSortedAll.length > 0
                ? ""
                : styles.spinnerContainerLength
            }
          >
            <div className={styles.spinner}>
              <Spinner />
            </div>
          </div>
        </div>
      </div>
    );
  },
);

export default FilterSortedElements;
