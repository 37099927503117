// redux
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
// types
import { BillingState } from "../types/billingTypes";

const initialState: BillingState = {
  clientSecret: "",
};

const billingSlice = createSlice({
  name: "billing",
  initialState,
  reducers: {
    setClientSecret: (state, action: PayloadAction<string>) => {
      state.clientSecret = action.payload;
    },
  },
});

export const { actions: billingActions, reducer: billingReducer } =
  billingSlice;
