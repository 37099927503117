import { useEffect, useRef, useState } from "react";
import { Outlet, NavLink, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useGetOrganizationUsersQuery } from "@/features/auth/api/authAPI";
import SortSavedModeModal from "@/shared/ui/SortSavedModeModal/SortSavedModeModal";
import { getFilterAppsState } from "@/entities/filterApps";
import Homestyles from "@/pages/Homepage/Homepage.module.scss";
import styles from "@/widgets/modalSaveMode/ui/ModalSaveMode/ModalSaveMode.module.scss";
import { getSortByCategoriesSlice } from "@/entities/sortByCategories/model/selectors/getSortByCategoriesState";
import ModalSaveModeSortBy from "@/shared/ui/ModalSaveModeSortBy/ModalSaveModeSortBy";
import { useCurrentOrganization } from "@/Hooks";
import { SavedModeMenu } from "./SavedModeMenu";
import { sortByCategoriesActions } from "@/entities/sortByCategories";
import Spinner from "@/shared/ui/Spinner/Spinner";
import { getZoomState } from "@/entities/zoom";
import ModalHidStarMenu from "@/shared/ui/ModalHidStarMenu/ModalHidStarMenu";

const SavedModeBaseView = () => {
  const location = useLocation();
  const containerRef = useRef<HTMLDivElement>(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const [sortDirectionSavedMode, setSortDirectionSavedMode] = useState("desc");
  const dispatch = useDispatch();
  const handleItemClick = (index: number) => {
    setActiveIndex(index);
  };

  const { filterSortSavedMode, userFilter } = useSelector(getFilterAppsState);
  const currentOrganization = useCurrentOrganization();

  const orderSavedMode =
    filterSortSavedMode !== ""
      ? `${sortDirectionSavedMode === "desc" ? "-" : ""}${filterSortSavedMode}`
      : "-created_at";

  const userOrderSavedMode = userFilter === null ? "" : userFilter;

  const { zoom } = useSelector(getZoomState);

  const { sortBySaveMode, saveModeOpen } = useSelector(
    getSortByCategoriesSlice,
  );

  const [selectedUser, setSelectedUser] = useState<string | null>(null);

  const { data: organizationUsers } = useGetOrganizationUsersQuery(
    currentOrganization?.uuid,
    {
      skip: !currentOrganization?.uuid,
    },
  );

  const isHiddenOrStarredRoute = () => {
    const path = location.pathname;
    return (
      path.includes("/saved-library/hidden") ||
      path.includes("/saved-library/starred")
    );
  };

  useEffect(() => {
    if (saveModeOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }

    return () => {
      document.body.style.overflow = "unset";
    };
  }, [saveModeOpen]);

  useEffect(() => {
    if (userFilter) {
      const user = organizationUsers?.results?.find(
        (member) => member.user.id === userFilter,
      );
      if (user) {
        setSelectedUser(
          `${user.user.first_name} ${user.user.last_name}`.trim() ||
            user.user.username,
        );
      }
    }
  }, [userFilter, organizationUsers]);

  const handleCloseModal = () => {
    dispatch(sortByCategoriesActions.closeSortSaveMode());
  };

  return (
    <div className={Homestyles.modalSaveModeContainer}>
      {currentOrganization ? (
        <div className={Homestyles.modalSaveMode}>
          <div ref={containerRef} className={styles.root}>
            <div className={styles.headerContent}>
              <div className={styles.groupContainer}>
                <NavLink
                  to="/saved-library/groups/"
                  className={styles.groupSave}
                >
                  <img src="/img/blackIcon/SavedGroup.svg" alt="groupSaveImg" />
                  <p>Saved Groups</p>
                </NavLink>
                <SavedModeMenu
                  activeIndex={activeIndex}
                  handleItemClick={handleItemClick}
                />
                {isHiddenOrStarredRoute() && (
                  <ModalHidStarMenu
                    activePath={location.pathname}
                    handleItemClick={handleItemClick}
                  />
                )}
              </div>
              <div className={styles.shareContainer}>
                <ModalSaveModeSortBy />
                {/* <>
                  <div className={styles.zoomDisplay}>
                    <button
                      type="button"
                      className={styles.buttonDecrease}
                      onClick={handleZoomOut}
                    >
                      <img
                        src="/img/whiteIcon/Minus.svg"
                        alt="decreaseImg"
                        loading="lazy"
                      />
                    </button>
                    <div className={styles.titleZoom}>
                      <p>Zoom</p>
                      <p>{zoom}X</p>
                    </div>
                    <button
                      type="button"
                      className={styles.buttonIncrease}
                      onClick={handleZoomIn}
                    >
                      <img
                        src="/img/whiteIcon/Plus.svg"
                        alt="increaseImg"
                        loading="lazy"
                      />
                    </button>
                  </div>
                  <div className={styles.zoomDisplayMobile}>
                    <ZoomSaveMode />
                  </div>
                </> */}
              </div>
            </div>

            <Outlet
              context={{
                zoom: zoom,
                sortkey: orderSavedMode,
                userFilter: userOrderSavedMode,
              }}
            />
            {saveModeOpen && (
              <SortSavedModeModal
                setSortDirection={setSortDirectionSavedMode}
                isOpen={saveModeOpen}
                onClose={handleCloseModal}
                selectedUser={selectedUser}
                setSelectedUser={setSelectedUser}
                showSavedBy={!isHiddenOrStarredRoute()}
              />
            )}
          </div>
        </div>
      ) : (
        <Spinner />
      )}
    </div>
  );
};

export { SavedModeBaseView };
