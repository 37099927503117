import React, { useEffect, useState, ForwardRefRenderFunction } from "react";
import Modal from "react-modal";
import { useDispatch, useSelector } from "react-redux";
import { IMarker } from "@/widgets/savePosition/libs/types/IMarker";
import { getVideoState } from "@/entities/video/model/selectors/getVideoState";
import { IAppVideoData } from "@/features/auth/types/auth";
import { modalMenuPhoneActions } from "@/entities/modalMenuPhone";
import { videoActions } from "@/entities/video/model/slice/videoSlice";
import { savePositionActions } from "@/entities/savePosition";
import styles from "./PhotoCardList.module.scss";
import {
  useGetUserQuery,
  useSavedPointsMutation,
  useDeletePointMutation,
} from "@/features/auth/api/authAPI";
import { IoIosCheckmarkCircle } from "react-icons/io";
import axios from "axios";
import { useCurrentOrganization, useMe } from '@/Hooks'
import { produce } from 'immer'
import mixpanel from "@/shared/lib/mixpanel";
import { RightClickModal } from "@/shared/ui/RightClickModal/RightClickModal";
import { toast } from "react-toastify";

interface PhotoCardListProps {
  onSeek: (time: number) => void;
  appVideoScreens: any[]; // Replace 'any' with the correct type
  setAppVideoScreens: React.Dispatch<React.SetStateAction<any[]>>; // Replace 'any' with the correct type
  setImagesReady: (state: boolean) => void;
  imagesReady: boolean;
  apiDataVideosOne?: IAppVideoData;
  setIsActivePoster: (state: boolean) => void;
  isModalOpenPhotoCard: boolean;
  handleOpenBillingPage: () => void;
  refetchDataScreens: () => void;
}

const PhotoCardList: ForwardRefRenderFunction<HTMLDivElement, PhotoCardListProps> = (
  {
    appVideoScreens,
    setAppVideoScreens,
    setImagesReady,
    onSeek,
    apiDataVideosOne,
    isModalOpenPhotoCard,
    handleOpenBillingPage,
    refetchDataScreens,
  },
  ref
) => {
  const dispatch = useDispatch();
  const { currentPlayTime } = useSelector(getVideoState);

  const [selectedImage, setSelectedImage] = useState("");
  const [imageIndex, setImageIndex] = useState(0);
  const [hoveredTimestamp, setHoveredTimestamp] = useState<string | null>(
    null,
  );
  const [selectedTimestamp, setSelectedTimestamp] = useState<string>("");
  const [selectedItem, setSelectedItem] = useState();
  const [loadedImagesCount, setLoadedImagesCount] = useState(0);
  const token = localStorage.getItem("access_token");
  const [savedPoint] = useSavedPointsMutation();
  const [deletePoint] = useDeletePointMutation();
  const { data: userData } = useGetUserQuery(null, { skip: !token });
  const { me } = useMe();
  const currentOrganization = useCurrentOrganization();

  
  const totalImages =
    appVideoScreens.length
      ? appVideoScreens.length - 1
      : 100;
  const accessToken = localStorage.getItem("access_token");

  const images = appVideoScreens.map((item) => item.screen) ?? [];

  // const itemScreen = apiDataVideosScreens
  //   ? [...apiDataVideosScreens.results]
  //       .sort((a, b) => Number(a.timestamp) - Number(b.timestamp))
  //       .map((item) => {
  //         return item;
  //       })
  //   : [];

  const itemScreen = [...appVideoScreens].sort((a, b) => Number(a.timestamp) - Number(b.timestamp))
    .map((item) => {
      return item;
    })

  const handleClick = async (item, timestamp: string) => {
    if (
      userData &&
      userData.username !== "" &&
      userData.email !== "" &&
      accessToken
    ) {

      if (!item.is_saved) {

        localStorage.setItem("btnName", "save a point");

        try {

          const sp = await savedPoint({
            organizationUuid: currentOrganization.uuid,
            app_video: Number(apiDataVideosOne?.id),
            ts: timestamp,
          }).unwrap();

          mixpanel.track('Image saved', {
            idApp: apiDataVideosOne?.id,
            app_name: apiDataVideosOne?.name,
            UserEmail: me?.email || '',
            OrganizationID: currentOrganization?.uuid || '',
          });

          if (me?.email) {
            mixpanel.identify(me.email);
            setTimeout(() => {
              mixpanel.people.increment('total_saved_screens', 1);
            }, 100);
          }

          setAppVideoScreens(produce((draft) => {
            const index = draft.findIndex((obj) => obj.id == item.id)

            if (index !== -1) {
              draft[index].is_saved = true
              draft[index].is_saved_pk = sp.id
            }
          }))
          setSelectedItem(prev => prev?.id === item.id ? { ...prev, is_saved: true, is_saved_pk: sp.id } : prev);
          //toast.success("Image saved successfully");

        } catch (error) {
          if (axios.isAxiosError(error)) {
            toast.error(error.response?.data.error);
          }
        }
      } else {

        try {

          if (item.is_saved_pk) {
            await deletePoint(item.is_saved_pk).unwrap();

            if (me?.email) {
              mixpanel.identify(me.email);
              setTimeout(() => {
                mixpanel.people.increment('total_unsaved_screens', 1);
              }, 100);
            }

            setAppVideoScreens(produce((draft) => {
              const index = draft.findIndex((obj) => obj.id == item.id)

              if (index !== -1) {
                draft[index].is_saved = false
                draft[index].is_saved_pk = null
              }
            }))
            setSelectedItem(prev => prev?.id === item.id ? { ...prev, is_saved: false, is_saved_pk: null } : prev);
            toast.success("Image removed from saved");
          } else {
            throw new Error("Saved point not found");
            toast.error("Saved point not found");
          }
        } catch (error) {
          console.log(error)
          const errorMessage = axios.isAxiosError(error)
            ? error.response?.data.error
            : "Error removing saved point";

          toast.error(errorMessage);
        }

      }

    } else {

      localStorage.removeItem("access_token");
      localStorage.setItem("btnName", "save a point");
      window.location.href = "/register";
      window.scrollTo(0, 0);
    }
  };

  const handlePrevImage = () => {
    const prevIndex = (imageIndex - 1 + images.length) % images.length;
    const prevImage = itemScreen[prevIndex];
    setSelectedImage(prevImage.screen);
    setSelectedTimestamp(prevImage.timestamp);
    setSelectedItem(prevImage)
    setImageIndex(prevIndex);
  };

  const handleNextImage = () => {
    const nextIndex = (imageIndex + 1) % images.length;
    const nextImage = itemScreen[nextIndex];
    setSelectedImage(nextImage.screen);
    setSelectedTimestamp(nextImage.timestamp);
    setSelectedItem(nextImage)
    setImageIndex(nextIndex);
  };

  const openModal = (item, image: string, timestamp: string) => {
    const index = images.indexOf(image);
    setSelectedImage(image);
    setSelectedTimestamp(timestamp);
    setSelectedItem(item)
    setImageIndex(index);
    dispatch(modalMenuPhoneActions.showModalOpenPhotoCard());
    const newMarker: IMarker = {
      timestamp: timestamp.toString(),
      appVideo: apiDataVideosOne!.id,
    };

    // setMarkers((prevMarkers) => [...prevMarkers, newMarker]);
    dispatch(savePositionActions.addMarker(newMarker));
  };

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === "ArrowRight") {
      handleNextImage();
    } else if (event.key === "ArrowLeft") {
      handlePrevImage();
    }
  };

  const closeModal = () => {
    dispatch(modalMenuPhoneActions.hideModalOpenPhotoCard());
    dispatch(savePositionActions.resetMarker());
  };

  const handleClickScreen = (item, itemScreens: string, timestamp: string) => {
    openModal(item, itemScreens, timestamp);
  };

  useEffect(() => {
    if (isModalOpenPhotoCard) {
      window.addEventListener("keydown", handleKeyDown);
    } else {
      window.removeEventListener("keydown", handleKeyDown);
    }

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [isModalOpenPhotoCard, handleKeyDown]);

  const handleImageLoad = () => {
    setLoadedImagesCount((count) => count + 1);
  };

  useEffect(() => {
    if (loadedImagesCount >= totalImages) {
      setImagesReady(true);
    } else {
      setImagesReady(false);
    }
  }, [loadedImagesCount, totalImages, setImagesReady]);

  const handleImageClick = () => {
    dispatch(videoActions.setPlaying(false));
    onSeek(selectedTimestamp ? Number(selectedTimestamp) + 0.01 : 0);
    closeModal();
    if (ref && "current" in ref && ref.current) {
      ref.current.scrollIntoView({ behavior: "instant" });
    }
  };

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 500);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 500);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const [contextMenu, setContextMenu] = useState<{ x: number; y: number; imageId: string; timestamp: string } | null>(null);

  const handleContextMenu = (event: React.MouseEvent, imageId: string, timestamp: string) => {
    event.preventDefault();
    setContextMenu({
      x: event.clientX,
      y: event.clientY,
      imageId,
      timestamp,
    });
  };

  const handleCloseContextMenu = () => {
    setContextMenu(null);
  };
  

  return (
    <div className={`${styles.root} ${isModalOpenPhotoCard ? styles.noDisplay : ""}`} ref={ref}>
      {Object.values(itemScreen).map((item, i) => {
        const highlighted =
          Number(itemScreen[i].timestamp) < currentPlayTime &&
          (Number(itemScreen[i + 1]?.timestamp) || 99999) > currentPlayTime;

        const imgClass = highlighted ? styles.photoContainer : "";

        return (
          item.screen && (
            <div
              key={i}
              onMouseEnter={() => setHoveredTimestamp(item.timestamp)}
              onMouseLeave={() => setHoveredTimestamp(null)}
              className={`${styles.photoItemContainer} ${item.is_blurry ? styles.featuredItem : ''}`}
            >
              <div
                key={item.id}
                className={`${imgClass} ${styles.photoItem}`}
                onClick={() => handleClickScreen(item, item.screen, item.timestamp)}
                onContextMenu={(e) => handleContextMenu(e, item.id.toString(), item.timestamp)}
              >
                <div className={!userData?.is_pro && item.is_blurry ? styles.screenItemContainerNotLogged : ""}>
                  {!userData?.is_pro && item.is_blurry && (
                    <div className={styles.screenItemContentNotLogged}>
                      <div className={styles.titleNotLogged}>
                        <p className={styles.titleNotLoggedPro}>
                          <span className={styles.span}>Pro</span>
                        </p>
                        <p className={styles.titleNotLoggedMember}>
                          Member's Only
                        </p>
                      </div>
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          handleOpenBillingPage();
                        }}
                        className={styles.buttonNotLogged}
                      >
                        <span className={styles.span}>Unlock Pro</span>
                      </button>
                    </div>
                  )}
                  <div className={!userData?.is_pro && item.is_blurry ? styles.imageContainerNotLogged : ""}>
                      <img
                        className={`${styles.highlighted} ${!userData?.is_pro && item.is_blurry ? styles.opacitymain : ''} ${item.is_blurry ? styles.featuredImage : ''}`}
                        src={item.screen}
                        alt="photosImg"
                        onLoad={handleImageLoad}
                        loading="lazy"
                      />
                    {(isMobile || hoveredTimestamp === item.timestamp) && (
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          handleClick(item, item.timestamp);
                        }}
                        type="button"
                        className={styles.bookmarkContainer}
                      >
                        <div className={styles.bookmark}>
                          {item.is_saved ? (
                            <IoIosCheckmarkCircle
                              style={{
                                color: "green",
                                fontSize: "18px",
                              }}
                            />
                          ) : (
                            <img
                              className={styles.bookmarkIcon}
                              src="/img/blackIcon/Save.svg"
                              alt="bookmarkImg"
                              loading="lazy"
                            />
                          )}
                        </div>
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )
        );
      })}
      <Modal
        isOpen={isModalOpenPhotoCard}
        onRequestClose={closeModal}
        contentLabel="Image Modal"
        className={styles.modal}
        overlayClassName={styles.overlay}
      >
        <div className={styles.modalContent}>
          <div
            className={styles.containerImage}
            onClick={handleImageClick}
            onContextMenu={(e) => handleContextMenu(e, selectedItem?.id.toString() || '', selectedTimestamp)}
            style={{
              cursor: 'url("/img/cursorHoverIcon.svg"), pointer',
            }}
          >
            <img
              className={styles.modalContentPhoto}
              src={selectedImage}
              alt="Enlarged pic"
              loading="lazy"
            />
            {!selectedItem?.is_blurry && (
              <div className={styles.bookmarkmodalContainer}>
                <button
                  type="button"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleClick(selectedItem, selectedTimestamp);
                  }}
                  className={styles.bookmarkmodal}
                >
                  {selectedItem?.is_saved ? (
                    <IoIosCheckmarkCircle
                      style={{
                        color: "green",
                        fontSize: "18px",
                      }}
                    />
                  ) : (
                    <img
                      className={styles.bookmarkIcon}
                      src="/img/blackIcon/Save.svg"
                      alt="bookmarkImg"
                      loading="lazy"
                    />
                  )}
                </button>
              </div>
            )}
          </div>
          <div>
            <button
              type="button"
              onClick={handleNextImage}
              className={styles.arrowButton}
            >
              <img
                className={styles.arrowRight}
                src="/img/whiteIcon/ChevronRight.svg"
                alt="arrowRightImg"
                loading="lazy"
              />
            </button>
            <button
              type="button"
              onClick={handlePrevImage}
              className={styles.arrowButton}
            >
              <img
                className={styles.arrowLeft}
                src="/img/whiteIcon/ChevronLeft.svg"
                alt="arrowLeftImg"
                loading="lazy"
              />
            </button>
          </div>
        </div>
      </Modal>
      {contextMenu && (
        <RightClickModal
          x={contextMenu.x}
          y={contextMenu.y}
          onClose={handleCloseContextMenu}
          imageUrl={appVideoScreens.find(screen => screen.id.toString() === contextMenu.imageId)?.screen || ''}
          imageId={contextMenu.imageId || ''}
          handleOpenInNewTab={handleClick}
          timestamp={contextMenu.timestamp}
          refetchData={refetchDataScreens}
          avsID={apiDataVideosOne?.id}
          imageType="video"
        />
      )}
    </div>
  );
};

export default React.forwardRef(PhotoCardList);
