import React, { useState, useEffect, useRef, useMemo, useCallback, ChangeEvent } from 'react'
import { useNavigate, Outlet, useLocation, useOutletContext } from 'react-router-dom'
import { useInView } from 'react-intersection-observer';

import {
  //useGetAvsTagsListQuery,
  useSearchAnythingQuery
} from "@/features/auth/api/authAPI";

import { useGetAvsTagsNotLoginQuery } from "@/features/auth/api/authAPI"
import { getFilterAppsState } from "@/entities/filterApps";
import { useDispatch, useSelector } from "react-redux";

import { toast } from 'react-toastify';
import MagicOCR from "@/widgets/magicOCR/ui/MagicOCR/MagicOCR";
import { filterActions, getFilterState } from "@/entities/filter";
import MagicModeButton from "@/widgets/magicModeButton/ui/MagicModeButton/MagicModeButton";
import FilterButton from "@/widgets/filter/ui/FilterButton/FilterButton";
import {
  filterAppsDataActions,
  getFilterAppsData,
} from "@/entities/filterAppsData";

import ExploreAppsStyles from "@/Views/Virtual/ExplorAppsNew.module.scss";


import {
  ACTIVE_VIEW_HOME,
  ACTIVE_VIEW_SEARCH,
  SEARCH_MODE_OCR,
  SEARCH_MODE_VECTOR,
  ACTIVE_VIEW_ANIMATION,
  ACTIVE_VIEW_WEB_ONBOARDING,
  ACTIVE_VIEW_SAVED_MODE
} from '@/Views/constants'



import { getCounterState } from '@/entities/counter';
import { useCurrentOrganization, useMe, useAPI } from '@/Hooks';
import mixpanel from '@/shared/lib/mixpanel';
import { filterAppsActions } from '@/entities/filterApps';
import { OnboardingModal } from "@/Views/OnboardingModal";
import { buttonFilterActions } from '@/entities/buttonFilter';
import { handleOpenVideoPage } from '@/app/libs/const/handleOpenVideoPage';
import { motion, AnimatePresence } from 'framer-motion';
import Spinner from '@/shared/ui/Spinner/Spinner';
import { produce } from 'immer';


const HomeRootView = () => {

  const dispatch = useDispatch();
  const api = useAPI()
  const { setFilterButtonPlaceHolder, setSearchBarPlaceHolder, rootActiveView, setRootActiveView } = useOutletContext();
  const [topLeft, setTopLeft] = useState()
  const [rootSearch, setRootSearch] = useState()
  const [anythingsearchOffset, setAnythingsearchOffset] = useState(0)
  const [anythingsearchLimit, setAnythingsearchLimit] = useState(20)
  const [anythingSearchResult, setAnythingSearchResult] = useState([])
  const [rootResultCount, setRootResultCount] = useState()
  const [rootResultFetching, setRootResultFetching] = useState(false)
  const [debouncedSearchValue, setDebouncedSearchValue] = useState("");
  const [rootResult, setRootResult] = useState()
  const location = useLocation()
  const [screenTypes, setScreenTypes] = useState([])
  const [uielements, setUielements] = useState([])
  const [activeSearchIndex, setActiveSearchIndex] = useState(0);
  const [shouldShowSpinner, setShouldShowSpinner] = useState(false);
  const [hasInitialLoad, setHasInitialLoad] = useState(false);
  const { ref: anythingSearchRef, inView: anythingSearchInView } = useInView({
    threshold: 0.5,
  });
  const [hasReachedEnd, setHasReachedEnd] = useState(false);

  useEffect(() => {
    setAnythingSearchResult([])
    setAnythingsearchOffset(0)
    setHasReachedEnd(false)
  }, [activeSearchIndex, debouncedSearchValue.length])



  const fetchScreenTypes = () => {
    api.get('/v1/tags/screen-types/')
      .then((res) => { setScreenTypes(res.data) })
  }

  const fetchUielements = () => {
    api.get('/v1/tags/ui-elements/')
      .then((res) => { setUielements(res.data) })
  }


  useEffect(() => {
    fetchScreenTypes()
    fetchUielements()
  }, [])

  const buttonSearchData = [
    {
      icon: "/img/blackIcon/Search.svg",
      name: "Trending Searches",
    },
    {
      icon: "/img/blackIcon/Screen.svg",
      name: "Apps",
    },
    {
      icon: "/img/blackIcon/Developer.svg",
      name: "Developers",
    },
    {
      icon: "/img/blackIcon/Screen2.svg",
      name: "Screen Types",
    },
    {
      icon: "/img/blackIcon/UI.svg",
      name: "UI Elements",
    },
  ]
  useEffect(() => {
    if (rootResult) {
      dispatch(
        filterAppsDataActions.setFilterAppDataSorted(rootResult.results),
      );
    }
  }, [rootResult, dispatch]);

  const { count } = useSelector(getCounterState);
  const navigate = useNavigate()
  const currentOrganization = useCurrentOrganization();
  const searchInputRef = useRef<HTMLInputElement>(null);

  const [searchValue, setSearchValue] = useState(() => {
    return new URLSearchParams(location.search).get('q') || "";
  });

  //const [searchValue, setSearchValue] = useState('')
  const [activeButtonHidStar, setActiveButtonHidStar] = useState<
    "hidden" | "starred" | null
  >(null);
  const { filterAppDataSorted } = useSelector(getFilterAppsData);
  const { isActiveMagic, isOcrSelected } = useSelector(getFilterState);

  const searchIndex = activeSearchIndex == 1 && debouncedSearchValue.length == 0 ? 'apps' : activeSearchIndex == 2 && debouncedSearchValue.length == 0 ? 'developers' : 'all'
  const searchSort = !searchValue ? 'asc' : undefined;

  const { data: anythingSearchResults, isFetching: isAnythingSearchLoading } = useSearchAnythingQuery(
    { q: debouncedSearchValue, i: searchIndex, s: searchSort, limit: anythingsearchLimit, offset: anythingsearchOffset },
    {
      // skip: !debouncedSearchValue || debouncedSearchValue.length < 2,
      refetchOnMountOrArgChange: true
    }
  );


  useEffect(() => {
    if (anythingSearchInView && !isAnythingSearchLoading && activeSearchIndex !== 0 && activeSearchIndex !== 3 && activeSearchIndex !== 4 && !hasReachedEnd) {
      setAnythingsearchOffset(anythingsearchOffset + anythingsearchLimit)
    }
  }, [anythingSearchInView, isAnythingSearchLoading, activeSearchIndex, hasReachedEnd])

  const {
    filterSort,
    filterGroup
  } = useSelector(getFilterAppsState);

  const [isManualTyping, setIsManualTyping] = useState(true);

  const handleChangeOCR = (e: ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setSearchValue(newValue);
    setIsQuerySuccessful(false);
    setIsManualTyping(true);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleSearch(false);
    }
  };

  const handleSearch = useCallback(async (isOcr: boolean) => {
    if (!searchValue.trim()) {
      toast.warn('Please enter a search term');
      return;
    }

    dispatch(filterActions.setOcrSelected(isOcr));

    const params = new URLSearchParams({
      'q': searchValue,
      'smode': isOcr ? SEARCH_MODE_OCR : SEARCH_MODE_VECTOR
    });

    dispatch(filterActions.deactivateFilterMagic());

    mixpanel.track('Search params', {
      search_term: searchValue,
      search_mode: isOcr ? 'text' : 'elements',
      UserEmail: me?.email || '',
      OrganizationID: currentOrganization?.uuid || '',
    });

    if (me?.email) {
      mixpanel.identify(me.email);
      setTimeout(() => {
        mixpanel.people.increment('total_performed_searches', 1);
        mixpanel.people.union({
          history_of_searches: [searchValue]
        });
      }, 100);
    }

    const searchPath = '/search/'

    const pathname = (rootActiveView === ACTIVE_VIEW_SEARCH)
      ? null
      : searchPath;

    navigate({ pathname: pathname, search: params.toString() });

  }, [searchValue, rootActiveView, location.pathname, navigate]);

  const [activeType, setActiveType] = useState<string>("");
  const sortDirection = useSelector((state) => state.filterApps.sortDirection);
  const modalRef = useRef<HTMLDivElement>(null);
  const magicOcrRef = useRef<HTMLDivElement>(null);
  const [isQuerySuccessful, setIsQuerySuccessful] = useState(false);
  const { me } = useMe()
  const elementRefs = useRef<(HTMLDivElement | null)[]>([]);
  const [visibleElementIndex, setVisibleElementIndex] = useState<number | null>(null);

  const uniqueFilterAppDataSorted = useMemo(() => {
    const uniqueSet = new Set(filterAppDataSorted.map((app) => app.id));
    return filterAppDataSorted.filter((app) => {
      if (uniqueSet.has(app.id)) {
        uniqueSet.delete(app.id);
        return true;
      }
      return false;
    });
  }, [filterAppDataSorted]);

  const handleFastSearchClick = (text: string) => {
    setSearchValue(text);
    setIsQuerySuccessful(false);
    setIsManualTyping(false);

    if (searchInputRef.current) {
      searchInputRef.current.focus();
    }

    const params = new URLSearchParams({
      'q': text,
      'smode': isOcrSelected ? SEARCH_MODE_OCR : SEARCH_MODE_VECTOR
    })

    dispatch(filterActions.deactivateFilterMagic())
    //navigate({ 'pathname': 'search', search: params.toString() })


    const searchPath = '/search/'

    navigate({
      pathname: searchPath,
      search: params.toString()
    });

  };


  const handleFastSearchClickNoLogin = (text: string) => {
    setSearchValue(text);
    setIsQuerySuccessful(false);
    setIsManualTyping(false);

    if (searchInputRef.current) {
      searchInputRef.current.focus();
    }

    const params = new URLSearchParams({
      'q': text,
      'smode': isOcrSelected ? SEARCH_MODE_OCR : SEARCH_MODE_VECTOR
    });

    dispatch(filterActions.deactivateFilterMagic());

    if (me?.email) {
      mixpanel.identify(me.email);
      setTimeout(() => {
        mixpanel.people.increment('total_performed_searches', 1);
        mixpanel.people.union({
          history_of_searches: [text]
        });
      }, 100);
    }
    mixpanel.track('Search params', {
      search_term: searchValue,
      search_mode: isOcrSelected ? 'text' : 'elements',
      UserEmail: me?.email || '',
      OrganizationID: currentOrganization?.uuid || '',
    });

    const searchPath = '/search/'

    navigate({
      pathname: searchPath,
      search: params.toString()
    });
  };


  useEffect(() => {
    const handleClickOutput = (event: MouseEvent) => {
      if (
        modalRef.current &&
        !modalRef.current.contains(event.target as Node) &&
        magicOcrRef.current &&
        !magicOcrRef.current.contains(event.target as Node)
      ) {

        const isInteractiveElement = (event.target as HTMLElement).closest('img,button,input,textarea,select,a, .magicOcrContentActiveMenuNew .magicMenuItemNew .menuGroupNew');
        const isWithinSearchResults = (event.target as HTMLElement).closest('.containerMagicMenuFast, .magicOcrContentActiveMenuNew');

        if (!isInteractiveElement && !isWithinSearchResults) {
          dispatch(filterActions.deactivateFilterMagic());
          dispatch(filterActions.setContextActive(true));
          setSearchValue("");
          setIsQuerySuccessful(false);
        }
      }
    };

    document.addEventListener("mousedown", handleClickOutput);

    return () => {
      document.removeEventListener("mousedown", handleClickOutput);
    };
  }, [dispatch, searchValue]);


  const onViewChange = (index: number, inView: boolean) => {
    if (inView) {
      setVisibleElementIndex(index);
    }
  };

  const handleMagicButtonClick = useCallback(() => {
    mixpanel.track('magic_button_click', {
      UserEmail: me?.email || '',
      OrganizationID: currentOrganization?.uuid || '',
    });

    setTimeout(() => {
      if (searchInputRef.current) {
        searchInputRef.current.focus();
      }
    }, 0);
  }, []);

  const isSearchPage = location.pathname.startsWith('/search');
  const isAnimationExplorer = location.pathname.startsWith('/animation-explorer');
  const isWebOnboarding = location.pathname.startsWith('/web-onboardings');
  const [noTopPaddingAfterDelay, setNoTopPaddingAfterDelay] = useState(false);

  const { data: notLoginTags = [] } = useGetAvsTagsNotLoginQuery();

  useEffect(() => {
    const timer = setTimeout(() => {
      setNoTopPaddingAfterDelay(true);
    }, 10000);

    return () => clearTimeout(timer);
  }, []);

  const [isZoomPopupOpen, setIsZoomPopupOpen] = useState(false);


  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 650 && isZoomPopupOpen) {
        setIsZoomPopupOpen(false);
      }
    };

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [isZoomPopupOpen]);


  const handleSortDirectionChange = (direction: "asc" | "desc") => {
    dispatch(filterAppsActions.setSortDirection(direction));
  };

  const handleExploreUIElementsClick = () => {
    dispatch(buttonFilterActions.toggleActiveView());
  }

  const order = useMemo(() => {
    return filterSort !== "" && filterGroup !== ""
      ? `${sortDirection === "desc" ? "-" : ""}${filterSort},${filterGroup}`
      : (filterSort !== "" &&
        `${sortDirection === "desc" ? "-" : ""}${filterSort}`) ||
      (filterGroup !== "" &&
        `${sortDirection === "desc" ? "-" : ""}${filterGroup}`) ||
      "";
  }, [filterSort, filterGroup, sortDirection]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedSearchValue(searchValue);
    }, 300);

    return () => clearTimeout(timer);
  }, [searchValue]);

  // const { data: avsTagsData = [] } = useGetAvsTagsListQuery(
  //   { q: debouncedSearchValue, },
  //   { skip: !debouncedSearchValue || debouncedSearchValue.length < 1 }
  // );


  let checkMagicOcrClick = (e) => {
    mixpanel.track('Search modal', {
      UserEmail: me?.email || '',
      OrganizationID: currentOrganization?.uuid || '',
    });
  }

  let checkFilterButtonClick = (e) => {
    mixpanel.track('Filter button', {
      UserEmail: me?.email || '',
      OrganizationID: currentOrganization?.uuid || '',
    });
  }

  useEffect(() => {
    if (location.pathname.startsWith('/search')) {
      setRootActiveView(ACTIVE_VIEW_SEARCH);
      dispatch(filterAppsDataActions.setRootActiveView(ACTIVE_VIEW_SEARCH));
    }
    else if (location.pathname.startsWith('/animation-explorer')) {
      setRootActiveView(ACTIVE_VIEW_ANIMATION);
      dispatch(filterAppsDataActions.setRootActiveView(ACTIVE_VIEW_ANIMATION));
    }
    else if (location.pathname.startsWith('/web-onboardings')) {
      setRootActiveView(ACTIVE_VIEW_WEB_ONBOARDING);
      dispatch(filterAppsDataActions.setRootActiveView(ACTIVE_VIEW_WEB_ONBOARDING));
    }
    else if (location.pathname.startsWith('/saved-library')) {
      setRootActiveView(ACTIVE_VIEW_SAVED_MODE);
      dispatch(filterAppsDataActions.setRootActiveView(ACTIVE_VIEW_SAVED_MODE));
    }
    else if (location.pathname === '/') {
      setRootActiveView(ACTIVE_VIEW_HOME);
      dispatch(filterAppsDataActions.setRootActiveView(ACTIVE_VIEW_HOME));
    }
  }, [location.pathname, location.search]);




  const handleSearchButtonClick = (index: number) => {
    setActiveSearchIndex(index);
    if (index === 1 || index === 2) {
      setShouldShowSpinner(true);
      setHasInitialLoad(false);
    }
    setAnythingSearchResult([]);

    const contentContainer = document.querySelector(`.${ExploreAppsStyles.containerSearchContent}`);
    if (contentContainer) {
      contentContainer.scrollTop = 0;
    }
  };


  const handleDeveloperClick = (slug: string) => {
    // temporarily disabled until developer page is ready
    //navigate(`/developers/${slug}`)
  }

  const upperCaseOnlyFirstLetterIfAllUpper = (text: string) => {
    if (text === text.toUpperCase()) {
      return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
    }
    return text;
  }


  useEffect(() => {
    setFilterButtonPlaceHolder(
      <FilterButton
        isFetching={rootResultFetching}
        apiDataFilter={rootResult}
        isNext={!!rootResult?.next}
        setSortDirection={handleSortDirectionChange}
        activeType={activeType}
        setActiveType={setActiveType}
        uniqueFilterAppDataSorted={uniqueFilterAppDataSorted}
        activeButtonHidStar={activeButtonHidStar}
        setActiveButtonHidStar={setActiveButtonHidStar}
        rootActiveView={rootActiveView}
      />
    )
    return () => {
      setFilterButtonPlaceHolder(<></>)
    }
  }, [rootResult, rootResultFetching, uniqueFilterAppDataSorted, order]);


  useEffect(() => {

    if (![ACTIVE_VIEW_SEARCH, ACTIVE_VIEW_SAVED_MODE].includes(rootActiveView)) {
      setSearchBarPlaceHolder(
        <MagicModeButton
          showLottie={false}
          onButtonClick={handleMagicButtonClick}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
        />
      )
    } else {
      setSearchBarPlaceHolder(<></>)
    }

    return () => {
      setSearchBarPlaceHolder(<></>)
    }
  }, [rootActiveView])

  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedSearchValue(searchValue);
    }, 300);

    return () => clearTimeout(timer);
  }, [searchValue]);

  useEffect(() => {
    if (anythingSearchResults && shouldShowSpinner) {
      setShouldShowSpinner(false);
      setHasInitialLoad(true);
    }
  }, [anythingSearchResults]);

  useEffect(() => {
    if (anythingSearchResults) {
      if (anythingSearchResults.hits.length === 0) {
        setHasReachedEnd(true)
      }

      setAnythingSearchResult(prevResults => {
        if (!hasInitialLoad) {
          return [...anythingSearchResults.hits];
        }
        return produce(prevResults, draft => {
          draft.push(...anythingSearchResults.hits);
        });
      });
    }
  }, [anythingSearchResults, hasInitialLoad]);

  return (
    <div className={ExploreAppsStyles.homeRootView}>
      <div className={ExploreAppsStyles.headerSearch}>
        {isActiveMagic && !isQuerySuccessful && (
          <AnimatePresence mode="wait">
            <motion.div
              className={ExploreAppsStyles.containerMagicOcr}
              variants={{
                hidden: {
                  opacity: 0,
                  x: "-50%",
                  y: 0,
                  scale: 0.8,
                },
                visible: {
                  opacity: 1,
                  x: "-50%",
                  y: 0,
                  scale: 1,
                  transition: {
                    type: "spring",
                    duration: 0.4,
                    bounce: 0.2
                  }
                },
                exit: {
                  opacity: 0,
                  x: "-50%",
                  y: "-100%",
                  scale: 0.8,
                  transition: {
                    type: "spring",
                    duration: 0.3,
                    bounce: 0
                  }
                }
              }}
              initial="hidden"
              animate="visible"
              exit="exit"
            >
              <div
                ref={magicOcrRef}
                className={ExploreAppsStyles.MagicOcrWrapper}
              >
                <div onClick={checkMagicOcrClick} ref={modalRef} className={`${ExploreAppsStyles.MagicOcr} `}>
                  <MagicOCR
                    searchValue={searchValue}
                    handleChangeOCR={handleChangeOCR}
                    onKeyDown={handleKeyDown}
                    style={{ width: '100%' }}
                    onSearch={handleSearch}
                    ref={searchInputRef}
                  />
                  {/* {me && searchValue && searchValue.length >= 1 && isManualTyping && avsTagsData.length > 0 && (
                    <div className={ExploreAppsStyles.containerTags}>
                      {avsTagsData.map((tagData, index) => (
                        <p key={index} onClick={() => handleFastSearchClick(tagData.tag)}>
                          {tagData.tag}
                        </p>
                      ))}
                    </div>
                  )} */}
                </div>

                <div className={ExploreAppsStyles.containerSearchList}>
                  {searchValue && (
                    <>

                      <div className={ExploreAppsStyles.searchResultItem} onClick={() => handleSearch(false)}>
                        <div className={ExploreAppsStyles.searchResultItemIcon}>
                          <img src="/img/blackIcon/SparkRight.svg" alt={searchValue} className={ExploreAppsStyles.searchResultItemImg} />
                        </div>
                        <div className={ExploreAppsStyles.resultContainer}>
                          <div title={searchValue} className={ExploreAppsStyles.resultName}>{searchValue}</div>
                          <div className={ExploreAppsStyles.resultCategory}>AI Search</div>
                        </div>
                      </div>

                      <div className={ExploreAppsStyles.searchResultItem} onClick={() => handleSearch(true)}>
                        <div className={ExploreAppsStyles.searchResultItemIcon}>
                          <img src="/img/blackIcon/TextInscreen.svg" alt={searchValue} className={ExploreAppsStyles.searchResultItemImg} />
                        </div>
                        <div className={ExploreAppsStyles.resultContainer}>
                          <div title={searchValue} className={ExploreAppsStyles.resultName}>{searchValue}</div>
                          <div className={ExploreAppsStyles.resultCategory}>Text inside Screens</div>
                        </div>
                      </div>

                      {anythingSearchResult.map((item, index) => (
                        <div key={index}>
                          {item.type == 'avs_tags' && (
                            <div key={`tags-${index}`} className={ExploreAppsStyles.searchResultItem} onClick={() => handleFastSearchClick(item.tag)}>
                              <div className={ExploreAppsStyles.searchResultItemIcon}>
                                <img src="/img/blackIcon/SparkRight.svg" alt={searchValue} className={ExploreAppsStyles.searchResultItemImg} />
                              </div>
                              <div className={ExploreAppsStyles.resultContainer}>
                                <div title={item.tag} className={ExploreAppsStyles.resultName}>{item.tag}</div>
                                <div className={ExploreAppsStyles.resultCategory}>AI Search</div>
                              </div>
                            </div>
                          )}

                          {item.type == 'apps' && (
                            <div key={`apps-${index}`} className={ExploreAppsStyles.searchResultItem} onClick={() => handleOpenVideoPage(0,
                              item.slug,
                              "Home Page screens",
                              1,
                              item.id,
                              //item.latest_appvideo_id
                            )}>
                              <img src={item.icon} alt={item.name} className={ExploreAppsStyles.resultIcon} />
                              <div className={ExploreAppsStyles.resultContainer}>
                                <h5 title={item.name} className={ExploreAppsStyles.resultName}>{item.name}</h5>
                                <h6 className={ExploreAppsStyles.resultCategory}>{item.shortname}</h6>
                              </div>
                            </div>
                          )}

                          {/* {item.type == 'developers' && (
                            <div className={ExploreAppsStyles.searchResultItem} onClick={() => handleDeveloperClick(item.slug)}>
                              <div className={ExploreAppsStyles.searchResultItemIcon}>
                                <img src="/img/blackIcon/Developer.svg" alt={searchValue} className={ExploreAppsStyles.searchResultItemImg} />
                              </div>
                              <div className={ExploreAppsStyles.resultContainer}>
                                <div title={item.name} className={ExploreAppsStyles.resultName}>{item.name}</div>
                                <div className={ExploreAppsStyles.resultCategory}>Developer</div>
                              </div>
                            </div>
                          )} */}

                        </div>
                      ))
                      }
                      <div ref={anythingSearchRef} style={{ minHeight: "10px" }}>
                        {isAnythingSearchLoading && (
                          <div className={ExploreAppsStyles.spinner}>
                            <Spinner color="#000" />
                          </div>
                        )}
                      </div>

                    </>
                  )}

                  {searchValue.length < 1 && (
                    <div className={ExploreAppsStyles.containerSearch}>
                      <div className={ExploreAppsStyles.containerButtonSearch}>
                        {buttonSearchData.map((item, index) => {
                          // temporarily disable developers while keeping same indexes
                          if (item.name !== 'Developers') {
                            return (
                              <div
                                key={index}
                            className={`${ExploreAppsStyles.buttonSearch} ${activeSearchIndex === index ? ExploreAppsStyles.buttonSearchActive : ''}`}
                            onClick={() => handleSearchButtonClick(index)}
                          >
                            <img src={item.icon} alt={item.name}  />
                              <h5 title={item.name} className={ExploreAppsStyles.buttonSearchName}>{item.name}</h5>
                            </div>
                            )
                          }
                        })}

                      </div>
                      <div className={ExploreAppsStyles.containerSearchContent}>


                        {shouldShowSpinner && (activeSearchIndex === 1 || activeSearchIndex === 2) ? (
                          <div className={ExploreAppsStyles.spinner}>
                            {/* <Spinner color="#000" /> */}
                          </div>
                        ) : (
                          <>

                            {activeSearchIndex === 1 && (
                              // apps
                              (anythingSearchResult.length > 0 ? anythingSearchResult : [])?.map((item, index) => (
                                <div key={index} className={ExploreAppsStyles.searchResultItem} onClick={() => handleOpenVideoPage(0,
                                  item.slug,
                                  "Home Page screens",
                                  1,
                                  item.id,
                                  //item.latest_appvideo_id
                                )}>
                                  <img src={item.icon} alt={item.name} className={ExploreAppsStyles.resultIcon} />
                                  <div className={ExploreAppsStyles.resultContainer}>
                                    <p title={item.name} className={`${ExploreAppsStyles.resultName} ${ExploreAppsStyles.appName}`}>{item.name}</p>
                                    <p className={`${ExploreAppsStyles.appShortName}`}>{item.shortname}</p>
                                  </div>
                                </div>
                              ))
                            )}

                            {/* {activeSearchIndex === 2 && (
                              // developers
                              (anythingSearchResult.length > 0 ? anythingSearchResult : [])?.map((item, index) => (
                                <div key={index} className={ExploreAppsStyles.containerMagicMenuFast} onClick={() => handleDeveloperClick(item.slug)}>
                                  <div className={ExploreAppsStyles.resultContainer}>
                                    <p title={item.name}>{upperCaseOnlyFirstLetterIfAllUpper(item.name)}</p>
                                  </div>
                                </div>
                              ))
                            )} */}

                          </>
                        )}

                        {activeSearchIndex === 0 && (
                          <div className={`${ExploreAppsStyles.containerMagicMenu} ${!me ? ExploreAppsStyles.containerMagicMenuNotLogin : ''}`}>
                            <div className={ExploreAppsStyles.containerMagicMenuFast}>
                              {notLoginTags.map((text, index) => (
                                <p
                                  key={index}
                                  onClick={() => ((me?.is_pro || currentOrganization?.is_pro) ? handleFastSearchClick(text) : handleFastSearchClickNoLogin(text))}
                                >
                                  {text}
                                </p>
                              ))}
                            </div>
                          </div>
                        )}


                        {activeSearchIndex === 3 && (
                          <div className={`${ExploreAppsStyles.containerMagicMenu} ${!me ? ExploreAppsStyles.containerMagicMenuNotLogin : ''}`}>
                            <div className={ExploreAppsStyles.containerMagicMenuFast}>
                              {screenTypes.map((text, index) => (
                                <p
                                  key={index}
                                  onClick={() => ((me?.is_pro || currentOrganization?.is_pro) ? handleFastSearchClick(text) : handleFastSearchClickNoLogin(text))}
                                >
                                  {text}
                                </p>
                              ))}
                            </div>
                          </div>
                        )}


                        {activeSearchIndex === 4 && (
                          <div className={`${ExploreAppsStyles.containerMagicMenu} ${!me ? ExploreAppsStyles.containerMagicMenuNotLogin : ''}`}>
                            <div className={ExploreAppsStyles.containerMagicMenuFast}>
                              {uielements.map((text, index) => (
                                <p
                                  key={index}
                                  onClick={() => ((me?.is_pro || currentOrganization?.is_pro) ? handleFastSearchClick(text) : handleFastSearchClickNoLogin(text))}
                                >
                                  {text}
                                </p>
                              ))}
                            </div>
                          </div>
                        )}


                        <div ref={anythingSearchRef} style={{ width: "100%", minHeight: "10px" }}>
                          {isAnythingSearchLoading && activeSearchIndex !== 0 && activeSearchIndex !== 3 && activeSearchIndex !== 4 && (
                            <div className={ExploreAppsStyles.spinner}>
                              <Spinner width="50px" color="#000" />
                            </div>
                          )}
                        </div>

                      </div>
                    </div>
                  )}
                </div>
              </div>
            </motion.div>
          </AnimatePresence>
        )}
      </div>
      <div >

        <div
          className={`${ExploreAppsStyles.containerTop} ${isActiveMagic ? ExploreAppsStyles.containerTopOverlay : ""}`}
        >
          {!isWebOnboarding && (
            <div>
              {topLeft}
            </div>
          )}

          {!isAnimationExplorer && !isWebOnboarding && (
            <div className={isSearchPage ? ExploreAppsStyles.magicModeButton : ExploreAppsStyles.magicModeButtonMobile}>
              {rootSearch}
            </div>
          )}

          <div>
            <div
              onClick={checkFilterButtonClick}
              className={ExploreAppsStyles.boxFilters}
            >

              {/* single view */}

              {/* {rootActiveView === ACTIVE_VIEW_HOME  && (
                <button className={ExploreAppsStyles.ExploreUIElements} onClick={handleExploreUIElementsClick}>
                  <img src={`${activeView === 0 ? "/img/blackIcon/ExploreUIElements.svg" : "/img/blackIcon/Categorie.svg"}`} alt="icon" />
                </button>
              )} */}


            </div>

          </div>
        </div>

        <OnboardingModal />

        <Outlet context={{
          topLeft: topLeft,
          setTopLeft: setTopLeft,
          setRootSearch: setRootSearch,
          setRootResult: setRootResult,
          setRootResultCount: setRootResultCount,
          setRootResultFetching: setRootResultFetching,
          setSearchValue: setSearchValue,
          searchValue: searchValue,
          sortDirection: sortDirection,
          setSortDirection: handleSortDirectionChange,
          order: order,
          elementRefs: elementRefs,
          onViewChange: onViewChange,
          setRootActiveView: setRootActiveView,
          searchInputRef: searchInputRef,
        }} />

      </div>
    </div>
  )
}

export { HomeRootView }