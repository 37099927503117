import React, { FC, useEffect, useRef, useState } from "react";
import Modal from "react-modal";
import { Organization } from "@/features/auth/types/auth";
import { useSavedStorePointsMutation, useSavedStoreScreensDeleteMutation } from "@/features/auth/api/authAPI";
import styles from "./AppStoreScreensModal.module.scss";
import { IoIosCheckmarkCircle } from "react-icons/io";
import { produce } from 'immer'
import { useMe } from "@/Hooks";
import mixpanel from "@/shared/lib/mixpanel";
import { toast } from "react-toastify";
import { RightClickModal } from "@/shared/ui/RightClickModal/RightClickModal";

interface AppStoreScreensModalProps {
  appStoreScreens,
  setAppStoreScreens,
  appStoreImageId?: string;
  appId?: string | null;
  isPro: boolean;
  currentOrganization: Organization;
  refetchDataStore: () => void;
}

const AppStoreScreensModal: FC<AppStoreScreensModalProps> = ({
  appStoreScreens,
  setAppStoreScreens,
  appStoreImageId,
  appId,
  isPro,
  currentOrganization,
  refetchDataStore,
}) => {

  const { me } = useMe()
  const [zoom, setZoom] = useState(1);
  const [isActiveSave, setIsActiveSave] = useState<Array<number>>([]);
  const [selectedItem, setSelectedItem] = useState()
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const [imageIndex, setImageIndex] = useState<number | null>(null);
  const [, setTotalImagesCount] = useState(0);
  const photoAppStoreScreens = useRef<HTMLDivElement>(null);
  const imageRefs = useRef<{ [key: string]: HTMLImageElement | null }>({});
  const [savedStoreScreens] = useSavedStorePointsMutation();
  const [savedStoreScreensDelete] = useSavedStoreScreensDeleteMutation();

  useEffect(() => {
    if (appStoreScreens) {
      setTotalImagesCount(!isPro ? 2 : appStoreScreens.length);
    }
  }, [appStoreScreens]);

  const scrollToImage = (imageId: string) => {
    if (photoAppStoreScreens.current && imageRefs.current[imageId]) {
      const elementRect = imageRefs.current[imageId]!.getBoundingClientRect();
      const parentRect = photoAppStoreScreens.current!.getBoundingClientRect();

      const scrollToMiddle =
        elementRect.left -
        parentRect.left -
        parentRect.width / 2 +
        elementRect.width / 2;

      photoAppStoreScreens.current.scrollTo({
        left: photoAppStoreScreens.current.scrollLeft + scrollToMiddle,
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    if (appStoreImageId) {
      scrollToImage(appStoreImageId);
      localStorage.removeItem(`appStoreScreens_${appId}`);
    }
  }, [appStoreImageId]);

  const applyZoom = (zoom: number) => {
    const photoCardListElement = photoAppStoreScreens.current;
    if (photoCardListElement) {
      photoCardListElement.style.setProperty("zoom", zoom.toString());
      photoCardListElement.style.setProperty("-ms-zoom", zoom.toString());
    }
  };

  useEffect(() => {
    applyZoom(zoom);
  }, [zoom]);

  const openImageModal = (item, image: string, index: number) => {
    setSelectedItem(item)
    setSelectedImage(item.thumbnail);
    setImageIndex(index);
  };

  const closeImageModal = () => {
    setSelectedImage(null)
    setSelectedImage(null);
    setImageIndex(null);
  };

  const handleNextImage = () => {
    if (imageIndex !== null && imageIndex < appStoreScreens.length - 1) {
      const nextIndex = imageIndex + 1;
      const nextItem = appStoreScreens[nextIndex];
      if (nextItem) {
        setSelectedItem(nextItem);
        setSelectedImage(nextItem.thumbnail);
        setImageIndex(nextIndex);
      }
    }
  };

  const handlePrevImage = () => {
    if (imageIndex !== null && imageIndex > 0) {
      const prevIndex = imageIndex - 1;
      const prevItem = appStoreScreens[prevIndex];
      if (prevItem) {
        setSelectedItem(prevItem);
        setSelectedImage(prevItem.thumbnail);
        setImageIndex(prevIndex);
      }
    }
  };

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (selectedImage) {
        if (event.key === "ArrowRight") {
          handleNextImage();
        } else if (event.key === "ArrowLeft") {
          handlePrevImage();
        }
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [selectedImage, imageIndex, appStoreScreens]);

  const isLargeScreen = window.innerWidth >= 1740;
  const isFirstImage = imageIndex === 0;
  const isLastImage = imageIndex === appStoreScreens.length - 1;

  const handleSaveOrDeleteImage = async (item: number) => {
    if (!me) {
      localStorage.setItem("btnName", "save image");
      window.location.href = "/register";
      return;
    }

    const imageToUpdate = appStoreScreens.find((screen) => screen.id === item);
    if (!imageToUpdate) return;

    try {
      if (imageToUpdate.is_saved) {
        const response = await savedStoreScreensDelete(imageToUpdate.is_saved_pk);

        if (me?.email) {
          mixpanel.identify(me.email);
          setTimeout(() => {
            mixpanel.people.increment('total_unsaved_screens', 1);
          }, 100);
        }

        if (response && !('error' in response)) {
          setAppStoreScreens(produce((draft) => {
            const index = draft.findIndex((obj) => obj.id === item);
            if (index !== -1) {
              draft[index].is_saved = false;
              draft[index].is_saved_pk = undefined;
            }
          }));
          toast.success("Image removed from saved");
        } else {
          throw new Error("Failed to remove saved image");
        }
      } else {
        const response = await savedStoreScreens({
          organizationUuid: currentOrganization.uuid,
          storeScreen: item,
        });

        if (me?.email) {
          mixpanel.identify(me.email);
          setTimeout(() => {
            mixpanel.people.increment('total_saved_screens', 1);
          }, 100);
        }

        mixpanel.track('Image saved', {
          idApp: appId || '',
          From: "VideoPage",
          idscreen: item,
          UserEmail: me?.email || '',
          OrganizationID: currentOrganization?.uuid || '',
        });

        if (response && !('error' in response)) {
          setAppStoreScreens(produce((draft) => {
            const index = draft.findIndex((obj) => obj.id === item);
            if (index !== -1) {
              draft[index].is_saved = true;
              draft[index].is_saved_pk = response.data?.id;
            }
          }));
          //toast.success("Image saved successfully");
        } else {
          throw new Error("Failed to save image");
        }
      }
    } catch (error) {
      console.error("Error:", error);
      if (error instanceof Error) {
        toast.error(error.message);
      } else {
        toast.error("Failed to update image save status");
      }
    }
  };

  // State to manage RightClickModal
  const [rightClickModal, setRightClickModal] = useState<{
    visible: boolean;
    x: number;
    y: number;
    imageUrl: string;
    imageId: number;
    timestamp: number;
  }>({ visible: false, x: 0, y: 0, imageUrl: '', imageId: 0, timestamp: 0 });

  const handleContextMenu = (e: React.MouseEvent, item: any) => {
    e.preventDefault();
    setRightClickModal({
      visible: true,
      x: e.clientX,
      y: e.clientY,
      imageUrl: item.image,
      imageId: item.id,
      timestamp: new Date(item.created_at).getTime(),
    });
  };

  const closeRightClickModal = () => {
    setRightClickModal((prev) => ({ ...prev, visible: false }));
  };

  return (
    <>
      <div className={styles.root}>

        <div className={styles.containerPhotos} ref={photoAppStoreScreens}>
          <>
            {(isPro ? appStoreScreens : appStoreScreens).map((item, index) => {
              return (
                <div
                  onClick={() => openImageModal(item, item.image, index)}
                  onContextMenu={(e) => handleContextMenu(e, item)}
                  key={item.id}
                  className={styles.imageContainer}
                  onMouseOver={() => {
                    setIsActiveSave([...isActiveSave, index]);
                  }}
                  onMouseOut={() => {
                    setIsActiveSave(
                      isActiveSave.filter((innerIndex) => innerIndex != index),
                    );
                  }}
                >
                  {isActiveSave.filter((innerIndex) => innerIndex == index)
                    .length > 0 && (
                      <button
                        type="button"
                        onClick={(event) => {
                          event.stopPropagation();
                          handleSaveOrDeleteImage(item.id);
                        }}
                        className={styles.bookmarkContainer}
                      >
                        <div className={styles.bookmark}>
                          {item.is_saved ? (
                            <img
                              className={styles.bookmarkIcon}
                              style={{ width: "20px" }}
                              src="/img/checkCircleGroupIconActive.svg"
                              alt="checkCircleImg"
                            />
                          ) : (
                            <img
                              className={styles.bookmarkIcon}
                              src="/img/blackIcon/Save.svg"
                              alt="bookmarkImg"
                              loading="lazy"
                            />
                          )}
                        </div>
                      </button>
                    )}
                  <img
                    src={item.thumbnail}
                    alt={`Screen ${item.id}`}
                    className={styles.image}
                    ref={(el) => (imageRefs.current[item.id] = el)}
                    style={{
                      maxHeight: isLargeScreen ? "805px" : window.innerWidth <= 750 ? "500px" : "715px",
                      maxWidth: isLargeScreen ? "377.17px" : window.innerWidth <= 750 ? "234px" : "335px",
                    }}
                    loading="lazy"
                  />
                </div>
              );
            })}
          </>
        </div>
      </div>

      {rightClickModal.visible && (
        <RightClickModal
          x={rightClickModal.x}
          y={rightClickModal.y}
          onClose={closeRightClickModal}
          imageUrl={rightClickModal.imageUrl}
          imageId={rightClickModal.imageId}
          timestamp={rightClickModal.timestamp}
          isStoreScreen={true}
          handleOpenInNewTab={() => { }}
          refetchData={refetchDataStore}
          imageType="store"
        />
      )}

      <Modal
        isOpen={!!selectedImage}
        onRequestClose={closeImageModal}
        contentLabel="Image Modal"
        className={styles.modal}
        overlayClassName={styles.overlay}
      >
        <div className={styles.modalContent} onContextMenu={(e) => handleContextMenu(e, selectedItem)}>
          <div className={styles.containerImage}>
            <img
              className={styles.modalContentPhoto}
              src={selectedImage!}
              alt="Enlarged pic"
              loading="lazy"
            />
            <div className={styles.bookmarkmodalContainer}>
              <button
                type="button"
                onClick={() => handleSaveOrDeleteImage(appStoreScreens[imageIndex!].id)}
                className={styles.bookmarkmodal}
              >
                {selectedItem && selectedItem.is_saved ? (
                  <IoIosCheckmarkCircle
                    style={{
                      color: "green",
                      fontSize: "18px",
                    }}
                  />
                ) : (
                  <img
                    className={styles.bookmarkIcon}
                    src="/img/blackIcon/Save.svg"
                    alt="bookmarkImg"
                    loading="lazy"
                  />
                )}
              </button>
            </div>
          </div>
          <img
            className={styles.arrowRight}
            src="/img/whiteIcon/ChevronRight.svg"
            alt="arrowRightImg"
            onClick={handleNextImage}
            loading="lazy"
            style={{
              visibility: isLastImage ? "hidden" : "visible",
              cursor: isLastImage ? "default" : "pointer",
            }}
          />
          <img
            className={styles.arrowLeft}
            src="/img/whiteIcon/ChevronLeft.svg"
            alt="arrowLeftImg"
            onClick={handlePrevImage}
            loading="lazy"
            style={{
              visibility: isFirstImage ? "hidden" : "visible",
              cursor: isFirstImage ? "default" : "pointer",
            }}
          />
        </div>
      </Modal>
    </>
  );
};

export default AppStoreScreensModal;
