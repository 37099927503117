// react
import React, { useState } from "react";
// redux
import { useDispatch, useSelector } from "react-redux";
// styles
import styles from "./FlowsTypes.module.scss";
import { filterAppsActions, getFilterAppsState } from "@/entities/filterApps";
import { useGetFlowTypesQuery } from "@/features/auth/api/authAPI";
import StoredCount from "../../shared/ui/storedcount/StoredCound";
import { handleFocus } from "../inputSearch/libs/const/handleFocus";
import { ACTIVE_VIEW_HOME } from "@/Views/constants";

interface FlowsTypesProps {
    activeButton: string | null;
    setActiveButton: (value: string | null) => void;
}

const FlowsTypes: React.FC<FlowsTypesProps> = ({
    activeButton,
    setActiveButton,
}) => {
    const dispatch = useDispatch();
    const { data: flowTypes, isLoading, error } = useGetFlowTypesQuery();
    const [isResetVisible, setIsResetVisible] = useState(false);

    const rootActiveView = useSelector(getFilterAppsState);

    React.useEffect(() => {
        setIsResetVisible(activeButton !== null);
    }, [activeButton]);

    const handleReset = () => {
        dispatch(filterAppsActions.resetFlowsFilter());
        setActiveButton(null);

    }

    const handleCountClick = (flowType: string) => {
        if (activeButton === flowType) {
            setActiveButton(null);
            dispatch(filterAppsActions.resetFlowsFilter());
        } else {
            setActiveButton(flowType);
            dispatch(filterAppsActions.setFlowType(flowType));
        }
    };

    if (isLoading) return <div>Loading...</div>;
    if (error) return <div>Error loading flow types</div>;

    return (
        <div className={styles.root}>
            <div className={styles.flex}>
                {flowTypes?.map((flowType) => (
                    <div
                        key={flowType.name}
                        className={`${styles.button} ${activeButton === flowType.name ? styles.active : ''}`}
                        onClick={() => handleCountClick(flowType.name)}
                    >
                        {flowType.name}
                    </div>
                ))}
            </div>
            <div className={styles.flex} >
                {rootActiveView === ACTIVE_VIEW_HOME &&
                    <StoredCount onFocus={() => handleFocus(dispatch)} />
                }
                {isResetVisible && (
                    <div onClick={handleReset} className={styles.reset}>
                        <img src="/img/gradientIcon/Reset.svg" alt="resetImg" loading="lazy" />
                    </div>
                )}
            </div>
        </div>
    );
};

export default FlowsTypes;

