import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import styles from "./SortByButton.module.scss";
import { dataItem } from "@/widgets/categoriesRevenueModal/libs/data/dataItem";
import useHandleClick from "@/widgets/categoriesRevenueModal/libs/hook/useHandleClick";
import { filterAppsDataActions } from "@/entities/filterAppsData";
import { filterAppsActions, getFilterAppsState } from "@/entities/filterApps";

export const SortByButton = ({ setSortMenuOpen }) => {

  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);

  const { filterSort } = useSelector(getFilterAppsState);

  const [selectedItem, setSelectedItem] = useState(filterSort);
  const sortDirection = useSelector((state) => state.filterApps.sortDirection);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const { handleClick } = useHandleClick();


  useEffect(() => {
    setSortMenuOpen(isOpen);
  }, [isOpen]);

  const handleClickItem = (index: number, text: string, filter: string, event?: React.MouseEvent) => {
    event?.stopPropagation();
    handleClick(index, text, filter);
    setSelectedItem(filter);
  };

  const handleSortDirectionChange = (direction: "asc" | "desc", event: React.MouseEvent) => {
    event.stopPropagation();
    if (sortDirection === direction) return;

    dispatch(filterAppsActions.resetPageFilterSidebar());
    dispatch(filterAppsDataActions.resetFilterAppDataSortedAll());
    dispatch(filterAppsDataActions.resetFilterAppDataSorted());
    dispatch(filterAppsDataActions.resetFilterAppData());
    dispatch(filterAppsActions.setSortDirection(direction));
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    setSelectedItem(filterSort);
  }, [filterSort]);

  const getSortingDescLabel = (filterSort: string, event?: React.MouseEvent) => {
    event?.stopPropagation();
    switch (filterSort) {
      case 'created_at':
        return 'Newest'
      case 'name':
        return 'Z - A'
      case 'download':
        return 'Higher'
      case 'rev_install_rate':
        return 'Higher'
      case 'release_date':
        return 'Newest'
      case 'revenue':
        return 'Highest'
      case 'onboarding_step_count':
        return 'Highest'
      default:
        return 'Descending'
    }
  }


  const getSortingAscLabel = (filterSort: string, event?: React.MouseEvent) => {
    event?.stopPropagation();
    switch (filterSort) {
      case 'created_at':
        return 'Oldest'
      case 'name':
        return 'A - Z'
      case 'download':
        return 'Lower'
      case 'rev_install_rate':
        return 'Lower'
      case 'release_date':
        return 'Oldest'
      case 'revenue':
        return 'Lowest'
      case 'onboarding_step_count':
        return 'Lowest'
      default:
        return 'Descending'
    }
  }

  return (
    <>
      {isOpen && (
        <div
          className={styles.overlay}
          onClick={() => setIsOpen(false)}
        />
      )}
      <div
        ref={dropdownRef}
        className={styles.sortBy}
        onClick={() => setIsOpen(!isOpen)}
      >

        <div style={{ display: 'flex', alignItems: 'left', gap: '5px' }}>
          <img src="/img/blackIcon/Sort.svg" alt="Sort By" />
          <p className={styles.sortByText}>Sort</p>
        </div>

        {isOpen && (
          <div className={styles.sortByMenu}>
            {dataItem.map((item, index) => (
              <div
                key={item.text}
                className={`${styles.sortByMenuItem} ${selectedItem === item.filter ? styles.active : ""}`}
                onClick={(e) => handleClickItem(index, item.text, item.filter, e)}
              >
                {item.text}
              </div>
            ))}
            <div className={styles.sortByDescendingAscending}>
              {filterSort === 'name' ? (
                <>
                  <button
                    className={`${styles.sortByMenuItem} ${sortDirection === "asc" ? styles.activeDescendingAscending : ""}`}
                    onClick={(e) => handleSortDirectionChange("asc", e)}
                  >
                    {getSortingAscLabel(filterSort)}
                  </button>
                  <button
                    className={`${styles.sortByMenuItem} ${sortDirection === "desc" ? styles.activeDescendingAscending : ""}`}
                    onClick={(e) => handleSortDirectionChange("desc", e)}
                  >
                    {getSortingDescLabel(filterSort)}
                  </button>
                </>
              ) : (
                <>
                  <button
                    className={`${styles.sortByMenuItem} ${sortDirection === "desc" ? styles.activeDescendingAscending : ""}`}
                    onClick={(e) => handleSortDirectionChange("desc", e)}
                  >
                    {getSortingDescLabel(filterSort)}
                  </button>
                  <button
                    className={`${styles.sortByMenuItem} ${sortDirection === "asc" ? styles.activeDescendingAscending : ""}`}
                    onClick={(e) => handleSortDirectionChange("asc", e)}
                  >
                    {getSortingAscLabel(filterSort)}
                  </button>
                </>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
};
