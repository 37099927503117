import React, { useEffect, useState } from "react";
import { ComparisonTable } from "./Onboarding/ComparisonTable";
import { StepIndicator } from "./Onboarding/StepIndicator";
import { GetStartedButton } from "./Onboarding/GetStartedButton";
import styles from './Onboarding/OnboardingModal.module.scss';
import { useOutletContext } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';

const steps = [
  {
    heading: "The Netflix for app growth. <br/>Watch how top apps print money, frame by frame.",
    subtext: "1,500+ apps x-rayed.\nEvery screen. Every flow. Every detail.\nBeyond pretty UI: see what actually converts.",
    buttonText: "Continue",
  },
  {
    content: <ComparisonTable />,
    buttonText: "Get Started",
  }
];

export function OnboardingModal() {
  const { popupVisible, setPopupVisible } = useOutletContext();
  const [currentStep, setCurrentStep] = useState(0);
  const [isHovering, setIsHovering] = useState(false);
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });

  useEffect(() => {
    const hasSeenPopup = localStorage.getItem("hasSeenOnboardingModal");
    if (!hasSeenPopup) {
      setPopupVisible(true);
      localStorage.setItem("hasSeenOnboardingModal", "true");
    }
  }, [popupVisible]);

  const handleNext = () => {
    if (currentStep < steps.length - 1) {
      setCurrentStep(currentStep + 1);
    } else {
      setPopupVisible(false);
    }
  };

  const handleMouseMove = (e: React.MouseEvent) => {
    const rect = e.currentTarget.getBoundingClientRect();
    const x = ((e.clientX - rect.left) / rect.width) * 100;
    const y = ((e.clientY - rect.top) / rect.height) * 100;
    setMousePosition({ x, y });
  };

  return (
    <AnimatePresence>
      {popupVisible && (
        <div
          className={styles.overlay}
          onClick={() => setPopupVisible(false)}
        >
          <motion.div
            onClick={(e) => {
              e.stopPropagation();
              if (currentStep < steps.length - 1) {
                handleNext();
              }
            }}
            onMouseMove={handleMouseMove}
            className={styles.modal}
            style={{
              cursor: currentStep < steps.length - 1 ? 'pointer' : 'default',
              background: `radial-gradient(circle at ${mousePosition.x}% ${mousePosition.y}%, rgb(155, 135, 245) 0%, rgb(132, 167, 255) 100%)`,
            }}
            initial={{ opacity: 0, scale: 0.9 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.5, x: '-100%', y: '-100%' }}
            transition={{ duration: 0.5 }}
          >
            <div className={styles.contentWrapper}>
              <StepIndicator
                totalSteps={steps.length}
                currentStep={currentStep}
                onStepClick={setCurrentStep}
              />

              <div className={styles.textContainer}>
                {steps[currentStep].heading && (
                  <h2 className={styles.heading} dangerouslySetInnerHTML={{ __html: steps[currentStep].heading }}></h2>
                )}
                {steps[currentStep].subtext && (
                  <p className={styles.subtext}>
                    {steps[currentStep].subtext}
                  </p>
                )}
                {steps[currentStep].content}

                {currentStep === 0 && (
                  <div>
                    <div className={styles.flexCenter}>
                      <motion.button
                        onClick={handleNext}
                        className={styles.pulseButton}
                        whileHover={{
                          scale: 1.1,
                          backgroundColor: "rgba(255, 255, 255, 0.2)"
                        }}
                        initial={{
                          backgroundColor: "rgba(255, 255, 255, 0.1)"
                        }}
                        transition={{
                          duration: 0.3
                        }}
                      >
                        <motion.img
                          src="/img/whiteIcon/ChevronRight.svg"
                          alt="arrow-right"
                          animate={{
                            opacity: [1, 0.7, 1]
                          }}
                          transition={{
                            duration: 1.5,
                            repeat: Infinity,
                            ease: "easeInOut"
                          }}
                        />
                        <motion.div
                          className={styles.pingOverlay}
                          initial={{ scale: 0.8, opacity: 0.5 }}
                          animate={{
                            scale: [1, 2],
                            opacity: [0.5, 0]
                          }}
                          transition={{
                            duration: 1,
                            repeat: Infinity,
                            ease: "easeOut"
                          }}
                        />
                      </motion.button>
                    </div>
                  </div>
                )}
              </div>

              {currentStep === steps.length - 1 && (
                <GetStartedButton
                  onClick={(e) => {
                    e.stopPropagation();
                    handleNext();
                  }}
                  isHovering={isHovering}
                  onMouseEnter={() => setIsHovering(true)}
                  onMouseLeave={() => setIsHovering(false)}
                >
                  {steps[currentStep].buttonText}
                </GetStartedButton>
              )}
            </div>
          </motion.div>
        </div>
      )}
    </AnimatePresence>
  );
}