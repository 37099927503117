// redux
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
// types
import { SavePositionState } from "../types/savePositionTypes";
import { IMarker } from "@/widgets/savePosition/libs/types/IMarker";

const initialState: SavePositionState = {
  isActivePosition: false,
  markers: [],
};

const savePositionSlice = createSlice({
  name: "savePosition",
  initialState,
  reducers: {
    toggleSavePosition: (state) => {
      state.isActivePosition = !state.isActivePosition;
    },
    activeSavePosition: (state) => {
      state.isActivePosition = true;
    },
    deactiveSavePosition: (state) => {
      state.isActivePosition = false;
    },
    addMarker: (state, action: PayloadAction<IMarker>) => {
      state.markers.push(action.payload);
    },
    resetMarker: (state) => {
      state.markers = initialState.markers;
    },
  },
});

export const { actions: savePositionActions, reducer: savePositionReducer } =
  savePositionSlice;
