import React, { FC, useState } from "react";
import styles from "./AnimationExplorerCard.module.scss";
import { useNavigate } from 'react-router-dom';
import { useMe, useCurrentOrganization } from "@/Hooks";
import { IoIosCheckmarkCircle } from "react-icons/io";
import { useCreateSavedAnimationMutation, useDeleteSavedAnimationMutation, useGetSavedAnimationsQuery } from "@/features/auth/api/authAPI";
import { toast } from 'react-toastify';
import { handleOpenVideoPage } from "@/app/libs/const/handleOpenVideoPage";

interface AnimationExplorerCardProps {
  name: string;
  shortname: string;
  icon: string;
  video: string;
  time_from: string;
  id: number;
  app_video: number;
  slug: string;
  appid: number;
  avpk: number;
}

const AnimationExplorerCard: FC<AnimationExplorerCardProps> = ({
  name,
  shortname,
  icon,
  video,
  time_from,
  app_video,
  id,
  slug,
  appid,
  avpk,
}) => {
  const navigate = useNavigate();
  const { me } = useMe();
  const currentOrganization = useCurrentOrganization();
  const [createSavedAnimation] = useCreateSavedAnimationMutation();
  const [deleteSavedAnimation] = useDeleteSavedAnimationMutation();
  const [isSaving, setIsSaving] = useState(false);

  const [isPlaying, setisPlaying] = useState(false)

  // Get saved animations for current organization only if we have a valid UUID
  const { data: savedAnimations } = useGetSavedAnimationsQuery(
    { organizationUuid: currentOrganization?.uuid || '' },
    { skip: !currentOrganization?.uuid }
  );

  // Check if this animation is saved
  const savedAnimation = savedAnimations?.results.find(
    saved => saved.app_video_animation.id === id
  );
  const isSaved = !!savedAnimation;

  const handleSaveClick = async (e: React.MouseEvent) => {
    e.stopPropagation();
    if (!me || !currentOrganization) {
      navigate("/register", { state: { fromLogin: true } });
      return;
    }

    setIsSaving(true);
    try {
      if (isSaved && savedAnimation) {
        await deleteSavedAnimation(savedAnimation.id).unwrap();
        toast.success("Animation removed from saved items");
      } else {
        await createSavedAnimation({
          organizationUuid: currentOrganization.uuid,
          data: { app_video_animation: id }
        }).unwrap();
        toast.success("Animation saved successfully");
      }
    } catch (error) {
      toast.error("Failed to save animation");
    } finally {
      setIsSaving(false);
    }
  };

  const redirectToVideoPage = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    handleOpenVideoPage(
      0,
      slug,
      "Home Page screens",
      1,
      appid,
      app_video,
      undefined,
      me?.username || "",
      currentOrganization?.uuid || ""
    );
  };

  const playVideoOnHover = (e: React.MouseEvent<HTMLVideoElement>) => {
    const promise = e.currentTarget.play();
    if (promise !== undefined) {
      promise.then(() => {
        setisPlaying(true)
      })
      .catch(() => {
        setisPlaying(false)
      })
    }
  };

  const pauseVideoOnHover = (e: React.MouseEvent<HTMLVideoElement>) => {
    if (isPlaying) {
      e.currentTarget.pause();
      setisPlaying(false)
    }
  };

  return (
    <div className={styles.card} onClick={redirectToVideoPage}>
      <div className={styles.card__header}>
        <div className={styles.card__header__icon}>
          <img src={icon} alt={name} />
        </div>
        <div className={styles.card__header__title}>
          <h1 className={styles.card__header__title__name}>{name}</h1>
          <p>{shortname}</p>
        </div>
      </div>
      <div className={styles.card__video_container}>
        <div className={styles.card__video}>
          <video
            src={video}
            preload="auto"
            muted
            loop
            onMouseOver={playVideoOnHover}
            onMouseOut={pauseVideoOnHover}
          />
        </div>
        <button
          type="button"
          onClick={handleSaveClick}
          className={styles.bookmark_button}
          disabled={isSaving}
        >
          {isSaved ? (
            <IoIosCheckmarkCircle
              style={{
                color: "green",
                fontSize: "18px",
              }}
            />
          ) : (
            <img
              className={styles.bookmark_icon}
              src="/img/blackIcon/Save.svg"
              alt="Save animation"
              loading="lazy"
            />
          )}
        </button>
      </div>
    </div>
  );
};

export default AnimationExplorerCard;
