import React, { useContext, useEffect, useState } from 'react'
import { useAPI } from '@/Hooks'


const AppCountContext = React.createContext<any>(null)

export const AppCountProvider = ({ children }: { children: React.ReactNode }) => {
    
    const api = useAPI()
    const [appCount, setAppCount] = useState(0)

    useEffect(() => {
        api.get('/v1/apps/stats/')
            .then((res) => {
                setAppCount(res.data.total)
            })
            .catch((err) => {
                console.error(err)
            })
    }, [])

    return (
        <AppCountContext.Provider value={{ appCount }}>
            {children}
        </AppCountContext.Provider>
    )
}

export const useAppCount = () => {
    const context = useContext(AppCountContext)
    if (context === undefined) {
        throw new Error('useAppCount must be used within AppCountProvider')
    }
    return context
}
