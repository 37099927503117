import React, { useState, useContext } from 'react'

const SavedModeContext = React.createContext()

const SavedModeProvider = ({ children }) => {

    const [savedModeActive, setSavedModeActive] = useState(false)

    return (
        <SavedModeContext.Provider value={{ savedModeActive, setSavedModeActive }}>{children}</SavedModeContext.Provider>
    )
}


const useSavedMode = () => {
    const ctx = useContext(SavedModeContext)
    return ctx
}

export { SavedModeProvider, useSavedMode }