// redux
import { useDispatch, useSelector } from "react-redux";
// entities
import { filterActions } from "@/entities/filter";
import { filterAppsDataActions } from "@/entities/filterAppsData";
import { filterAppsActions, getFilterAppsState } from "@/entities/filterApps";
import { counterActions } from "@/entities/counter";
// types
import { ICategoriesData } from "../types/ICategoriesData";

const useHandleClick = (
  appDataCategories: ICategoriesData | undefined,
  onSave: (category: string) => void,
  isFetchingFilter: boolean,
  dataCategories: string[],
  setDataCategories: (categories: string[]) => void,
  selectedCategoryIds: number[],
  setSelectedCategoryIds: (categories: number[]) => void,
) => {
  const dispatch = useDispatch();
  const { activeModalIndexCategories, isActiveCategories, categories } =
    useSelector(getFilterAppsState);

  const handleItemClick = (index: number) => {
    const isCurrentlyActive = activeModalIndexCategories.includes(index);
    const categoryName = appDataCategories!.results[index].name;
    const category = appDataCategories!.results[index];

    if (isCurrentlyActive && !isFetchingFilter) {
      const updatedActiveIndexes = activeModalIndexCategories.filter(
        (i) => i !== index,
      );
      dispatch(
        filterAppsActions.setActiveModalIndexCategories(updatedActiveIndexes),
      );
      const updatedCategoryIds = selectedCategoryIds.filter(
        (id) => id !== category.id,
      );
      setSelectedCategoryIds(updatedCategoryIds);
      dispatch(filterAppsActions.setCategories(updatedCategoryIds.join(",")));
      setDataCategories(dataCategories.filter((item) => item !== categoryName));
      dispatch(filterActions.deactivateCategory());
      if (updatedCategoryIds.length === 0) {
        dispatch(counterActions.removeFromArray("Select Categories"));
      }
      dispatch(filterAppsActions.deactiveCategories());
      onSave("");

      if (categories !== "") {
        dispatch(filterAppsDataActions.resetFilterAppDataSorted());
        dispatch(filterAppsDataActions.resetFilterAppDataSortedAll());
        dispatch(filterAppsActions.resetPageFilterSidebar());
        dispatch(filterAppsActions.resetPageFilter());
      }
    } else if (!dataCategories.includes(categoryName)) {
      const updatedActiveIndexes = [...activeModalIndexCategories, index];
      dispatch(
        filterAppsActions.setActiveModalIndexCategories(updatedActiveIndexes),
      );
      const updatedCategoryIds = [...selectedCategoryIds, category.id];
      setSelectedCategoryIds(updatedCategoryIds);
      dispatch(filterAppsActions.setCategories(updatedCategoryIds.join(",")));
      setDataCategories([...dataCategories, categoryName]);
      if (!isActiveCategories) {
        dispatch(counterActions.addToArray("Select Categories"));
        dispatch(filterAppsActions.activeCategories());
      }
      dispatch(filterActions.activateCategory());
      onSave(categoryName);
      dispatch(filterAppsDataActions.resetFilterAppDataSorted());
      dispatch(filterAppsDataActions.resetFilterAppDataSortedAll());
      dispatch(filterAppsActions.resetPageFilterSidebar());
      dispatch(filterAppsActions.resetPageFilter());
    }
  };

  const handleReset = () => {
    if (activeModalIndexCategories.length > 0 && !isFetchingFilter) {
      dispatch(filterAppsActions.setActiveModalIndexCategories([]));
      dispatch(filterAppsDataActions.resetFilterAppDataSorted());
      dispatch(filterAppsDataActions.resetFilterAppDataSortedAll());
      dispatch(filterAppsActions.resetPageFilterSidebar());
      dispatch(filterAppsDataActions.resetFilterAppData());
      dispatch(filterAppsActions.resetPageFilter());
      dispatch(filterActions.deactivateCategory());
      dispatch(filterAppsActions.setCategories(""));
      setDataCategories([]);
      onSave("");

      if (isActiveCategories) {
        dispatch(counterActions.removeFromArray("Select Categories"));
        dispatch(filterAppsActions.deactiveCategories());
      }
    }
  };

  return { handleItemClick, activeModalIndexCategories, handleReset };
};

export default useHandleClick;
