import {
    FC,
    useEffect,
    useRef,
    useState,
    useCallback,
  } from "react";
  
  import { useOutletContext } from 'react-router-dom'
  import { useDispatch, useSelector } from "react-redux";
  import { getButtonFilter } from "@/entities/buttonFilter";
  import { getIdModalState } from "@/entities/idModal";
  import { getFilterAppsData } from "@/entities/filterAppsData";
  import Spinner from "@/shared/ui/Spinner/Spinner";
  import styles from "./ExplorAppsNew.module.scss";
  import { getFilterState } from "@/entities/filter";
  import { ObserverWrapper } from "@/widgets/observerWrapper/ui/ObserverWrapper/ObserverWrapper";
  import { useCurrentOrganization } from '@/Hooks'
  import { RootState } from "@reduxjs/toolkit/query";
  import { filterAppsActions, getFilterAppsState } from "@/entities/filterApps";
  import { AppResponseData } from "@/features/auth/types/auth";
  import { useInView } from "react-intersection-observer";
  import React from "react";
  import { VariableSizeList as List } from "react-window";
  import InfiniteLoader from "react-window-infinite-loader";
  import AutoSizer from "react-virtualized-auto-sizer";
  import ExploreStoreRow from "./ExploreStoreRow";
  
  interface ExploreAppsProps {
    apiDataFilter: AppResponseData | undefined;
    isFetching: boolean;
  }
  
  interface ExploreAppsContext {
    setTopLeft: React.Dispatch<React.SetStateAction<React.ReactNode>>;
    elementRefs: React.MutableRefObject<(HTMLElement | null)[]>;
    onViewChange: (index: number, inView: boolean) => void;
  }
  
  interface RowProps {
    index: number;
    style: React.CSSProperties;
  }
  
  interface ListRef {
    resetAfterIndex: (index: number) => void;
  }
  
  const ExploreStore: FC<ExploreAppsProps> = ({
    apiDataFilter,
    isFetching,
  }) => {
  
  
    const { setTopLeft, elementRefs, onViewChange } = useOutletContext<ExploreAppsContext>();
    const { only } = useSelector(getFilterAppsState);
    const dispatch = useDispatch();
  
    const useFetchData = (apiDataFilter?: AppResponseData | null, isFetching?: boolean) => {
      const dispatch = useDispatch();
      const [lastFetchedTime, setLastFetchedTime] = useState(Date.now());
      const { ref: refIntersection, inView } = useInView({ threshold: 0 });
  
      useEffect(() => {
        if (inView && apiDataFilter?.next && !isFetching && Date.now() - lastFetchedTime > 500) {
          setLastFetchedTime(Date.now());
          dispatch(filterAppsActions.countPageFilter());
        }
      }, [inView, apiDataFilter?.next, isFetching, lastFetchedTime, dispatch]);
  
      return { refIntersection };
    };
  
    useEffect(() => {
      if (!setTopLeft) return;
      setTopLeft(<></>);
    }, [setTopLeft]);
  
  
    const currentOrganization = useCurrentOrganization()
    const { openModalId } = useSelector(getIdModalState);
    const { filterAppDataSorted } = useSelector(getFilterAppsData);
    const { isActiveMagic } = useSelector(getFilterState);
    const isPaywallOnly = useSelector((state: RootState) => state.filterApps.isPaywallOnly);
    const [lastVisibleElementIndex] = useState<
      number | null
    >(null);
  
    const modalRef = useRef<HTMLDivElement>(null);
    const { refIntersection } = useFetchData(apiDataFilter, isFetching, dispatch);


    //todo: to be renamed as apps
    //const [filterAppDataSorted, setFilterAppDataSorted] = useState([]);

    // useEffect(() => {
    //     if (apiDataFilter) {
    //         setFilterAppDataSorted(prev => [...prev, ...apiDataFilter.results])
    //     }
        
    // }, [apiDataFilter])
  
    useEffect(() => {
      if (lastVisibleElementIndex !== null && elementRefs?.current) {
        const targetElement = elementRefs.current[lastVisibleElementIndex];
        if (targetElement) {
          targetElement.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
        }
      }
    }, [lastVisibleElementIndex, elementRefs]);
  
  
    const [showNoResults, setShowNoResults] = useState(false);
    const [showNoResultsSaved, setShowNoResultsSaved] = useState(false);
    const [showNoResultsHidden, setShowNoResultsHidden] = useState(false);
  
  
    const [currentAppIndex, setCurrentAppIndex] = useState<number | null>(null);
    const [AppDataToShow, setAppDataToShow] = useState<AppResponseData | null>(null);
  
    const handleNextApp = (currentIndex: number) => {
      const nextIndex = currentIndex + 1;
      setCurrentAppIndex(nextIndex);
  
      const appData = filterAppDataSorted[nextIndex];
      setAppDataToShow(appData || null);
    };
  
    const handlePreviousApp = (currentIndex: number) => {
      const previousIndex = currentIndex - 1;
      setCurrentAppIndex(previousIndex);
  
      const appData = filterAppDataSorted[previousIndex];
      setAppDataToShow(appData || null);
    };
  
    useEffect(() => {
      let timer: NodeJS.Timeout;
  
      if (!isFetching && filterAppDataSorted.length === 0) {
        if (only === 'saved') {
          timer = setTimeout(() => {
            setShowNoResultsSaved(true);
            setShowNoResults(false);
            setShowNoResultsHidden(false);
          }, 200);
        }
        else if (only === 'hidden') {
          timer = setTimeout(() => {
            setShowNoResultsHidden(true);
            setShowNoResults(false);
            setShowNoResultsSaved(false);
          }, 200);
        }
        else {
          timer = setTimeout(() => {
            setShowNoResults(true);
            setShowNoResultsSaved(false);
            setShowNoResultsHidden(false);
          }, 200);
        }
      } else {
        setShowNoResults(false);
        setShowNoResultsSaved(false);
        setShowNoResultsHidden(false);
      }
  
      return () => {
        if (timer) clearTimeout(timer);
      };
    }, [isFetching, filterAppDataSorted.length, only]);
  
    const infiniteLoaderRef = useRef(null);
    const listRef = useRef<ListRef | null>(null);
  
    const isItemLoaded = (index: number) => {
      const loaded = index < filterAppDataSorted.length;
      return loaded
    };
  
    const itemCount = Math.max(1, filterAppDataSorted.length + (apiDataFilter?.next ? 1 : 0));
  
    const getItemSize = (index: number) => {
  
      if (index !== 0 && !isItemLoaded(index)) {
        return 240;
      }

      return 600
      //return 750;
    };
  
    const loadMoreItems = useCallback((startIndex: number, stopIndex: number) => {
      if (isFetching || !apiDataFilter?.next) {
        return Promise.resolve();
      }
  
      return new Promise<void>((resolve) => {
        dispatch(filterAppsActions.countPageFilter());
        resolve();
      });
    }, [isFetching, apiDataFilter?.next, dispatch]);
  
    const Column = useCallback(({ index, style }: RowProps) => {
  
      if (filterAppDataSorted.length === 0 && !isFetching) {
        if (only === "saved") {
          return (
            <div style={style} className={styles.noMatches}>
              <img src="/img/Illustrations.jpg" width="250px" height="250px" alt="no results" />
              <h2>No Results Found</h2>
              <p>You haven't saved any apps yet.<br />
              Save apps that you want to see again</p>
            </div>
          );
        } else if (only === "hidden") {
          return (
            <div style={style} className={styles.noMatches}>
              <img src="/img/Illustrations.jpg" width="250px" height="250px" alt="no results" />
              <h2>No Results Found</h2>
              <p>It looks like you haven't hidden any apps yet.<br />
              Try hiding apps that you don't want to see.</p>
            </div>
          );
        } else {
          return (
            <div style={style} className={styles.noMatches}>
              <img src="/img/Illustrations.jpg" width="250px" height="250px" alt="no results" />
              <h2>No Results Found</h2>
              <p>It looks like there are no apps matching your current filters.<br />
              Try adjusting your filters</p>
            </div>
          );
        }
      }
  
      if (!isItemLoaded(index)) {
        return (
          <div style={style} className={styles.spinner}>
            <Spinner />
          </div>
        );
      }
  
      const appData = filterAppDataSorted[index];
      if (!appData) {
        return null;
      }

      return (
        <div style={style} key={`filter-${appData.id}-${index}`}>
          <ObserverWrapper index={index} onViewChange={onViewChange}>
            <ExploreStoreRow
                organization={currentOrganization?.uuid}
                key={appData.id}
                isOpen={openModalId === index}
                dataApi={appData}
                apiDataSavedStoreScreens={{
                count: 0,
                next: null,
                previous: null,
                results: []
                }}
            />
          </ObserverWrapper>
        </div>
      )
    }, [openModalId, filterAppDataSorted, currentOrganization, isPaywallOnly, only, isFetching]);
  
    useEffect(() => {
      if (listRef.current) {
        listRef.current.resetAfterIndex(0);
      }
    }, [filterAppDataSorted]);
  
    return (
      <div className={`${styles.main_container} ${isActiveMagic ? styles.photosComponentsOverlay : ""}`}>
        
          <AutoSizer>
            {({ height, width }: { height: number; width: number }) => (
              <InfiniteLoader
                ref={infiniteLoaderRef}
                isItemLoaded={isItemLoaded}
                itemCount={itemCount}
                loadMoreItems={loadMoreItems}
                threshold={1}
                minimumBatchSize={1}
              >
                 {({ onItemsRendered, ref }: {
                  onItemsRendered: (params: { overscanStartIndex: number; overscanStopIndex: number; visibleStartIndex: number; visibleStopIndex: number }) => void;
                  ref: (list: any) => void;
                }) => (
                  <List
                    height={height}
                    itemCount={itemCount}
                    itemSize={getItemSize}
                    onItemsRendered={onItemsRendered}
                    ref={(list: any) => {
                      ref(list);
                      listRef.current = list;
                    }}
                    width={width}
                  >
                    {Column}
                  </List>
                )}
              </InfiniteLoader>
            )}
          </AutoSizer>
      </div>
    );
  };
  
  export default ExploreStore;
  