// react
import React, { useEffect, useState, useCallback, useRef } from "react";
// animation
//import { useAutoAnimate } from "@formkit/auto-animate/react";
// redux
import { useDispatch } from "react-redux";
// entities
import { AppOnboardingVideo } from "@/features/auth/types/auth";
import { useCreateSavedObScreenMutation, useDeleteSavedObScreenMutation, useGetAppOnboardingVideoScreensQuery, useGetSavedObScreensQuery } from "@/features/auth/api/authAPI";
import Spinner from "@/shared/ui/Spinner/Spinner";
import styles from "./WebOnboardingHomePage.module.scss";
import Modal from "react-modal";
import { useSelector } from "react-redux";
import { getZoomState } from "@/entities/zoom";
import { useInView } from "react-intersection-observer";
import { useCurrentOrganization, useMe } from '@/Hooks'
import { IoIosCheckmarkCircle } from "react-icons/io";
import { toast } from "react-toastify";
import { RightClickModal } from "@/shared/ui/RightClickModal/RightClickModal";
import ContentLoader from "react-content-loader";
import { handleOpenVideoPage } from "@/app/libs/const/handleOpenVideoPage";
import InfoPhoneHomePageHorizontal from "@/shared/ui/InfoPhoneHomePageHorizontal/InfoPhoneHomePageHorizontal";

interface WebOnboardingHomePageProps {
  videoData: AppOnboardingVideo;
  isOpen: boolean;
}

const WebOnboardingHomePage: React.FC<WebOnboardingHomePageProps> = ({
  videoData,
  isOpen
}) => {
  // Move all hooks to the top
  //const [parent] = useAutoAnimate();
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [allScreens, setAllScreens] = useState<any[]>([]);
  const [hasMore, setHasMore] = useState(true);
  const [selectedImage, setSelectedImage] = useState<string | undefined>(undefined);
  const [imageIndex, setImageIndex] = useState<number | null>(null);
  const [currentModalImage, setCurrentModalImage] = useState<any | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const { zoom } = useSelector(getZoomState);
  const spinnerRef = useRef<HTMLDivElement>(null);
  const { ref: refIntersection, inView } = useInView({
    threshold: 0,
  });
  const containerRef = useRef<HTMLDivElement>(null);
  const currentOrg = useCurrentOrganization()
  const { me } = useMe()
  const [savingStates, setSavingStates] = useState<Record<string, boolean>>({});
  const [contextMenu, setContextMenu] = useState<{
    x: number;
    y: number;
    show: boolean;
    imageId: string | null;
    timestamp?: number;
    imageUrl?: string;
  }>({
    x: 0,
    y: 0,
    show: false,
    imageId: null,
  });

  const appInfoData = [
    {
      img: videoData.store_url,
      title: "Web onboarding page:",
      text: "Click me to see",
      onClick: () => window.open(videoData.store_url, "_blank"),
    },
    {
      img: videoData.app.icon,
      title: "Monthly Revenue:",
      text: videoData.app.revenue ? `$${videoData.app.revenue}` : null,
    },
    {
      img: videoData.app.icon,
      title: "Monthly Installs:",
      text: videoData.app.downloads ? `${videoData.app.downloads}` : null,
    },
  ].filter(info => info.text !== null);

  const {
    data: screenData,
    isLoading: isScreensLoading,
    isFetching: isScreensFetching
  } = useGetAppOnboardingVideoScreensQuery({
    app_ob_video: videoData.id,
    page,
    page_size: 10
  }, {
    skip: !hasMore
  });

  const { data: savedObScreens, refetch: refetchSavedObScreens } = useGetSavedObScreensQuery({
    organizationUuid: currentOrg?.uuid || '',
  }, {
    skip: !currentOrg?.uuid,
  });
  const [createSavedObScreen] = useCreateSavedObScreenMutation();
  const [deleteSavedObScreen] = useDeleteSavedObScreenMutation();


  // All useEffect hooks
  useEffect(() => {
    if (screenData?.results) {
      setAllScreens(prev => [...prev, ...screenData.results]);
      setHasMore(!!screenData.next);
    }
  }, [screenData]);

  useEffect(() => {
    return () => {
      setAllScreens([]);
      setPage(1);
    };
  }, [videoData.id]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (selectedImage) {
        if (event.key === "ArrowRight") {
          handleNextImage();
        } else if (event.key === "ArrowLeft") {
          handlePrevImage();
        }
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [selectedImage]);

  useEffect(() => {
    if (isLoading && screenData?.results) {
      setIsLoading(false);
      if (imageIndex !== null) {
        const nextIndex = imageIndex + 1;
        if (nextIndex < allScreens.length) {
          const nextImage = allScreens[nextIndex];
          setSelectedImage(nextImage.screen);
          setImageIndex(nextIndex);
          setCurrentModalImage(nextImage);
        }
      }
    }
  }, [screenData, allScreens, imageIndex, isLoading]);

  // All handler functions
  const loadMore = useCallback(() => {
    if (hasMore && !isScreensFetching) {
      setPage(prev => prev + 1);
    }
  }, [hasMore, isScreensFetching]);

  const handleScroll = useCallback(() => {
    if (!containerRef.current || !hasMore || isScreensFetching) return;

    const container = containerRef.current;
    const scrollPosition = container.scrollTop + container.clientHeight;
    const threshold = container.scrollHeight - 100; // Load more when 100px from bottom

    if (scrollPosition >= threshold) {
      loadMore();
    }
  }, [hasMore, isScreensFetching, loadMore]);

  const closeImageModal = () => {
    setSelectedImage(undefined);
    setImageIndex(null);
    setCurrentModalImage(null);
  };

  const handleNextImage = () => {
    if (imageIndex !== null && allScreens) {
      const nextIndex = imageIndex + 1;

      if (nextIndex >= allScreens.length) {
        if (screenData?.next) {
          setIsLoading(true);
          setPage(prev => prev + 1);
          return;
        } else {
          setImageIndex(0);
          setSelectedImage(allScreens[0].screen);
          setCurrentModalImage(allScreens[0]);
          return;
        }
      }

      const nextImage = allScreens[nextIndex];
      setSelectedImage(nextImage.screen);
      setImageIndex(nextIndex);
      setCurrentModalImage(nextImage);
    }
  };

  const handlePrevImage = () => {
    if (imageIndex !== null && allScreens) {
      const prevIndex = (imageIndex - 1 + allScreens.length) % allScreens.length;
      const prevImage = allScreens[prevIndex];
      setSelectedImage(prevImage.screen);
      setImageIndex(prevIndex);
      setCurrentModalImage(prevImage);
    }
  };

  // Intersection observer for infinite scroll
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const target = entries[0];
        if (target.isIntersecting && hasMore && !isScreensFetching) {
          loadMore();
        }
      },
      {
        root: null,
        rootMargin: '100px',
        threshold: 0
      }
    );

    if (spinnerRef.current) {
      observer.observe(spinnerRef.current);
    }

    return () => {
      if (spinnerRef.current) {
        observer.unobserve(spinnerRef.current);
      }
    };
  }, [loadMore, hasMore, isScreensFetching]);

  // Add scroll listener when zoom > 1
  useEffect(() => {
    const container = containerRef.current;
    if (zoom && zoom > 1 && container) {
      container.addEventListener('scroll', handleScroll);
      return () => {
        container.removeEventListener('scroll', handleScroll);
      };
    }
  }, [zoom, handleScroll]);

  // Load more when element comes into view
  useEffect(() => {
    if (inView) {
      loadMore();
    }
  }, [inView, loadMore]);


  const handleSaveClick = async (screenId: number, ts: string, e: React.MouseEvent) => {
    e.stopPropagation();
    if (!currentOrg?.uuid) return;

    // Set loading state for this specific screen
    setSavingStates(prev => ({ ...prev, [ts]: true }));

    try {
      const savedScreen = savedObScreens?.results.find(
        saved => saved.ts === ts
      );
      const isSaved = !!savedScreen;

      if (isSaved && savedScreen) {
        await deleteSavedObScreen(savedScreen.id).unwrap();
        toast.success("Screen removed from saved items");
      } else {
        await createSavedObScreen({
          organizationUuid: currentOrg.uuid,
          data: {
            app_ob_video: videoData.id,
            ts: ts
          }
        }).unwrap();
        toast.success("Screen saved successfully");
      }
    } catch (error) {
      console.error('Error saving/unsaving screen:', error);
      toast.error("Failed to save screen");
    } finally {
      // Clear loading state for this specific screen
      setSavingStates(prev => ({ ...prev, [ts]: false }));
    }
  };

  const handleVideoClick = (timestamp: number) => {
    handleOpenVideoPage(
      timestamp,
      videoData.app?.slug,
      "Web Onboarding Page screens",
      2,
      videoData.app?.id,
      undefined,
      videoData.id,
      me?.username || "",
      currentOrg?.uuid || ""
    );
  };

  const handleContextMenu = (event: React.MouseEvent, imageId: string, timestamp: number, imageUrl: string) => {
    event.preventDefault();
    event.stopPropagation();
    setContextMenu({
      x: event.clientX,
      y: event.clientY,
      show: true,
      imageId: imageId,
      timestamp: timestamp,
      imageUrl: imageUrl
    });
  };

  const handleCloseContextMenu = useCallback(() => {
    setContextMenu(prev => ({ ...prev, show: false }));
  }, []);

  if (screenData?.count === 0) {
    return null;
  }

  return (
    <div className={styles.root} ref={containerRef}>
      <div className={styles.container} style={{ zoom: zoom }}>
        <div className={styles.appInfo}>
          <div className={styles.appInfoLeft}>
            {videoData.app && (
              <>
                <img
                  src={videoData.app.icon}
                  alt={videoData.app.name}
                  className={styles.appIcon}
                />
                <div className={styles.appDetails}>
                  <h3>{videoData.app.name}</h3>
                  <p>{videoData.app.shortname}</p>
                </div>
              </>
            )}
          </div>
          <div className={styles.appInfoRight}>
            {appInfoData.map((info, index) => (
              <InfoPhoneHomePageHorizontal
                key={index}
                img={info.img}
                title={info.title}
                text={info.text}
                onClick={info.onClick}
              />
            ))}
          </div>
        </div>
        <div
          className={styles.screensGrid}
          style={{ zoom: zoom }}
        >
          {isScreensLoading && allScreens.length === 0 ? (
            <div className={styles.loading}>
              <Spinner />
            </div>
          ) : (
            <>
              {allScreens.map((screen) => {
                const savedScreen = savedObScreens?.results?.find(
                  saved => saved.ts === screen.timestamp
                );
                const isSaved = !!savedScreen;
                const isSaving = savingStates[screen.timestamp] || false;

                return (
                  <div key={screen.id} className={styles.screenItem}
                    onClick={() => handleVideoClick(screen.timestamp)}
                    onContextMenu={(e) => handleContextMenu(e, screen.id.toString(), Number(screen.timestamp), screen.screen)}>
                    <img
                      src={screen.screen}
                      alt={`Screen ${screen.id}`}
                      className={styles.screenImage}
                    />
                    <div
                      className={styles.expand}
                      onClick={(e) => {
                        e.stopPropagation();
                        setSelectedImage(screen.screen);
                        setImageIndex(allScreens.findIndex(s => s.id === screen.id));
                        setCurrentModalImage(screen);
                      }}
                    >
                      <img src="/img/blackIcon/FullScreen.svg" alt="expandModalIcon" />
                    </div>
                    <div className={styles.screenControls}>
                      <button
                        type="button"
                        onClick={(e) => handleSaveClick(screen.id, screen.timestamp, e)}
                        className={styles.bookmark_button}
                        disabled={isSaving}
                      >
                        {isSaved ? (
                          <IoIosCheckmarkCircle
                            style={{
                              color: "green",
                              fontSize: "18px",
                            }}
                          />
                        ) : (
                          <img
                            className={styles.bookmark_icon}
                            src="/img/blackIcon/Save.svg"
                            alt="Save screen"
                            loading="lazy"
                          />
                        )}
                      </button>
                    </div>
                  </div>
                );
              })}
              {(isScreensFetching || hasMore) && (
                <div ref={spinnerRef} className={styles.loadingMore}>
                  <Spinner />
                </div>
              )}
            </>
          )}
        </div>
      </div>
      <Modal
        isOpen={!!selectedImage}
        onRequestClose={closeImageModal}
        contentLabel="Image Modal"
        className={styles.modal}
        overlayClassName={styles.overlay}
      >
        <div className={styles.modalContent}>
          <div className={styles.containerImage} onClick={() => handleVideoClick(currentModalImage?.timestamp || 0)}>
            {isLoading ? (
              <ContentLoader
                speed={2}
                width={400}
                height={800}
                viewBox="0 0 400 800"
                backgroundColor="#f3f3f3"
                foregroundColor="#ecebeb"
              >
                <rect x="0" y="0" rx="2" ry="2" width="400" height="800" />
              </ContentLoader>
            ) : (
              <img

                className={styles.modalContentPhoto}
                src={selectedImage!}
                alt="Enlarged pic"
                loading="lazy"
              />
            )}
          </div>
          <img
            className={styles.arrowRight}
            src="/img/whiteIcon/ChevronRight.svg"
            alt="arrowRightImg"
            onClick={handleNextImage}
            loading="lazy"
            style={{ opacity: imageIndex === allScreens.length - 1 && !screenData?.next ? 0.5 : 1 }}
          />
          <img
            className={styles.arrowLeft}
            src="/img/whiteIcon/ChevronLeft.svg"
            alt="arrowLeftImg"
            onClick={handlePrevImage}
            loading="lazy"
          />
        </div>
      </Modal>
      {contextMenu.show && (
        <RightClickModal
          x={contextMenu.x}
          y={contextMenu.y}
          onClose={handleCloseContextMenu}
          imageUrl={contextMenu.imageUrl || ''}
          imageId={contextMenu.imageId || ''}
          handleOpenInNewTab={(timestamp) => handleVideoClick(timestamp)}
          timestamp={contextMenu.timestamp || 0}
          avsID={videoData.id}
          refetchData={refetchSavedObScreens}
          imageType="webOnboarding"
        />
      )}
    </div>
  );
};

export default WebOnboardingHomePage;
