import { useEffect, useState, useRef } from "react";
import { useOutletContext } from 'react-router-dom'
import HiddenApps from "@/widgets/hiddenApps/ui/HiddenApps/HiddenApps";
import styles from "@/widgets/modalHirStar/ui/ModalHirStar/ModalHirStar.module.scss";

import { useAPI } from '@/Hooks'

const HiddenAppsView = () => {

  const { zoom, sortkey } = useOutletContext()
  const api = useAPI()
  const [hiddenApps, setHiddenApps] = useState()
  const appsZoomRef = useRef<HTMLDivElement>(null);

  const applyZoom = (zoom: number) => {
    const photoCardListElement = appsZoomRef.current;

    if (photoCardListElement) {
      photoCardListElement.style.setProperty("--zoom-level", zoom.toString());
    }
  };
  useEffect(() => {
    applyZoom(zoom);
  }, [zoom]);

  const fetchHiddenApps = () => {
    // todo sort by created_at default
    const params = { order: sortkey }
    api.get('/v1/hidden-apps/', { params: params })
       .then((resp) => setHiddenApps(resp.data.results))
  }

  useEffect(() => {
    fetchHiddenApps()
  }, [sortkey])

  return (
      <div className={styles.appsContainer} ref={appsZoomRef}>
        {hiddenApps && <HiddenApps setHiddenApps={setHiddenApps} appDataHidden={hiddenApps} />}
      </div>
  );
};

export { HiddenAppsView };
