import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { useSavedPointsMutation } from "@/features/auth/api/authAPI";
import {
  IAppVideoData,
  ResponseSavedPointGet,
} from "@/features/auth/types/auth";
import { savePositionActions } from "@/entities/savePosition";
import { getVideoState } from "@/entities/video/model/selectors/getVideoState";
import { videoActions } from "@/entities/video/model/slice/videoSlice";
import { IMarker } from "../types/IMarker";
import { useCurrentOrganization } from '@/Hooks'
import { toast } from "react-toastify";

export const useSavePosition = (
  apiDataVideosOne: IAppVideoData | undefined,
  isLoading: boolean,
  setIsActiveClick: (state: boolean) => void,
  setIsActivePoster: (state: boolean) => void,
  apiDataPointsByVideo: ResponseSavedPointGet | undefined,
  styleProps: string,
) => {
  const { currentPlayTime } = useSelector(getVideoState);

  const dispatch = useDispatch();
  const accessToken = localStorage.getItem("access_token");
  const [savedPoint] = useSavedPointsMutation();
  const currenOrganization = useCurrentOrganization()

  const saveMarkerWithoutFilter = async (newMarker: IMarker) => {
    try {
      await savedPoint({
        organizationUuid: currenOrganization?.uuid,
        app_video: newMarker.appVideo!,
        ts: newMarker.timestamp,
      }).unwrap();
    } catch (error) {
      if (axios.isAxiosError(error)) {
        toast.error(error.response?.data.error);
      }
    }
    setIsActiveClick(true);
    setTimeout(() => {
      setIsActiveClick(false);
      dispatch(videoActions.setPlaying(true));
    }, 1500);
    const timer = setTimeout(() => {
      dispatch(savePositionActions.deactiveSavePosition());
    }, 1500);
    return () => clearTimeout(timer);
  };

  const handleClick = async (
    timestamp?: number,
    selectedTimestamp?: string,
  ) => {
    if (accessToken) {
      if (styleProps === "white") {
        dispatch(videoActions.setPlaying(false));
        dispatch(savePositionActions.activeSavePosition());
        setIsActivePoster(false);

        const appId = apiDataVideosOne?.id;
        const newMarker = {
          timestamp: selectedTimestamp!.toString(),
          appVideo: appId,
        };

        await saveMarkerWithoutFilter(newMarker);
        toast.success("Point saved successfully!");
      } else {
        dispatch(videoActions.setPlaying(false));
        dispatch(savePositionActions.activeSavePosition());
        setIsActivePoster(false);

        const appId = apiDataVideosOne?.id;
        const newMarker = {
          timestamp: timestamp!.toFixed(2).toString(),
          appVideo: appId,
        };

        await saveMarkerWithoutFilter(newMarker);
        toast.success("Point saved successfully!");
      }
    } else {
      localStorage.removeItem("access_token");
      window.location.href = "/login";
      window.scrollTo(0, 0);
    }
  };

  return { handleClick, isLoading, currentPlayTime };
};
