import React, { useEffect } from 'react'
import { Navigate, Outlet, useOutletContext } from 'react-router-dom'

import {
    useSavedMode
} from '@/Hooks'
import { useMe } from '@/Hooks/useMe'
import { ACTIVE_VIEW_SAVED_MODE } from '@/Views/constants'

const SavedModeRootView = () => {

    const { setSavedModeActive } = useSavedMode()
    const { me, loading } = useMe()
    const { setRootActiveView, setSearchBarPlaceHolder } = useOutletContext()
    useEffect(() => {
        setRootActiveView(ACTIVE_VIEW_SAVED_MODE)
        setSavedModeActive(true)
        return () => {
            setSavedModeActive(false)
        }
    }, [])

    if (loading) {
        return null 
    }
    if (!me) {
        return <Navigate to="/" replace />
    }

    return (
        <Outlet />
    )
}

export { SavedModeRootView }