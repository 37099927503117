import React, { useState } from 'react';
import styles from "./CreateNewteam.module.scss";
import { useCreateOrganizationMutation } from '../../../features/auth/api/authAPI';
import CustomSlider from '@/widgets/customSlider/ui/CustomSlider/CustomSlider';

import {
  useGetPlansQuery
} from "@/features/auth/api/authAPI";

interface CreateNewTeamProps {
  isOpen: boolean;
  onClose: () => void;
}

const CreateNewTeam: React.FC<CreateNewTeamProps> = ({ isOpen, onClose }) => {
  const [createOrganization, { isLoading, isError, error }] = useCreateOrganizationMutation();
  const [teamName, setTeamName] = useState('');
  const [sliderValue, setSliderValue] = useState(1);
  const { data: apiDataPlans } = useGetPlansQuery();

  if (!isOpen) return null;

  const handleSliderChange = (value: number) => {
    setSliderValue(value);
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    try {
      const result = await createOrganization({
        name: teamName,
        price_id: apiDataPlans[0].id,
        qte: sliderValue,
        success_url: import.meta.env.VITE_SD_CHECKOUT_SUCCESS_URL
      }).unwrap();

      if (result.redirect_url) {
        window.location.href = result.redirect_url;
      }
    } catch (err) {
      console.error('Failed to create organization:', err);
    }
  };

  const price3month = apiDataPlans?.[0].unit_amount / 100;

  return (
    <div className={styles.modalOverlay}>
      <div className={styles.modalContent}>
        <div className={styles.closeButton}>
          <button onClick={onClose}>
            <img src="/img/blackIcon/Close.svg" alt="Close" />
          </button>
        </div>
        <div className={styles.container}>
          <h1 className={styles.title}>Create a New Team</h1>
          <p className={styles.description}>
            Creating a team will start a new subscription. You will be charged ${sliderValue == 1 ? price3month : sliderValue * price3month} today. Your new subscription will renew on
            {' '}{new Date(Date.now() + 90 * 24 * 60 * 60 * 1000).toLocaleDateString('en-US', {
              month: 'long',
              day: 'numeric',
              year: 'numeric'
            })}
          </p>
          <div className={styles.infoBox}>
            <div className={styles.planTeamsSliderContainer}>
              <div className={styles.planTeamsSlider}>
                <h1 className={styles.teamSizeText}>Team Size: {sliderValue}</h1>

                <div className={styles.sliderContainer}>
                  <CustomSlider
                    min={1}
                    max={20}
                    step={1}
                    value={sliderValue}
                    onChange={handleSliderChange}
                  />
                </div>
              </div>
              <ul className={styles.infoList}>
                <li>By setting up a team, your billing will move to your team account, which will need a payment method.</li>
                <li>As a team owner, you'll retain your Pro benefits on your own account.</li>
              </ul>
            </div>
          </div>
          <form onSubmit={handleSubmit}>
            <div className={styles.inputGroup}>
              <label className={styles.label}>Team Name</label>
              <input
                type="text"
                className={styles.input}
                placeholder="Sparks Team"
                value={teamName}
                onChange={(e) => setTeamName(e.target.value)}
                required
              />
              <p className={styles.inputHint}>You can change this at any time.</p>
            </div>
            <div className={styles.buttonGroup}>
              <button type="button" className={styles.cancelButton} onClick={onClose}>Cancel</button>
              <button type="submit" className={styles.continueButton} disabled={isLoading}>
                {isLoading ? 'Creating...' : 'Continue'}
              </button>
            </div>
          </form>
          {isError && <p className={styles.errorMessage}>Error: {error.toString()}</p>}
        </div>
      </div>
    </div>
  );
};

export default CreateNewTeam;
