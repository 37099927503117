// redux
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
// features
import { VideosScreensResponseData } from "@/features/auth/types/auth";
// types
import { PhotosState } from "../types/photosDataTypes";

const initialState: PhotosState = {
  apiDataPhotos: {
    count: 0,
    next: "",
    previous: "",
    results: [],
  },
};

const photosDataSlice = createSlice({
  name: "photosData",
  initialState,
  reducers: {
    setPhotos: (state, action: PayloadAction<VideosScreensResponseData>) => {
      state.apiDataPhotos = action.payload;
    },
  },
});

export const { actions: photosDataActions, reducer: photosDataReducer } =
  photosDataSlice;
