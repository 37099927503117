import React from "react";
import { useDispatch } from "react-redux";
import { filterActions } from "@/entities/filter";
import styles from "./MagicModeButton.module.scss";

import { useAppCount } from "@/Hooks";

interface MagicModeButtonProps {
  showLottie: boolean;
  onButtonClick?: () => void;
}

const MagicModeButton: React.FC<MagicModeButtonProps> = ({ showLottie, onButtonClick }) => {
  const dispatch = useDispatch();
  const { appCount } = useAppCount()

  const handleClick = () => {
    dispatch(filterActions.activateFilterMagic());
    onButtonClick();
  };

  return (
    <div className={styles.root}>
      <button
        style={{ position: "relative" }}
        className={styles.ocrModeContainer}
        onClick={handleClick}
        disabled={showLottie}
      >
        <div className={styles.ocrMode}>
          <div className={styles.ocrModeText}>
            <div className={styles.ocrModeTextIcon}>
            <img
              src="/img/blackIcon/SmartSearch.svg"
              alt="searchImg"
              loading="lazy"
              draggable={false}
            />
            </div>
            <h3 className={styles.magicTextDefault}>Search among {appCount} apps, UI elements and screen types</h3>
            <h3 className={styles.magicTextMobile}>it's magic</h3>
          </div>
        </div>
      </button>
    </div>
  );
};

export default MagicModeButton;
