// react-router-dom
import { useNavigate } from "react-router-dom";
// redux
import { useDispatch } from "react-redux";
// entities
import { userActions } from "@/entities/user";
// styles
import styles from "./LogoHeader.module.scss";

const LogoHeader = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleClickLogo = () => {
    dispatch(userActions.setWithoutLoading(false));
    navigate("/", { state: { fromLogin: true } });
    window.scrollTo(0, 0);
    window.location.reload();
  };

  return (
    <div className={styles.root}>
      <button onClick={handleClickLogo}>
        <img src="/img/headerLogoMain.svg" alt="headerAuthImg" loading="lazy" />
      </button>
    </div>
  );
};

export default LogoHeader;
