// redux
import { useDispatch, useSelector } from "react-redux";
// enitites
import { sortByCategoriesActions } from "@/entities/sortByCategories";
import { getSortByCategoriesSlice } from "@/entities/sortByCategories/model/selectors/getSortByCategoriesState";
import { filterAppsDataActions } from "@/entities/filterAppsData";
import { filterAppsActions } from "@/entities/filterApps";
import { counterActions } from "@/entities/counter";
// data
import { dataItem } from "../data/dataItem";

const useHandleClick = () => {
  const dispatch = useDispatch();
  const { indexGroup, isActiveGroup } = useSelector(getSortByCategoriesSlice);

  const handleClick = (index: number, text: string, filter: string) => {
    if (indexGroup === index) {
      return;
    }

    const previousSortText = dataItem[indexGroup]?.text || "None";

    if (isActiveGroup) {
      dispatch(counterActions.removeFromArray(`Group By: ${previousSortText}`));
    }

    if (text !== "None") {
      dispatch(counterActions.addToArray(`Group By: ${text}`));
      dispatch(sortByCategoriesActions.setIsActiveGroup(true));
    } else {
      dispatch(sortByCategoriesActions.setIsActiveGroup(false));
    }

    dispatch(sortByCategoriesActions.setIndexGroup(index));
    dispatch(sortByCategoriesActions.setGroup(text));
    dispatch(sortByCategoriesActions.closeDeveloper());

    dispatch(filterAppsDataActions.resetFilterAppDataSortedAll());
    dispatch(filterAppsDataActions.resetFilterAppDataSorted());
    dispatch(filterAppsDataActions.resetFilterAppData());
    dispatch(filterAppsActions.resetPageFilterSidebar());
    dispatch(filterAppsActions.resetPageFilter());
    dispatch(filterAppsActions.setFilterGroup(filter));
  };

  return { handleClick };
};

export default useHandleClick;
