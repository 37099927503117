import React from "react";
import styles from "./SortHidStarModalItem.module.scss";

interface SortHidStarModalItemProps {
  name: string;
  isActive: boolean;
  onClick: () => void;
}

const SortHidStarModalItem: React.FC<SortHidStarModalItemProps> = ({
  name,
  isActive,
  onClick,
}) => {
  return (
    <div className={styles.root}>
      <div
        onClick={onClick}
        className={`${styles.root} ${isActive ? styles.itemActive : ""}`}
      >
        <span>{name}</span>
      </div>
    </div>
  );
};

export default SortHidStarModalItem;
