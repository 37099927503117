import React from "react";
import { AppResponseData } from "@/features/auth/types/auth";
import styles from "./AdditionalInfoModal.module.scss";

interface AdditionalInfoModalProps {
  data: AppResponseData;
  onClick: (idApp: number) => void;
  onMouseEnter: () => void;
  onMouseLeave: () => void;
}

const AdditionalInfoModal: React.FC<AdditionalInfoModalProps> = ({
  data,
  onClick,
  onMouseEnter,
  onMouseLeave,
}) => {
  const additionalInfoModalRef = React.useRef<HTMLDivElement>(null);


  return (
    <div
      className={styles.root}
      ref={additionalInfoModalRef}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {data?.results.map((item) => {
        return (
          <div
            onClick={() => onClick(item.id)}
            className={styles.rootItem}
            key={item.id}
          >
            <img src={item.icon} alt="iconImg" loading="lazy" />
            <p>{item.name}</p>
          </div>
        );
      })}
    </div>
  );
};

export default AdditionalInfoModal;
