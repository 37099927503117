// redux
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isOpen: false,
};

const inputSlice = createSlice({
  name: "input",
  initialState,
  reducers: {
    toggleInput: (state) => {
      state.isOpen = !state.isOpen;
    },
    showInput: (state) => {
      state.isOpen = true;
    },
    hideInput: (state) => {
      state.isOpen = false;
    },
  },
});

export const { actions: inputActions, reducer: inputReducer } = inputSlice;
