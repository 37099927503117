import React from "react";
import { format, subMonths, subYears } from "date-fns";
import { useDispatch, useSelector } from "react-redux";
import { filterAppsActions, getFilterAppsState } from "@/entities/filterApps";
import { counterActions } from "@/entities/counter";
import Calendar from "@/widgets/calendar/ui/Calendar/Calendar";
import styles from "./UpdateDateModal.module.scss";
import "./calendar.scss";
import { filterAppsDataActions } from "@/entities/filterAppsData";
import { handleFocus } from "@/widgets/inputSearch/libs/const/handleFocus";
import StoredCount from "@/shared/ui/storedcount/StoredCound";

import { ACTIVE_VIEW_HOME } from "@/Views/constants";

export interface UpdateDateModalProps {
  selectedYearMinUpdate: number | null;
  setSelectedYearMinUpdate: (state: number | null) => void;
  selectedYearMaxUpdate: number | null;
  setSelectedYearMaxUpdate: (state: number | null) => void;
  monthMin: Date;
  setMonthMin: (state: Date) => void;
  monthMax: Date;
  setMonthMax: (state: Date) => void;
  rootActiveView: string;
}

const UpdateDateModal: React.FC<UpdateDateModalProps> = ({
  selectedYearMinUpdate,
  setSelectedYearMinUpdate,
  selectedYearMaxUpdate,
  setSelectedYearMaxUpdate,
  monthMin,
  setMonthMin,
  monthMax,
  setMonthMax,
  rootActiveView,
}) => {
  const dispatch = useDispatch();
  const {
    activeButtonUpdateDate,
    isActiveCalendarUpdate,
    selectedDataRangeMax,
    selectedDataRangeMin,
    isActiveCalendarRangeMin,
    isActiveCalendarRangeMax,
  } = useSelector(getFilterAppsState);
  const currentYear = new Date().getFullYear();
  const [yearCalendarMin, setYearCalendarMin] = React.useState(false);
  const [yearCalendarMax, setYearCalendarMax] = React.useState(false);
  const [isResetVisible, setIsResetVisible] = React.useState(false);
  const [windowWidth, setWindowWidth] = React.useState(window.innerWidth);

  React.useEffect(() => {
    setIsResetVisible(
      selectedDataRangeMin !== "Min" ||
      selectedDataRangeMax !== "Max" ||
      activeButtonUpdateDate !== null,
    );
  }, [selectedDataRangeMin, selectedDataRangeMax, activeButtonUpdateDate]);

  React.useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handlePreviousMonthMin = () => {
    const newMonth = new Date(monthMin);
    newMonth.setMonth(monthMin.getMonth() - 1);
    setMonthMin(newMonth);
  };

  const handleNextMonthMin = () => {
    const newMonth = new Date(monthMin);
    newMonth.setMonth(monthMin.getMonth() + 1);
    setMonthMin(newMonth);
  };

  const handlePreviousMonthMax = () => {
    const newMonth = new Date(monthMax);
    newMonth.setMonth(monthMax.getMonth() - 1);
    setMonthMax(newMonth);
  };

  const handleNextMonthMax = () => {
    const newMonth = new Date(monthMax);
    newMonth.setMonth(monthMax.getMonth() + 1);
    setMonthMax(newMonth);
  };

  const handleClick = (label: string) => {
    if (activeButtonUpdateDate === label) {
      dispatch(filterAppsActions.setSelectedDataRangeMin("Min"));
      dispatch(filterAppsActions.setSelectedDataRangeMax("Max"));
      dispatch(filterAppsActions.setUpdatedFrom(""));
      dispatch(filterAppsActions.setUpdatedTo(""));
      dispatch(filterAppsDataActions.resetFilterAppDataSorted());
      dispatch(filterAppsDataActions.resetFilterAppDataSortedAll());
      dispatch(filterAppsDataActions.resetFilterAppData());
      dispatch(filterAppsActions.resetPageFilter());
      dispatch(filterAppsActions.resetPageFilterSidebar());
      dispatch(filterAppsActions.setActiveButtonUpdateDate(null));

      if (isActiveCalendarUpdate) {
        dispatch(counterActions.removeFromArray("Last Update Date"));
        dispatch(filterAppsActions.deactiveUpdateDate());
      }

      return;
    }

    const currentDate = new Date();
    let minDate;
    let maxDate;

    switch (label) {
      case "Last 3 months":
        minDate = subMonths(currentDate, 3);
        maxDate = currentDate;
        break;
      case "Last 1 year":
        minDate = subYears(currentDate, 1);
        maxDate = currentDate;
        break;
      case "Last 2 years":
        minDate = subYears(currentDate, 2);
        maxDate = currentDate;
        break;
      default:
        return;
    }

    const formattedDateMin =
      minDate instanceof Date ? format(minDate, "yyyy-MM-dd") : minDate;
    const formattedDateMax =
      maxDate instanceof Date ? format(maxDate, "yyyy-MM-dd") : maxDate;

    dispatch(filterAppsActions.setSelectedDataRangeMin(minDate));
    dispatch(filterAppsActions.setSelectedDataRangeMax(maxDate));
    dispatch(filterAppsActions.setUpdatedFrom(formattedDateMin));
    dispatch(filterAppsActions.setUpdatedTo(formattedDateMax));
    dispatch(filterAppsDataActions.resetFilterAppDataSorted());
    dispatch(filterAppsDataActions.resetFilterAppDataSortedAll());
    dispatch(filterAppsDataActions.resetFilterAppData());
    dispatch(filterAppsActions.resetPageFilter());
    dispatch(filterAppsActions.resetPageFilterSidebar());
    dispatch(filterAppsActions.setActiveButtonUpdateDate(label));
    setMonthMin(minDate);
    setMonthMax(maxDate);

    if (!isActiveCalendarUpdate) {
      dispatch(counterActions.addToArray("Last Update Date"));
      dispatch(filterAppsActions.activeUpdateDate());
    }
  };

  const handleReset = () => {
    if (selectedDataRangeMin !== "Min" || selectedDataRangeMax !== "Max") {
      const currentDate = new Date();
      dispatch(filterAppsActions.setSelectedDataRangeMin("Min"));
      dispatch(filterAppsActions.setSelectedDataRangeMax("Max"));
      dispatch(filterAppsActions.setUpdatedFrom(""));
      dispatch(filterAppsActions.setUpdatedTo(""));
      dispatch(filterAppsDataActions.resetFilterAppDataSorted());
      dispatch(filterAppsDataActions.resetFilterAppDataSortedAll());
      dispatch(filterAppsDataActions.resetFilterAppData());
      dispatch(filterAppsActions.resetPageFilter());
      dispatch(filterAppsActions.resetPageFilterSidebar());
      dispatch(filterAppsActions.setActiveButtonUpdateDate(null));
      setSelectedYearMinUpdate(null);
      setSelectedYearMaxUpdate(null);
      setMonthMin(currentDate);
      setMonthMax(currentDate);

      if (isActiveCalendarUpdate) {
        dispatch(counterActions.removeFromArray("Last Update Date"));
        dispatch(filterAppsActions.deactiveUpdateDate());
      }
    }
  };

  const handleClickInputMin = () => {
    if (isActiveCalendarRangeMax) {
      dispatch(filterAppsActions.setActiveCalendarRangeMax(false));
    }

    dispatch(filterAppsActions.toggleCalendarRangeMin());
    setYearCalendarMax(false);
  };

  const handleClickInputMax = () => {
    if (isActiveCalendarRangeMin) {
      dispatch(filterAppsActions.setActiveCalendarRangeMin(false));
    }

    dispatch(filterAppsActions.toggleCalendarRangeMax());
    setYearCalendarMin(false);
  };

  const handleSelectedMin = (date: Date | string) => {
    if (date instanceof Date) {
      const formattedDate = format(date, "yyyy-MM-dd");
      if (
        selectedDataRangeMax === "Max" ||
        date <= new Date(selectedDataRangeMax)
      ) {
        dispatch(filterAppsActions.setSelectedDataRangeMin(date));
        dispatch(filterAppsActions.setUpdatedFrom(formattedDate));
      }
    } else {
      dispatch(filterAppsActions.setSelectedDataRangeMin("Min"));
      dispatch(filterAppsActions.setUpdatedFrom(""));
    }
    dispatch(filterAppsActions.setActiveCalendarRangeMin(false));

    // Деактивируем кнопку "Last 3 months" при выборе пользовательских дат
    if (activeButtonUpdateDate !== null) {
      dispatch(filterAppsActions.setActiveButtonUpdateDate(null));
    }

    dispatch(filterAppsDataActions.resetFilterAppDataSorted());
    dispatch(filterAppsDataActions.resetFilterAppDataSortedAll());
    dispatch(filterAppsDataActions.resetFilterAppData());
    dispatch(filterAppsActions.resetPageFilter());
    dispatch(filterAppsActions.resetPageFilterSidebar());
  };

  const handleSelectedMax = (date: Date | string) => {
    if (date instanceof Date) {
      const formattedDate = format(date, "yyyy-MM-dd");
      if (
        selectedDataRangeMin === "Min" ||
        date >= new Date(selectedDataRangeMin)
      ) {
        dispatch(filterAppsActions.setSelectedDataRangeMax(date));
        dispatch(filterAppsActions.setUpdatedTo(formattedDate));
      }
    } else {
      dispatch(filterAppsActions.setSelectedDataRangeMax("Max"));
      dispatch(filterAppsActions.setUpdatedTo(""));
    }
    dispatch(filterAppsActions.setActiveCalendarRangeMax(false));

    // Деактивируем кнопку "Last 3 months" при выборе пользовательских дат
    if (activeButtonUpdateDate !== null) {
      dispatch(filterAppsActions.setActiveButtonUpdateDate(null));
    }

    dispatch(filterAppsDataActions.resetFilterAppDataSorted());
    dispatch(filterAppsDataActions.resetFilterAppDataSortedAll());
    dispatch(filterAppsDataActions.resetFilterAppData());
    dispatch(filterAppsActions.resetPageFilter());
    dispatch(filterAppsActions.resetPageFilterSidebar());
  };

  const formatNumber = (selectedData: string | Date): string => {
    if (selectedData === "Max" || !selectedData) return "Max";
    if (selectedData === "Min" || !selectedData) return "Min";

    const date =
      selectedData instanceof Date ? selectedData : new Date(selectedData);
    if (Number.isNaN(date.getTime())) {
      return typeof selectedData === "string"
        ? `1 Jan ${selectedData}`
        : "Invalid Date";
    }

    return windowWidth < 550
      ? format(date, "d MMM")
      : format(date, "d MMM yyyy");
  };

  const isDateDisabled = (date: Date) => {
    if (isActiveCalendarRangeMin && selectedDataRangeMax !== "Max") {
      return date > new Date(selectedDataRangeMax);
    }
    if (isActiveCalendarRangeMax && selectedDataRangeMin !== "Min") {
      return date < new Date(selectedDataRangeMin);
    }
    return false;
  };

  return (
    <div className={styles.root}>
      <div className={styles.menuButtons}>
        <div
          onClick={() => handleClick("Last 3 months")}
          className={
            activeButtonUpdateDate === "Last 3 months"
              ? styles.buttonItemActive
              : styles.buttonItem
          }
        >
          <button type="button">Last 3 months</button>
        </div>
        <div
          onClick={() => handleClick("Last 1 year")}
          className={
            activeButtonUpdateDate === "Last 1 year"
              ? styles.buttonItemActive
              : styles.buttonItem
          }
        >
          <button type="button">Last 1 year</button>
        </div>
        <div
          onClick={() => handleClick("Last 2 years")}
          className={
            activeButtonUpdateDate === "Last 2 years"
              ? styles.buttonItemActive
              : styles.buttonItem
          }
        >
          <button type="button">Last 2 years</button>
        </div>
      </div>
      <div className={styles.customMenuButton}>
        <div onClick={handleClickInputMin} className={styles.min}>
          <input
            type="text"
            value={formatNumber(selectedDataRangeMin)}
            maxLength={8}
            readOnly
          />
          <img
            className={styles.arrowReleaseDate}
            src="/img/blackIcon/DropDown.svg"
            alt="arrowReleaseDateImg"

            loading="lazy"
          />
        </div>
        <span className={styles.textTo}>to</span>
        <div onClick={handleClickInputMax} className={styles.max}>
          <input
            type="text"
            value={formatNumber(selectedDataRangeMax)}
            readOnly
            maxLength={8}
          />
          <img
            className={styles.arrowReleaseDate}
            src="/img/blackIcon/DropDown.svg"
            alt="arrowReleaseDateImg"

            loading="lazy"
          />
        </div>
      </div>
      <div className={styles.flex}>
        {rootActiveView === ACTIVE_VIEW_HOME && (
          <StoredCount onFocus={() => handleFocus(dispatch)} />
        )}
        {isResetVisible && (
          <div onClick={handleReset} className={styles.reset}>
            <img
              src="/img/blackIcon/Close.svg"
              alt="resetImg"
              loading="lazy"
            />
          </div>
        )}
      </div>
      {isActiveCalendarRangeMin && (
        <Calendar
          setSelectedDate={(date: Date | undefined) => handleSelectedMin(date!)}
          month={monthMin}
          setMonth={setMonthMin}
          handlePreviousMonth={handlePreviousMonthMin}
          handleNextMonth={handleNextMonthMin}
          yearCalendarMin={yearCalendarMin}
          setYearCalendarMin={setYearCalendarMin}
          yearCalendarMax={yearCalendarMax}
          setYearCalendarMax={setYearCalendarMax}
          isActiveCalendarMin={isActiveCalendarRangeMin}
          isActiveCalendarMax={isActiveCalendarRangeMax}
          selectedYearMinUpdate={selectedYearMinUpdate!}
          selectedDataRangeMax={selectedDataRangeMax}
          setMonthMin={setMonthMin}
          currentYear={currentYear}
          isDateDisabled={isDateDisabled}
        />
      )}
      {isActiveCalendarRangeMax && (
        <Calendar
          setSelectedDate={(date: Date | undefined) => handleSelectedMax(date!)}
          month={monthMax}
          setMonth={setMonthMax}
          handlePreviousMonth={handlePreviousMonthMax}
          handleNextMonth={handleNextMonthMax}
          yearCalendarMin={yearCalendarMin}
          setYearCalendarMin={setYearCalendarMin}
          yearCalendarMax={yearCalendarMax}
          setYearCalendarMax={setYearCalendarMax}
          isActiveCalendarMin={isActiveCalendarRangeMin}
          isActiveCalendarMax={isActiveCalendarRangeMax}
          selectedYearMaxUpdate={selectedYearMaxUpdate!}
          selectedDataRangeMin={selectedDataRangeMin}
          currentYear={currentYear}
          setMonthMax={setMonthMax}
          isDateDisabled={isDateDisabled}
        />
      )}
    </div>
  );
};

export default UpdateDateModal;
