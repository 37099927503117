import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { userActions } from "@/entities/user";
import { useLogout } from "../../libs/hook/useLogout";
import styles from "./ModalMenu.module.scss";
import { modalMenuActions } from "@/entities/modalMenu";
import { useMe, useCurrentOrganization } from "@/Hooks";
import mixpanel from "@/shared/lib/mixpanel";
import Spinner from "@/shared/ui/Spinner/Spinner";
import { Organization } from "@/features/auth/types/auth";
import { getGradientForLetter } from "@/Hooks/useGradient";
import SuggestAppModal from "@/widgets/suggestAppModal/SuggestAppModal";
import Modal from "react-modal";

interface ModalMenuProps {
  onClose: () => void;
  handleToggleList: () => void;
  handleSelectOrganization: (org: Organization) => void;
  displayImage: string | JSX.Element;
  isOrganizationListOpen: boolean;
  isLoadingOrganizations: boolean;
  organizationsList: Organization[];
}

const ModalMenu = React.forwardRef<HTMLDivElement, ModalMenuProps>(
  (
    {
      onClose,
      displayImage,
      handleToggleList,
      isOrganizationListOpen,
      isLoadingOrganizations,
      organizationsList,
      handleSelectOrganization,
    },
    ref,
  ) => {
    const { me } = useMe();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { handleLogout, isLoggedOut } = useLogout();
    const accessToken = localStorage.getItem("access_token");
    const currentOrganization = useCurrentOrganization();
    const [isSuggestAppModalOpen, setIsSuggestAppModalOpen] =
      useState<boolean>(false);
    const handleClickApps = () => {
      navigate("saved-library/starred/");
      onClose();
    };

    const handleClickSavedLibrary = () => {
      navigate("saved-library/");
      onClose();
    };

    const handleClickBilling = () => {
      navigate("/settings/account");
      onClose();
    };

    const handleClickFreeTools = () => {
      navigate("/free-tools");
      mixpanel.track("Free tools clicked", {
        UserEmail: me?.email || "",
        OrganizationID: currentOrganization?.uuid || "",
      });
      onClose();
    };

    const handleClickRegister = () => {
      dispatch(userActions.setWithoutLoading(true));
      navigate("/register", { state: { withoutLoading: true } });
      onClose();
    };

    const organizationsWithGradients = organizationsList?.map((org) => ({
      ...org,
      gradient: getGradientForLetter(org.name.charAt(0)),
    }));

    return (
      <>
        <div
          className={styles.overlay}
          onClick={() => dispatch(modalMenuActions.hideModal())}
        />
        <div
          className={`${styles.ModalMenu} ModalMenu`}
          data-has-token={!!accessToken}
        >
          <div className={styles.header}>
            {me && (
              <div
                className={styles.header_organization_info}
                onClick={handleToggleList}
              >
                {displayImage}
              </div>
            )}
            {me && <div className={styles.line2} />}

            <div className={styles.list}>
              <ul>
                {me && (
                  <>
                    <button
                      className={styles.savedMode2}
                      onClick={() => {
                        navigate("/saved-library");
                        onClose();
                      }}
                    >
                      <img src="/img/blackIcon/Save.svg" alt="Saved Library" />
                      Saved Mode
                    </button>
                    <li
                      onClick={
                        accessToken ? handleClickBilling : handleClickRegister
                      }
                    >
                      <img
                        src="/img/blackIcon/AccountBilling.svg"
                        alt="Account area and billing"
                      />
                      <span>Account area and billing</span>
                    </li>
                    <li
                      onClick={
                        accessToken ? handleClickApps : handleClickRegister
                      }
                    >
                      <img
                        src="/img/blackIcon/Star.svg"
                        alt="Starred Apps Icon"
                      />
                      <span>Hidden & Favorite Apps</span>
                    </li>
                    <li
                      onClick={
                        accessToken ? handleClickSavedLibrary : handleClickRegister
                      }
                    >
                      <img
                        src="/img/blackIcon/Save.svg"
                        alt="Saved Library"
                      />
                      <span>Saved Library</span>
                    </li>
                  </>
                )}

                {!me && (
                  <li
                    onClick={() => {
                      navigate("/login");
                      onClose();
                    }}
                  >
                    <img src="/img/blackIcon/Logout.svg" alt="Login Icon" />
                    <span>Login</span>
                  </li>
                )}

                <li onClick={handleClickFreeTools}>
                  <img
                    src="/img/blackIcon/MagicWand.svg"
                    alt="Starred Apps Icon"
                  />
                  <span>Free tools</span>
                </li>
                {/* <li onClick={() => {
                navigate("/");
                onClose();
              }}>
                <img src="/img/blogIcon.svg" alt="blog" />
                <span>Blog</span>
              </li> */}
                <li
                  onClick={() => {
                    navigate("/about-us");
                    onClose();
                  }}
                >
                  <img src="/img/blackIcon/AboutUs.svg" alt="About Us Icon" />
                  <span>About Us</span>
                </li>
                {/* <li>
                <Link to="/about-us">
                  <img src="/img/blackIcon/AboutUs.svg" alt="About Us Icon" />
                  <span>About Us2</span>
                </Link>
              </li> */}
                {/* <li onClick={() => {
                navigate("mailto:support@screensdesign.com");
                onClose();
              }}>
                <img src="/img/blackIcon/AboutUs.svg" alt="About Us Icon" />
                <span>Request new app</span>
              </li> */}
                <li
                  onClick={() => {
                    window.open("https://help.screensdesign.com/", "_blank");
                    onClose();
                  }}
                >
                  <img src="/img/blackIcon/Support.svg" alt="Support Icon" />
                  <span>Support</span>
                </li>
                {!isLoggedOut && (
                  <li onClick={handleLogout}>
                    <img src="/img/blackIcon/Logout.svg" alt="Logout Icon" />
                    <span>Logout</span>
                  </li>
                )}
                <li>
                  <div className={styles.listLine} />
                </li>
                <li onClick={() => setIsSuggestAppModalOpen(true)}>
                  <img src="/img/message-add-menu.svg" alt="Suggest App" />
                  <span>Suggest App</span>
                </li>
              </ul>
            </div>

            <div className={styles.line} />

            <div className={styles.horisList}>
              <ul>
                <li
                  onClick={() => {
                    navigate("/privacy");
                    onClose();
                  }}
                >
                  Privacy
                </li>
                <li
                  onClick={() => {
                    navigate("/terms");
                    onClose();
                  }}
                >
                  Terms
                </li>
                <li
                  onClick={() => {
                    navigate("/copyright/");
                    onClose();
                  }}
                  >Copyright</li>
              </ul>
            </div>

            {isOrganizationListOpen && (
              <div className={styles.OrganizationList}>
                {isLoadingOrganizations ? (
                  <div className={styles.loading}>
                    <Spinner />
                  </div>
                ) : organizationsList ? (
                  organizationsWithGradients
                    .sort((a, b) => {
                      const aUserOrg = me?.organizations?.find(
                        (userOrg) => userOrg.uuid === a.uuid,
                      );
                      const bUserOrg = me?.organizations?.find(
                        (userOrg) => userOrg.uuid === b.uuid,
                      );
                      if (aUserOrg?.is_pro && !bUserOrg?.is_pro) return -1;
                      if (!aUserOrg?.is_pro && bUserOrg?.is_pro) return 1;
                      return 0;
                    })
                    .map((org) => {
                      const userOrg = me?.organizations?.find(
                        (userOrg) => userOrg.uuid === org.uuid,
                      );
                      return (
                        <div
                          key={org.uuid}
                          className={styles.OrganizationList_Item}
                          onClick={() => handleSelectOrganization(org)}
                        >
                          <div className={styles.OrganizationList_Info}>
                            {org.image ? (
                              <img
                                src={org.image}
                                alt={org.name}
                                className={styles.OrganizationList_Image}
                              />
                            ) : (
                              <div
                                className={styles.OrganizationList_Image}
                                style={{
                                  background: userOrg.active
                                    ? "black"
                                    : org.gradient,
                                }}
                              >
                                {org.name.charAt(0).toUpperCase()}
                              </div>
                            )}
                            <span
                              className={styles.OrganizationList_Name}
                              title={org.name}
                            >
                              {org.name}
                            </span>
                          </div>
                          {userOrg && userOrg.active && (
                            <div className={styles.OrganizationList_Checkmark}>
                              <img
                                src="/img/whiteIcon/Check.svg"
                                alt="Active organization"
                              />
                            </div>
                          )}
                          {userOrg && userOrg.is_pro && (
                            <div
                              className={
                                styles.header_organization_container_pro_container
                              }
                            >
                              <p>PRO</p>
                            </div>
                          )}
                        </div>
                      );
                    })
                ) : (
                  <div className={styles.error}>
                    Failed to load organizations
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
        {isSuggestAppModalOpen && (
          <SuggestAppModal
            onClose={() => setIsSuggestAppModalOpen(false)}
            isOpen={isSuggestAppModalOpen}
          />
        )}
      </>
    );
  },
);

export default ModalMenu;
