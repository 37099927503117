import {
  useMe,
  useCurrentOrganization
} from '@/Hooks'

import React, {
  ChangeEvent,
  Fragment,
  useEffect,
  useRef,
  useState,
  useCallback,
} from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import Modal from "react-modal";
import { saveAs } from "file-saver";
import { useSelector } from "react-redux";
import {
  IAppSavedGroup,
  IAppSavedGroupPoints,
  IAppSavedGroupStorePoints,
  IAppSaveStoreScreensData,
  ResponseSavedPoint,
  ResponseSavedPointGet,
  ResponseSavedStoreScreens,
  ResponseSharedItem,
  ResponseSharedItemSavedPoints,
  ResponseSharedItemStoreScreens,
  ResponseSharedViewsPoints,
} from "@/features/auth/types/auth";
import {
  useDeletePointMutation,
  useDeleteSavedAppsMutation,
  useDeleteSavedGroupPointsMutation,
  useDeleteSavedGroupScreensMutation,
  useDeleteSavedGroupStorePointsMutation,
  useFetchSavedGroupsMutation,
  useGetSavedGroupDownloadQuery,
  useGetSavedGroupPointsQuery,
  useGetSavedGroupStorePointsQuery,
  useSavedGroupDownloadMutation,
  useSavedGroupPointsMutation,
  useSavedGroupsMutation,
  useSavedGroupStorePointsMutation,
  useSavedStoreScreensDeleteMutation,
  useSharedGroupsMutation,
  useDeleteSavedAnimationMutation,
  useCreateSavedGroupAnimationMutation,
  useCreateSavedGroupObScreenMutation,
  useDeleteSavedObScreenMutation,
} from "@/features/auth/api/authAPI";
import { getZoomState } from "@/entities/zoom";
import { getSortByCategoriesSlice } from "@/entities/sortByCategories/model/selectors/getSortByCategoriesState";
import ModalSaveModeSavedGroupScreens from "@/shared/ui/ModalSaveModeSavedGroupScreens/ModalSaveModeSavedGroupScreens";
import ModalAppsEmpty from "@/shared/ui/ModalAppsEmpty/ModalAppsEmpty";
import ModalSaveModeSavedGroupStore from "@/shared/ui/ModalSaveModeSavedGroupStore/ModalSaveModeSavedGroupStore";
import ModalSaveModeGroups from "@/shared/ui/ModalSaveModeGroups/ModalSaveModeGroups";
import ModalSaveModeShareGroup from "@/shared/ui/ModalSaveModeShareGroup/ModalSaveModeShareGroup";
import ModalSaveModeShareStore from "@/shared/ui/ModalSaveModeShareStore/ModalSaveModeShareStore";
import ModalSaveModeShare from "@/shared/ui/ModalSaveModeShare/ModalSaveModeShare";
import ModalSaveModeScreens from "@/shared/ui/ModalSaveModeScreens/ModalSaveModeScreens";
import ModalSaveModeStore from "@/shared/ui/ModalSaveModeStore/ModalSaveModeStore";
import ModalSaveModeGroup from "@/shared/ui/ModalSaveModeGroup/ModalSaveModeGroup";
import ModalSaveModeGroupsModal from "@/shared/ui/ModalSaveModeGroupsModal/ModalSaveModeGroupsModal";
import ModalSaveModeSelectedModal from "@/shared/ui/ModalSaveModeSelectedModal/ModalSaveModeSelectedModal";
import Spinner from "@/shared/ui/Spinner/Spinner";
import styles from "./ModalSaveMode.module.scss";
import { useInView } from 'react-intersection-observer';
import { toast } from 'react-toastify';
import mixpanel from "@/shared/lib/mixpanel";
import { AnimationCard } from "@/shared/ui/AnimationCard/AnimationCard";
import { WebOnboardingCard } from "@/shared/ui/WebOnboardingCard/WebOnboardingCard";
import { handleOpenVideoPage } from "@/app/libs/const/handleOpenVideoPage";

interface ModalSaveModeProps {
  dataSavedPoint?: ResponseSavedPointGet;
  activeIndex: number;
  apiDataSharedViews?: "" | ResponseSharedViewsPoints | null | undefined;
  apiDataSavedStoreScreens: ResponseSavedStoreScreens | undefined;
  apiDataSharedItem: ResponseSharedItem | undefined;
  sharedGroupsQuery: string | null;
  refetchSavedPoints: () => void;
  apiDataSharedItemSavedPoints: ResponseSharedItemSavedPoints | undefined;
  apiDataSharedItemStoreScreens: ResponseSharedItemStoreScreens | undefined;
  viewType: string;
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  isLoading: boolean;
  isFetching: boolean;
  pageSize: number;
}

interface OutletContext {
  zoom: number;
  sortkey: string;
  userFilter: string;
}

const ModalSaveMode: React.FC<ModalSaveModeProps> = ({
  dataSavedPoint,
  activeIndex,
  apiDataSharedViews,
  apiDataSavedStoreScreens,
  sharedGroupsQuery,
  refetchSavedPoints,
  apiDataSharedItemSavedPoints,
  apiDataSharedItemStoreScreens,
  viewType,
  page,
  setPage,
  isFetching
}) => {

  const { zoom } = useOutletContext<OutletContext>();
  const [savedGroups, setSavedGroups] = useState([])
  const currenOrganization = useCurrentOrganization()
  const { me } = useMe();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");
  const [imageIndex, setImageIndex] = useState<null | number>(null);
  const [name, setName] = useState<string>("...");
  const [timestamp, setTimestamp] = useState<number | null>(null);
  const [idItem, setIdItem] = useState<null | number>(null);
  const [idStoreItem, setIdStoreItem] = useState<null | number>(null);
  const [imageIdStore, setImageIdStore] = useState<null | number>(null);
  const [videoIdItem, setVideoIdItem] = useState<null | number>(null);
  const [selectActive, setSelectActive] = useState(false);
  const [selectGroupActive, setSelectGroupActive] = useState(false);
  const [selectCurrentGroupActive, setSelectCurrentGroupActive] =
    useState(false);
  const [selectedPhotos, setSelectedPhotos] = useState<
    Set<{ id: number; url: string; videoId: number }>
  >(new Set());
  const [selectedApps, setSelectedApps] = useState(new Set());
  const [selectedGroups, setSelectedGroups] = useState<Set<number>>(new Set());
  const [selectedCurrentGroups, setSelectedCurrentGroups] = useState<
    Set<{ id: number; url: string; videoId: number }>
  >(new Set());
  const [isLinkSaved, setIsLinkSaved] = useState(false);
  const [groupActive, setGroupActive] = useState(false);
  const [modalAddGroupActive, setModalAddGroupActive] = useState(false);
  const [addGroupModal, setAddGroupModal] = useState(false);
  const [groupName, setGroupName] = useState("");
  const [hoveredScreenId, setHoveredScreenId] = useState<number | null>(null);
  const [hoveredStoreScreenId, setHoveredStoreScreenId] = useState<
    number | null
  >(null);
  const [isCurrentGroupActive, setIsCurrentGroupActive] = useState(false);

  const [filterValueGroup, setFilterValueGroup] = useState("");

  const [typeScreen, setTypeScreen] = useState<string>("");
  const [groupIdPoint, setGroupIdPoint] = useState<null | number>(null);
  const [appId, setAppId] = useState<number | null>(null);
  const [slugText, setSlugText] = useState<string>("");
  const [responseSavedGroup, setResponseSavedGroup] = useState<number | null>(
    null,
  );
  const [modalOpenGroups, setModalOpenGroups] = useState<boolean>(false);
  const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);
  const [matchingGroups, setMatchingGroups] = useState<IAppSavedGroup[]>([]);
  const [photoIdGroupScreen, setPhotoIdGroupScreen] = useState<number | null>(
    null,
  );
  const [isGroupPhotoModalOpen, setIsGroupPhotoModalOpen] = useState(false);
  const [selectedGroupPhoto, setSelectedGroupPhoto] = useState<string>("");
  const refCreateGroup = useRef<HTMLDivElement>(null);
  const refCreateGroupBtn = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const photoGroups = useRef<HTMLDivElement>(null);
  const photoGroupRef = useRef<HTMLDivElement>(null);
  const lastGroupRef = useRef<HTMLDivElement>(null);
  const refAddNewGroup = useRef<HTMLDivElement>(null);
  const refButtonAddNewGroup = useRef<HTMLDivElement>(null);
  const [appData, setAppData] = useState<
    ResponseSavedPoint | IAppSaveStoreScreensData | undefined
  >();
  const { zoomGroupSaveMode } = useSelector(getZoomState);
  const firstImageRef = useRef<HTMLDivElement>(null);
  const { ref, inView } = useInView({
    threshold: 0.5,
  });

  const navigate = useNavigate();

  //const { data: apiDataSavedGroups } = useGetSavedGroupsQuery(null);

  const { data: apiDataSavedGroupPoints, refetch: refetchSavedGroupPoints } =
    useGetSavedGroupPointsQuery(groupIdPoint!, { skip: !groupIdPoint });

  const [fetchSavedGroups, { isLoading: isFetchingSavedGroups }] = useFetchSavedGroupsMutation();

  const {
    data: apiDataSavedGroupDownload,
    refetch: refetchSavedGroupDownload,
  } = useGetSavedGroupDownloadQuery(responseSavedGroup!, {
    skip: !responseSavedGroup,
  });

  if (apiDataSavedGroupDownload && !apiDataSavedGroupDownload.ready_at) {
    setTimeout(() => {
      refetchSavedGroupDownload();
    }, 5000);
  }

  const {
    data: apiDataSavedGroupStorePoints,
    refetch: refetchSavedGroupStorePoints,
  } = useGetSavedGroupStorePointsQuery(groupIdPoint!, {
    skip: !groupIdPoint,
  });

  // const { data: apiDataSavedGroupsFilter, refetch } =
  //   useGetSavedGroupsFilterQuery(filterValueGroup);

  //const apiDataSavedGroupsFilter = null
  //const apiDataSavedGroupsFilter = savedGroups

  const [sharedGroups] = useSharedGroupsMutation();
  const [deletePoint] = useDeletePointMutation();
  const [deleteApps] = useDeleteSavedAppsMutation();
  const [deleteStorePoint] = useSavedStoreScreensDeleteMutation();
  const [deleteGroupPoint] = useDeleteSavedGroupPointsMutation();
  const [deleteSavedScreens] = useDeleteSavedGroupScreensMutation();
  const [deleteSavedStoreScreens] = useDeleteSavedGroupStorePointsMutation();
  const [deleteAnimation] = useDeleteSavedAnimationMutation();
  const [createSavedGroupAnimation] = useCreateSavedGroupAnimationMutation();
  const [createGroup] = useSavedGroupsMutation();
  const [addScreenToGroup] = useSavedGroupPointsMutation();
  const [addScreeStoreToGroup] = useSavedGroupStorePointsMutation();
  const [savedGroupDownload] = useSavedGroupDownloadMutation();
  const [createSavedGroupObScreen] = useCreateSavedGroupObScreenMutation();
  const [deleteObScreen] = useDeleteSavedObScreenMutation();

  const changeGroupName = (event: ChangeEvent<HTMLInputElement>) => {
    setGroupName(event.target.value);
  };

  const images = dataSavedPoint?.results.map((item) => {
    return item.screen;
  });

  const handleDeletePoint = (id: number | null) => {
    deletePoint(id).unwrap();
    closeModal();
  };

  const handleDeleteStorePoint = (id: number) => {
    deleteStorePoint(id).unwrap();

    if (me?.email) {
      mixpanel.identify(me.email);
      setTimeout(() => {
        mixpanel.people.increment('total_unsaved_screens', 1);
      }, 100);
    }

    closeModal();
    //sessionStorage.removeItem(`markersStore_${videoIdItem}`);
  };

  const openModal = (
    image: string,
    name: string,
    ts: number,
    id: number,
    idVideo: number,
    type: string,
    imageStoreId: number,
    idStore?: number,
    appId?: number,
    slug?: string,
    appData?: ResponseSavedPoint | IAppSaveStoreScreensData | undefined,
  ) => {
    setVideoIdItem(idVideo);
    setIdItem(id);
    const index = type === "Store"
      ? apiDataSavedStoreScreens?.results.findIndex(item => item.id === id)
      : images?.indexOf(image);
    setSelectedImage(image);
    setImageIndex(index !== undefined ? index : 0);
    setIsOpenModal(true);
    setName(name);
    setTimestamp(ts);
    setTypeScreen(type);
    setImageIdStore(imageStoreId);
    setIdStoreItem(idStore || null);
    setAppId(appId || null);
    setSlugText(slug!);
    setAppData(appData || undefined);
  };

  const closeModal = () => {
    setIsOpenModal(false);
  };

  const openGroupPhotoModal = (
    photo: string,
    photoId: number,
    name: string,
    ts: number,
    imageStoreId: number,
    appId?: number,
    slug?: string,
  ) => {
    const typeScreen = photo.endsWith(".webp") ? "Store" : "Screen";
    setTypeScreen(typeScreen);

    setSelectedGroupPhoto(photo);
    setPhotoIdGroupScreen(photoId);
    setIsGroupPhotoModalOpen(true);
    setAppId(appId || null);
    setName(name);
    setImageIdStore(imageStoreId);
    setTimestamp(ts);
    setSlugText(slug!);
  };

  const handleShareLinkGroup = async () => {
    try {
      const body = {
        saved_group: groupIdPoint!,
      };

      const response = await sharedGroups(body).unwrap();

      const urlToCopy = `${window.location.href}?sharedGroups=${response.key}`;

      await navigator.clipboard.writeText(urlToCopy);

      setIsLinkSaved(true);

      setTimeout(() => {
        setIsLinkSaved(false);
      }, 700);
    } catch (err) {
      console.error("Failed to copy: ", err);
    }
  };

  const handleNextImage = () => {
    const nextIndex = (imageIndex! + 1) % images!.length;
    setImageIndex(nextIndex);
    const nextItem = dataSavedPoint!.results[nextIndex];
    setSelectedImage(nextItem.screen!);
    setName(nextItem.app.name);
    setAppId(nextItem.app.id);
    setTimestamp(Number(nextItem.ts));
  };

  const handlePrevImage = () => {
    const prevIndex = (imageIndex! - 1 + images!.length) % images!.length;
    setImageIndex(prevIndex);
    const prevItem = dataSavedPoint!.results[prevIndex];
    setSelectedImage(prevItem.screen!);
    setName(prevItem.app.name);
    setAppId(prevItem.app.id);
    setTimestamp(Number(prevItem.ts));
  };

  const handleNextStoreImage = () => {
    const data = activeIndex === 0 ? dataSavedPoint : apiDataSavedStoreScreens;
    if (!data || !data.results || data.results.length === 0) {
      console.warn('No screens available');
      return;
    }
    const nextIndex = (imageIndex! + 1) % data.results.length;
    setImageIndex(nextIndex);
    const nextItem = data.results[nextIndex];
    if (nextItem) {
      setSelectedImage(activeIndex === 0 ? nextItem.screen : nextItem.store_screen?.thumbnail);
      setName(nextItem.app.name);
      setAppId(nextItem.app.id);
      setIdStoreItem(nextItem.id);
    } else {
      console.warn('Invalid screen data', nextItem);
    }
  };

  const handlePrevStoreImage = () => {
    const data = activeIndex === 0 ? dataSavedPoint : apiDataSavedStoreScreens;
    if (!data || !data.results || data.results.length === 0) {
      console.warn('No screens available');
      return;
    }
    const prevIndex = (imageIndex! - 1 + data.results.length) % data.results.length;
    setImageIndex(prevIndex);
    const prevItem = data.results[prevIndex];
    if (prevItem) {
      setSelectedImage(activeIndex === 0 ? prevItem.screen : prevItem.store_screen?.thumbnail);
      setName(prevItem.app.name);
      setAppId(prevItem.app.id);
      setIdStoreItem(prevItem.id);
    } else {
      console.warn('Invalid screen data', prevItem);
    }
  };

  useEffect(() => {
    if (!isCurrentGroupActive) {
      const handleKeyDown = (event: KeyboardEvent) => {
        if (event.key === "ArrowRight") {
          if (typeScreen === "Screen") {
            handleNextImage();
          } else {
            handleNextStoreImage();
          }
        } else if (event.key === "ArrowLeft") {
          if (typeScreen === "Screen") {
            handlePrevImage();
          } else {
            handlePrevStoreImage();
          }
        }
      };

      window.addEventListener("keydown", handleKeyDown);

      return () => {
        window.removeEventListener("keydown", handleKeyDown);
      };
    }
  }, [imageIndex, dataSavedPoint, apiDataSavedStoreScreens]);

  const handleClickPhone = (
    timestamp: number,
    blank: boolean = false,
    name: string,
    appId: number,
    slug: string
  ) => {
    handleOpenVideoPage(
      timestamp,
      slug,
      "Saved mode video",
      1,
      appId,
      undefined,
      undefined,
    );
  };

  const handleClickStorePhone = (
    timestamp: number,
    slugText: string,
    appId: number,
  ) => {
    handleOpenVideoPage(
      timestamp || 0,
      slugText,
      "Saved mode store",
      1,
      appId,
      undefined,
    );
  };

  const handlePhotoSelect = (id: number, url: string, videoId: number) => {
    const photo = { id, url, videoId };
    setSelectedPhotos((prevSelected) => {
      const newSelected = new Set(prevSelected);
      const itemToFind = [...newSelected].find((p) => p.id === id);

      if (itemToFind) {
        newSelected.delete(itemToFind);
      } else {
        newSelected.add(photo);
      }

      // Update selectActive based on whether there are any selected items
      if (newSelected.size === 0) {
        setSelectActive(false);
      } else if (!selectActive) {
        setSelectActive(true);
      }

      return newSelected;
    });
  };

  const allGroupPhotos = [
    ...(apiDataSavedGroupPoints?.results || []),
    ...(apiDataSavedGroupStorePoints?.results || []),
  ];

  const handleNextGroupPhoto = () => {
    if (allGroupPhotos.length === 0) return;

    const currentIndex = allGroupPhotos.findIndex(
      (item) => item.id === photoIdGroupScreen,
    );
    const nextIndex = (currentIndex + 1) % allGroupPhotos.length;
    const nextItem = allGroupPhotos[nextIndex];

    updatePhotoDetails(nextItem);
  };

  const handlePrevGroupPhoto = () => {
    if (allGroupPhotos.length === 0) return;

    const currentIndex = allGroupPhotos.findIndex(
      (item) => item.id === photoIdGroupScreen,
    );
    const prevIndex =
      (currentIndex - 1 + allGroupPhotos.length) % allGroupPhotos.length;
    const prevItem = allGroupPhotos[prevIndex];

    updatePhotoDetails(prevItem);
  };

  const updatePhotoDetails = (
    item: IAppSavedGroupPoints | IAppSavedGroupStorePoints,
  ) => {
    let imageUrl: string;
    let appName: string;
    let timestamp: number;
    let id: number;

    if ("saved_point" in item) {
      imageUrl = item.saved_point.screen;
      appName = item.saved_point.app.name;
      timestamp = Number(item.saved_point.ts);
      id = item.saved_point.id;
    } else if ("saved_store_screen" in item) {
      imageUrl = item.saved_store_screen.store_screen.thumbnail;
      appName = item.saved_store_screen.app.name;
      timestamp = 0;
      id = item.saved_store_screen.store_screen.id;
    } else {
      return;
    }

    const typeScreen = imageUrl.endsWith(".webp") ? "Store" : "Screen";

    setSelectedGroupPhoto(imageUrl);
    setPhotoIdGroupScreen(item.id);
    setTypeScreen(typeScreen);
    setTimestamp(timestamp);
    setName(appName);
    setImageIdStore(id);
    if ("saved_point" in item) {
      setAppId(item.saved_point.app.id);
      setSlugText(item.saved_point.app.slug);
    } else if ("saved_store_screen" in item) {
      setAppId(item.saved_store_screen.app.id);
      setSlugText(item.saved_store_screen.app.slug);
    }
  };

  const handleGroupSelect = (id: number) => {
    setSelectedGroups((prevSelected) => {
      const newSelected = new Set(prevSelected);
      if (newSelected.has(id)) {
        newSelected.delete(id);
      } else {
        newSelected.add(id);
      }
      return newSelected;
    });
  };

  const handleCurrentGroupSelect = (
    id: number,
    url: string,
    videoId: number,
  ) => {
    setSelectedCurrentGroups((prevSelected) => {
      const newSelected = new Set(prevSelected);
      const itemToFind = [...newSelected].find(
        (photo) => photo.id === id && photo.url === url,
      );

      if (itemToFind) {
        newSelected.delete(itemToFind);
      } else {
        newSelected.add({ id, url, videoId });
      }

      return newSelected;
    });
  };

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "ArrowRight") {
        handleNextGroupPhoto();
      } else if (event.key === "ArrowLeft") {
        handlePrevGroupPhoto();
      }
    };

    window.addEventListener(
      "keydown",
      handleKeyDown as unknown as EventListener,
    );
    return () =>
      window.removeEventListener(
        "keydown",
        handleKeyDown as unknown as EventListener,
      );
  }, [handleNextGroupPhoto, handlePrevGroupPhoto]);

  const handleDeselectAll = () => {
    setSelectedPhotos(new Set());
    setSelectedApps(new Set());
    setSelectActive(false);
    setAddGroupModal(false);
  };

  const handleCloseGroup = () => {
    setGroupActive(false);
    setModalAddGroupActive(false);
    setFilterValueGroup("");
  };

  const handleCloseGroups = () => {
    setIsCurrentGroupActive(false);
  };

  const handleCreateGroup = async () => {
    setSelectActive(false);

    try {
      const newGroup = await createGroup({
        organizationUuid: currenOrganization.uuid,
        name: groupName,
        total_saved_points: 0,
        total_saved_store_screens: 0,
      }).unwrap();

      await handleClickGroup(newGroup.id, []);

      setTimeout(() => {
        setModalAddGroupActive(false);
        setGroupName("");
      }, 3000);

      setTimeout(() => {
        if (lastGroupRef.current && containerRef.current) {
          const container = containerRef.current;
          const lastGroupBottom =
            lastGroupRef.current.offsetTop + lastGroupRef.current.offsetHeight;
          const targetScrollTop = lastGroupBottom - container.offsetHeight;

          container.scrollTo({
            top: targetScrollTop,
            behavior: "smooth",
          });
        }
      }, 200);
    } catch (error) {
      console.error("Error creating group:", error);
    }
  };

  const handleFilterGroup = (event: ChangeEvent<HTMLInputElement>) => {
    //refetch();
    setFilterValueGroup(event.target.value);
  };

  //important
  //important
  const handleClickGroup = async (idGroup: number, screens: string[]) => {
    const group = savedGroups?.results.find(g => g.id === idGroup);
    if (!group) return;

    // Determine which items are already in the group based on content type
    const existingIds = new Set(
      viewType === "animation"
        ? group.saved_animations?.map(item => item.id)
        : viewType === "webonboarding"
          ? group.saved_ob_screens?.map(item => item.id)
          : screens
    );

    const screensToAdd = Array.from(selectedPhotos).filter(photo => !existingIds.has(photo.id));
    const alreadyExistingCount = selectedPhotos.size - screensToAdd.length;

    if (alreadyExistingCount > 0 && screensToAdd.length > 0) {
      const message = `${alreadyExistingCount} item(s) already inside this group. Adding ${screensToAdd.length} new item(s).`;
      toast.info(message);
    } else if (alreadyExistingCount === selectedPhotos.size) {
      toast.info("All selected items are already in this group.");
      return;
    }

    try {
      for (const photo of screensToAdd) {
        if (viewType === "animation") {
          await createSavedGroupAnimation({
            saved_group: idGroup,
            saved_animation: photo.id
          }).unwrap();
          toast.success("Animation added to group");
        } else if (viewType === "webonboarding") {
          await createSavedGroupObScreen({
            saved_group: idGroup,
            saved_ob_screen: photo.id
          }).unwrap();
          toast.success("Screen added to group");
        } else if (photo.url.endsWith("webp")) {
          await addScreeStoreToGroup({
            saved_group: idGroup,
            saved_store_screen: photo.id,
          }).unwrap();
          toast.success("Store screen added to group");
        } else if (photo.url.endsWith("jpg")) {
          await addScreenToGroup({
            saved_group: idGroup,
            saved_point: photo.id,
          }).unwrap();
          toast.success("Screen added to group");
        }
      }

      setSavedGroups(prevGroups => {
        if (!prevGroups) return prevGroups;
        return {
          ...prevGroups,
          results: prevGroups.results.map(group => {
            if (group.id === idGroup) {
              if (viewType === "animation") {
                return {
                  ...group,
                  saved_animations: [
                    ...group.saved_animations || [],
                    ...screensToAdd.map(photo => ({ id: photo.id }))
                  ]
                };
              } else if (viewType === "webonboarding") {
                return {
                  ...group,
                  saved_ob_screens: [
                    ...group.saved_ob_screens || [],
                    ...screensToAdd.map(photo => ({ id: photo.id }))
                  ]
                };
              }
              return {
                ...group,
                saved_points: [
                  ...group.saved_points,
                  ...screensToAdd
                    .filter(photo => !photo.url.endsWith("webp"))
                    .map(photo => ({ id: photo.id }))
                ],
                saved_store_screens: [
                  ...group.saved_store_screens,
                  ...screensToAdd
                    .filter(photo => photo.url.endsWith("webp"))
                    .map(photo => ({ id: photo.id }))
                ]
              };
            }
            return group;
          })
        };
      });

      await fetchSavedGroups(currenOrganization.uuid);
      if (timer) {
        clearTimeout(timer);
      }

      const newTimer = setTimeout(() => {
        setSelectedPhotos(new Set());
        setAddGroupModal(false);
        setSelectActive(false);
      }, 100);
      setTimer(newTimer);

    } catch (error) {
      console.error("Error adding items to group:", error);
      toast.error("Failed to add items to group");
    }
  };

  useEffect(() => {
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [timer]);

  const handleAddGroup = () => {
    const selectedPhotosArray = Array.from(selectedPhotos).map(photo => ({
      id: photo.id,
      url: photo.url,
      videoId: photo.videoId,
      type: viewType
    }));

    navigate('/saved-library/groups/', {
      state: {
        openAddGroupModal: true,
        selectedPhotos: selectedPhotosArray
      }
    });

    setGroupActive(true);
    setAddGroupModal(false);
  };

  const imageDimensions = [
    { width: 246.88, height: 534 },
    { width: 226, height: 490.59 },
    { width: 206, height: 447.17 },
    { width: 186, height: 403.76 },
  ];

  useEffect(() => {
    const handleCloseCreateGroup = (event: MouseEvent) => {
      if (
        refCreateGroup.current &&
        !refCreateGroup.current.contains(event.target as Node) &&
        refCreateGroupBtn.current &&
        !refCreateGroupBtn.current.contains(event.target as Node)
      ) {
        setModalAddGroupActive(false);
      }
    };

    document.addEventListener("mousedown", handleCloseCreateGroup);

    return () => {
      document.removeEventListener("mousedown", handleCloseCreateGroup);
    };
  }, []);

  const scrollToTop = useCallback(() => {
    if (firstImageRef.current) {
      firstImageRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, []);

  const applyZoom = (zoom: number) => {
    const photoCardListElement = photoGroups.current;

    if (photoCardListElement) {
      photoCardListElement.style.setProperty("--zoom-level", zoom.toString());
    }
  };

  useEffect(() => {
    const handleCloseGroup = (event: MouseEvent) => {
      if (
        refAddNewGroup.current &&
        !refAddNewGroup.current.contains(event.target as Node) &&
        refButtonAddNewGroup.current &&
        !refButtonAddNewGroup.current.contains(event.target as Node)
      ) {
        setAddGroupModal(false);
      }
    };

    document.addEventListener("mousedown", handleCloseGroup);

    return () => {
      document.removeEventListener("mousedown", handleCloseGroup);
    };
  }, []);

  useEffect(() => {
    applyZoom(zoom);
  }, [zoom]);

  const applyZoomGroup = (zoom: number) => {
    const photoCardListElement = photoGroupRef.current;

    if (photoCardListElement) {
      photoCardListElement.style.setProperty("--zoom-level", zoom.toString());
    }
  };

  useEffect(() => {
    scrollToTop();
    applyZoomGroup(zoomGroupSaveMode);
  }, [zoomGroupSaveMode, isCurrentGroupActive]);

  const [isDeleting, setIsDeleting] = useState(false);

  const handleDeleteSelectedPhotos = async () => {
    setIsDeleting(true);

    try {
      const deletePromises = Array.from(selectedPhotos).map(async (photoId) => {
        if (viewType === "animation") {
          await deleteAnimation(photoId.id).unwrap();
          if (me?.email) {
            mixpanel.identify(me.email);
            mixpanel.people.increment('total_unsaved_animations', 1);
          }
        } else if (viewType === "webonboarding") {
          await deleteObScreen(photoId.id).unwrap();
          if (me?.email) {
            mixpanel.identify(me.email);
            mixpanel.people.increment('total_unsaved_screens', 1);
          }
        } else if (photoId.url.endsWith("webp")) {
          await deleteStorePoint(photoId.id).unwrap();
          if (me?.email) {
            mixpanel.identify(me.email);
            mixpanel.people.increment('total_unsaved_screens', 1);
          }
        } else if (photoId.url.endsWith("jpg")) {
          await deletePoint(photoId.id).unwrap();
          if (me?.email) {
            mixpanel.identify(me.email);
            mixpanel.people.increment('total_unsaved_screens', 1);
          }
        }
      });

      const deleteAppsPromises = Array.from(selectedApps).map(async (app) => {
        await deleteApps(Number(app)).unwrap();
      });

      await Promise.all(deletePromises);
      await Promise.all(deleteAppsPromises);

      setSelectedPhotos(new Set());
      setSelectedApps(new Set());
      setSelectActive(false);
      refetchSavedPoints();
    } catch (error) {
      console.error("Error deleting items:", error);
    } finally {
      setIsDeleting(false);
    }
  };

  const handleDeleteSelectedPhotosGroup = async () => {
    setIsDeleting(true);
    try {
      const deletePromises = Array.from(selectedCurrentGroups).map(
        async (photoId) => {
          // todo: this logic here is wrong, never rely on file extensions
          if (photoId.url.endsWith("webp")) {
            await deleteSavedStoreScreens(photoId.id).unwrap();
          } else if (photoId.url.endsWith("jpg")) {
            await deleteSavedScreens(photoId.id).unwrap();
          }
        },
      );

      await Promise.all(deletePromises);

      setSelectedCurrentGroups(new Set());
      setSelectCurrentGroupActive(false);
      //refetch();
    } catch (error) {
      console.error("Failed to delete selected photos group:", error);
    } finally {
      setIsDeleting(false);
    }
  };

  const handleDeleteGroup = async () => {
    setIsDeleting(true);
    try {
      const deletePromises = Array.from(selectedGroups).map((photoId) =>
        deleteGroupPoint(photoId).unwrap(),
      );

      await Promise.all(deletePromises);

      setSelectedGroups(new Set());
      setSelectGroupActive(false);
    } catch (error) {
      console.error("Failed to delete groups:", error);
    } finally {
      setIsDeleting(false);
    }
  };

  const handleDeleteScreenFromGroup = async () => {
    if (typeScreen === "Store") {
      await deleteSavedStoreScreens(photoIdGroupScreen!).unwrap();
    } else {
      await deleteSavedScreens(photoIdGroupScreen!).unwrap();
    }
    setIsGroupPhotoModalOpen(false);
    refetchSavedGroupPoints();
    refetchSavedGroupStorePoints();
  };

  const hasMatchingItems = (groupId: number) => {
    const group = savedGroups?.results.find(g => g.id === groupId);
    if (!group) return false;

    return Array.from(selectedPhotos).some(photo => {
      if (viewType === "animation") {
        // Check if this animation is already in the group's saved_animations
        return group.saved_animations?.some(animation => animation.id === photo.id);
      } else if (viewType === "webonboarding") {
        // Check if this web onboarding screen is already in the group's saved_ob_screens
        return group.saved_ob_screens?.some(screen => screen.id === photo.id);
      } else if (photo.url.endsWith("webp")) {
        // Check store screens
        return group.saved_store_screens.some(screen => screen.id === photo.id);
      } else {
        // Check regular screens
        return group.saved_points.some(point => point.id === photo.id);
      }
    });
  };

  const handleAddToGroupModal = () => {
    closeModal();
    setSelectActive(true);
    {
      activeIndex === 0
        ? setSelectedPhotos((prevSelected) => {
          const newSelected = new Set(prevSelected);
          const newPhoto = {
            id: imageIdStore!,
            url: selectedImage,
            videoId: videoIdItem!,
          };
          newSelected.add(newPhoto);
          return newSelected;
        })
        : setSelectedPhotos((prevSelected) => {
          const newSelected = new Set(prevSelected);
          const newPhoto = {
            id: idStoreItem!,
            url: selectedImage,
            videoId: videoIdItem!,
          };
          newSelected.add(newPhoto);
          return newSelected;
        });
    }
    setAddGroupModal(true);
  };

  useEffect(() => {
    if (groupIdPoint) {
      const fetchData = async () => {
        const response = await savedGroupDownload(
          Number(groupIdPoint)!,
        ).unwrap();

        setResponseSavedGroup(Number(response.id));
      };

      fetchData();
    }
  }, [groupIdPoint]);

  const handleZipDownload = () => {
    saveAs(apiDataSavedGroupDownload!.file, "images.zip");
  };


  useEffect(() => {
    if (selectedPhotos.size === 0) {
      setSelectActive(false);
    } else if (selectedPhotos.size > 0) {
      setSelectActive(true);
    }
  }, [selectedPhotos]);

  const handleGroupClick = (group: IAppSavedGroup) => {
    navigate(`/saved-library/groups/${group.id}/`);
  };


  useEffect(() => {
    if (!currenOrganization) { return }
    fetchSavedGroups(currenOrganization.uuid)
      .unwrap()
      .then((resp) => setSavedGroups(resp))
      .catch((error) => console.error("Failed to fetch saved groups:", error));

  }, [currenOrganization])

  const [itemsToRefetch, setItemsToRefetch] = useState<Set<number>>(new Set());

  const handleRefetchRequest = useCallback((itemId: number) => {
    setItemsToRefetch(prev => new Set([...prev, itemId]));
  }, []);

  useEffect(() => {
    if (itemsToRefetch.size > 0) {
      const timeoutId = setTimeout(() => {
        refetchSavedPoints();
        setItemsToRefetch(new Set());
      }, 2000);

      return () => clearTimeout(timeoutId);
    }
  }, [itemsToRefetch, refetchSavedPoints]);


  useEffect(() => {
    if (inView && !isFetching) {
      if (viewType === "animation" && dataSavedPoint?.next) {
        setPage(prevPage => prevPage + 1);
      } else if (viewType === "video" && dataSavedPoint?.next) {
        setPage(prevPage => prevPage + 1);
      } else if (viewType === "webonboarding" && dataSavedPoint?.next) {
        setPage(prevPage => prevPage + 1);
      } else if (viewType === "store" && apiDataSavedStoreScreens?.next) {
        setPage(prevPage => prevPage + 1);
      }
    }
  }, [inView, dataSavedPoint?.next, apiDataSavedStoreScreens?.next, isFetching, viewType]);

  return (
    <div>
      {!inView && (
        <div onClick={scrollToTop} className={styles.arrowUp}>
          <img src="/img/whiteIcon/ArrowRight.svg" alt="" />
        </div>
      )}
      {addGroupModal && selectedPhotos.size > 0 && (
        <ModalSaveModeGroupsModal
          apiDataSavedGroupsFilter={savedGroups}
          filterValueGroup={filterValueGroup}
          handleFilterGroup={handleFilterGroup}
          handleAddGroup={handleAddGroup}
          handleClickGroup={handleClickGroup}
          hasMatchingItems={hasMatchingItems}
          ref={refAddNewGroup}
        />
      )}
      {selectActive && (
        <ModalSaveModeSelectedModal
          handleDeselectAll={handleDeselectAll}
          selectedPhotos={selectedPhotos}
          selectedApps={selectedApps}
          setAddGroupModal={setAddGroupModal}
          addGroupModal={addGroupModal}
          setFilterValueGroup={setFilterValueGroup}
          handleDeleteSelectedPhotos={handleDeleteSelectedPhotos}
          isDeleting={isDeleting}
          ref={refButtonAddNewGroup}
        />
      )}
      {groupActive ? (
        isCurrentGroupActive ? (
          <div className={styles.headerGroupContentActive}>
            <div className={styles.headerGroupLeft}>
              {selectCurrentGroupActive ? (
                <div
                  onClick={() => {
                    setSelectCurrentGroupActive(!selectCurrentGroupActive);
                    setSelectedCurrentGroups(new Set());
                  }}
                  className={styles.selectSave}
                >
                  <img src="/img/cursorClickIcon.svg" alt="cursorClickImg" />
                  <p>Deselect All</p>
                </div>
              ) : (
                <>
                  <div
                    onClick={handleCloseGroups}
                    className={styles.groupArrow}
                  >
                    <img
                      src="/img/whiteIcon/ChevronLeft.svg"
                      alt="arrowGroupLeftImg"
                    />
                  </div>
                  {/* <div
                    onClick={() =>
                      setSelectCurrentGroupActive(!selectCurrentGroupActive)
                    }
                    className={styles.selectSave}
                  >
                    <img src="/img/cursorClickIcon.svg" alt="cursorClickImg" />
                    <p>Select</p>
                  </div> */}
                  {apiDataSavedGroupDownload?.ready_at && (
                    <div
                      onClick={handleZipDownload}
                      className={styles.downloadScreen}
                    >
                      <p>Download all Screens</p>
                    </div>
                  )}
                </>
              )}
            </div>
            <div className={styles.groupSaveActive}>
              <h3>{savedGroups?.results[0].name}</h3>
              <p>
                {savedGroups &&
                  savedGroups?.results[0].total_saved_points +
                  savedGroups?.results[0]
                    .total_saved_store_screens}{" "}
                Screens
              </p>
            </div>
            <div className={styles.headerGroupRight}>
              {/* <div className={styles.zoomDisplayGroupContainer}>
                <div className={styles.zoomDisplay}>
                  <button
                    type="button"
                    className={styles.buttonDecrease}
                    onClick={handleZoomOutGroup}
                  >
                    <img
                      src="/img/whiteIcon/Minus.svg"
                      alt="decreaseImg"
                      loading="lazy"
                    />
                  </button>
                  <div className={styles.titleZoom}>
                    <p>Zoom</p>
                    <p>{zoomGroupSaveMode}X</p>
                  </div>
                  <button
                    type="button"
                    className={styles.buttonIncrease}
                    onClick={handleZoomInGroup}
                  >
                    <img
                      src="/img/whiteIcon/Plus.svg"
                      alt="increaseImg"
                      loading="lazy"
                    />
                  </button>
                </div>
              </div> */}
              {selectedCurrentGroups.size > 0 ? (
                <div
                  onClick={handleDeleteSelectedPhotosGroup}
                  className={styles.deleteScreens}
                >
                  <button>
                    {isDeleting ? (
                      <Spinner width="50px" />
                    ) : (
                      <img src="/img/whiteIcon/Delete.svg" alt="trashImg" />
                    )}
                  </button>
                </div>
              ) : (
                <div
                  onClick={handleShareLinkGroup}
                  className={styles.shareThis}
                >
                  <img
                    src="/img/shareSaveModeIcon.svg"
                    alt="shareImg"
                    loading="lazy"
                  />
                  <p
                    style={{
                      color: isLinkSaved ? "#26de86" : "#fff",
                    }}
                  >
                    {isLinkSaved ? "Link saved" : "Share this"}
                  </p>
                </div>
              )}
            </div>
          </div>
        ) : (
          <div className={styles.headerGroupContent}>
            {selectGroupActive ? (
              <div className={styles.headerGroupLeftSide}>
                <div
                  onClick={() => {
                    setSelectGroupActive(!selectGroupActive);
                    setSelectedGroups(new Set());
                  }}
                  className={styles.selectSave}
                >
                  <img src="/img/cursorClickIcon.svg" alt="cursorClickImg" />
                  <p>Deselect All</p>
                </div>
              </div>
            ) : (
              <div className={styles.headerGroupLeftSide}>
                <div onClick={handleCloseGroup} className={styles.groupArrow}>
                  <img
                    src="/img/blackIcon/ChevronLeft.svg"
                    alt="arrowGroupLeftImg"
                  />
                </div>
                {/* 444444444 */}
                {/* <div
                  onClick={() => setSelectGroupActive(!selectGroupActive)}
                  className={styles.selectSave}
                >
                  <img src="/img/cursorClickIcon.svg" alt="cursorClickImg" />
                  <p>Select</p>
                </div> */}
              </div>
            )}
            <div ref={refCreateGroup} className={styles.groupSaveActive}>
              <img src="/img/blackIcon/SavedGroup.svg" alt="groupSaveImg" />
              <p>Saved Groups</p>
            </div>
            {selectedGroups.size > 0 ? (
              <div onClick={handleDeleteGroup} className={styles.groupCreate}>
                {isDeleting ? (
                  <Spinner width="30px" />
                ) : (
                  <img src="/img/whiteIcon/Delete.svg" alt="trashImg" />
                )}
                <p>Delete</p>
              </div>
            ) : (
              <div
                ref={refCreateGroupBtn}
                onClick={() => setModalAddGroupActive(!modalAddGroupActive)}
                className={styles.groupCreate}
              >
                <img src="/img/whiteIcon/Plus.svg" alt="plusGroupImg" />
                <p>Create New Group</p>
              </div>
            )}
          </div>
        )
      ) : (
        <></>
      )}

      <div className={styles.mainContent}>
        {viewType === "animation" ? (
          <>
            <div className={styles.animations}>
              {dataSavedPoint?.results.map((item, index) => {
                const matchingGroups = savedGroups?.results?.filter(
                  (group) =>
                    group.saved_animations?.some((animation) => animation.id === item.id),
                );
                return (
                  <Fragment key={`saved-animation-${item.id}-${page}-${index}`}>
                    <AnimationCard
                      key={item.id}
                      id={item.id}
                      url={item.app_video_animation.video}
                      name={item.app_video_animation.app.name}
                      icon={item.app_video_animation.app.icon}
                      shortname={item.app_video_animation.app.shortname}
                      selectActive={selectActive}
                      isSelected={Array.from(selectedPhotos).some(photo => photo.id === item.id)}
                      onSelect={(id, url) => handlePhotoSelect(id, url, item.app_video_animation.id)}
                      appId={item.app_video_animation.app.id}
                      videoId={item.app_video_animation.app_video}
                      slug={item.app_video_animation.app.slug}
                    />
                  </Fragment>
                );
              })}

              <div ref={ref} style={{ width: "100%", minHeight: "30px" }}>
                {isFetching && (
                  <div className={styles.spinnerContainer}>
                    <Spinner width="150px" />
                  </div>
                )}
              </div>
            </div>
          </>
        ) : viewType === "webonboarding" ? (
          <div className={styles.webonboarding}>
            {dataSavedPoint && dataSavedPoint.results.length > 0 ? (
              <>
                {dataSavedPoint.results.map((item) => (
                  <WebOnboardingCard
                    key={item.id}
                    id={item.id}
                    url={item.screen}
                    name={item.app.name}
                    icon={item.app.icon}
                    shortname={item.app.shortname}
                    selectActive={true}
                    isSelected={Array.from(selectedPhotos).some(photo => photo.id === item.id)}
                    onSelect={(id, url) => handlePhotoSelect(id, url, item.app_ob_video)}
                    appId={item.app.id}
                    slug={item.app.slug}
                    timeFrom={parseFloat(item.ts)}
                    onboardingVideoId={item.app_ob_video}
                  />
                ))}

                <div ref={ref} style={{ width: "100%", minHeight: "30px" }}>
                  {isFetching && (
                    <div className={styles.spinnerContainer}>
                      <Spinner width="150px" />
                    </div>
                  )}
                </div>
              </>
            ) : (
              <ModalAppsEmpty
                text="no web onboarding added"
                img="/img/groupEmptyIcon.svg"
              />
            )}
          </div>
        ) : viewType === "video" ? (
          <div className={styles.photosContainer}>
            {apiDataSharedItemSavedPoints &&
              apiDataSharedItemStoreScreens &&
              sharedGroupsQuery ? (
              <div className={styles.groupPhotoContainerActive}>
                {apiDataSharedItemSavedPoints?.results.map((item, index) => {
                  return (
                    <Fragment key={`saved-point-${item.id}-${page}-${index}`}>
                      <ModalSaveModeShareGroup
                        key={index}
                        selectCurrentGroupActive={selectCurrentGroupActive}
                        handleCurrentGroupSelect={handleCurrentGroupSelect}
                        item={item}
                        openGroupPhotoModal={openGroupPhotoModal}
                        selectedCurrentGroups={selectedCurrentGroups}
                      />
                    </Fragment>
                  );
                })}
                {apiDataSharedItemStoreScreens?.results.map((item, index) => {
                  return (
                    <Fragment key={`saved-point-${item.id}-${page}-${index}`}>
                      <ModalSaveModeShareStore
                        key={index}
                        selectCurrentGroupActive={selectCurrentGroupActive}
                        handleCurrentGroupSelect={handleCurrentGroupSelect}
                        item={item}
                        openGroupPhotoModal={openGroupPhotoModal}
                        selectedCurrentGroups={selectedCurrentGroups}
                      />
                    </Fragment>
                  );
                })}
              </div>
            ) : activeIndex === 0 ? (
              apiDataSharedViews ? (
                apiDataSharedViews?.results.map((item, index) => {
                  return (
                    <Fragment key={`saved-point-${item.id}-${page}-${index}`}>
                      <ModalSaveModeShare
                        setName={setName}
                        item={item}
                        handleClickPhone={handleClickPhone}
                        selectActive={selectActive}
                        selectedPhotos={selectedPhotos}
                        handlePhotoSelect={handlePhotoSelect}
                        openModal={openModal}
                      />
                    </Fragment>
                  );
                })
              ) : dataSavedPoint && dataSavedPoint?.results.length > 0 ? (
                <>
                  {dataSavedPoint.results.map((item, index) => {
                    const matchingGroups = savedGroups?.results?.filter(
                      (group) =>
                        group.saved_points.some((image) => image.id === item.id),
                    );
                    return (
                      <Fragment key={`saved-point-${item.id}-${page}-${index}`}>
                        <ModalSaveModeScreens
                          ref={index === 0 ? (el) => { firstImageRef.current = el; } : null}
                          setHoveredScreenId={setHoveredScreenId}
                          item={item}
                          setSelectActive={setSelectActive}
                          handlePhotoSelect={handlePhotoSelect}
                          selectActive={selectActive}
                          selectedPhotos={selectedPhotos}
                          setName={setName}
                          handleClickPhone={handleClickPhone}
                          openModal={openModal}
                          matchingGroups={matchingGroups}
                          setModalOpenGroups={setModalOpenGroups}
                          setMatchingGroups={setMatchingGroups}
                          onGroupClick={handleGroupClick}
                          onRefetchRequest={handleRefetchRequest}
                        />
                      </Fragment>
                    );
                  })}

                  <div ref={ref} style={{ width: "100%", minHeight: "30px" }}>
                    {isFetching && (
                      <div className={styles.spinnerContainer}>
                        <Spinner width="150px" />
                      </div>
                    )}
                  </div>
                </>
              ) : (
                <ModalAppsEmpty
                  text="no screens added"
                  img="/img/groupEmptyIcon.svg"
                />
              )
            ) : activeIndex === 1 ? (
              apiDataSavedStoreScreens &&
                apiDataSavedStoreScreens?.results.length > 0 ? (
                <>
                  {apiDataSavedStoreScreens?.results.map((item, index) => {
                    const matchingGroups = savedGroups?.results?.filter(
                      (group) =>
                        group.saved_store_screens.some(
                          (image) => image.id === item.id,
                        ),
                    );
                    return (
                      <Fragment key={`saved-point-${item.id}-${page}-${index}`}>
                        <ModalSaveModeStore
                          ref={index === 0 ? (el) => { firstImageRef.current = el; } : null}
                          setHoveredStoreScreenId={setHoveredStoreScreenId}
                          item={item}
                          setSelectActive={setSelectActive}
                          handlePhotoSelect={handlePhotoSelect}
                          selectActive={selectActive}
                          selectedPhotos={selectedPhotos}
                          setName={setName}
                          handleClickPhone={handleClickPhone}
                          openModal={openModal}
                          matchingGroups={matchingGroups}
                          setModalOpenGroups={setModalOpenGroups}
                          setMatchingGroups={setMatchingGroups}
                        />
                      </Fragment>
                    );
                  })}

                  <div ref={ref} style={{ width: "100%", minHeight: "30px" }}>
                    {isFetching && (
                      <div className={styles.spinnerContainer}>
                        <Spinner width="150px" />
                      </div>
                    )}
                  </div>
                </>
              ) : (
                <ModalAppsEmpty
                  text="no app store screens added"
                  img="/img/groupEmptyIcon.svg"
                />
              )
            ) : (
              <div />
            )}
          </div>
        ) : groupActive ? (
          <div className={styles.groupPhotoContainerWrapper}>
            {isCurrentGroupActive ? (
              <div
                ref={photoGroupRef}
                className={styles.groupPhotoContainerActive}
              >
                {apiDataSavedGroupPoints &&
                  apiDataSavedGroupPoints?.results.length > 0 ? (
                  apiDataSavedGroupPoints?.results.map((item, index) => {
                    return (
                      <Fragment key={`saved-point-${item.id}-${page}-${index}`}>
                        <ModalSaveModeSavedGroupScreens
                          key={index}
                          handleCurrentGroupSelect={handleCurrentGroupSelect}
                          item={item}
                          openGroupPhotoModal={openGroupPhotoModal}
                          selectedCurrentGroups={selectedCurrentGroups}
                        />
                      </Fragment>
                    );
                  })
                ) : (
                  <ModalAppsEmpty
                    text="no screens added"
                    img="/img/groupEmptyIcon.svg"
                  />
                )}
                {apiDataSavedGroupStorePoints?.results.map((item, index) => {
                  return (
                    <Fragment key={`saved-point-${item.id}-${page}-${index}`}>
                      <ModalSaveModeSavedGroupStore
                        key={index}
                        selectCurrentGroupActive={selectCurrentGroupActive}
                        handleCurrentGroupSelect={handleCurrentGroupSelect}
                        item={item}
                        openGroupPhotoModal={openGroupPhotoModal}
                        selectedCurrentGroups={selectedCurrentGroups}
                      />
                    </Fragment>
                  );
                })}
              </div>
            ) : (
              <div className={styles.groupPhotoContainer}>
                {savedGroups &&
                  savedGroups?.results.length > 0 ? (
                  savedGroups?.results.map((item, index) => {
                    const countScreens =
                      item.total_saved_points + item.total_saved_store_screens;
                    const isLastGroup =
                      index === savedGroups.results.length - 1;
                    // 2222222
                    return (
                      <Fragment key={`saved-point-${item.id}-${page}-${index}`}>
                        <ModalSaveModeGroups
                          key={index}
                          selectGroupActive={true}
                          selectedGroups={selectedGroups}
                          item={item}
                          isLastGroup={isLastGroup}
                          handleGroupSelect={handleGroupSelect}
                          setFilterValueGroup={setFilterValueGroup}
                          setGroupIdPoint={setGroupIdPoint}
                          countScreens={countScreens}
                          imageDimensions={imageDimensions}
                          ref={lastGroupRef}
                        />
                      </Fragment>
                    );
                  })
                ) : (
                  <ModalAppsEmpty
                    text="no groups added"
                    img="/img/groupEmptyIcon.svg"
                  />
                )}
              </div>
            )}
          </div>
        ) : (
          <div ref={photoGroups} className={styles.photosContainer}>
            {apiDataSharedItemSavedPoints &&
              apiDataSharedItemStoreScreens &&
              sharedGroupsQuery ? (
              <div className={styles.groupPhotoContainerActive}>
                {apiDataSharedItemSavedPoints?.results.map((item, index) => {
                  return (
                    <Fragment key={`saved-point-${item.id}-${page}-${index}`}>
                      <ModalSaveModeShareGroup
                        key={index}
                        selectCurrentGroupActive={selectCurrentGroupActive}
                        handleCurrentGroupSelect={handleCurrentGroupSelect}
                        item={item}
                        openGroupPhotoModal={openGroupPhotoModal}
                        selectedCurrentGroups={selectedCurrentGroups}
                      />
                    </Fragment>
                  );
                })}
                {apiDataSharedItemStoreScreens?.results.map((item, index) => {
                  return (
                    <Fragment key={`saved-point-${item.id}-${page}-${index}`}>
                      <ModalSaveModeShareStore
                        key={index}
                        selectCurrentGroupActive={selectCurrentGroupActive}
                        handleCurrentGroupSelect={handleCurrentGroupSelect}
                        item={item}
                        openGroupPhotoModal={openGroupPhotoModal}
                        selectedCurrentGroups={selectedCurrentGroups}
                      />
                    </Fragment>
                  );
                })}
              </div>
            ) : activeIndex === 0 ? (
              apiDataSharedViews ? (
                apiDataSharedViews?.results.map((item, index) => {
                  return (
                    <Fragment key={`saved-point-${item.id}-${page}-${index}`}>
                      <ModalSaveModeShare
                        setName={setName}
                        item={item}
                        handleClickPhone={handleClickPhone}
                        selectActive={selectActive}
                        selectedPhotos={selectedPhotos}
                        handlePhotoSelect={handlePhotoSelect}
                        openModal={openModal}
                      />
                    </Fragment>
                  );
                })
              ) : dataSavedPoint && dataSavedPoint?.results.length > 0 ? (
                <>
                  {dataSavedPoint?.results.map((item, index) => {
                    const matchingGroups = savedGroups?.results?.filter(
                      (group) =>
                        group.saved_points.some((image) => image.id === item.id),
                    );
                    return (
                      <Fragment key={`saved-point-${item.id}-${page}-${index}`}>
                        <ModalSaveModeScreens
                          ref={index === 0 ? (el) => { firstImageRef.current = el; } : null}
                          setHoveredScreenId={setHoveredScreenId}
                          item={item}
                          setSelectActive={setSelectActive}
                          handlePhotoSelect={handlePhotoSelect}
                          selectActive={selectActive}
                          selectedPhotos={selectedPhotos}
                          setName={setName}
                          handleClickPhone={handleClickPhone}
                          openModal={openModal}
                          matchingGroups={matchingGroups}
                          setModalOpenGroups={setModalOpenGroups}
                          setMatchingGroups={setMatchingGroups}
                          onGroupClick={handleGroupClick}
                          onRefetchRequest={handleRefetchRequest}
                        />
                      </Fragment>
                    );
                  })}

                  <div ref={ref} style={{ width: "100%", minHeight: "30px" }}>
                    {isFetching && (
                      <div className={styles.spinner}>
                        <Spinner />
                      </div>
                    )}
                  </div>
                </>
              ) : (
                <ModalAppsEmpty
                  text="no screens added"
                  img="/img/groupEmptyIcon.svg"
                />
              )
            ) : activeIndex === 1 ? (
              apiDataSavedStoreScreens &&
                apiDataSavedStoreScreens?.results.length > 0 ? (
                <>
                  {apiDataSavedStoreScreens?.results.map((item, index) => {
                    const matchingGroups = savedGroups?.results?.filter(
                      (group) =>
                        group.saved_store_screens.some(
                          (image) => image.id === item.id,
                        ),
                    );
                    return (
                      <Fragment key={`saved-point-${item.id}-${page}-${index}`}>
                        <ModalSaveModeStore
                          ref={index === 0 ? (el) => { firstImageRef.current = el; } : null}
                          setHoveredStoreScreenId={setHoveredStoreScreenId}
                          item={item}
                          setSelectActive={setSelectActive}
                          handlePhotoSelect={handlePhotoSelect}
                          selectActive={selectActive}
                          selectedPhotos={selectedPhotos}
                          setName={setName}
                          handleClickPhone={handleClickPhone}
                          openModal={openModal}
                          matchingGroups={matchingGroups}
                          setModalOpenGroups={setModalOpenGroups}
                          setMatchingGroups={setMatchingGroups}
                        />
                      </Fragment>
                    );
                  })}

                  <div ref={ref} style={{ width: "100%", minHeight: "30px" }}>
                    {isFetching && (
                      <div className={styles.spinnerContainer}>
                        <Spinner width="150px" />
                      </div>
                    )}
                  </div>
                </>
              ) : (
                <ModalAppsEmpty
                  text="no app store screens added"
                  img="/img/groupEmptyIcon.svg"
                />
              )
            ) : (
              <div />
            )}
          </div>
        )}
      </div>

      {modalAddGroupActive && (
        // Create a new group
        <ModalSaveModeGroup
          ref={refCreateGroup}
          changeGroupName={changeGroupName}
          groupName={groupName}
          handleCreateGroup={handleCreateGroup}
        />
      )}
      <Modal
        isOpen={isGroupPhotoModalOpen}
        onRequestClose={() => setIsGroupPhotoModalOpen(false)}
        contentLabel="Group Photo Modal"
        className={styles.modalGroup}
        overlayClassName={styles.overlay}
      >
        <div className={styles.modalContent}>
          <div className={styles.containerImage}>
            <button
              className={styles.modalContentPhotoButton}
              onClick={
                typeScreen === "Store"
                  ? () =>
                    handleClickStorePhone(
                      timestamp!,
                      slugText,
                      appId
                    )
                  : () => handleClickPhone(timestamp!, true, name, appId!, slugText)
              }
            >
              <img
                className={styles.modalContentPhoto}
                src={selectedGroupPhoto}
                alt="Enlarged pic"
                loading="lazy"
              />
            </button>
          </div>
          <img
            className={styles.arrowRight}
            src="/img/whiteIcon/ChevronRight.svg"
            alt="arrowRightImg"
            onClick={handleNextGroupPhoto}
            loading="lazy"
          />
          <img
            className={styles.arrowLeft}
            src="/img/whiteIcon/ChevronLeft.svg"
            alt="arrowLeftImg"
            onClick={handlePrevGroupPhoto}
            loading="lazy"
          />
          <div className={styles.modalFooterContainer}>
            <div className={styles.modalFooter}>
              <button onClick={handleDeleteScreenFromGroup}>
                <img
                  className={styles.deleteIcon}
                  src="/img/whiteIcon/Delete.svg"
                  alt="deleteImg"
                  loading="lazy"
                />
                <p>Delete</p>
              </button>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={isOpenModal}
        onRequestClose={closeModal}
        contentLabel="Image Modal"
        className={styles.modal}
        overlayClassName={styles.overlay}
      >
        <div className={styles.modalContent}>
          <div className={styles.containerImage}>
            <button
              className={styles.modalContentPhotoButton}
              onClick={
                typeScreen === "Store"
                  ? () =>
                    handleClickStorePhone(
                      timestamp!,
                      slugText,
                      appId
                    )
                  : () => handleClickPhone(timestamp!, true, name, appId!, slugText)
              }
            >
              <img
                className={styles.modalContentPhoto}
                src={selectedImage}
                alt="Enlarged pic"
                loading="lazy"
              />
            </button>
          </div>
          <img
            className={styles.arrowRight}
            src="/img/whiteIcon/ChevronRight.svg"
            alt="arrowRightImg"
            onClick={
              typeScreen === "Store" ? handleNextStoreImage : handleNextImage
            }
            loading="lazy"
          />
          <img
            className={styles.arrowLeft}
            src="/img/whiteIcon/ChevronLeft.svg"
            alt="arrowLeftImg"
            onClick={
              typeScreen === "Store" ? handlePrevStoreImage : handlePrevImage
            }
            loading="lazy"
          />
          <div className={styles.modalFooterContainer}>
            <div className={styles.modalFooter}>
              <div onClick={handleAddToGroupModal} className={styles.groupSave}>
                <img src="/img/whiteIcon/Plus.svg" alt="plusGroupImg" />
                <p>Add to Group</p>
              </div>
              <div
                onClick={
                  typeScreen === "Store"
                    ? () => handleDeleteStorePoint(idStoreItem!)
                    : () => handleDeletePoint(idItem)
                }
                className={styles.deleteScreens}
              >
                <button>
                  <img src="/img/whiteIcon/Delete.svg" alt="trashImg" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ModalSaveMode;
