// react
import React from "react";
// rc-slider
import Slider from "rc-slider";
// styles
import "rc-slider/assets/index.css";
import "./CustomSlider.scss";

interface CustomSliderProps {
  min: number;
  max: number;
  step: number;
  value: number | number[];
  onChange: (value: number | number[]) => void;
}

const CustomSlider: React.FC<CustomSliderProps> = ({
  min,
  max,
  step,
  value,
  onChange,
}) => {
  const calculateLeftPosition = (val: number) => {
    const range = max - min;
    const adjustedValue = val - min;
    const percentage = (adjustedValue / range) * 100;
    return `${percentage}%`;
  };

  return (
    <div className="custom-slider-container">
      <div
        style={{
          left: `calc(${calculateLeftPosition(value as number)} - 2px)`,
        }}
        className="value-label"
      >
        <p>{`${value} users`}</p>
      </div>
      <div
        style={{
          left: `calc(${calculateLeftPosition(value as number)} - 5px)`,
        }}
        className="value-label-item"
      />
      <Slider
        min={min}
        max={max}
        step={step}
        value={value}
        handleStyle={{
          marginTop: "-5px",
          borderColor: "#3f51b5",
          height: 20,
          width: 20,
          background:
            "linear-gradient(90deg, rgb(35 97 217), rgb(94 36 217) 100%)",
          outline: "1px solid #e9e9e9",
          border: "5px solid #F9F9F9",
          cursor: "pointer",
          opacity: 1,
        }}
        onChange={onChange}
        trackStyle={{
          background:
            "linear-gradient(90deg, rgb(35 97 217), rgb(94 36 217) 100%)",
          height: 8,
        }}
        railStyle={{
          backgroundColor: "#F9F9F9",
          border: "1px solid #e9e9e9",
          height: 8,
        }}
      />
    </div>
  );
};

export default CustomSlider;
