// redux
import { createSlice } from "@reduxjs/toolkit";
// types
import { SaveModeState } from "../types/saveModeTypes";

const initialSate: SaveModeState = {
  isActiveProModal: false
};

const saveModeSlice = createSlice({
  name: "saveMode",
  initialState: initialSate,
  reducers: {

    toggleProModal: (state) => {
      state.isActiveProModal = !state.isActiveProModal;
    },
    activeProModal: (state) => {
      state.isActiveProModal = true;
    },
    deactiveProModal: (state) => {
      state.isActiveProModal = false;
    }
  },
});

export const { actions: saveModeActions, reducer: saveModeReducer } =
  saveModeSlice;
