// features
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { VideoResponseData } from "@/features/auth/types/auth";
// redux
import { videosState } from "../types";

const initialState: videosState = {
  apiDataVideos: {
    count: 0,
    next: null,
    previous: null,
    results: [],
  },
};

const videosDataSlice = createSlice({
  name: "videos",
  initialState,
  reducers: {
    setVideosData: (state, action: PayloadAction<VideoResponseData>) => {
      state.apiDataVideos = action.payload;
    },
  },
});

export const { actions: videosDataActions, reducer: videosDataReducer } =
  videosDataSlice;
