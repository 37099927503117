export const buttonData = [
  {
    text: "App Screens",
    img: "/img/mobileIcon.svg",
    imgActive: "/img/gradientIcon/Screen.svg",
  },
  {
    text: "Store Screenshots",
    img: "/img/maskGroupIcon.svg",
    imgActive: "/img/maskGroupIconActive.svg",
  },
  // {
  //   text: "Ads",
  //   img: "/img/chartIcon.svg",
  //   imgActive: "/img/chartIconActive.svg",
  // },
];

export const webOnboardingData = [
  {
    text: "Web Onboarding screens",
    img: "/img/mobileIcon.svg",
    imgActive: "/img/gradientIcon/Screen.svg",
  },
];
