import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useAPI } from "@/Hooks";

const DeveloperDetailView = () => {

  // skeleton for developer detail page, which will be like homepage but filtered on this developer

  const [id, setId] = useState()
  const [name, setName] = useState()

  const { slug } = useParams();
  const api = useAPI();

  useEffect(() => {
    api.get(`/v1/app-developers/${slug}/`)
    .then((res) => {
      setId(res.data.id)
      setName(res.data.name)
    })
    .catch((err) => {
      console.log(err)
    })
  }, [slug])

  return (
    <>
      <h3>{name}</h3>
    </>
    
  )
};


export { DeveloperDetailView };