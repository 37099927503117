import { forwardRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getInputSearchState } from "@/entities/inputSearch";
import { handleFocus } from "../../libs/const/handleFocus";
import { InputSearchProps } from "../../libs/types/InputSearchProps";
import styles from "./InputSearch.module.scss";

const InputSearch = forwardRef<HTMLDivElement, InputSearchProps>(
  ({ value, onChange, isAnyFilterActive, resetSelectedFilters }, ref) => {
    const { isActiveInput } = useSelector(getInputSearchState);

    const dispatch = useDispatch();

    return (
      <div
        ref={ref}
        className={styles.root}
        onFocus={() => handleFocus(dispatch)}
      >
        <div
          className={`${styles.containerInput} ${
            isActiveInput ? styles.isOpen : ""
          }`}
        >
          <div className={styles.inputBox}>
            <input
              value={value}
              onChange={onChange}
              type="text"
              placeholder="Filter by App Name"
            />
            {isAnyFilterActive && (
            <div onClick={resetSelectedFilters} className={styles.reset}>
              <img src="/img/gradientIcon/Reset.svg" alt="resetImg" loading="lazy" />
              <p>Reset All</p>
            </div>
          )}
          </div>
        </div>
      </div>
    );
  },
);

export default InputSearch;
