import React, { useEffect } from "react";
import { useInView } from "react-intersection-observer";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from 'react-router-dom'
import {
  useGetAppOneQuery,
  useGetAppSlugOneQuery,
  useGetSavedPointByVideoIdQuery,
  useGetVideoLabelsQuery,
  useGetVideoOneQuery,
  useGetVideoStoreScreensByIdQuery,
  useGetVideosScreensByIdQuery,
  useGetVideoOneOBQuery
} from "@/features/auth/api/authAPI";
// entities
import { videoActions } from "@/entities/video/model/slice/videoSlice";
// types
import { State } from "../types/State";
import { Actions } from "../types/Actions";
import { filterAppsActions } from "@/entities/filterApps";
import {
  getModalMenuPhoneState,
  modalMenuPhoneActions,
} from "@/entities/modalMenuPhone";
import { ImageData } from "@/widgets/savePosition/libs/types/VideoControlsProps";
import { useLocation } from "react-router-dom";

import { useCurrentOrganization } from "@/Hooks";
import { skip } from "node:test";
import { AppResponseDataOne } from "@/features/auth/types/auth";


const useModalPhoneItemEffects = (state: State, actions: Actions) => {

  const currentOrganization = useCurrentOrganization();
  const dispatch = useDispatch();
  const { slug } = useParams<{ slug: string }>()
  const { activeIndex } = useSelector(getModalMenuPhoneState);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  let appId = queryParams.get("id") || "";
  const onboardingVideoId = queryParams.get("obv") || "";
  const videoType = queryParams.get("vt") || "";
  const videoId = queryParams.get("av") || "";
  const appStoreData = null;
  const accessToken = localStorage.getItem("access_token");

  const { ref, inView } = useInView({
    threshold: 0,
  });

  useEffect(() => {
    if (videoType) {
      dispatch(videoActions.setVideoType(Number(videoType)));
    }
  }, [videoType])

  const { ref: refLottie, inView: inViewLottie } = useInView({
    threshold: 1,
  });

  const fetchVTT = async (url: string) => {
    try {
      const accessToken = localStorage.getItem("access_token");
      const response = await fetch(url, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const vttText = await response.text();
      return vttText;
    } catch (error) {
      console.error("Error fetching the VTT file:", error);
    }
  };

  interface ImageDataParse extends ImageData {
    startTime: string;
    endTime: string;
  }

  const parseVTT = (vttText: string): ImageData[] => {
    const lines = vttText.split("\n");
    const images: ImageData[] = [];
    let currentImage: Partial<ImageDataParse> = {};

    for (const line of lines) {
      if (line.includes("-->")) {
        const [start, end] = line.split("-->").map((time) => time.trim());
        currentImage.startTime = start;
        currentImage.endTime = end;
      } else if (line.startsWith("{")) {
        const data = JSON.parse(line);
        Object.assign(currentImage, data);
        images.push(currentImage as ImageData);
        currentImage = {};
      }
    }

    return images;
  };

  var appDataOne: AppResponseDataOne | null = null;
  var isLoadingDataOne: boolean = false;

  if (appId) {
    var { data: appDataOne, isLoading: isLoadingDataOne } = useGetAppOneQuery(
      Number(appId),
      {
        skip: !appId
      }
    );
  } else if (slug) {

    var { data: appDataOne, isLoading: isLoadingDataOne } = useGetAppSlugOneQuery(
      slug,
      {
        skip: appId
      }
    );

    
  }
  appId = appDataOne?.id;

  // let { data: appDataOne, isLoading: isLoadingDataOne } = useGetAppOneQuery(
  //   Number(appId),
  // );

  const videoIdToUse = videoId || appDataOne?.latest_appvideo_id;
  const videoIdToUseOB = onboardingVideoId || appDataOne?.latest_appobvideo_id;

  const {
    data: apiDataVideosOne,
    isFetching,
    isLoading,
    isError: isErrorDataVideosOne,
    refetch: refetchDataVideo,
  } = useGetVideoOneQuery(Number(videoIdToUse), {
    refetchOnMountOrArgChange: true,
    refetchOnFocus: true,
    refetchOnReconnect: true,
    skip: !videoIdToUse,
  });


  const {
    data: apiDataVideosOneOB,
    isFetching: isFetchingOB,
    isLoading: isLoadingOB,
    isError: isErrorDataVideosOneOB,
    refetch: refetchDataVideoOB,
  } = useGetVideoOneOBQuery(Number(videoIdToUseOB), {
    refetchOnMountOrArgChange: true,
    refetchOnFocus: true,
    refetchOnReconnect: true,
    skip: !videoIdToUseOB,
  });



  const { data: apiDataPointsByVideo, refetch: refetchSavedPoints } = useGetSavedPointByVideoIdQuery(
    {
      organizationUuid: currentOrganization?.uuid,
      videoId: Number(videoIdToUse)
    },
    {
      skip: !videoIdToUse || !accessToken || !currentOrganization,
    },
  );

  const { data: apiDataVideosLabelsById } = useGetVideoLabelsQuery(
    Number(videoIdToUse),
    {
      skip: !videoIdToUse,
    },
  );

  const { data: apiDataVideosStoreScreens, isLoading: isLoadingDataStore, refetch: refetchDataStore } =
    useGetVideoStoreScreensByIdQuery(
      {
        id: Number(appId), //Number(modalId),
        screenText: "",
      },
      { skip: activeIndex !== 1 || !appId },
    );

  const { data: apiDataVideosScreensById, isLoading: isLoadingDataScreens, refetch: refetchDataScreens } =
    useGetVideosScreensByIdQuery(Number(appId), {
      skip: !appId,
    });
  const [isPlaying, setIsPlaying] = React.useState(false);

  React.useEffect(() => {
    if (appDataOne && videoIdToUse) {
      const vttUrl = `${import.meta.env.VITE_API_BASE_URL}/v1/appvideos/${videoIdToUse}/metadata.vtt/`;
      fetchVTT(vttUrl).then((vttText) => {
        if (vttText) {
          const imageDataArray = parseVTT(vttText);
          actions.setCroppedImages(imageDataArray);
        }
      });
    }
    if (appStoreData) {
      dispatch(
        modalMenuPhoneActions.setActiveIndex(
          JSON.parse(appStoreData).activeIndex,
        ),
      );
    }
  }, [appDataOne]);

  React.useEffect(() => {
    if (
      !inViewLottie &&
      apiDataVideosScreensById &&
      !isFetching &&
      !isPlaying
    ) {
      dispatch(videoActions.setPlaying(true));
      setIsPlaying(true);
    }

    dispatch(filterAppsActions.setDublicateData(true));

    if (state.isScrolling) {
      window.scrollTo(0, 0);
      actions.setIsScrolling(false);
    }

    if (!apiDataVideosOne || !state.videoReady || !state.isTimestampReady) {
      actions.setShowLottie(true);
    }

    if (
      apiDataVideosScreensById?.results &&
      apiDataVideosScreensById.results.some((item) => item.screen === null)
    ) {
      actions.setHasNullItem(true);
    } else {
      actions.setHasNullItem(false);
    }

    applyZoom(state.zoom);
  }, [
    inViewLottie,
    apiDataVideosScreensById?.results,
    state.zoom,
    state.isActivePosition,
    state.showLottie,
    appDataOne,
    apiDataVideosOne,
  ]);

  const applyZoom = (zoom: number) => {
    const photoCardListElement = actions.photoCardListRef.current;
    if (photoCardListElement) {
      photoCardListElement.style.setProperty("zoom", zoom.toString());
      photoCardListElement.style.setProperty("zoom-ms", zoom.toString());
    }
  };

  React.useEffect(() => {
    const timer = setTimeout(() => {
      actions.setShowLottie(false);
    }, 4100);
    return () => clearTimeout(timer);
  }, [state.showLottie]);

  React.useEffect(() => {
    const videoType = sessionStorage.getItem("videoType");
    if (videoType) {
      dispatch(videoActions.setVideoType(Number(videoType)));
      sessionStorage.removeItem("videoType");
    }
  }, [dispatch]);

  if (
    (state.isModalOpenPhotoCard ||
      state.isOpen ||
      !state.videoReady ||
      state.isModalOpen ||
      inViewLottie) &&
    !apiDataVideosOne
  ) {
    document.body.style.overflow = "hidden";
  } else {
    document.body.style.overflow = "auto";
  }


  return {
    ref,
    refLottie,
    inViewLottie,
    inView,
    apiDataVideosOne,
    isErrorDataVideosOne,
    isFetching,
    isLoading,
    appDataOne,
    isLoadingDataOne,
    apiDataVideosStoreScreens,
    apiDataVideosScreensById,
    isLoadingDataScreens,
    isLoadingDataStore,
    appStoreImageId: appStoreData && JSON.parse(appStoreData).imageId,
    appId: appId, //modalId,
    refetchDataVideo,
    apiDataVideosLabelsById,
    apiDataPointsByVideo,
    refetchSavedPoints,
    refetchDataScreens,
    refetchDataStore,
    apiDataVideosOneOB,
    isErrorDataVideosOneOB,
    isFetchingOB,
    isLoadingOB,
    refetchDataVideoOB
  };
};
export default useModalPhoneItemEffects;
