import React from "react";
import { Link } from "react-router-dom";
import styles from "./AuthResetEmail.module.scss";
import { useAPI } from '@/Hooks'
import { toast } from 'react-toastify';

const AuthResetEmail = () => {

  const api = useAPI()
  const [email, setEmail] = React.useState('');

  const handleSubmit = (e) => {
    e.preventDefault()

    const data = { email: email }

    api.post('/v1/auth/reset/', data)
       .then((resp) => {
        toast.success(resp.data.detail);
       })
       .catch((err) => {
        toast.error(err.response?.data?.detail || 'An error occurred');
       })
  }

  return (
    <div className={styles.root}>
      <h3>
        {`Enter the email address associated with your account and we'll send you
        a link to reset your password.`}
      </h3>
      <div className={styles.reset}>
        <form className={styles.form}>
          <input
            className={styles.email}
            type="email"
            placeholder="Enter email address"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <button onClick={handleSubmit} className={styles.formButtonLogin}>Continue</button>
          <div className={styles.routeRegister}>
            <p>Dont have an account?</p>
            <Link className={styles.register} to="/register">
              Sign up
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AuthResetEmail;
