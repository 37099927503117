import React from "react";
import { Outlet } from 'react-router-dom';
import AccountArea from "@/widgets/accountArea/ui/AccountArea/AccountArea";
import styles from "@/widgets/accountArea/ui/AccountArea/AccountArea.module.scss";

const AccountTeamsBaseView = () => {
    return (
        <div className={styles.root}>
            <div className={styles.content2}>
                <AccountArea>
                    <Outlet />
                </AccountArea>
            </div>
        </div>
    );
};

export { AccountTeamsBaseView };
