import React from 'react';
import styles from './toolsModal.module.scss';

interface CloseToolsProps {
  handleCloseTools: () => void;
  text: string;
}

export const CloseTools = ({ handleCloseTools, text }: CloseToolsProps) => {

  return (
    <button className={styles.toolsButton} onClick={handleCloseTools}>
      <img className={styles.toolsIcon} src="/img/gradientIcon/MagicWand.svg" alt="Tools" />
      <span>{text}</span>
      <img className={styles.arrowIcon} src="/img/chevron_icon_new.svg" alt="Tools" draggable={false}/>
    </button>
  );
};