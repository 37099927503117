import React, { useEffect, useRef } from "react";
import Modal from "react-modal";
import { useNavigate, useParams } from "react-router-dom";
import {
  useCheckoutSessionMutation,
  useGetPlansQuery
} from "@/features/auth/api/authAPI";
import { ResponsePlans } from "@/features/auth/types/auth";
import BillingPlanMonth from "@/shared/ui/BillingPlanMonth/BillingPlanMonth";
import BillingPlanTeams from "@/shared/ui/BillingPlanTeams/BillingPlanTeams";
import styles from "./BillingPage.module.scss";
import { useCurrentOrganization } from "@/Hooks";
import Spinner from "@/shared/ui/Spinner/Spinner";

interface BillingPageProps {
  isOpen: boolean;
  onClose: () => void;
}

const BillingPage = ({ isOpen, onClose }: BillingPageProps) => {

  const modalRef = useRef<HTMLDivElement>(null);
  const [referral, setReferral] = React.useState();
  const [referralCouponId, setReferralCouponId] = React.useState();
  const currentOrg = useCurrentOrganization();
  const [activeMenuItem, setActiveMenuItem] = React.useState("individual");
  const [isProcessing, setIsProcessing] = React.useState(false);
  const [pendingPlanData, setPendingPlanData] = React.useState<string | null>(
    null,
  );
  const [sliderValue, setSliderValue] = React.useState<number>(5);
  const { data: apiDataPlans, isLoading } = useGetPlansQuery();
  const navigate = useNavigate();
  const [createCheckoutSession] = useCheckoutSessionMutation();

  const params = useParams();

  console.log(params.uuid);
  

  useEffect(() => {

    if (!modalRef.current) { 
      return
    }

    const handleClickOutside = (e: MouseEvent) => {
      
      const modalContainer = document.querySelector('#BillingModalContainer')

      if (e.target.id !== 'unlockProButton' && !modalContainer?.contains(e.target as Node)) {
        onClose()
      }
    }
    

    document.addEventListener("click", handleClickOutside);
    
    return () => {
      document.removeEventListener("click", handleClickOutside);
    }
  }, [modalRef])

  useEffect(() => {
    window.rewardful("ready", () => {
      setReferral(window.Rewardful.referral);
      if (window.Rewardful.coupon) {
        setReferralCouponId(window.Rewardful.coupon.id);
      }
    });
  }, []);

  const handleSliderChange = (value: number | number[]) => {
    typeof value === "number"
      ? setSliderValue(value)
      : setSliderValue(value[0]);
  };

  const handleMenuItemClick = (item: "individual" | "teams") => {
    setActiveMenuItem(item);
  };

  useEffect(() => {
    //const pendingPlan = localStorage.getItem("pendingPlanPurchase");
    //setPendingPlanData(pendingPlan);

    // if (pendingPlan && currentOrg?.uuid) {
    //   const planData = JSON.parse(pendingPlan);
    //   setActiveMenuItem(planData.menuItem || "individual");
    //   if (planData.qte) {
    //     setSliderValue(planData.qte);
    //   }

    //   const timer = setTimeout(() => {
    //     handleSelectPlan(planData.priceId, planData.qte);
    //     localStorage.removeItem("pendingPlanPurchase");
    //     localStorage.removeItem("showBillingAfterVerification");
    //     setPendingPlanData(null);
    //   }, 3000);

    //   return () => clearTimeout(timer);
    // }
  }, [currentOrg?.uuid]);

  // useEffect(() => {
  //   if (!isOpen) {
  //     localStorage.removeItem("showBillingAfterVerification");
  //   }
  // }, [isOpen]);

  const handleSelectPlan = async (priceId: string, qte: number) => {
    if (!currentOrg?.uuid) {
      // localStorage.setItem(
      //   "pendingPlanPurchase",
      //   JSON.stringify({
      //     priceId,
      //     qte,
      //     menuItem: activeMenuItem,
      //     timestamp: Date.now(),
      //   }),
      // );
      
      //localStorage.setItem("showBillingAfterVerification", "true");
      navigate("/register");
      return;
    }

    setIsProcessing(true);

    try {
      const data = await createCheckoutSession({
        priceId: priceId,
        uuid: params.uuid || currentOrg.uuid,
        qte: qte,
        referralId: referral,
        referralCouponId: referralCouponId,
      }).unwrap();

      if (data.redirect_url) {
        window.location.href = data.redirect_url;
      } else {
        console.error("No redirect URL received from checkout session");
      }
    } catch (error) {
      console.error("Error creating checkout session:", error);
    } finally {
      setIsProcessing(false);
    }
  };

  const planUsersIcon = [
    { img: "/img/imageAppIcon1.jpg", index: 4 },
    { img: "/img/imageAppIcon2.jpg", index: 5 },
    { img: "/img/imageAppIcon3.jpg", index: 6 },
    { img: "/img/imageAppIcon4.jpg", index: 7 },
  ];

  const planFeaturesItem = [
    { text: "Access to all apps videos" },
    { text: "Unlimited collections" },
    { text: "Powerful AI search" },
  ];

  return (
    <div ref={modalRef}>
      <Modal
        isOpen={isOpen}
        onRequestClose={onClose}
        contentLabel="Billing Modal"
        className={styles.modal}
        overlayClassName={styles.overlay}
        shouldCloseOnOverlayClick={false}
      >
        <div className={styles.root} id="BillingModalContainer">
          <div className={styles.header}>
            <div className={styles.titleContainer}>
              <h1 className={styles.title}>Upgrade to Pro Today</h1>
              <p className={styles.subtitle}>
                Get unlimited access to all features and screens.
                <br />
                No strings - cancel anytime
              </p>
            </div>
            <div className={styles.planUsers}>
              <div className={styles.planUsersImg}>
                {planUsersIcon.map((item, index) => {
                  const zIndex = planUsersIcon.length - item.index;
                  let marginLeft = 0;

                  if (index === planUsersIcon.length - 2) {
                    marginLeft = 8;
                  } else if (index === planUsersIcon.length - 3) {
                    marginLeft = 16;
                  } else if (index === planUsersIcon.length - 4) {
                    marginLeft = 24;
                  }

                  return (
                    <div
                      className={styles.planUsersItem}
                      key={item.index}
                      style={{
                        zIndex: zIndex - 1,
                        position: "relative",
                        left: `${marginLeft}px`,
                      }}
                    >
                      <img src={item.img} alt="unlockProIcon" />
                    </div>
                  );
                })}
              </div>
              <div className={styles.planUsersText}>
                <h3>1500+Apps</h3>
                <p>20 new every week</p>
              </div>
            </div>
            <div className={styles.headerMenu}>
              <div
                className={`${styles.headerMenuItem} ${activeMenuItem === "individual" ? styles.headerMenuItemActive : ""}`}
                onClick={() => handleMenuItemClick("individual")}
              >
                <img
                  src={
                    activeMenuItem === "individual"
                      ? "/img/whiteIcon/Profile.svg"
                      : "/img/gradientIcon/Profile.svg"
                  }
                  alt="userImg"
                />
                <p>Individual</p>
              </div>
              <div
                className={`${styles.headerMenuItem} ${activeMenuItem === "teams" ? styles.headerMenuItemActive : ""}`}
                onClick={() => handleMenuItemClick("teams")}
              >
                <img
                  src={
                    activeMenuItem === "teams"
                      ? "/img/whiteIcon/Team2.svg"
                      : "/img/gradientIcon/Team2.svg"
                  }
                  alt="teamImg"
                />
                <p>Teams</p>
              </div>
            </div>
          </div>
          <div className={styles.plansContainer}>
            {isLoading ? (
              <div className={styles.spinnerContainer}>
                <Spinner />
              </div>
            ) : (
              apiDataPlans &&
              (activeMenuItem === "individual" ? (
                (apiDataPlans as ResponsePlans[]).map((item) => (
                  <React.Fragment key={item.id}>
                    <BillingPlanMonth
                      planFeaturesItem={planFeaturesItem}
                      handleSelectPlan={handleSelectPlan}
                      isProcessing={isProcessing}
                      pendingPlanData={pendingPlanData}
                    />
                  </React.Fragment>
                ))
              ) : (
                <BillingPlanTeams
                  sliderValue={sliderValue}
                  handleSliderChange={handleSliderChange}
                  handleSelectPlan={handleSelectPlan}
                  pendingPlanData={pendingPlanData}
                />
              ))
            )}
          </div>
          {/* <div onClick={onClose} className={styles.limitedFeatures}>
            continue with limited features
          </div> */}
        </div>
      </Modal>
    </div>
  );
};

export default BillingPage;
