// redux
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
// types
import { modalDataState } from "../types/modalDataTypes";

const initialState: modalDataState = {
  dataModalApp: {
    id: 0,
    name: "",
    shortname: "",
    avs: [],
    icon: "",
  },
  timestamp: null,
};

const modalDataSlice = createSlice({
  name: "modalData",
  initialState,
  reducers: {
    setModalData: (state, action) => {
      state.dataModalApp = action.payload;
    },
    setTimestamp: (state, action: PayloadAction<number>) => {
      state.timestamp = action.payload;
    },
  },
});

export const { actions: modalDataActions, reducer: modalDataReducer } =
  modalDataSlice;
