import React, { useState, useRef, useEffect } from "react";
import { FixedSizeList as List } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import Spinner from "@/shared/ui/Spinner/Spinner";
import styles from "./SuggestAppAutocomplete.module.scss";
import { useSearchAnythingQuery } from "@/features/auth/api/authAPI";
import useDebounce from "@/Hooks/useDebounce";

interface SuggestAppAutocompleteProps {
  value: string;
  onChange: (value: string) => void;
}

interface SearchResponse {
  hits: Array<{
    id: string;
    type: string;
    slug: string;
    name: string;
    icon: string | null;
    score: number;
  }>;
}

const ITEM_HEIGHT = 56; // Height of each item (including padding)
const MAX_ITEMS = 4; // Maximum number of items to show at once

const SuggestAppAutocomplete = ({
  value,
  onChange,
}: SuggestAppAutocompleteProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const wrapperRef = useRef<HTMLDivElement>(null);

  // Use debounce hook with 500ms delay and trailing option
  const [debouncedValue] = useDebounce(value, 500, {
    trailing: true,
    leading: false,
  });

  const shouldSearch = debouncedValue.length >= 2;

  const { data, isFetching } = useSearchAnythingQuery<SearchResponse>(
    { q: debouncedValue, i: "apps" },
    {
      skip: !shouldSearch,
      refetchOnMountOrArgChange: true,
    },
  );

  // Handle click outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Open dropdown when we have debounced results
  useEffect(() => {
    if (shouldSearch) {
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  }, [shouldSearch]);

  const handleOptionSelect = (selectedValue: string) => {
    onChange(selectedValue);
    setIsOpen(false);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value);
    if (e.target.value.length >= 2) {
      setIsOpen(true);
    }
  };

  const Row = ({
    index,
    style,
  }: {
    index: number;
    style: React.CSSProperties;
  }) => {
    const app = data!.hits[index];
    return (
      <div
        className={styles.autocompleteItem}
        style={style}
        onClick={() => handleOptionSelect(app.name)}
      >
        <img
          src={app.icon || "/img/default-app-icon.png"}
          alt={app.name}
          className={styles.appIcon}
        />
        <span>{app.name}</span>
      </div>
    );
  };

  const showDropdown = shouldSearch && isOpen;
  const showNoResults = shouldSearch && !isFetching && data?.hits?.length === 0;

  return (
    <div className={styles.autocompleteWrapper} ref={wrapperRef}>
      <input
        className={styles.autocompleteInput}
        type="text"
        placeholder="Type an app name"
        value={value}
        onChange={handleInputChange}
      />
      {showDropdown && (
        <div className={styles.autocompleteDropdown}>
          {isFetching ? (
            <div className={styles.autocompleteDropdownLoading}>
              <Spinner />
            </div>
          ) : data?.hits?.length ? (
            <>
              <div className={styles.existingAppsLabel}>
                These apps already exist in our database
              </div>
              <div
                style={{
                  height: Math.min(data.hits.length, MAX_ITEMS) * ITEM_HEIGHT,
                }}
              >
                <AutoSizer>
                  {({ width }) => (
                    <List
                      height={
                        Math.min(data.hits.length, MAX_ITEMS) * ITEM_HEIGHT
                      }
                      itemCount={data.hits.length}
                      itemSize={ITEM_HEIGHT}
                      width={width}
                    >
                      {Row}
                    </List>
                  )}
                </AutoSizer>
              </div>
            </>
          ) : showNoResults ? (
            <div className={styles.noResults}>No results found</div>
          ) : null}
        </div>
      )}
    </div>
  );
};

export default SuggestAppAutocomplete;
