import React, { useState } from "react";
import { NavLink, useNavigate } from 'react-router-dom'
import { IAppSavedGroup } from "@/features/auth/types/auth";
import styles from "./ModalSaveModeGroups.module.scss";

interface ModalSaveModeGroupsProps {
  selectGroupActive: boolean;
  selectedGroups: Set<number>;
  item: IAppSavedGroup;
  isLastGroup: boolean;
  handleGroupSelect: (id: number) => void;
  setFilterValueGroup: React.Dispatch<React.SetStateAction<string>>;
  setGroupIdPoint: React.Dispatch<React.SetStateAction<number | null>>;
  countScreens: number;
  imageDimensions: {
    width: number;
    height: number;
  }[];
}

const ModalSaveModeGroups = React.forwardRef<
  HTMLDivElement,
  ModalSaveModeGroupsProps
>(
  (
    {
      selectGroupActive,
      selectedGroups,
      item,
      isLastGroup,
      handleGroupSelect,
      setFilterValueGroup,
      setGroupIdPoint,
      countScreens,
      imageDimensions,
    },
    ref,
  ) => {
    const navigate = useNavigate();

    const handleClick = () => {
      navigate(`/saved-library/groups/${item.id}/`);
      setFilterValueGroup(item.name);
    };

    const handleMouseEnter = () => {
      setGroupIdPoint(item.id);
      setFilterValueGroup(item.name);
    };

    const renderPhotos = (points: any[], isSavedPoints: boolean) => {
      return points.slice(0, 4).map((point, index) => {
        const topValue = (3 - index) * 8;
        const zIndex = 4 - index;
        const dimension = imageDimensions[index] || { width: 100, height: 100 };
        const src = isSavedPoints ? point.screen : point.store_screen.image;
        const rotation = index * 2;

        return (
          <div
            key={point.id}
            onClick={handleClick}
            onMouseEnter={handleMouseEnter}
            className={`${styles.groupPhotoItem} ${selectGroupActive && selectedGroups.has(Number(item.id))
                ? styles.groupPhotoItemActive
                : ""
              }`}
            style={{
              top: `${topValue}px`,
              zIndex,
              width: `${dimension.width}px`,
              height: `${dimension.height}px`,
              transform: `translateX(-50%) rotate(${rotation}deg)`
            }}
          >
            <img
              src={src}
              alt="groupPhotoImg"
              loading="lazy"
              style={{ width: "100%", height: "100%" }}
            />
          </div>
        );
      });
    };

    const renderEmptyPhotos = (count: number) => {
      return [...Array(Math.max(0, 4 - count))].map((_, index) => {
        const emptyClasses = [
          "groupPhotoEmptyOne",
          "groupPhotoEmptyTwo",
          "groupPhotoEmptyThree",
          "groupPhotoEmptyFour",
        ];
        const className = styles[emptyClasses[3 - index]];
        return (
          <div
            key={`empty-${index}`}
            className={`${className} ${styles.groupPhotoEmpty}`}
          />
        );
      });
    };

    const [hoveredScreenId, setHoveredScreenId] = useState<number | null>(null);

    return (
      <div
        className={`${styles.root} ${selectGroupActive && selectedGroups.has(Number(item.id)) ? styles.rootActive : ""}`}
        ref={isLastGroup ? ref : null}
        onMouseEnter={() => setHoveredScreenId(item.id)}
        onMouseLeave={() => setHoveredScreenId(null)}
      >
        <div

          // onClick={handleClick}
          onMouseEnter={handleMouseEnter}
          className={styles.groupContainerTitle}
        >
          <NavLink to={`/saved-library/groups/${item.id}/`}>

            <div
              onClick={(event) => {
                event.preventDefault()
                event.stopPropagation();
                if (selectGroupActive) {
                  handleGroupSelect(item.id);
                }
              }}
              className={`${styles.hoverElement} ${selectGroupActive && selectedGroups.has(Number(item.id)) ? styles.hoverElementActive : ""}`}
            >

              {selectGroupActive && selectedGroups.has(Number(item.id)) && (
                <img
                  src="/img/whiteIcon/Check.svg"
                  alt="checkCircleImg"
                />
              )}
            </div>

            <h1>{item.name}</h1>
            <p>{countScreens} Screens</p>
          </NavLink>
        </div>
        <div className={styles.groupContainerPhotos}
        >
          {countScreens > 0 ? (
            <div className={styles.groupPhoto}>
              {renderPhotos(item.saved_points, true)}
              {renderPhotos(item.saved_store_screens, false)}
              {renderEmptyPhotos(
                item.saved_points.length + item.saved_store_screens.length,
              )}
            </div>
          ) : (
            <div
              onClick={handleClick}
              onMouseEnter={handleMouseEnter}
              className={styles.groupPhoto}
            >
              <div
                className={`${styles.groupPhotoEmptyOne} ${styles.groupPhotoEmpty}`}
              />
              <div
                className={`${styles.groupPhotoEmptyTwo} ${styles.groupPhotoEmpty}`}
              />
              <div
                className={`${styles.groupPhotoEmptyThree} ${styles.groupPhotoEmpty}`}
              />
              <div
                className={`${styles.groupPhotoEmptyFour} ${styles.groupPhotoEmpty}`}
              />
              <div className={styles.groupPhotoContent}>
                <img src="/img/groupEmptyIcon.svg" alt="groupEmptyImg" />
                <p>no screen added</p>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  },
);

export default ModalSaveModeGroups;
