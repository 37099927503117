import React from "react";
import { IDeletingItems } from "@/widgets/hiddenApps/ui/libs/types";
import { IHiddenApp } from "@/features/auth/types/auth";
import styles from "./HiddenApp.module.scss";

interface HiddenAppsProps {
  handleOpenVideoPage: (
    timestamp: number,
    appId: number,
    slugText: string,
  ) => void;
  handleDeleteApp: (id: number) => Promise<void>;
  item: IHiddenApp;
  deletingItems: IDeletingItems;
}

const HiddenApp: React.FC<HiddenAppsProps> = ({
  handleOpenVideoPage,
  handleDeleteApp,
  item,
  deletingItems,
}) => {
  return (
    <div className={styles.root}>
      <div
        onClick={() => handleOpenVideoPage(item.timestamp || 0, item.app.slug, "hide/starred", 1, item.app.id, undefined, undefined)}
        className={styles.hiddenApp}
      >
        <div className={styles.hiddenAppIcon}>
          <img src={item.app.icon} alt="appIconImg" />
        </div>
        <div className={styles.hiddenAppTitle}>
          <h1>{item.app.name}</h1>
          <p>{item.app.shortname}</p>
        </div>
      </div>
      <button
        onClick={() => handleDeleteApp(item.id)}
        className={`${styles.hiddenAppImg}`}
        disabled={Object.values(deletingItems).some((item) => item.isDeleting)}
      >
        <img
          src={
            deletingItems &&
            deletingItems[item.id] &&
            deletingItems[item.id].iconChanged
              ? "/img/HideStarPage/HiddenGrey.svg"
              : "/img/HideStarPage/HiddenGrey.svg"
          }
          alt="eyeOffHiddenImg"
        />
      </button>
    </div>
  );
};

export default HiddenApp;
