

export const ACTIVE_VIEW_HOME = 'home'
export const ACTIVE_VIEW_SEARCH = 'search'
export const SEARCH_MODE_OCR  = 'text'  //searchModeOCR
export const SEARCH_MODE_VECTOR = 'elements' // searchModeVector
export const SEARCH_RESULT_MODE_CONTEXT = 'ctx' //resultModeContext
export const SEARCH_RESULT_MODE_NO_CONTEXT = 'noctx' // resultModeNoContext
export const ACTIVE_VIEW_ANIMATION = 'animation-explorer'
export const ACTIVE_VIEW_WEB_ONBOARDING = 'web-onboardings'
export const ACTIVE_VIEW_SAVED_MODE = 'saved-mode'