// redux
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
// types
import { idModalState } from "../types/idModalTypes";

const initialState: idModalState = {
  openModalId: null,
};

const idModalSlice = createSlice({
  name: "modalAppIndex",
  initialState,
  reducers: {
    setModalAppIndex: (state, action: PayloadAction<null | number>) => {
      state.openModalId = action.payload;
    },
  },
});

export const { actions: idModalActions, reducer: idModalReducer } =
  idModalSlice;
