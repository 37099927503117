import { IAppSavedGroup } from "@/features/auth/types/auth";
import styles from "./ModalSaveModeMore.module.scss";
import React from "react";

interface ModalSaveModeMoreProps {
  matchingGroups: IAppSavedGroup[];
}

const ModalSaveModeMore: React.FC<ModalSaveModeMoreProps> = ({
  matchingGroups,
}) => {

  return (
    <div className={styles.root}>
      {matchingGroups?.map((group, groupIndex) => (
        <div key={groupIndex} className={styles.photoGroupItem}>
          <p>{group.name}</p>
        </div>
      ))}
    </div>
  );
};

export default ModalSaveModeMore;
