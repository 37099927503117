import { useEffect, useState, useRef } from "react";
import { useOutletContext } from 'react-router-dom'
import StarredApps from "@/widgets/starredApps/ui/StarredApps/StarredApps";
import styles from "@/widgets/modalHirStar/ui/ModalHirStar/ModalHirStar.module.scss";
import { useAPI } from '@/Hooks'

const StarredAppsView = () => {

  const { zoom, sortkey } = useOutletContext()
  const api = useAPI()
  const [savedApps, setSavedApps] = useState([])
  const appsZoomRef = useRef<HTMLDivElement>(null);

  const fetchSavedApps = () => {

    const params = { order: sortkey }
    api.get('/v1/saved-apps/', { params: params })
       .then((resp) => setSavedApps(resp.data.results))
  }

  useEffect(() => {
    fetchSavedApps()
  }, [sortkey])

  const applyZoom = (zoom: number) => {
    const photoCardListElement = appsZoomRef.current;

    if (photoCardListElement) {
      photoCardListElement.style.setProperty("--zoom-level", zoom.toString());
    }
  };

  useEffect(() => {
    applyZoom(zoom);
  }, [zoom]);

  return (
    <div className={styles.appsContainer} ref={appsZoomRef}>
      {savedApps && <StarredApps setSavedApps={setSavedApps} apiDataApps={savedApps} />}
    </div>
  );
};

export { StarredAppsView };
