// redux
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
// feature
import { AppResponseData, IAppData } from "@/features/auth/types/auth";
// types
import { FilterAppsDataState } from "../types/filterAppsDataTypes";

const initialState: FilterAppsDataState = {
  filterAppData: {
    count: 0,
    next: "",
    previous: "",
    results: [],
  },
  filterAppDataSorted: [],
  filterAppDataSortedAll: [],
  savedApps: [],
  appDataCount: 0,
  rootActiveView: 'home',
  isScreensLoadingHome: false,
  isScreensLoadingStore: false,
};

const filterAppsDataSlice = createSlice({
  name: "filterAppsData",
  initialState,
  reducers: {
    setFilterAppsData: (state, action: PayloadAction<AppResponseData>) => {
      state.filterAppData = action.payload;
    },
    pushFilterAppsData: (state, action: PayloadAction<AppResponseData>) => {
      state.filterAppData.results = [
        ...state.filterAppData.results,
        ...action.payload.results,
      ];
    },
    setFilterAppDataSorted: (state, action: PayloadAction<IAppData[]>) => {
      state.filterAppDataSorted = [
        ...state.filterAppDataSorted,
        ...action.payload,
      ];
    },
    resetFilterAppDataSorted: (state) => {
      state.filterAppDataSorted = initialState.filterAppDataSorted;
    },
    resetFilterAppData: (state) => {
      state.filterAppData = initialState.filterAppData;
    },
    setFilterAppDataSortedAll: (state, action: PayloadAction<IAppData[]>) => {
      state.filterAppDataSortedAll = [
        ...state.filterAppDataSortedAll,
        ...action.payload,
      ];
    },
    resetFilterAppDataSortedAll: (state) => {
      state.filterAppDataSortedAll = initialState.filterAppDataSortedAll;
    },
    removeAppFromData: (state, action: PayloadAction<number>) => {
      state.filterAppDataSorted = state.filterAppDataSorted.filter(
        (app) => app.id !== action.payload
      );
    },
    setSavedApps: (state, action: PayloadAction<IAppData[]>) => {
      state.savedApps = action.payload;
    },

    setAppDataCount: (state, action: PayloadAction<number>) => {
      state.appDataCount = action.payload;
    },
    setIsScreensLoadingHome: (state, action: PayloadAction<boolean>) => {
      state.isScreensLoadingHome = action.payload;
    },
    setIsScreensLoadingStore: (state, action: PayloadAction<boolean>) => {
      state.isScreensLoadingStore = action.payload;
    },
    setRootActiveView: (state, action: PayloadAction<string>) => {
      state.rootActiveView = action.payload;
    },
  },
});

export const {
  actions: filterAppsDataActions,
  reducer: filterAppsDataReducer,
} = filterAppsDataSlice;
