// react
import React from "react";
// redux
import { useDispatch, useSelector } from "react-redux";
// entities
import { getFilterAppsState } from "@/entities/filterApps";
// shared
import CategoryModalItem from "@/shared/ui/CategoryModalItem/CategoryModalItem";
// const
import { transformText } from "../../libs/const/transoformText";
// hooks
import useHandleClick from "../../libs/hook/useHandleClick";
// types
import { ICategoryModalProps } from "../../libs/types/ICategoryModalProps";
// styles
import styles from "./CategoryModal.module.scss";
import StoredCount from "@/shared/ui/storedcount/StoredCound";
import { handleFocus } from "@/widgets/inputSearch/libs/const/handleFocus";

import { ACTIVE_VIEW_HOME } from '@/Views/constants'


const CategoryModal: React.FC<ICategoryModalProps> = ({
  appDataCategories,
  onSave,
  isFetchingFilter,
  dataCategories,
  setDataCategories,
  selectedCategoryIds,
  setSelectedCategoryIds,
  rootActiveView
}) => {
  // selectors
  const { activeModalIndexCategories } = useSelector(getFilterAppsState);
  // custom hook
  const { handleItemClick, handleReset } = useHandleClick(
    appDataCategories,
    onSave,
    isFetchingFilter,
    dataCategories,
    setDataCategories,
    selectedCategoryIds,
    setSelectedCategoryIds,
  );
  const dispatch = useDispatch();

  const [isResetVisible, setIsResetVisible] = React.useState(false);

  React.useEffect(() => {
    setIsResetVisible(activeModalIndexCategories.length > 0);
  }, [activeModalIndexCategories]);

  return (
    <div className={styles.root}>



      <div className={styles.itemContainer}>
        {appDataCategories!.results.map((item, index) => {
          const isActive = activeModalIndexCategories.includes(index);
          return (
            <CategoryModalItem
              key={item.id}
              text={transformText(item.name)}
              onItemClick={() => handleItemClick(index)}
              isActive={isActive}
            />
          );
        })}
      </div>

      {rootActiveView === ACTIVE_VIEW_HOME &&
        <div style={{ display: "flex", justifyContent: "start", marginBottom: "20px" }}>
          <StoredCount onFocus={() => handleFocus(dispatch)} />
        </div>
      }

      <div style={{ textAlign: "center" }}>
        {isResetVisible && (
          <div onClick={handleReset} className={styles.reset}>
            <img src="/img/blackIcon/Close.svg" alt="resetImg" loading="lazy" />
            <p>Reset Category</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default CategoryModal;
