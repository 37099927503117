// react
import React from "react";
// react-redux
import { useSelector } from "react-redux";
// entities
import { getFilterState } from "@/entities/filter";
// styles
import styles from "./WrapperModal.module.scss";
import { Portal } from "../Portal/Portal";

interface WrapperModalProps {
  children: React.ReactNode;
}

const WrapperModal: React.FC<WrapperModalProps> = ({ children }) => {
  // selectors
  const { isActive } = useSelector(getFilterState);

  return isActive ? (
    <Portal element={document.getElementById("modal")!}>
      <div className={styles.root}>{children}</div>
    </Portal>
  ) : null;
};

export default WrapperModal;
