// redux
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
// types
import { ModalFiltersState } from "../types/modalFilterTypes";

const initialState: ModalFiltersState = {
  isCategoryOpen: false,
  isHiddenOpen: false,
  isMontlyInstallsOpen: false,
  isMontlyRevenueOpen: false,
  isRevInstallRateOpen: false,
  isOnboardingStepsOpen: false,
  isPaywallOpen: false,
  isLabelledOpen: false,
  isContainsWords: false,
  isReleaseDateOpen: false,
  isUpdateDateOpen: false,
  isShowIconInstalls: true,
  isShowIconCategories: true,
  isShowIconRevenue: true,
  isShowIconOnboarding: true,
  isShowIconPaywall: true,
  isShowIconContains: true,
  isFlowsOpen: false,
  isShowIconFlows: true,
};

const modalFiltersSlice = createSlice({
  name: "modalFilters",
  initialState,
  reducers: {
    openCategoryModal: (state) => {
      state.isCategoryOpen = true;
    },
    closeCategoryModal: (state) => {
      state.isCategoryOpen = false;
    },
    toggleCategoryModal: (state) => {
      state.isCategoryOpen = !state.isCategoryOpen;
    },
    openMontlyInstallsModal: (state) => {
      state.isMontlyInstallsOpen = true;
    },
    closeMontlyInstallsModal: (state) => {
      state.isMontlyInstallsOpen = false;
    },
    toggleMontlyInstallsModal: (state) => {
      state.isMontlyInstallsOpen = !state.isMontlyInstallsOpen;
    },
    openRevInstallRateModal: (state) => {
      state.isRevInstallRateOpen = true;
    },
    closeRevInstallRateModal: (state) => {
      state.isRevInstallRateOpen = false;
    },
    toggleRevInstallRateModal: (state) => {
      state.isRevInstallRateOpen = !state.isRevInstallRateOpen;
    },
    openMontlyRevenueModal: (state) => {
      state.isMontlyRevenueOpen = true;
    },
    closeMontlyRevenueModal: (state) => {
      state.isMontlyRevenueOpen = false;
    },
    toggleMontlyRevenueModal: (state) => {
      state.isMontlyRevenueOpen = !state.isMontlyRevenueOpen;
    },
    openOnboardingStepsModal: (state) => {
      state.isOnboardingStepsOpen = true;
    },
    closeOnboardingStepsModal: (state) => {
      state.isOnboardingStepsOpen = false;
    },
    toggleOnboardingStepsModal: (state) => {
      state.isOnboardingStepsOpen = !state.isOnboardingStepsOpen;
    },
    openPaywallModal: (state) => {
      state.isPaywallOpen = true;
    },
    closePaywallModal: (state) => {
      state.isPaywallOpen = false;
    },
    togglePaywallModal: (state) => {
      state.isPaywallOpen = !state.isPaywallOpen;
    },
    openContainsWordsModal: (state) => {
      state.isContainsWords = true;
    },
    closeContainsWordsModal: (state) => {
      state.isContainsWords = false;
    },
    toggleContainsWordsModal: (state) => {
      state.isContainsWords = !state.isContainsWords;
    },
    openLabelledModal: (state) => {
      state.isLabelledOpen = true;
    },
    closeLabelledModal: (state) => {
      state.isLabelledOpen = false;
    },
    toggleLabelledModal: (state) => {
      state.isLabelledOpen = !state.isLabelledOpen;
    },
    setIsReleaseDateOpen: (state, action: PayloadAction<boolean>) => {
      state.isReleaseDateOpen = action.payload;
    },
    setIsHiddenOpen: (state, action: PayloadAction<boolean>) => {
      state.isHiddenOpen = action.payload;
    },
    setIsUpdateDateOpen: (state, action: PayloadAction<boolean>) => {
      state.isUpdateDateOpen = action.payload;
    },
    setIsShowIconInstalls: (state, action: PayloadAction<boolean>) => {
      state.isShowIconInstalls = action.payload;
    },
    setIsShowIconCategories: (state, action: PayloadAction<boolean>) => {
      state.isShowIconCategories = action.payload;
    },
    setIsShowIconRevenue: (state, action: PayloadAction<boolean>) => {
      state.isShowIconRevenue = action.payload;
    },
    setIsShowIconOnboarding: (state, action: PayloadAction<boolean>) => {
      state.isShowIconOnboarding = action.payload;
    },
    setIsShowIconPaywall: (state, action: PayloadAction<boolean>) => {
      state.isShowIconPaywall = action.payload;
    },
    setIsShowIconContains: (state, action: PayloadAction<boolean>) => {
      state.isShowIconContains = action.payload;
    },
    openFlowsModal: (state) => {
      state.isFlowsOpen = true;
    },
    closeFlowsModal: (state) => {
      state.isFlowsOpen = false;
    },
    toggleFlowsModal: (state) => {
      state.isFlowsOpen = !state.isFlowsOpen;
    },
    setIsShowIconFlows: (state, action: PayloadAction<boolean>) => {
      state.isShowIconFlows = action.payload;
    },
  },
});

export const { actions: modalFiltersActions, reducer: modalFiltersReducer } =
  modalFiltersSlice;
