import styles from "./AboutUs.module.scss";

const AboutUsView = () => {

  return (
    <div className={styles.aboutUs}>
        <h1 className={styles.aboutUs__title}>About Us</h1>
        <p>At ScreensDesign, we are a team of dedicated mobile app developers who understand the challenges of staying current in a rapidly evolving industry. We created ScreensDesign.com out of necessity – born from our own experience of constantly downloading apps to find the latest trends in design and optimize user funnels.</p>
        <p>Our platform is the resource we always wished we had – a comprehensive, easily navigable showcase of the best in mobile app design. ScreensDesign provides instant access to a vast library of app screens, from onboarding flows to paywalls, all in one place.</p>
        <p>We believe ScreensDesign is the perfect tool for anyone working with mobile apps. Whether you're an app owner, a marketing professional, a designer, a developer, or a product manager, our platform offers invaluable insights and inspiration to enhance your work in the mobile app ecosystem.</p>
        <p>Our mission is to inspire creativity, drive innovation, and save valuable time for professionals across the mobile app industry. By offering easy access to the latest design trends and user experience patterns, we aim to help elevate the quality of apps across the board.</p>
        <p>We're committed to continually updating our platform with fresh content, new features, and innovative tools to support your creative process. ScreensDesign isn't just a website – it's our contribution to fostering a more collaborative, inspired, and efficient app development community.</p>
    </div>
  );
};

export { AboutUsView }