import React, { useEffect, useState } from 'react'
import { Outlet, Navigate } from 'react-router-dom'

import { useAccountTeams } from '@/Hooks'
import { useMe } from '@/Hooks/useMe'



const AccountTeamsRootView = () => {
    const { setAccountTeamsActive } = useAccountTeams()
    const { me, loading } = useMe()
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);

    useEffect(() => {
        setAccountTeamsActive(true)
        return () => {
            setAccountTeamsActive(false)
        }
    }, [])

    if (loading) {
        return null 
    }
    if (!me) {
        return <Navigate to="/" replace />
    }

    return (
        <SidebarContext.Provider value={{ isSidebarOpen, setIsSidebarOpen }}>
            <Outlet />
        </SidebarContext.Provider>
    )
}

export const SidebarContext = React.createContext<{
    isSidebarOpen: boolean;
    setIsSidebarOpen: (open: boolean) => void;
}>({
    isSidebarOpen: false,
    setIsSidebarOpen: () => {},
});

export { AccountTeamsRootView }
