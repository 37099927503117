// react
import React from "react";
// redux
import { useDispatch, useSelector } from "react-redux";
// styles
import styles from "./PaywallType.module.scss";
import { filterAppsDataActions } from "@/entities/filterAppsData";
import { filterAppsActions, getFilterAppsState } from "@/entities/filterApps";
import { filterActions } from "@/entities/filter";
import { AppPaywall } from "@/features/auth/types/auth";
import { counterActions } from "@/entities/counter";
import { handleFocus } from "@/widgets/inputSearch/libs/const/handleFocus";
import StoredCount from "@/shared/ui/storedcount/StoredCound";
import { Switch } from "@mui/material";
import { styled } from '@mui/material/styles';

import { ACTIVE_VIEW_HOME } from '@/Views/constants'

const CustomSwitch = styled(Switch)(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 3,
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        background: 'linear-gradient(90deg, rgb(35 97 217), rgb(94 36 217) 100%)',
        opacity: 1,
      },
    },
  },
  '& .MuiSwitch-thumb': {
    width: 20,
    height: 20,
    backgroundColor: '#fff',
  },
  '& .MuiSwitch-track': {
    borderRadius: 26,
    backgroundColor: '#grey',
    opacity: 0.3,
  },
}));

export interface PaywallTypeProps {
  onSave: (value: string) => void;
  dataPaywall: AppPaywall[] | undefined;
  isFetchingFilter: boolean;
  rootActiveView: string;
  activeButton: ("hidden" | "starred" | "paywall")[] | null;
  setActiveButton: (state: ("hidden" | "starred" | "paywall")[]) => void;
}

const PaywallType: React.FC<PaywallTypeProps> = ({
  onSave,
  dataPaywall,
  isFetchingFilter,
  rootActiveView,
  activeButton,
  setActiveButton,
}) => {
  // selectors
  const { activeModalIndexPaywall, isActivePaywall } =
    useSelector(getFilterAppsState);
  // dispatch
  const dispatch = useDispatch();

  const [isResetVisible, setIsResetVisible] = React.useState(false);

  const isExplorePage = window.location.pathname === "/";

  React.useEffect(() => {
    setIsResetVisible(activeModalIndexPaywall !== null);
  }, [activeModalIndexPaywall]);

  const handleItemClick = (paywall: string, index: number) => {
    const isCurrentlyActive = activeModalIndexPaywall === index;

    if (isCurrentlyActive && !isFetchingFilter) {
      dispatch(filterAppsActions.setActiveModalIndexPaywall(null));
      dispatch(filterAppsDataActions.resetFilterAppDataSorted());
      dispatch(filterAppsDataActions.resetFilterAppData());
      dispatch(filterAppsActions.resetPageFilter());
      dispatch(filterActions.deactivatePaywall());
      dispatch(filterAppsActions.setPaywall(""));
      dispatch(counterActions.removeFromArray("Paywall Type"));
      dispatch(filterAppsActions.deactivePaywall());
      onSave("");
    } else {
      dispatch(filterAppsActions.setActiveModalIndexPaywall(index));
      if (activeModalIndexPaywall !== null) {
        dispatch(filterActions.deactivateCategory());
      }

      dispatch(filterAppsDataActions.resetFilterAppDataSorted());
      dispatch(filterAppsDataActions.resetFilterAppDataSortedAll());
      dispatch(filterAppsActions.resetPageFilter());
      dispatch(filterAppsActions.resetPageFilterSidebar());
      dispatch(filterAppsActions.setActiveModalIndexPaywall(index));
      dispatch(filterAppsActions.setPaywall(dataPaywall![index].name));
      dispatch(filterActions.activatePaywall());
      if (!isActivePaywall) {
        dispatch(counterActions.addToArray("Paywall Type"));
        dispatch(filterAppsActions.activePaywall());
      }
      onSave(dataPaywall![index].name);
      onSave(paywall);
    }
  };

  const handleReset = () => {
    if (activeModalIndexPaywall !== null && !isFetchingFilter) {
      dispatch(filterAppsActions.setActiveModalIndexPaywall(null));
      dispatch(filterAppsDataActions.resetFilterAppDataSorted());
      dispatch(filterAppsDataActions.resetFilterAppData());
      dispatch(filterAppsActions.resetPageFilter());
      dispatch(filterActions.deactivatePaywall());
      dispatch(filterAppsActions.setPaywall(""));
      onSave("");

      if (isActivePaywall) {
        dispatch(counterActions.removeFromArray("Paywall Type"));
        dispatch(filterAppsActions.deactivePaywall());
      }
    }
  };

  const handlePaywallClick = () => {
    const currentButtons = activeButton || [];
    const isActive = currentButtons.includes("paywall");

    if (!isActive) {
      setActiveButton([...currentButtons, "paywall"]);
      dispatch(filterAppsActions.setIsPaywallOnly(true));
      dispatch(counterActions.addToArray("Paywall"));
    } else {
      setActiveButton(currentButtons.filter(btn => btn !== "paywall"));
      dispatch(filterAppsActions.setIsPaywallOnly(false));
      dispatch(counterActions.removeFromArray("Paywall"));
    }

    dispatch(filterAppsDataActions.resetFilterAppDataSorted());
    dispatch(filterAppsDataActions.resetFilterAppDataSortedAll());
    dispatch(filterAppsDataActions.resetFilterAppData());
    dispatch(filterAppsActions.resetPageFilter());
  };

  return (
    <div className={styles.root}>
      <div className={styles.itemContainer}>
        {dataPaywall!.map((paywall, index) => (
          <div
            key={index}
            className={`${styles.item} ${index === activeModalIndexPaywall ? styles.itemActive : ""
              }`}
            onClick={() => handleItemClick(paywall.name, index)}
          >
            <p>{paywall.name}</p>
          </div>
        ))}
        {isExplorePage && (
          <div className={styles.switchContainer}>
            <div className={styles.items}>
              <img
                src="/img/blackIcon/PaywallType.svg"
                alt="paywallFilterImg"
              />
              <p>Paywall Only</p>
            </div>
            <div className={styles.switch}>
              <CustomSwitch
                checked={Boolean(activeButton?.includes("paywall"))}
                onChange={handlePaywallClick}
                inputProps={{ 'aria-label': 'paywall apps toggle' }}
              />
            </div>
          </div>
        )}
      </div>
      <div className={styles.flex}>
        {rootActiveView === ACTIVE_VIEW_HOME &&
          <StoredCount onFocus={() => handleFocus(dispatch)} />
        }
        {isResetVisible && (
          <div onClick={handleReset} className={styles.reset}>
            <img src="/img/blackIcon/Close.svg" alt="resetImg" loading="lazy" />

          </div>
        )}
      </div>
    </div>
  );
};

export default PaywallType;
