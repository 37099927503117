// redux
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
// types
import { modalMenuPhoneState } from "../types/modalMenuPhoneTypes";

const initialState: modalMenuPhoneState = {
  isModalOpen: false,
  modalId: null,
  activeIndex: 0,
  isModalOpenPhotoCard: false,
  isExploreAppsReset: false,
};

const modalMenuPhoneSlice = createSlice({
  name: "modalMenuPhone",
  initialState,
  reducers: {
    toggleModalPhone: (state) => {
      state.isModalOpen = !state.isModalOpen;
    },
    showModalPhone: (state) => {
      state.isModalOpen = true;
    },
    hideModalPhone: (state) => {
      state.isModalOpen = false;
    },
    setModalId: (state, action: PayloadAction<number>) => {
      state.modalId = action.payload;
    },
    setActiveIndex: (state, action: PayloadAction<number>) => {
      state.activeIndex = action.payload;
    },
    toggleMoodalOpenPhotoCard: (state) => {
      state.isModalOpenPhotoCard = !state.isModalOpenPhotoCard;
    },
    showModalOpenPhotoCard: (state) => {
      state.isModalOpenPhotoCard = true;
    },
    hideModalOpenPhotoCard: (state) => {
      state.isModalOpenPhotoCard = false;
    },
    hideExploreAppsReset: (state) => {
      state.isExploreAppsReset = false;
    },
    showExploreAppsReset: (state) => {
      state.isExploreAppsReset = true;
    },
  },
});

export const {
  actions: modalMenuPhoneActions,
  reducer: modalMenuPhoneReducer,
} = modalMenuPhoneSlice;
