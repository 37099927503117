// react
import React from "react";
// styles
import styles from "./ContainsWords.module.scss";

export interface ContainsWordsProps {
  handleReset: () => void;
}

const ContainsWords: React.FC<ContainsWordsProps> = ({ handleReset }) => {
  return (
    <div className={styles.root}>
      {/* <div className={styles.min}>
        <input
          type="text"
          value={value}
          onChange={onChange}
          onKeyPress={handleKeyPress}
          maxLength={30}
        />
      </div> */}
      <div onClick={handleReset} className={styles.reset}>
        <img src="/img/gradientIcon/Reset.svg" alt="resetImg" loading="lazy" />
        <p>Reset Category</p>
      </div>
    </div>
  );
};

export default ContainsWords;
