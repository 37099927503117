import React, { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

import { useAPI, useMe } from '@/Hooks'

const EmailVerifyView = () => {

    const navigate = useNavigate()
    const { me, loading } = useMe()
    const { token } = useParams<{ token: string }>()
    const [isSuccess, setIsSuccess] = useState(false)
    const [isLoading, setIsLoading] = useState(true)
    const api = useAPI()

    const doVerifyToken = (token) => {

        const data = {
            token: token,
            client_id: import.meta.env.VITE_API_CLIENT_ID
        }

        api.post('/v1/auth/email-verify/', data)
            .then((resp) => {

                localStorage.setItem("access_token", resp.data.access_token)

                setIsSuccess(true)
                localStorage.setItem('showBillingAfterVerification', 'yes')
                toast.success('Email verified! Redirecting to home in 2 seconds')
                setIsLoading(false)
                setTimeout(() => location.href = '/', 2000)
            })
            .catch((err) => {
                toast.error('Email verification failed redirecting to home in 2 seconds')
                setIsSuccess(false)
                setIsLoading(false)
                setTimeout(() => navigate('/'), 2000)
            })
    }

    useEffect(() => {
        if (me && !loading) {
            return navigate('/')
        }

        if (!me && !loading) {
            doVerifyToken(token)
        }

    }, [me])

    return (
        <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            height: '100vh'
        }}>
            {isLoading ? (
                <></>
            ) : (
                <>
                    <img
                        src={isSuccess ? "/img/paymentLogoIcon.svg" : "/img/gradientIcon/Warning.svg"}
                        alt={isSuccess ? "successImg" : "failImg"}
                        style={{
                            height: isSuccess ? 'auto' : '200px',
                            width: isSuccess ? 'auto' : '200px'
                        }}
                    />
                    <h1 style={{
                        fontSize: '24px',
                        fontWeight: 'bold'
                    }} >{isSuccess ? 'Email verified! Redirecting' : 'Email verification failed'}</h1>
                </>
            )}
        </div>
    )
}

export { EmailVerifyView }
