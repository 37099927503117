import React from "react";
import styles from "./ModalHirStar.module.scss";

interface ModalHirStarEmptyProps {
  text: string;
  img: string;
}

const ModalHirStarEmpty: React.FC<ModalHirStarEmptyProps> = ({ text, img }) => {
  return (
    <div className={styles.root}>
      <div className={styles.groupPhotoContent}>
        <img src={img} alt="groupEmptyImg" />
        <p>{text}</p>
      </div>
    </div>
  );
};

export default ModalHirStarEmpty;
