// react

// styles
import { handleOpenVideoPage } from "@/app/libs/const/handleOpenVideoPage";
import styles from "./ModalSaveModeSharedGroupScreens.module.scss";

interface ModalSaveModeSharedGroupScreensProps {
  item: {
    id: number;
    screen: string;
    app: {
      id: number;
      name: string;
      shortname: string;
      icon: string | null;
      slug: string;
    };
    ts: string;
  };
  openGroupPhotoModal: (
    photo: string,
    photoId: number,
    name: string,
    ts: number,
    imageStoreId: number,
    appId?: number,
    slug?: string
  ) => void;
}

const ModalSaveModeSharedGroupScreens = ({ 
  item, 
  openGroupPhotoModal 
}: ModalSaveModeSharedGroupScreensProps) => {
  return (
    <div 
      className={styles.groupPhotoCard}
    >
      <div className={styles.groupPhotoHeaderTitle}>
        <img
          src={item.app.icon || "/img/skeletonLogo.svg"}
          alt="groupPhotoIcon"
          className={styles.appIcon}
        />
        <div className={styles.groupPhotoTitle} style={{ width: "60%" }} onClick={() => handleOpenVideoPage(0, item.app.slug, "Shared Group Screens", 1, item.app.id)} >
          <h3 title={item.app.name}>{item.app.name}</h3>
          <p title={item.app.shortname}>{item.app.shortname}</p>
        </div>
      </div>
      <div 
        className={styles.groupPhotoScreen}
       
      >
        <div  
        onClick={() =>
        openGroupPhotoModal(
          item.screen,
          item.id,
          item.app.name,
          Number(item.ts),
          0,
          item.app.id,
          item.app.slug
        )
      }>
          <img src={item.screen} alt="groupScreenImg" />
        </div>
      </div>
    </div>
  );
};

export default ModalSaveModeSharedGroupScreens;
