import "./instrument";
import ReactDOM from "react-dom/client";
import Modal from "react-modal";
import { SnackbarProvider } from "notistack";
import App from "./app/App";
// Modal
// providers
import { StoreProvider } from "./app/providers/store/StoreProvider";
import './shared/lib/mixpanel';
import "./index.scss";
import "./toast.scss";
import { ToastContainer } from 'react-toastify';
import { CheckMarkToastIcon, InfoToastIcon, ErrorToastIcon, Close } from '../public/ToastSVG/ToastSVG';


Modal.setAppElement("#root");

ReactDOM.createRoot(document.getElementById("root")!).render(
  <StoreProvider>
      <SnackbarProvider
        maxSnack={2}
        autoHideDuration={3000}
        preventDuplicate
        hideIconVariant
        transitionDuration={{ enter: 100, exit: 100 }}
        dense
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        className="snackbar-custom"
      >
      <ToastContainer
          position="top-center"
          autoClose={1500}
          limit={2}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
          icon={({ type }) => {
            switch (type) {
              case 'error':
                return <ErrorToastIcon />;
              case 'info':
                return <InfoToastIcon />;
              case 'warning':
                return <InfoToastIcon />;
              default:
                return <CheckMarkToastIcon />;
            }
          }}
          closeButton={<Close />}
        />
        <App />
      </SnackbarProvider>
  </StoreProvider>,
);
