import React, { useState } from 'react'
import { Outlet } from 'react-router-dom'

import LayoutHeader from "@/widgets/layoutHeader/ui/LayoutHeader/LayoutHeader";

import {
    SavedModeProvider,
    HiddenStarredModeProvider,
    AccountTeamsProvider,
    AppCountProvider
} from '@/Hooks'

const AppRootView = () => {

    const [popupVisible, setPopupVisible] = useState(false);
    const [isActiveAnyModal, setIsActiveAnyModal] = useState(false);
    const [filterButtonPlaceHolder, setFilterButtonPlaceHolder] = useState();
    const [searchBarPlaceHolder, setSearchBarPlaceHolder] = useState();
    const [rootActiveView, setRootActiveView] = useState()



    return (
            <SavedModeProvider>
                <HiddenStarredModeProvider>
                    <AccountTeamsProvider>
                        <AppCountProvider>
                            <LayoutHeader
                                filterButtonPlaceHolder={filterButtonPlaceHolder}
                                searchBarPlaceHolder={searchBarPlaceHolder}
                                >

                                    <Outlet context={{ 
                                        popupVisible: popupVisible, 
                                        setPopupVisible: setPopupVisible,
                                        isActiveAnyModal: isActiveAnyModal,
                                        setIsActiveAnyModal: setIsActiveAnyModal,
                                        setFilterButtonPlaceHolder: setFilterButtonPlaceHolder,
                                        setSearchBarPlaceHolder: setSearchBarPlaceHolder,
                                        rootActiveView: rootActiveView,
                                        setRootActiveView: setRootActiveView
                                    }}/>
                                </LayoutHeader>
                            
                        </AppCountProvider>
                    </AccountTeamsProvider>
                </HiddenStarredModeProvider>
            </SavedModeProvider>
    )
}

export { AppRootView }
