import { Navigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useGetCheckoutStatusQuery } from "@/features/auth/api/authAPI";
import { appsActions } from "@/entities/apps";

const CheckoutDoneView = () => {

    const dispatch = useDispatch();
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const sessionId = urlParams.get("session_id");

    const { data: apiDataStatus } = useGetCheckoutStatusQuery(sessionId!, {
        skip: !sessionId,
    });

    if (apiDataStatus) {
        dispatch(appsActions.setStatusPayment(apiDataStatus.status));
        dispatch(appsActions.setLineItems(apiDataStatus.line_items));
        dispatch(appsActions.setTransactionId(apiDataStatus.line_items[0].id));
        dispatch(appsActions.setDescription(apiDataStatus.line_items[0].description));
        dispatch(appsActions.setAmountPlans(apiDataStatus.amount_total));
    }

    if (apiDataStatus && apiDataStatus.status === "open") {
        // no more billing-checkout page
        //return <Navigate to="/billing-checkout" />;
        return <Navigate to="/" />;
    }

    if (apiDataStatus && apiDataStatus.status === "complete") {
        return <Navigate to="/" />;
    }

    return null;
}

export { CheckoutDoneView }