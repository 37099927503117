// react
import { ImageData } from "@/widgets/savePosition/libs/types/VideoControlsProps";
import React from "react";
import styles from './CroppedImage.module.scss';

interface CroppedImageProps {
  imageData: ImageData;
  photoWidth: number;
}

export const CroppedImage: React.FC<CroppedImageProps> = ({
  imageData,
  photoWidth,
}) => {
  const canvasRef = React.useRef<HTMLCanvasElement>(null);
  const [isLoading, setIsLoading] = React.useState(true);

  React.useEffect(() => {
    const canvas = canvasRef.current;
    if (canvas) {
      const ctx = canvas.getContext("2d");
      if (ctx) {
        const img = new Image();
        img.onload = () => {
          canvas.width = imageData.sWidth;
          canvas.height = imageData.sHeight;
          ctx.drawImage(
            img,
            imageData.sx,
            imageData.sy,
            imageData.sWidth,
            imageData.sHeight,
            0,
            0,
            imageData.sWidth,
            imageData.sHeight,
          );
          setIsLoading(false);
        };
        img.src = imageData.img;
      }
    }
  }, [imageData]);

  return (
    <div className={styles.container} style={{ width: photoWidth }}>
      <canvas
        className={`${styles.canvas} ${isLoading ? styles.hidden : ''}`}
        ref={canvasRef}
        width={imageData.sWidth}
        height={imageData.sHeight}
      />
      {isLoading && (
        <div
          className={styles.placeholder}
          style={{
            width: photoWidth,
            height: photoWidth * 2 // Adjust this ratio based on your typical image dimensions
          }}
        />
      )}
    </div>
  );
};
