import React, { useState, forwardRef } from 'react'
import { useNavigate, useOutletContext } from 'react-router-dom';
import axios from 'axios'
import { produce } from 'immer'
import { useSavedPointsMutation, useDeletePointMutation } from '@/features/auth/api/authAPI';
import { IoIosCheckmarkCircle } from 'react-icons/io';
import styles from './NoContextItems.module.scss';
import { useMe, useCurrentOrganization } from '@/Hooks'
import { toast } from 'react-toastify';
import { filterActions } from '@/entities/filter/model/slice/filterSlice';
import { useDispatch, useSelector } from 'react-redux';
import ContentLoader from "react-content-loader";
import { AnimatePresence } from 'framer-motion';
import PhoneHomePageHorisontalNew from '@/Views/Virtual/PhoneHomePageHorisontalNew';
import { motion } from 'framer-motion';
import Modal from "react-modal";
import { getFilterAppsData } from "@/entities/filterAppsData/model/selectors/getFilterAppsData";


const NoContextItems = forwardRef(({
  index,
  app,
  setSearchResults,
  onAppClick,
  handleExpandClick,
  handleOpenBillingPage,
  setSearchValue,
  handleContextMenu,
  transitionDirection,
  searchResults,
  currentAppIndex,
  setCurrentAppIndex,
  onNextApp,
  onPreviousApp,
  appDataToShow,
  appIndex,
  currentScreenNotBlurredID,
  setCurrentScreenNotBlurredID,
}, ref) => {

  const dispatch = useDispatch()
  const { me } = useMe()
  const currentOrganization = useCurrentOrganization()
  const navigate = useNavigate();
  const [savedPoint] = useSavedPointsMutation();
  const [deletePoint] = useDeletePointMutation();
  const { isScreensLoadingHome, isScreensLoadingStore } = useSelector(getFilterAppsData);

  //console.log(isScreensLoadingHome,isScreensLoadingStore,'1231231');
  

  const [cursorPosition, setCursorPosition] = useState({ x: 0, y: 0 });
  const [isHovered, setIsHovered] = useState(false);
  const [isHovered2, setIsHovered2] = useState(false);
  const { debugMode } = useOutletContext()
  const [imageLoaded, setImageLoaded] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleMouseMove = (e: React.MouseEvent) => {
    setCursorPosition({ x: e.clientX, y: e.clientY });
  };

  const modalVariants = {
    hidden: { opacity: 0, scale: 0.5, x: "-50%", y: "-50%" },
    visible: { opacity: 1, scale: 1, x: "-50%", y: "-50%" },
    exit: { opacity: 0, scale: 0.5, x: "-50%", y: "-50%" }
  };

  const contentVariants = {
    hidden: (direction: 'next' | 'prev') => ({
      opacity: 0,
      y: direction === 'next' ? 100 : -100
    }),
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5,
        ease: "easeOut"
      }
    },
    exit: (direction: 'next' | 'prev') => ({
      opacity: 0,
      y: direction === 'next' ? -100 : 100,
      transition: {
        duration: 0.5,
        ease: "easeIn"
      }
    })
  };


  const handleClickPro = () => { handleOpenBillingPage() }


  const handleMatchTextClick = (matchText: string) => {
    setSearchValue(matchText);
    dispatch(filterActions.activateFilterMagic());
  };

  const handleOpenModal = () => {
    setCurrentAppIndex(appIndex);
    setCurrentScreenNotBlurredID(app.id);
    setIsModalOpen(!isModalOpen);
  };

  const handlePreviousApp = () => {
    setCurrentScreenNotBlurredID(searchResults[currentAppIndex - 1].id);
    onPreviousApp(currentAppIndex);
  }

  const handleNextApp = () => {
    setCurrentScreenNotBlurredID(searchResults[currentAppIndex + 1].id);
    onNextApp(currentAppIndex);
  }

  const handleSavePhoto = async () => {
    if (me && me.username !== "" && me.email !== "" && currentOrganization) {
      if (app.is_saved) {
        try {
          await deletePoint(app.is_saved_pk).unwrap();

          setSearchResults(produce((draft) => {
            const index = draft.findIndex(obj => obj.id == app.id)
            if (index !== -1) {
              draft[index].is_saved = false
              draft[index].is_saved_pk = null
            }
          }))

          toast.success("Point removed successfully");
        } catch (error) {
          if (axios.isAxiosError(error)) {
            toast.error("Error removing saved point");
          }
        }
      } else {
        try {
          const resp = await savedPoint({
            app_video: app.app_video_id,
            ts: app.timestamp,
            organizationUuid: currentOrganization.uuid,
          }).unwrap();

          setSearchResults(produce((draft) => {
            const index = draft.findIndex(obj => obj.id == app.id)
            if (index !== -1) {
              draft[index].is_saved = true
              draft[index].is_saved_pk = resp.id
            }
          }))
          toast.success("Point saved successfully");
        } catch (error) {
          if (axios.isAxiosError(error)) {
            toast.error("Error saving point");
          }
        }
      }
    } else {
      localStorage.removeItem("access_token");
      localStorage.setItem("btnName", "save point");
      navigate("/login", { state: { fromLogin: true } });
      window.scrollTo(0, 0);
    }
  };




  return (
    <div
      ref={ref}
      key={`${app.app.id}-${app.id}`}
      className={styles.appItem}
    >
      <div className={styles.photoItemTitle}>
        <img src={app.app.icon} alt="appIcon" loading="lazy" />
        <div className={styles.photoItemContent} onClick={() => onAppClick(app.app, app.timestamp, app.app_video_id)}>
          <h3 className={styles.appName} title={app.app.name}>{app.app.name}</h3>
          <p className={styles.appShortname} title={app.app.developer.name}>{app.app.developer.name}</p>
        </div>
      </div>
      <div className={styles.photoContainer}>

        {isHovered && debugMode && (
          <div style={{
            width: '20%',
            position: 'absolute',
            zIndex: 99,
            //left: 0,
            //top: 10,
            margin: '10px',
            color: 'white',
            backgroundColor: 'black',
            //transform: `translate(${cursorPosition.x}px, ${cursorPosition.y-20}px)`,
          }}>

            <strong>Score: {app.match_score}</strong>
            <br />
            <strong>Text: {app.match_text}</strong>
          </div>
        )}

        <div
          className={`${styles.containerPhoto}`}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          onMouseMove={handleMouseMove}
        >
          {(isHovered && app.match_text) && (
            <p
              className={styles.matchText}
              onClick={() => handleMatchTextClick(app.match_text)}
              title={app.match_text}
            >
              {app.match_text}
            </p>
          )}

          {!imageLoaded && (
            <div className={styles.skeletonWrapper}>
              <ContentLoader
                speed={2}
                width="100%"
                height="650px"
                viewBox="0 0 224 485"
                backgroundColor="#f3f3f3"
                foregroundColor="#ecebeb"
                preserveAspectRatio="none"
              >
                <rect
                  x="0"
                  y="0"
                  rx="7"
                  ry="7"
                  width="100%"
                  height="100%"
                />
              </ContentLoader>
            </div>
          )}

          <img
            src={app.screen}
            alt="screen"
            className={styles.photo}
            loading="lazy"
            style={{ visibility: imageLoaded ? 'visible' : 'hidden' }}
            onLoad={() => setImageLoaded(true)}
            onClick={() => handleOpenModal()}
            onContextMenu={(e) => handleContextMenu(
              e,
              app.id.toString(),
              app.timestamp,
              app.app_video_id
            )}
          />

          {app.is_blurry && (
            <div className={styles.unlockProOverlay} onClick={() => onAppClick(app.app, app.timestamp, app.app_video_id)} >
              <div className={styles.unlockProContent} onClick={(e) => e.stopPropagation()}>
                <div>
                  <h1>
                    <span>
                      <button className={styles.Button} onClick={(e) => { e.preventDefault(); handleClickPro(); }}>Pro</button>
                    </span>
                    Member's Only
                  </h1>
                </div>
                <div>
                  <button
                    className={styles.proButton}
                    onClick={(e) => { e.preventDefault(); handleClickPro(); }}
                  >
                    Unlock Pro
                  </button>
                </div>
              </div>
            </div>
          )}

          <button
            type="button"
            onClick={handleSavePhoto}
            className={styles.saveButton}
          >
            {app.is_saved ? (
              <IoIosCheckmarkCircle className={styles.savedIcon} />
            ) : (
              <img
                className={styles.saveIcon}
                src="/img/blackIcon/Save.svg"
                alt="Save"
                loading="lazy"
              />
            )}
          </button>

          {isHovered && !app.is_blurry && (
            <button onClick={() => handleExpandClick(app, index)} className={styles.bigscreenContainer}>
              <div className={styles.bigscreen}>
                <img
                  src="/img/blackIcon/FullScreen.svg"
                  alt="expandModalIcon"
                  loading="lazy"
                  className={styles.bigscreenIcon}
                />
              </div>
            </button>
          )}
        </div>
      </div>

      <AnimatePresence mode="wait">
        {isModalOpen && (
          <Modal
            isOpen={isModalOpen}
            onRequestClose={handleOpenModal}
            contentLabel="Image Modal"
            className={styles.modal1}
            overlayClassName={styles.overlay}
          >
            <motion.div
              initial="hidden"
              animate="visible"
              exit="exit"
              variants={modalVariants}
              transition={{ duration: 0.3, ease: "easeInOut" }}
              style={{ position: "absolute", top: "50%", left: "50%" }}
            >
              <motion.div
                key={currentAppIndex}
                initial="hidden"
                animate="visible"
                exit="exit"
                variants={contentVariants}
                custom={transitionDirection}
                transition={{ duration: 0.3, ease: "easeInOut" }}
              >
                <motion.div>

                  {currentAppIndex > 0 && !isScreensLoadingHome && (
                    <div className={styles.prevAppContainer}>
                      <button
                        className={styles.previusAppButton}
                        onClick={handlePreviousApp}
                        onMouseEnter={() => setIsHovered(true)}
                        onMouseLeave={() => setIsHovered(false)}
                      >
                        <img src="/img/blackIcon/ArrowRight.svg" alt="Arrow Right" />
                      </button>
                      <p className={`${styles.previusAppText} ${isHovered ? styles.visible : ""}`}>
                        Go to previous Screen
                      </p>
                    </div>
                  )}

                  <AnimatePresence mode="wait" custom={transitionDirection}>
                    <motion.div
                      key={currentAppIndex}
                      custom={transitionDirection}
                      initial="hidden"
                      animate="visible"
                      exit="exit"
                      style={{ width: '100%' }}
                    >
                      <PhoneHomePageHorisontalNew
                        dataApi={appIndex === currentAppIndex ? app.app : appDataToShow}
                        app_video_id={app.app_video_id}
                        isOpen={false}
                        setIsPhonePageLoading={() => { }}
                        setIsSaveScreen={() => { }}
                        setLastClickTime={() => { }}
                        isFirstGroup={false}
                        currentOrganization={currentOrganization}
                        isPaywallOnly={false}
                        isPhonePageLoading={false}
                        ScreenNotBlurredID={currentScreenNotBlurredID}
                      />

                    </motion.div>
                  </AnimatePresence>

                  {searchResults.length > currentAppIndex + 1 && !isScreensLoadingHome && (
                    <div className={styles.nextAppContainer}>
                      <button
                        className={styles.nextAppButton}
                        onClick={handleNextApp}
                        onMouseEnter={() => setIsHovered2(true)}
                        onMouseLeave={() => setIsHovered2(false)}
                      >
                        <img src="/img/blackIcon/ArrowRight.svg" alt="Arrow Right" />
                      </button>
                      <p className={`${styles.nextAppText} ${isHovered2 ? styles.visible : ""}`}>
                        Go to next Screen
                      </p>
                    </div>
                  )}
                </motion.div>
              </motion.div>
            </motion.div>
          </Modal>
        )}
      </AnimatePresence>

    </div>
  )
})

NoContextItems.displayName = 'NoContextItems';

export { NoContextItems }