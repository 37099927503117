import React from 'react'
import AuthTerms from '@/shared/ui/AuthTerms/AuthTerms'
import LogoHeader from '@/shared/ui/LogoHeader/LogoHeader'
import AuthReg from '@/widgets/authReg/ui/AuthReg/AuthReg'

import styles from './RegisterView.module.scss'

const RegisterView = () => {
    return (
        <div className={styles.root}>
            {/* <LogoHeader /> */}
        <div className={styles.auth}>
            <AuthReg />
        </div>
        </div>
    )
}

export { RegisterView }