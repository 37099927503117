import React, { useState, useEffect, useRef, useCallback } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import styles from "./Teams.module.scss";
import { useCreateInviteMutation, useDeleteMembershipMutation, useListOrganizationUsersQuery, useUpdateOrganizationMutation, useGetOrganizationQuery, useGetOrganizationInvitesQuery, useSwitchOrganizationMutation, useGetOrganizationsListQuery } from "@/features/auth/api/authAPI";
import Spinner from '../Spinner/Spinner';
import RemoveMember from '../RemoveMember/RemoveMember';
import ChangeRole from '../ChangeRole/ChangeRole';
import { useUpdateMembershipRoleMutation, useLeaveOrganizationMutation } from "@/features/auth/api/authAPI";
import MembersList from '../MembersList/MembersList';
import Billing from '../Billing/Billing';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetUserQuery } from "@/features/auth/api/authAPI";
import { useGradient } from '@/Hooks/useGradient';
import SeatsModal from '../SeatsModal/SeatsModal';
import { useDispatch } from 'react-redux';
import { SidebarContext } from '@/Views/AccountTeamsView/AccountTeamsRootView';
import { useContext } from 'react';
import mixpanel from '@/shared/lib/mixpanel';
import { useCurrentOrganization, useAPI } from '@/Hooks';
import { saveModeActions } from '@/entities/saveMode/model/slice/saveModeSlice';

interface Organization {
    uuid: number;
    name: string;
    role: string;
}

interface MeData {
    organizations: Organization[];
    id: number;
    email: string;
}

interface TeamsProps {
    me: MeData;
    selectedOrgId: string;
    initialActiveMenuItem: string;
    refetchOrganizations: () => void;
}

const Teams: React.FC<TeamsProps> = ({ me, selectedOrgId, initialActiveMenuItem, refetchOrganizations }) => {

    const api = useAPI()
    const [email, setEmail] = useState("");
    const [role, setRole] = useState("member");
    const [existingEmails, setExistingEmails] = useState<Set<string>>(new Set());
    const [error, setError] = useState("");
    const [selectedOrg, setSelectedOrg] = useState<Organization | null>(null);
    const [removingMember, setRemovingMember] = useState<{ id: number; name: string } | null>(null);
    const [isRemoveModalOpen, setIsRemoveModalOpen] = useState(false);
    const [changingRole, setChangingRole] = useState<{ id: number; currentRole: string; name: string } | null>(null);
    const [isChangeRoleModalOpen, setIsChangeRoleModalOpen] = useState(false);
    const [activeMenuItem, setActiveMenuItem] = useState(initialActiveMenuItem);
    const fileInputRef = useRef<HTMLInputElement>(null);
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [updateMembershipRole] = useUpdateMembershipRoleMutation();
    const [createInvite, { isLoading: isCreatingInvite }] = useCreateInviteMutation();
    const [deleteMembership] = useDeleteMembershipMutation();
    const [leaveOrganization] = useLeaveOrganizationMutation();
    const [updateOrganization] = useUpdateOrganizationMutation();
    const { data: organizationData } = useGetOrganizationQuery(selectedOrgId);
    const currentOrganization = useCurrentOrganization()

    const { data: organizationUsers, isLoading: isLoadingUsers, refetch: refetchUsers } = useListOrganizationUsersQuery({
        uuid: selectedOrgId,
        order: '-created_at',
        page: 1,
        page_size: 100,
    });


    const { data: invites, refetch: refetchInvites } = useGetOrganizationInvitesQuery({
        uuid: selectedOrgId,
        order: "-created_at",
        page: 1,
        page_size: 10
    });

    const [isEditingName, setIsEditingName] = useState(false);
    const [newName, setNewName] = useState('');
    const nameInputRef = useRef<HTMLInputElement>(null);

    const { refetch } = useGetUserQuery(null);

    const [showBillingPopup, setShowBillingPopup] = useState(false);

    const { isSidebarOpen, setIsSidebarOpen } = useContext(SidebarContext);

    const [switchOrganization] = useSwitchOrganizationMutation();

    useEffect(() => {
        refetchUsers();
    }, []);

    const letterGradient = useGradient(selectedOrg?.name?.[0] || 'A');

    useEffect(() => {
        const org = me.organizations.find(org => org.uuid === selectedOrgId);
        setSelectedOrg(org || null);
        setError("");
        setEmail("");
        setRole("member");
        setSelectedFile(null);
    }, [selectedOrgId, me.organizations]);

    useEffect(() => {
        setActiveMenuItem(initialActiveMenuItem);
    }, [initialActiveMenuItem]);

    const isOnlyOwner = me.organizations.filter(org => org.role === 'owner').length === 1;
    console.log(isOnlyOwner, 'isOnlyOwner');

    const handleLeaveOrganization = async () => {
        try {
            await leaveOrganization(selectedOrgId).unwrap();
            toast.success("Successfully left the organization");

            if (me.organizations.length > 1) {
                const nextOrg = me.organizations.find(org => org.uuid.toString() !== selectedOrgId);
                if (nextOrg) {
                    try {
                        await switchOrganization(nextOrg.uuid.toString()).unwrap();
                        refetchOrganizations();
                        navigate('/settings/teams/' + nextOrg.uuid + '/members');
                    } catch (error) {
                        console.error('Error switching organization:', error);
                        toast.error('Failed to switch organization');
                    }
                }
            } else {
                navigate('/settings/account');
            }
        } catch (error: any) {
            console.error("Error leaving organization:", error);
            if (error.data && error.data.non_field_errors) {
                setError(error.data.non_field_errors[0]);
                toast.error(error.data.non_field_errors[0]);
            } else {
                setError("Failed to leave organization.");
                toast.error("Failed to leave organization.");
            }
        }
    };

    const handleCreateInvite = async () => {
        if (!selectedOrg) {
            toast.error("Organization not found.");
            return;
        }

        if (!email.trim()) {
            toast.error("Email address cannot be empty.");
            return;
        }

        if (existingEmails.has(email)) {
            toast.error("This email is already in use.");
            return;
        }

        try {
            await createInvite({
                organizationUuid: selectedOrg.uuid.toString(),
                email: email.trim(),
                role: role,
            }).unwrap();
            refetchInvites();
            setEmail("");
            setRole("member");
            mixpanel.track('Create invite', {
                email: email,
                role: role,
                UserEmail: me?.email || '',
                OrganizationID: currentOrganization?.uuid || '',
            });

            toast.success("Invite sent successfully!");
        } catch (error: any) {
            console.error("Error creating invite:", error);
            if (error.data?.non_field_errors?.[0]?.includes("you need additional seats")) {
                if (selectedOrg.role === 'owner') {
                    setShowBillingPopup(true);
                } else {
                    toast.error("Additional seats are required. Please contact the organization owner.");
                }
            } else if (error.data && error.data.detail === "You do not have permission to perform this action.") {
                toast.error("You don't have permission to invite members. Please contact an administrator.");
            } else if (error.data && error.data.non_field_errors) {
                toast.error(error.data.non_field_errors[0]);
            } else if (error.data && error.data.email) {
                toast.error(error.data.email[0]);
            } else {
                toast.error("Failed to send invite.");
            }
        }
    };

    const handleManageSubscriptionClick = () => {

        const data = { return_url: location.href }

        api.post(`/v1/organizations/${selectedOrg.uuid}/portal/`, data)
            .then((resp) => {
                window.open(resp.data.url, '_blank').focus()
            })
            .catch((err) => {
                // todo: show error if there is any
                console.log(err)
            })

    }

    const handleDeleteMembership = async (id: number) => {
        try {
            await deleteMembership({ id }).unwrap();
            setRemovingMember(null);
            setIsRemoveModalOpen(false);
            refetchUsers();
            toast.success("Member removed successfully");
        } catch (error) {
            toast.error("Failed to remove member");
            setError("Failed to remove member.");
        }
    }

    const handleRoleChange = async (id: number, newRole: string, newOrgName?: string) => {
        try {
            await updateMembershipRole({
                id,
                role: newRole,
                name: newOrgName
            }).unwrap();
            refetch();
            refetchUsers();
            refetchOrganizations();
            setChangingRole(null);
            setIsChangeRoleModalOpen(false);
            toast.success("Member role updated successfully");
        } catch (error: any) {
            if (error.data?.non_field_errors?.[0] === "You cannot edit your own membership") {
                toast.error("You cannot edit your own membership");
            } else {
                toast.error("Failed to update member role");
            }
            setError("Failed to update member role.");
        }
    };

    const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            setSelectedFile(file);
            try {
                const formData = new FormData();
                formData.append('image', file);

                const result = await updateOrganization({
                    uuid: selectedOrgId,
                    data: formData
                }).unwrap();

                setSelectedOrg(prev => ({
                    ...prev!,
                    image: result.image
                }));
                toast.success('Organization image updated successfully');
            } catch (error: any) {
                console.error('Update organization error:', error);
                toast.error(error.data?.detail || 'Failed to update organization image. Please try again.');
            }
        }
    };

    const handleNameChange = useCallback(async () => {
        if (!newName.trim() || newName === selectedOrg?.name) {
            setIsEditingName(false);
            return;
        }

        try {
            const result = await updateOrganization({
                uuid: selectedOrgId,
                data: { name: newName.trim() }
            }).unwrap();

            setSelectedOrg(prevOrg => ({
                ...prevOrg!,
                name: result.name
            }));
            toast.success('Organization name updated successfully');
            setIsEditingName(false);
            await refetch();
        } catch (error: any) {
            console.error('Update organization error:', error);
            if (error.data?.name?.[0] === "Organization with this name already exists.") {
                toast.error('An organization with this name already exists. Please choose a different name.');
            } else {
                toast.error(error.data?.detail || 'Failed to update organization name. Please try again.');
            }
            if (nameInputRef.current) {
                nameInputRef.current.focus();
            }
        }
    }, [newName, selectedOrg, selectedOrgId, updateOrganization, refetch]);

    const handleUnlockProClick = () => {
        dispatch(saveModeActions.activeProModal())
      }

    useEffect(() => {
        if (isEditingName && nameInputRef.current) {
            nameInputRef.current.focus();
        }
    }, [isEditingName]);

    if (!selectedOrg || isLoadingUsers) {
        return <div className={styles.spinner}><Spinner /></div>
    }

    return (
        <div className={styles.container}>
            <div className={styles.mobileHeader}>
                <button className={styles.mobileHeaderButtonLeft} onClick={() => setIsSidebarOpen(true)}>
                    <img width={24} height={24} src="/img/ColorIconTeam.svg" alt="team" />
                </button>
                <h1 className={styles.mobileHeaderTitle}>Team area and Billing</h1>
                <button
                    className={styles.mobileHeaderButtonRight}
                    onClick={() => setIsSidebarOpen(true)}>
                    <img src="/img/blackIcon/Close.svgg" alt="close" />
                </button>
            </div>

            <div className={styles.teamHeader}>
                <div className={styles.teamInfo}>
                    <input
                        type="file"
                        ref={fileInputRef}
                        style={{ display: 'none' }}
                        onChange={handleFileChange}
                        accept="image/*"
                    />
                    <button className={styles.buttonFile} onClick={() => fileInputRef.current?.click()} style={{ background: letterGradient }}>
                        {selectedFile ? (
                            <img
                                src={URL.createObjectURL(selectedFile)}
                                alt="Organization"
                                className={styles.teamIcon}
                            />
                        ) : organizationData?.image ? (
                            <img
                                src={organizationData.image}
                                alt="Organization"
                                className={styles.teamIcon}
                            />
                        ) : (
                            <p
                                className={styles.latter}
                            >
                                {selectedOrg.name[0]}
                            </p>
                        )}
                    </button>

                    <div className={styles.teamDetails}>
                        {isEditingName ? (
                            <input
                                ref={nameInputRef}
                                type="text"
                                value={newName}
                                onChange={(e) => setNewName(e.target.value)}
                                onBlur={handleNameChange}
                                onKeyPress={(e) => e.key === 'Enter' && handleNameChange()}
                                className={styles.nameInput}
                            />
                        ) : (
                            <h1
                                className={styles.teamName}
                                onMouseEnter={() => setNewName(selectedOrg.name)}
                                onClick={() => setIsEditingName(true)}
                            >
                                {selectedOrg.name}
                                <span className={styles.tooltip}>Change name of team</span>
                            </h1>
                        )}
                        {/* <p className={styles.teamDescription}>Dynamic team collaboration platform designed.</p> */}
                    </div>
                </div>

                <div>
                    {organizationData?.has_subscription && selectedOrg?.role === 'owner' ? (
                        <button className={styles.switchButton} onClick={handleManageSubscriptionClick}>
                            Manage Subscription
                        </button>
                    ) : selectedOrg?.role === 'owner' ? (
                        <button id="unlockProButton" className={styles.switchButton} onClick={handleUnlockProClick}>Unlock Pro</button>
                    ) : null}
                </div>

            </div>

            {(selectedOrg?.role === 'owner' || selectedOrg?.role === 'admin') && (
                <div className={styles.containerStyle2}>
                    <h1 className={styles.addMemberTitle}>Add member</h1>

                    <div className={styles.formStyle}>
                        <div className={styles.containerInput}>
                            <input
                                type="email"
                                placeholder="Email of the person to invite"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                className={styles.inputStyle}
                            />
                            <select
                                value={role}
                                onChange={(e) => setRole(e.target.value)}
                                className={styles.selectStyle}
                            >
                                <option value="member">Member</option>
                                <option value="admin">Admin</option>
                            </select>
                        </div>
                        <button
                            onClick={handleCreateInvite}
                            className={styles.buttonStyle}
                            disabled={isCreatingInvite}
                        >
                            {isCreatingInvite ? 'Adding...' : 'Invite'}
                        </button>
                    </div>
                </div>
            )}

                    <MembersList
                        organizationUsers={organizationUsers?.results || []}
                        onDeleteMembership={handleDeleteMembership}
                        onUpdateMembershipRole={handleRoleChange}
                        userRole={selectedOrg?.role || ''}
                        currentUserId={me.id}
                        organizationUuid={selectedOrgId}
                        handleLeaveOrganization={handleLeaveOrganization}
                    />
            
            {removingMember && (
                <RemoveMember
                    member={removingMember}
                    onClose={() => {
                        setIsRemoveModalOpen(false);
                        setRemovingMember(null);
                    }}
                    onConfirm={() => {
                        handleDeleteMembership(removingMember.id);
                    }}
                    isOpen={isRemoveModalOpen}
                />
            )}
            {changingRole && (
                <ChangeRole
                    member={changingRole}
                    onClose={() => {
                        setIsChangeRoleModalOpen(false);
                        setChangingRole(null);
                    }}
                    onConfirm={(newRole, newOrgName) => {
                        handleRoleChange(changingRole.id, newRole, newOrgName)
                    }}
                    isOpen={isChangeRoleModalOpen}
                />
            )}
            {showBillingPopup && (
                <SeatsModal
                    isOpen={showBillingPopup}
                    onClose={() => setShowBillingPopup(false)}
                    currentSeats={organizationData?.seats}
                    organizationUuid={selectedOrgId}
                />
            )}
        </div>
    );
};

export default Teams;
